import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import { Modal, RadioButton, Stack, TextStyle, ResourceList, Tabs } from "@shopify/polaris";

import { Banner } from "lib/components";
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { GET_ORDER_LINES, CHECK_ORDER_LINES } from "app/orders/apollo/queries";

import provideExportOrderProps from "./props";

const ProviderExportOrder = (props) => {
  const { isOpenModal, setIsOpenModal } = props;
  const { t } = useTranslation();
  const { loading: getOrderLineLoading, data: getOrderLineData } = useQuery(GET_ORDER_LINES, {
    variables: { input: { projection: { vendor: 1, vendorId: 1, fulfillmentStatus: 1, status: 1 } } },
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([[]]);
  const [statusList, setStatusList] = useState([]);
  const [fulfillmentStatus, setFulfillmentStatus] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [exportAll, setExportAll] = useState(true);
  const [isPrimaryActionLoading, setPrimaryAction] = useState(false);
  const [exportLink, setExportLink] = useState(false);
  const [isModalClickable, setIsModalClickable] = useState(true);
  const [params, setParams] = useState("");
  const [queryData, setQueryData] = useState({});
  const dataToFetch = {};
  const exportOrderUrl = "/export/order";
  const [
    getCheckOrderList,
    { loading: checkOrderLineLoading, data: checkOrderLineData },
  ] = useLazyQuery(CHECK_ORDER_LINES, { variables: { input: { ...queryData } } });

  useEffect(() => {
    let bannerData = false;
    if (!checkOrderLineLoading && checkOrderLineData) {
      const responseData = baseHelper.getResponseData(checkOrderLineData, constant.gql.CHECK_ORDER_LINES);
      if (!responseData) {
        bannerData = {
          title:
            baseHelper.getResponseError(checkOrderLineData, constant.gql.CHECK_ORDER_LINES) ||
            t("message.exportOrder.noOrder"),
          status: constant.CRITICAL,
        };
      }

      if (bannerData) {
        setPrimaryAction(false);
        setBanner({ ...bannerData, isOpen: true });
      } else {
        const csvDownloadRoute = (exportAll && exportOrderUrl) || `${exportOrderUrl}${params}`;
        setPrimaryAction(false);
        setExportLink(csvDownloadRoute);
      }
    }
  }, [checkOrderLineLoading, checkOrderLineData, exportAll, params, t]);
  useEffect(() => {
    if (!getOrderLineLoading || getOrderLineData) {
      const orderLines = baseHelper.getResponseData(getOrderLineData, constant.gql.GET_ORDER_LINES);
      if (!orderLines) {
        const bannerValue = {
          status: constant.CRITICAL,
          title: baseHelper.getResponseError(getOrderLineData, constant.gql.GET_ORDER_LINES),
        };
        setBanner({ ...bannerValue, isOpen: true });
      }
      if (!orderLines.length) {
        setIsModalClickable(false);
        return;
      }
      let vendorData = [];
      let statusData = [];
      const fulfillmentStatusData = [];
      const orderStatusData = [];
      orderLines.forEach(({ fulfillmentStatus: fStatus, status, vendor, vendorId }) => {
        statusData.push(
          {
            id: fStatus,
            name: fStatus,
          },
          {
            id: status,
            name: status,
          }
        );
        fulfillmentStatusData.push(fStatus);
        orderStatusData.push(status);
        vendorData.push({
          id: vendorId,
          name: baseHelper.ucFirst(vendor),
        });
      });
      vendorData = baseHelper.removeDuplicateData(vendorData);
      statusData = baseHelper.removeDuplicateData(statusData);
      vendorData = [...baseHelper.sort(vendorData, constant.NAME)];
      statusData = [...baseHelper.sort(statusData, constant.NAME)];
      setStatusList(statusData);
      setFulfillmentStatus(fulfillmentStatusData);
      setOrderStatus(orderStatusData);
    }
  }, [getOrderLineData, getOrderLineLoading]);

  const exportOrder = {
    label: {
      resourceName: [{ singular: t("label.exportOrder.Status"), plural: t("label.exportOrder.Statuses") }],
    },
    exportModalTabs: [
      {
        id: "status",
        content: t("label.exportOrder.Status"),
        panelID: "panel-status",
      },
    ],
  };

  const onExport = () => {
    setPrimaryAction(true);
    let bannerData = false;
    let status = [];
    let fulfillmentStatusNew = [];
    let orderStatusNew = [];

    status = [...selectedOptions[0]];
    fulfillmentStatusNew = status.filter((item) => item && fulfillmentStatus.includes(item));
    orderStatusNew = status.filter((item) => item && orderStatus.includes(item));
    const fulfillmentString = fulfillmentStatusNew.join(",");
    const orderString = orderStatusNew.join(",");
    if (!(exportAll || fulfillmentString || orderString)) {
      bannerData = {
        title: t("message.exportOrder.pleaseSelect"),
        status: constant.CRITICAL,
      };
      setPrimaryAction(false);
      setBanner({ ...bannerData, isOpen: true });
      return;
    }
    setParams(`?status=${orderString}&fulfillmentStatus=${fulfillmentString}`);
    if (orderStatusNew.length > 0) {
      dataToFetch.status = orderStatusNew;
    }
    if (fulfillmentStatusNew.length > 0) {
      dataToFetch.fulfillmentStatus = fulfillmentStatusNew;
    }
    setQueryData(dataToFetch);
    getCheckOrderList();
  };

  const toggleBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const getResourceListTabs = () => {
    const exportModalTabs = baseHelper.cloneData(exportOrder.exportModalTabs);
    const singularItem = t("label.exportOrder.Item");
    const pluralItem = t("label.exportOrder.Items");
    const changeIndex = 0;

    selectedOptions.forEach((item, index) => {
      if (item.length > 0) {
        exportModalTabs[index + changeIndex].content += ` (${item.length} ${
          item.length === 1 ? singularItem : pluralItem
        } ${t("label.exportOrder.Selected")})`;
      }
    });
    return exportModalTabs;
  };
  const closeModal = () => {
    setSelectedTabIndex(0);
    setSelectedOptions([[], []]);
    setExportAll(true);
    setPrimaryAction(false);
    setExportLink(false);
    setIsOpenModal(false);
    toggleBanner();
  };

  const downloadCSV = () => {
    window.open(exportLink);
    closeModal();
  };

  const handleSelectionChange = (selectedItems) => {
    const prevSelectedOptions = [...selectedOptions];
    prevSelectedOptions[selectedTabIndex] = [...selectedItems];
    setSelectedOptions(prevSelectedOptions);
    setExportLink(false);
  };

  const handleTabChange = (selectedTabInde) => {
    setSelectedTabIndex(selectedTabInde);
    setExportLink(false);
  };

  const items = [statusList];
  const updateResourceName = [...exportOrder.label.resourceName];

  const promotedBulkActions = [
    {
      content: t("label.exportOrder.GenerateCSV"),
      onAction: () => onExport(true),
    },
  ];
  return (
    <>
      <Modal
        open={isOpenModal}
        onClose={() => closeModal()}
        title={t("label.exportOrder.ExportOrder")}
        loading={getOrderLineLoading}
        primaryAction={{
          loading: isPrimaryActionLoading,
          content: exportLink ? t("label.exportOrder.DownloadCSV") : t("label.exportOrder.GenerateCSV"),
          onAction: () => (exportLink ? downloadCSV() : onExport()),
          disabled: !isModalClickable,
        }}
        secondaryActions={[
          {
            content: t("label.exportOrder.Cancel"),
            onAction: () => closeModal(),
          },
        ]}
      >
        <Modal.Section>
          {banner.isOpen && (
            <Banner
              key="banner"
              onDismiss={toggleBanner}
              title={banner.title}
              status={banner.status}
              isOpen={banner.isOpen}
            />
          )}
          <Stack vertical>
            <RadioButton
              label={t("label.exportOrder.ExportAll")}
              helpText={t("label.exportOrder.ExportAllText")}
              checked={exportAll}
              name="allOrders"
              onChange={() => {
                setBanner({ isOpen: false });
                setExportAll((prevState) => !prevState);
                setExportLink(false);
              }}
            />
            <RadioButton
              label={t("label.exportOrder.ExportSelected")}
              helpText={t("label.exportOrder.ExportSelectedText")}
              name="selectedOrders"
              checked={!exportAll}
              onChange={() => {
                setExportAll((prevState) => !prevState);
                setExportLink(false);
                setBanner({ isOpen: false });
              }}
            />
          </Stack>
          {!exportAll && (
            <>
              <Tabs
                key="tabs"
                tabs={getResourceListTabs()}
                selected={selectedTabIndex}
                onSelect={handleTabChange}
                fitted
              />
              <ResourceList
                key="resourceList"
                resourceName={updateResourceName[selectedTabIndex]}
                items={items[selectedTabIndex]}
                renderItem={(item) => {
                  const { id, name, location } = item;
                  return (
                    <ResourceList.Item id={id}>
                      <h3>
                        <TextStyle variation="strong">{name}</TextStyle>
                      </h3>
                      <div>{location}</div>
                    </ResourceList.Item>
                  );
                }}
                selectedItems={selectedOptions[selectedTabIndex]}
                onSelectionChange={handleSelectionChange}
                promotedBulkActions={promotedBulkActions}
              />
            </>
          )}
        </Modal.Section>
      </Modal>
    </>
  );
};

ProviderExportOrder.propTypes = provideExportOrderProps.type;

export default ProviderExportOrder;

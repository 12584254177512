import React, { useState, useContext } from "react";
import { Modal, TextContainer } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import helpers
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import components
import { Banner } from "lib/components";

// import props
import { deleteProp } from "../propsType";

// import gql
import { DELETE_OFFER } from "../../../../../../../apollo/mutations";

// import advance shipping context
import { AdvanceShippingContext } from "../../context/context";

const DeleteOffers = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { t } = useTranslation();
  const { deleteModal, toggleDeleteModal, deleteId, bands = [], shippingBandId, refetch } = props || {};
  const { gql } = constant;
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });

  const shippingBand =
    (bands.length > 0 && bands.find((band) => band.value === shippingBandId || band.parentId === shippingBandId)) || "";

  const [deleteOfferManage, { loading: deleteLoading }] = useMutation(DELETE_OFFER);
  const deleteShipping = () => {
    deleteOfferManage({
      variables: {
        input: {
          shippingOfferId: deleteId,
        },
      },
    })
      .then((res) => {
        const resData = baseHelper.getResponseData(res.data, gql.DELETE_SHIPPING_OFFER);
        if (!resData) {
          const resError = baseHelper.getResponseError(res.data, gql.DELETE_SHIPPING_OFFER);
          setBannerOuter({ isOpen: true, title: resError, status: "critical" });
          return;
        }
        setBannerOuter({ isOpen: true, title: t("message.ShippingOfferDeleted"), status: "success" });
        toggleDeleteModal();
        refetch();
      })
      .catch(() => {
        setBannerOuter({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
      });
  };

  const toggleBanner = () => {
    setBanner({ isOpen: !banner.isOpen });
  };

  if (!isVendorAllowed) {
    return null;
  }

  return (
    <>
      <Modal
        open={deleteModal}
        onClose={toggleDeleteModal}
        title={`${t("label.DeleteOffer")} - ${shippingBand ? shippingBand.label : ""}`}
        primaryAction={{
          content: t("button.advanceShippingList.secondaryAction"),
          onAction: deleteShipping,
          destructive: true,
          loading: deleteLoading,
        }}
      >
        <Modal.Section>
          {banner.isOpen && [
            <Banner
              isOpen={banner.isOpen}
              onDismiss={toggleBanner}
              message={banner.message}
              title={banner.title}
              status={banner.status}
            />,
            <br />,
          ]}
          <TextContainer>
            <p>{t("message.SureDeleteOffer")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

DeleteOffers.propTypes = deleteProp.type;

export default DeleteOffers;

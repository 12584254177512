import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex, handleBrandRegex } = yupHelper;

const firstNameSchema = (errorMessage) => {
  return yup.object().shape({
    firstName: yup.string().required(errorMessage).nullable(),
  });
};

const lastNameSchema = (errorMessage) => {
  return yup.object().shape({
    lastName: yup.string().required(errorMessage).nullable(),
  });
};

const emailSchema = (fieldRequired, validEmail) => {
  return yup.object().shape({
    email: yup.string().required(fieldRequired).matches(emailRegex, validEmail).nullable(),
  });
};

const phoneNumberSchema = (errorMessage, min, max) => {
  return yup.object().shape({
    phoneNumber: yup.string().required(errorMessage).min(7, min).max(15, max).nullable(),
  });
};

const streetAddressSchema = (errorMessage) => {
  return yup.object().shape({
    streetAddress: yup.string().required(errorMessage).nullable(),
  });
};

const citySchema = (errorMessage) => {
  return yup.object().shape({
    city: yup.string().required(errorMessage).nullable(),
  });
};

const countrySchema = (errorMessage) => {
  return yup.object().shape({
    country: yup.string().required(errorMessage).nullable(),
  });
};

const postalCodeSchema = (min, max) => {
  return yup.object().shape({
    postalCode: yup.string().min(4, min).max(6, max).nullable(),
  });
};

const provinceCodeSchema = (errorMessage) => {
  return yup.object().shape({
    provinceCode: yup.string().required(errorMessage).nullable(),
  });
};

const brandNameSchema = (errorMessage) => {
  return yup.object().shape({
    brandName: yup.string().required(errorMessage).nullable(),
  });
};

const brandHandleSchema = (fieldRequired, validBrandHandle) => {
  return yup.object({
    brandName: yup.string().required(fieldRequired).matches(handleBrandRegex, validBrandHandle),
  });
};

export {
  brandHandleSchema,
  brandNameSchema,
  citySchema,
  countrySchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  postalCodeSchema,
  provinceCodeSchema,
  streetAddressSchema,
};

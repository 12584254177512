const config = {
  "/": [
    {
      caption: "Check here for onboarding history of any user",
      label: "Onboarding History",
      link: "/onboard-activity",
    },
  ],
  "/activity": [
    {
      caption: "Check progress of your bulk activities ",
      label: "Bulk Activity ",
      link: "activity/bulk",
    },
  ],
  "/invitation": [
    {
      label: "Add Vendors",
      caption: "Manage your vendors",
      link: "/providers/add",
    },
  ],
  "/products": [
    {
      label: "Add Product",
      caption: "Add products to your store",
      link: "/products/add",
    },
  ],
  "/payments": [
    {
      label: "Automatic Payouts",
      caption: "Manage payouts",
      link: "/payouts",
    },
  ],
  "/providers": [
    {
      label: "Add Providers",
      caption: "Manage your providers",
      link: "/providers/add",
    },
  ],
};

export default config;

import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";

// import hoc
import { withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
import { Banner, SkeletonAnnotated } from "lib/components";
import { baseHelper, errorHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { NotFound } from "layout/private/components";

import { GET_SELLER_VARIANT } from "app/products/apollo/queries";
import { UPDATE_VARIANT, UPLOAD_IMAGE } from "app/products/apollo/mutations";

import { TitleEdit, Image, Price, Inventory, Shipping } from "./subFeatureItems";
import validate from "../yup";
import { conforms } from "lodash";

const OperatorVariantEdit = () => {
  const { match, history } = useContext(PrivateContext);
  const { params } = match;
  const { productId, id: variantId } = params;
  const { data: variantData, loading, error } = useQuery(GET_SELLER_VARIANT, {
    variables: { input: { _id: productId } },
  });

  const [updateVariant, { loading: updateLoading, error: updateVariantError }] = useMutation(UPDATE_VARIANT, {
    refetchQueries: [
      {
        query: GET_SELLER_VARIANT,
        variables: { input: { _id: productId } },
      },
    ],
  });
  const [uploadImage, { loading: imageLoading, error: uploadImageError }] = useMutation(UPLOAD_IMAGE);
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [variantValue, setVariantValue] = useState({});
  const [image, setImage] = useState();
  const [options, setOptions] = useState({
    option1Val: null,
    option2Val: null,
    option3Val: null,
  });
  const [productImages, setProductImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [submitButton, setSubmitState] = useState({
    isReadyToSubmit: false,
  });

  const { gql } = constant;

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  useEffect(() => {
    if (error || uploadImageError || updateVariantError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: errorHelper.parse(error || uploadImageError || updateVariantError),
      });
    }
  }, [error, uploadImageError, updateVariantError]);

  useEffect(() => {
    const { option1Val, option2Val, option3Val } = options;
    let isAllValuesFilled = false;
    if (option1Val) {
      isAllValuesFilled = option1Val && variantValue.option1Val;
    }
    if (option2Val) {
      isAllValuesFilled = option2Val && variantValue.option2Val;
    }
    if (option3Val) {
      isAllValuesFilled = option3Val && variantValue.option3Val;
    }

    const isAnyValidationError =
      errorMessage &&
      !!(
        (errorMessage.size && option1Val) ||
        (errorMessage.color && option2Val) ||
        (errorMessage.material && option3Val)
      );

    setSubmitState((prevState) => ({
      ...prevState,
      isReadyToSubmit: isAllValuesFilled && !isAnyValidationError,
    }));
  }, [variantValue, errorMessage, options]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(variantData, gql.GET_PRODUCT_SELLER_PRODUCT);

    if (responseData) {
      const { sellerProduct = {} } = responseData || {};
      const { variants = [], images = [] } = sellerProduct || {};

      if (variants.length < variantId || variantId <= 0) {
        return <NotFound />;
      }

      const variantOption = variants[variantId - 1];
      setOptions({
        option1: variantOption.option1,
        option2: variantOption.option2,
        option3: variantOption.option3,
        option1Val: variantOption.option1Val,
        option2Val: variantOption.option2Val,
        option3Val: variantOption.option3Val,
      });
      setProductImages(images || []);
      setImage((variantOption.image && [{ url: variantOption.image }]) || []);
      setVariantValue(variants[variantId - 1]);
    }
  }, [variantData, gql, variantId]);

  if (loading) {
    return <SkeletonAnnotated />;
  }

  const variantUpdate = (updateValues) => {
    updateVariant({ variables: { input: updateValues } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, gql.EDIT_VARIANT);
        const bannerData = {};
        bannerData.url = true;
        bannerData.status = "success";
        bannerData.title = "Variant was updated successfully. What’s next?";
        bannerData.action = {
          content: "Review your products here",
          onAction: () => history.push(`/products/edit/${productId}`),
        };
        if (resError) {
          bannerData.url = false;
          bannerData.title = resError;
          bannerData.status = "critical";
          banner.action = null;
        }
        setBanner({ isOpen: true, status: bannerData.status, title: bannerData.title, action: bannerData.action });
      })
      .catch((exception) => {
        setBanner({
          isOpen: true,
          status: "critical",
          title: errorHelper.parse(exception),
        });
      });
  };

  const onSubmit = async () => {
    const result = variantValue;
    await handleValidation("option1", result.option1Val);
    await handleValidation("option2", result.option2Val);
    await handleValidation("option3", result.option3Val);

    if (!submitButton.isReadyToSubmit) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return false;
    }

    const { sellerDiscount } = result;
    result.discount = sellerDiscount;
    delete result.sellerDiscount;
    const notToUpdate = constant.NOT_TO_UPDATE;
    notToUpdate.forEach((element) => {
      delete result[element];
    });

    if (image && image.length && image[0].url) {
      const imageUrl = image[0].url;
      result.image = { url: imageUrl };
    }
    result.id = parseInt(variantId, 10);
    result.productId = productId;
    const isLocalImageUrl = image && image.upload === constant.LOCAL;

    if (!isLocalImageUrl) {
      variantUpdate(result);
    }

    if (isLocalImageUrl) {
      try {
        const res = await uploadImage({ variables: { input: { image: image[0], productId: match.params.productId } } });
        const resData = baseHelper.getResponseData(res.data, gql.UPLOAD_IMAGE);
        if (resData) {
          result.image = { url: resData.imageUrl };
          variantUpdate(result);
        }
      } catch (exception) {
        setBanner({
          isOpen: true,
          status: "critical",
          title: errorHelper.parse(exception),
        });
      }
    }
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const handleChange = (fieldName, fieldValue) => {
    if (fieldName === constant.WEIGHT_UNIT || fieldValue > -1 || fieldValue.price > -1 || fieldName === constant.SKU) {
      setVariantValue((prevState) => ({
        ...prevState,
        [fieldName]: fieldValue,
      }));
    }
  };

  const handleTitleChange = (fieldName, fieldValue) => {
    setVariantValue((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Layout.Section>
            <Banner
              title={banner.title}
              status={banner.status}
              isOpen={banner.isOpen}
              action={banner.action}
              onDismiss={onDismissBanner}
            />
          </Layout.Section>
          <br />
        </>
      )}
      <Layout.AnnotatedSection title="Edit Variant">
        <TitleEdit
          options={options}
          variantData={variantValue}
          handleChange={handleTitleChange}
          handleValidation={handleValidation}
          errorMessage={errorMessage}
        />
        <Image productImages={productImages} setImage={setImage} image={image} />
        <Price variantData={variantValue} handleChange={handleChange} />
        <Inventory variantData={variantValue} handleChange={handleChange} handleTitleChange={handleTitleChange} />
        <Shipping variantData={variantValue} handleChange={handleChange} handleTitleChange={handleTitleChange} />
        <PageActions
          primaryAction={{
            content: "Submit",
            onAction: () => onSubmit(),
            loading: updateLoading || imageLoading,
            disabled: variantValue && !(variantValue.option1Val || variantValue.option2Val || variantValue.option3Val),
          }}
          secondaryActions={{
            content: "Cancel",
            onAction: () => history.push(`/products/edit/${productId}`),
            disabled: updateLoading || imageLoading,
          }}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(OperatorVariantEdit, { feature: constant.EDIT_VARIANT });

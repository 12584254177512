import React from "react";
import { Card, Checkbox, Select, Stack, TextField, FormLayout } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { useTranslation } from "react-i18next";
import { renderDetail } from "../propsType/index";

const RenderOfferDetail = (props) => {
  const { t } = useTranslation();
  const { FIXED, FREE } = constant;
  const {
    isCumulative,
    handleCost,
    handleDescription,
    handleType,
    priceType,
    description,
    toggleCumulativeShipping,
    isOrderValue,
    moneyFormat,
    priceValue,
  } = props;

  const options = [
    { label: t("label.Fixed"), value: FIXED },
    { label: t("label.FreeShipping"), value: FREE },
  ];

  return (
    <Card title={t("label.OfferDetails")} sectioned>
      <Stack.Item>
        <TextField
          label={t("label.Description")}
          placeholder={`${t("placeHolder.Description")} *`}
          value={description}
          onChange={handleDescription}
        />
      </Stack.Item>
      <Stack.Item>
        <FormLayout>
          <Stack>
            <Select
              label={`${t("label.FixedOrFreeShipping")} *`}
              options={options}
              onChange={handleType}
              placeholder={t("placeHolder.SelectShippingRate")}
              value={priceType}
            />
            {priceType === FIXED && (
              <Stack.Item>
                <TextField
                  type="number"
                  label={`${t("label.Amount")} *`}
                  min={0}
                  step={0.01}
                  value={(priceValue && priceValue.toString()) || ""}
                  onChange={handleCost}
                  prefix={moneyFormat}
                />
              </Stack.Item>
            )}
          </Stack>
        </FormLayout>
      </Stack.Item>
      <br />
      {!isOrderValue && (
        <Stack.Item>
          <Checkbox
            checked={isCumulative}
            onChange={toggleCumulativeShipping}
            label={t("label.ChargeCumulative")}
            helpText={t("message.CumulativeShipping")}
          />
        </Stack.Item>
      )}
    </Card>
  );
};

RenderOfferDetail.propTypes = renderDetail.type;

export default RenderOfferDetail;

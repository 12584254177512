import constant from "lib/constant/constant";

const { onboardingSteps } = constant;

const { WELCOME, UPDATE_PROFILE, ADD_PRODUCT, FINISH } = onboardingSteps;

const tabs = [
  {
    id: WELCOME,
    content: "Welcome",
    accessibilityLabel: "welcome-to-marketcube",
    panelID: "welcome-content",
    tabIndex: 0,
  },
  {
    id: UPDATE_PROFILE,
    content: "Step 1",
    panelID: "updateProfile-content",
    tabIndex: 1,
  },
  {
    id: ADD_PRODUCT,
    content: "Step 2",
    panelID: "addProduct-content",
    tabIndex: 2,
  },
  {
    id: FINISH,
    content: "Finish Setup",
    panelID: "finish-content",
    tabIndex: 3,
  },
];

export default tabs;

import React, { useState, useContext, useCallback } from "react";
import { Stack, TextStyle, Link, Caption, Badge, ResourceItem } from "@shopify/polaris";
import { ReceiptMajorMonotone, CashDollarMajorMonotone } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";

// import helpers
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import props
import Popover from "lib/components/popover/popover";
import { rowProp } from "../propTypes";

// import cms and subFeature
import View from "./view";
import actionData from "../cms/resourceTableData";

const topMargin10px = {
  marginTop: "10px",
};

function PaymentRow(props) {
  const { history } = useContext(PrivateContext);
  const { t } = useTranslation();
  const { item, setBanner, activePopover, setActivePopover } = props;
  const { _id: id, orderId } = item;
  const { mainLink } = actionData(t);
  const [active, setActive] = useState(false);
  const [orderLineItemId, setOrderLineItemId] = useState("");
  const [commissionVendor1, setCommissionVendor] = useState("");
  const [shippingAmount, setShippingAmount] = useState("");
  const [moneyFormat1, setMoneyFormat] = useState("");
  const [vendorName, setVendorName] = useState("");

  // eslint-disable-next-line no-shadow
  const handleChange = (value, id, orderId, commissionVendor, moneyFormat, vendor, shippingAmount = '') => {
    if (value === "invoice") {
      window.open(`/invoice/${orderId}/${id}`, "_blank");
    }
    if (value === "payVendor") {
      setActive(true);
      setOrderLineItemId(id);
      setCommissionVendor(commissionVendor);
      setMoneyFormat(moneyFormat);
      setVendorName(vendor);
      setShippingAmount(shippingAmount)
    }
  };

  const handlePopover = useCallback(
    (itemId) => {
      setActivePopover({ [itemId]: !activePopover[itemId] });
    },
    [activePopover, setActivePopover]
  );

  const renderOrderRow = (items) => {
    const {
      vendor,
      dueDate,
      status,
      orderNumber,
      accountingStatus,
      moneyFormat,
      commissionSeller,
      createdAt,
      commissionVendor,
      paidVia,
      shippingAmount,
      _id,
    } = items;
    let isStatus = false;
    let isDuedate = true;
    const { REJECTED, PAID, UNPAID, NEW, DISPUTE } = constant;
    const isAccountStatus =
      (accountingStatus === PAID && true) ||
      (accountingStatus === UNPAID && false) ||
      (accountingStatus === DISPUTE && false);
    if (status.toLowerCase() === REJECTED || status.toLowerCase() === NEW) {
      isStatus = true;
    }
    if (dueDate === "" || dueDate === null) {
      isDuedate = false;
    }

    const handleClick = () => {
      history.push(`${mainLink.orderNumber}${orderId}`);
    };

    const badgeStatus = baseHelper.ucFirst(status);
    const badgeType = baseHelper.getBadgeType(status);

    const accountingBadgeStatus = baseHelper.ucFirst(accountingStatus);
    const accountingBadgeType = baseHelper.getBadgeType(accountingStatus);

    return (
      <>
        <Stack alignment="leading">
          <Stack.Item fill>
            <TextStyle>
              <Link onClick={handleClick}>
                {"#"}
                {orderNumber}
              </Link>
            </TextStyle>
            <Caption>
              <TextStyle variation="subdued">
                {`${t("label.CreatedOn")}: ${baseHelper.formatDate(createdAt, true)}`}
              </TextStyle>
              <br />
              <TextStyle>{`${t("label.Vendor")}: ${vendor}`}</TextStyle>
              <br />
              <TextStyle>{` ${t("label.SellerCommisson")}: ${moneyFormat} ${baseHelper.getPrice(commissionSeller)}`}</TextStyle>
              <br />
              <TextStyle>{` ${t("label.VendorAmount")}: ${moneyFormat} ${baseHelper.getPrice(commissionVendor)}`}</TextStyle>
              {!!shippingAmount && (
                <>
                  <br />
                  <TextStyle>
                    {`${t("label.Shipping")}: ${moneyFormat} ${baseHelper.getPrice(shippingAmount)}`}
                  </TextStyle>
                </>
              )}
              {isDuedate ? (
                <>
                  {" "}
                  <br />
                  <TextStyle>{`${t("label.DueDate")}: ${baseHelper.formatDate(dueDate)}`}</TextStyle>
                </>
              ) : (
                ""
              )}
              <br />
              {!paidVia && (
                <TextStyle>{`${t("label.paidManually")}: ${baseHelper.formatDate(createdAt, false)}`}</TextStyle>
              )}
            </Caption>
          </Stack.Item>
          <Stack.Item>
            <Popover
              // eslint-disable-next-line no-underscore-dangle
              active={activePopover[_id]}
              setActive={() => handlePopover(_id)}
              options={[
                {
                  content: "Invoice",
                  icon: ReceiptMajorMonotone,
                  onAction: () => handleChange("invoice", id, orderId, commissionVendor, moneyFormat, vendor),
                },
                {
                  content: "Pay Vendor",
                  icon: CashDollarMajorMonotone,
                  onAction: () => handleChange("payVendor", id, orderId, commissionVendor, moneyFormat, vendor, shippingAmount),
                },
              ]}
              disabled={isAccountStatus || isStatus}
            />
          </Stack.Item>
        </Stack>
        <br />
        <Stack.Item style={topMargin10px}>
          <div>
            <Stack>
              <Stack.Item>
                <Badge status={badgeType}>{badgeStatus}</Badge>
              </Stack.Item>
              <Stack.Item>
                <Badge status={accountingBadgeType}>{accountingBadgeStatus}</Badge>
              </Stack.Item>
            </Stack>
          </div>
        </Stack.Item>
      </>
    );
  };

  return (
    <>
      <ResourceItem id={id}>
        <Stack vertical={false} distribution="fillEvenly">
          {renderOrderRow(item)}
        </Stack>
      </ResourceItem>

      {active && (
        <View
          active={active}
          handleClose={() => setActive(!active)}
          orderLineItemId={orderLineItemId}
          commissionVendor={commissionVendor1}
          shippingAmount={shippingAmount}
          moneyFormat={moneyFormat1}
          vendor={vendorName}
          setBanner={setBanner}
          shippingAmount={shippingAmount}
        />
      )}
    </>
  );
}

PaymentRow.propTypes = rowProp.type;
export default PaymentRow;

import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneNumberSchema,
  citySchema,
  countrySchema,
  postalCodeSchema,
  streetAddressSchema,
  brandHandleSchema,
  brandNameSchema,
  mailSubjectSchema,
} from "./schema";

const { validateValue, handlePromiseError } = yupHelper;
const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  CITY,
  COUNTRY,
  POSTAL_CODE,
  STREET_ADDRESS,
  BRAND_HANDLE,
  BRAND_NAME,
  MAIL_SUBJECT,
} = constant;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case FIRST_NAME:
      promise = validateValue(firstNameSchema(cms), { firstName: value });
      break;
    case LAST_NAME:
      promise = validateValue(lastNameSchema(cms), { lastName: value });
      break;
    case EMAIL:
      promise = validateValue(emailSchema(cms), { email: value });
      break;
    case PHONE_NUMBER:
      promise = validateValue(phoneNumberSchema(cms), { phoneNumber: value });
      break;
    case CITY:
      promise = validateValue(citySchema(cms), { city: value });
      break;
    case COUNTRY:
      promise = validateValue(countrySchema(cms), { country: value });
      break;
    case POSTAL_CODE:
      promise = validateValue(postalCodeSchema(cms), { postalCode: value });
      break;
    case STREET_ADDRESS:
      promise = validateValue(streetAddressSchema(cms), { streetAddress: value });
      break;
    case BRAND_NAME:
      promise = validateValue(brandNameSchema(cms), { brandName: value });
      break;
    case BRAND_HANDLE:
      promise = validateValue(brandHandleSchema(), { brandHandle: value });
      break;
    case MAIL_SUBJECT:
      promise = validateValue(mailSubjectSchema(cms), { mailSubject: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};
export default validate;

import React, { useContext } from "react";
import { Card, Checkbox, TextField, Select } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";
// import context
import { FormContext } from "../../../context/context";

import vendorProductCms from "../../../cms/vendorProduct";

const Shipping = () => {
  const { cms = {} } = useContext(PrivateContext);
  const { handleChange, data } = useContext(FormContext);
  const options = ["lb", "oz", "kg", "g"];
  const selectWeight = (
    <Select
      id="selectWeight"
      value={data.selectWeight || "lb"}
      label={cms.label.weightUnit}
      onChange={(value) => handleChange(constant.SELECT_WEIGHT, value)}
      labelHidden
      options={options}
    />
  );
  return (
    <Card title="Shipping" id="productShipping">
      <Card.Section>
        <Checkbox
          id="shippingCheckbox"
          label={cms.message.physicalProduct}
          checked={data.shipping || ""}
          onChange={(value) => handleChange(constant.SHIPPING, value)}
        />
      </Card.Section>
      {data.shipping && (
        <Card.Section title={cms.label.weight}>
          <p>{cms.message.calculateShipping}</p>
          <br />
          <TextField
            id="weight"
            label={cms.label.weight}
            type="number"
            value={data.weight || ""}
            placeholder={vendorProductCms.label.shipping.weightUnit}
            onChange={(value) => handleChange(constant.WEIGHT, Number(value))}
            connectedRight={selectWeight}
          />
        </Card.Section>
      )}
    </Card>
  );
};

export default Shipping;

import { gql } from "apollo-boost";

const VENDOR_LIST = gql`
  subscription {
    getVendorList {
      data {
        vendor {
          email {
            address
            verified
          }
          firstName
          lastName
          brandName
          createdAt
          _id
          roles {
            name
          }
        }
      }
    }
  }
`;

export default VENDOR_LIST;

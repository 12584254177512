import { gql } from "apollo-boost";

const CONNECT_STRIPE_VENDOR = gql`
  mutation connectStripeVendor($input: ConnectStripeVendor) {
    connectStripeVendor(input: $input) {
      status
      data
      error
    }
  }
`;

const CONNECT_PAYPAL_VENDOR = gql`
  mutation connectPaypalVendor($input: PaypalVendor) {
    connectPaypalVendor(input: $input) {
      status
      data
      error
    }
  }
`;

const DISCONNECT_STRIPE_VENDOR = gql`
  mutation disconnectStripe($input: DisconnectStripe) {
    disconnectStripe(input: $input) {
      status
      error
      data
    }
  }
`;

const DISCONNECT_PAYPAL_VENDOR = gql`
  mutation disconnectPaypal($input: DisconnectPaypal) {
    disconnectPaypal(input: $input) {
      status
      data
      error
    }
  }
`;

export { CONNECT_PAYPAL_VENDOR, CONNECT_STRIPE_VENDOR, DISCONNECT_PAYPAL_VENDOR, DISCONNECT_STRIPE_VENDOR };

import React, { useContext } from "react";
import { Voyager } from 'graphql-voyager';
import fetch from 'isomorphic-fetch';

import { Spinner } from "lib/components";

import config from "config";

import { PrivateContext } from "lib/context";

const GraphQLVoyager = () => {
  const { isLoading } = useContext(PrivateContext);

  const introspectionProvider = (query) => {
    return fetch(config.apiURL, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({query: query}),
    }).then(response => {
      return response.json()
    });
  }

  const getComponent = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return <Voyager introspection={introspectionProvider} />;
  };

  const VoyagerComponent = getComponent();
  return VoyagerComponent;
};

export default GraphQLVoyager;

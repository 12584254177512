const pageContent = {
  label: {
    active: "Active",
    beta: "(Beta):",
    betaPlan: "beta",
    branding: "Branding",
    click: "Click ",
    coming: "coming soon",
    comingSoon: "Coming Soon",
    commission: "Advanced commission",
    commonFeatures: {
      advancedCommission: "Advanced Commission",
      customBranding: "Custom Branding",
    },
    current: "current plan",
    currentPlan: "Current Plan",
    custom: "Custom",
    customBranding: "Custom branding",
    features: "Features:",
    free: "Start here for FREE",
    freeProducts: "50 (unlimted in Beta)",
    freeTrial: "FREE 14 day trial",
    freeVendors: "5 (unlimted in Beta)",
    goTo: " to go to plan page.",
    growth: {
      basicShippingOptions: "Basic Shipping Options",
      customDomain: "Custom Domain",
      pricingManagement: "Pricing Management",
    },
    here: "here",
    marketcube: "Marketcube.io",
    maxProducts: "Products",
    maxVendors: "Vendors",
    month: "m",
    note: "Please note:",
    plan: "Plan:",
    popular: "Popular",
    pro: {
      advancedPricingManagement: "Advanced Pricing Management",
      advancedShippingOptions: "Advanced Shipping Options",
      apiAccess: "API Access",
      customDomain: "Custom Domain",
      returnsManagement: "Returns Management",
      vendorPages: "Vendor Pages",
    },
    registerAsSeller: "Register As Seller",
    startForFree: "Start for FREE",
    startWithOurFreeVersion: "Start with our FREE version",
    unlimited: "Unlimited",
    upgradeSoon: "upgrade soon",
  },
  message: {
    availableFrom: "this plan will be available from the 15th of May 2018",
    betaFeature: "during our Beta all features are available and usage is unlimited",
    byProceeding: "By proceeding, you are agreeing* to the",
    termsOfService: "Terms of service.",
    subjectToGovt: "* Subject to government tax and other prevailing charges.",
    ourPlatform:
      // eslint-disable-next-line max-len
      "Our platform is absolutely Free, it costs zilch, nada, zero. Please help us build the worlds best marketplace management platform and we’ll endeavour to keep it free for you to use. We would very much appreciate that you inform and guide us of any problems or gaps our applications has and we will fix them as soon as we can, and if it's something we've missed we'll build it for you.",
    needHelp: "We need your help!",
    sellersRep:
      // eslint-disable-next-line max-len
      "Sellers represent the online store owners, like yourself, who typically sell products or services on behalf of their vendors. Once registered you can start inviting your vendors to add products or services, that you wish to then sell.",
    goBack: "Click here to go back",
    unable: "Can't downgrade to this plan as you have",
    moreProducts: "more products than of this plan's limit.",
    moreVendors: "more vendors associated than of this plan's limit.",
    later: "this plan will be available later in the year.",
    planUpdated: "Plan is updated successfully.",
    notSureTryFreeVersion: "Not sure? Why not try our free version",
    getMarketcubeFree: "Get Maketcube.io with 3 vendors and 10 products free forever.",
    planNote:
      // eslint-disable-next-line max-len
      "Please note: The free tier is a fully functional version of our app but does not allow for branding customisations and the number of products and vendors is limited. Once happy you can always upgrade to the next most suitable tier, without loosing any of your data.",
  },
  bootstrap: {
    products: "Products: 500",
    vendors: "Vendors: 25",
  },
  growth: {
    products: "Products: 5000",
    vendors: "Vendors: 150",
    customDom: "Custom domain",
    shipping: "Basic Shipping Options",
    pricing: "Pricing management",
  },
  pro: {
    products: "Products: 25000",
    vendors: "Vendors: 500",
    customDom: "Custom domain",
    shipping: "Advanced shipping Options",
    pricing: "Advanced pricing management",
    returns: "Returns management",
    pages: "Vendor pages",
    api: "API Access",
  },
};

export default pageContent;

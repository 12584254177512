import { gql } from "apollo-boost";

const GET_FILTER_OPTIONS = gql`
  query {
    getFilterOptions {
      data {
        perProduct {
          label
          value
        }
        perCategory {
          label
          value
        }
        perVendor {
          label
          value
        }
        perVendorAndCategory {
          label
          value
        }
      }
      error
      status
    }
  }
`;
export { GET_FILTER_OPTIONS };

import { gql } from "apollo-boost";

const GET_ALL_METRICS = gql`
  query getAllMetrics($input: GetAllMetrics) {
    getAllMetrics(input: $input) {
      data {
        order {
          month
          count
        }
        payment {
          month
          count
        }
        product {
          month
          count
        }
        vendor {
          month
          count
        }
      }
      error
      status
    }
  }
`;
export { GET_ALL_METRICS };

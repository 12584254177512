import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { Spinner } from "lib/components";

import constant from "lib/constant/constant";
import { OperatorAdvanceShipping } from "./operator";
import { ProviderAdvanceShipping } from "./provider";

const { userKey } = constant;
const { provider, operator } = userKey;

const AdvanceShipping = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorAdvanceShipping />;
    }
    if (role === provider) {
      return <ProviderAdvanceShipping />;
    }
    return null;
  };

  if (isLoading) {
    return <Spinner />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const AdvanceShippingComponent = getComponent(currentUserRole);
  return AdvanceShippingComponent;
};

export default AdvanceShipping;

import { gql } from "apollo-boost";

const GET_WOOCOMMERCE_TEMP_PRODUCT_LIST = gql`
  query getTempWooCommerceProductList($input: GetListInput) {
    getTempWooCommerceProductList(input: $input) {
      data {
            _id
            name
            image
            isShopifySync
            providerIds
            storeProductId
            status
            updatedAt
            createdAt
          }
      error
      status
    }
  }
`;

export default GET_WOOCOMMERCE_TEMP_PRODUCT_LIST;
import React from "react";

import constant from "lib/constant/constant";

import { AdminOrderList } from "./admin/features";
import { OperatorOrderList } from "./operator/features";
import { ProviderOrderList } from "./provider/features";

import { storageHelper } from "../../../lib/helpers";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const OrderList = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminOrderList />;
    }
    if (role === operator) {
      return <OperatorOrderList />;
    }
    if (role === provider) {
      return <ProviderOrderList />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const OrderListComponent = getComponent(currentUserRole);
  return OrderListComponent;
};

export default OrderList;

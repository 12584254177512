import React, { useContext } from "react";

import { OperatorEmailTemplate } from "app/emailTemplate/modules/operator/features";
import { AdminEmailTemplate } from "app/emailTemplate/modules/admin/features";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { Spinner } from "@shopify/polaris";

const { userKey } = constant;
const { operator, admin } = userKey;

const EmailTemplate = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === admin) {
      return <AdminEmailTemplate />;
    }
    if (role === operator) {
      return <OperatorEmailTemplate />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const EmailTemplateComponent = getComponent(currentUserRole);
  return EmailTemplateComponent;
};

export default EmailTemplate;

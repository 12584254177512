// import packages
import React, { useState, useContext } from "react";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import hoc
import { withFeature } from "lib/hoc";

// import helper
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import components
import { Sheet } from "lib/components";
import { Image, Inventory, Organization, Price, Shipping, TitleDescription, Variant } from "./subFeatureItems";

// import provider
import { FormProvider } from "../../context/context";

// import gql
import { ADD_PRODUCT, UPLOAD_IMAGE } from "../../../../../../apollo/mutations";

// import props
import { manualFormProp } from "../../props";

const { SHOPIFY, OPTIONS } = constant;

const ManualForm = (props) => {
  const { setBanner } = props;
  const { history, cms } = useContext(PrivateContext);

  const [value, setValue] = useState({
    shipping: true,
    policySelection: SHOPIFY,
  });
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");
  const [imageStartUploading, setImageStartUploading] = useState(false);

  const shopify = constant.SHOPIFY;
  const [uploadImage, { loading: uploadImageLoading }] = useMutation(UPLOAD_IMAGE);
  const [addProductItem, { loading: addProductLoading }] = useMutation(ADD_PRODUCT);

  const primaryAction = {
    content: cms("common.label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.label.cancel"),
    onAction: () => setSheetActive(false),
  };

  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: fieldValue,
    });
  };

  const handleTiny = (content) => {
    handleChange(constant.TINYMCE, content);
  };

  const learnMore = (productItem, title) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(`TODO: Add description about ${productItem}`);
  };

  const addProduct = async (formValues) => {
    try {
      const val = await addProductItem({
        variables: {
          input: formValues,
        },
      });

      const resData = baseHelper.getResponseData(val.data, constant.gql.ADD_PRODUCT);
      const resError = baseHelper.getResponseError(val.data, constant.gql.ADD_PRODUCT);
      if (resError) {
        const banner = {
          action: null,
          isOpen: true,
          status: constant.CRITICAL,
          title: resError,
        };
        setBanner(banner);
        return;
      }
      if (resData) {
        history.push(`edit/${resData}`, { add: true });
        setValue({});
      }
    } catch (addProductError) {
      setBanner({
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  const findVendorName = (options, selectedValue) => {
    const result = options.find((vendorOption) => vendorOption.value === selectedValue);
    return result;
  };

  const imageUpload = (formField) => {
    const formValues = formField || {};
    const { images = [] } = formValues || {};
    let hasImageError = false;
    if (images && images.length) {
      const uploadedImages = [];
      const uploadImageCallback = (imageResponse) => {
        const { imageUrl = null, imageId = null } = imageResponse;
        if (imageUrl) {
          const uploadedImageData = {
            imageUrl,
            imageId,
          };

          uploadedImages.push(uploadedImageData);
        }
        if (hasImageError) {
          return;
        }
        if (uploadedImages && uploadedImages.length > 0 && uploadedImages.length === images.length) {
          setImageStartUploading(false);
          formValues.images = uploadedImages;
          addProduct(formValues);
        }
      };
      formValues.images.map(async (img) => {
        try {
          if (hasImageError) {
            return;
          }
          const response = await uploadImage({ variables: { input: { image: img } } });
          if (!imageStartUploading) {
            setImageStartUploading(true);
          }
          const imageResponse = baseHelper.getResponseData(response.data, "uploadImage");
          const imageError = baseHelper.getResponseError(response.data, "uploadImage");
          if (imageError) {
            setImageStartUploading(false);
            hasImageError = true;
            setBanner((prev) => ({
              ...prev,
              action: null,
              isOpen: true,
              status: constant.CRITICAL,
              title: imageError,
            }));
          }
          uploadImageCallback(imageResponse);
        } catch (uploadImageError) {
          setImageStartUploading(false);
          hasImageError = true;
          setBanner((prev) => ({
            ...prev,
            action: null,
            isOpen: true,
            status: constant.CRITICAL,
            title: cms("section.form.message.error.imageNotSaved"),
          }));
        }
      });
      setImageStartUploading(false);
    } else {
      addProduct(formValues);
    }
    return null;
  };

  const submitCallBack = () => {
    const {
      title,
      tinyMCE,
      image: formImage,
      price,
      comparePrice,
      sku,
      barcode,
      policySelection,
      inventoryQuantity,
      organisationTags,
      productValueType,
      shipping,
      weight,
      weightUnit,
      vendorValue,
      variant,
      vendorOptions,
      length = 0,
      width = 0,
      height = 0,
    } = value;

    const option1 = (variant && variant.length && variant[0].option1) || constant.SIZE;
    const option2 = (variant && variant.length && variant[0].option2) || constant.COLOR;
    const option3 = (variant && variant.length && variant[0].option3) || constant.MATERIAL;

    const vendorName = findVendorName(vendorOptions, vendorValue);

    const vendor = vendorName.label;
    const vendorId = vendorValue;

    const measurement = {
      length: {
        value: parseFloat(length),
        unit: constant.CM,
      },
      width: {
        value: parseFloat(width),
        unit: constant.CM,
      },
      height: {
        value: parseFloat(height),
        unit: constant.CM,
      },
    };

    const formValues = {
      title,
      description: tinyMCE,
      vendor,
      vendorId,
      barcode,
      inventoryManagement: policySelection === shopify ? shopify : null,
      sku,
      isShipping: !!shipping,
      weightUnit: weightUnit || OPTIONS[0],
      fulfillmentService: constant.MANUAL,
      option1,
      option2,
      option3,
      images: formImage,
      variants: variant,
      measurement,
      tags: organisationTags,
      productType: productValueType,
    };

    formValues.comparePrice = parseFloat(comparePrice);
    formValues.price = parseFloat(price);
    formValues.weight = parseFloat(weight);
    formValues.quantity = parseInt(inventoryQuantity, 10);

    imageUpload(formValues);
  };

  const onFormSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };
    if (!value.title || value.title.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("section.form.message.error.titleIsRequired"),
      };
      setBanner(banner);
      return;
    }
    if (!value.vendorValue || value.vendorValue.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("section.form.message.error.vendorIsRequired"),
      };
      setBanner(banner);
      return;
    }
    submitCallBack();
  };

  return (
    <>
      <FormProvider value={{ handleChange, handleTiny, data: value, learnMore, setBanner }}>
        <TitleDescription />
        <Image />
        <Price />
        <Inventory />
        <Shipping />
        <Variant />
        <Organization />
      </FormProvider>
      <PageActions
        primaryAction={{
          id: "submitButton",
          content: cms("common.button.submit"),
          onAction: () => onFormSubmit(),
          loading: imageStartUploading || uploadImageLoading || addProductLoading || false,
        }}
        secondaryActions={[
          {
            id: "cancelButton",
            onAction: () => history.push("/"),
            content: cms("common.button.cancel"),
          },
        ]}
      />
      <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {sheetContent}
      </Sheet>
    </>
  );
};

ManualForm.propTypes = manualFormProp.type;

export default withFeature(ManualForm, { feature: constant.PRODUCT_USING_FORM });

import { gql } from "apollo-boost";

const UPDATE_SELLER_SETTING = gql`
  mutation updateSellerShippingSetting($input: UpdateSellerSetting) {
    updateSellerShippingSetting(input: $input) {
      data 
      status
      error
    }
  }
`;

export { UPDATE_SELLER_SETTING };

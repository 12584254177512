import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Card,
  Tabs,
  Link,
  ResourceItem,
  Layout,
  Stack,
  FormLayout,
  Select,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import helpers and constant
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Banner, ResourceList } from "lib/components";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import context
import { PrivateContext } from "lib/context";

// import gql
import { GET_SESSION_LIST } from "app/userManagement/apollo/queries";
import { SESSION_LIST } from "../../../../apollo/subscriptions";

// import sub feature
import SessionFooter from "./subFeature/sessionFooter";

const AdminSessionList = () => {
  const { t } = useTranslation();
  const { history } = useContext(PrivateContext);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const prevFilter = baseHelper.queryParamsFromLocation(history);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [taggedWith, setTaggedWith] = useState(prevFilter.list_search || null);
  const [currentPage, setCurrentPage] = useState(parseInt(prevFilter.page, 10) || 1);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevFilter.perPage, 10) || 10);
  const [dataToFetch, setDataToFetch] = useState({
    search: prevFilter.search,
    list_filter: prevFilter.list_filter,
    list_search: prevFilter.list_search,
    filter: prevFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
  });
  const [search, setSearch] = useState(queryParam.search || "");
  const [tabSelected, setTabSelected] = useState(parseInt(queryParam.tab, 10) || 0);
  const [totalUSerList, setTotalUserList] = useState(0);
  const [userList, setUserList] = useState([]);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const options = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  const { data: sessionList, loading, subscribeToMore, error } = useQuery(GET_SESSION_LIST, {
    variables: {
      input: {
        search: dataToFetch.search,
        list_filter: dataToFetch.list_filter,
        list_search: dataToFetch.list_search,
        filter: dataToFetch.filter,
        page: parseInt(dataToFetch.page, 10),
        perPage: parseInt(dataToFetch.perPage, 10),
      },
    },
  });

  useEffect(() => {
    const appliedFilterObj = { ...dataToFetch };
    if (Object.keys(appliedFilterObj).length > 0) {
      const tab =
        (Object.prototype.hasOwnProperty.call(appliedFilterObj, t("label.providerList.tabKey")) &&
          appliedFilterObj.tabKey) ||
        0;
      const limitPerPage =
        (Object.prototype.hasOwnProperty.call(appliedFilterObj, t("label.providerList.perPage")) &&
          appliedFilterObj.perPage) ||
        10;
      const Page =
        (Object.prototype.hasOwnProperty.call(appliedFilterObj, t("label.providerList.page")) &&
          appliedFilterObj.page) ||
        1;
      setCurrentPage(parseInt(Page, 10));
      setSelectedLimit(parseInt(limitPerPage, 10));
      setTabSelected(parseInt(tab, 10));
    }
  }, [dataToFetch, t]);

  useEffect(() => {
    if (sessionList) {
      const responseData = baseHelper.getResponseData(sessionList, constant.gql.GET_SESSION_LIST);
      const responseError = baseHelper.getResponseError(sessionList, constant.gql.GET_SESSION_LIST);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      if (responseData) {
        const { activity: activityData = [], count: totalCount = 0 } = responseData;
        setUserList(activityData);
        setTotalUserList(totalCount);
      }
    }
    if (error) {
      setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
    }
  }, [error, sessionList, t]);

  // Subscription for User Session List.
  useEffect(() => {
    subscribeToMore({
      document: SESSION_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newSessionData = subscriptionData.data.sessionList.data.sessionList;
        const prevSessionList = prev.getSessionList.data;
        const usersListData = [newSessionData, ...prevSessionList.activity];
        const totalCount = parseInt(prevSessionList.count, 10) + 1;
        return [setUserList(usersListData), setTotalUserList(totalCount)];
      },
    });
  }, [subscribeToMore]);

  const tabs = [
    {
      id: "all",
      content: t("label.All"),
      accessibilityLabel: "All users",
      panelID: "all-users-content",
      value: "all",
    },
    {
      id: "seller",
      content: t("label.CreateAssociation.Seller"),
      panelID: "seller-content",
      value: "seller",
    },
    {
      id: "vendor",
      content: t("label.CreateAssociation.Vendor"),
      panelID: "vendor-content",
      value: "vendor",
    },
  ];

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      const { value: tabName } = tabs[selectedTabIndex];
      setTabSelected(selectedTabIndex);
      baseHelper.setUrl(history, { tab: tabName, tabKey: selectedTabIndex, filter: tabName });
      const filteredObj = baseHelper.convertToInt(baseHelper.queryParamsFromLocation(history), {
        perPage: 10,
        page: 1,
      });
      setDataToFetch({ ...filteredObj, page: parseInt(currentPage, 10) });
    },
    [currentPage, history, tabs]
  );

  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.convertToInt(baseHelper.queryParamsFromLocation(history), { perPage: 10, page: 1 });
    setDataToFetch({ ...filteredObj, page: parseInt(page, 10) });
  };

  const handleSelectLimit = useCallback(
    (value) => {
      setSelectedLimit(parseInt(value, 10));
      baseHelper.setUrl(history, { perPage: parseInt(value, 10) });
      const result = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...result, perPage: parseInt(value, 10), page: parseInt(currentPage, 10) });
    },
    [currentPage, history]
  );

  const renderItem = (item) => {
    const notFound = "Not Found";
    const { _id, login, logout, brandName = notFound, ip = notFound, device = notFound, userId } = item;
    const loginDateTime = baseHelper.formatDate(login) || notFound;
    const logoutDateTime = baseHelper.formatDate(logout) || notFound;
    const loggedInTime = baseHelper.formatDate(login) || notFound;

    return (
      <>
        <ResourceItem id={_id}>
          <Stack vertical>
            <Stack distribution="leading">
              <Stack.Item>
                <Link url={`${constant.PROVIDER_VIEW}${userId}`}>{brandName || notFound}</Link>
              </Stack.Item>
              <Stack.Item>
                <TextStyle variation="strong">Log in Time:</TextStyle>
                {` ${loginDateTime}`}
              </Stack.Item>
              <Stack.Item>
                <TextStyle variation="strong">Log out Time:</TextStyle>
                {` ${logoutDateTime}`}
              </Stack.Item>
              <Stack.Item>
                <TextStyle variation="strong">IP:</TextStyle>
                {` ${ip || notFound}`}
              </Stack.Item>
            </Stack>

            <Stack distribution="leading">
              <Stack.Item>
                <TextStyle variation="strong">Logged In Time:</TextStyle>
                {` ${loggedInTime}`}
              </Stack.Item>
              <Stack.Item>
                <TextStyle variation="strong">Device:</TextStyle>
                {` ${device}`}
              </Stack.Item>
            </Stack>
          </Stack>
        </ResourceItem>
      </>
    );
  };

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...result, search: searchValue, page: 1 });
  };

  const handleQueryValueRemove = () => {
    setSearch("");
  };

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  // function for remove values of more filter.
  const handleTaggedWithRemove = () => {
    setTaggedWith(null);
    baseHelper.setUrl(history, { list_search: "" });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const filterOptions = [
    { label: "Select", value: "" },
    { label: "User", value: "brandName" },
  ];

  function disambiguateLabel(key, value) {
    switch (key) {
      case "userFilter":
        return `${value}`;
      default:
        return value;
    }
  }

  const appliedFilterArrObj = [
    {
      key: "userFilter",
      label: disambiguateLabel("userFilter", taggedWith),
      onRemove: handleTaggedWithRemove,
    },
  ];
  const appliedFilters = !isEmpty(taggedWith) ? appliedFilterArrObj : [];

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    baseHelper.setUrl(history, { list_filter: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { list_search: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const customFilter = (
    <FormLayout>
      <Select options={filterOptions} value={selectedFilter} placeholder="Filter" onChange={handleSelectChange} />
      {(selectedFilter && <TextField label="like" value={taggedWith} onChange={handleTaggedWithChange} />) || null}
    </FormLayout>
  );

  const filters = [
    {
      key: "Filter By",
      label: "Filter By",
      filter: customFilter,
      shortcut: false,
    },
  ];

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner(() => setBanner({ isOpen: false, title: "", status: "" }))}
          />
        </Layout.Section>
      )}
      <Layout.Section>
        <Card>
          <Tabs tabs={tabs} selected={tabSelected} onSelect={handleTabChange}>
            <ResourceList
              resourceName={{ singular: "customer", plural: "customers" }}
              renderItem={renderItem}
              items={userList || []}
              loading={loading}
              onQueryChange={handleSearchChange}
              onQueryClear={handleQueryValueRemove}
              queryValue={search}
              filters={filters}
              appliedFilters={appliedFilters}
            />
          </Tabs>
          <SessionFooter
            count={totalUSerList}
            optionList={options}
            perPageCount={selectedLimit}
            onSelectLimit={handleSelectLimit}
            onPerPage={handlePerPage}
            currentPage={currentPage}
            isLoading={loading}
          />
        </Card>
      </Layout.Section>
    </>
  );
};

export default withErrorBoundary(AdminSessionList);

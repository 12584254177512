import React, { useState } from "react";
import { Card, Collapsible, Checkbox, Stack, Caption } from "@shopify/polaris";

import { reviewProduct } from "../props";

const ReviewProduct = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(true);
  const {
    isAdded,
    isDeleted,
    date,
    vendor,
    store,
    0: label,
    1: vendorChange,
    2: sellerChange,
    3: key,
    onToggleSelect,
  } = props;
  const handleToggleSelect = () => {
    setIsSelected(!isSelected);
    onToggleSelect(key);
  };
  const actionText = (isAdded && "Added") || (isDeleted && "Deleted") || "Updated";
  return (
    <Card
      title={[
        <Checkbox label="Basic checkbox" labelHidden checked={isSelected} onChange={handleToggleSelect} />,
        " ",
        `${label} changed`,
      ]}
      sectioned
      actions={[
        {
          content: isOpen ? "Hide" : "Show",
          onAction: () => setIsOpen(!isOpen),
          disclosure: isOpen ? "up" : "down",
        },
      ]}
    >
      <Stack>
        <Stack.Item fill>
          <Caption>
            Last Updated At:
            {date}
          </Caption>
        </Stack.Item>
      </Stack>
      <Collapsible open={isOpen} id="basic-collapsible-2">
        <Card.Section title={`${actionText} by ${vendor}`}>{vendorChange || "Not Provided"}</Card.Section>
        <Card.Section title={`Published at ${store}`}>{sellerChange || "NotProvided"}</Card.Section>
      </Collapsible>
    </Card>
  );
};

ReviewProduct.propTypes = reviewProduct.type;

export default ReviewProduct;

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-apollo";
import { Tabs, Card, Layout } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
import { ResourceList, Banner } from "lib/components";

// layout component
import { TwoColumnLayout } from "layout/private/components";

import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import productListCMS from "app/products/modules/generic/feature/list/cms/productListCMS";

// import report
import { QuickAction, QuickReport } from "app/reports";

// GQL
import { GET_PRODUCT_LIST_PROVIDER } from "app/products/apollo/queries";
import { BULK_PRODUCT } from "app/products/apollo/mutations";

// Generic Component.
import { RejectProduct, DeleteProduct, ProductListView } from "app/products/modules/generic/feature/list";
import MoreFilters, { Filter } from "app/products/modules/generic/moreFilters/moreFilter";
import { SUBSCRIPTION_BY_PROVIDER } from "app/products/apollo/subscriptions/productList";

const AdminProductList = () => {
  const { history } = useContext(PrivateContext);
  const { t } = useTranslation();
  const { tabs, sortOptions, resourceName } = productListCMS(t);
  const queryParams = baseHelper.getQueryParams(history.location.search);
  let searchVal = queryParams.search && queryParams.search.split("+");
  searchVal = searchVal && searchVal.length && searchVal.join(" ");
  const [queryValue, setQueryValue] = useState(searchVal || "");
  const [selectedItems, setSelectedItems] = useState([]);
  const getQueryParams = baseHelper.getQueryParams(history.location.search);
  const selectedSort = queryParams.sort_name && `${queryParams.sort_name}_${queryParams.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || "createdAt_desc");
  const [page, setPage] = useState(getQueryParams.page || 1);
  const [perPage, setPerPage] = useState(getQueryParams.perPage || 10);
  const [singleId, setSingleId] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [primaryActionTitle, setPrimaryActionTitle] = useState(null);
  const [secondaryActionsTitle, setSecondaryActionsTitle] = useState(null);
  const [keyName, setKeyName] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [productListData, setProductListData] = useState(false);
  const [productListTotal, setProductListTotal] = useState(0);
  const [selected, setSelected] = useState(parseInt(queryParams.tab, 10) || 0);
  const [taggedWith, setTaggedWith] = useState(queryParams.list_search || null);
  const [selectedFilter, setSelectedFilter] = useState(queryParams.list_filter || null);
  const [listLoading, setListLoading] = useState(false);
  const [activePopover, setActivePopover] = useState({});
  const [active, setActive] = useState({});

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });
  if (!getQueryParams) {
    getQueryParams.search = "";
    getQueryParams.sort_name = "";
    getQueryParams.sort_order = "";
  }
  const {
    error: errorProductList,
    loading: loadingProductList,
    data: dataProductList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_PRODUCT_LIST_PROVIDER, {
    variables: {
      input: {
        search: getQueryParams.search,
        sort_name: getQueryParams.sort_name || "createdBy",
        sort_order: getQueryParams.sort_order || "desc",
        page: parseInt(getQueryParams.page || page, 10),
        perPage: parseInt(getQueryParams.perPage || perPage, 10),
        filter: getQueryParams.filter,
        list_filter: getQueryParams.list_filter,
        list_search: getQueryParams.list_search,
      },
    },
  });

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LIST_PROVIDER,
        variables: {
          input: {
            search: getQueryParams.search,
            sort_name: getQueryParams.sort_name,
            sort_order: getQueryParams.sort_order,
            page: parseInt(getQueryParams.page || page, 10),
            perPage: parseInt(getQueryParams.perPage || perPage, 10),
            filter: getQueryParams.filter,
            list_filter: getQueryParams.list_filter,
            list_search: getQueryParams.list_search,
          },
        },
      },
    ],
  });

  useEffect(() => {
    const getProducts = baseHelper.getResponseData(dataProductList, constant.gql.GET_PRODUCT_LIST);
    const getProductsError = baseHelper.getResponseError(dataProductList, constant.gql.GET_PRODUCT_LIST);
    if (getProducts) {
      setProductListData(getProducts.productList);
      setProductListTotal(getProducts.count);
    }
    if (getProductsError) {
      setListLoading(false);
      setBanner({ title: getProductsError, status: "critical", isOpen: true });
    }
  }, [dataProductList]);

  useEffect(() => {
    if (errorProductList) {
      setListLoading(false);
      setBanner({ title: t("error.common.somethingWentWrong"), status: "critical", isOpen: true });
    }
  }, [errorProductList, t]);

  // Subscription for provider list Page.
  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIPTION_BY_PROVIDER,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  const handleSearchValue = (searchValue) => {
    const search = baseHelper.setQueryParams(history.location, { search: searchValue });
    setQueryValue(searchValue);
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleQueryValueRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { search: "" });
    setQueryValue("");
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSort = (selectedValue) => {
    const sort = selectedValue.split("_");
    if (sort && sort.length === 2) {
      const sortedValue = baseHelper.setQueryParams(history.location, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedValue);
      history.push(`${history.location.pathname}?${sortedValue}`);
    }
  };

  const handleTabChange = (selectedTabIndex) => {
    const tabName = tabs[selectedTabIndex].value;
    setSelected(selectedTabIndex);
    const tabChange = baseHelper.setQueryParams(history.location, {
      tab: selectedTabIndex,
      filter: tabName,
    });
    history.push(`${history.location.pathname}?${tabChange}`);
  };

  const handleDeleteModal = (eventKey, id = false) => {
    if (eventKey === constant.DELETE.toLowerCase() && id) {
      setSingleId(id);
      setOpenDeleteModal(true);
      return;
    }
    setSingleId(null);
    setOpenDeleteModal(true);
  };

  const handleRejectModal = (eventKey, id = false) => {
    setSingleId(null);
    if (
      (eventKey === constant.REJECT ||
        eventKey === constant.UNREJECT ||
        eventKey === constant.UNDELETE ||
        eventKey === constant.RE_SUBMIT) &&
      id
    ) {
      setSingleId(id);
    }
    switch (eventKey) {
      case constant.RE_SUBMIT:
        setModalTitle(t("label.productList.reSubmitProducts"));
        setPrimaryActionTitle(t("label.productList.reSubmit"));
        setSecondaryActionsTitle(t("label.productList.cancel"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      default:
        break;
    }
  };

  const bulkAction = async (eventKey, deleteFromShopify = false, id = false, reason = "", buttonSelected = null) => {
    const { location } = history;
    const { pathname } = location;
    if (
      pathname.includes(constant.PRODUCT_URL) &&
      !pathname.includes(constant.PRODUCT_URL_SHOPIFY) &&
      eventKey === constant.BULK_EDIT
    ) {
      history.push(constant.PRODUCT_URL_EDIT, { selectedItems });
      return;
    }
    let formData = {};
    formData = {
      key: eventKey,
      ids: selectedItems,
      all: productListTotal === selectedItems.length,
      deleteFromShopify,
      reason,
    };
    if (id) {
      formData.ids = [id];
      formData.all = false;
      setListLoading(`${eventKey}_${id}`);
    }
    setSelectedButton(buttonSelected);
    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setOpenRejectModal(false);
        setSelectedItems([]);
        setKeyName(null);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.BULK_PRODUCT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_PRODUCT);
          if (responseData) {
            setBanner({ isOpen: true, title: t("message.productList.requestSuccess"), status: "success" });
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: "critical" });
          }
        }
      })
      .catch((err) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        if (err) {
          setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
        }
      });
  };

  const renderItem = (item, _, keyIndex) => {
    return (
      <ProductListView
        listLoading={listLoading}
        item={item}
        keyIndex={keyIndex}
        bulkAction={bulkAction}
        handleRejectModal={handleRejectModal}
        handleDeleteModal={handleDeleteModal}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        setListBanner={setBanner}
        active={active}
        setActive={setActive}
      />
    );
  };

  const promotedBulkActions = [
    {
      content: t("label.productList.bulkAction"),
    },
  ];
  const bulkActions = [
    {
      content: t("label.productList.deleteSelected"),
      onAction: () => handleDeleteModal(constant.DELETE.toLowerCase()),
      disabled: tabs[selected].value === constant.DELETED.toLowerCase(),
    },
    {
      content: t("label.productList.editSelected"),
      onAction: () => bulkAction(constant.BULK_EDIT),
      disabled: tabs[selected].value === constant.DELETED.toLowerCase(),
    },
  ];

  const handleTaggedWithRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "" });
    setTaggedWith("");
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSelectedFilterRemove = () => {
    const search = baseHelper.setQueryParams(history.location, { list_search: "", list_filter: "" });
    setSelectedFilter("");
    setTaggedWith("");
    history.push(`${history.location.pathname}?${search}`);
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = [];
  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: "taggedWith",
      label: disambiguateLabel("taggedWith", taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: "selectedFilter",
      label: disambiguateLabel("selectedFilter", selectedFilter),
      onRemove: handleSelectedFilterRemove,
    });
  }

  const handleClearAll = () => {
    handleTaggedWithRemove();
    handleSelectedFilterRemove();
  };

  const filters = [
    {
      key: "selectedFilter",
      label: t("label.productList.filterBy"),
      filter: (
        <Filter
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
  ];

  if (selectedFilter) {
    filters.push({
      key: t("taggedWith"),
      label: "Advance Filter",
      filter: (
        <MoreFilters
          setTaggedWith={setTaggedWith}
          taggedWith={taggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    });
  }
  const linkData = [
    {
      action: () => history.push("/products/add"),
      caption: "Add your products",
      id: "addProduct",
      label: "Add Products",
    },
  ];

  const getSecondaryComponent = () => {
    return (
      <>
        <QuickAction link={linkData} />
        <QuickReport />
      </>
    );
  };

  const getComponent = () => {
    return (
      <>
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              action={banner.action}
              onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
            />
            <br />
          </>
        )}
        <Layout>
          <DeleteProduct
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
          />
          <RejectProduct
            openRejectModal={openRejectModal}
            setOpenRejectModal={setOpenRejectModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            modalTitle={modalTitle}
            primaryActionTitle={primaryActionTitle}
            secondaryActionsTitle={secondaryActionsTitle}
            keyName={keyName}
          />
          <Layout.Section>
            <Card>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <ResourceList
                  resourceName={resourceName}
                  items={(productListData && productListData) || []}
                  idForItem={(products) => {
                    const { _id: id } = products;
                    return id;
                  }}
                  selectable
                  selectedItems={selectedItems}
                  onSelectionChange={(selectValue) => setSelectedItems(selectValue)}
                  bulkActions={bulkActions}
                  promotedBulkActions={promotedBulkActions}
                  loading={loadingProductList}
                  sortValue={sortValue}
                  sortOptions={sortOptions}
                  onSortChange={handleSort}
                  onQueryChange={handleSearchValue}
                  onQueryClear={handleQueryValueRemove}
                  handleClearAll={handleClearAll}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  queryValue={queryValue}
                  count={productListTotal}
                  page={page}
                  perPage={perPage}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  renderItem={renderItem}
                />
              </Tabs>
            </Card>
          </Layout.Section>
        </Layout>
      </>
    );
  };

  return <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent()} />;
};

export default withFeature(withErrorBoundary(AdminProductList), { feature: constant.PRODUCTS });

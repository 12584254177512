const paymentService = {
  label: {
    clientId: "Client Id*",
    secret: "Secret*",
  },
  placeholder: {
    clientIdPlaceholder: "Enter client id here",
    secretPlaceholder: "Enter secret here",
  },
};

export default paymentService;

import React from "react";
import { Card, TextField, Layout, Caption, TextStyle, FormLayout, TextContainer } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// import components
import ImageComponent from "lib/components/dropZone/dropZone";

// imports HOC
import { withFeature } from "lib/hoc";
import { baseHelper } from "lib/helpers";

const CustomFieldSection = (props) => {
  const { t } = useTranslation();
  const { data, handleChange, handleImageRemove, learnMore, lastUpdated } = props;
  const getFields = () => {
    return data.map(({ label, placeholder, inputType, value, key }) => {
      if (inputType === "textField") {
        return (
          <>
            <TextField
              label={label}
              placeholder={placeholder}
              value={value}
              onChange={(updatedValue) => handleChange(key, updatedValue)}
            />
            <br />
          </>
        );
      }
      if (inputType === "image") {
        return (
          <>
            <span>{label}</span>
            <ImageComponent
              onAdd={(updatedValue) => handleChange(key, updatedValue)}
              onRemove={(updatedValue) => handleImageRemove(key, updatedValue)}
              allowMultiple
              size={10}
            />
            <br />
          </>
        );
      }
      if (inputType === "file") {
        return (
          <>
            <TextField
              label={label}
              placeholder={placeholder}
              value={value}
              onChange={(updatedValue) => handleChange(key, updatedValue)}
            />
            <br />
          </>
        );
      }
    });
  };
  return (
    <Layout.AnnotatedSection
      title={t("sectionContent.manageVendor.customFields.title")}
      description={t("sectionContent.manageVendor.customFields.description")}
    >
      <Card
        title={[
          t("sectionContent.manageVendor.customFields.title"),
          lastUpdated && (
            <TextStyle variation="subdued">
              <Caption>{`Updated : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>
            </TextStyle>
          ),
        ]}
        actions={[
          {
            content: "Learn More",
            onAction: () => {
              learnMore(t("sectionContent.manageVendor.customFields.title"), "TODO:");
            },
          },
        ]}
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>As a seller, you can add custom fields to gather information from your vendor</TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

CustomFieldSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleImageRemove: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  learnMore: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
};

CustomFieldSection.defaultProps = {
  lastUpdated: "",
};

export default withFeature(CustomFieldSection, { feature: "advanceVendorCustomProfile" });

const privatePageContent = {
  activity: {
    breadcrumbs: [{ url: "/" }],
    pageClassName: "activity",
    title: "Activity ",
  },
  addPayment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Connect Stripe/PayPal",
  },
  addProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "Add Products",
  },
  addVariant: {
    breadcrumbs: [{ url: "/" }],
    title: "Add Variant",
  },
  addVendor: {
    breadcrumbs: [{ url: "/providers" }],
    title: "Add Vendors",
  },
  association: {
    breadcrumbs: [{ url: "/" }],
    title: "Create Association",
  },
  advanceShipping: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Advance Shipping",
  },
  bulkActivity: {
    breadcrumbs: [{ url: "/" }],
    pageClassName: "bulkActivity",
    title: "Bulk Activity ",
  },
  bulkProductEdit: {
    breadcrumbs: [{ url: "/" }],
    primaryAction: {
      content: "Add Product",
      id: "addProductPrimaryAction",
      url: "/products/add",
    },
    title: "Bulk Product Edit",
  },
  changePassword: {
    breadcrumbs: [{ url: "/" }],
    title: "Change Password",
  },
  commission: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Commission",
  },
  connectPayment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Connect Stripe/PayPal",
  },
  connectShop: {
    breadcrumbs: [{ url: "/" }],
    title: "Connect Shop",
  },
  contactUs: {
    breadcrumbs: [{ url: "/" }],
    title: "Contact Us",
  },
  customizeEmailTemplate: { breadcrumbs: [{ url: "/email-template" }], title: "Edit Email Template" },
  dashboard: {},
  discountSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Discount Setting",
  },
  domain: {
    breadcrumbs: [{ url: "/" }],
    title: "Edit Custom Domain",
  },
  downloadFile: {},
  editProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "Edit Product",
  },
  editProfile: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Edit Profile",
  },
  editVariant: {
    breadcrumbs: [{ url: "/" }],
    title: "Edit Variant",
  },
  emailTemplate: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Email Templates",
  },
  feature: {
    breadcrumbs: [{ url: "/" }],
    title: "Feature Panel",
  },
  fulfillment: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Fulfillment",
  },
  invitation: {
    pageClassName: "invitationList",
    title: "Invitations",
  },
  invoice: {
    breadcrumbs: [{ url: "/" }],
    title: "Invoice",
  },
  listSeller: {
    breadcrumbs: [{ url: "/" }],
    title: "Operators",
  },
  markupSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Markup Setting",
  },
  maintenance: {
    breadcrumbs: [{ url: "/" }],
    title: "Edit Maintenance",
  },
  manageCustomer: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Manage Customer Details",
  },
  muteNotification: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Mute Notification",
  },
  onboardActivity: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Onboard History",
  },
  orderExport: {},
  orders: {
    pageClassName: "ordersList",
    title: "Orders",
  },
  orderSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Order Setting",
  },
  orderSlip: {},
  payments: {
    title: "Payments",
    pageClassName: "payment",
  },
  payouts: {
    breadcrumbs: [{ url: "/payments" }],
    pageClassName: "automaticPayoutsList",
    title: "Automatic Payouts",
  },
  products: {
    title: "Products",
  },
  productAttachmentSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Product Attachment Setting",
  },
  productSetting: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Product Setting",
  },
  reviewProduct: {
    breadcrumbs: [{ url: "/" }],
    title: "Review Product",
  },
  session: {
    breadcrumbs: [{ url: "/" }],
    title: "User Session",
  },
  settings: {
    title: "Settings",
  },
  shipping: {
    breadcrumbs: [{ url: "/setting" }],
    pageClassName: "manageShipping",
    title: "Shipping",
  },
  shopifyCallback: {
    breadcrumbs: [{ url: "/" }],
    title: "",
  },
  shopifyProduct: {
    primaryAction: {
      content: "Add Products",
      id: "productsPrimaryAction",
      url: "/products/add",
    },
    title: "Shopify Product",
  },
  term: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Terms",
  },
  updatePlan: {
    breadcrumbs: [{ url: "/" }],
    title: "Update Plan",
  },
  vendors: {
    pageClassName: "providersList",
    title: "Vendors",
  },
  advanceVendorProfile: {
    breadcrumbs: [{ url: "/providers" }],
    title: "Advance Vendors",
  },
  viewActivity: {
    breadcrumbs: [{ url: "/" }],
    pageClassName: "viewActivity",
    title: "View Bulk Activity",
  },
  viewProduct: {
    title: "Edit Product",
  },
  viewVendor: { breadcrumbs: [{ url: "/providers" }], title: "Vendors Profile" },
  viewOrder: {
    breadcrumbs: [{ url: "/orders" }],
  },
  branding: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Branding",
  },
  manageVendor: {
    breadcrumbs: [{ url: "/setting" }],
    title: "Manage vendor Profile",
  },
  woocommerceProduct: {
    breadcrumbs: [{ url: "/products" }],
    title: "WooCommerce Products",
  },
};

export default privatePageContent;

const config = {
  "/orders": [
    {
      caption: "Export your orders",
      id: "exportOrder",
      label: "Export Order",
      link: "/orders",
    },
  ],
  "/payments": [
    {
      caption: "Export your payments",
      id: "exportPayment",
      label: "Export Payment",
      link: "/payments",
    },
    {
      caption: "Manage payouts",
      id: "automaticPayouts",
      label: "Automatic Payouts",
      link: "/payouts",
    },
  ],
  "/products": [
    {
      caption: "Add your products",
      id: "addProduct",
      label: "Add Products",
      link: "/products/add",
    },
  ],
  "/providers": [
    {
      caption: "Add your vendors",
      id: "addProvider",
      label: "Add Vendors",
      link: "/providers/add",
    },
  ],
};

export default config;

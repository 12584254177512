import React, { useContext, useState, useEffect } from "react";
import { Layout, TextContainer, Modal } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import component, constant and helpers
import { Banner, SkeletonAnnotated } from "lib/components";
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

// sub feature
import StandardDiscount from "./subFeature/standardDiscount";

// import yup validation
import validate from "./yup";

// GQL queries and mutation
import { STANDARD_DISCOUNT_SETTING } from "../../../../apollo/mutations";
import { STANDARD_DISCOUNT } from "../../../../apollo/queries";

const ProviderDiscount = () => {
  const { cms } = useContext(PrivateContext);
  const { FLAT, STANDARD_AMOUNT, PERCENTAGE, AMOUNT_LIMIT, gql, value: values } = constant;
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [standardType, setStandardType] = useState("");
  const [standardAmount, setStandardAmount] = useState("");
  const [isSubmitDisbled, setIsSubmitDisabled] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    message: "",
    status: "",
  });

  const { loading: standardDiscountLoading, data: standardDiscountData, error: standardDiscountError } = useQuery(
    STANDARD_DISCOUNT
  );
  const [setDiscountData, { loading: isLoadingLabel }] = useMutation(STANDARD_DISCOUNT_SETTING);

  const onSubmit = () => {
    setIsSubmitDisabled(false);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onFormSubmit = () => {
    setDiscountData({
      variables: {
        input: {
          type: standardType,
          price: parseInt(standardAmount, 10),
        },
      },
    })
      .then((res) => {
        setIsOpen(false);
        const responseData = baseHelper.getResponseData(res.data, gql.MANAGE_DISCOUNT);
        const responseError = baseHelper.getResponseError(res.data, gql.MANAGE_DISCOUNT);
        if (responseData) {
          setBanner({
            isOpen: true,
            title: `${cms("provider.message.success")}`,
            status: "success",
          });
        }
        if (responseError) {
          setBanner({
            isOpen: true,
            title: responseError,
            status: "critical",
          });
        }
      })
      .catch(() => {
        setBanner({
          isOpen: true,
          title: `${cms("common.message.error.somethingWentWrong")}`,
          status: "critical",
        });
        setIsOpen(false);
      });
  };

  const handleChange = (value) => {
    const isLessThanMaxFlat = standardType === FLAT && value <= values.MAX_FLAT;
    const isLessThanMaxPercentage = standardType === PERCENTAGE && value <= values.MAX_PERCENTAGE;
    if (isLessThanMaxFlat || isLessThanMaxPercentage) {
      setStandardAmount(value);
      setIsSubmitDisabled(true);
    }
  };

  const handleSelectChange = (value) => {
    // clears standard-amount when standard-type is changed
    setStandardAmount("");
    setStandardType(value);
    setIsSubmitDisabled(true);
  };

  const handleStandardValidate = async (field, value) => {
    let validationError = await validate(field, value, cms);
    const setType = standardType;
    if (!validationError && field === STANDARD_AMOUNT && setType === PERCENTAGE) {
      validationError = await validate(AMOUNT_LIMIT, value, cms);
    }
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const isDisabled = () => {
    const isFieldEmpty = !(standardType && standardAmount);
    let isError = false;
    if (errorMessage && errorMessage.standardAmount && errorMessage.standardAmount.trim() !== "") {
      isError = true;
    }
    return isFieldEmpty || isError;
  };

  const responseError = baseHelper.getResponseError(standardDiscountError, gql.GET_DISCOUNT);
  useEffect(() => {
    if (responseError) {
      setBanner({
        title: responseError,
        status: "critical",
        isOpen: true,
      });
    }
  }, [responseError]);

  const responseData = baseHelper.getResponseData(standardDiscountData, gql.GET_DISCOUNT);
  useEffect(() => {
    if (responseData) {
      const { price = "", type = "", updatedAt = "" } = responseData;
      setStandardAmount(String(price));
      setStandardType(type);
      setLastUpdated(updatedAt);
    }
  }, [responseData]);

  if (standardDiscountLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      <StandardDiscount
        fieldValue={{ standardType, standardAmount, lastUpdated }}
        handleChange={handleChange}
        handleSelectChange={handleSelectChange}
        handleValidate={handleStandardValidate}
        errorMessage={errorMessage}
        onSubmit={onSubmit}
        isDisabled={isDisabled}
        isSubmitDisbled={isSubmitDisbled}
      />
      <Modal
        open={isOpen}
        onClose={onClose}
        title={`${cms("provider.modal.button.title")}`}
        primaryAction={{
          content: `${cms("provider.modal.button.primary")}`,
          onAction: () => onFormSubmit(),
          loading: isLoadingLabel,
        }}
      >
        <Modal.Section>
          <TextContainer>{`${cms("provider.modal.content")}`}</TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderDiscount), { feature: constant.DISCOUNT_SETTING });

import { gql } from "apollo-boost";

export const GET_ALL_COUNTS = gql`
  query getCount {
    getCount {
      data {
        inviteCount {
          joined
          notJoined
          total
        }
        orderCount {
          approved
          new
          rejected
          shipped
          total
        }
        productCount {
          approved
          delete
          new
          reject
          review
          total
        }
        sellerCount {
          total
          approved
          new
        }
        vendorCount {
          approved
          new
          total
        }
        wooTempProductCount {
          product
        }
      }
      error
      status
    }
  }
`;

export const GET_PRODUCT_COUNTS = gql`
  query productCount {
    productCount {
      data {
        productCount {
          _id
          approved
          delete
          new
          reject
          review
          total
          user
        }
      }
      error
      status
    }
  }
`;

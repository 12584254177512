import React, { useContext } from "react";
import { Card, Layout, Checkbox, Stack, Badge, Icon, Collapsible, TextContainer } from "@shopify/polaris";

import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../../../context/context";

const Contact = () => {
  const { page, learnMore } = useContext(BrandingContext);
  const { currentUser = {}, cms = () => {} } = useContext(PrivateContext);
  const { plan } = currentUser;
  const isGrowthAndAbovePlan = baseHelper.isGrowthAndAbovePlan({ plan });
  const { isHideContact, handleChange } = page;
  const { IS_HIDE_CONTACT, CONTACT } = constant;

  const badge = (
    <Stack wrap={false}>
      <Badge status="warning">{cms("message.growthAndAbove")}</Badge>
      <Icon source="risk" color="orange" />
    </Stack>
  );

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.hideDetails.title")}
        description={cms("section.hideDetails.description")}
      >
        <Card
          title={cms("section.hideDetails.title")}
          actions={[{ content: "Learn more", onAction: () => learnMore(cms("section.hideDetails.title"), "TODO: ") }]}
        >
          <Card.Section>
            <TextContainer>{cms("section.hideDetails.description")}</TextContainer>
            <br />
            <Checkbox
              label={cms("section.hideDetails.label.hide")}
              checked={isHideContact}
              helpText={cms("section.hideDetails.label.description")}
              onChange={(value) => isGrowthAndAbovePlan && handleChange(IS_HIDE_CONTACT, value, CONTACT)}
              disabled={!isGrowthAndAbovePlan}
            />
            <span>{!isGrowthAndAbovePlan && badge}</span>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default Contact;

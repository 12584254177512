import React from "react";
import PropTypes from "prop-types";

import AdminQuickAction from "app/reports/admin/widgets/quickAction/quickAction";
import OperatorQuickAction from "app/reports/operator/widgets/quickAction/quickAction";
import ProviderQuickAction from "app/reports/provider/widgets/quickAction/quickAction";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

const { userKey } = constant;
const { operator, provider, admin } = userKey;

const QuickAction = (props) => {
  const { link = [] } = props;
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminQuickAction link={link} />;
    }
    if (role === operator) {
      return <OperatorQuickAction link={link} />;
    }
    if (role === provider) {
      return <ProviderQuickAction link={link} />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const QuickActionsComponent = getComponent(currentUserRole);
  return QuickActionsComponent;
};

QuickAction.propTypes = {
  link: PropTypes.arrayOf(PropTypes.object),
};

QuickAction.defaultProps = {
  link: [],
};

export default QuickAction;

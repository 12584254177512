import React from "react";
import { Card, FormLayout, TextField, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

const Inventory = (props) => {
  const { data, onChange } = props;
  const { sku, barCode, inventoryPolicy, quantity } = data;
  const isDontTrackPolicy = inventoryPolicy !== "shopify";

  const handleInventory = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleInventoryPolicy = (value) => {
    if (value === "dontTrack") {
      handleInventory("quantity", "");
    }
    handleInventory("inventoryPolicy", value);
  };

  return (
    <Card sectioned title="Inventory">
      <FormLayout>
        <TextField
          id="sku"
          label="SKU (Stock Keeping Unit)"
          value={sku}
          onChange={(value) => handleInventory("sku", value)}
          placeholder="0"
        />
        <TextField
          id="barCode"
          label="Barcode (ISBN, UPC, GTIN, etc.)"
          value={barCode}
          onChange={(value) => handleInventory("barCode", value)}
          placeholder="0"
        />
        <Select
          id="inventoryPolicy"
          label="Inventory Policy"
          options={[
            { label: "Don't track inventory", value: "dontTrack" },
            { label: "Shopify tracks this product's inventory", value: "shopify" },
          ]}
          onChange={handleInventoryPolicy}
          value={inventoryPolicy}
          placeholder="Please Select"
        />
        <TextField
          id="inventoryQuantity"
          label="Quantity"
          value={quantity}
          onChange={(value) => handleInventory("quantity", value)}
          placeholder="0"
          disabled={isDontTrackPolicy}
        />
      </FormLayout>
    </Card>
  );
};

Inventory.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Inventory;

// import packages
import React from "react";
import { Banner, Card, FormLayout, List, Select, TextField, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";

// constants
import constant from "lib/constant/constant";

const { ALLOWED_DAY, SELECTED_POLICY, RETURN_ALLOWED, NO_RETURN_ALLOWED } = constant;

const Returns = (props) => {
  const { cms = {} } = props;

  const standardLists = cms("section.onboardingTerms.returnSection.caption") || [];

  const renderCaptions = () => {
    const list = standardLists.map((content) => (
      <List.Item>
        <b>{content.title}</b> 
{' '}
{content.description}
      </List.Item>
    ));
    return list;
  };
  const returnOptions = [
    {
      label: cms("section.onboardingTerms.returnSection.label.allowed"),
      value: RETURN_ALLOWED,
    },
    {
      label: cms("section.onboardingTerms.returnSection.label.notAllowed"),
      value: NO_RETURN_ALLOWED,
    },
  ];
  const { data, handleChange, handleValidation, errorMessage, sheetToggle, sheetActive } = props;
  const isDisabled = data.selectedPolicy !== RETURN_ALLOWED;
  return (
    <Card title={cms("section.onboardingTerms.returnSection.title")}>
      <Card.Section>
        <TextContainer>{cms("section.onboardingTerms.returnSection.description")}</TextContainer>
        <br />
        <FormLayout>
          <FormLayout.Group>
            <Select
              id="returnValue"
              label={cms("section.onboardingTerms.returnSection.label.return")}
              placeholder={cms("section.onboardingTerms.returnSection.placeHolder.return")}
              onChange={(value) => handleChange(SELECTED_POLICY, value)}
              options={returnOptions}
              value={(data && data.selectedPolicy) || ""}
            />
            <TextField
              id="allowedDay"
              label={cms("section.onboardingTerms.returnSection.label.days")}
              placeholder={cms("section.onboardingTerms.returnSection.placeHolder.days")}
              suffix={(data.allowedDay && <div className="suffixColor">{constant.DAYS}</div>) || constant.DAYS}
              min={0}
              onChange={(value) => handleChange(ALLOWED_DAY, value)}
              onBlur={() => handleValidation(ALLOWED_DAY, data.allowedDay)}
              value={((data.allowedDay || data.allowedDay === 0) && data.allowedDay.toString()) || ""}
              disabled={isDisabled}
              error={errorMessage && errorMessage.allowedDay}
              type="number"
            />
          </FormLayout.Group>
        </FormLayout>
        <br />
        <Banner status="info">
          <p>{cms("section.onboardingTerms.options")}</p>
          <br />
          <p>
            <List type="bullet">{renderCaptions()}</List>
          </p>
        </Banner>
      </Card.Section>
    </Card>
  );
};

Returns.propTypes = {
  data: PropTypes.exact({
    selectedPolicy: PropTypes.string,
    allowedDay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  errorMessage: PropTypes.oneOfType([null, PropTypes.object]),
};

Returns.defaultProps = {
  errorMessage: null,
};

export default Returns;

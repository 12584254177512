import PropTypes from "prop-types";

const barGraphProp = {
  type: {
    data: PropTypes.array,
    xDataKey: PropTypes.string,
    barDataKey: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  },
};

const composedGraphProp = {
  type: {
    data: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
  },
};

const lineGraphProp = {
  type: {
    data: PropTypes.array,
    xDataKey: PropTypes.string,
    dataKey1: PropTypes.string,
    dataKey2: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  },
};

const pieGraphProp = {
  type: {
    data: PropTypes.array,
    dataKey: PropTypes.string,
    labelSuffix: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    cx: PropTypes.number,
    cy: PropTypes.number,
    outerRadius: PropTypes.number,
  },
};

const popoverProp = {
  type: {
    timePeriod: PropTypes.string,
    timeByOptions: PropTypes.array,
    handleTimePeriod: PropTypes.func,
    disabled: PropTypes.bool,
    selectedDates: PropTypes.object,
    setSelectedDates: PropTypes.func,
    text: PropTypes.string,
    submitReportingQuery: PropTypes.func,
  },
};

const reportingAutoCompleteProp = {
  type: {
    label: PropTypes.string,
    initOptions: PropTypes.array,
    allowMultiple: PropTypes.bool,
    listTitle: PropTypes.string,
    handleAutoComplete: PropTypes.func,
  },
};

const reportingFilterProp = {
  type: {
    setFilterBy: PropTypes.func,
    selectedFilterOption: PropTypes.string,
    setSelectedFilterOption: PropTypes.func,
    filterOptions: PropTypes.array,
    setFilterOptions: PropTypes.func,
    timePeriod: PropTypes.string,
    handleTimePeriod: PropTypes.func,
    setSelectedDates: PropTypes.func,
    selectedDates: PropTypes.object,
    reportingConfig: PropTypes.object,
    filterOptionsResponse: PropTypes.arrayOf(PropTypes.object),
    submitReportingQuery: PropTypes.func,
  },
};

const stackedBarGraphProp = {
  type: {
    data: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
  },
};

const tabProp = {
  type: {
    reportingConfig: PropTypes.object,
  },
};

export {
  barGraphProp,
  composedGraphProp,
  lineGraphProp,
  pieGraphProp,
  popoverProp,
  reportingAutoCompleteProp,
  reportingFilterProp,
  stackedBarGraphProp,
  tabProp,
};

// helper
import { yupHelper } from "lib/helpers";
// constant
import constant from "lib/constant/constant";
import { emailSchema, passwordSchema, confirmationPasswordSchema, acceptPolicySchema } from "./schema";

const { validateValue, handlePromiseError } = yupHelper;
const { EMAIL, PASSWORD, CONFIRM_PASSWORD, IS_ACCEPT_POLICY } = constant;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case EMAIL:
      promise = validateValue(emailSchema(cms), { email: value });
      break;
    case PASSWORD:
      promise = validateValue(passwordSchema(cms), { password: value });
      break;
    case CONFIRM_PASSWORD:
      promise = validateValue(confirmationPasswordSchema(cms), {
        password: value.password,
        confirmationPassword: value.confirmPassword,
      });
      break;
    case IS_ACCEPT_POLICY:
      promise = validateValue(acceptPolicySchema(cms), { acceptPolicy: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;

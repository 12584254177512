import { gql } from "apollo-boost";

const UPDATE_ORDER_SETTING = gql`
  mutation updateOrderSetting($input: UpdateOrderSetting) {
    updateOrderSetting(input: $input) {
      data {
        setting {
          isAcceptOrder
          isFulfillOrder
        }
      }
      status
      error
    }
  }
`;

const UPDATE_LIVE_ORDER_SETTING = gql`
  mutation disableOrderConnect($input: IsStoreConnected) {
    disableOrderConnect(input: $input) {
      data
      status
      error
    }
  }
`;

export { UPDATE_ORDER_SETTING, UPDATE_LIVE_ORDER_SETTING };

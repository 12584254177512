import { gql } from "apollo-boost";

const SYNC_INVENTORY_VENDOR = gql`
  mutation syncInventoryVendor {
    syncInventoryVendor {
      status
      data
      error
    }
  }
`;

const DISCONNECT_SHOP = gql`
  mutation disconnectShop($input: DisconnectShop) {
    disconnectShop(input: $input) {
      status
      data
      error
    }
  }
`;

const IS_STORE_CONNECTED = gql`
  mutation isStoreConnected($input: IsStoreConnected!) {
    isStoreConnected(input: $input) {
      status
      data {
        associated
        supplier
      }
      error
    }
  }
`;

const SYNC_WOOCOMMERCE_VENDOR_PRODUCT = gql`
  mutation syncWooCommerceVendorProduct {
    syncWooCommerceVendorProduct {
      status
      data
      error
    }
  }
`;

export { SYNC_INVENTORY_VENDOR, DISCONNECT_SHOP, IS_STORE_CONNECTED, SYNC_WOOCOMMERCE_VENDOR_PRODUCT };

import { gql } from "apollo-boost";

const GET_WOOCOMMERCE_TEMP_PRODUCT_SUBSCRIPTION = gql`
  subscription {
    tempWooCommerceProductList {
      data {
        operationType
        tempWooCommerceProduct {
            _id
            name
            image
            isShopifySync
            providerIds
            storeProductId
            status
            updatedAt
            createdAt
        }
      }
      error
      status
    }
  }
`;

export default GET_WOOCOMMERCE_TEMP_PRODUCT_SUBSCRIPTION;
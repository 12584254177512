import React from "react";
import PropType from "prop-types";
import { Spinner as PolarisSpinner } from "@shopify/polaris";

const Spinner = (props) => {
  const { isFullPage = false } = props;
  const styleClassName = isFullPage ? "fullPageSpinner" : "spinner";
  return (
    <div className={styleClassName}>
      <PolarisSpinner accessibilityLabel="Spinner" size="large" color="teal" />
    </div>
  );
};

Spinner.propTypes = {
  isFullPage: PropType.bool,
};
Spinner.defaultProps = {
  isFullPage: false,
};

export default Spinner;

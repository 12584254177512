const cmsVendorList = (t) => {
  const cmsData = {
    filterOptions: [
      { label: t("label.Destination"), value: "destination" },
      { label: t("label.product"), value: "product" },
    ],
    label: {
      show_all_vendor: t("label.shippingAdvance.showAllRule"),
      select_filter: t("label.providerList.selectFilter"),
      like: t("label.providerList.like"),
      filter_by: t("label.providerList.filterBy"),
      filter: t("label.providerList.filter"),
    },
  };

  return cmsData;
};

export default cmsVendorList;

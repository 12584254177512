import _ from "lodash";
import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Banner, DropZone as PolarisDropZone, Button, List, Stack, Thumbnail } from "@shopify/polaris";

const DropZoneFile = (props) => {
  const {
    allowMultiple = true,
    disabled,
    downloadExistingFile,
    existingFileList = [],
    fileList = [],
    id,
    label,
    loadingPosition = "",
    onAdd,
    onRemove,
    removeExistingFile,
  } = props;

  const [files, setFiles] = useState(fileList);
  const [existingFiles, setExistingFiles] = useState(existingFileList);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [hasError, setHasError] = useState(rejectedFiles.length > 0);

  const allowedTypes = [ "application/pdf", "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg", "image/jpg", "image/png", 
  "image/svg+xml" ];
  const filePlaceHolder = "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
  const allowedImageTypes = ["jpeg", "png", "jpg"];
  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  useEffect(() => {
    setExistingFiles(existingFileList);
  }, [existingFileList]);


  const handleDrop = useCallback(
    async (_droppedFiles, acceptedFiles, rejectedFiles) => {
      const promiseList = acceptedFiles.map(acceptedFile => {
        return new Promise((resolve, reject) => {
          const reader = new window.FileReader();
          reader.readAsDataURL(acceptedFile);
          reader.onloadend = () => {
            const result = reader.result || "";
            resolve(result);
          };
        });
      });
    const response = await Promise.all(promiseList);
      onAdd(response);
      setFiles([...files, ...response]);
      setRejectedFiles(rejectedFiles);
    },
    [files, onAdd]
  );

  const fileUpload = <PolarisDropZone.FileUpload />;

  const removeFile = (file, buttonIndex) => {
    const updatedFiles = [...files];
    const index = updatedFiles.indexOf(file);
    if (index < 0) {
      return;
    }
    onRemove(index, buttonIndex);
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setHasError(false);
    }
  };

  const isValidSize = file => {
    if (file.size >= props.size * 1024 * 1024) {
      return false;
    }
    return true;
  };

  const isTypeAllowed = file => {
    return allowedTypes.includes(file.type);
  };

  const customValidator = file => {
    const isValidFile = isValidSize(file) && isTypeAllowed(file);
    if (!isValidFile) {
      setHasError(!isValidFile);
    }
    return isValidFile;
  };

  const uploadedFiles = () => {
    if (files.length === 0 && existingFiles.length === 0) {
      return null;
    }
    return (
      <Stack>
        {existingFiles.map((file, index) => {
          const fileUrl = file.fileURL;
          const pos = _.isString(fileUrl) && fileUrl.length && fileUrl.lastIndexOf("/") + 1;
          const extension = fileUrl.substring(pos, fileUrl.length).split(".")[1];
          return (
            <Stack key={`dropZoneUploadedFile${index}`} alignment="center" vertical>
              <Stack.Item fill>
                <Thumbnail 
                  alt={`file${index}`}
                  size="large"
                  source={ allowedImageTypes.includes(extension) ? fileUrl : filePlaceHolder}
                />
              </Stack.Item>
              <Stack.Item>
                <Button
                  loading={index === loadingPosition}
                  disabled={loadingPosition && index !== loadingPosition}
                  onClick={() => removeExistingFile(file,index)}
                >
                  <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                </Button>
                <Button
                  loading={index === loadingPosition}
                  disabled={loadingPosition && index !== loadingPosition}
                  onClick={() => downloadExistingFile(file)}
                >
                  <i className="far fa-download fa-lg" style={{ color: "green" }} />
                </Button>
              </Stack.Item>
            </Stack>
          )
        })}
        {files.map((file, index) => {
          const isImage = file && _.isString(file) && file.length && file.includes(":image/"); 
          return (
            <Stack alignment="center" vertical key="uploadedFiles">
              <Stack.Item fill>
                <Thumbnail
                  size="large"
                  alt={isImage ? "image" : "file"}
                  source={isImage ? file : filePlaceHolder}
                />
              </Stack.Item>
             
              <Stack.Item>
                <Button onClick={() => removeFile(file, index)}>
                  <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                </Button>
              </Stack.Item>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const errorMessage = rejectedFiles.length && hasError && (
    <Banner title="The following files couldn’t be uploaded:" status="critical" onDismiss={() => setHasError(false)}>
      <List type="bullet">
        {rejectedFiles.map(file => (
          <List.Item>{`"${file.name}" is not supported. File type must be .pdf, .doc, .docx, .gif, .jpg, .png or .svg and should be less than ${props.size}MB.`}</List.Item>
        ))}
      </List>
    </Banner>
  );

  const isDropZoneShown = (!allowMultiple && files.length === 0) || allowMultiple;

  return (
    <Stack vertical>
      {errorMessage}
      {uploadedFiles()}
      {isDropZoneShown && (
        <PolarisDropZone
          id={id}
          type="file"
          onDrop={handleDrop}
          allowMultiple={allowedTypes}
          customValidator={customValidator}
          disabled={disabled}
          label={label}
        >
          {fileUpload}
        </PolarisDropZone>
      )}
    </Stack>
  );
};

DropZoneFile.propTypes = {
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  existingFileList: PropTypes.arrayOf(PropTypes.any),
  fileList: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string,
  label: PropTypes.string,
  loadingPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  removeExistingFile: PropTypes.func,
  size: PropTypes.number,  
};
DropZoneFile.defaultProps = {
  allowMultiple: false,
  disabled: false,
  existingFileList: [],
  fileList: [],
  id: "dropZone",
  loadingPosition: "",
  label: "",
  onAdd: () => {},
  onRemove: () => {},
  removeExistingFile: () => {},
  size: 1,
};

export default DropZoneFile;

import React from "react";
import { Button, ButtonGroup } from "@shopify/polaris";
import { actionProp } from "../propsType/index";

const Actions = (props) => {
  const { actions } = props;
  if (!(Array.isArray(actions) && actions.length)) return null;

  const filterAction = actions.filter((item) => item);
  const buttons = filterAction.map((item) => {
    const button = (
      <Button
        size="slim"
        destructive={item.destructive || false}
        disabled={item.disabled || false}
        loading={!!item.loading}
        onClick={() => item.onAction()}
        key={item.content}
      >
        {item.content}
      </Button>
    );

    return button;
  });
  return <ButtonGroup>{buttons}</ButtonGroup>;
};

Actions.propTypes = actionProp.type;

export default Actions;

import React, { useState, useEffect, useContext, useCallback } from "react";
import { Card, TextField, FormLayout, Stack, Button, Heading, Link, Checkbox } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import helpers
import baseHelper from "lib/helpers/base";

import { SkeletonAnnotated } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context/privateContext";

import { GET_USER } from "app/setup/apollo/queries";

// import validation
import config from "config";
import validate from "../yup";
import { PaymentContext } from "../context";

import { stripeTextFields } from "./fieldConfig";
// import gql query
import { CONNECT_STRIPE, DISCONNECT_STRIPE } from "../../../../../apollo/mutations";

const StripeSection = () => {
  const { gql, STRIPE } = constant;
  const { setBanner, setIsStripe } = useContext(PaymentContext);
  const { oldCMS } = useContext(PrivateContext);
  const { message, label } = oldCMS;
  const { data: getUserData, loading: getUserLoading } = useQuery(GET_USER);
  const [stripeKey, setStripeKey] = useState({});
  const [loadStripeData, { loading: stripeLoading }] = useMutation(CONNECT_STRIPE);
  const [disconnectFromStripe, { loading: disconnectStripeLoading }] = useMutation(DISCONNECT_STRIPE);
  const { clientId, secret } = stripeKey;
  const [isStripeConnected, setIsStripeConnected] = useState(!!clientId && secret);
  const [values, setValues] = useState({
    stripeClientId: isStripeConnected ? clientId : "",
    stripeSecret: isStripeConnected ? secret : "",
  });
  const [stripeUri, setStripeUri] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [button, setButton] = useState({
    stripeButtonDisabled: true,
  });
  const isStripeError = useCallback(() => Object.values(errorMessage).some((error) => error), [errorMessage]);
  const isStripeValuesFilled = useCallback(() => !!(values.stripeClientId && values.stripeSecret), [
    values.stripeClientId,
    values.stripeSecret,
  ]);

  useEffect(() => {
    if (getUserData) {
      const resData = baseHelper.getResponseData(getUserData, gql.GET_USER);
      const resError = baseHelper.getResponseError(getUserData, gql.GET_USER);
      if (resError) {
        setBanner({
          isOpen: true,
          status: "critical",
          title: resError,
        });
      }
      if (resData) {
        const stripeVales = resData.stripeKey;
        if (stripeVales) {
          setStripeKey(stripeVales);
          setIsStripeConnected(stripeVales.clientId && stripeVales.secret);
          setIsStripe(stripeVales.clientId && stripeVales.secret);
          setValues({ stripeClientId: stripeVales.clientId, stripeSecret: stripeVales.secret });
        }
      }
    }
  }, [getUserData, setBanner, gql.GET_USER, setIsStripe]);

  useEffect(() => {
    setButton((prevState) => ({
      ...prevState,
      stripeButtonDisabled: isStripeError() || !isStripeValuesFilled(),
    }));
  }, [errorMessage, isStripeConnected, isStripeError, isStripeValuesFilled]);

  const handleValueChange = (field, value) => {
    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleOnBlur = async (field, value) => {
    const error = await validate(field, value.trim());
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };
  const handleStripSubmit = () => {
    loadStripeData({ variables: { input: { clientId: values.stripeClientId, secret: values.stripeSecret } } })
      .then((res) => {
        const resData = baseHelper.getResponseData(res.data, gql.CONNECT_STRIPE);
        setIsStripeConnected(resData);
        setIsStripe(resData);
        let banner = { isOpen: true, title: label.connectedStripe, status: "success" };
        if (!resData) {
          const error = baseHelper.getResponseError(res.data, gql.CONNECT_STRIPE);
          banner = { isOpen: true, title: error, status: "critical" };
        }
        setBanner({ isOpen: banner.isOpen, title: banner.title, status: banner.status });
      })
      .catch(() => {
        setBanner({ isOpen: true, title: message.somethingWentWrong, status: "critical" });
      });
  };
  const handleStripeDisconnect = () => {
    disconnectFromStripe({ variables: { input: { paymentMethod: STRIPE } } })
      .then((res) => {
        const resData = baseHelper.getResponseData(res.data, gql.DISCONNECT_STRIPE);
        setIsStripeConnected(!resData);
        setIsStripe(!resData);
        let banner = { isOpen: true, title: message.disconnectStripeSuccessfully, status: "success" };
        if (!resData) {
          const error = baseHelper.getResponseError(res.data, gql.DISCONNECT_STRIPE);
          banner = { isOpen: true, title: error, status: "critical" };
        }
        setBanner({ isOpen: banner.isOpen, title: banner.title, status: banner.status });
      })
      .catch(() => {
        setBanner({ isOpen: true, title: message.somethingWentWrong, status: "critical" });
      });
  };

  if (getUserLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      <Card
        sectioned
        title={(!isStripeConnected && label.connectStripe) || (isStripeConnected && label.connectedStripe)}
      >
        <FormLayout>
          {!isStripeConnected && (
            <Stack.Item>
              <Heading>
                <span id="stripeStep1Heading">{label.step1}</span>
              </Heading>
              <p id="stripeClientAndSecret">{message.addClientIdSecretStripe}</p>
            </Stack.Item>
          )}
          {!isStripeConnected &&
            stripeTextFields.map((textField) => (
              <TextField
                id={textField.key}
                key={textField.key}
                label={textField.label}
                value={values[textField.key]}
                placeholder={textField.placeholder}
                onChange={(value) => handleValueChange(textField.key, value)}
                onBlur={() => handleOnBlur(textField.key, values[textField.key])}
                error={errorMessage && errorMessage[textField.key]}
              />
            ))}
          {isStripeConnected &&
            stripeTextFields.map((textField) => (
              <TextField
                id={textField.key}
                key={textField.key}
                label={textField.label}
                value={values[textField.key]}
                disabled={isStripeConnected}
              />
            ))}
          {!isStripeConnected && (
            <>
              <Stack.Item>
                <Heading>
                  <span id="stripeStep2Heading">{label.step2}</span>
                </Heading>
                <p id="stripeRedirectUri">
                  <Link id="stripeUrl" url={constant.STRIPE_DASHBOARD_URL} external>
                    {message.messageAdd}
                  </Link>
                  {message.addRedirectStripe}
                </p>
              </Stack.Item>
              <Checkbox
                id="stripeCheckbox"
                label={`${config.rootURL}/stripe/callback`}
                checked={stripeUri}
                onChange={() => setStripeUri(!stripeUri)}
              />
            </>
          )}
          <Stack>
            <Stack.Item fill />
            <Stack.Item>
              {!isStripeConnected && (
                <Button
                  id="stripeConnectButton"
                  primary
                  loading={stripeLoading}
                  disabled={button.stripeButtonDisabled || !stripeUri}
                  onClick={() => handleStripSubmit()}
                >
                  {label.connect}
                </Button>
              )}
              {isStripeConnected && (
                <Button
                  id="stripeDisconnectButton"
                  destructive
                  loading={disconnectStripeLoading}
                  onClick={() => handleStripeDisconnect()}
                >
                  {label.disconnect}
                </Button>
              )}
            </Stack.Item>
          </Stack>
        </FormLayout>
      </Card>
    </>
  );
};

export default StripeSection;

// import react packages;
import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// Import Polaris Components
import { TextField, Layout, Card, Stack, PageActions, TextContainer } from "@shopify/polaris";

// helper
import { PrivateContext, OnboardingContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { useTranslation } from "react-i18next";

import { IS_STORE_CONNECTED } from "app/products/apollo/mutations/connect";

import config from "config";
import { SyncProduct } from "../../../subFeatures";

// import components
import TermModal from "./subFeatureItems/term";
import ConnectedShop from "./subFeatureItems/connected";

// eslint-disable-next-line no-useless-escape
const subDomainRegex = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/;

const VendorConnectShopify = (props) => {
  const { history, currentUser: currentUserData } = useContext(PrivateContext);
  const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const { t } = useTranslation();
  const { setBanner } = props;
  const [shop, setShop] = useState((currentUserData && currentUserData.shop) || "");
  const [isConnected, setIsConnected] = useState(!!(currentUserData && currentUserData.shop));
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isStoreConnected, { loading }] = useMutation(IS_STORE_CONNECTED);
  const { IS_STORE_CONNECTED: STORE_CONNECTED } = constant.gql;

  const onSubmit = async () => {
    const isValidSubDomain = subDomainRegex.test(shop) && baseHelper.isValidUrl(`https://${shop}.myshopify.com`);
    if (!isValidSubDomain) {
      setBanner({ isOpen: true, status: "critical", title: t("message.ProductAdd.InvalidSubDomain") });
      setIsOpen(false);
      return;
    }
    try {
      const response = await isStoreConnected({
        variables: { input: { shop } },
      });
      const responseError = baseHelper.getResponseError(response.data, STORE_CONNECTED);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        setIsOpen(false);
        return;
      }
      const resData = baseHelper.getResponseData(response.data, STORE_CONNECTED);
      if (resData.supplier) {
        setIsOpen(false);
        setBanner({ isOpen: true, status: "critical", title: `${shop} ${t("message.ProductAdd.AlreadyConnected")}` });
        return;
      }
      if (resData.associated) {
        setIsOpen(false);
        setBanner({
          isOpen: true,
          status: "critical",
          title: `${t("message.ProductAdd.CannotSync")} ${shop} ${t("message.ProductAdd.AlreadyAssociated")}`,
        });
        return;
      }
      setTimeout(() => {
        const clientId = config.vendorShopifyAPIKey;
        const redirectUri = baseHelper.getCurrentDomain(window.location, "providers/shopify-callback");
        const scope =
          "read_products, write_products, read_customers, write_customers, read_orders, " +
          "write_orders, read_inventory, write_inventory, read_fulfillments, write_fulfillments, " +
          "read_shipping, write_shipping ";
        const url =
          `https://${shop}.myshopify.com` +
          `/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`;
        window.location.href = url;
      });
    } catch (error) {
      setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
    }
  };

  const handelChange = (value) => {
    setShop(value);
  };
  const handleTermModal = (value) => {
    if (!shop) {
      setIsOpen(false);
      setErrorMessage(t("message.ProductAdd.ShopRequired"));
      return;
    }
    if (value === constant.CONNECT) {
      setIsOpen(true);
    }
    setErrorMessage("");
  };

  return (
    <>
      <TermModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shop={shop}
        useTranslation={useTranslation}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
      />
      <Card title="Connect your shop">
        <Card.Section>
          <TextContainer>
            'Live Connect' allows you the ability to connect your store. This then makes it possible for you to manage
            your products and orders from within your own store. This also ensures that inventory, price and product
            changes as well as order updates are kept insync between your own store and the marketplace.
          </TextContainer>
          <br />
          <TextField
            label={`${t("label.ProductAdd.Shop")}*`}
            error={errorMessage}
            id="shopInput"
            type={constant.TEXT}
            suffix=".myshopify.com"
            value={shop}
            onChange={(value) => handelChange(value)}
            onBlur={handleTermModal}
            disabled={isConnected}
          />
          <br />
          {currentUserData.shop && isConnected && (
            <Stack>
              <Stack.Item fill>
                <ConnectedShop
                  useTranslation={useTranslation}
                  currentUserData={currentUserData}
                  setBanner={setBanner}
                  setIsConnected={setIsConnected}
                  setShop={setShop}
                />
              </Stack.Item>
              <Stack.Item>
                <SyncProduct history={history} setBanner={setBanner} />
              </Stack.Item>
            </Stack>
          )}
        </Card.Section>
      </Card>
      {!(currentUserData.shop && isConnected) && (
        <PageActions
          primaryAction={{
            id: "connectShop",
            content: t("label.ProductAdd.Connect"),
            onAction: () => handleTermModal(constant.CONNECT),
            disabled: isConnected,
          }}
          secondaryActions={[
            {
              id: "cancelAction",
              content: t("label.ProductAdd.Cancel"),
              onAction: () => (isOnboarding && onPrevious()) || history.push("/"),
            },
          ]}
        />
      )}
    </>
  );
};

VendorConnectShopify.propTypes = {
  setBanner: PropTypes.func,
};

VendorConnectShopify.defaultProps = {
  setBanner: () => {},
};

export default withFeature(VendorConnectShopify, { feature: constant.PRODUCT_FROM_SHOPIFY });

import React, { useContext, useState } from "react";
import { Card, Link, Collapsible, Caption, Layout, Stack, Heading, TextStyle, VideoThumbnail } from "@shopify/polaris";
import { TwoColumnLayout } from "layout/private/components";
import { QuickReport } from "app/reports";
import { PrivateContext } from "lib/context";
import { withErrorBoundary } from "lib/hoc";

import standardList from "./settingConfig";

const AdminSettings = () => {
  const { history } = useContext(PrivateContext);
  const [openSetup, setOpenSetup] = useState({});

  const handleSetupToggle = (key) => {
    setOpenSetup((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const borderSet = {
    border: "0",
  };

  const iconHeightFix = {
    lineHeight: "2.2em",
  };

  return standardList.map((settingList) => {
    return (
      <Layout.AnnotatedSection
        title={settingList.title}
        description={[
          <div>
            {settingList.isVideo && (
              <>
                <VideoThumbnail
                  videoLength={80}
                  thumbnailUrl="https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg"
                />
                <br />
              </>
            )}
            <TextStyle variation="subdued">{settingList.description}</TextStyle>
          </div>,
        ]}
      >
        {settingList.list.map((actionList) => {
          return (
            <Card>
              <Card.Header
                sectioned
                title={
                  <Stack>
                    <i className={actionList.icon} style={iconHeightFix} />
                    <Heading>
                      {actionList.title}
                      <TextStyle variation="subdued">
                        <Caption>{actionList.description}</Caption>
                      </TextStyle>
                    </Heading>
                  </Stack>
                }
                actions={[
                  {
                    content: openSetup[actionList.code] ? "Hide" : "Show",
                    onAction: () => handleSetupToggle(actionList.code),
                    disclosure: openSetup[actionList.code] ? "up" : "down",
                  },
                ]}
              />
              <br />
              <Collapsible open={openSetup[actionList.code]} id={`collapsible${actionList.code}`}>
                <Card.Section subdued>
                  {actionList.actions.map((step) => {
                    return (
                      <div style={borderSet}>
                        <Card.Section subdued>
                          <Stack>
                            <Stack.Item>
                              <Link url={step.link}>{step.label}</Link>
                              <TextStyle variation="">
                                <Caption>{step.description}</Caption>
                              </TextStyle>
                            </Stack.Item>
                          </Stack>
                        </Card.Section>
                      </div>
                    );
                  })}
                  {actionList.isVideo && (
                    <Layout.AnnotatedSection
                      title={actionList.thumbnailHeader}
                      description={(
                        <div>
                          <VideoThumbnail videoLength={80} thumbnailUrl={actionList.thumbnail} />
                          <br />
                          <TextStyle variation="">{actionList.thumbnailDescription}</TextStyle>
                        </div>
                      )}
                    />
                  )}
                </Card.Section>
              </Collapsible>
            </Card>
          );
        })}
      </Layout.AnnotatedSection>
    );
  });
};

export default withErrorBoundary(AdminSettings);

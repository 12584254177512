import React, { useState, useContext, useEffect } from "react";
import { Tabs } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { SkeletonAnnotated, Spinner } from "lib/components";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

// sub features
import { About, AddProvider, Commission, Finish, Terms, UpdateProfile, Welcome } from "./features";

import tabs from "./stepperOnboardingConfig";

const { onboardingSteps } = constant;
const { ABOUT, VENDOR, COMMISSION, FINISH, TERMS, WELCOME, UPDATE_PROFILE } = onboardingSteps;

const OperatorStepperOnboarding = () => {
  const { isLoading, setOnboardingTab } = useContext(PrivateContext);
  const initialSelectedTab = storageHelper.get("setupProgress") || "welcome";

  const indexToTabKeyMapper = {
    welcome: WELCOME,
    about: ABOUT,
    businessInfo: UPDATE_PROFILE,
    commission: COMMISSION,
    term: TERMS,
    vendor: VENDOR,
  };

  const findTab = (key) => tabs.find((tab) => tab.id === key);
  const tabKey = indexToTabKeyMapper[initialSelectedTab];
  const currentTab = findTab(tabKey);
  // states
  const [selected, setSelected] = useState(tabKey);
  const [selectedTabIndex, setSelectedTabIndex] = useState(currentTab && currentTab.tabIndex);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [selected]);

  const changeTab = (value) => {
    setSelected(value);
    const activeTab = findTab(value);
    setSelectedTabIndex(activeTab && activeTab.tabIndex);
    if (value !== FINISH) {
      setOnboardingTab(value);
    }
  };

  const getOnboardingStep = () => {
    if (isLoading) {
      if (selected === WELCOME || selected === FINISH) {
        return <Spinner />;
      }
      return <SkeletonAnnotated primaryLines={15} />;
    }
    switch (selected) {
      case WELCOME:
        return <Welcome setSelectedTab={changeTab} />;
      case ABOUT:
        return <About setSelectedTab={changeTab} />;
      case UPDATE_PROFILE:
        return <UpdateProfile setSelectedTab={changeTab} />;
      case COMMISSION:
        return <Commission setSelectedTab={changeTab} />;
      case TERMS:
        return <Terms setSelectedTab={changeTab} />;
      case VENDOR:
        return <AddProvider setSelectedTab={changeTab} />;
      case FINISH:
        return <Finish />;
      default:
        return null;
    }
  };

  const topMargin25px = {
    marginTop: "25px",
  };

  return (
    <>
      <Tabs tabs={tabs} selected={selectedTabIndex} fitted />
      <div style={topMargin25px}>{getOnboardingStep()}</div>
    </>
  );
};

export default OperatorStepperOnboarding;

import React, { useState, useCallback, useContext, useEffect } from "react";
import { Layout, Card, Checkbox, Banner, PageActions, TextField } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import context
import { PrivateContext } from "lib/context";

// helpers and component
import { baseHelper, errorHelper, hooksHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import gql
import { CUSTOMER } from "../../../../apollo/mutations";
import { GET_CUSTOMER } from "../../../../apollo/queries";

// import yup validation
import validate from "./yup";

import cmsData from "./cms/cms.json";

const ManageCustomerDetails = () => {
  const { useCMS } = hooksHelper;
  const cms = useCMS(cmsData);
  const history = useHistory();
  const { currentUser, isLoading } = useContext(PrivateContext);
  const { gql } = constant;
  const { _id: currentUserId = "" } = currentUser || {};
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [emailChecked, setEmailChecked] = useState(false);
  const [phoneChecked, setPhoneChecked] = useState(false);
  const [emailField, setEmailField] = useState("");
  const [phoneField, setPhoneField] = useState("");
  const [error, setError] = useState({});
  const [isAnyError, setIsAnyError] = useState(false);

  const { loading: customerLoading = false, error: customerError, data: customerData } = useQuery(GET_CUSTOMER, {
    variables: {
      input: { sellerId: currentUserId },
    },
  });

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setError((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const [customer, { loading: customerUpdateLoading }] = useMutation(CUSTOMER);

  useEffect(() => {
    if (customerData) {
      const resData = baseHelper.getResponseData(customerData, gql.GET_CUSTOMER);
      setEmailChecked(resData && resData.customer && resData.customer.email && !!resData.customer.email);
      setEmailField((resData && resData.customer && resData.customer.email) || "");
      setPhoneChecked(resData && resData.customer && resData.customer.phoneNumber && !!resData.customer.phoneNumber);
      setPhoneField((resData && resData.customer && resData.customer.phoneNumber) || "");
    }
  }, [customerData, gql.GET_CUSTOMER]);

  useEffect(() => {
    if (customerError) {
      setBannerStatus({ isOpen: true, status: "critical", title: errorHelper.parse(customerError) });
    }
  }, [customerError]);

  const handleChange = useCallback((newChecked, id) => {
    if (id === "email") {
      setEmailChecked(newChecked);
    }
    if (id === "email" && !newChecked) {
      setEmailField(null);
    }
    if (id === "phoneNumber") {
      setPhoneChecked(newChecked);
    }
    if (id === "phoneNumber" && !newChecked) {
      setPhoneField(null);
    }
    setError({});
  }, []);

  const acceptOnlyValidInput = (value) => {
    return baseHelper.validateWholeNumber(value) && value;
  };

  const onSubmit = async () => {
    if (emailChecked) {
      await handleValidation("email", emailField);
    }
    if (phoneChecked) {
      await handleValidation("phone", phoneField);
    }
    if ((emailChecked && emailField) || phoneChecked) {
      try {
        const inputData = {};
        if (emailChecked && emailField) {
          inputData.email = emailField;
        }
        if (phoneChecked && phoneField) {
          inputData.phoneNumber = phoneField;
        }
        const res = await customer({
          variables: {
            input: inputData,
          },
        });
        const resData = baseHelper.getResponseData(res.data, gql.CUSTOMER);
        const resError = baseHelper.getResponseError(res.data, gql.CUSTOMER);
        if (resError) {
          setBannerStatus({
            isOpen: true,
            status: "critical",
            title: resError,
          });
        }
        if (resData) {
          setBannerStatus({
            isOpen: true,
            status: "success",
            title: cms("section.message.success"),
          });
        }
      } catch (exception) {
        setBannerStatus({
          isOpen: true,
          status: "critical",
          title: errorHelper.parse(exception),
        });
      }
    }
  };

  useEffect(() => {
    const isAnyErrorValue = () => Object.values(error).some((err) => err);
    setIsAnyError(isAnyErrorValue());
  }, [error]);

  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  if (isLoading || customerLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <div>
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("section.title")} description={cms("section.description")}>
        <Card sectioned>
          <Checkbox
            id="email"
            label={cms("section.label.email")}
            helpText={cms("section.helpText.email")}
            checked={emailChecked}
            onChange={handleChange}
          />
          {emailChecked && (
            <div className="check-box">
              <TextField
                placeholder={cms("section.placeholder.email")}
                value={emailField}
                onChange={(value) => {
                  setEmailField(value);
                }}
                onBlur={() => handleValidation("email", emailField)}
                error={(error && error.email) || null}
              />
            </div>
          )}
          <Checkbox
            id="phoneNumber"
            label={cms("section.label.phone")}
            helpText={cms("section.helpText.phone")}
            checked={phoneChecked}
            onChange={handleChange}
          />
          {phoneChecked && (
            <div className="check-box">
              <TextField
                placeholder={cms("section.placeholder.phone")}
                value={phoneField}
                onChange={(value) => {
                  setPhoneField(acceptOnlyValidInput(value));
                }}
                onBlur={() => handleValidation("phone", phoneField)}
                error={(error && error.phone) || null}
              />
            </div>
          )}
          <br />
          <div className="manageCustomerButton">
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                onAction: () => onSubmit(),
                disabled: isAnyError,
                loading: customerUpdateLoading,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  onAction: () => history.push("/"),
                },
              ]}
            />
          </div>
        </Card>
      </Layout.AnnotatedSection>
    </div>
  );
};

export default withErrorBoundary(ManageCustomerDetails);

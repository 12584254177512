import React, { useContext } from "react";
import {
  Banner,
  Caption,
  Card,
  FormLayout,
  Layout,
  List,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { PrivateContext } from "lib/context";

// constants
import constant from "lib/constant/constant";

// import hoc
import { withFeature } from "lib/hoc";

// propsTypes
import { baseHelper } from "lib/helpers";
import { paymentProp } from "../../props";

const { PAYMENT_TERMS_DAY, SELECTED_PAYMENT, PRE_FULFILLMENT, POST_FULFILLMENT, POST_RETURN } = constant;

const Payment = (props) => {
  const { cms } = useContext(PrivateContext);
  const paymentOptions = [
    {
      label: cms("section.payment.label.preFulfilment"),
      value: PRE_FULFILLMENT,
    },
    {
      label: cms("section.payment.label.postFulfilment"),
      value: POST_FULFILLMENT,
    },
    {
      label: cms("section.payment.label.postReturnsExpiry"),
      value: POST_RETURN,
    },
  ];

  const label = [
    cms("section.payment.label.preFulfilment"),
    cms("section.payment.label.postFulfilment"),
    cms("section.payment.label.postReturnsExpiry"),
  ];
  const renderCaptions = (captions = []) => (
    <>
      {captions &&
        captions.map((caption, index) => (
          <List.Item key={caption}>
            <Caption>
              <b>{label[index]}</b>
              {` ${caption}`}
            </Caption>
          </List.Item>
        ))}
    </>
  );
  const { data, handleChange, handleValidation, errorMessage, learnMore, lastUpdated } = props;
  return (
    <div>
      <Layout.AnnotatedSection title={cms("section.payment.title")} description={cms("section.payment.description")}>
        <Card
          title={[
            cms("section.payment.title"),
            <TextStyle variation="subdued">
              {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
            </TextStyle>,
          ]}
          actions={[
            {
              content: cms("common.label.learnMore"),
              onAction: () => learnMore(cms("section.payment.title")),
            },
          ]}
          sectioned
        >
          <FormLayout>
            <TextContainer>{cms("section.payment.description")}</TextContainer>
            <Stack>
              <Select
                placeholder={cms("common.placeholder.type")}
                options={paymentOptions}
                id="paymentTermsDay"
                onChange={(value) => handleChange("type", value)}
                value={(data && data.type) || ""}
              />
              <TextField
                id="numberId"
                suffix={(data.days && <div className="suffixColor">{constant.DAYS}</div>) || constant.DAYS}
                min={0}
                placeholder={cms("common.placeholder.zero")}
                onChange={(value) => handleChange("days", value)}
                onBlur={() => handleValidation(PAYMENT_TERMS_DAY, data.paymentTermsDay)}
                value={(data && (data.days === 0 || data.days) && data.days.toString()) || ""}
                disabled={data.type === PRE_FULFILLMENT}
                error={errorMessage && errorMessage.paymentTermsDay}
                type="number"
              />
            </Stack>
            <Banner status="info">
              <p>{cms("common.label.option")}</p>
              <br />
              <List type="bullet">{renderCaptions(cms("section.payment.caption"))}</List>
            </Banner>
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </div>
  );
};
Payment.propTypes = paymentProp.type;
export default withFeature(Payment, { feature: constant.PAYMENT });

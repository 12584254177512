import cms from "../cms/paymentService";

export const stripeTextFields = [
  {
    id: "stripeClientId",
    label: cms.label.clientId,
    placeholder: cms.placeholder.clientIdPlaceholder,
    key: "stripeClientId",
  },
  {
    id: "stripeSecret",
    label: cms.label.secret,
    placeholder: cms.placeholder.secretPlaceholder,
    key: "stripeSecret",
  },
];

export const paypalTextFields = [
  {
    id: "paypalClientId",
    label: cms.label.clientId,
    placeholder: cms.placeholder.clientIdPlaceholder,
    key: "paypalClientId",
  },
  {
    id: "paypalSecret",
    label: cms.label.secret,
    placeholder: cms.placeholder.secretPlaceholder,
    key: "paypalSecret",
  },
];

import React, { useState, useEffect, useContext } from "react";
import { Layout, SettingToggle, TextStyle, Modal, TextContainer, Button, Card, Stack } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import config from "config";
// import hoc
import { PrivateContext } from "lib/context";
import { withFeature, withErrorBoundary } from "lib/hoc";

// import from lib
import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";

// import gql
import { UPDATE_ORDER_SETTING, UPDATE_LIVE_ORDER_SETTING } from "../../../../apollo";

const { gql, ACCEPTED_ORDER, FULFILLMENTS } = constant;

const ProviderOrderSetting = () => {
  const [updateProductSyncMutation] = useMutation(UPDATE_LIVE_ORDER_SETTING);
  const { currentUser, cms, history } = useContext(PrivateContext);
  const { _id: currentUserId, setting = {}, shop } = currentUser;
  const { isAcceptOrder, isFulfillOrder } = setting || {};
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isOrderSyncEnabled, setIsOrderSyncEnabled] = useState(currentUser.isOrderConnect);
  const isVendor = baseHelper.isVendor(currentUser);
  const isShopConnected = isVendor && currentUser.shop;
  const [toggleOrderStatus, setToggleOrderStatus] = useState(false);
  const [autoOrdersEnabled, setAutoOrdersEnabled] = useState(false);
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [setSettingOrder] = useMutation(UPDATE_ORDER_SETTING);
  const contentStatus = toggleOrderStatus
    ? cms("section.autoAccept.button.disable")
    : cms("section.autoAccept.button.enable");
  const textStatus = toggleOrderStatus
    ? cms("section.liveOrderInventory.label.enabled")
    : cms("section.liveOrderInventory.label.disabled");

  const contentLiveStatus = isOrderSyncEnabled
    ? cms("section.autoAccept.button.disable")
    : cms("section.autoAccept.button.enable");
  const textLiveStatus = isOrderSyncEnabled
    ? cms("section.autoAccept.button.enable")
    : cms("section.autoAccept.button.disable");
  const autoFullfillStatus = autoOrdersEnabled
    ? cms("section.autoFulfillOrders.button.disable")
    : cms("section.autoFulfillOrders.button.enable");
  const textAutoStatus = autoOrdersEnabled
    ? cms("section.autoAccept.label.enabled")
    : cms("section.autoAccept.label.disabled");
  const updateOrderSetting = (orderStatus, key = false) => {
    const requestData = {
      id: currentUserId,
    };
    requestData.isAcceptOrder = orderStatus;
    requestData.isFulfillOrder = autoOrdersEnabled;

    if (!orderStatus && key === ACCEPTED_ORDER) {
      requestData.isAcceptOrder = orderStatus;
      requestData.isFulfillOrder = orderStatus;
    }
    if (key === FULFILLMENTS) {
      requestData.isAcceptOrder = toggleOrderStatus;
      requestData.isFulfillOrder = orderStatus;
    }
    setButtonLoading(key);
    setSettingOrder({
      variables: { input: requestData },
    })
      .then((res) => {
        setButtonLoading(false);
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_ORDER_SETTING);
        let bannerValue = { status: "critical", title: errorResponse };
        if (!errorResponse) {
          const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_ORDER_SETTING);
          bannerValue = { status: "success", title: cms("message.success") };
          setToggleOrderStatus(responseData.setting.isAcceptOrder);
          setAutoOrdersEnabled(responseData.setting.isFulfillOrder);
        }
        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: true,
        });
      })
      .catch(() => {
        setButtonLoading(false);
        setBannerStatus({
          status: "critical",
          title: cms("common.message.error.somethingWentWrong"),
          isOpen: true,
        });
      });
  };
  const onSubmit = () => {
    const orderStatus = toggleOrderStatus;
    updateOrderSetting(!orderStatus, ACCEPTED_ORDER);
  };
  const onAutoSubmit = () => {
    const orderStatus = autoOrdersEnabled;
    updateOrderSetting(!orderStatus, FULFILLMENTS);
  };
  useEffect(() => {
    setToggleOrderStatus(isAcceptOrder);
  }, [isAcceptOrder]);
  useEffect(() => {
    if (isFulfillOrder) {
      setAutoOrdersEnabled(isFulfillOrder);
    }
  }, [isFulfillOrder]);
  let setButtonColor = false;
  let setFullFillButtonColor = false;
  if (autoOrdersEnabled) {
    setFullFillButtonColor = true;
  }
  if (toggleOrderStatus) {
    setButtonColor = true;
  }
  const toggleProductSync = async () => {
    if (isVendor && shop && !isOrderSyncEnabled) {
      const clientId = config.vendorShopifyAPIKey;
      const redirectUri = baseHelper.getCurrentDomain(window.location, constant.SUPPLIERS_SHOPIFY);
      const scope =
        "read_products, write_products, read_customers, write_customers, read_orders, write_orders, read_inventory, write_inventory, read_fulfillments, write_fulfillments, read_shipping, write_shipping ";
      const url = `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`;
      window.location.href = url;
    } else {
      setButtonLoading(true);
      await updateProductSyncMutation({
        variables: {
          input: { shop },
        },
      })
        .then((res) => {
          const errorResponse = baseHelper.getResponseError(res.data, gql.DISABLE_ORDER_CONNECT);
          let bannerValue = { status: "critical", title: errorResponse };
          if (!errorResponse) {
            bannerValue = { status: "success", title: cms("message.success") };
            setIsOrderSyncEnabled(!isOrderSyncEnabled);
          }
          setButtonLoading(false);
          setIsConfirmationModalVisible(false);
          setBannerStatus({
            status: bannerValue.status,
            title: bannerValue.title,
            isOpen: true,
          });
        })
        .catch(() => {
          setButtonLoading(false);
          setIsConfirmationModalVisible(false);
          setBannerStatus({
            status: "critical",
            title: cms("common.message.error.somethingWentWrong"),
            isOpen: true,
          });
        });
    }
  };
  const toggleConfirmationModal = () => {
    setIsConfirmationModalVisible(!isConfirmationModalVisible);
  };
  const renderConfirmationModal = () => {
    return (
      <Modal
        key={cms("modal.title")}
        open={isConfirmationModalVisible}
        onClose={toggleConfirmationModal}
        title={cms("modal.title")}
        primaryAction={{
          content: contentLiveStatus,
          onAction: toggleProductSync,
          loading: isButtonLoading,
          destructive: isOrderSyncEnabled,
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("modal.content")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  };
  return (
    <>
      {renderConfirmationModal()}
      <Layout.Section>
        <Banner
          isOpen={bannerStatus.isOpen}
          status={bannerStatus.status}
          title={bannerStatus.title}
          onDismiss={() => setBannerStatus({ title: "", status: "", isOpen: false })}
        />
      </Layout.Section>
      <br />
      <Layout.AnnotatedSection
        title={cms("section.liveOrderInventory.title")}
        description={cms("section.liveOrderInventory.description")}
      >
        <Layout.Section>
          {!isShopConnected && (
            <Card sectioned>
              <Stack>
                <Stack.Item fill>{cms("section.liveOrderInventory.content.connectShop")}</Stack.Item>
                <Stack.Item>
                  <Button onClick={() => history.push(constant.CONNECT_SHOP_PAGE)}>
                    {cms("section.liveOrderInventory.button.connect")}
                  </Button>
                </Stack.Item>
              </Stack>
            </Card>
          )}
          {isShopConnected && (
            <SettingToggle
              action={{
                content: contentLiveStatus,
                onAction: () => (isOrderSyncEnabled ? toggleConfirmationModal() : toggleProductSync()),
                destructive: isOrderSyncEnabled,
              }}
              enabled={isOrderSyncEnabled}
            >
              {cms("section.liveOrderInventory.content.liveOrderInventory")}
              <TextStyle variation="strong">{` ${textLiveStatus.toLowerCase()}`}</TextStyle>
            </SettingToggle>
          )}
        </Layout.Section>
      </Layout.AnnotatedSection>
      <br />
      <Layout.AnnotatedSection
        title={cms("section.autoAccept.title")}
        description={cms("section.autoAccept.description")}
      >
        <Layout.Section>
          <SettingToggle
            action={{
              id: "submit",
              content: contentStatus,
              onAction: () => onSubmit(),
              destructive: setButtonColor,
              loading: isButtonLoading === ACCEPTED_ORDER,
            }}
            enabled={setButtonColor}
          >
            {cms("section.autoAccept.title")}
            <TextStyle variation="strong">{` ${textStatus}`}</TextStyle>
          </SettingToggle>
        </Layout.Section>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={cms("section.autoFulfillOrders.title")}
        description={cms("section.autoFulfillOrders.description")}
      >
        <Layout.Section>
          <SettingToggle
            action={{
              id: "submit",
              content: autoFullfillStatus,
              onAction: () => onAutoSubmit(),
              disabled: !toggleOrderStatus,
              destructive: setFullFillButtonColor,
              loading: isButtonLoading === FULFILLMENTS,
            }}
            enabled={setFullFillButtonColor}
          >
            {cms("section.autoFulfillOrders.content")}
            <TextStyle variation="strong">{` ${textAutoStatus}`}</TextStyle>
          </SettingToggle>
        </Layout.Section>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderOrderSetting), { feature: constant.ORDER_SETTING });

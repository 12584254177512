// import packages
import React, { useContext, useState } from "react";
import { Layout } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import components
import { Banner } from "lib/components";
import { PaymentProvider } from "../context";
import AllowedCurrency from "./allowedCurrency";
import AutomaticPayout from "./automaticPayout";

const ConnectPaymentService = () => {
  const { t } = useTranslation();
  const { oldCMS } = useContext(PrivateContext);
  const { sectionContent, sectionLabel } = oldCMS;
  const isAllowedCurrency = true;
  const [isStripe, setIsStripe] = useState(false);
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  return (
    <>
      {!isAllowedCurrency && (
        <Layout.Section>
          <Banner isOpen title={t("message.automaticPayout.defaultBanner")} status="warning" />
        </Layout.Section>
      )}
      <br />
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            title={banner.title}
            status={banner.status}
            onDismiss={() => setBanner({ isOpen: !banner.isOpen })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={sectionLabel.connectPaymentAccount}
        description={sectionContent.connectPaymentAccount}
      >
        <PaymentProvider value={{ setBanner, isAllowedCurrency, setIsStripe }}>
          <AllowedCurrency />
        </PaymentProvider>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={t("sectionContent.automaticPayout.title")}
        description={t("sectionContent.automaticPayout.description")}
      >
        <PaymentProvider value={{ setBanner, isStripe }}>
          <AutomaticPayout />
        </PaymentProvider>
      </Layout.AnnotatedSection>
    </>
  );
};
export default ConnectPaymentService;

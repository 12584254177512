import React from "react";
import PropTypes from "prop-types";
import { Modal, Stack, TextContainer } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

const EmailChangeModal = (props) => {
  const { isOpen, onClose, onSubmit, isLoading } = props;
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t("message.updateVendorProfile.emailChanged")}
      primaryAction={{
        content: t("button.updateVendorProfile.updateEmailModal.primaryAction"),
        onAction: onSubmit,
        loading: isLoading,
      }}
      secondaryActions={[
        {
          content: t("common.Cancel"),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>
            <TextContainer>
              <p>{t("message.updateVendorProfile.updateEmailText")}</p>
            </TextContainer>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

EmailChangeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EmailChangeModal;

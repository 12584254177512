// packages
import React, { useState, useEffect, useContext } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

import {
  Card,
  Checkbox,
  FormLayout,
  InlineError,
  Layout,
  Link,
  PageActions,
  Stack,
  TextField,
  Page,
  EmptyState,
} from "@shopify/polaris";

// subFeatures
import { PrivacyPolicy, TermsPolicy } from "app/public/modules/register/subFeatures";

// yup validation
import validate from "app/public/modules/register/yup/index";

// constant
import constant from "lib/constant/constant";

// helpers
import { baseHelper, storageHelper } from "lib/helpers";

// helper components
import { Banner, SkeletonAnnotated } from "lib/components";

// gql
import { REGISTER, REGISTER_BY_INVITE } from "app/public/apollo/mutations/index";
import { BULK_INVITED_VENDOR_DATA } from "app/public/apollo/queries";

// context
import { PublicContext } from "lib/context";
import config from "config";

const Register = () => {
  const { history, cms } = useContext(PublicContext);
  const {
    gql,
    EMAIL,
    PASSWORD,
    CONFIRM_PASSWORD,
    IS_ACCEPT_POLICY,
    PRIVACY,
    TERMS,
    POLICY_LINK,
    TERMS_LINK,
  } = constant;
  const { store = "", seller, id, token } = baseHelper.queryParamsFromLocation(history);
  const isInviteRegisterPage = !!store;
  const url = baseHelper.parseUrl(config.rootURL);
  const isCustomDomain = window.location.hostname !== url.hostname;
  // states
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    isAcceptPolicy: false,
  });
  const termsLink = storageHelper.get(TERMS_LINK);
  const policyLink = storageHelper.get(POLICY_LINK);
  const [errorMessage, setErrorMessage] = useState(false);
  const [submitButton, setSubmitState] = useState({
    isReadyToSubmit: false,
  });
  const [modal, setModal] = useState({
    privacy: false,
    terms: false,
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    message: "",
  });
  const [registerInfo, setRegisterInfo] = useState({
    title: "",
    description: "",
  });
  const [isEmail, setIsEmail] = useState(false);

  const [sellerInviteData, setSellerInviteData] = useState(null);
  const [inviteData, setInviteData] = useState(null);

  const [checkInvitedVendor, { data: InvitedVendorData, loading: invitedDataLoading }] = useLazyQuery(
    BULK_INVITED_VENDOR_DATA,
    {
      variables: { input: { id: baseHelper.mongoIdAsString(id) } },
    }
  );
  useEffect(() => {
    if (id) checkInvitedVendor();
  }, [checkInvitedVendor, id]);

  useEffect(() => {
    if (InvitedVendorData) {
      const resData = baseHelper.getResponseData(InvitedVendorData, gql.GET_BULK_INVITED_USER);
      const resError = baseHelper.getResponseError(InvitedVendorData, gql.GET_BULK_INVITED_USER);
      if (resData) {
        const { seller: sellerInvitedData, invite } = resData;
        setSellerInviteData(sellerInvitedData);
        setInviteData(invite);
        setIsEmail(!!(invite && invite.email));
        setValues({ email: invite && invite.email });
      }
      if (resError) {
        setBanner({ isOpen: true, status: "critical", title: resError });
      }
    }
  }, [InvitedVendorData, gql.GET_BULK_INVITED_USER]);
  const [registerUser, { loading }] = useMutation(REGISTER);
  const [registerInvitedUser, { loading: invitedUserRegisterLoading }] = useMutation(REGISTER_BY_INVITE);

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  useEffect(() => {
    const isAnyValidationError =
      errorMessage && !!(errorMessage.email || errorMessage.password || errorMessage.confirmPassword);
    const isAllValuesFilled = values.email && values.password && values.confirmPassword && values.isAcceptPolicy;
    setSubmitState((prevState) => ({
      ...prevState,
      isReadyToSubmit: isAllValuesFilled && !isAnyValidationError,
    }));
  }, [values, errorMessage]);

  useEffect(() => {
    if (values.password && values.confirmPassword) {
      handleValidation(CONFIRM_PASSWORD, {
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
    }
  }, [values.password, CONFIRM_PASSWORD, values.confirmPassword]);

  const handleRegisterInfo = (title, description) => {
    if ((title && title !== "") || (description && description !== "")) {
      setRegisterInfo({
        title,
        description,
      });
    }
  };
  const { title: defaultRegisterTitle, description: defaultRegisterDescription } = registerInfo;
  const registerValue = storageHelper.get("register");
  if (registerValue && (defaultRegisterTitle === "" || defaultRegisterDescription === "")) {
    const { title, desc } = registerValue;
    handleRegisterInfo(title, desc);
  }

  const handleChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const redirectToLogin = () => {
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  };

  const onSubmit = async () => {
    let bannerData = {};
    try {
      await handleValidation(EMAIL, values.email);
      await handleValidation(PASSWORD, values.password);
      await handleValidation(CONFIRM_PASSWORD, {
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
      await handleValidation(IS_ACCEPT_POLICY, values.isAcceptPolicy);

      if (!submitButton.isReadyToSubmit) {
        return false;
      }
      let res = {};
      const inputData = {
        email: values.email,
        password: values.password,
      };
      let registerMutation = gql.REGISTER;
      if (isInviteRegisterPage || isCustomDomain) {
        registerMutation = gql.REGISTER_BY_INVITE;
        if (store) inputData.shop = store;
        if (seller || isCustomDomain) inputData.seller = storageHelper.get("sellerId") || seller;
        if (token) inputData.associatedToken = token;
        if (sellerInviteData && sellerInviteData.brandName) inputData.brandName = sellerInviteData.brandName;
        res = await registerInvitedUser({
          variables: { input: inputData },
        });
      }
      if (!(isInviteRegisterPage || isCustomDomain)) {
        res = await registerUser({
          variables: { input: inputData },
        });
      }
      const responseData = baseHelper.getResponseData(res.data, registerMutation);
      const errorData = baseHelper.getResponseError(res.data, registerMutation);
      bannerData = {
        isOpen: true,
        status: "success",
        title: isInviteRegisterPage ? cms("message.success.registered") : cms("message.success.registerSuccessfully"),
      };

      if (!responseData && errorData) {
        bannerData = { isOpen: true, status: "critical", title: errorData };
      }

      if (bannerData) setBanner(bannerData);
      if (!errorData) {
        redirectToLogin();
      }
    } catch (err) {
      bannerData = { isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") };
      setBanner(bannerData);
    }
    return false;
  };

  const toggleModal = (modalType) => {
    if (modalType === PRIVACY && policyLink) {
      window.open(policyLink);
      return;
    }
    if (modalType === TERMS && termsLink) {
      window.open(termsLink);
      return;
    }
    setModal((prevState) => ({
      ...prevState,
      [modalType]: !prevState[modalType],
    }));
  };

  if (invitedDataLoading) {
    return <SkeletonAnnotated />;
  }

  if (inviteData && inviteData.status === "revoked") {
    return (
      <Page>
        <EmptyState
          heading="Sorry"
          action={{
            content: "Back",
            onAction: () => history.push("/"),
          }}
          secondaryAction={{
            content: "Learn more about MarketCube",
            url: "http://marketcube.io",
          }}
        >
          <p>{`Your ${store} has cancelled the invitation, please contact your seller`}</p>
        </EmptyState>
      </Page>
    );
  }

  const policyLabel = cms("label.policyTerms").map((item) => {
    if (item && item.type && item.type === "action") {
      return (
        <Link key={item.content} plain id="linkTerms" onClick={() => toggleModal(item.key)}>
          {item.content}
        </Link>
      );
    }
    return <React.Fragment key={item.content}>{item.content}</React.Fragment>;
  });

  return (
    <>
      <Banner
        id="registerBanner"
        isOpen={banner.isOpen}
        status={banner.status}
        title={banner.title}
        onDismiss={() => setBanner({ isOpen: false })}
      >
        {banner.message}
      </Banner>
      <PrivacyPolicy isOpen={modal.privacy} onClose={() => toggleModal(PRIVACY)} />
      <TermsPolicy isOpen={modal.terms} onClose={() => toggleModal(TERMS)} />
      <br />
      <Layout>
        <Layout.AnnotatedSection
          title={registerInfo.title || cms("title")}
          description={registerInfo.description || cms("description")}
        >
          <Card sectioned>
            <FormLayout>
              <TextField
                id="email"
                label={`${cms("common.label.email")}*`}
                type="email"
                value={values.email || ""}
                placeholder={cms("common.placeholder.emailAddress")}
                onChange={(value) => handleChange(EMAIL, value)}
                onBlur={() => handleValidation(EMAIL, values.email)}
                disabled={isInviteRegisterPage || isEmail}
                error={errorMessage && errorMessage.email}
              />
              <TextField
                id="password"
                label={`${cms("common.label.password")}*`}
                placeholder={cms("common.placeholder.password")}
                type="password"
                value={values.password || ""}
                onChange={(value) => handleChange(PASSWORD, value)}
                onBlur={() => handleValidation(PASSWORD, values.password)}
                error={errorMessage && errorMessage.password}
              />
              <TextField
                id="confirmPassword"
                label={`${cms("common.label.confirmPassword")}*`}
                placeholder={cms("common.placeholder.confirmPassword")}
                type="password"
                value={values.confirmPassword || ""}
                onChange={(value) => handleChange(CONFIRM_PASSWORD, value)}
                onBlur={() => {
                  handleValidation(CONFIRM_PASSWORD, {
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                  });
                }}
                error={errorMessage && errorMessage.confirmPassword}
              />
              <Stack wrap spacing="extraTight">
                <Checkbox
                  id="privacyPolicyTermsOfUse"
                  checked={values.isAcceptPolicy}
                  onChange={(value) => handleChange(IS_ACCEPT_POLICY, value)}
                  onBlur={() => handleValidation(IS_ACCEPT_POLICY, values.isAcceptPolicy)}
                />
                <div className="checkBoxLabel">
                  <span>{policyLabel}</span>
                </div>
              </Stack>
              <InlineError message={errorMessage && errorMessage.isAcceptPolicy} fieldID="privacyPolicyTermsOfUse" />
            </FormLayout>
          </Card>
          <PageActions
            primaryAction={{
              id: "submitButton",
              content: cms("button.primary"),
              onAction: () => onSubmit(),
              disabled: !submitButton.isReadyToSubmit,
              loading: loading || invitedUserRegisterLoading,
            }}
            secondaryActions={[
              {
                id: "cancelButton",
                content: cms("common.button.cancel"),
                onAction: () => history.push("/register-role"),
              },
            ]}
          />
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
};

export default Register;

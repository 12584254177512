import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-apollo";
import { Tabs, Card, Layout } from "@shopify/polaris";

// import hoc
import { PrivateContext } from "lib/context";
import { withFeature, withErrorBoundary } from "lib/hoc";

// import form lib
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// layout component
import { TwoColumnLayout } from "layout/private/components";

// lib components
import { ResourceList, Banner } from "lib/components";

// report components
import { QuickAction, QuickReport } from "app/reports";

// import GQL
import { GET_PRODUCT_LIST } from "app/products/apollo/queries";
import { BULK_PRODUCT } from "app/products/apollo/mutations";
import productListCMS from "app/products/modules/generic/feature/list/cms/productListCMS";

// import subFeatures.
import { RejectProduct, DeleteProduct, ProductListView } from "app/products/modules/generic/feature/list";
import MoreFilters, { Filter } from "app/products/modules/generic/moreFilters/moreFilter";
import { SUBSCRIPTION_BY_OPERATOR } from "app/products/apollo/subscriptions/productList";
import { SyncProduct } from "./subFeature";

const OperatorProductList = () => {
  const { history } = useContext(PrivateContext);
  const prevAppliedFilter = baseHelper.queryParamsFromLocation(history);
  const [selected, setSelected] = useState(parseInt(prevAppliedFilter.tab, 10) || 0);
  const selectedSort = prevAppliedFilter.sort_name && `${prevAppliedFilter.sort_name}_${prevAppliedFilter.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || "createdAt_desc");
  const [productList, setProductList] = useState(false);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevAppliedFilter.perPage, 10) || 10);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(parseInt(prevAppliedFilter.page, 10) || 1);
  const [queryValue, setQueryValue] = useState(prevAppliedFilter.search || null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [singleId, setSingleId] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [primaryActionTitle, setPrimaryActionTitle] = useState(null);
  const [secondaryActionsTitle, setSecondaryActionsTitle] = useState(null);
  const [keyName, setKeyName] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(prevAppliedFilter.list_filter || null);
  const [taggedWith, setTaggedWith] = useState(prevAppliedFilter.list_search || null);
  const [defaultBanner, setDefaultBanner] = useState(true);
  const [activePopover, setActivePopover] = useState({});
  const [active, setActive] = useState({});
  const { t } = useTranslation();
  const { tabs, sortOptions, message, resourceName } = productListCMS(t);

  const [dataToFetch, setDataToFetch] = useState({
    search: prevAppliedFilter.search,
    list_filter: prevAppliedFilter.list_filter,
    list_search: prevAppliedFilter.list_search,
    filter: prevAppliedFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevAppliedFilter.sort_name || "createdAt",
    sort_order: prevAppliedFilter.sort_order || "desc",
  });
  const [banner, setBanner] = useState({
    isOpen: true,
    title: message.defaultBanner.title,
    status: "info",
  });
  const [resBanner, setResBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });
  const inputData = {
    search: dataToFetch.search,
    list_filter: dataToFetch.list_filter,
    list_search: dataToFetch.list_search,
    filter: dataToFetch.filter,
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
  };
  // GQL:Operations
  const {
    error: errorProductList,
    loading: loadingProductList,
    data: dataProductList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_PRODUCT_LIST, {
    variables: {
      input: inputData,
    },
  });

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  useEffect(() => {
    if (message.defaultBanner.title && defaultBanner) {
      setBanner((preState) => ({
        ...preState,
        title: message.defaultBanner.title,
      }));
      setDefaultBanner(false);
    }
  }, [defaultBanner, message]);

  const handleDeleteModal = (eventKey, id = false) => {
    if (eventKey === constant.DELETE.toLowerCase() && id) {
      setSingleId(id);
      setOpenDeleteModal(true);
      return;
    }
    setSingleId(null);
    setOpenDeleteModal(true);
  };
  const handleRejectModal = (eventKey, id = false) => {
    setSingleId(null);

    if (
      (eventKey === constant.REJECT ||
        eventKey === constant.UNREJECT ||
        eventKey === constant.UNDELETE ||
        eventKey === constant.RE_SUBMIT) &&
      id
    ) {
      setSingleId(id);
    }
    switch (eventKey) {
      case constant.REJECT:
        setModalTitle(t("label.productList.rejectProducts"));
        setPrimaryActionTitle(t("label.productList.reject"));
        setSecondaryActionsTitle(t("label.productList.donReject"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      case constant.UNREJECT:
        setModalTitle(t("label.productList.unRejectProducts"));
        setPrimaryActionTitle(t("label.productList.unReject"));
        setSecondaryActionsTitle(t("label.productList.cancel"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      case constant.UNDELETE:
        setModalTitle(t("label.productList.unDeleteProducts"));
        setPrimaryActionTitle(t("label.productList.unDelete"));
        setSecondaryActionsTitle(t("label.productList.cancel"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      case constant.RE_SUBMIT:
        setModalTitle(t("label.productList.reSubmitProducts"));
        setPrimaryActionTitle(t("label.productList.reSubmit"));
        setSecondaryActionsTitle(t("label.productList.cancel"));
        setKeyName(eventKey);
        setSelectedButton(eventKey);
        setOpenRejectModal(true);
        break;
      default:
        break;
    }
  };

  const bulkAction = async (eventKey, deleteFromShopify = false, id = false, reason = "", buttonSelected = null) => {
    const { location } = history;
    const { pathname } = location;
    if (
      pathname.includes(constant.PRODUCT_URL) &&
      !pathname.includes(constant.PRODUCT_URL_SHOPIFY) &&
      eventKey === constant.BULK_EDIT
    ) {
      history.push(constant.PRODUCT_URL_EDIT, { selectedItems });
      return;
    }
    let formData = {};
    formData = {
      key: eventKey,
      ids: selectedItems,
      all: totalProductCount === selectedItems.length,
      deleteFromShopify,
      reason,
    };
    if (id) {
      formData.ids = [id];
      formData.all = false;
      setListLoading(`${eventKey}_${id}`);
    }
    setSelectedButton(buttonSelected);
    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        setOpenRejectModal(false);
        setSelectedItems([]);
        setKeyName(null);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.BULK_PRODUCT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_PRODUCT);
          if (responseData) {
            const title = t("message.productList.requestSuccess");
            setResBanner({ isOpen: true, title, status: "success" });
          }
          if (responseDataError) {
            setResBanner({ isOpen: true, title: responseDataError, status: "critical" });
          }
        }
      })
      .catch((err) => {
        setListLoading(false);
        setSingleId(null);
        setSelectedButton(null);
        setOpenDeleteModal(false);
        if (err) {
          setResBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
        }
      });
  };

  const handleTabChange = (selectedTabIndex) => {
    const tabName = tabs[selectedTabIndex].value;
    setSelected(selectedTabIndex);
    baseHelper.setUrl(history, { filter: tabName, tab: selectedTabIndex });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      setSortValue(selectedItem);
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      const filterApplied = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filterApplied });
    }
  };

  useEffect(() => {
    setSelectedItems([]);
    const productListResponseResponseData = baseHelper.getResponseData(dataProductList, constant.gql.GET_PRODUCT_LIST);
    const productListResponseResponseError = baseHelper.getResponseError(
      dataProductList,
      constant.gql.GET_PRODUCT_LIST
    );
    if (productListResponseResponseData) {
      setProductList(productListResponseResponseData.productList);
      setTotalProductCount(productListResponseResponseData.count);
    }
    if (productListResponseResponseError) {
      setListLoading(false);
      setResBanner({ status: "critical", title: productListResponseResponseError, isOpen: true });
    }
  }, [dataProductList]);

  useEffect(() => {
    if (errorProductList) {
      setListLoading(false);
      setResBanner({ status: "critical", title: errorHelper.parse(errorProductList), isOpen: true });
    }
  }, [errorProductList, t]);

  // Subscription for provider list Page.
  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIPTION_BY_OPERATOR,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: value });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handlePage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleQueryValueChange = (value) => {
    setQueryValue(value);
    baseHelper.setUrl(history, { search: value });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  const handleQueryValueRemove = () => {
    setQueryValue(null);
    baseHelper.setUrl(history, { search: "" });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  const handleTaggedWithRemove = () => {
    setTaggedWith("");
    baseHelper.setUrl(history, { list_search: "", page: 1 });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleSelectedFilterRemove = () => {
    setSelectedFilter("");
    setTaggedWith("");
    baseHelper.setUrl(history, { list_search: "", list_filter: "", page: 1 });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleClearAll = () => {
    handleQueryValueRemove();
    handleTaggedWithRemove();
    handleSelectedFilterRemove();
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }
  const appliedFilters = [];
  if (!isEmpty(taggedWith)) {
    appliedFilters.push({
      key: "taggedWith",
      label: disambiguateLabel("taggedWith", taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  if (!isEmpty(selectedFilter)) {
    appliedFilters.push({
      key: "selectedFilter",
      label: disambiguateLabel("selectedFilter", selectedFilter),
      onRemove: handleSelectedFilterRemove,
    });
  }

  const filters = [
    {
      key: "selectedFilter",
      label: t("label.productList.filterBy"),
      filter: (
        <Filter
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
  ];

  if (selectedFilter) {
    filters.push({
      key: t("taggedWith"),
      label: "Advance Filter",
      filter: (
        <MoreFilters
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    });
  }

  const renderItem = (item, _, keyIndex) => {
    return (
      <ProductListView
        item={item}
        listLoading={listLoading}
        keyIndex={keyIndex}
        bulkAction={bulkAction}
        loadingBulkProduct={loadingBulkProduct}
        handleRejectModal={handleRejectModal}
        handleDeleteModal={handleDeleteModal}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
        setListBanner={setResBanner}
        active={active}
        setActive={setActive}
      />
    );
  };

  const promotedBulkActions = [
    {
      content: t("label.productList.bulkAction"),
    },
  ];

  const bulkActions = [
    {
      content: t("label.productList.pushSelected"),
      onAction: () => bulkAction(constant.APPROVE),
      disabled: tabs[selected].value === constant.APPROVED.toLowerCase(),
    },
    {
      content: t("label.productList.deleteSelected"),
      onAction: () => handleDeleteModal(constant.DELETE.toLowerCase()),
      destructive: true,
      disabled:
        tabs[selected].value === constant.DELETED.toLowerCase() ||
        tabs[selected].value === constant.REJECTED.toLowerCase(),
    },
    {
      content: t("label.productList.editSelected"),
      onAction: () => bulkAction(constant.BULK_EDIT),
      disabled:
        tabs[selected].value === constant.DELETED.toLowerCase() ||
        tabs[selected].value === constant.REJECTED.toLowerCase(),
    },
    {
      content: t("label.productList.rejectSelected"),
      onAction: () => handleRejectModal(constant.REJECT),
      disabled:
        tabs[selected].value === constant.DELETED.toLowerCase() ||
        tabs[selected].value === constant.REJECTED.toLowerCase(),
    },
    {
      content: t("label.productList.markAvailable"),
      onAction: () => bulkAction(constant.PUBLISH),
    },
    {
      content: t("label.productList.markUnavailable"),
      onAction: () => bulkAction(constant.UNPUBLISH),
    },
    {
      content: t("label.productList.unRejectSelected"),
      onAction: () => handleRejectModal(constant.UNREJECT),
    },
  ];

  const linkData = [
    {
      action: () => setOpenSyncModal(true),
      caption: "Import all products from your shopify store",
      id: "importProduct",
      label: "Import products from Shopify store",
    },
    {
      action: () => history.push("/products/add"),
      caption: "Add your products",
      id: "addProduct",
      label: "Add Products",
    },
  ];

  const getSecondaryComponent = () => {
    return (
      <>
        <QuickAction link={linkData} />
        <QuickReport />
      </>
    );
  };

  const getComponent = () => {
    return (
      <>
        {resBanner.isOpen && (
          <>
            <Banner
              isOpen={resBanner.isOpen}
              title={resBanner.title}
              status={resBanner.status}
              onDismiss={() => {
                setResBanner({ isOpen: false, title: "", status: "" });
              }}
            />
            <br />
          </>
        )}
        <Layout>
          <SyncProduct
            openSyncModal={openSyncModal}
            setResBanner={setResBanner}
            setOpenSyncModal={setOpenSyncModal}
            dataToFetch={inputData}
          />
          <DeleteProduct
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            selectedItems={selectedItems}
            isSeller
          />
          <RejectProduct
            openRejectModal={openRejectModal}
            setOpenRejectModal={setOpenRejectModal}
            bulkAction={bulkAction}
            id={singleId}
            loadingBulkProduct={loadingBulkProduct}
            selectedButton={selectedButton}
            modalTitle={modalTitle}
            primaryActionTitle={primaryActionTitle}
            secondaryActionsTitle={secondaryActionsTitle}
            keyName={keyName}
          />
          <Layout.Section>
            <Card>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <ResourceList
                  resourceName={resourceName}
                  items={productList || []}
                  idForItem={(products) => {
                    const { productId: id } = products;
                    return id;
                  }}
                  renderItem={renderItem}
                  onQueryChange={handleQueryValueChange}
                  onQueryClear={handleQueryValueRemove}
                  handleClearAll={handleClearAll}
                  queryValue={queryValue}
                  selectedItems={selectedItems}
                  onSelectionChange={setSelectedItems}
                  sortValue={sortValue}
                  sortOptions={sortOptions}
                  onSortChange={sortFilter}
                  bulkActions={bulkActions}
                  promotedBulkActions={promotedBulkActions}
                  loading={loadingProductList}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  page={currentPage}
                  count={Number(totalProductCount)}
                  perPage={selectedLimit}
                  setPage={handlePage}
                  setPerPage={handleSelectLimit}
                />
              </Tabs>
            </Card>
          </Layout.Section>
        </Layout>
      </>
    );
  };

  return <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent()} />;
};

export default withFeature(withErrorBoundary(OperatorProductList), { feature: constant.PRODUCTS });

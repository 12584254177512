/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useContext, useEffect } from "react";
import { Banner, ResourceList } from "lib/components";
import { useQuery, useMutation } from "react-apollo";
import {
  Card,
  Tabs,
  ResourceItem,
  TextStyle,
  Thumbnail,
  Badge,
  Stack,
  Caption,
  Heading,
  Select,
  Spinner,
} from "@shopify/polaris";

import { baseHelper, imageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

import GET_TEMP_PRODUCT_LIST from "app/products/apollo/queries/getTempProductList";
import { SYNC_SHOPIFY_PRODUCT } from "app/products/apollo/mutations";

import { useTranslation } from "react-i18next";
import constant from "lib/constant/constant";
import { withFeature } from "lib/hoc";

const List = () => {
  const { history } = useContext(PrivateContext);
  const { t } = useTranslation();
  const queryParams = baseHelper.getQueryParams(history.location.search);
  const [selected, setSelected] = useState(parseInt(queryParams.tab, 10) || 0);
  let searchVal = queryParams.search && queryParams.search.split("+");
  searchVal = searchVal && searchVal.length && searchVal.join(" ");
  const [values, setValues] = useState(searchVal || "");
  const [selectedAction, setSelectedAction] = useState("");
  const [appliedFiltersValue, setAppliedFiltersValue] = useState([]);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [queryValue, setQueryValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const getQueryParams = baseHelper.getQueryParams(history.location.search);
  const [sortValue, setSortValue] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [page, setPage] = useState(getQueryParams.page || 1);
  const [perPage, setPerPage] = useState(getQueryParams.perPage || 20);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });

  if (!getQueryParams) {
    getQueryParams.search = "";
    getQueryParams.sort_name = "";
    getQueryParams.sort_order = "";
    getQueryParams.filter = "";
  }

  const inputData = {
    search: getQueryParams.search,
    sort_name: getQueryParams.sort_name,
    sort_order: getQueryParams.sort_order,
    filter: getQueryParams.filter,
    page: parseInt(getQueryParams.page || page, 10),
    perPage: parseInt(getQueryParams.perPage || perPage, 10),
  };

  const { loading, data } = useQuery(GET_TEMP_PRODUCT_LIST, {
    variables: {
      input: inputData,
    },
  });

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(SYNC_SHOPIFY_PRODUCT, {
    refetchQueries: [
      {
        query: GET_TEMP_PRODUCT_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  const tempData = baseHelper.getResponseData(data, constant.gql.GET_TEMP_PRODUCT_LIST);

  useEffect(() => {
    const paramValue = baseHelper.getQueryParams(history.location.search);
    const listFilter = (paramValue.list_filter && paramValue.list_filter.split(",")) || [];
    const listSearch = (paramValue.list_search && paramValue.list_search.split(",")) || [];
    const appliedFilters = [];
    listFilter.forEach((item, index) => {
      appliedFilters.push({
        key: item,
        value: listSearch[index],
      });
    });
    setAppliedFiltersValue(appliedFilters);
  }, [history.location.search, values]);

  const handleSearchValue = (searchValue) => {
    const search = baseHelper.setQueryParams(history.location, { search: searchValue });
    setQueryValue(searchValue);
    history.push(`${history.location.pathname}?${search}`);
  };

  const handleSort = (select) => {
    const sort = select.split("_");
    if (sort && sort.length === 2) {
      const sortedValue = baseHelper.setQueryParams(history.location, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(sortedValue);
      history.push(`${history.location.pathname}?${sortedValue}`);
    }
  };

  const tabsData = [
    { id: "all", panelID: "all", content: t("label.ShopifyProduct.All"), value: "all" },
    { id: "synced", panelID: "synced", content: t("label.ShopifyProduct.Synced"), value: "synced" },
    { id: "unsynced", panelID: "unsynced", content: t("label.ShopifyProduct.NotSynced"), value: "unsynced" },
  ];
  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      const filterValue = baseHelper.setQueryParams(history.location, {
        filter: tabsData[selectedTabIndex].value,
        tab: selectedTabIndex,
      });
      history.push(`${history.location.pathname}?${filterValue}`);
    },
    [history, tabsData]
  );

  const handleSync = async (eventKey, id = false) => {
    let formData = {};

    formData = {
      key: eventKey,
      ids: selectedItems,
      all: false,
      searchParams: {
        search: searchVal,
      }
    };

    if (id) {
      formData.ids = [id];
      formData.all = false;
      setListLoading(`${eventKey}_${id}`);
    }

    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        setListLoading(false);
        if (res) {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.SYNC_SHOPIFY_PRODUCT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.SYNC_SHOPIFY_PRODUCT);
          if (responseData) {
            const title = t("message.productList.requestSuccess");
            setBanner({ isOpen: true, title, status: "success" });
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: "critical" });
          }
        }
      })
      .catch((err) => {
        setListLoading(false);
        if (err) {
          setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
        }
      });
  };

  const bulkActions = [
    {
      content: "Sync Products",
      onAction: () => handleSync("approve"),
    },
  ];

  const handleChange = (val, _id) => {
    setSelectedButtonIndex(_id);
    if (val === "sync") {
      handleSync("approve", _id);
    }
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            action={banner.action}
            onDismiss={() => setBanner({ isOpen: false })}
          />
          <br />
        </>
      )}
      <Banner title={t("message.shopifyProduct.addProductInstruction")} isOpen status="warning" />
      <br />
      <Banner title={t("message.shopifyProduct.importedProductInstruction")} isOpen status="warning" />
      <br />
      <Card>
        <Tabs tabs={tabsData} selected={selected} onSelect={handleTabChange} />
        <ResourceList
          resourceName={{ singular: t("common.Product"), plural: t("common.Products") }}
          items={tempData.tempProductList}
          idForItem={(item) => {
            const { _id } = item;
            return _id;
          }}
          selectable
          bulkActions={bulkActions}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          loading={loading}
          sortValue={sortValue}
          sortOptions={[
            { label: t("label.ShopifyProduct.DateAsc"), value: "createdAt_asc" },
            { label: t("label.ShopifyProduct.DateDesc"), value: "createdAt_desc" },
            { label: t("label.ShopifyProduct.ProductAsc"), value: "title_asc" },
            { label: t("label.ShopifyProduct.ProductDesc"), value: "title_desc" },
          ]}
          onSortChange={handleSort}
          onQueryChange={handleSearchValue}
          queryValue={queryValue}
          count={tempData.count}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          renderItem={(item) => {
            const { _id, updatedAt, title, images, vendor, published } = item;
            const thumbnailImage =
              images.length > 0
                ? imageHelper.resize({ url: images[0].url, type: constant.imageTypes.THUMBNAIL })
                : constant.NOIMAGESNAP;

            const syncedStatusType = (published && constant.SYNCED) || constant.NOT_SYNCED;
            const syncedStatus = (published && t("label.ShopifyProduct.Synced")) || t("label.ShopifyProduct.NotSynced");
            return (
              <ResourceItem id={_id}>
                <Stack>
                  <Stack.Item>
                    <Thumbnail size={constant.LARGE} source={thumbnailImage} alt={`${title} image`} />
                  </Stack.Item>
                  <Stack.Item fill>
                    <Heading element="h2">
                      <div className="content-text-length">
                        <TextStyle variation="strong">{title}</TextStyle>
                      </div>
                    </Heading>
                    <div className="content-text-length">
                      <Caption>
                        {`${t("label.ShopifyProduct.By")} `}
                        <b>{vendor}</b>
                        {` ${t("label.ShopifyProduct.On")} ${baseHelper.formatDate(updatedAt)}`}
                      </Caption>
                    </div>
                    <div>
                      <Stack>
                        <Stack.Item>
                          <Badge status={baseHelper.getBadgeType(syncedStatusType)}>
                            {baseHelper.ucFirst(syncedStatus)}
                          </Badge>
                        </Stack.Item>
                      </Stack>
                    </div>
                  </Stack.Item>
                  <Stack.Item>
                    <Select
                      options={[{ label: "Publish", value: "sync" }]}
                      placeholder={
                        (_id === selectedButtonIndex && listLoading && <Spinner size="small" />) || t("common.Actions")
                      }
                      value={selectedAction}
                      onChange={(val) => handleChange(val, _id)}
                      disabled={published}
                    />
                  </Stack.Item>
                </Stack>
              </ResourceItem>
            );
          }}
        />
      </Card>
    </>
  );
};

export default withFeature(List, { feature: constant.SHOPIFY_PRODUCT });

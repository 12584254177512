import React, { useContext } from "react";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import { SkeletonList } from "lib/components";

import { QuickLink, QuickReport } from "app/reports";

import { TwoColumnLayout } from "layout/private/components";
import AdminInvitation from "./admin/invitation";
import OperatorInvitation from "./operator/invitation";

import { storageHelper } from "../../../lib/helpers";

const { userKey } = constant;
const { admin, operator } = userKey;

const Invitation = () => {
  const { isLoading } = useContext(PrivateContext);
  const currentUserRole = storageHelper.get("userRole");

  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonList />;
    }
    if (role === admin) {
      return <AdminInvitation />;
    }
    if (role === operator) {
      return <OperatorInvitation />;
    }
    return null;
  };

  const getSecondaryComponent = () => {
    if (currentUserRole === operator || currentUserRole === admin) {
      return (
        <>
          <QuickLink />
          <QuickReport />
        </>
      );
    }

    return <QuickLink />;
  };

  return <TwoColumnLayout primary={getComponent(currentUserRole)} secondary={getSecondaryComponent()} />;
};

export default Invitation;

import React, { useContext, useEffect } from "react";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { baseHelper } from "lib/helpers";

import { SHOPIFY_CALLBACK } from "app/products/apollo/mutations";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

const ProviderShopifyCallback = () => {
  const { currentUser, history } = useContext(PrivateContext);

  const { t } = useTranslation();
  const [getShopifyCallback] = useMutation(SHOPIFY_CALLBACK);
  const isOrderConnect = !!(currentUser && currentUser.shop);
  const isUpdatePermission = !!(isOrderConnect && currentUser.isOrderConnect);
  const queryParams = baseHelper.getQueryParams(history.location.search);

  const data = {
    shop: queryParams.shop,
    code: queryParams.code,
  };

  const shopParam = "?shop=true";
  const route =
    (isUpdatePermission && `/${shopParam}`) ||
    (isOrderConnect && `/setting/order${shopParam}`) ||
    `/products/add${shopParam}`;

  // ComponentDidMount: Call GET_SHOPIFY_CALLBACK mutation and show success or error banner
  useEffect(() => {
    getShopifyCallback({ variables: { input: data } })
      .then((res) => {
        let bannerData = { title: t("message.shopifyCallback.shopConnected"), status: "success" };
        const resError = baseHelper.getResponseError(res.data, constant.gql.SAVE_SHOPIFY_VENDOR);
        if (resError) {
          bannerData = { title: resError, status: "critical" };
        }
        history.push(route, {
          bannerData,
        });
      })
      .catch((err) => {
        const bannerData = { title: t("error.common.somethingWentWrong"), status: "critical" };
        history.push(route, { bannerData });
      });
  }, []);

  // useEffect(() => {
  //   const getCallback = () => {
  //     try {
  //       const res = getShopifyCallback({ variables: { input: data } });
  //       // currentUserRefetch();

  //       let bannerData = { title: t("message.shopifyCallback.shopConnected"), status: "success" };
  //       if (!res) {
  //         const resError = baseHelper.getResponseError(res, constant.gql.SAVE_SHOPIFY_VENDOR);
  //         bannerData = { title: resError, status: "critical" };
  //       }
  //       // if (isUpdatePermission) {
  //       //   history.push(route, {
  //       //     bannerData,
  //       //   });
  //       // } else {
  //       //   history.push(route, {
  //       //     bannerData,
  //       //   });
  //       // }
  //       history.push(route, {
  //         bannerData,
  //       });
  //     } catch (err) {
  //       const bannerData = { title: t("error.common.somethingWentWrong"), status: "critical" };
  //       history.push(route, { bannerData });
  //     }
  //   };
  //   if (!callbackData && !error && currentUser) {
  //     getCallback();
  //   }
  // }, [callbackData, error, currentUser, getShopifyCallback, data, history, route, t]);

  return (
    <div>
      <Spinner isFullPage />
      <h3 className="text-center" style={{ marginTop: "32%" }}>
        {t("message.shopifyCallback.wait")}
      </h3>
    </div>
  );
};

export default withErrorBoundary(ProviderShopifyCallback);

import React, { useContext } from "react";
import { TextField, FormLayout } from "@shopify/polaris";

// import hoc
import { withFeature } from "lib/hoc";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const NZPostShipping = (props) => {
  const { cms } = useContext(PrivateContext);
  const { clientId, clientSecret, accountNumber, siteCode, handleClientId, handleClientSecret, handleAccountNumber,  handleSiteCode, setError: error } = props;

  return (
    <>
      <TextField label={cms("label.clientId")} value={clientId} onChange={handleClientId} error={error && !clientId && error.clientId} />
      <TextField label={cms("label.clientSecret")} value={clientSecret} onChange={handleClientSecret} error={error && !clientSecret && error.clientSecret} />
      <TextField label={cms("label.accountNumber")} value={accountNumber} onChange={handleAccountNumber} error={error && !accountNumber && error.accountNumber} />
      <TextField label={cms("label.siteCode")} value={siteCode} onChange={handleSiteCode} error={error && !siteCode && error.siteCode} />
    </>        
  );
};

export default withFeature(NZPostShipping, { feature: constant.DROPSHIP });

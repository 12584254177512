import React, { useState, useEffect } from "react";
import { Modal, Stack, TextField, Checkbox } from "@shopify/polaris";
import { useQuery, useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { GET_SELLER_FEATURE } from "app/sellers/apollo/queries/";
import { UPDATE_SELLER_FEATURE } from "app/sellers/apollo/mutations";

import cmsSellerList from "../../cms/sellerListCMS";

const UpdateSeller = (props) => {
  const { updateModalOpen, operatorUpdateID, operatorUpdateName, updateModalClose, handleBanner } = props;

  const [sellerFeatureData, setSellerFeatureData] = useState({});
  const { t } = useTranslation();
  const sellerListCMS = cmsSellerList(t);
  const { message, label } = sellerListCMS;

  const { data: getSellerFeature, loading: getFeatureLoading, error: getFeatureError } = useQuery(GET_SELLER_FEATURE, {
    variables: { input: { id: operatorUpdateID } },
  });
  const [updateSellerFeature, { loading: updateFeatureLoading, error: updateFeatureError }] = useMutation(
    UPDATE_SELLER_FEATURE,
    {
      refetchQueries: [
        {
          query: GET_SELLER_FEATURE,
          variables: { input: { id: operatorUpdateID } },
        },
      ],
    }
  );

  // To get seller feature data
  useEffect(() => {
    let bannerData = {};
    if (getFeatureError) {
      bannerData = {
        isOpen: true,
        status: "critical",
        title: getFeatureError,
      };
      handleBanner(bannerData);
      updateModalClose();
    }
    const responseData = baseHelper.getResponseData(getSellerFeature, constant.gql.GET_SELLER_FEATURE);
    const responseError = baseHelper.getResponseError(getSellerFeature, constant.gql.GET_SELLER_FEATURE);

    if (responseError) {
      bannerData = {
        isOpen: true,
        status: "critical",
        title: responseError,
      };
      handleBanner(bannerData);
      updateModalClose();
    }
    if (responseData) {
      setSellerFeatureData(responseData);
    }
  }, [getFeatureError, getFeatureLoading, getSellerFeature, handleBanner, updateModalClose]);

  // To handle error while updating seller feature
  useEffect(() => {
    let bannerData = {};
    if (updateFeatureError) {
      bannerData = {
        isOpen: true,
        status: "critical",
        title: updateFeatureError,
      };
      handleBanner(bannerData);
      updateModalClose();
    }
  }, [handleBanner, updateFeatureError, updateModalClose]);

  const editSellerFeature = async () => {
    let bannerData = {};
    try {
      const res = await updateSellerFeature({
        variables: { input: { id: operatorUpdateID, ...sellerFeatureData } },
      });
      const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_SELLER_FEATURE);
      const errorData = baseHelper.getResponseError(res.data, constant.gql.UPDATE_SELLER_FEATURE);
      if (!errorData) {
        bannerData = {
          isOpen: true,
          status: "success",
          title: message.updatedPlanMessage,
        };
      }
      if (!responseData) {
        bannerData = {
          isOpen: true,
          status: "critical",
          title: message.something_went_wrong,
        };
      }
    } catch (err) {
      bannerData = {
        isOpen: true,
        status: "critical",
        title: message.something_went_wrong,
      };
    }
    handleBanner(bannerData);
    updateModalClose();
  };

  const handleChange = (field, value) => {
    setSellerFeatureData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const { isBranding, maxVendors, maxProducts } = sellerFeatureData;

  const acceptOnlyValidInput = (value) => {
    const result = Math.abs(value);
    return result;
  };

  return (
    <Modal
      open={updateModalOpen}
      onClose={updateModalClose}
      loading={getFeatureLoading}
      title={`${baseHelper.ucFirst(operatorUpdateName)} ${label.featuresUpdate}`}
      primaryAction={{
        content: label.updateFeatures,
        loading: updateFeatureLoading,
        onAction: () => {
          editSellerFeature();
        },
      }}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>
            <TextField
              id={label.maxProducts}
              label={label.maxProducts}
              type="number"
              min={0}
              value={maxProducts || ""}
              onChange={(value) => handleChange("maxProducts", acceptOnlyValidInput(value))}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              id={label.maxVendors}
              label={label.maxVendors}
              type="number"
              min={0}
              value={maxVendors || ""}
              onChange={(value) => handleChange("maxVendors", acceptOnlyValidInput(value))}
            />
          </Stack.Item>
          <Stack.Item>
            <Checkbox
              id={label.branding}
              name={label.branding}
              label={label.branding}
              checked={isBranding}
              onChange={() => handleChange("isBranding", !isBranding)}
            />
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

UpdateSeller.defaultProps = {
  updateModalOpen: false,
  operatorUpdateID: "",
  operatorUpdateName: "",
  updateModalClose: () => {},
  handleBanner: () => {},
};

UpdateSeller.propTypes = {
  updateModalOpen: PropTypes.bool,
  operatorUpdateID: PropTypes.string,
  operatorUpdateName: PropTypes.string,
  updateModalClose: PropTypes.func,
  handleBanner: PropTypes.func,
};

export default UpdateSeller;

import React, { useContext } from "react";
import { Playground } from 'graphql-playground';
import 'graphql-playground/playground.css';

import { Spinner } from "lib/components";

import withErrorBoundary from "lib/hoc/error/error";

import config from "config";

import { PrivateContext } from "lib/context";

const GraphQLPlayground = () => {
  const { isLoading } = useContext(PrivateContext);

  const getComponent = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return <Playground endpoint={config.apiURL} subscriptionEndpoint={config.webSocketUrl} />;
  };

  const PlaygroundComponent = getComponent();
  return PlaygroundComponent;
};

export default withErrorBoundary(GraphQLPlayground);

import React, { useState, useEffect, useContext } from "react";
import { useMutation, useLazyQuery, useQuery } from "react-apollo";
import { Layout, Stack, Button, TextStyle, Card } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import helper components
import { Banner, Spinner } from "lib/components";

// import helpers
import baseHelper from "lib/helpers/base";

// import constant
import constant from "lib/constant/constant";

// import gql
import { PrivateContext } from "lib/context";
import { GET_VENDOR_AUTH_CODE, GET_SELLER_PAYMENT } from "../../../../apollo/queries";
import { DISCONNECT_PAYPAL_VENDOR, DISCONNECT_STRIPE_VENDOR } from "../../../../apollo/mutations";

const ProviderConnect = () => {
  const { t } = useTranslation();
  const { currentUser, history } = useContext(PrivateContext);

  const [isStripeConnected, setIsStripeConnected] = useState(
    !!(currentUser && currentUser.stripe && currentUser.stripe.accountId)
  );

  const { gql } = constant;
  const { GET_SELLER_PAYMENT: GET_SELLER_PAYMENT_GQL } = gql || {};
  const [getAuthCode, { loading: paypalLoading, data: paypalData, error: paypalError }] = useLazyQuery(
    GET_VENDOR_AUTH_CODE
  );
  const { loading, data, error } = useQuery(GET_SELLER_PAYMENT);
  const [disconnectFromStripe, { loading: disconnectStripeLoading }] = useMutation(DISCONNECT_STRIPE_VENDOR);
  const [disconnectFromPaypal, { loading: disconnectPaypalLoading }] = useMutation(DISCONNECT_PAYPAL_VENDOR);
  const { _id: id } = currentUser;
  const { location } = history;
  const { state } = location;

  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const sellerResponseData = baseHelper.getResponseData(data, GET_SELLER_PAYMENT_GQL);
  const errorSellerResponse = baseHelper.getResponseData(data, GET_SELLER_PAYMENT_GQL);
  useEffect(() => {
    if (error) {
      setBanner({ title: t("error.common.somethingWentWrong"), status: "critical", isOpen: true });
    }
    if (state && !state.isPaypalConnected && !state.isStripeConnected) {
      setBanner({ title: state.message, status: "critical", isOpen: true });
    }
    if (state && state.isStripeConnected) {
      setBanner({ title: "Stripe Account Connected Successfully", status: "success", isOpen: true });
    }
    if (state && state.isPaypalConnected) {
      setBanner({ title: t("message.paypalConnect"), status: "success", isOpen: true });
    }
  }, [error, t, state]);
  useEffect(() => {
    if (errorSellerResponse && !sellerResponseData) {
      setBanner({ isOpen: true, title: errorSellerResponse, status: "critical" });
    }
  }, [errorSellerResponse, sellerResponseData]);
  const { stripeKey, paypalKey } = sellerResponseData;
  const { paypal } = currentUser;

  const stripeClientId = stripeKey && stripeKey.clientId;
  const paypalClientId = paypalKey && paypalKey.clientId;
  const isPaypalConnected = paypal && (paypal.payerId || paypal.emailId);
  const serverUrl = baseHelper.getCurrentDomain(window.location, "stripe/callback");
  const stripeUrl = `
  ${constant.STRIPE_ACCOUNT}${stripeClientId}&scope=read_write&state=${id}&redirect_uri=${serverUrl}`;
  const dismiss = () => {
    setBanner(false);
  };

  useEffect(() => {
    if (paypalData) {
      const responseData = baseHelper.getResponseData(paypalData, gql.GET_VENDOR_AUTH_CODE);

      if (responseData) {
        window.location.href = responseData.uri;
      }
      if (!responseData) {
        const errorResponseData = baseHelper.getResponseError(paypalData, gql.GET_VENDOR_AUTH_CODE);
        setBanner({ title: errorResponseData, status: "critical", isOpen: true });
      }
    }

    if (paypalError) {
      setBanner({ title: t("error.common.somethingWentWrong"), status: "critical", isOpen: true });
    }
  }, [gql.GET_VENDOR_AUTH_CODE, paypalData, paypalError, t]);

  if (loading) {
    return <Spinner />;
  }

  const onPaypalConnect = async () => {
    const redirectUri = baseHelper.getCurrentDomain(window.location, "paypal/callback");
    
    getAuthCode({
      variables: { input: { redirectUri } },
    });
  };
  const handleDisconnect = (key) => {
    if (key === gql.STRIPE_CONNECTED) {
      disconnectFromStripe({ variables: { input: { paymentMethod: constant.STRIPE } } })
        .then((response) => {
          const responseData = baseHelper.getResponseData(response.data, gql.DISCONNECT_STRIPE);
          const errorResponseData = baseHelper.getResponseError(response.data, gql.DISCONNECT_STRIPE);

          if (errorResponseData) {
            setBanner({ title: errorResponseData, status: "critical", isOpen: true });
            return;
          }
          if (responseData) {
            setBanner({ title: t("message.stripeDisconnect"), status: "success", isOpen: true });
            setIsStripeConnected(false);
          }
        })
        .catch(() => {
          setBanner({ title: t("error.common.somethingWentWrong"), status: "critical", isOpen: true });
        });
    }
    if (key === constant.gql.PAYPAL_CONNECTED) {
      disconnectFromPaypal({ variables: { input: { paymentMethod: constant.PAYPAL } } })
        .then((response) => {
          const responseData = baseHelper.getResponseData(response.data, gql.DISCONNECT_PAYPAL);
          let bannerData = { status: "success", title: t("message.paypalDisconnect") };
          if (!responseData) {
            const errorResponseData = baseHelper.getResponseError(response.data, gql.DISCONNECT_PAYPAL);
            bannerData = { title: errorResponseData, status: "critical" };
          }
          setBanner({ isOpen: true, title: bannerData.title, status: bannerData.status });
        })
        .catch(() => {
          setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
        });
    }
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner title={banner.title} status={banner.status} onDismiss={() => dismiss()} isOpen={banner.isOpen} />
        </Layout.Section>
      )}

      <Layout.AnnotatedSection
        title={t("sectionLabel.connectPaymentAccount")}
        description={t("sectionContent.connectPaymentAccount")}
      >
        <Card title={t("label.stripe")} sectioned>
          {stripeClientId && (
            <Stack wrap={false}>
              <Stack.Item fill>
                <TextStyle>
                  {isStripeConnected ? t("message.connectedStripe") : t("message.connectStripeInfo")}
                </TextStyle>
              </Stack.Item>
              <Stack.Item>
                {isStripeConnected ? (
                  <Button
                    id="stripeDisconnect"
                    slim
                    primary
                    loading={disconnectStripeLoading}
                    destructive
                    onClick={() => handleDisconnect(constant.gql.STRIPE_CONNECTED)}
                  >
                    {t("label.disconnect")}
                  </Button>
                ) : (
                  <Button id="stripeConnect" slim primary url={stripeUrl}>
                    {t("label.connect")}
                  </Button>
                )}
              </Stack.Item>
            </Stack>
          )}
          {!stripeClientId && (
            <Stack wrap={false}>
              <Stack.Item fill>
                <TextStyle>{t("message.askSellerStripe")}</TextStyle>
              </Stack.Item>
            </Stack>
          )}
        </Card>
        <Card title={t("label.paypal")} sectioned>
          {paypalClientId && (
            <Stack wrap={false}>
              <Stack.Item fill>
                <TextStyle>
                  {isPaypalConnected ? t("message.connectedPaypal") : t("message.connectPaypalInfo")}
                </TextStyle>
              </Stack.Item>
              <Stack.Item>
                {isPaypalConnected ? (
                  <Button
                    id="paypalDisconnect"
                    slim
                    primary
                    loading={disconnectPaypalLoading}
                    destructive
                    onClick={() => handleDisconnect(constant.gql.PAYPAL_CONNECTED)}
                  >
                    {t("label.disconnect")}
                  </Button>
                ) : (
                  <Button id="paypalConnect" slim loading={paypalLoading} primary onClick={() => onPaypalConnect()}>
                    {t("label.connect")}
                  </Button>
                )}
              </Stack.Item>
            </Stack>
          )}
          {!paypalClientId && (
            <Stack wrap={false}>
              <Stack.Item fill>
                <TextStyle>{t("message.askSellerPaypal")}</TextStyle>
              </Stack.Item>
            </Stack>
          )}
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderConnect), { feature: constant.CONNECT_PAYMENT });

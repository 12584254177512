import { gql } from "apollo-boost";

const FETCH_CURRENT_USER = gql`
  {
    getCurrentUser {
      status
      data {
        _id
        aws {
          bucket
          path
          id
          secret
        }
        isOtp
        firstlogin
        firstName
        lastName
        isFirstProduct
        payoutSetting {
          isAutoStripe
          isAutoPaypal
        }
        email {
          address
          verified
        }
        contactEmail
        roles {
          name
        }
        moneyFormat
        shop
        brandName
        vendors {
          max
          associated
        }
        products {
          max
          used
        }
        isOrderConnect
        isReadOnly
        plan {
          isBranding
          name
          price
        }
        stripe {
          accountId
        }
        paypalKey {
          clientId
          secret
        }
        paypal {
          emailId
          payerId
          status
        }
        setupProgress
        phoneNumber
        address
        city
        country
        pinCode
        commissionType
        setting {
          isAcceptOrder
          isFulfillOrder
        }
        logo
        shop
        shopifyAccessToken
        generalInfo {
          isSellOnline
          storeType
          isVendorOnboarding
          marketplaceUnderstanding
          annualRevenue
          alternateEmail
          brandDescription
        }
        introductorySeller
        introductorySellerName
        paymentGateway
        planId
        userLevel
        isProductSync
        isHideMcInfo
        emailSign
        footerText
        loginText
        domain
        isDomainActive
        register {
          title
          desc
        }
        style {
          header {
            color
            font
          }
          button {
            color
            font
          }
        }
        maintenance {
          isApp
          isSite
        }
        brand {
          termsLink
          policyLink
          slug
          description
          cover {
            imageUrl
          }
        }
        isAwsCredsAvailable
        sellerName
        sellerShop
        createdAt
        requestedBy
        updatedAt
        storeConfig {
          wooCommerce {
            shop,
            accessKey,
          }
          bigCommerce {
            shopDomain,
            accessToken,
          }
        }
      }
    }
  }
`;

export default FETCH_CURRENT_USER;

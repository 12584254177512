import React, { useContext } from "react";

import { QuickReport, QuickLink } from "app/reports";
import { TwoColumnLayout } from "layout/private/components";
import { PrivateContext } from "lib/context";
import { SkeletonList } from "lib/components";

import { Activity } from "app/userManagement/modules";

import { storageHelper } from "lib/helpers";
import OnBoardingSkeleton from "lib/components/skeletonOnboarding/onBoardingSkeleton";
import { Layout } from "@shopify/polaris";

const ProviderDashboard = () => {
  const { isLoading } = useContext(PrivateContext);
  const getSecondaryComponent = () => (
    <>
      <QuickLink />
      <QuickReport />
    </>
  );
  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return <Activity />;
  };

  if (isLoading && storageHelper.get("setupProgress")) {
    return (
      <Layout.Section>
        <OnBoardingSkeleton />
      </Layout.Section>
    );
  }
  return <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent()} />;
};

export default ProviderDashboard;

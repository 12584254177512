const config = {
  "/": [
    {
      caption: "Check activity",
      label: "Activity ",
      link: "/activity",
    },
    {
      caption: "Add products to your store",
      label: "Add Products",
      link: "/products/add",
    },
    {
      caption: "Manage your vendors",
      label: "Add Vendors",
      link: "/providers/add",
    },
    {
      caption: "Have any question?",
      label: "Contact Us",
      link: "/contact-us",
    },
    {
      caption: "Access our graphql playground from here",
      label: "GraphQL Playground",
      link: "/playground",
    },
  ],
  "/activity": [
    {
      caption: "Check progress of your bulk activities ",
      label: "Bulk Activity ",
      link: "activity/bulk",
    },
  ],
  "/invitation": [
    {
      caption: "Invite your vendors",
      label: "Invite Vendors",
      link: "/providers/add",
    },
  ],
  "/orders": [
    {
      caption: "Manage order auto-accept settings",
      label: "Order Settings",
      link: "/setting/order",
    },
  ],
  "/payments": [
    {
      caption: "Manage payouts",
      label: "Automatic Payouts",
      link: "/payouts",
    },
  ],
  "/products": [
    {
      caption: "Add products to your store",
      label: "Add Product",
      link: "/products/add",
    },
    {
      caption: "Manage product review settings",
      label: "Product Settings",
      link: "/setting/product",
    },
  ],
  "/providers": [
    {
      caption: "Manage your providers",
      label: "Add Providers",
      link: "/providers/add",
    },
    {
      caption: "Manage external vendor page",
      label: "Advance Vendor Profile",
      link: "/advance-providers",
    },
  ],
};

export default config;

import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { TextField, Select, Checkbox, Modal, Stack } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import helpers
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import helper components
import { Banner } from "lib/components";

// import gql
import { CREATE_BAND, UPDATE_SHIPPING_BAND } from "app/shipping/apollo/mutations";
import { GET_SHIPPING_BAND } from "app/shipping/apollo/queries";

// import props
import { optionProp } from "../props";

const Option = (props) => {
  const { show, onClose, isDefault, shippingBand, handleBanner } = props;
  const { currentUser, cms } = useContext(PrivateContext);
  const [errorBanner, setErrorBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
  });

  // To show error inside Modal
  const handleErrorBanner = (errorBannerData) => {
    setErrorBanner(errorBannerData);
  };

  const [createShippingBand, { loading }] = useMutation(CREATE_BAND, {
    refetchQueries: [
      {
        query: GET_SHIPPING_BAND,
      },
    ],
  });
  const [updateShippingBand, { loading: updateLoading }] = useMutation(UPDATE_SHIPPING_BAND, {
    refetchQueries: [
      {
        query: GET_SHIPPING_BAND,
      },
    ],
  });
  const [state, setState] = useState({
    shippingBand,
  });

  useEffect(() => {
    if (shippingBand) {
      setState((prevState) => ({
        ...prevState,
        shippingBand,
      }));
    }
  }, [shippingBand]);

  const closeModal = () => {
    let errorBannerData = {};
    errorBannerData = {
      isOpen: false,
      status: "",
      title: "",
    };
    handleErrorBanner(errorBannerData);
    onClose();
  };
  const { _id, name, price = "", description, priceType, isUpdateAllowed } = state.shippingBand;

  const handleChange = (key, value) => {
    if (value === constant.SHIPPING_FREE) {
      setState((prevState) => ({
        shippingBand: {
          ...prevState.shippingBand,
          price: 0,
        },
      }));
    }
    setState((prevState) => ({
      shippingBand: {
        ...prevState.shippingBand,
        [key]: value,
      },
    }));
  };

  const options = [
    { label: cms("band.label.fixed"), value: constant.SHIPPING_FIXED },
    { label: cms("band.label.free"), value: constant.SHIPPING_FREE },
  ];

  const addShippingBand = async () => {
    let bannerData = {};
    const errorBannerData = { isOpen: false, status: "", title: "" };
    const res = await createShippingBand({
      variables: { input: { name, price: parseFloat(price), description, priceType, isUpdateAllowed } },
    });

    const responseData = baseHelper.getResponseData(res.data, constant.gql.CREATE_BAND);
    const errorData = baseHelper.getResponseError(res.data, constant.gql.CREATE_BAND);
    if (!errorData) {
      bannerData = { isOpen: true, status: "success", title: cms("band.modal.add.message.success") };
    }
    if (!responseData) {
      bannerData = { isOpen: true, status: "critical", title: errorData };
    }
    handleBanner(bannerData);
    handleErrorBanner(errorBannerData);
    onClose();
  };

  const editShippingBand = async () => {
    let bannerData = {};
    const errorBannerData = { isOpen: false, status: "", title: "" };
    const res = await updateShippingBand({
      variables: { input: { _id, name, price: parseFloat(price), description, priceType, isUpdateAllowed } },
    });

    const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_SHIPPING_BAND);
    const errorData = baseHelper.getResponseError(res.data, constant.gql.UPDATE_SHIPPING_BAND);
    if (!errorData) {
      bannerData = { isOpen: true, status: "success", title: cms("band.modal.edit.message.success") };
    }
    if (!responseData) {
      bannerData = { isOpen: true, status: "critical", title: errorData };
    }
    handleBanner(bannerData);
    handleErrorBanner(errorBannerData);
    onClose();
  };

  const showAmount = () => {
    return (
      <TextField
        id={constant.PRICE}
        label={`${cms("band.modal.add.label.amount")}*`}
        type="number"
        value={price.toString()}
        onChange={(value) => handleChange(constant.PRICE, value)}
        prefix={currentUser.moneyFormat}
      />
    );
  };

  const isValid = () => {
    let errorBannerData = {};
    let errMsg = "";

    if (!name) {
      errMsg = cms("band.modal.add.message.error.nameIsRequired");
    } else if (!description) {
      errMsg = cms("band.modal.add.message.error.descriptionIsRequired");
    } else if (!priceType) {
      errMsg = cms("band.modal.add.message.error.priceTypeIsRequired");
    } else if (priceType !== constant.SHIPPING_FREE && !price) {
      errMsg = cms("band.modal.add.message.error.priceIsRequired");
    } else if (priceType !== constant.SHIPPING_FREE && price < 1) {
      errMsg = cms("band.modal.add.message.error.positivePrice");
    }

    if (errMsg) {
      errorBannerData = {
        isOpen: true,
        status: "critical",
        title: errMsg,
      };
      handleErrorBanner(errorBannerData);
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    const isValidData = isValid();
    if (!isValidData) {
      return false;
    }
    if (isDefault) {
      addShippingBand();
      return true;
    }

    editShippingBand();
    return true;
  };

  return (
    <>
      <Modal
        open={show}
        onClose={() => closeModal()}
        title={isDefault ? cms("band.modal.add.title") : `${cms("band.modal.edit.title")} ${shippingBand.name}`}
        primaryAction={{
          content: isDefault ? cms("band.modal.add.button.primary") : cms("band.modal.edit.button.primary"),
          loading: isDefault ? loading : updateLoading,
          onAction: () => {
            onSubmit();
          },
        }}
      >
        <Modal.Section>
          {errorBanner.isOpen && (
            <div>
              <Banner
                isOpen={errorBanner.isOpen}
                status={errorBanner.status}
                onDismiss={() => setErrorBanner({ isOpen: false, status: "", title: "" })}
              >
                {errorBanner.title}
              </Banner>
              <br />
            </div>
          )}
          <Stack vertical>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <TextField
                    label={cms("band.modal.add.placeholder.name")}
                    id={constant.NAME}
                    value={name}
                    onChange={(value) => handleChange(constant.NAME, value)}
                    placeholder={cms("band.modal.add.placeholder.name")}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TextField
                id={constant.DESCRIPTION}
                label={`${cms("band.modal.add.placeholder.description")}*`}
                value={description}
                onChange={(value) => handleChange(constant.DESCRIPTION, value)}
                placeholder={cms("band.modal.add.placeholder.description")}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Select
                    id={constant.PRICE_TYPE}
                    label={`${cms("band.modal.add.label.shippingType")}*`}
                    options={options}
                    value={priceType}
                    placeholder={cms("band.modal.add.label.shippingType")}
                    onChange={(value) => handleChange(constant.PRICE_TYPE, value)}
                  />
                </Stack.Item>
                <Stack.Item>{priceType === constant.SHIPPING_FIXED && showAmount()}</Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                id={constant.IS_UPDATE_ALLOWED}
                label={cms("band.modal.add.label.allowVendorUpdate")}
                checked={isUpdateAllowed}
                value={isUpdateAllowed}
                onChange={(value) => handleChange(constant.IS_UPDATE_ALLOWED, value)}
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};

Option.propTypes = optionProp.type;

export default Option;

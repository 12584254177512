import React from "react";
import { Modal, TextContainer } from "@shopify/polaris";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

import { SYNC_INVENTORY_VENDOR } from "app/products/apollo/mutations";
import { GET_PRODUCT_LIST } from "app/products/apollo/queries";

const SyncProduct = (props) => {
  const { openSyncModal = false, setOpenSyncModal, setResBanner, dataToFetch } = props;
  const [syncProductsData, { loading: loadingSyncProductsData }] = useMutation(SYNC_INVENTORY_VENDOR, {
    refetchQueries: [
      {
        query: GET_PRODUCT_LIST,
        variables: {
          input: dataToFetch,
        },
      },
    ],
  });
  const { t } = useTranslation();

  const syncProducts = async () => {
    await syncProductsData()
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.SYN_INVENTORY_VENDOR);
        const responseError = baseHelper.getResponseError(res.data, constant.gql.SYN_INVENTORY_VENDOR);
        if (responseData) {
          setOpenSyncModal(false);
          setResBanner({ status: "info", title: t("message.productList.productSync"), isOpen: true });
        }
        if (responseError) {
          setResBanner({ status: "critical", title: responseError, isOpen: true });
        }
      })
      .catch((err) => {
        if (err) {
          setResBanner({ status: "critical", title: t("error.common.somethingWentWrong"), isOpen: true });
        }
      });
  };

  return (
    <>
      <Modal
        open={openSyncModal}
        title={t("label.productList.importProductsFromYourStore")}
        onClose={() => setOpenSyncModal(false)}
        secondaryActions={{
          content: t("button.productList.cancel.primaryAction"),
          onAction: () => setOpenSyncModal(false),
          disabled: loadingSyncProductsData,
          id: "cancelProductSync",
        }}
        primaryAction={[
          {
            content: t("label.productList.syncProducts"),
            onAction: syncProducts,
            destructive: true,
            loading: loadingSyncProductsData,
            id: "submitProductSync",
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{t("message.productList.description2")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

SyncProduct.defaultProps = {
  openSyncModal: false,
  dataToFetch: {},
  setOpenSyncModal: () => {},
  setResBanner: () => {},
};
SyncProduct.propTypes = {
  openSyncModal: PropTypes.bool,
  dataToFetch: PropTypes.shape(PropTypes.object),
  setOpenSyncModal: PropTypes.func,
  setResBanner: PropTypes.func,
};

export default SyncProduct;

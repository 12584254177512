import config from "../../config";

class ChatHelper {
  constructor() {
    this.sellerRole = "operator";
    this.vendorRole = "provider";
  }

  hide = () => {
    const hideChat = () => {
      // window.tidioChatApi.hide();
      window.tidioChatApi.display(false);
    };
    if (!window.tidioChatApi) {
      document.addEventListener("tidioChat-ready", hideChat);
    } else {
      window.tidioChatApi.on("ready", hideChat);
    }
    // window.customerly("hide");
    // window.Intercom && window.Intercom("shutdown");
  };

  show = () => {
    if (config.muteChatIntegration) {
      return;
    }

    const showChat = () => {
      // window.tidioChatApi.show();
      window.tidioChatApi.display(true);
    };

    if (!window.tidioChatApi) {
      document.addEventListener("tidioChat-ready", showChat);
    } else {
      window.tidioChatApi.on("ready", showChat);
    }

    // if (!isIntercomVisible) {
    //   return;
    // }
    // window.customerly("show");
    // window.Intercom("boot", {
    //   app_id: window.intercomSettings.app_id,
    //   name,
    //   email,
    // });
  };

  setUserDetails = ({
    city,
    country,
    name,
    email,
    id,
    role,
    plan = {},
    sellerName = "",
    sellerShop = "",
    shop = "",
  }) => {
    if (config.muteChatIntegration) {
      return;
    }

    // if (!isIntercomVisible) {
    //   return
    // }
    // window.Intercom("update", {
    //   name,
    //   email,
    // });
    const isVendor = role === this.vendorRole;
    const isSeller = role === this.sellerRole;

    const attributes = {
      role,
    };

    if (isVendor) {
      attributes.sellerPlan = plan.name || "";
      attributes.sellerPrice = plan.price || "";
      attributes.sellerName = sellerName;
      attributes.sellerShop = sellerShop;
    } else if (isSeller) {
      attributes.plan = plan.name || "";
      attributes.price = plan.price || "";
      attributes.shop = shop || "";
    }

    const onChatApiReady = () => {
      window.tidioChatApi.setVisitorData({
        distinct_id: id, // Unique user ID in your system
        email, // User email
        name, // Visitor name
        city, // City
        country, // Country
      });

      window.tidioChatApi.setContactProperties(attributes);
    };

    if (!window.tidioChatApi) {
      document.addEventListener("tidioChat-ready", onChatApiReady);
    } else {
      window.tidioChatApi.on("ready", onChatApiReady);
    }
  };
}

export default new ChatHelper();

import { gql } from "apollo-boost";

const GET_SELLER_DATA = gql`
  query SellerData($input: GetSellerData!) {
    getSellerData(input: $input) {
      data {
        email {
          address
          verified
        }
        register {
          desc
          title
        }
        _id
        isOtp
        logo
        loginText
        brandName
        footerText
        style {
          header {
            color
            font
          }
          button {
            color
            font
          }
        }
        plan {
          isBranding
        }
        isHideMcInfo
        brand {
          termsLink
          policyLink
        }
      }
      status
      error
    }
  }
`;

export default GET_SELLER_DATA;

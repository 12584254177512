import { gql } from "apollo-boost";

const GET_VENDOR_LIST = gql`
  query getConsignmentVendorList($input: ShippingLabelInput!) {
    getConsignmentVendorList(input: $input) {
      status
      data {
        label
        value
      }
      error
    }
  }
`;
export { GET_VENDOR_LIST };

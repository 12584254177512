import React, { useContext } from "react";
import { Layout, Stack, Button, DisplayText } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

import html2canvas from "html2canvas";
import * as JsPDF from "jspdf";

import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

require("jspdf-autotable");

const OrderSlipView = (props) => {
  const { currentUser } = useContext(PrivateContext);
  const { sellerInfo, orderData, logo, orderLineItems, vendorInfo, fulfillment = {} } = props;
  const { t } = useTranslation();

  const { type = "" } = fulfillment || {};

  const { priceTaxRate = 0, commissionSeller } = orderLineItems || {};

  const tax = baseHelper.getPrice(parseFloat(priceTaxRate || 0) / 100);

  const fulfillmentData =
    fulfillment && type === constant.CONSIGNMENT && baseHelper.fulfillmentDetails(fulfillment, currentUser);

  const getVendorShippingAddress = (data, users) => {
    const { address = "", city = "", pincode = "", country = "" } = data || {};
    const { firstName = "", lastName = "", phoneNumber = "" } = users || {};
    return (
      <>
        {firstName} {lastName}
        {firstName || lastName ? <br /> : ""}
        {phoneNumber}
        {phoneNumber ? <br /> : ""}
        {address}
        {address ? <br /> : ""}
        {city}
        {city ? <br /> : ""}
        {country}
        {country ? <br /> : ""}
        {pincode}
      </>
    );
  };

  const getShippingAddress = (sName, sAddress) => (
    <>
      {sName}
      <br />
      {sAddress.addressFormatted}
      <br />
      {sAddress.city}
      <br />
      {sAddress.country}
      {sAddress.pinCode ? `, ${sAddress.pinCode}` : ""}
    </>
  );

  const pdfToHTML = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new JsPDF("pt");
      pdf.addImage(imgData, "PNG", 5, 5, 200, 287);
      pdf.save(`${orderData.shopifyOrderId}.pdf`);
    });
  };
  const totals = baseHelper.getOrderPrice([orderLineItems], orderData);
  const vAddress = baseHelper.getFullAddress(vendorInfo);
  const vName = baseHelper.getFullName(vendorInfo);
  const sAddress = baseHelper.getFullAddress(sellerInfo);
  const sName = baseHelper.getFullName(sellerInfo);
  const isVendor = baseHelper.isVendor(currentUser);
  const totalPriceInclusiveTax = baseHelper.getPrice(orderLineItems.price * orderLineItems.quantity);
  const priceExclusiveTax = baseHelper.getPrice(totalPriceInclusiveTax / (1 + parseFloat(tax)));
  const totalTax = parseFloat(totalPriceInclusiveTax) - parseFloat(priceExclusiveTax);
  const sellerCommissionInPercent = (commissionSeller / parseFloat(totalPriceInclusiveTax)) * 100;
  const sellerTaxOnCommission = totalTax * (sellerCommissionInPercent / 100);

  // const { sellerCommissionTax, commissionSeller, vendorCommissionTax, commissionVendor } = orderLineItems || {};

  return (
    <>
      <Layout>
        <Layout.Section>
          <Stack>
            <Stack.Item>
              <Button primary onClick={pdfToHTML}>
                {t("label.invoiceSlip.download")}
              </Button>
            </Stack.Item>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          <Stack>
            <Stack.Item>
              <div id="divToPrint" className="invoice-box">
                <Table responsive>
                  <tbody>
                    <tr className="top">
                      <td colSpan="3">
                        <table>
                          <tbody>
                            <tr>
                              <td className="title text-capitalize">
                                {(logo && (
                                  <img
                                    src={logo}
                                    id="tableBanner"
                                    style={{ width: "200px", maxHeight: "100px" }}
                                    alt=""
                                  />
                                )) || <DisplayText size="medium">{sellerInfo.brandName}</DisplayText>}
                              </td>
                              <td>
                                {`${t("label.invoiceSlip.invoiceNumber")} ${orderLineItems.shopifyOrderId}`}
                                <br />
                                {`${t("label.invoiceSlip.created")}${baseHelper.formatDate(orderLineItems.createdAt)}`}
                                <br />
                                {/* Due: February 1, 2015 */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className="information">
                      <td colSpan="3">
                        <table>
                          <tbody>
                            <tr>
                              <td className="text-capitalize">
                                {vName}
                                <br />
                                {vAddress.addressFormatted}
                                <br />
                                {vAddress.city}
                                <br />
                                {vAddress.country}
                                {vAddress.pinCode ? `, ${vAddress.pinCode}` : ""}
                                <br />
                              </td>

                              <td className="text-capitalize">
                                {t("label.invoiceSlip.invoiceStatus")}
                                <br />
                                {orderLineItems.accountingStatus || t("label.invoiceSlip.unpaid")}
                                <br />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <tr className="heading">
                      <td>{t("label.invoiceSlip.item")}</td>
                      <td>{t("label.invoiceSlip.quantity")}</td>
                      <td style={{ textAlign: "right" }}>{t("label.invoiceSlip.price")}</td>
                    </tr>

                    <tr className="item">
                      <td>{orderLineItems.name}</td>
                      <td>{orderLineItems.quantity || 0}</td>
                      <td style={{ textAlign: "right" }}>
                        {`${orderLineItems.moneyFormat} ${baseHelper.getPrice(totalPriceInclusiveTax)}`}
                        {!priceTaxRate ? "" : `(${orderLineItems.moneyFormat}${baseHelper.getPrice(totalTax)} Tax)`}
                      </td>
                    </tr>

                    <tr className="item">
                      <td colSpan="2">{t("label.invoiceSlip.shipping")}</td>

                      <td>{`${orderLineItems.moneyFormat} ${baseHelper.getPrice(totals.shippingAmount)}`}</td>
                    </tr>

                    <tr className="item">
                      <td colSpan="2">{`Tax ${orderData.taxesIncluded ? "(Included)" : ""}`}</td>

                      <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(orderLineItems.totalTax)}`}</td>
                    </tr>

                    <tr className="total">
                      <td colSpan="2" />

                      <td>
                        {`${t("label.invoiceSlip.total")}${orderLineItems.moneyFormat} ${baseHelper.getPrice(
                          totals.totalPrice
                        )}`}
                      </td>
                    </tr>

                    <tr className="total">
                      <td />
                      <td />
                    </tr>
                    <tr className="item">
                      <td colSpan="2">{t("label.invoiceSlip.vendorAmount")}</td>

                      <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(orderLineItems.commissionVendor)}`}</td>
                    </tr>
                    {/* {
                      !vendorCommissionTax ? (
                        <tr className="item">
                          <td colSpan="2">{t("label.invoiceSlip.vendorAmount")}</td>

                          <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(orderLineItems.commissionVendor)}`}</td>
                        </tr>
                      ) : (
                          <>
                            <tr className="item">
                              <td >{t("label.invoiceSlip.vendorAmount")}</td>
                              <td>Goods</td>
                              <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(commissionVendor - vendorCommissionTax)}`}</td>
                            </tr>
                            <tr className="item">
                              <td />
                              <td>Tax</td>
                              <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(vendorCommissionTax)}`}</td>
                            </tr>
                            <tr className="item">
                              <td />
                              <td><strong>Total</strong></td>
                              <td>
                                <strong>
                                  {`${orderLineItems.moneyFormat}${baseHelper.getPrice(commissionVendor)}`}
                                </strong>
                              </td>
                            </tr>
                          </>
                        )
                    } */}
                    {!priceTaxRate ? (
                      <tr className="item">
                        <td colSpan="2">{t("label.invoiceSlip.sellerCommission")}</td>

                        <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(commissionSeller)}`}</td>
                      </tr>
                    ) : (
                      <>
                        <tr className="item">
                          <td>{t("label.invoiceSlip.sellerCommission")}</td>
                          <td>Tax inclusive</td>
                          <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(commissionSeller)}`}</td>
                        </tr>
                        <tr className="item">
                          <td />
                          <td>Tax</td>
                          <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(sellerTaxOnCommission)}`}</td>
                        </tr>
                      </>
                    )}
                    {/* {
                      !sellerCommissionTax ? (
                        <tr className="item">
                          <td colSpan="2">{t("label.invoiceSlip.sellerCommission")}</td>

                          <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(orderLineItems.commissionSeller)}`}</td>
                        </tr>
                      ) : (
                          <>
                            <tr className="item">
                              <td >{t("label.invoiceSlip.sellerCommission")}</td>
                              <td>Goods</td>
                              <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(commissionSeller - sellerCommissionTax)}`}</td>
                            </tr>
                            <tr className="item">
                              <td />
                              <td>Tax</td>
                              <td>{`${orderLineItems.moneyFormat}${baseHelper.getPrice(sellerCommissionTax)}`}</td>
                            </tr>
                            <tr className="item">
                              <td />
                              <td><strong>Total</strong></td>
                              <td>
                                <strong>
                                  {`${orderLineItems.moneyFormat}${baseHelper.getPrice(commissionSeller)}`}
                                </strong>
                              </td>
                            </tr>
                          </>
                        )
                    } */}
                    <tr className="information">
                      <td colSpan="3">
                        <table>
                          <tbody>
                            <tr>
                              <td className="text-capitalize">
                                {(isVendor &&
                                  type === constant.CONSIGNMENT &&
                                  getVendorShippingAddress(fulfillmentData, sellerInfo)) ||
                                  getShippingAddress(sName, sAddress)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Stack.Item>
          </Stack>
        </Layout.Section>
      </Layout>
    </>
  );
};
OrderSlipView.defaultProps = {
  sellerInfo: {},
  vendorInfo: {},
  orderData: {},
  logo: "",
  orderLineItems: [],
};
OrderSlipView.propTypes = {
  sellerInfo: PropTypes.shape(PropTypes.object),
  vendorInfo: PropTypes.shape(PropTypes.object),
  orderData: PropTypes.shape(PropTypes.object),

  logo: PropTypes.string,
  orderLineItems: PropTypes.arrayOf(PropTypes.object),
};

export default OrderSlipView;

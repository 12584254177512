import React, { useState, useEffect } from "react";
import { Stack, Modal, ChoiceList } from "@shopify/polaris";
import { useMutation, useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

// constant
import constant from "lib/constant/constant";

// import helpers
import { baseHelper } from "lib/helpers";

// import yup
import { schema } from "../yup/schema";
import validate from "../yup";

// import propTypes
import { viewProp } from "../propTypes";

// import gql
import PAY_VENDOR from "../../../../../apollo/mutation/payVendor";
import { GET_SELLER_SETTING } from "../../../../../../setup/apollo/queries";

const View = (props) => {
  const { t } = useTranslation();
  const modalData = [
    {
      label: t("label.PayViaStripe"),
      value: constant.STRIPE,
      name: "Radio Group",
      helpText: t("placeHolder.StripeHelptext"),
    },
    {
      label: t("label.PayViaPaypal"),
      value: constant.PAYPAL,
      name: "Radio Group",
      helpText: t("placeHolder.PaypalHelptext"),
    },
    {
      label: t("label.PayManual"),
      value: constant.MANUAL,
      name: "Radio Group",
      helpText: t("placeHolder.ManualHelptext"),
    },
  ];
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  const [isExcludeShippingAmount, setIsExcludeShippingAmount] = useState(false);
  const {
    orderLineItemId,
    handleClose,
    vendor,
    active,
    commissionVendor,
    moneyFormat,
    setBanner,
    shippingAmount,
  } = props;
  const [payVendor, { loading }] = useMutation(PAY_VENDOR);
  const { data: sellerSetting } = useQuery(GET_SELLER_SETTING);

  const amount = isExcludeShippingAmount ? commissionVendor : (Number(commissionVendor) + Number(shippingAmount));

  useEffect(() => {
    if (!sellerSetting) {
      return;
    }
    const sellerData = baseHelper.getResponseData(sellerSetting, constant.gql.GET_SELLER_SETTING);
    if (!sellerData) {
      return;
    }

    const { shipping = {} } = sellerData;
    const { isExcludeShippingAmount = false } = shipping || {};

    setIsExcludeShippingAmount(isExcludeShippingAmount);
  }, [sellerSetting]);

  if (selected) {
    schema(selected);
  }
  const submit = async () => {
    const validateError = await validate(selected);
    if (selected.length === 0) {
      setError(validateError);
      return;
    }
    try {
      const response = await payVendor({ variables: { input: { paymentMethod: selected[0], orderLineItemId } } });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.PAY_VENDOR);
      const bannerValue = {
        isOpen: true,
        title: `${t("label.Paid")}  ${moneyFormat}  ${amount} ${t("label.To")} ${vendor} ${t("label.Successfully")}`,
        status: "success",
      };
      if (responseError) {
        bannerValue.title = response.data.payVendor.error;
        bannerValue.status = "critical";
      }
      setBanner(bannerValue);
      setError(false);
    } catch (e) {
      setBanner({
        isOpen: true,
        title: t("error.common.somethingWentWrong"),
        status: "critical",
      });
    }
    setSelected("");
    handleClose();
  };
  const handleChange = (e) => {
    setSelected(e);
    setError(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={`${t("label.Paid")} ${vendor} ${moneyFormat} ${amount}`}
      primaryAction={{
        content: t("label.PayVendor"),
        onAction: () => {
          submit();
        },
        loading,
      }}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>
            <ChoiceList choices={modalData} selected={selected} onChange={handleChange} error={error} />
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

View.propTypes = viewProp.type;

export default View;

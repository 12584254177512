import React, { useCallback, useState } from "react";
import { Banner, Button, Modal, Stack, Icon, Card, Subheading, TextContainer, TextStyle } from "@shopify/polaris";
import { ChatMajorMonotone } from "@shopify/polaris-icons";

const LargeModalExample = (props) => {
  const [active, setActive] = useState(true);
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  return (
    <div>
      <Modal medium open={false} onClose={toggleActive} title="Advanced commission">
        <Modal.Section>
          <TextContainer>
            <Banner status="info">
              <p>Advanced commission can be setup once you have added Product(s) and Vendor(s).</p>
            </Banner>
            <Banner status="critical">
              <p>
                You do not have access to the Advanced Commission feature on your current plan. You will need to
                upgrade.
              </p>
            </Banner>
            <Card sectioned>
              <TextStyle>
                <p>
                  This feature will extend the commisson setting capbility to allow you to setup up different commissons
                  by Vendor and Product category.
                </p>

                <br />
                <Subheading>Vendor based</Subheading>
                <p>Flat rate is a set amount charged on all orders e.g. $2 on every order</p>
                <br />
                <Subheading>Product category</Subheading>
                <p>Percentage commission is a set percentage of order value charged on all orders e.g. 10 %</p>
                <br />
                <p>You can revist this setting after completing the onboarding steps.</p>
              </TextStyle>
            </Card>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default LargeModalExample;

const settingList = [
  {
    code: "marketplace",
    title: "Your Account",
    description:
      "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
    thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
    list: [
      {
        title: "User",
        description: "User Settings: etc",
        icon: "fal fa-user fa-lg",
        code: "user",
        thumbnailHeader: "User settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Profile",
            link: "/profile",
            code: "profile",
            description: "",
          },
          {
            label: "Customize Email Templates",
            code: "emailTemplate",
            link: "/email-template",
            description: "",
          },
          {
            label: "Onboarding History",
            code: "onboardingHistory",
            link: "/onboard-activity",
            description: "",
          },
          {
            label: "User Session",
            code: "session",
            link: "/session",
            description: "",
          },
          {
            label: "Association",
            code: "association",
            link: "/association",
            description: "",
          },
          {
            label: "Automatic Payouts",
            code: "automaticPayouts",
            link: "/payouts",
            description: "",
          },
          {
            label: "Maintenance",
            code: "maintenance",
            link: "/maintenance",
            description: "",
          },
          {
            label: "Activate Custom Domain",
            code: "customDomain",
            link: "/domain",
            description: "",
          },
          {
            label: "Feature Panel",
            code: "feature",
            link: "/feature",
            description: "",
          },
        ],
      },
    ],
  },
];

export default settingList;

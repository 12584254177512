import { gql } from "apollo-boost";

const RESEND_VERIFY_EMAIL = gql`
  mutation resendEmail($input: Email!) {
    resendEmail(input: $input) {
      data
      status
      error
    }
  }
`;

const VERIFY_EMAIL = gql`
  mutation verifyEmail($input: VerifyEmail) {
    verifyEmail(input: $input) {
      data
      status
      error
    }
  }
`;

export { RESEND_VERIFY_EMAIL, VERIFY_EMAIL };

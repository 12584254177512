// import react packages;
import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// Import Polaris Components
import { TextField, Card, Stack, PageActions, TextContainer } from "@shopify/polaris";

// helper
import { PrivateContext, OnboardingContext } from "lib/context";
import { baseHelper, errorHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { useTranslation } from "react-i18next";

import { IS_WOOCOMMERCE_STORE_CONNECTED } from "app/products/apollo/mutations/wooCommerceConnect";

import { SyncWooCommerceProduct } from "../../../subFeatures";

// import components
import TermModal from "./subFeatureItems/term";
import ConnectedShop from "./subFeatureItems/connected";

const VendorConnectWoocommerce = (props) => {
  const { cms, currentUser: currentUserData, history } = useContext(PrivateContext);
  const { wooCommerce: wooCommerceConfig = {} } = (currentUserData && currentUserData.storeConfig) || {};
  const { shop = "", accessKey = "", accessToken = "" } = wooCommerceConfig || {};
  const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const { t } = useTranslation();
  const { setBanner } = props;
  const [value, setValue] = useState({
    consumerKey   : accessKey || "",
    consumerSecret: accessToken || "",
    shopDomain    : shop || ""
  });
  const [isConnected, setIsConnected] = useState(!!(shop));
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    shopDomain: "",
    consumerKey: "",
    consumerSecret: ""
  });

  const [isWooCommerceConnected, { loading }] = useMutation(IS_WOOCOMMERCE_STORE_CONNECTED);
  const { IS_WOOCOMMERCE_STORE_CONNECTED: STORE_CONNECT } = constant.gql;

  const onSubmit = async () => {
    const { consumerKey = "", consumerSecret = "", shopDomain = '' } = value;
    const isValidSubDomain = baseHelper.isValidSubDomain(shopDomain.trim());
    if (!isValidSubDomain) {
      setBanner({ isOpen: true, status: "critical", title: t("message.ProductAdd.InvalidShopDomain") });
      setIsOpen(false);
      return;
    }
    try {
      const response = await isWooCommerceConnected({
        variables: { input: {
          consumerKey   : (consumerKey || "").trim(),
          consumerSecret: (consumerSecret || "").trim(),
          shopDomain    : (shopDomain || "").trim(),
        },
       },
      });
      const responseError = baseHelper.getResponseError(response.data, STORE_CONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        setIsOpen(false);
        return;
      }
      const responseData = baseHelper.getResponseData(response.data, STORE_CONNECT);
      if(responseData){
        setIsConnected(true);
        setBanner({ isOpen: true, status: "success", title: `${cms("section.connect.message.success.connected")}`});
        setIsOpen(false);
        return;
      }
      
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: (fieldValue || "").trim(),
    });
  };

  const handleTermModal = (fieldName = "check", modalValue = "") => {
    if (!value.shopDomain || !value.consumerSecret || !value.consumerKey) {
      setIsOpen(false);

      const errorMessages = {
        consumerKey: t("message.ProductAdd.WooCommerceRequired", { item: "Consumer Key" }),
        consumerSecret: t("message.ProductAdd.WooCommerceRequired", { item: "Consumer Secret" }),
        shopDomain: t("message.ProductAdd.WooCommerceRequired", { item: "Shop Domain" }),
      };

      setErrorMessage({
        ...errorMessage,
          [fieldName]: !value[fieldName] ? errorMessages[fieldName] : "",
      });

      return;
    }
    if (modalValue === constant.CONNECT) {
      setIsOpen(true);
    }
    setErrorMessage("");
  };

  const bottomSpace = {
    marginBottom: "10px",
  };

  return (
    <>
      <TermModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shopDomain={value.shopDomain}
        useTranslation={useTranslation}
        setBanner={setBanner}
        loading={loading}
        onSubmit={onSubmit}
      />
      <Card title={cms("section.connect.title")}>
        <Card.Section>
          <TextContainer>
          {cms("section.connect.description")}
          </TextContainer>
          <br />
          <div style={bottomSpace}>
          <TextField
             label={`${cms("section.connect.label.shopDomain")}*`}
            error={errorMessage.shopDomain}
            id="shopInput"
            prefix="https://"
            placeholder="demo-example.com"
            type={constant.TEXT}
            value={value.shopDomain}
            onChange={(inputValue) => handleChange(constant.SHOP_DOMAIN, inputValue)}
            onBlur={() => handleTermModal(constant.SHOP_DOMAIN)}
            disabled={isConnected}
          />
          </div>
          { !isConnected ?  (<div style={bottomSpace}><TextField
            label={`${cms("section.connect.label.wooCommerceConsumerKey")}*`}
            error={errorMessage.consumerKey}
            id="consumerKey"
            placeholder="ck_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
            type={constant.TEXT}
            value={value.consumerKey}
            onChange={(inputValue) => handleChange(constant.CONSUMER_KEY, inputValue)}
            onBlur={() => handleTermModal(constant.CONSUMER_KEY)}
            disabled={isConnected}
          
          /></div>):null
}
          {!isConnected ? <TextField
            label={`${cms("section.connect.label.wooCommerceConsumerSecret")}*`}
            error={errorMessage.consumerSecret}
            id="consumerSecret"
            placeholder="cs_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
            type={constant.TEXT}
            value={value.consumerSecret}
            onChange={(inputValue) => handleChange(constant.CONSUMER_SECRET, inputValue)}
            onBlur={() => handleTermModal(constant.CONSUMER_SECRET)}
            disabled={isConnected}
          /> : null
          }
          <br />
          {isConnected && (
            <Stack>
              <Stack.Item fill>
                <ConnectedShop
                  cms={cms}
                  useTranslation={useTranslation}
                  currentUserData={currentUserData}
                  setBanner={setBanner}
                  setIsConnected={setIsConnected}
                  setValue={setValue}
                  value={value}
                />
              </Stack.Item>
              <Stack.Item>
                <SyncWooCommerceProduct history={history} setBanner={setBanner} cms={cms}/>
              </Stack.Item>
            </Stack>
          )}
        </Card.Section>
      </Card>
      {!isConnected && (
        <PageActions
          primaryAction={{
            id: "connectShop",
            content: `${cms("section.connect.button.connect")}`,
            onAction: () => handleTermModal("check ", constant.CONNECT),
            disabled: isConnected,
          }}
          secondaryActions={[
            {
              id: "cancelAction",
              content: `${cms("section.connect.button.cancel")}`,
              onAction: () => (isOnboarding && onPrevious()) || history.push("/"),
            },
          ]}
        />
      )}
    </>
  );
};

VendorConnectWoocommerce.propTypes = {
  setBanner: () => {},
};

VendorConnectWoocommerce.defaultProps = {
  setBanner: () => { },
};

export default withFeature(VendorConnectWoocommerce, {   
  feature: constant.SHOP_CONNECT,
  subFeature: constant.WOOCOMMERCE, });

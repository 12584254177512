import React, { useContext } from "react";
import { ResourceList, TextStyle } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import propTypes
import { listProps } from "../props";

const List = (props) => {
  const { items = {}, selected = 0, selectedItems = {}, handleSelection, orderLoading, onExport = () => {} } = props;
  const { cms } = useContext(PrivateContext);
  const { label = {} } = cms || {};

  const promotedBulkActions = [
    {
      content: "Generate CSV",
      onAction: () => onExport(),
    },
  ];

  return (
    <>
      <ResourceList
        key="resourceList"
        items={items[selected]}
        renderItem={(item) => {
          const { id, name } = item;
          return (
            <ResourceList.Item id={id} accessibilityLabel={`View details for ${id}`}>
              <h3>
                <TextStyle variation="strong">{name}</TextStyle>
              </h3>
            </ResourceList.Item>
          );
        }}
        selectedItems={selectedItems[selected]}
        onSelectionChange={handleSelection}
        selectable
        promotedBulkActions={promotedBulkActions}
        loading={orderLoading}
      />
    </>
  );
};

List.propTypes = listProps.type;
List.defaultProps = listProps.default;

export default List;

import React from "react";
import { Caption, ResourceItem, Stack, Badge, TextStyle, Link } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// import from lib
import { baseHelper } from "lib/helpers";
import Popover from "lib/components/popover/popover";
import constant from "lib/constant/constant";

import cmsSellerList from "../../cms/sellerListCMS";

const SellerListView = (props) => {
  const { item, keyIndex, onUpdateSeller, onLoginSeller, activePopover, setActivePopover } = props;
  const { _id, email, brandName, plan, roles } = item;
  const { address, verified } = email;
  const { name: planName = "" } = plan || {};
  const { name: role } = roles;
  const { t } = useTranslation();
  const sellerListCMS = cmsSellerList(t);
  const { button, label } = sellerListCMS;

  const topMargin10px = {
    marginTop: "10px",
  };

  const handleSelectAction = (selectAction, id, operatorBrandName, operatorRole) => {
    if (selectAction === "login") {
      onLoginSeller(id, operatorBrandName, operatorRole);
    } else {
      onUpdateSeller(id, operatorBrandName, operatorRole);
    }
  };

  return (
    <>
      <ResourceItem id={_id} sortOrder={keyIndex} accessibilityLabel={`${label.viewDetailsFor} ${brandName}`}>
        <Stack>
          <Stack.Item fill>
            <Link url={`/operators/view/${_id}`}>
              <TextStyle>{baseHelper.ucFirst(brandName)}</TextStyle>
            </Link>
            <Caption>
              <Stack.Item>{`${label.email} ${address}`}</Stack.Item>
              <Stack.Item>{`${label.plan}: ${planName}`}</Stack.Item>
            </Caption>
            <div style={topMargin10px}>
              <Stack>
                <Stack.Item>
                  <Badge status={verified ? "success" : "new"}>{verified ? constant.APPROVED : constant.PENDING}</Badge>
                </Stack.Item>
              </Stack>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Popover
              // eslint-disable-next-line no-underscore-dangle
              active={activePopover[_id]}
              // eslint-disable-next-line no-underscore-dangle
              setActive={() => setActivePopover({ [_id]: !activePopover[_id] })}
              options={[
                {
                  content: button.updatePlan,
                  onAction: () => handleSelectAction("updatePlan", _id, brandName, role),
                },
                {
                  content: button.loginSeller,
                  onAction: () => handleSelectAction("login", _id, brandName, role),
                },
              ]}
            />
          </Stack.Item>
        </Stack>
      </ResourceItem>
    </>
  );
};

SellerListView.defaultProps = {
  item: {},
  keyIndex: "",
  onUpdateSeller: () => {},
  onLoginSeller: () => {},
  activePopover: {},
};

SellerListView.propTypes = {
  item: PropTypes.objectOf(PropTypes.shape),
  keyIndex: PropTypes.number,
  onUpdateSeller: PropTypes.func,
  onLoginSeller: PropTypes.func,
  activePopover: PropTypes.objectOf(),
  setActivePopover: PropTypes.func.isRequired,
};

export default SellerListView;

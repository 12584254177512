import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { Card, Tabs } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import lib
import { baseHelper } from "lib/helpers";
import { Banner, ResourceList } from "lib/components";
import constant from "lib/constant/constant";

// import gql
import { GET_ACTIVITY } from "../../../../apollo/queries";

import ActivityListView from "./activityListView";
import ActivityListFooter from "./activityListPagination";

const ActivityList = () => {
  const { history } = useContext(PrivateContext);
  const { t } = useTranslation();
  const { gql } = constant;
  const prevFilter = baseHelper.queryParamsFromLocation(history);
  const [selected, setSelected] = useState(parseInt(prevFilter.tab, 10) || 0);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevFilter.perPage, 10) || 10);
  const [currentPage, setCurrentPage] = useState(parseInt(prevFilter.page, 10) || 1);
  const [bulkLog, setBulkLog] = useState(false);
  const [queryValue, setQueryValue] = useState(prevFilter.search || null);
  const selectedSort = prevFilter.sort_name && `${prevFilter.sort_name}_${prevFilter.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || "createdAt_desc");
  const [totalLog, setTotalLog] = useState(0);
  const [dataToFetch, setDataToFetch] = useState({
    search: prevFilter.search,
    filter: prevFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevFilter.sort_name || "createdAt",
    sort_order: prevFilter.sort_order || "desc",
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    message: "",
    status: "",
  });

  const inputData = {
    search: dataToFetch.search,
    filter: dataToFetch.filter,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
  };

  const { data: activityData, loading: activityLoading, error: activityError } = useQuery(GET_ACTIVITY, {
    variables: {
      input: inputData,
    },
  });

  useEffect(() => {
    if (activityError) {
      setBanner({ status: "critical", title: t("error.common.somethingWentWrong"), isOpen: true });
    }
  }, [activityError, t]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(activityData, gql.GET_ACTIVITY);
    const responseError = baseHelper.getResponseError(activityData, gql.GET_ACTIVITY);
    if (responseData) {
      const { count: totalCount = 0 } = responseData;
      setBulkLog(responseData.activity);
      setTotalLog(totalCount);
    }
    if (responseError) {
      setBanner({ status: "critical", title: responseError, isOpen: true });
    }
  }, [activityData, gql.GET_ACTIVITY]);

  const renderItem = (item, _, keyIndex) => {
    return <ActivityListView item={item} keyIndex={keyIndex} />;
  };

  const tabs = [
    {
      id: "allActivity",
      content: "All",
      accessibilityLabel: "All",
      panelID: "allActivity",
      value: "all",
    },
    {
      id: "orderActivity",
      content: "Order",
      accessibilityLabel: "Order",
      panelID: "orderActivity",
      value: "order",
    },
    {
      id: "productActivity",
      content: "Product",
      accessibilityLabel: "Product",
      panelID: "productActivity",
      value: "product",
    },
  ];

  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleQueryValueChange = (value) => {
    baseHelper.setUrl(history, { search: value });
    setQueryValue(value);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleTabChange = (selectedTabIndex) => {
    const { value: tabName } = tabs[selectedTabIndex];
    setSelected(selectedTabIndex);
    baseHelper.setUrl(history, { tab: selectedTabIndex, filter: tabName });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleSearchTextRemove = () => {
    setQueryValue(null);
    baseHelper.setUrl(history, { search: "" });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleClearAll = () => {
    handleSearchTextRemove();
  };

  const handleQueryValueRemove = () => {
    baseHelper.setUrl(history, { search: "" });
    setQueryValue("");
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedItem);
      const filteredObj = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filteredObj });
    }
  };

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: parseInt(value, 10) });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...result });
  };

  const sortOptions = [
    { label: t("label.logsList.selectSort"), value: "" },
    { label: t("label.logsList.sortByDateAsc"), value: constant.CREATED_ASC },
    { label: t("label.logsList.sortByDateDesc"), value: constant.CREATED_DESC },
  ];

  const limitOptions = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            status={banner.status}
            title={banner.title}
            isOpen={banner.isOpen}
            onDismiss={() => {
              setBanner({ status: "", title: "", isOpen: false });
            }}
          />
          <br />
        </>
      )}
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <ResourceList
            resourceName={{
              singular: t("label.logsList.activityLogSingular"),
              plural: t("label.logsList.activityLogPlural"),
            }}
            items={bulkLog || []}
            renderItem={renderItem}
            onQueryChange={handleQueryValueChange}
            onQueryClear={handleQueryValueRemove}
            handleClearAll={handleClearAll}
            queryValue={queryValue}
            sortValue={sortValue}
            sortOptions={sortOptions}
            onSortChange={sortFilter}
            loading={activityLoading}
          />
        </Tabs>
        <ActivityListFooter
          optionsList={limitOptions}
          onSelectLimit={handleSelectLimit}
          perPageCount={selectedLimit}
          totalCount={totalLog}
          onPerPage={handlePerPage}
          currentPage={currentPage}
        />
      </Card>
    </>
  );
};

export default withFeature(withErrorBoundary(ActivityList), { feature: constant.ACTIVITY_LIST });

import React, { useState, useEffect, useContext } from "react";
import { Layout, Page } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { PrivateContext } from "lib/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import from lib
import constant from "lib/constant/constant";
import { Banner, Spinner } from "lib/components";
import { baseHelper } from "lib/helpers";
import { NotFound } from "layout/private/components";

// import cms
import GET_ORDER_DETAILS from "app/orders/apollo/queries/orderSlip";
import OrderSlipView from "app/orders/modules/generic/slip/subFeature/orderSlipView";

const AdminOrderSlip = () => {
  const { match } = useContext(PrivateContext);
  const { params } = match;
  const { id } = params;
  const defaultLogo = constant.MARKET_CUBE;
  const [sellerInfo, setSellerInfo] = useState(false);
  const [logo, setLogo] = useState(false);
  const [isPackingSlipFound, setIsPackingSlipFound] = useState(true);
  const [orderData, setOrderData] = useState(false);
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });

  const { t } = useTranslation();

  const { error: orderDetailsError, loading: orderDetailsLoading, data: orderDetailsData } = useQuery(
    GET_ORDER_DETAILS,
    {
      variables: { input: { id } },
    }
  );

  const imageToDataURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  // getOrderSlipDetails
  useEffect(() => {
    const orderSlipResponseData = baseHelper.getResponseData(orderDetailsData, constant.gql.GET_ORDER_DETAILS);
    const orderSlipResponseError = baseHelper.getResponseError(orderDetailsData, constant.gql.GET_ORDER_DETAILS);
    if (orderSlipResponseData) {
      setIsPackingSlipFound(true);
      const { isNotFound = false } = orderSlipResponseData || {};
      if (!isNotFound) {
        setNotFound(true);
      }
      const { orderDataRow, orderLineItemsData = [], seller } = orderSlipResponseData;
      if (seller && seller.plan && seller.plan.isBranding) {
        imageToDataURL(seller.logo, (url) => {
          setOrderData({ ...orderDataRow });
          setOrderLineItems(orderLineItemsData);
          setSellerInfo(seller);
          setLogo(seller.logo && url);
        });
      } else {
        imageToDataURL(defaultLogo, (url) => {
          setOrderData({ ...orderDataRow });
          setOrderLineItems(orderLineItemsData);
          setSellerInfo(seller);
          setLogo(url);
        });
      }
    }
    if (orderSlipResponseError) {
      setIsPackingSlipFound(false);
      setNotFound(true);
      setBanner({ isOpen: true, title: orderSlipResponseError, status: "critical" });
    }
  }, [defaultLogo, orderDetailsData]);
  // end

  useEffect(() => {
    if (orderDetailsError) {
      setIsPackingSlipFound(false);
      setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
    }
  }, [orderDetailsError, t]);

  if (orderDetailsLoading) {
    return <Spinner isFullPage />;
  }

  if (notFound) {
    return (
      <div className="Polaris-Page__OrderSlip">
        <NotFound />
      </div>
    );
  }
  return (
    <div className="Polaris-Page__OrderSlip">
      <Page>
        {banner.isOpen && (
          <Layout.Section>
            <Banner
              title={banner.title}
              status={banner.status}
              message={banner.message}
              onDismiss={() => {
                setBanner({ isOpen: false, title: "", status: "" });
              }}
            />
          </Layout.Section>
        )}
        {isPackingSlipFound && (
          <OrderSlipView sellerInfo={sellerInfo} orderData={orderData} logo={logo} orderLineItems={orderLineItems} />
        )}
      </Page>
    </div>
  );
};

export default withFeature(withErrorBoundary(AdminOrderSlip), { feature: constant.ORDER_SLIP });

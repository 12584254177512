import { gql } from "apollo-boost";

const INVOICE_DATA = gql`
  query getInvoiceData($input: GetInvoiceData!) {
    getInvoiceData(input: $input) {
      data {
        order {
          _id
          confirmed
          currency
          status
          shopifyOrderId
        }
        orderLine {
          name
          sellerCommissionTax
          vendorCommissionTax
          commissionSeller
          commissionVendor
          accountingStatus
          createdAt
          price
          productId
          priceTaxRate
          quantity
          shippingAmount
          status
          totalDiscount
          moneyFormat
          markUpPrice
          sellerDiscount
          totalTax
        }
        seller {
          firstName
          lastName
          phoneNumber
          address
          city
          logo
          country
          plan {
            isBranding
            name
            price
          }
          pinCode
          brandName
        }
        orderLine {
          _id
          accountingStatus
          commissionSeller
          commissionSysAdmin
          commissionVendor
          createdAt
          customCharge
          customLabel
          discountAllocations {
            amount
            discount_application_index
          }
          dueDate
          fulfillableQuantity
          fulfillmentService
          fulfillmentStatus
          grams
          id
          image
          isDeleted
          isGiftCard
          isProductDeleted
          isShippingPaid
          isVendorDeleted
          markUpPrice
          moneyFormat
          name
          orderId
          orderNumber
          paidVia
          price
          vendorDiscount
          vendorId
          vendor
          variantTitle
          priceSet {
            shopMoney {
              amount
              currencyCode
            }
            presentmentMoney {
              amount
              currencyCode
            }
          }
          productExists
          productId
          properties {
            name
            value
          }
          quantity
          requiresShipping
          sellerDiscount
          sellerId
          shippingAmount
          shop
          shopifyLineItemId
          shopifyOrderDate
          shopifyOrderId
          shopifyOrderUpdateDate
          status
          totalDiscount
          totalDiscountSet {
            shopMoney {
              amount
              currencyCode
            }
            presentmentMoney {
              amount
              currencyCode
            }
          }
          totalTax
          totalDiscount
          taxLines {
            price
            rate
            title
            price_set {
              shopMoney {
                amount
                currencyCode
              }
              presentmentMoney {
                amount
                currencyCode
              }
            }
          }
          updatedAt
          trackingCompany
          trackingNumber
        }
        vendor {
          firstName
          lastName
          address
          city
          country
          pinCode
          email {
            address
          }
        }
      }
      status
      error
    }
  }
`;
export default INVOICE_DATA;

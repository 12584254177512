import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Layout, Button, Stack, FormLayout, TextField, Card, Checkbox, TextContainer } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

import { baseHelper } from "lib/helpers";
import { Banner, SkeletonAnnotated, Sheet } from "lib/components";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { withErrorBoundary, withFeature } from "lib/hoc";

import { GET_VENDOR_PROFILE } from "app/vendors/apollo/queries";
import { UPDATE_VENDOR } from "app/vendors/apollo/mutations";

import validate from "./yup";

const EditVendorProfile = () => {
  const { history, setPageData, match } = useContext(PrivateContext);
  const { t } = useTranslation();
  const {
    gql,
    BRAND_HANDLE,
    BRAND_NAME,
    CITY,
    COUNTRY,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PHONE_NUMBER,
    POSTAL_CODE,
    PROVINCE_CODE,
    STREET_ADDRESS,
  } = constant;
  const { params } = match;
  const [errorMessage, setErrorMessage] = useState(false);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [values, setValues] = useState({
    brandHandle: "",
    brandHandleValueExist: false,
    brandName: "",
    checked: false,
    city: "",
    country: "",
    email: "",
    firstName: "",
    lastName: "",
    oldBrandHandle: "",
    oldBrandName: "",
    phoneNumber: "",
    postalCode: "",
    provinceCode: "",
    streetAddress: "",
  });

  const [isTitle, setIsTitle] = useState(false);

  const [updateVendorProfile, { loading }] = useMutation(UPDATE_VENDOR);
  const { data: vendorProfileData, loading: vendorProfileLoading } = useQuery(GET_VENDOR_PROFILE, {
    variables: { input: { vendorId: params.id } },
  });

  const currentVendor = baseHelper.getResponseData(vendorProfileData, gql.GET_VENDOR_PROFILE);

  useEffect(() => {
    if (currentVendor) {
      const { vendorRow = {} } = currentVendor;
      const {
        address,
        brand,
        brandName,
        city,
        country,
        email = {},
        firstName,
        isReadOnly,
        lastName,
        phoneNumber,
        pinCode,
        provinceCode,
      } = vendorRow || {};

      const { slug: brandHandle } = brand || {};
      const { address: emailAddress } = email;

      setValues({
        brandHandle,
        brandHandleValueExist: !!brandHandle,
        brandName,
        checked: isReadOnly,
        city,
        country,
        email: emailAddress,
        firstName,
        lastName,
        oldBrandHandle: brandHandle,
        oldBrandName: brandName,
        phoneNumber,
        postalCode: pinCode,
        provinceCode,
        streetAddress: address,
      });
    }
  }, [currentVendor]);

  const handleBrandChange = (value) => {
    const manipulatedBrandHandleValue = baseHelper.generateBrandHandle(value);
    setValues((prevState) => ({
      ...prevState,
      [BRAND_NAME]: value,
      [BRAND_HANDLE]: manipulatedBrandHandleValue,
    }));
  };

  const handleField = (field, value) => {
    const { brandHandleValueExist } = values;
    if (field === BRAND_NAME && !brandHandleValueExist) {
      handleBrandChange(value);
    }

    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetContent, setSheetContent] = useState("");
  const [sheetTitle, setSheetTitle] = useState("");

  const learnMore = (title, content) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(content);
  };

  const handleBrandValidation = async (value) => {
    const { brandHandle } = values;
    const brandError = await validate(BRAND_NAME, value, t);
    const brandHandleError = await validate(BRAND_HANDLE, brandHandle, t);

    setErrorMessage((prevState) => ({
      ...prevState,
      [BRAND_NAME]: brandError,
      [BRAND_HANDLE]: brandHandleError,
    }));
  };

  const handleValidation = async (field, value) => {
    const { brandHandleValueExist } = values;
    if (field === BRAND_NAME && !brandHandleValueExist) {
      handleBrandValidation(value);
    }
    const validationError = await validate(field, value, t);

    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleClick = () => {
    const {
      brandHandle,
      brandName,
      checked,
      city,
      country,
      email,
      firstName,
      lastName,
      oldBrandHandle,
      oldBrandName,
      phoneNumber,
      postalCode,
      provinceCode,
      streetAddress,
    } = values;

    const isFieldEmpty = !(
      brandHandle &&
      brandName &&
      city &&
      country &&
      email &&
      firstName &&
      lastName &&
      phoneNumber &&
      postalCode &&
      provinceCode &&
      streetAddress
    );

    const {
      brandHandle: brandHandleError,
      brandName: brandNameError,
      city: cityError,
      country: countryError,
      email: emailError,
      firstName: firstNameError,
      lastName: lastNameError,
      phoneNumber: phoneNumberError,
      postalCode: postalCodeError,
      provinceCode: provinceCodeError,
      streetAddress: streetAddressError,
    } = errorMessage;

    const isAnyError =
      brandHandleError ||
      brandNameError ||
      cityError ||
      countryError ||
      emailError ||
      firstNameError ||
      lastNameError ||
      phoneNumberError ||
      postalCodeError ||
      provinceCodeError ||
      streetAddressError;

    if (isFieldEmpty || isAnyError) {
      return;
    }

    updateVendorProfile({
      variables: {
        input: {
          formValues: {
            address: streetAddress,
            brand: { slug: brandHandle },
            brandName,
            city,
            country,
            email,
            firstName,
            id: params.id,
            isReadOnly: checked,
            lastName,
            phoneNumber: phoneNumber.toString(),
            pinCode: postalCode,
            provinceCode,
          },
        },
      },
    })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.UPDATE_PROFILE);
        if (responseError) {
          setBanner({
            isOpen: true,
            status: "critical",
            title: responseError,
          });
          return;
        }
        const isBrandChanged = oldBrandName !== brandName || oldBrandHandle !== brandHandle;
        history.push(`/providers/view/${params.id}`, { profileEdit: true, isBrandChanged });
      })
      .catch(() => {
        setBanner({
          isOpen: true,
          status: "critical",
          title: `${t("error.common.somethingWentWrong")}`,
        });
      });
  };

  const handleCancel = () => {
    history.push(`/providers/view/${params.id}`);
  };

  useEffect(() => {
    if (!isTitle && values && values.brandName) {
      setIsTitle(true);
      setPageData({
        title: t("pageTitle.providerProfile", { item: baseHelper.ucFirst(values.brandName) }),
      });
    }
  }, [setPageData, values, t, isTitle]);

  if (vendorProfileLoading) {
    return (
      <>
        <SkeletonAnnotated annotatedLines={2} primaryLines={24} />
        <SkeletonAnnotated annotatedLines={2} primaryLines={3} />
      </>
    );
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={`${t("sectionContent.updateVendorProfile.title")}`}
        description={`${t("sectionContent.updateVendorProfile.description")}`}
      >
        <Card
          title="Edit Brand Details"
          actions={[
            {
              content: "Learn more",
              onAction: () => {
                learnMore(`${t("sectionContent.updateVendorProfile.title")}`, "TODO:");
              },
            },
          ]}
        >
          <Card.Section>
            <TextContainer>{`${t("sectionContent.updateVendorProfile.description")}`}</TextContainer>
            <br />
            <TextField
              label={`${t("label.BrandName")}*`}
              value={values.brandName}
              onChange={(value) => handleField(BRAND_NAME, value)}
              onBlur={() => handleValidation(BRAND_NAME, values.brandName)}
              error={errorMessage && errorMessage.brandName}
            />
            <br />
            <TextField
              label={`${t("label.BrandHandle")}*`}
              value={values.brandHandle}
              onChange={(value) => handleField(BRAND_HANDLE, value)}
              onBlur={() => handleValidation(BRAND_HANDLE, values.brandHandle)}
              error={errorMessage && errorMessage.brandHandle}
            />
          </Card.Section>
        </Card>
        <Card
          title={`${t("sectionContent.updateVendorProfile.title")}`}
          actions={[
            {
              content: "Learn more",
              onAction: () => {
                learnMore(`${t("sectionContent.updateVendorProfile.title")}`, "TODO:");
              },
            },
          ]}
        >
          <Card.Section>
            <TextContainer>{`${t("sectionContent.updateVendorProfile.description")}`}</TextContainer>
            <br />
            <FormLayout>
              <TextField
                label={`${t("label.FirstName")}*`}
                value={values.firstName}
                onChange={(value) => handleField(FIRST_NAME, value)}
                onBlur={() => handleValidation(FIRST_NAME, values.firstName)}
                error={errorMessage && errorMessage.firstName}
              />

              <TextField
                label={`${t("label.LastName")}*`}
                value={values.lastName}
                onChange={(value) => handleField(LAST_NAME, value)}
                onBlur={() => handleValidation(LAST_NAME, values.lastName)}
                error={errorMessage && errorMessage.lastName}
              />

              <TextField
                label={`${t("label.Email")}*`}
                value={values.email}
                onChange={(value) => handleField(EMAIL, value)}
                onBlur={() => handleValidation(EMAIL, values.email)}
                error={errorMessage && errorMessage.email}
              />

              <TextField
                label={`${t("label.PhoneNumber")}*`}
                value={(values.phoneNumber && values.phoneNumber.toString()) || ""}
                onChange={(value) => handleField(PHONE_NUMBER, value)}
                onBlur={() => handleValidation(PHONE_NUMBER, values.phoneNumber)}
                error={errorMessage && errorMessage.phoneNumber}
              />

              <TextField
                label={`${t("label.StreetAddress")}*`}
                value={values.streetAddress}
                onChange={(value) => handleField(STREET_ADDRESS, value)}
                onBlur={() => handleValidation(STREET_ADDRESS, values.streetAddress)}
                error={errorMessage && errorMessage.streetAddress}
              />

              <TextField
                label={`${t("label.City")}*`}
                value={values.city}
                onChange={(value) => handleField(CITY, value)}
                onBlur={() => handleValidation(CITY, values.city)}
                error={errorMessage && errorMessage.city}
              />

              <TextField
                label={`${t("label.ProvinceCode")}*`}
                value={values.provinceCode}
                onChange={(value) => handleField(PROVINCE_CODE, value)}
                onBlur={() => handleValidation(PROVINCE_CODE, values.provinceCode)}
                error={errorMessage && errorMessage.provinceCode}
              />

              <TextField
                label={`${t("label.Country")}*`}
                value={values.country}
                onChange={(value) => handleField(COUNTRY, value)}
                onBlur={() => handleValidation(COUNTRY, values.country)}
                error={errorMessage && errorMessage.country}
              />

              <TextField
                label={`${t("label.PostalCode")}*`}
                value={values.postalCode}
                onChange={(value) => handleField(POSTAL_CODE, value)}
                onBlur={() => handleValidation(POSTAL_CODE, values.postalCode)}
                error={errorMessage && errorMessage.postalCode}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <Card title="Vendor's access permission">
          <Card.Section>
            <TextContainer>
              <p>
                {`By default a vendor has full write access in their account however you can set a vendor's access to be
                'read only'. With 'read only' access a vendor can log into their account but they are not able to
                perform any create, update or delete actions ie. add or manage products and orders or change any
                settings.`}
              </p>
              <p>
                This permission can be revoked or added at any time by you after a vendor has been created. This feature
                is particulariy useful when you are looking to manage on behalf of your vendors.
              </p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={`${t("message.updateVendorProfile.readOnly")}`}
                checked={values.checked}
                onChange={(value) => handleField("checked", value)}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <br />
        <Stack distribution="equalSpacing" spacing="tight">
          <Button onClick={() => handleCancel()}>{`${t("label.Cancel")}`}</Button>
          <Button primary onClick={() => handleClick()} loading={loading}>{`${t("label.Submit")}`}</Button>
        </Stack>
      </Layout.AnnotatedSection>
      <Sheet
        isOpen={sheetActive}
        title={sheetTitle}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: "Close", onAction: () => setSheetActive(false) }}
        primaryAction={{ content: "Done", onAction: () => setSheetActive(false) }}
      >
        {sheetContent}
      </Sheet>
    </>
  );
};

export default withFeature(withErrorBoundary(EditVendorProfile), { feature: constant.EDIT_VENDOR });

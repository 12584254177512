import React, { useContext } from "react";
import { Card, TextField, FormLayout, Select } from "@shopify/polaris";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

const Price = (props) => {
  const { currentUser } = useContext(PrivateContext);
  const { moneyFormat = "Rs." } = currentUser;
  const { variantData = {}, handleChange = () => {} } = props;
  const { price = 0, comparePrice = 0, vendorDiscount = { price: 0, type: "flat" } } = variantData;
  const { symbol } = constant;
  const options = [
    { label: "Flat", value: constant.FLAT },
    { label: "Percentage", value: constant.PERCENTAGE },
  ];
  const handlePriceChange = (field, variantField, value, typeCheck) => {
    const finalValue = { ...variantField, ...value };
    if (!typeCheck) {
      finalValue.price = "";
    }
    if (typeCheck === constant.PERCENTAGE && value.price <= 100) {
      handleChange(field, finalValue);
    }
    if (typeCheck !== constant.PERCENTAGE) {
      handleChange(field, finalValue);
    }
    // handleChange(field, finalValue);
  };
  const selectWeight = (field, variantField) => (
    <Select
      id={`${field}Type`}
      value={(variantField && variantField.type) || "flat"}
      placeholder="Select type"
      label="type"
      onChange={(value) => handlePriceChange(field, variantField, { type: value }, false)}
      labelHidden
      options={options}
    />
  );
  return (
    <Card title="Pricing" id="variantPrice" sectioned>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            id="price"
            label="Price"
            type="number"
            min="0"
            value={(price && price.toString()) || ""}
            placeholder="0.00"
            prefix={(moneyFormat === "Rs." && moneyFormat) || symbol.DOLLAR}
            onChange={(value) => handleChange(constant.PRICE, Number(value))}
          />
          <TextField
            id="comparePrice"
            label="Compare at price"
            type="number"
            min="0"
            value={(comparePrice && comparePrice.toString()) || ""}
            placeholder="0.00"
            prefix={(moneyFormat === "Rs." && moneyFormat) || symbol.DOLLAR}
            onChange={(value) => handleChange(constant.COMPARE_PRICE, Number(value))}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            id="discount"
            label="Discount"
            type="number"
            min="0"
            max={(vendorDiscount && vendorDiscount.type === constant.PERCENTAGE && 100) || 9999}
            value={(vendorDiscount && vendorDiscount.price && vendorDiscount.price.toString()) || ""}
            placeholder="0.00"
            onChange={(value) =>
              handlePriceChange(constant.VENDOR_DISCOUNT, vendorDiscount, { price: Number(value) }, vendorDiscount.type)
            }
            connectedRight={selectWeight(constant.VENDOR_DISCOUNT, vendorDiscount)}
            suffix={vendorDiscount && vendorDiscount.type === constant.PERCENTAGE && symbol.PERCENTAGE}
            prefix={vendorDiscount && vendorDiscount.type === constant.FLAT && (moneyFormat || symbol.DOLLAR)}
          />
        </FormLayout.Group>
      </FormLayout>
    </Card>
  );
};

Price.propTypes = {
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Price;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Select } from "@shopify/polaris";

import { PublicContext } from "lib/context";
import constant from "lib/constant/constant";

const AccountType = (props) => {
  const { oldCMS: cms } = useContext(PublicContext);
  const { accountType, isAccountTypeDisable, handleChange, handleValidation, errorMessage } = props;
  const { label = {} } = cms || {};
  const accountTypes = [
    { label: label.seller, value: "seller" },
    { label: label.supplier, value: "vendor" },
  ];

  const onAccountChange = (value) => {
    handleChange(constant.ACCOUNT_TYPE, value);
    handleChange(constant.BRAND_NAME, "");
  };

  return (
    <Select
      id="accountType"
      label={`${label.accountType}*`}
      value={accountType}
      placeholder={label.accountType}
      onChange={(value) => onAccountChange(value)}
      onBlur={() => handleValidation(constant.ACCOUNT_TYPE, accountType)}
      disabled={isAccountTypeDisable}
      options={accountTypes}
      error={errorMessage && errorMessage.accountType}
    />
  );
};

AccountType.propTypes = {
  isAccountTypeDisable: PropTypes.bool.isRequired,
  accountType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  errorMessage: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default AccountType;

import React, { useCallback, useState, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, TextField, TextContainer, Modal, Stack, PageActions } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import helper components
import { Banner, SkeletonAnnotated, Sheet } from "lib/components";
import { baseHelper, storageHelper, featureHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import yup validations
import { useTranslation } from "react-i18next";
import validate from "../../../generic/editProfile/yup/validate";

// import gql
import { EDIT_PROFILE_VENDOR } from "../../../../apollo/mutation";
import { FETCH_VENDOR_PROFILE_DATA } from "../../../../apollo/queries";

// import subFeature
import editProfileConfig from "./editProfileConfig";
import { BrandSection, PaymentSection, ShippingSection, SocialLinkSection, CustomFieldSection } from "./subFeature";

const ProviderEditProfile = () => {
  const { currentUser, isLoading, history, featureFlag, cms } = useContext(PrivateContext);
  const { t } = useTranslation();
  const { _id: currentUserId } = currentUser;
  const textFields = editProfileConfig(cms);
  const { UPDATE_PROFILE, GET_VENDOR_PROFILE_DATA } = constant.gql;
  const [updateVendorProfile, { loading: updateProfileLoading }] = useMutation(EDIT_PROFILE_VENDOR);
  const [active, setActive] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [sellerId, setSellerId] = useState("");
  const [isAdvanceProfileHidden, setIsAdvanceProfileHidden] = useState(false);
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");
  const [detailUpdatedAt, setDetailUpdateAt] = useState("");

  const isBrandHandle = !!(currentUser && currentUser.brand && currentUser.brand.slug);
  const [vendorData, setVendorData] = useState({
    firstName: currentUser && currentUser.firstName,
    lastName: currentUser && currentUser.lastName,
    email: currentUser && currentUser.email && currentUser.email.address,
    phoneNumber: currentUser && currentUser.phoneNumber,
    streetAddress: currentUser && currentUser.address,
    city: currentUser && currentUser.city,
    country: currentUser && currentUser.country,
    postalCode: currentUser && currentUser.pinCode,
    provinceCode: currentUser && currentUser.provinceCode,
  });
  const [brandInformation, setBrandInformation] = useState({
    brandName: currentUser && currentUser.brandName,
    slug: currentUser && currentUser.brand && currentUser.brand.slug,
    description: "",
    brandImage: "",
    brandCoverImage: "",
  });

  const [paymentInformation, setPaymentInformation] = useState({
    isEnabled: false,
    bankName: "",
    accountNumber: "",
    sortCode: "",
    country: "",
  });

  const [shippingInformation, setShippingInformation] = useState({
    isEnabled: false,
    isSameAsBusinessAddress: false,
    streetAddress: "",
    city: "",
    country: "",
    postalCode: "",
  });

  const [socialLinkInformation, setSocialLinkInformation] = useState({
    isEnabled: false,
    facebook: "",
    youtube: "",
    twitter: "",
    instagram: "",
    linkedin: "",
  });

  const [additionalInformation, setAdditionalInformation] = useState([]);

  const [banner, setBanner] = useState({
    status: "",
    title: "",
    children: null,
    isOpen: false,
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorShippingMessage, setErrorShippingMessage] = useState(false);
  const [errorPaymentMessage, setErrorPaymentMessage] = useState(false);

  // useQuery Calls to fetch data.
  const { loading: fetchLoading, data, error: fetchVendorError } = useQuery(FETCH_VENDOR_PROFILE_DATA, {
    variables: { input: { vendorId: currentUserId } },
  });

  const updateVendorData = (vendorFieldsData, vendorDetailsData) => {
    const { isShipping = false, isPayment = false, isSocialLink = false, customFields = [] } = vendorFieldsData || {};
    const updatedCustomFields = customFields
      .filter((field) => field)
      .map(({ label, placeHolder, inputType, key }) => ({
        label,
        placeHolder,
        inputType,
        key,
        value: "",
      }));
    const {
      shipping = false,
      payment = false,
      customFields: customFieldWithValue = [],
      brand = {},
      brandCover = {},
      socialLink = false,
      description = "",
      updatedAt,
    } = vendorDetailsData || {};
    setDetailUpdateAt(updatedAt);
    setShippingInformation((prevState) => ({
      ...prevState,
      isSameAsBusinessAddress: shipping.isBizAddress,
      city: shipping.city,
      country: shipping.country,
      postalCode: shipping.pinCode,
      streetAddress: shipping.address,
      isEnabled: isShipping,
    }));
    setPaymentInformation((prevState) => ({
      ...prevState,
      accountNumber: payment.account,
      bankName: payment.bank,
      country: payment.country,
      sortCode: payment.sortCode,
      isEnabled: isPayment,
    }));
    setSocialLinkInformation((prevState) => ({
      ...prevState,
      facebook: socialLink.facebook,
      youtube: socialLink.youtube,
      twitter: socialLink.twitter,
      instagram: socialLink.instagram,
      linkedin: socialLink.linkedin,
      isEnabled: isSocialLink,
    }));
    setBrandInformation((prevState) => ({
      ...prevState,
      brandImage: (brand && brand.imageUrl) || "",
      brandCoverImage: (brandCover && brandCover.imageUrl) || "",
      description: description || "",
    }));

    const newAdditionalInformation =
      updatedCustomFields &&
      updatedCustomFields.length &&
      updatedCustomFields.map((item) => {
        const customFieldsData = customFieldWithValue.find((field) => field.label === item.label);
        item.value = customFieldsData && customFieldsData.value;
        return item;
      });
    setAdditionalInformation(newAdditionalInformation);
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const responseData = baseHelper.getResponseData(data, GET_VENDOR_PROFILE_DATA);
    const responseError = baseHelper.getResponseError(data, GET_VENDOR_PROFILE_DATA);
    if (responseError) {
      setBanner({ isOpen: true, status: "critical", title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }
    const { vendorField: vendorFieldsData, vendorDetails: vendorDetailsData, seller = false } = responseData;
    const { dependency = [] } = featureFlag;
    const advanceProfile = dependency && dependency.find((item) => item.key === constant.ADVANCE_VENDOR_PROFILE);

    const advanceProfilePermission = featureHelper.checkFeature({
      currentUser,
      featureFlag: advanceProfile,
    });
    const isProfileHidden = !!(advanceProfilePermission && advanceProfilePermission.error);
    const { _id: id } = seller;
    const idOfSeller = seller && baseHelper.mongoIdAsString(id);
    setSellerId(idOfSeller);
    setIsAdvanceProfileHidden(!seller || isProfileHidden);
    updateVendorData(vendorFieldsData, vendorDetailsData);
  }, [data, GET_VENDOR_PROFILE_DATA]);

  useEffect(() => {
    if (fetchVendorError) {
      setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
    }
  }, [fetchVendorError, t]);

  const handleChangeEvent = (field, value) => {
    setIsSubmitEnabled(true);
    setVendorData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleValidation = async (field, value) => {
    setIsSubmitEnabled(true);
    const error = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const handleBrandInformation = (field, value) => {
    setIsSubmitEnabled(true);
    setBrandInformation((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    if (field === constant.BRAND_NAME && !isBrandHandle) {
      const brandHandleAccurateValue = baseHelper.generateBrandHandle(value);
      handleBrandInformation(constant.BRAND_SLUG, brandHandleAccurateValue);
    }
  };

  const handlePaymentInformation = (field, value) => {
    setPaymentInformation((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleShippingInformation = (field, value) => {
    setShippingInformation((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleShippingValidation = async (field, value) => {
    const error = await validate(field, value);
    setErrorShippingMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const handleSocialLinkInformation = (field, value) => {
    setSocialLinkInformation((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handlePaymentValidation = async (field, value) => {
    const error = await validate(field, value);
    setErrorPaymentMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };
  const handleImage = (prevValue = [], newValue) => {
    const manipulateImage = newValue.map((value) => {
      return { url: value };
    });
    const imageArray = [...prevValue, ...manipulateImage];
    return imageArray;
  };
  const handleAdditionalSectionChange = (key, value) => {
    const updatedAdditionalInformation = [...additionalInformation];
    updatedAdditionalInformation.forEach((field) => {
      if (field.key === key) {
        const updatedFieldValue = (field.inputType === constant.IMAGE && handleImage(field.value, value)) || value;
        field.value = updatedFieldValue;
      }
    });
    setAdditionalInformation([...updatedAdditionalInformation]);
  };

  const handleImageRemove = (index) => {
    additionalInformation.forEach((field) => {
      if (field.inputType === constant.IMAGE) {
        field.value.splice(index, 1);
      }
    });
    setAdditionalInformation([...additionalInformation]);
  };

  const handleClose = useCallback(() => setActive(!active), [active]);

  const vendorDetailsRequestData = () => {
    const {
      streetAddress,
      postalCode,
      isSameAsBusinessAddress,
      city: shippingCity,
      country: shippingCountry,
      isEnabled: isShippingEnabled,
    } = shippingInformation;

    const shippingData = {
      address: streetAddress,
      pinCode: postalCode,
      country: shippingCountry,
      city: shippingCity,
      isBizAddress: isSameAsBusinessAddress,
      isEnabled: isShippingEnabled,
    };
    const emptyImageUrl = { url: "" };
    const customFields =
      additionalInformation.length &&
      additionalInformation.map((item) => {
        return {
          label: item.label,
          key: item.key,
          inputType: item.inputType,
          value: (item.inputType !== constant.IMAGE && item.value) || "",
          imageUrls: (item.inputType === constant.IMAGE && item.value) || emptyImageUrl,
          multimediaUrls: [],
          placeholder: item.placeHolder,
        };
      });
    const {
      bankName,
      country: paymentCountry,
      sortCode,
      accountNumber,
      isEnabled: isPaymentEnabled,
    } = paymentInformation;
    const paymentData = {
      sortCode,
      bank: bankName,
      account: accountNumber,
      country: paymentCountry,
      isEnabled: isPaymentEnabled,
    };

    const { facebook, twitter, instagram, linkedin, youtube, isEnabled: isSocialLink } = socialLinkInformation;
    const socialLinkData = {
      facebook,
      twitter,
      instagram,
      linkedin,
      youtube,
      isEnabled: isSocialLink,
    };
    const { brandImage, brandCoverImage, description } = brandInformation;
    const dataToUpdate = {
      customFields,
      payment: paymentData,
      shipping: shippingData,
      socialLink: socialLinkData,
      description: description || "",
      sellerId,
    };
    const isBrandImage = (Array.isArray(brandImage) && brandImage.length && brandImage[0]) || brandImage || null;
    const isBrandCoverImage =
      (Array.isArray(brandCoverImage) && brandCoverImage.length && brandCoverImage[0]) || brandCoverImage || null;
    if (isBrandImage) {
      dataToUpdate.brandImage = {
        url: isBrandImage,
      };
    }
    if (isBrandCoverImage) {
      dataToUpdate.brandCoverImage = {
        url: isBrandCoverImage,
      };
    }
    return dataToUpdate;
  };

  const editProfile = async () => {
    const vendorDetailData = vendorDetailsRequestData();
    try {
      const res = await updateVendorProfile({
        variables: {
          input: {
            formValues: {
              firstName: vendorData.firstName,
              lastName: vendorData.lastName,
              email: vendorData.email,
              phoneNumber: vendorData.phoneNumber.toString(),
              brandName: brandInformation.brandName,
              address: vendorData.streetAddress,
              city: vendorData.city,
              country: vendorData.country,
              pinCode: vendorData.postalCode,
              provinceCode: vendorData.provinceCode,
              brand: {
                slug: brandInformation.slug,
              },
            },
            dataToUpdate: vendorDetailData,
          },
        },
      });
      const responseError = baseHelper.getResponseError(res.data, UPDATE_PROFILE);
      if (responseError) {
        setBanner({
          title: responseError,
          status: "critical",
          isOpen: true,
        });
        return;
      }
      if (isEmailChanged) {
        setActive(false);
        setBanner({
          title: cms("message.success.profileUpdatedVerifyEmail"),
          children: cms("message.success.redirect"),
          status: "success",
          isOpen: true,
        });
        storageHelper.remove("token");
        setTimeout(() => {
          history.push("/login");
        }, 10000);
      } else {
        const bannerValue = {
          title: cms("message.success.profileUpdated"),
          status: "success",
          isOpen: true,
        };
        const brandName = currentUser && currentUser.brandName;
        const brandSlug = currentUser && currentUser.brand && currentUser.brand.slug;
        if (brandName !== brandInformation.brandName || brandSlug !== brandInformation.slug) {
          bannerValue.children = t("message.updateVendorProfile.shopifyPushMessage");
        }
        setBanner(bannerValue);
      }
    } catch (e) {
      setActive(false);
      setBanner({
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
        isOpen: true,
      });
    }
  };

  const updateEmailModal = () => {
    return (
      <Modal
        open={active}
        onClose={handleClose}
        title={cms("section.editProfile.emailChangeModal.title")}
        primaryAction={{
          content: cms("section.editProfile.emailChangeModal.button.primary"),
          onAction: () => editProfile(),
          loading: updateProfileLoading,
        }}
        secondaryActions={[
          {
            content: cms("common.button.cancel"),
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <TextContainer>
                <p>{cms("section.editProfile.emailChangeModal.content")}</p>
              </TextContainer>
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    );
  };

  const showErrorForEmptyFields = () => {
    Object.keys(vendorData).map((item) => {
      if (!vendorData[item]) {
        return handleValidation(item, "");
      }
    });
    const { brandImage, brandCoverImage, description, ...requiredBrandValues } = brandInformation;
    Object.keys(requiredBrandValues).map((item) => {
      if (!brandInformation[item]) {
        return handleValidation(item, "");
      }
    });
    if (!(isAdvanceProfileHidden || brandInformation.description)) {
      return handleValidation(constant.BRAND_DESCRIPTION, "");
    }
    if (shippingInformation.isEnabled && !shippingInformation.isSameAsBusinessAddress) {
      const { isEnabled, isSameAsBusinessAddress, postalCode, ...requiredShippingValues } = shippingInformation;
      Object.keys(requiredShippingValues).map((item) => {
        if (!shippingInformation[item]) {
          return handleShippingValidation(item, "");
        }
      });
    }
    if (paymentInformation.isEnabled) {
      const { isEnabled, ...requiredPaymentValues } = paymentInformation;
      Object.keys(requiredPaymentValues).map((item) => {
        if (!paymentInformation[item]) {
          return handlePaymentValidation(item, "");
        }
      });
    }
  };
  const paymentValidation = () => {
    let paymentEmptyField = false;
    let isPaymentError = false;
    if (paymentInformation.isEnabled) {
      const { isEnabled, ...requiredPaymentValues } = paymentInformation;
      paymentEmptyField = Object.values(requiredPaymentValues).some((value) => !value);
      isPaymentError = Object.values(errorPaymentMessage).some((error) => error);
    }
    return paymentEmptyField || isPaymentError;
  };
  const shippingValidation = () => {
    let isShippingEmpty = false;
    let isShippingError = false;
    if (!isAdvanceProfileHidden && shippingInformation.isEnabled && !shippingInformation.isSameAsBusinessAddress) {
      const { isEnabled, isSameAsBusinessAddress, postalCode, ...requiredShippingValues } = shippingInformation;
      isShippingEmpty = Object.values(requiredShippingValues).some((value) => !value);
      isShippingError = Object.values(errorShippingMessage).some((error) => error);
    }
    return isShippingEmpty || isShippingError;
  };
  const vendorValidation = () => {
    const vendorRequiredData = { ...vendorData };
    delete vendorRequiredData.provinceCode;

    const vendorFormEmptyField = Object.values(vendorRequiredData).some((value) => !value);
    const vendorFormError = Object.values(errorMessage).some((error) => error);
    return vendorFormEmptyField || vendorFormError;
  };
  const brandValidation = () => {
    const { brandImage, brandCoverImage, description, ...requiredBrandValues } = brandInformation;
    const brandValues = (!isAdvanceProfileHidden && { ...requiredBrandValues, description }) || requiredBrandValues;
    const brandEmptyEmptyField = Object.values(brandValues).some((value) => !value);
    return brandEmptyEmptyField;
  };

  const isAnyEmptyField = () => {
    const isVendorError = vendorValidation();
    const isBrandError = brandValidation();
    const isPaymentError = paymentValidation();
    const isShippingError = shippingValidation();
    const isAnyError = isVendorError || isBrandError || isPaymentError || isShippingError;
    return isAnyError;
  };

  const onSubmit = () => {
    setIsSubmitEnabled(false);
    showErrorForEmptyFields();
    if (isAnyEmptyField() || !(isAdvanceProfileHidden || brandInformation.description)) {
      return;
    }
    if (currentUser.email.address !== vendorData.email) {
      setIsEmailChanged(true);
      setActive(true);
    } else {
      editProfile();
    }
  };

  const getFields = (text) =>
    text.map((textField) => (
      <TextField
        key={textField.key}
        type={textField.type}
        id={textField.key}
        label={textField.label}
        placeholder={textField.placeholder}
        value={(vendorData[textField.key] && vendorData[textField.key].toString()) || ""}
        min={textField.min}
        onChange={(value) => handleChangeEvent(textField.key, value)}
        onBlur={() => handleValidation(textField.key, vendorData[textField.key])}
        error={errorMessage && errorMessage[textField.key]}
      />
    ));

  const learnMore = (title, content) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(content);
  };

  if (isLoading && fetchLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {updateEmailModal()}
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            status={banner.status}
            title={banner.title}
            isOpen={banner.isOpen}
            onDismiss={() => {
              setBanner({ status: "", title: "", isOpen: false, children: null });
            }}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.Section>
        <BrandSection
          brandInformation={brandInformation}
          errorMessage={errorMessage}
          handleChange={handleBrandInformation}
          handleValidation={handleValidation}
          isAdvanceProfileHidden={isAdvanceProfileHidden}
          learnMore={learnMore}
        />
      </Layout.Section>
      <Layout.Section>
        <Layout.AnnotatedSection
          title={cms("section.editProfile.title")}
          description={cms("section.editProfile.description")}
        >
          <Card
            title={[
              cms("section.editProfile.title"),
              // lastUpdated && <Caption>{`Updated : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>,
            ]}
            actions={[
              {
                content: "Learn More",
                onAction: () => {
                  learnMore(cms("section.editProfile.title"), "TODO:");
                },
              },
            ]}
          >
            <Card.Section>
              <FormLayout>
                <TextContainer>You can change all of it within the platform at any point in the future.</TextContainer>
                {getFields(textFields)}
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </Layout.Section>

      {!isAdvanceProfileHidden && shippingInformation.isEnabled && (
        <Layout.Section>
          <ShippingSection
            handleChange={handleShippingInformation}
            shippingInformation={shippingInformation}
            handleShippingValidation={handleShippingValidation}
            errorShippingMessage={errorShippingMessage}
            learnMore={learnMore}
            lastUpdated={detailUpdatedAt}
          />
        </Layout.Section>
      )}
      {!isAdvanceProfileHidden && paymentInformation.isEnabled && (
        <Layout.Section>
          <PaymentSection
            handleChange={handlePaymentInformation}
            paymentInformation={paymentInformation}
            handlePaymentValidation={handlePaymentValidation}
            errorPaymentMessage={errorPaymentMessage}
            learnMore={learnMore}
            lastUpdated={detailUpdatedAt}
          />
        </Layout.Section>
      )}
      {!isAdvanceProfileHidden && socialLinkInformation.isEnabled && (
        <Layout.Section>
          <SocialLinkSection
            handleChange={handleSocialLinkInformation}
            socialLinkInformation={socialLinkInformation}
            learnMore={learnMore}
            lastUpdated={detailUpdatedAt}
          />
        </Layout.Section>
      )}
      {!isAdvanceProfileHidden && !!(additionalInformation && additionalInformation.length) && (
        <Layout.Section>
          <CustomFieldSection
            handleChange={handleAdditionalSectionChange}
            data={[...additionalInformation]}
            handleImageRemove={handleImageRemove}
            learnMore={learnMore}
            lastUpdated={detailUpdatedAt}
          />
        </Layout.Section>
      )}
      <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={{
          content: cms("common.label.done"),
          onAction: () => setSheetActive(false),
        }}
        secondaryAction={{
          content: cms("common.button.cancel"),
          onAction: () => setSheetActive(false),
        }}
      >
        {sheetContent}
      </Sheet>
      <Layout.Section>
        <PageActions
          primaryAction={{
            content: t("common.Submit"),
            key: "submitButton",
            onAction: () => onSubmit(),
            loading: !isEmailChanged && updateProfileLoading,
            disabled: updateProfileLoading || !isSubmitEnabled || isAnyEmptyField(),
          }}
          secondaryActions={[
            {
              content: t("common.Cancel"),
              id: "cancelButton",
              onAction: () => history.push("/"),
            },
          ]}
        />
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderEditProfile), { feature: constant.EDIT_PROFILE });

const config = {
  "/orders": [
    {
      caption: "Export your orders",
      id: "exportOrder",
      label: "Export Order",
      link: "/orders",
    },
    {
      caption: "Import all orders from your shopify store",
      id: "importOrder",
      label: "Import orders from Shopify store",
      link: "/orders",
    },
  ],
  "/payments": [
    {
      caption: "Export your payments",
      id: "exportPayment",
      label: "Export Payment",
      link: "/payments",
    },
  ],
  "/products": [
    {
      caption: "Import all products from your shopify store",
      id: "importProduct",
      label: "Import products from Shopify store",
      link: "/products",
    },
    {
      caption: "Add your products",
      id: "addProduct",
      label: "Add Products",
      link: "/products/add",
    },
  ],
  "/providers": [
    {
      caption: "Add your vendors",
      id: "addProvider",
      label: "Add Vendors",
      link: "/providers/add",
    },
    {
      caption: "Manage external vendor page",
      id: "vendorProfile",
      label: "Advance Vendor Profile",
      link: "/advance-providers",
    },
    {
      caption: "Create/Update brand page",
      id: "brandPage",
      label: "Create brand page using builder",
      action: function action() {
        const { shop, token, brandName, domain } = this;
        const url = `${domain}/builder/${shop}/${brandName}/${token}`;
        window.open(url);
      },
    },
  ],
};

export default config;

import React, { useContext, useEffect, useState } from "react";
import { Card, Layout, TextField, FormLayout, Stack, Badge, Icon, Caption, Link, Collapsible } from "@shopify/polaris";
import { RiskMinor } from "@shopify/polaris-icons";
import { useQuery } from "react-apollo";
// import helper
import { baseHelper } from "lib/helpers";

// import contexts
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import { BrandingContext } from "../context/context";

import { GET_USER } from "../../../../../apollo/queries";

const Domain = () => {
  const { domain, handleChange, setBanner, learnMore } = useContext(BrandingContext);
  const { currentUser = {}, history, cms = () => {} } = useContext(PrivateContext);

  const { plan } = currentUser;
  const {
    gql: { GET_USER: GET_USER_GQL },
    DOMAIN,
  } = constant;
  const isGrowthAndAbovePlan = baseHelper.isGrowthAndAbovePlan({ plan });
  const { data: getUserData, error: getUserError } = useQuery(GET_USER);
  const [isStripeConnected, setIsStripeConnected] = useState(false);
  const badge = [
    {
      content: !isGrowthAndAbovePlan && (
        <Stack wrap={false}>
          <Badge status="warning">{cms("message.growthAndAbove")}</Badge>
          <Icon source={RiskMinor} color="black" />
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    if (getUserData) {
      const resData = baseHelper.getResponseData(getUserData, GET_USER_GQL);
      const resError = baseHelper.getResponseError(getUserData, GET_USER_GQL);
      if (resError) {
        setBanner({
          isOpen: true,
          status: "critical",
          title: resError,
        });
      }
      if (resData) {
        const stripeValues = (resData && resData.stripeKey) || null;
        setIsStripeConnected(!!(stripeValues && stripeValues.clientId && stripeValues.secret));
      }
    }
    if (getUserError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  }, [getUserData, setBanner, GET_USER_GQL, getUserError, cms]);

  const planContent = cms("section.domain.content.caption.plan").map(
    (planCMS) =>
      planCMS &&
      planCMS.content &&
      ((planCMS.link && (
        <Link key={planCMS.id} onClick={() => history.push(planCMS.link)}>
          {planCMS.content}
        </Link>
      )) ||
        planCMS.content)
  );

  return (
    <>
      <Collapsible open>
        <Layout.AnnotatedSection title={cms("section.domain.title")} description={cms("section.domain.description")}>
          <Card
            sectioned
            title={cms("section.domain.content.title")}
            actions={[
              badge,
              { content: "Learn more", onAction: () => learnMore(cms("section.domain.content.title"), "TODO: ") },
            ]}
          >
            <FormLayout>
              <TextField
                id="domain"
                disabled={!isGrowthAndAbovePlan}
                placeholder={cms("section.domain.placeholder.domain")}
                value={domain}
                onChange={(value) => handleChange(DOMAIN, value, DOMAIN)}
              />
            </FormLayout>
            <br />
            <Caption>{cms("section.domain.content.caption.domain")}</Caption>
            {isStripeConnected && [<br />, <Caption>{cms("section.domain.content.caption.stripe")}</Caption>]}
            {!isGrowthAndAbovePlan && [<br />, <Caption>{planContent}</Caption>]}
          </Card>
        </Layout.AnnotatedSection>
      </Collapsible>
    </>
  );
};

export default Domain;

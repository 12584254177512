// import packages
import React from "react";
import { Modal, TextContainer, Heading } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// cms
import registerCMS from "app/public/modules/register/cms/register";

// props
import { policyProp } from "../props";

const PrivacyPolicy = (props) => {
  const { t } = useTranslation();
  const { message = {} } = registerCMS;
  const { isOpen, onClose } = props;
  return (
    <Modal
      large
      id="privacyPolicy"
      title={t("label.policy")}
      open={isOpen}
      onClose={onClose}
      primaryAction={{
        content: "Close",
        onAction: onClose,
      }}
    >
      <Modal.Section>
        <TextContainer>
          <div className="textFormat">
            <p>{message.privacyTag.para1}</p>
            <ul>
              <li>{message.privacyLiTag.liTag1}</li>
              <li>{message.privacyLiTag.liTag2}</li>
              <li>{message.privacyLiTag.liTag3}</li>
            </ul>
            <p>{message.privacyTag.para2}</p>
            <p>{message.privacyTag.para3}</p>
            <Heading>{message.privacyHTag.h2Tag1}</Heading>
            <p>{message.privacyTag.para4}</p>
            <Heading>{message.privacyHTag.h2Tag2}</Heading>
            <p>{message.privacyTag.para5}</p>
            <Heading>{message.privacyHTag.h2Tag3}</Heading>
            <p>{message.privacyTag.para6}</p>
            <Heading>{message.privacyHTag.h2Tag4}</Heading>
            <p>{message.privacyTag.para7}</p>
            <Heading>{message.privacyHTag.h2Tag5}</Heading>
            <p>{message.privacyTag.para9}</p>
            <Heading>{message.privacyHTag.h2Tag6}</Heading>
            <p>{message.privacyTag.para10}</p>
            <Heading>{message.privacyHTag.h2Tag7}</Heading>
            <p>{message.privacyTag.para11}</p>
            <Heading>{message.privacyHTag.h2Tag8}</Heading>
            <p>{message.privacyTag.para12}</p>
            <Heading>{message.privacyHTag.h2Tag9}</Heading>
            <p>{message.privacyTag.para13}</p>
          </div>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};
PrivacyPolicy.propTypes = policyProp.type;
export default PrivacyPolicy;

import { gql } from "apollo-boost";

const GET_CUSTOMER = gql`
  query getCustomer($input: GetCustomer!) {
    getCustomer(input: $input) {
      status
      error
      data {
        customer {
          email
          phoneNumber
        }
      }
    }
  }
`;

export default GET_CUSTOMER;

import React from "react";
import { Card, FormLayout, TextField, Layout, Select, Caption, TextContainer } from "@shopify/polaris";

import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { inventoryProp } from "../propTypes";

const Inventory = (props) => {
  const { cms, data, handleChange, learnMore } = props;

  const acceptOnlyValidInput = (value) => {
    return (baseHelper.validateWholeNumber(value) && value) || (value !== "" && data.quantity);
  };

  return (
    <Layout.Section>
      <Card
        title={[
          cms("section.inventory.title"),
          data && data.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(data.updatedAt)}`}</Caption>,
        ]}
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => {
              learnMore(cms("section.inventory.title"), "TODO:");
            },
          },
        ]}
        id="productInventory"
      >
        <Card.Section>
          <TextContainer>You can add inventory policy for the product.</TextContainer>
          <br />
          <FormLayout>
            <FormLayout.Group>
              <TextField
                id="sku"
                label={cms("section.inventory.label.sku")}
                value={data.sku || ""}
                onChange={(value) => handleChange(constant.SKU, value)}
              />
              <TextField
                id="barcode"
                label={cms("section.inventory.label.barcode")}
                value={data.barcode}
                onChange={(value) => handleChange(constant.BARCODE, value)}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Select
                label={cms("section.inventory.label.inventory")}
                value={data.inventoryManagement}
                options={[
                  {
                    label: cms("section.inventory.label.dontTrack"),
                    value: "Don't track inventory",
                  },
                  {
                    label: cms("section.inventory.label.shopify"),
                    value: "shopify",
                  },
                ]}
                onChange={(value) => handleChange(constant.INVENTORY_MANAGEMENT, value)}
              />
              <TextField
                id="inventoryQuantity"
                disabled={!data.inventoryManagement || data.inventoryManagement === constant.DO_NOT_TRACK_INVENTORY}
                label={cms("section.inventory.label.quantity")}
                min={0}
                value={(data.quantity && data.quantity.toString()) || ""}
                onChange={(value) => handleChange(constant.QUANTITY, acceptOnlyValidInput(value))}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};

Inventory.propTypes = inventoryProp.type;
export default Inventory;

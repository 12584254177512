import React, { useContext, useState } from "react";
import { Layout, Card } from "@shopify/polaris";

// import helper
import { storageHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import components
import { TwoColumnLayout } from "layout/private/components";
import { SkeletonList, Banner } from "lib/components";
import { QuickAction, QuickReport } from "app/reports";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import subFeature
import PaymentList from "../../../generic/list/list";
import ProviderExportPayment from "../export/export";

const ProviderPaymentList = () => {
  const { isLoading } = useContext(PrivateContext);

  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });
  const [isOpenModal, setModal] = useState(false);

  const linkData = [
    {
      action: () => setModal(!isOpenModal),
      caption: "Export your payments",
      id: "exportPayment",
      label: "Export Payment",
    },
  ];

  const toggleBanner = () => {
    setBanner({ isOpen: false });
  };
  const getComponent = () => {
    if (isLoading) {
      return <SkeletonList />;
    }
    return (
      <>
        <Layout>
          {banner.isOpen && (
            <Layout.Section>
              <Banner status={banner.status} isOpen={banner.isOpen} title={banner.title} onDismiss={toggleBanner} />
            </Layout.Section>
          )}
          <Layout.Section>
            <Card>
              <PaymentList setBanner={setBanner} />
            </Card>
          </Layout.Section>

          <ProviderExportPayment openModal={isOpenModal} setOpenModal={setModal} />
        </Layout>
      </>
    );
  };

  const getSecondaryComponent = () => {
    return (
      <>
        <QuickAction link={linkData} />
        <QuickReport />
      </>
    );
  };

  const currentUserRole = storageHelper.get("userRole");
  return <TwoColumnLayout primary={getComponent(currentUserRole)} secondary={getSecondaryComponent()} />;
};

export default withFeature(withErrorBoundary(ProviderPaymentList), { feature: constant.PAYMENTS });

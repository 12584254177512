import React from "react";
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { GenericLink } from "app/vendors/modules/generic/feature/add/subFeatures";

const OperatorGenericLink = ({ learnMore }) => {
  return <GenericLink learnMore={learnMore} />;
};

OperatorGenericLink.propTypes = {
  learnMore: PropTypes.func.isRequired,
};

export default withFeature(OperatorGenericLink, { feature: constant.GENERIC_LINK });

const config = {
  "/": [
    {
      caption: "Add products to your store",
      label: "Add Products",
      link: "/products/add",
    },
    {
      caption: "Have any question?",
      label: "Contact Us",
      link: "/contact-us",
    },
  ],
};

export default config;

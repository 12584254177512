import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex } = yupHelper;
const emailSchema = (cms) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail"))
      .nullable(),
  });
};

export default emailSchema;

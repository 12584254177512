import React from "react";
import { Caption, Card, FormLayout, Layout, TextContainer, TextField, TextStyle } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// constant
import constant from "lib/constant/constant";
import ImageComponent from "lib/components/dropZone/dropZone";
import { baseHelper } from "lib/helpers";

const BrandSection = (props) => {
  const { t } = useTranslation();
  const {
    brandInformation,
    errorMessage,
    handleChange,
    handleValidation,
    isAdvanceProfileHidden,
    learnMore,
    lastUpdated,
  } = props;
  const imageUrl = brandInformation && brandInformation.brandImage;
  const coverImageUrl = brandInformation && brandInformation.brandCoverImage;

  const getFields = () => {
    return (
      <>
        <TextField
          id={brandInformation.brandName}
          label={`${t("label.BrandName")}*`}
          placeholder={t("placeHolder.BrandName")}
          value={brandInformation.brandName}
          onChange={(value) => handleChange(constant.BRAND_NAME, value)}
          onBlur={() => handleValidation(constant.BRAND_NAME, brandInformation.brandName)}
          error={errorMessage && errorMessage.brandName}
        />
        <br />
        <TextField
          id="BrandHandle"
          label={`${t("label.manageVendor.brandHandle")}*`}
          placeholder={t("placeHolder.manageVendor.brandHandle")}
          value={brandInformation.slug}
          onChange={(value) => handleChange(constant.BRAND_SLUG, value)}
          onBlur={() => handleValidation(constant.BRAND_SLUG, brandInformation.slug)}
          error={errorMessage && errorMessage.slug}
        />
        {!isAdvanceProfileHidden && [
          <br />,
          <TextField
            id={brandInformation.description}
            label={`${t("label.manageVendor.brandDescription")}*`}
            placeholder={t("placeHolder.manageVendor.brandDescription")}
            value={brandInformation.description}
            onChange={(value) => handleChange(constant.BRAND_DESCRIPTION, value)}
            onBlur={() => handleValidation(constant.BRAND_DESCRIPTION, brandInformation.description)}
            error={errorMessage && errorMessage.description}
            multiline
          />,
        ]}
        {!isAdvanceProfileHidden && [
          <br />,
          <span>
            {t("label.manageVendor.brandLogo")} <small>(recommended size 200px X 110px)</small>
          </span>,
          <ImageComponent
            onAdd={(value) => handleChange("brandImage", value)}
            allowMultiple={false}
            fileList={(imageUrl !== "" && [imageUrl]) || []}
            onRemove={() => handleChange("brandImage", "")}
            size={1}
          />,
          <br />,
          <span>
            {t("label.manageVendor.brandCoverImage")} <small> (recommended size 2000px X 400px) </small>
          </span>,
          <ImageComponent
            onAdd={(value) => handleChange("brandCoverImage", value)}
            allowMultiple={false}
            fileList={(coverImageUrl !== "" && [coverImageUrl]) || []}
            onRemove={() => handleChange("brandCoverImage", "")}
            size={1}
          />,
        ]}
      </>
    );
  };
  return (
    <Layout.AnnotatedSection
      title={t("sectionContent.profile.vendor.brandTitle")}
      description={t("sectionContent.profile.vendor.description")}
    >
      <Card
        title={[
          t("sectionContent.profile.vendor.brandTitle"),
          lastUpdated && (
            <TextStyle variation="subdued">
              <Caption>{`Updated : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>
            </TextStyle>
          ),
        ]}
        actions={[
          {
            content: "Learn More",
            onAction: () => {
              learnMore(t("sectionContent.profile.vendor.brandTitle"), "TODO:");
            },
          },
        ]}
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>You can change all of it within the platform at any point in the future.</TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

BrandSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  isAdvanceProfileHidden: PropTypes.bool.isRequired,
  brandInformation: PropTypes.shape({
    brandName: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    brandImage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    brandCoverImage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }).isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  learnMore: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
};

BrandSection.defaultProps = {
  errorMessage: PropTypes.bool,
  lastUpdated: "",
};

export default BrandSection;

import React, { useState, useContext, useEffect } from "react";
import { Card, FormLayout, Modal, TextField, Select } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import helper
import { baseHelper } from "lib/helpers";

import { PrivateContext } from "lib/context";
import { ResourceList } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import mutation
import { DELETE_RULE } from "app/shipping/apollo/mutations";

// import subFeatures
import AddRule from "../add/add";
import EditRule from "../edit/edit";
import RuleItem from "./subFeatureItems/rowItem";

// import propType
import { listProp } from "../../propTypes";
import cmsVendorList from "../cms/vendorListCMS";

const List = (props) => {
  const { ruleDataResponse, products, destinations, loading, setBanner, isVendorAllowed, bands, refetch } = props;
  const { currentUser, setPageData, cms } = useContext(PrivateContext);
  const { t } = useTranslation();
  const vendorListCMS = cmsVendorList(t);
  const { label, filterOptions } = vendorListCMS;
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [taggedWith, setTaggedWith] = useState("");

  const [state, setState] = useState({
    rules: [],
    searchValue: "",
    showModal: false,
    showDeleteModal: false,
    selectedRule: false,
    rulesToShow: [],
    appliedFilters: [],
    dismissBanner: false,
  });
  const [deleteRule, { loading: deleteRuleLoading }] = useMutation(DELETE_RULE);

  useEffect(() => {
    if (ruleDataResponse) {
      const { shippingRules = [] } = ruleDataResponse || {};
      setState({ ...state, rules: shippingRules, rulesToShow: shippingRules });
    }
  }, [ruleDataResponse]);

  const toggleDeleteModal = (id = false) =>
    setState({
      ...state,
      showDeleteModal: !state.showDeleteModal,
      selectedRule: state.showDeleteModal ? false : id,
    });

  const onDelete = async () => {
    const { selectedRule } = state;
    const requestData = {
      shippingRuleId: selectedRule,
    };
    try {
      const response = await deleteRule({
        variables: { input: requestData },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.DELETE_SHIPPING_RULE);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      setBanner({
        isOpen: true,
        status: "success",
        title: cms("modal.rule.delete.message.success.deleteSuccessfully"),
      });
      refetch();
    } catch (err) {
      setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
    }
    toggleDeleteModal(false);
  };

  const toggleModal = (id = false) =>
    setState((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
      selectedRule: prevState.showModal ? false : id,
    }));

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      setPageData({
        primaryAction: {
          content: cms("modal.rule.add.title"),
          disabled: !isVendorAllowed,
          onClick: () => toggleModal(),
        },
      });
    }
  }, [isVendorAllowed, setPageData, isLoading]);

  const handleFiltersChange = (appliedFilters) => {
    if (!appliedFilters.length) {
      setState({ ...state, rulesToShow: [...state.rules], searchValue: "" });
      return;
    }
    let destinationRowsToShow = [];
    let productRowsToShow = [];
    const filterValue = appliedFilters.toLowerCase().trim();
    if (selectedFilter === constant.DESTINATION) {
      let options = [];
      destinations.forEach((item) => {
        options = [...options, ...item.options];
      });
      const filteredDestinations = options.filter((item) => item.label.toLowerCase().includes(filterValue));
      destinationRowsToShow = state.rules.filter((item) =>
        filteredDestinations.find((destination) => destination.value === item.destinationCode)
      );
    }
    if (selectedFilter === constant.PRODUCT) {
      const filteredProducts = products.filter((item) => item.label.toLowerCase().includes(filterValue));
      productRowsToShow = state.rules.filter((item) =>
        filteredProducts.find((product) => product.value === item.productId)
      );
    }
    setState({
      ...state,
      rulesToShow: [...destinationRowsToShow, ...productRowsToShow],
      searchValue: "",
    });
  };

  const handleSearchChange = (searchValue) => {
    if (!searchValue) {
      setState({ ...state, rulesToShow: [...state.rules], searchValue, appliedFilters: [] });
      return;
    }
    const search = searchValue.toLowerCase().trim();
    const updatedRules = state.rules.filter((item) => {
      const shippingBand = bands.find((band) => band.value === item.shippingBandId);
      return shippingBand && shippingBand.label && shippingBand.label.toLowerCase().includes(search);
    });
    setState({ ...state, rulesToShow: [...updatedRules], searchValue, appliedFilters: [] });
  };
  const handleQueryClear = () => {
    setState({ searchValue: "" });
  };
  const renderDeleteModal = () => {
    const { showDeleteModal, selectedRule } = state;
    const ruleToUpdate = state.rules.find((item) => item._id === selectedRule) || {};
    const shippingBand = bands.find((band) => band.value === ruleToUpdate.shippingBandId);
    if (!ruleToUpdate) {
      return null;
    }
    return (
      <Modal
        open={showDeleteModal}
        onClose={toggleDeleteModal}
        title={`${cms("modal.rule.delete.label.delete")}- ${shippingBand ? shippingBand.label : ""}`}
        primaryAction={{
          content: cms("modal.rule.delete.button.primary"),
          destructive: true,
          loading: deleteRuleLoading,
          onAction: onDelete,
        }}
      >
        <Modal.Section>{cms("modal.rule.delete.content")}</Modal.Section>
      </Modal>
    );
  };

  const renderModal = () => {
    const { showModal, selectedRule = false } = state;
    const ruleToUpdate = state.rules.find((item) => {
      const { _id: id } = item;
      return id === selectedRule;
    });
    if (ruleToUpdate) {
      return (
        <EditRule
          handleBanner={setBanner}
          destinations={destinations}
          bands={bands}
          products={products}
          shippingRule={{ ...ruleToUpdate }}
          show={showModal}
          onClose={toggleModal}
          refetch={refetch}
        />
      );
    }
    return (
      <AddRule
        handleBanner={setBanner}
        destinations={destinations}
        bands={bands}
        products={products}
        shippingRule={{ ...ruleToUpdate }}
        show={showModal}
        onClose={toggleModal}
        refetch={refetch}
      />
    );
  };

  const renderItem = (item) => {
    if (loading) {
      return null;
    }
    const moneyFormat = currentUser.moneyFormat || constant.symbol.DOLLAR;
    return (
      <RuleItem
        moneyFormat={moneyFormat}
        item={item}
        bands={bands}
        destinations={destinations}
        products={products}
        isVendorAllowed={isVendorAllowed}
        toggleModal={toggleModal}
        toggleDeleteModal={toggleDeleteModal}
      />
    );
  };

  const resourceName = {
    singular: constant.RULE,
    plural: constant.RULES,
  };

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    setTaggedWith("");
    setState({ ...state, rulesToShow: [...state.rules], searchValue: "" });
  };

  const handleTaggedWithRemove = () => {
    setTaggedWith("");
    setSelectedFilter("");
    handleFiltersChange("");
  };

  const handleClearAll = () => {
    handleTaggedWithRemove();
  };

  // function for more filter.
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    handleFiltersChange(value);
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = !isEmpty(taggedWith)
    ? [{ key: "taggedWith", label: disambiguateLabel("taggedWith", taggedWith), onRemove: handleTaggedWithRemove }]
    : [];

  const customFilter = (
    <FormLayout>
      <Select
        label={label.show_all_vendor}
        value={selectedFilter}
        onChange={handleSelectChange}
        options={filterOptions}
        placeholder={label.select_filter}
      />
      {(selectedFilter && <TextField label={label.like} value={taggedWith} onChange={handleTaggedWithChange} />) ||
        null}
    </FormLayout>
  );

  const filters = [
    {
      key: "taggedWith",
      label: label.filter_by,
      filter: customFilter,
      shortcut: false,
    },
  ];

  const { rulesToShow } = state;

  return (
    <>
      {renderModal()}
      {renderDeleteModal()}
      <Card>
        <ResourceList
          resourceName={resourceName}
          items={loading ? [] : rulesToShow}
          renderItem={renderItem}
          loading={loading}
          filters={filters}
          queryValue={state.searchValue}
          onQueryChange={(searchValue) => handleSearchChange(searchValue)}
          onQueryClear={handleQueryClear}
          appliedFilters={appliedFilters}
          handleClearAll={handleClearAll}
        />
      </Card>
    </>
  );
};

List.propTypes = listProp.type;

export default List;

import React, { useState, useContext } from "react";
import { Modal, Stack, Select, Checkbox, DisplayText, TextField, FormLayout } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper } from "lib/helpers";

// import lib component
import { Banner } from "lib/components";

// import context
import { PrivateContext } from "lib/context";
import { AdvanceShippingContext } from "../../context/context";

// import gql
import { EDIT_OFFER } from "../../../../../../../apollo/mutations";

// import propType
import { editProp } from "../propsType";

const EditOffers = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { currentUser = {} } = useContext(PrivateContext);
  const { t } = useTranslation();
  const { editModal, toggleEditModal, bands = [], destinations = [], products = [], refetch, item, moneyFormat } =
    props || {};
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });
  const {
    _id,
    shippingBandId,
    price: priceVal,
    description: descriptions,
    destinationCode,
    productId,
    priceType: priceAmount,
    isCumulative,
  } = item || {};
  const [checked, setChecked] = useState(isCumulative);
  const [priceType, setPriceAmount] = useState(priceAmount);
  const [priceValue, setPriceValue] = useState(priceVal);
  const [description, setDescription] = useState(descriptions);
  const isFieldDisabled = false;
  const [updateOfferManage, { loading: updateLoading }] = useMutation(EDIT_OFFER);

  const handleChange = (value) => {
    setDescription(value);
  };
  const toggleBanner = () => {
    setBanner({ isOpen: !banner.isOpen });
  };
  const { FIXED, FREE, gql } = constant;
  const options = [
    { label: t("label.Fixed"), value: FIXED },
    { label: t("label.FreeShipping"), value: FREE },
  ];
  const handleType = (value) => {
    if (value !== FIXED) {
      setPriceValue("");
    }
    setPriceAmount(value);
  };
  const handleCost = (value) => {
    setPriceValue(value);
  };

  const isValid = () => {
    if (!description) {
      return t("error.advanceShipping.AddDescriptionForOffer");
    }
    if (description.length > 255) {
      return t("error.advanceShipping.DescriptionCharacter");
    }
    if (!priceType) {
      return t("error.advanceShipping.SelectShippingOfferType");
    }
    if (priceType !== FREE && !priceValue) {
      return t("error.advanceShipping.ProvideCost");
    }
    if (priceType !== FREE && priceValue < 0) {
      return t("error.advanceShipping.ProvideValidPrice");
    }
    return false;
  };

  const updateShipping = () => {
    const paramsToUpdate = {
      _id,
      parentId: item && item.parentId,
      priceType,
      description,
      isCumulative: checked,
      price: (priceType && priceType === constant.FIXED && parseFloat(priceValue)) || 0,
    };

    const { userId: ruleCreatedBy = null } = item || {};
    const { _id: currentUserId = null } = currentUser || {};
    if (baseHelper.mongoIdAsString(ruleCreatedBy) !== baseHelper.mongoIdAsString(currentUserId)) {
      paramsToUpdate.parentId = _id;
    }

    updateOfferManage({
      variables: {
        input: paramsToUpdate,
      },
    })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, gql.UPDATE_SHIPPING_OFFER);
        if (resError) {
          setBanner({ isOpen: true, title: resError, status: "critical" });
          return;
        }

        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_SHIPPING_OFFER);
        if (responseData) {
          setBannerOuter({ isOpen: true, title: t("message.ShippingOfferEdited"), status: "success" });
          toggleEditModal();
          refetch();
        }
      })
      .catch(() => {
        setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
      });
  };
  const handleSubmit = () => {
    const isValidation = isValid();
    if (isValidation) {
      setBanner({
        isOpen: true,
        title: isValidation,
        status: "critical",
      });
      return false;
    }
    updateShipping();
    return true;
  };

  const shippingBand =
    (bands.length > 0 &&
      bands.find((band) => {
        return band.value === shippingBandId || band.parentId === shippingBandId;
      })) ||
    "";
  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((country) => country.value === destinationCode);
  const product = products.find((pItem) => pItem.value === productId);

  if (!isVendorAllowed) {
    return null;
  }

  return (
    <>
      <Modal
        open={editModal}
        onClose={toggleEditModal}
        title={t("label.EditOffer")}
        primaryAction={{
          content: t("label.Update"),
          onAction: handleSubmit,
          loading: updateLoading,
        }}
      >
        <Modal.Section>
          {banner.isOpen && [
            <Banner
              isOpen={banner.isOpen}
              onDismiss={toggleBanner}
              message={banner.message}
              title={banner.title}
              status={banner.status}
            />,
            <br />,
          ]}
          <Stack vertical>
            <Stack.Item>
              <DisplayText size="small">
                {`${t("label.ShippingBand")}: ${shippingBand && shippingBand.label}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <DisplayText size="small">
                {` ${t("label.Destination")}: ${(destination && destination.label) || t("label.All")}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <DisplayText size="small">
                {` ${t("label.Products")}: ${(product && product.label) || t("label.All")}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <TextField
                label={`${t("label.Description")} *`}
                placeholder={t("placeHolder.Description")}
                value={description}
                onChange={handleChange}
              />
            </Stack.Item>
            <Stack.Item>
              <FormLayout>
                <Stack>
                  <Select
                    label={`${t("label.FixedOrFreeShipping")} *`}
                    options={options}
                    onChange={handleType}
                    value={priceType}
                    placeholder={t("placeHolder.SelectShippingRate")}
                  />
                  {priceType === FIXED && (
                    <Stack.Item>
                      <TextField
                        type="number"
                        label={`${t("label.Amount")} *`}
                        min={0}
                        step={0.01}
                        value={(priceValue && priceValue.toString()) || ""}
                        disabled={isFieldDisabled}
                        onChange={handleCost}
                        prefix={moneyFormat}
                      />
                    </Stack.Item>
                  )}
                </Stack>
              </FormLayout>
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                label={t("label.ChargeCumulative")}
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};

EditOffers.propTypes = editProp.type;

export default EditOffers;

import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import {
  brandHandleSchema,
  brandNameSchema,
  citySchema,
  countrySchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  postalCodeSchema,
  provinceCodeSchema,
  streetAddressSchema,
} from "./schema";

const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  STREET_ADDRESS,
  CITY,
  COUNTRY,
  POSTAL_CODE,
  PROVINCE_CODE,
  BRAND_NAME,
  BRAND_HANDLE,
} = constant;
const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, t) => {
  let promise = "";
  switch (field) {
    case FIRST_NAME:
      promise = validateValue(firstNameSchema(`${t("message.updateVendorProfile.fieldRequired")}`), {
        firstName: value,
      });
      break;
    case LAST_NAME:
      promise = validateValue(lastNameSchema(`${t("message.updateVendorProfile.fieldRequired")}`), {
        lastName: value,
      });
      break;
    case EMAIL:
      promise = validateValue(
        emailSchema(
          `${t("message.updateVendorProfile.email.requiredMessage")}`,
          `${t("message.updateVendorProfile.email.matches")}`
        ),
        { email: value }
      );
      break;
    case PHONE_NUMBER:
      promise = validateValue(
        phoneNumberSchema(
          `${t("message.updateVendorProfile.phoneNumber")}`,
          `${t("message.updateVendorProfile.minPhoneNumber")}`,
          `${t("message.updateVendorProfile.maxPhoneNumber")}`
        ),
        {
          phoneNumber: value,
        }
      );
      break;
    case STREET_ADDRESS:
      promise = validateValue(streetAddressSchema(`${t("message.updateVendorProfile.streetAddress")}`), {
        streetAddress: value,
      });
      break;
    case CITY:
      promise = validateValue(citySchema(`${t("message.updateVendorProfile.city")}`), {
        city: value,
      });
      break;
    case PROVINCE_CODE:
        promise = validateValue(provinceCodeSchema(`${t("message.updateVendorProfile.provinceCode")}`), {
          provinceCode: value,
        });
        break;
    case COUNTRY:
      promise = validateValue(countrySchema(`${t("message.updateVendorProfile.country")}`), {
        country: value,
      });
      break;
    case POSTAL_CODE:
      promise = validateValue(
        postalCodeSchema(
          `${t("message.updateVendorProfile.minPostalCode")}`,
          `${t("message.updateVendorProfile.maxPostalCode")}`
        ),
        {
          postalCode: value,
        }
      );
      break;
    case BRAND_NAME:
      promise = validateValue(brandNameSchema(`${t("message.updateVendorProfile.brandName")}`), {
        brandName: value,
      });
      break;
    case BRAND_HANDLE:
      promise = validateValue(
        brandHandleSchema(
          `${t("message.updateVendorProfile.brandHandle.required")}`,
          `${t("message.updateVendorProfile.brandHandle.match")}`
        ),
        {
          brandName: value,
        }
      );
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;

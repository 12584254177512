import React, { useCallback, useContext, useState } from "react";
import { Card, Stack, Link, List, Caption, TextStyle, Collapsible, Button } from "@shopify/polaris";
import PropTypes from "prop-types";

// import context
import { PrivateContext } from "lib/context";

// config
import constant from "lib/constant/constant";
import storageHelper from "lib/helpers/storage";
import appConfig from "config";
import quickActionConfig from "./quickActionConfig";
import { config, title } from "../quickSetting/quickSettingConfig";

const { brandPageURL } = appConfig;

const OperatorQuickAction = (props) => {
  const { link = [] } = props;
  const { match, history, cms, currentUser } = useContext(PrivateContext);
  const { path } = match;
  const [openActionLink, setActive] = useState(true);
  const [actionLinkExpanded, setExpanded] = useState(false);
  const handleToggle = useCallback(() => setActive((actionLink) => !actionLink), []);

  const currentUserContext = {
    brandName: currentUser.brandName,
    domain: brandPageURL,
    shop: currentUser.shop,
    token: storageHelper.get("token"),
  };

  const actionCard = (action) => {
    const onAction = (action.action && action.action.bind(currentUserContext)) || (() => history.push(action.link));

    return (
      <List>
        <List.Item>
          <Stack>
            <Link onClick={onAction} loading={action.loading || false}>
              <TextStyle variation="strong">{action.label}</TextStyle>
            </Link>
          </Stack>
          <Stack>
            <Caption>{action.caption}</Caption>
          </Stack>
        </List.Item>
      </List>
    );
  };

  const getQuickActions = (actions = []) => {
    let updatedActions = [...actions];
    // NOTE: Remove this condition, once the development and QA is completed
    if (process.env.REACT_APP_ENV === "prod") {
      updatedActions = updatedActions.filter((action) => action.id !== "brandPage");
    }
    return updatedActions.map((links) => actionCard(links));
  };

  const getQuickSettings = (settings = []) => {
    return settings.map((setting) => actionCard(setting));
  };

  const quickActions = (link.length && link) || quickActionConfig[path];

  return (
    <Card>
      <Card.Header title={constant.ACTION}>
        <Button
          plain
          disclosure={actionLinkExpanded ? "down" : "up"}
          onClick={() => {
            handleToggle();
            setExpanded(!actionLinkExpanded);
          }}
        >
          {actionLinkExpanded ? "Show" : "Hide"}
        </Button>
      </Card.Header>

      <br />

      <Collapsible
        open={openActionLink}
        id="collapsibleQuickLink"
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <Card>
          <Card.Section subdued>{getQuickActions(quickActions)}</Card.Section>
          {config[path] && (
            <Card.Section subdued title={title[path] || cms("common.actions.title.setting")}>
              {getQuickSettings(config[path])}
            </Card.Section>
          )}
        </Card>
      </Collapsible>
    </Card>
  );
};

OperatorQuickAction.propTypes = {
  link: PropTypes.arrayOf(PropTypes.object),
};

OperatorQuickAction.defaultProps = {
  link: [],
};

export default OperatorQuickAction;

import React, { useState, useContext } from "react";
import { Modal, Card, TextField, Stack, Button, RadioButton, Select } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { PrivateContext } from "lib/context";
import { useTranslation } from "react-i18next";
import { AutoComplete, Banner } from "lib/components";
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { ADD_OFFER } from "../../../../../../../apollo/mutations";
import { addProp } from "../propsType/index";
import Destinations from "../subFeatures/destinations";
import RenderOfferCondition from "../subFeatures/renderOfferCondition";
import RenderOfferDetail from "../subFeatures/renderOfferDetail";
import RenderOfferDestination from "../subFeatures/renderOfferDestination";

// import advance shipping context
import { AdvanceShippingContext } from "../../context/context";

const AddOffers = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { addModal, toggleAddModal, destinationData, shippingBands, refetch, products } = props;
  const { currentUser } = useContext(PrivateContext);
  const { moneyFormat } = currentUser;
  const { t } = useTranslation();
  const defaultOffer = {
    productQuantity: {
      value: 0,
      criteria: "",
    },
    productPrice: {
      value: 0,
      criteria: "",
    },
    orderValue: {
      value: 0,
      criteria: "",
    },
  };
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });
  const { CRITERIA, FIXED, FREE, ORDER_VALUE, PRODUCT_PRICE, PRODUCT_QUANTITY, VALUE, gql } = constant;
  const [shippingOffer, setShippingOffer] = useState({ ...defaultOffer });
  const [description, setDescription] = useState("");
  const [shippingBandId, setShippingBandId] = useState("");
  const [checkedDestination, setCheckedDestination] = useState(true);
  const [checkedProduct, setCheckedProduct] = useState(true);
  const [checkedCondition, setCheckedCondition] = useState(true);
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [offerCriteria, setofferCriteria] = useState("");
  const [offerCriteriaValue, setofferCriteriaValue] = useState("");
  const [priceType, setPricetype] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [isCumulative, setisCumulative] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [popoverActive, setPopoverActive] = useState(false);
  const [destinationCodes, setDestinationCodes] = useState([]);

  const handleShippingBand = (value) => {
    setShippingBandId(value);
  };

  const togglePopoverActive = () => setPopoverActive(!popoverActive);
  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {t("label.SelectRegions")}
    </Button>
  );
  const handleDestination = () => setCheckedDestination(!checkedDestination);
  const handleProduct = () => {
    if (!checkedProduct) {
      setProductIds("");
    }
    setCheckedProduct(!checkedProduct);
  };
  const handleCondition = () => setCheckedCondition(!checkedCondition);

  const handleSelectChangeDropdown = (value) => {
    if (value === ORDER_VALUE) {
      setProductIds([]);
      setCheckedProduct(!checkedProduct);
      setisCumulative(!isCumulative);
    }
    setSelectedDropdown(value);
    setofferCriteria("");
    setofferCriteriaValue("");
  };
  const handleShippingDestination = (value) => {
    setDestinationCodes(value);
  };

  const [createOfferManage, { loading: createLoading }] = useMutation(ADD_OFFER);

  let isOrderValue = selectedDropdown === ORDER_VALUE;
  const handleCriteria = (criteria, value) => {
    if (!shippingOffer[criteria]) {
      shippingOffer[criteria] = {
        criteria: value,
      };
    } else {
      shippingOffer[criteria].criteria = value;
    }
    setShippingOffer({ ...shippingOffer });
    setofferCriteria(value);
  };

  const handleCriteriaValue = (criteria, value) => {
    if (!shippingOffer[criteria]) {
      shippingOffer[criteria] = {
        value,
      };
    } else {
      shippingOffer[criteria].value = value;
    }
    setShippingOffer({ ...shippingOffer });
    setofferCriteriaValue(value);
  };

  const handleDescription = (value) => {
    setDescription(value);
  };
  const handleProducts = (value) => {
    setProductIds(value);
  };
  const handleType = (value) => {
    if (value !== FIXED) {
      setPriceValue("");
    }
    setPricetype(value);
  };
  const handleCost = (value) => {
    setPriceValue(value);
  };
  const toggleCumulativeShipping = () => {
    setisCumulative(!isCumulative);
  };

  const renderRegion = () => {
    return <Destinations destinationData={destinationData} destinationCodes={destinationCodes} />;
  };

  const isValid = () => {
    const { productQuantity, productPrice, orderValue } = shippingOffer || {};
    const isProductQuantity = selectedDropdown === PRODUCT_QUANTITY;
    const isProductPrice = selectedDropdown === PRODUCT_PRICE;
    isOrderValue = selectedDropdown === ORDER_VALUE;
    if (!shippingBandId) {
      return t("error.advanceShipping.SelectShippingBand");
    }
    if (isProductQuantity && !(productQuantity && CRITERIA in productQuantity && productQuantity.criteria)) {
      return t("error.advanceShipping.SelectCriteriaQuantity");
    }
    if (isProductQuantity && !(productQuantity && VALUE in productQuantity && productQuantity.value)) {
      return t("error.advanceShipping.ProvideQuantity");
    }
    if (isProductQuantity && productQuantity.value <= 0) {
      return t("error.advanceShipping.EnterValidQuantity");
    }
    if (isProductPrice && !(productPrice && CRITERIA in productPrice && productPrice.criteria)) {
      return t("error.advanceShipping.SelectCriteriaProduct");
    }
    if (isProductPrice && !(productPrice && VALUE in productPrice && productPrice.value)) {
      return t("error.advanceShipping.ProvidePrice");
    }
    if (isProductPrice && productPrice.value <= 0) {
      return t("error.advanceShipping.EnterValidPrice");
    }
    if (isOrderValue && !(orderValue && CRITERIA in orderValue && orderValue.criteria)) {
      return t("error.advanceShipping.SelectCriteriaOrder");
    }
    if (isOrderValue && !(orderValue && VALUE in orderValue && orderValue.value)) {
      return t("error.advanceShipping.ProvideOrder");
    }
    if (isOrderValue && orderValue.value <= 0) {
      return t("error.advanceShipping.EnterValidOrderAmount");
    }
    if (!description) {
      return t("error.advanceShipping.AddDescriptionForOffer");
    }
    if (description.length > 255) {
      return t("error.advanceShipping.DescriptionCharacter");
    }
    if (!priceType) {
      return t("error.advanceShipping.SelectShippingOfferType");
    }
    if (priceType !== FREE && !priceValue) {
      return t("error.advanceShipping.ProvideCost");
    }
    if (priceType !== FREE && priceValue < 0) {
      return t("error.advanceShipping.ProvideValidPrice");
    }
    return false;
  };

  const addShipping = () => {
    createOfferManage({
      variables: {
        input: {
          shippingBandId,
          price: parseFloat(priceValue, 10),
          description,
          condition: selectedDropdown,
          destinationCodes,
          productIds,
          ...shippingOffer,
          priceType,
          isCumulative,
        },
      },
    })
      .then((res) => {
        const resData = baseHelper.getResponseData(res.data, gql.CREATE_SHIPPING_OFFER);
        if (!resData) {
          const resError = baseHelper.getResponseError(res.data, gql.CREATE_SHIPPING_OFFER);
          setBanner({ isOpen: true, title: resError, status: "critical" });
          return;
        }

        setBannerOuter({ isOpen: true, title: t("message.ShippingOfferAdded"), status: "success" });
        toggleAddModal();
        refetch();
      })
      .catch(() => {
        setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
      });
  };
  const handleSubmit = () => {
    const isValidation = isValid();
    if (isValidation) {
      setBanner({
        isOpen: true,
        title: isValidation,
        status: "critical",
      });
      return false;
    }
    addShipping();
    return true;
  };
  const toggleBanner = () => {
    setBanner({ isOpen: !banner.isOpen });
  };

  const offerConditions = () => {
    return (
      <RenderOfferCondition
        selectedDropdown={selectedDropdown}
        shippingOffer={shippingOffer}
        checkedCondition={checkedCondition}
        handleSelectChangeDropdown={handleSelectChangeDropdown}
        offerCriteria={offerCriteria}
        offerCriteriaValue={offerCriteriaValue}
        handleCriteriaValue={handleCriteriaValue}
        handleCriteria={handleCriteria}
        handleCondition={handleCondition}
        moneyFormat={moneyFormat}
      />
    );
  };
  const OfferDetail = () => {
    return (
      <RenderOfferDetail
        isCumulative={isCumulative}
        handleCost={handleCost}
        handleDescription={handleDescription}
        handleType={handleType}
        priceType={priceType}
        description={description}
        toggleCumulativeShipping={toggleCumulativeShipping}
        isOrderValue={isOrderValue}
        moneyFormat={moneyFormat}
        priceValue={priceValue}
      />
    );
  };
  const OfferDestination = () => {
    return (
      <RenderOfferDestination
        checkedDestination={checkedDestination}
        renderRegion={renderRegion}
        handleDestination={handleDestination}
        popoverActive={popoverActive}
        activator={activator}
        handleShippingDestination={handleShippingDestination}
        destinationData={destinationData}
        destinationCodes={destinationCodes}
        togglePopoverActive={togglePopoverActive}
      />
    );
  };

  if (!isVendorAllowed) {
    return null;
  }

  return (
    <>
      <Modal
        open={addModal}
        large
        onClose={toggleAddModal}
        title={t("label.AddNewOffer")}
        primaryAction={{
          content: t("label.Add"),
          onAction: handleSubmit,
          loading: createLoading,
        }}
      >
        <Modal.Section>
          {banner.isOpen && [
            <Banner
              isOpen={banner.isOpen}
              onDismiss={toggleBanner}
              message={banner.message}
              title={banner.title}
              status={banner.status}
            />,
            <br />,
          ]}
          <Stack vertical>
            <Card title={t("label.ShippingBand")} sectioned>
              <Stack.Item>
                <Select
                  options={shippingBands}
                  placeholder={`${t("placeHolder.SelectShippingBand")} *`}
                  value={shippingBandId}
                  onChange={handleShippingBand}
                />
              </Stack.Item>
            </Card>
            {OfferDestination()}
            {!isOrderValue && (
              <Card title={t("label.product")} sectioned>
                <Stack.Item>
                  <RadioButton
                    label={t("label.AllProducts")}
                    helpText={t("message.AllowAllProduct")}
                    checked={checkedProduct}
                    id="all-products"
                    name="all-products"
                    onChange={handleProduct}
                  />
                </Stack.Item>
                <Stack.Item>
                  <RadioButton
                    label={t("label.OnlySelectedProducts")}
                    helpText={t("message.AllowSelectedProduct")}
                    id="selected-products"
                    name="selected-products"
                    checked={!checkedProduct}
                    onChange={handleProduct}
                  />
                  {!checkedProduct && (
                    <AutoComplete
                      id="productOfferCondition"
                      label={t("label.shippingOffer.productPlural")}
                      labelHidden
                      placeholder={t("placeHolder.shippingOffer.startTypingTitle")}
                      values={products}
                      selected={productIds}
                      onChange={handleProducts}
                      minimumSearchLength={3}
                    />
                  )}
                </Stack.Item>
              </Card>
            )}
            {offerConditions()}
            {OfferDetail()}
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};

AddOffers.propTypes = addProp.type;

export default AddOffers;

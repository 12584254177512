import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Layout, Card, FormLayout, TextField, PageActions, TextContainer } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// libs
import { Banner, SkeletonAnnotated, Sheet } from "lib/components";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { baseHelper, storageHelper } from "lib/helpers";

// config
import fields from "./updateProfileConfig";
import validate from "./yup";

// gql
import { EDIT_PROFILE } from "../../../../../apollo/mutations";

// sub features
import EmailChangeModal from "./subFeatures";

const {
  onboardingSteps: { WELCOME, ADD_PRODUCT },
} = constant;

const UpdateProfile = (props) => {
  const {
    BRAND_NAME,
    BRAND_SLUG,
    CITY,
    COUNTRY,
    EMAIL,
    FIRST_NAME,
    gql,
    LAST_NAME,
    PHONE_NUMBER,
    POSTAL_CODE,
    PROVINCE_CODE,
    STREET_ADDRESS,
  } = constant;

  const { UPDATE_PROFILE } = gql;
  const { setSelectedTab } = props;
  const onNext = () => setSelectedTab(ADD_PRODUCT);
  const onPrevious = () => setSelectedTab(WELCOME);

  const { currentUser, history, cms = {}, isLoading = false, currentUserRefetch = () => {} } = useContext(
    PrivateContext
  );
  const { t } = useTranslation();
  const {
    firstName = "",
    lastName = "",
    email = "",
    phoneNumber = "",
    brandName = "",
    address = "",
    city = "",
    country = "",
    pinCode = "",
    provinceCode = "",
    setupProgress,
    slug = (currentUser.brand && currentUser.brand.slug) || "",
  } = currentUser || {};
  const isBrandHandle = !!(currentUser && currentUser.brand && currentUser.brand.slug);

  // states
  const [state, setState] = useState({
    [FIRST_NAME]: firstName,
    [LAST_NAME]: lastName,
    [EMAIL]: email && email.address,
    [PHONE_NUMBER]: phoneNumber,
    [CITY]: city,
    [COUNTRY]: country,
    [POSTAL_CODE]: pinCode,
    [PROVINCE_CODE]: provinceCode,
    [STREET_ADDRESS]: address,
    [BRAND_NAME]: brandName,
    [BRAND_SLUG]: slug,
  });
  const [error, setError] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [banner, setBanner] = useState({
    status: "",
    title: "",
    children: null,
    isOpen: false,
  });
  const [sheetActive, setSheetActive] = useState(false);

  useEffect(() => {
    setState({
      [FIRST_NAME]: firstName,
      [LAST_NAME]: lastName,
      [EMAIL]: email && email.address,
      [PHONE_NUMBER]: phoneNumber,
      [CITY]: city,
      [COUNTRY]: country,
      [POSTAL_CODE]: pinCode,
      [PROVINCE_CODE]: provinceCode,
      [STREET_ADDRESS]: address,
      [BRAND_NAME]: brandName,
      [BRAND_SLUG]: slug,
    });
  }, [
    firstName,
    FIRST_NAME,
    lastName,
    LAST_NAME,
    email,
    EMAIL,
    phoneNumber,
    PHONE_NUMBER,
    city,
    CITY,
    country,
    COUNTRY,
    pinCode,
    POSTAL_CODE,
    provinceCode,
    PROVINCE_CODE,
    address,
    STREET_ADDRESS,
    brandName,
    BRAND_NAME,
    slug,
    BRAND_SLUG,
  ]);

  // gql states
  const [updateProviderProfile, { loading: updateProviderProfileLoading }] = useMutation(EDIT_PROFILE);

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const updateProfile = async () => {
    updateProviderProfile({
      variables: {
        input: {
          formValues: {
            firstName: state[FIRST_NAME],
            lastName: state[LAST_NAME],
            email: state[EMAIL],
            phoneNumber: state[PHONE_NUMBER].toString(),
            brandName: state[BRAND_NAME],
            address: state[STREET_ADDRESS],
            city: state[CITY],
            country: state[COUNTRY],
            pinCode: state[POSTAL_CODE],
            provinceCode: state[PROVINCE_CODE],
            brand: {
              slug: state[BRAND_SLUG],
            },
          },
        },
      },
    })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, UPDATE_PROFILE);
        if (responseError) {
          setBanner({
            title: responseError,
            status: "critical",
            isOpen: true,
          });
          return;
        }
        if (isModalActive) {
          setBanner({
            title: t("message.updateVendorProfile.verifyUpdatedEmail"),
            children: t("message.updateVendorProfile.redirectLogin"),
            status: "success",
            isOpen: true,
          });
          storageHelper.remove("token");
          setTimeout(() => {
            history.push("/login");
          }, 5000);
          setIsModalActive(false);
          return;
        }
        onNext();
        currentUserRefetch();
      })
      .catch(() => {
        setBanner({
          status: "critical",
          title: t("error.common.somethingWentWrong"),
          isOpen: true,
        });
      });
  };

  // check for any validation error or empty fields
  const validateFields = () => {
    const vendorRequiredData = { ...state };
    delete vendorRequiredData.provinceCode;
    const isAnyError = Object.values(error).some((err) => err);
    const isAnyEmptyField = Object.values(vendorRequiredData).some((value) => !value);
    return isAnyError || isAnyEmptyField;
  };

  const isOldNewValuesSame = () => {
    const newValues = {
      address: state[STREET_ADDRESS],
      brandName: state[BRAND_NAME],
      city: state[CITY],
      country: state[COUNTRY],
      email: state[EMAIL],
      firstName: state[FIRST_NAME],
      lastName: state[LAST_NAME],
      phoneNumber: state[PHONE_NUMBER],
      pinCode: state[POSTAL_CODE],
      provinceCode: state[PROVINCE_CODE],
      slug: state[BRAND_SLUG],
    };
    const oldValues = {
      address,
      brandName,
      city,
      country,
      email: email && email.address,
      firstName,
      lastName,
      phoneNumber,
      pinCode,
      provinceCode,
      slug,
    };

    return baseHelper.isItemEqual(newValues, oldValues);
  };

  const onSubmit = () => {
    if (validateFields()) {
      return;
    }
    if (email && email.address !== state[EMAIL]) {
      setIsModalActive(true);
      return;
    }
    const noChangeInProfile = isOldNewValuesSame();
    if (noChangeInProfile && setupProgress !== 0) {
      onNext();
      return;
    }
    updateProfile();
  };

  const handleChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    if (key === constant.BRAND_NAME && !isBrandHandle) {
      const brandHandleAccurateValue = baseHelper.generateBrandHandle(value);
      handleChange(constant.BRAND_SLUG, brandHandleAccurateValue);
    }
  };

  const handleValidation = async (key, value) => {
    const validationError = await validate(key, value);
    setError((prevState) => ({
      ...prevState,
      [key]: validationError,
    }));
  };

  const getFields = (text) =>
    text.map(({ key = "", type = "", label = "", placeholder = "", min = "" }) => (
      <TextField
        key={key}
        type={type}
        id={key}
        label={label}
        placeholder={placeholder}
        value={(state && state[key] && state[key].toString()) || ""}
        min={min}
        onChange={(value) => handleChange(key, value)}
        onBlur={() => handleValidation(key, state && state[key])}
        error={error && error[key]}
      />
    ));
  if ((error && error[BRAND_SLUG]) || (error && error[BRAND_NAME])) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <Layout>
      <EmailChangeModal
        isOpen={isModalActive}
        onClose={() => setIsModalActive(false)}
        onSubmit={() => updateProfile()}
        isLoading={updateProviderProfileLoading}
      />
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            status={banner.status}
            title={banner.title}
            isOpen={banner.isOpen}
            onDismiss={() => {
              setBanner({ status: "", title: "", isOpen: false, children: null });
            }}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.updateProfile.title")}
        description={cms("section.updateProfile.description")}
      >
        <Card title={cms("section.updateProfile.brandSection.title")}>
          <Card.Section>
            <TextContainer>{cms("section.updateProfile.brandSection.description")}</TextContainer>
            <br />
            <FormLayout>
              <TextField
                key={BRAND_NAME}
                id={BRAND_NAME}
                label={cms("section.updateProfile.brandSection.label.name")}
                placeholder={cms("section.updateProfile.brandSection.placeHolder.name")}
                value={state && state[BRAND_NAME]}
                onChange={(value) => handleChange(BRAND_NAME, value)}
                onBlur={() => handleValidation(BRAND_NAME, state && state[BRAND_NAME])}
                error={error && error[BRAND_NAME]}
                helpText={cms("section.updateProfile.brandSection.helpText.name")}
              />
              <TextField
                id="BrandHandle"
                label={cms("section.updateProfile.brandSection.label.handle")}
                placeholder={cms("section.updateProfile.brandSection.placeHolder.handle")}
                value={state && state[BRAND_SLUG]}
                onChange={(value) => handleChange(BRAND_SLUG, value)}
                onBlur={() => handleValidation(constant.BRAND_SLUG, state && state[BRAND_SLUG])}
                error={error && error[BRAND_SLUG]}
                helpText={cms("section.updateProfile.brandSection.helpText.handle")}
              />
            </FormLayout>
          </Card.Section>
        </Card>

        <Card
          title={cms("section.updateProfile.contactSection.title")}
          // actions={[{ content: "Learn more",onAction: () => setSheetActive(!sheetActive) }]}
        >
          <Card.Section>
            <TextContainer>{cms("section.updateProfile.contactSection.description")}</TextContainer>
            <br />
            <FormLayout>{getFields(fields)}</FormLayout>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            content: (
              <span>
                &ensp;&ensp;
                {cms("common.button.next")}
                &ensp;&ensp;
              </span>
            ),
            id: "next",
            onAction: onSubmit,
            loading: updateProviderProfileLoading,
            disabled: validateFields(),
          }}
          secondaryActions={[
            {
              content: cms("common.button.previous"),
              id: "previous",
              onAction: () => onPrevious(),
              loading: false,
            },
          ]}
        />
      </Layout.AnnotatedSection>
      <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: "Close", onAction: () => setSheetActive(false) }}
        primaryAction={{ content: "Submit", onAction: () => setSheetActive(false) }}
      />
    </Layout>
  );
};

UpdateProfile.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default UpdateProfile;

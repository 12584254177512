import React, { useState } from "react";

import Baby from "babyparse";
import fileDownload from "js-file-download";

import { Spinner } from "lib/components";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "lib/hoc";

const DownloadCSV = () => {
  const { t } = useTranslation();
  const [isDownload, setIsDownload] = useState(true);
  const data = [
    {
      firstName: t("label.vendorcsv.firstName"),
      lastName: t("label.vendorcsv.lastName"),
      email: t("label.vendorcsv.email"),
      brandName: t("label.vendorcsv.brandName"),
      phoneNumber: t("label.vendorcsv.phoneNumber"),
      address: t("label.vendorcsv.address"),
      city: t("label.vendorcsv.city"),
      country: t("label.vendorcsv.country"),
      postalCode: t("label.vendorcsv.postalCode"),
    },
  ];
  const csv = Baby.unparse(data);
  if (isDownload) {
    fileDownload(csv, "bulk-vendor.csv");
    setIsDownload(false);
    setTimeout(() => {
      window.close();
    }, 500);
  }

  return <Spinner isFullPage />;
};

export default withErrorBoundary(DownloadCSV);

import React from "react";
import { Card, TextField, Layout, Caption, TextStyle, FormLayout, TextContainer } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { baseHelper } from "lib/helpers";

const SocialLinkSection = (props) => {
  const { t } = useTranslation();
  const { socialLinkInformation, handleChange, learnMore, lastUpdated } = props;

  const getFields = () => {
    const fields = [
      {
        label: t("label.manageVendor.facebook"),
        placeholder: t("placeHolder.manageVendor.facebookUrl"),
        isRequired: false,
        key: "facebook",
      },
      {
        label: t("label.manageVendor.youTube"),
        placeholder: t("placeHolder.manageVendor.youtubeUrl"),
        isRequired: false,
        key: "youtube",
      },
      {
        label: t("label.manageVendor.twitter"),
        placeholder: t("placeHolder.manageVendor.twitterUrl"),
        isRequired: false,
        key: "twitter",
      },
      {
        label: t("label.manageVendor.instagram"),
        placeholder: t("placeHolder.manageVendor.instagramUrl"),
        isRequired: false,
        key: "instagram",
      },
      {
        label: t("label.manageVendor.linkedIn"),
        placeholder: t("placeHolder.manageVendor.linkedinUrl"),
        isRequired: false,
        key: "linkedin",
      },
    ];
    return fields.map(({ label, placeholder, isRequired, key }) => {
      const required = isRequired ? "*" : "";
      return (
        <>
          <TextField
            label={`${label}${required}`}
            placeholder={placeholder}
            value={socialLinkInformation[key]}
            id={key}
            onChange={(value) => handleChange(key, value)}
          />
          <br />
        </>
      );
    });
  };

  return (
    <Layout.AnnotatedSection
      title={t("sectionContent.manageVendor.socialLinkSection.title")}
      description={t("sectionContent.manageVendor.socialLinkSection.description")}
    >
      <Card
        title={[
          t("sectionContent.manageVendor.socialLinkSection.title"),
          lastUpdated && (
            <TextStyle variation="subdued">
              <Caption>{`Updated : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>
            </TextStyle>
          ),
        ]}
        actions={[
          {
            content: "Learn More",
            onAction: () => {
              learnMore(t("sectionContent.manageVendor.socialLinkSection.title"), "TODO:");
            },
          },
        ]}
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>Please use this section to add your social presence.</TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

SocialLinkSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  socialLinkInformation: PropTypes.shape({
    facebook: PropTypes.string,
    youtube: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    linkedin: PropTypes.string,
  }).isRequired,
  learnMore: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
};

SocialLinkSection.defaultProps = {
  lastUpdated: "",
};

export default SocialLinkSection;

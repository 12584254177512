import { gql } from "apollo-boost";

const GET_SELLER_FULFILLMENT = gql`
  query GetSellerFulfillmentData($input: OwnerId!) {
    getSellerFulfillment(input: $input) {
      data {
        type
        dropship {
          isThirdPartyShipping
          shippingOption
          nzPost {
            clientId
            clientSecret
            siteCode
            accountNumber
          }
          canadaPost {
            userName
            password
            customerNumber
            contractId
          }
        }
        consignment {
          global {
            address
            city
            country
            countryCode
            pincode
          }
          addresses {
            vendorIds
            address {
              address
              city
              country
              countryCode
              pincode
            }
          }
        }
        sellerId
      }
      status
      error
    }
  }
`;

export default GET_SELLER_FULFILLMENT;

import React from "react";
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { BulkInvite } from "app/vendors/modules/generic/feature/add/subFeatures";

const AdminBulkInvite = (props) => {
  const { setBanner, learnMore } = props;
  return <BulkInvite setBanner={setBanner} learnMore={learnMore} />;
};

AdminBulkInvite.propTypes = {
  setBanner: PropTypes.func.isRequired,
  learnMore: PropTypes.func.isRequired,
};
export default withFeature(AdminBulkInvite, { feature: constant.BULK_INVITE });

import { gql } from "apollo-boost";

const GET_SELLER_DATA = gql`
  query SellerData($input: GetSellerData!) {
    getSellerData(input: $input) {
      data {
        email {
          address
          verified
        }
        register {
          desc
          title
        }
        isOtp
      }
      status
      error
    }
  }
`;

export default GET_SELLER_DATA;

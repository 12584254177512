import constant from "lib/constant/constant";

const { BRAND_NAME, CITY, COUNTRY, EMAIL, FIRST_NAME, LAST_NAME, PHONE_NUMBER, POSTAL_CODE, PROVINCE_CODE, STREET_ADDRESS } = constant;

const textFields = [
  {
    label: "First Name*",
    placeholder: "First Name",
    key: FIRST_NAME,
    type: "text",
  },
  {
    label: "Last Name*",
    placeholder: "Last Name",
    key: LAST_NAME,
    type: "text",
  },
  {
    label: "Email*",
    placeholder: "Email",
    key: EMAIL,
    type: "text",
  },
  {
    label: "Phone Number*",
    placeholder: "Phone Number",
    key: PHONE_NUMBER,
    type: "number",
    min: 0,
  },
  {
    label: "Address",
    placeholder: "Address",
    key: STREET_ADDRESS,
    type: "text",
  },
  {
    label: "City*",
    placeholder: "City",
    key: CITY,
    type: "text",
  },
  {
    label: "Province Code",
    placeholder: "Province Code",
    key: PROVINCE_CODE,
    type: "text",
  },
  {
    label: "Country*",
    placeholder: "Country",
    key: COUNTRY,
    type: "text",
  },
  {
    label: "Postal Code*",
    placeholder: "Postal Code",
    key: POSTAL_CODE,
    type: "text",
  },
];

export default textFields;

import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, TextField } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import helper  components
import { Banner, SkeletonAnnotated } from "lib/components";

// import helper
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import error Boundary
import { withErrorBoundary } from "lib/hoc";

// import yup validation
import validate from "../../../generic/editProfile/yup/validate";

// import gql
import { EDIT_PROFILE_SYSTEM_ADMIN } from "../../../../apollo/mutation";

// import subFeature
import editProfileConfig from "./editProfileConfig";

const AdminEditProfile = () => {
  const { currentUser, isLoading, history, cms } = useContext(PrivateContext);
  const textFields = editProfileConfig(cms);

  const { UPDATE_PROFILE_SYSTEM_ADMIN } = constant.gql;

  const [updateVendorProfile, { loading: updateProfileLoading }] = useMutation(EDIT_PROFILE_SYSTEM_ADMIN);

  const [systemAdminData, setSystemAdminData] = useState({
    firstName: currentUser && currentUser.firstName,
    lastName: currentUser && currentUser.lastName,
    email: currentUser && currentUser.email && currentUser.email.address,
    phoneNumber: currentUser && currentUser.phoneNumber,
  });

  const [banner, setBanner] = useState({
    status: "",
    title: "",
    isOpen: false,
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const isAnyError = () => Object.values(errorMessage).some((error) => error);

  const handleChangeEvent = (field, value) => {
    setIsSubmitEnabled(true);
    setSystemAdminData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleValidation = async (field, value) => {
    setIsSubmitEnabled(true);
    const error = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const onSubmit = async () => {
    setIsSubmitEnabled(false);
    if (isAnyError()) {
      return;
    }
    try {
      const res = await updateVendorProfile({
        variables: {
          input: {
            firstName: systemAdminData.firstName,
            lastName: systemAdminData.lastName,
            email: systemAdminData.email,
            phoneNumber: systemAdminData.phoneNumber.toString(),
          },
        },
      });
      const responseError = baseHelper.getResponseError(res.data, UPDATE_PROFILE_SYSTEM_ADMIN);
      if (responseError) {
        setBanner({
          title: responseError,
          status: "critical",
          isOpen: true,
        });
        return;
      }
      setBanner({
        title: cms("message.success.profileUpdated"),
        status: "success",
        isOpen: true,
      });
    } catch (e) {
      setBanner({
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
        isOpen: true,
      });
    }
  };

  const getFields = (text) =>
    text.map((textField) => (
      <TextField
        key={textField.key}
        id={textField.key}
        label={textField.label}
        placeholder={textField.placeholder}
        value={(systemAdminData[textField.key] && systemAdminData[textField.key].toString()) || ""}
        onChange={(value) => handleChangeEvent(textField.key, value)}
        onBlur={() => handleValidation(textField.key, systemAdminData[textField.key])}
        error={errorMessage && errorMessage[textField.key]}
      />
    ));

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner title={banner.title} status={banner.status} isOpen={banner.isOpen} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.editProfile.title")}
        description={cms("section.editProfile.description")}
      >
        <Card
          sectioned
          primaryFooterAction={{
            content: cms("common.button.submit"),
            key: "submitButton",
            onAction: () => onSubmit(),
            loading: updateProfileLoading,
            disabled: updateProfileLoading || !isSubmitEnabled,
          }}
          secondaryFooterActions={[
            {
              content: cms("common.button.cancel"),
              key: "cancelButton",
              onAction: () => history.push("/"),
            },
          ]}
        >
          <FormLayout>{getFields(textFields)}</FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
};

export default withErrorBoundary(AdminEditProfile);

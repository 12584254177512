import React, { useContext, useEffect } from "react";
import { useMutation } from "react-apollo";
import { TextContainer } from "@shopify/polaris";

// import helpers
import {baseHelper, errorHelper} from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import helpers components
import { Spinner } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import gql
import { CONNECT_STRIPE_VENDOR } from "../../../../apollo/mutations";

const StripeCallback = () => {
  const { history, currentUser, isLoading } = useContext(PrivateContext);
  const { gql } = constant;
  const { _id: id } = currentUser;
  const [connectToStripe, { loading }] = useMutation(CONNECT_STRIPE_VENDOR);
  const { code, state } = baseHelper.getQueryParams(history.location.search);

  const redirectRoute = "/payment-connect";

  const connect = async () => {
    try {
      const response = await connectToStripe({ variables: { input: { code, state } } });
      const responseData = baseHelper.getResponseData(response.data, gql.CONNECT_STRIPE_VENDOR);
      const responseError = baseHelper.getResponseError(response.data, gql.CONNECT_STRIPE_VENDOR);

      if (responseError) {
        history.push("/payment-connect", { isStripeConnected: false, message: responseError });
      }

      if (responseData) {
        history.push("/payment-connect", {
          isStripeConnected: true,
        });
      }
    } catch (exception) {
      history.push(redirectRoute, {
        message: errorHelper.parse(exception),
        isStripeConnected: false,
      });
    }
  };

  useEffect(() => {
    if (code) {
      connect();
    }
    if (!code) {
      history.push(redirectRoute);
    }
  }, [code, history]);

  if (loading || isLoading) {
    return <Spinner fontSize={42} />;
  }

  if (id !== state) {
    return <TextContainer>Not Found</TextContainer>;
  }
  return null;
};

export default withErrorBoundary(StripeCallback);

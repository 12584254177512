import React from "react";
import { Card, Caption, Link, Stack, DataTable, TextStyle } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

import constant from "lib/constant/constant";

import { fulfillmentsProp } from "../propsType";

const Fulfillments = (props) => {
  const { t } = useTranslation();
  const { lineData } = props;
  let row = [];
  let totalQuantity;
  let fulfilledQuantity;
  if (lineData) {
    row = lineData
      .filter((value) => {
        if (value.fulfillmentStatus === constant.FULFILLED) {
          return value;
        }
      })
      .map((value) => {
        return [
          <Stack.Item fill>
            <Link url="">{value.name}</Link>
            <Caption>
              <br />
              <TextStyle>
                {`${t("label.provider")}: `}
                <Link>{value.vendor}</Link>
              </TextStyle>
            </Caption>
          </Stack.Item>,

          <Stack.Item>{value.trackingCompany}</Stack.Item>,
          <Stack.Item>{value.trackingNumber}</Stack.Item>,
        ];
      });
    totalQuantity = lineData.length;
    fulfilledQuantity = row.length;
  }

  return (
    <Card>
      <Card.Section subdued>
        <DataTable
          columnContentTypes={["text", "text", "numeric"]}
          headings={[t("label.product"), t("label.shippingCompany"), t("label.number")]}
          rows={row}
        />
      </Card.Section>
      <Card.Section>
        <Stack>
          <Stack.Item fill>
            <TextStyle variation="strong">{t("label.totalFulfillments")}</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation="strong">{`${fulfilledQuantity} of ${totalQuantity}`}</TextStyle>
          </Stack.Item>
        </Stack>
      </Card.Section>
    </Card>
  );
};
Fulfillments.propTypes = fulfillmentsProp.type;
export default Fulfillments;

import React, { useContext, useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Checkbox, Form, FormLayout, Layout, Card, TextStyle, Caption, TextContainer, Stack, Spinner
} from "@shopify/polaris";

import { PrivateContext } from "lib/context";

// constants
import constant from "lib/constant/constant";

// import hoc
import { withFeature } from "lib/hoc";
// import components
import { Banner, CardFooter } from "lib/components";

// propsTypes
import { baseHelper } from "lib/helpers";
// import { paymentProp } from "../../props";

// import gql
import { UPDATE_SELLER_SETTING } from "../../../../../../apollo/mutations";
import { GET_SELLER_SETTING } from "../../../../../../apollo/queries";


const Shipping = () => {
  const { cms } = useContext(PrivateContext);  
  const [isExcludeShippingAmount, setIsExcludeShippingAmount] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false); 
 
  const [lastUpdated, setLastUpdated] = useState("");
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [setUpShipping, { loading: shippingLoading }] = useMutation(UPDATE_SELLER_SETTING);
  const { data: sellerSetting } = useQuery(GET_SELLER_SETTING);

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });
  
  const handleChange = val => {
    setIsExcludeShippingAmount(val);
    setIsSubmit(true);
  }

  useEffect(() => {
    if (!sellerSetting) {
      return;
    }
    const sellerData = baseHelper.getResponseData(sellerSetting, constant.gql.GET_SELLER_SETTING);
    if (!sellerData) {
      return;
    }

    const { shipping = {} } = sellerData;
    const { isExcludeShippingAmount = false, updatedAt = '' } = shipping || {};

    setIsExcludeShippingAmount(isExcludeShippingAmount);
    setLastUpdated(updatedAt);
    setIsSubmit(false)

  }, [sellerSetting]);

  const handleSubmit = async () => {
    if (!isSubmit) {
      return;
    }
    try {
      const response = await setUpShipping({
        variables: {
          input: {
            isExcludeShippingAmount
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.UPDATE_SELLER_SETTING);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: "success", title: cms("message.settingSuccess") });
    } catch (resError) {
      setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
    }

  }

  return (
     <Layout.AnnotatedSection title={cms("section.shipping.title")} description={cms("section.shipping.description")}>
       {banner.isOpen && (
        <Banner
          isOpen={banner.isOpen}
          status={banner.status}
          title={banner.title}
          isScrollTop={false}
          onDismiss={() => dismissBanner()}
        />
      )}
      <br />
      <Card
        title={[
            cms("section.shipping.label"),
            <TextStyle variation="subdued">
              {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
            </TextStyle>,
          ]}
        actions={[
            {
              content: cms("common.label.learnMore"),
            },
        ]}
        sectioned
      >
      
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <TextContainer>{cms("section.shipping.helpText")}</TextContainer>
          <Stack>
            <Checkbox
              label={cms("section.shipping.checkboxTitle")}
              checked={isExcludeShippingAmount}
              onChange={handleChange}
            />
          </Stack>
        </FormLayout>
        <br />
        <CardFooter disabled={!isSubmit} 
          loading={shippingLoading || false} 
        />
      </Form>
      </Card>
      </Layout.AnnotatedSection>
  );
};

export default withFeature(Shipping, { feature: constant.PAYMENT });

import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import helper components
import { Banner } from "lib/components";
// import helpers
import { baseHelper } from "lib/helpers";
// import constant
import constant from "lib/constant/constant";
// import gql
import { GET_SHIPPING_BAND } from "app/shipping/apollo/queries";
// import subFeature
import { PrivateContext } from "lib/context";
import List from "./component/list";

const ShippingBand = () => {
  const { t } = useTranslation();
  const { cms } = useContext(PrivateContext);
  const [state, setState] = useState({
    shippingBands: [],
    shippingTerm: "",
  });
  const [bannerStatus, setBannerStatus] = useState({
    isOpen: false,
    title: "",
    status: "",
  });
  const handleBanner = (bannerData) => {
    setBannerStatus(bannerData);
  };

  const { loading: queryLoading, data: queryData } = useQuery(GET_SHIPPING_BAND);

  useEffect(() => {
    if (!queryData) {
      return;
    }
    let bannerData = {};
    const responseData = baseHelper.getResponseData(queryData, constant.gql.GET_BAND);
    const responseError = baseHelper.getResponseError(queryData, constant.gql.GET_BAND);
    if (responseError) {
      bannerData = {
        isOpen: true,
        status: "critical",
        title: responseError,
      };
      handleBanner(bannerData);
    }
    if (responseData) {
      const { shippingRows = [], shippingTerm } = responseData;
      if (!shippingRows.length) {
        bannerData = {
          isOpen: true,
          status: "warning",
          title: cms("operator.message.shippingBandRequired"),
        };
        handleBanner(bannerData);
      }
      setState({
        shippingBands: shippingRows.length ? shippingRows : [],
        shippingTerm,
      });
    }
  }, [queryData, t]);

  return (
    <>
      {bannerStatus.isOpen && (
        <Banner
          isOpen={bannerStatus.isOpen}
          status={bannerStatus.status}
          onDismiss={() => setBannerStatus({ isOpen: false, status: "", title: "" })}
        >
          {bannerStatus.title}
        </Banner>
      )}
      <List bands={state.shippingBands} term={state.shippingTerm} loading={queryLoading} handleBanner={handleBanner} />
    </>
  );
};

export default ShippingBand;

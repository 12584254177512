const config = {
  "/": [
    {
      caption: "Update your profile",
      label: "Profile",
      link: "/profile",
    },
  ],
  "/orders": [
    {
      caption: "Manage order auto-accept settings",
      label: "Order Acceptance",
      link: "/setting/order",
    },
  ],
  "/payments": [
    {
      caption: "Connect to stripe for payments",
      label: "Payout using Stripe",
      link: "/payment-connect",
    },
    {
      caption: "Connect to paypal for payments",
      label: "Payout using Paypal",
      link: "/payment-connect",
    },
  ],
  "/products": [
    {
      caption: "Manage auto add product settings",
      label: "Auto Add Product",
      link: "/setting/product",
    },
    {
      caption: "Manage discount settings",
      label: "Discount",
      link: "/setting/discount",
    },
    {
      caption: "Manage shipping settings",
      label: "Shipping",
      link: "/shipping",
    },
  ],
};

const title = {
  "/": "Settings",
  "/orders": "Order Settings",
  "/payments": "Payment Settings",
  "/products": "Product Settings",
};

export { config, title };

import React, { useContext } from "react";
import { Card, TextField, Select, FormLayout, Stack } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
import { FormContext } from "../../../add/context/context";

const { BUCKET_NAME, FOLDER_PATH, SECRET_ACCESS_KEY, ACCESS_KEY_ID } = constant;

const Bucket = props => {
    const { cms } = useContext(PrivateContext);
    const { data, handleChange } = useContext(FormContext);
 
  return (
      <Card sectioned>
        <FormLayout>
          <TextField 
          label="S3 Bucket Name *" 
          placeholder="Name of the S3 bucket that you want to add as a origin" 
          onChange={value => handleChange(constant.BUCKET_NAME, value)}
          value={data.bucket || ""} />

          <TextField 
          label="S3 Bucket Folder *" 
          placeholder="The folder in your bucket that contain images or enter '/' for root directory" 
          value={data.path || ""}
          onChange={value => handleChange(constant.FOLDER_PATH, value)} />

          <TextField 
          label="S3 Access Key *" 
          placeholder="accessKeyId of your s3 bucket" 
          onChange={value => handleChange(constant.ACCESS_KEY_ID, value)} 
          value={data.id || ""} />

          <TextField 
          label="S3 Secret Key *" 
          placeholder="secretAccessKey of your s3 bucket" 
          onChange={value => handleChange(constant.SECRET_ACCESS_KEY, value)}
          value={data.secret || ""} />
        </FormLayout>
      </Card>
  );
}

export default (Bucket);
import { gql } from "apollo-boost";

const CREATE_BAND = gql`
  mutation CreateShippingBand($input: CreateShippingBand!) {
    createShippingBand(input: $input) {
      status
      data
      error
    }
  }
`;

const UPDATE_SHIPPING_BAND = gql`
  mutation UpdateShippingBand($input: UpdateShippingBand!) {
    updateShippingBand(input: $input) {
      status
      data
      error
    }
  }
`;

const DELETE_BAND = gql`
  mutation DeleteShippingBand($input: DeleteShippingBand!) {
    deleteShippingBand(input: $input) {
      status
      data
      error
    }
  }
`;

const SAVE_SHIPPING = gql`
  mutation saveShipping($input: SaveShipping!) {
    saveShipping(input: $input) {
      data
      status
      error
    }
  }
`;

export { SAVE_SHIPPING, CREATE_BAND, UPDATE_SHIPPING_BAND, DELETE_BAND };

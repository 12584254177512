import React, { useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Card, Layout, Stack, Collapsible, TextContainer, RadioButton } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../../../context/context";

const Email = () => {
  const { cms = () => {} } = useContext(PrivateContext);
  const { page, learnMore } = useContext(BrandingContext);
  const { email, handleChange, handleTiny } = page;
  const { IS_DEFAULT, EMAIL, CONTENT } = constant;

  const emailList = [
    {
      label: cms("section.emailSignature.label.default"),
      value: true,
      id: "defaultEmail",
    },
    {
      label: cms("section.emailSignature.label.customized"),
      value: false,
      id: "customEmail",
    },
  ];

  const renderList = () => {
    return emailList.map((list) => (
      <RadioButton
        label={list.label}
        checked={email.isDefault === list.value}
        id={list.id}
        key={list.id}
        name="email"
        onChange={() => handleChange(IS_DEFAULT, list.value, EMAIL)}
      />
    ));
  };

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.emailSignature.title")}
        description={cms("section.emailSignature.description")}
      >
        <Card
          title={cms("section.emailSignature.title")}
          actions={[
            { content: "Learn more", onAction: () => learnMore(cms("section.emailSignature.title"), "TODO: ") },
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.emailSignature.description")}</TextContainer>
            <br />
            <Stack vertical>{renderList()}</Stack>
            <br />
            <Collapsible open={!email.isDefault} id="custom-email-collapsible">
              <TextContainer>
                <Editor
                  id="emailCustomText"
                  textareaName="emailDescription"
                  value={email.content}
                  onEditorChange={(value) => handleTiny(CONTENT, value, EMAIL)}
                  init={{
                    menubar: true,
                    plugins: ["autolink link image lists print preview"],
                    toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                  }}
                />
              </TextContainer>
            </Collapsible>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default Email;

import React, { useContext, useState, useCallback, useMutation } from "react";
import { Card, TextContainer } from "@shopify/polaris";

import constant from "lib/constant/constant";

import baseHelper from "lib/helpers/base";

// import components
import { DropZoneFile } from "lib/components";

// import context
import { PrivateContext } from "lib/context";
import { FormContext } from "../../../context/context";

import { DELETE_PRODUCT_ATTACHMENT } from "../../../../../../../apollo/mutations";

const Attachment = () => {
  const { handleChange, data, learnMore } = useContext(FormContext);
  // const { gql } = constant; setDisabledButton, existingProductAttachments = [], setExistingAttachments
  const { cms } = useContext(PrivateContext);
  const [loadingId, setLoadingId] = useState("");
  const [ existingAttachments, setExistingAttachments] = useState([])
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
  });
  const addFile = value => {
    const productFiles = (data && data.file) || [];
    const uploadedFiles = [...productFiles, ...value];
    handleChange(constant.FILE, uploadedFiles);
  };
  const removeFile = index => {
    const productFiles = data.file || [];
    if (index < 0 || productFiles.length <= 0) {
      return;
    }
    productFiles.splice(index, 1);
    handleChange(constant.FILE, productFiles);
  };
  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    [],
  );
 
  const handleDownload = file => {
    window.open(file.fileURL, "_blank");
}

  return (
      <Card
        title="Product Attachment" //{cms("section.form.section.image.title")}
        id="productAttachment"
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => {
              learnMore("product file", cms("section.form.section.image.title"));
            },
          },
        ]}
        >
        <Card.Section>
        <TextContainer>{"You can add files for the product."}</TextContainer>
        <br />  
        <DropZoneFile id="productAttachmentId" 
        size={10}
        onAdd={addFile}
        onRemove={removeFile}
        downloadExistingFile={handleDownload}
        allowMultiple >   
        </DropZoneFile>
        </Card.Section>
      </Card>
   
  );
};

export default Attachment;

import { gql } from "apollo-boost";

const GET_PRODUCT_SETTING = gql`
  query {
    getProductSetting {
      data {
        isHideTagAndType
      }
      error
      status
    }
  }
`;

export default GET_PRODUCT_SETTING;

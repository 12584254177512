function paymentListData(t) {
  return {
    mainLink: {
      orderNumber: "/orders/view/",
    },
    links: {
      vendor: {
        link: "/suppliers/view/",
        id: "vendorId",
      },
    },
    sortOptions: [
      { label: t("label.automaticPayouts.sortByDateAsc"), value: "createdAt_asc" },
      { label: t("label.automaticPayouts.sortByDateDesc"), value: "createdAt_desc" },
    ],
    resourceName: {
      singular: t("label.automaticPayouts.automaticPayout"),
      plural: t("label.automaticPayouts.automaticPayouts"),
    },
    tabsData: [
      {
        id: "all-payouts",
        content: t("label.All"),
        accessibilityLabel: "All payouts",
        panelID: "all-payouts-content",
        value: "all",
      },
      {
        id: "failed-payouts",
        content: t("label.Failed"),
        accessibilityLabel: "Failed payouts",
        panelID: "failed-payouts-content",
        value: "error",
      },
      {
        id: "pending-payouts",
        content: t("label.Pending"),
        accessibilityLabel: "Pending payouts",
        panelID: "pending-payouts-content",
        value: "pending",
      },
      {
        id: "paid-payouts",
        content: t("label.Success"),
        accessibilityLabel: "Success payouts",
        panelID: "paid-payouts-content",
        value: "paid",
      },
    ],
    PerPage: [
      {
        label: "10",
        value: "10",
      },
      {
        label: "20",
        value: "20",
      },
      {
        label: "50",
        value: "50",
      },
      {
        label: "100",
        value: "100",
      },
    ],
  };
}

export default paymentListData;

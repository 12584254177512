// import packages
import React, { useContext } from "react";
import { Button, TextField, Select, Stack } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const AdvanceItem = (props) => {
  const { count, updateVal, advancedValue = [], allSuppliers, removeItem } = props;
  const { PRE_FULFILLMENT, POST_FULFILLMENT, POST_RETURN, OPTION, TYPE, VALUE, VENDOR } = constant;
  const { cms = {} } = useContext(PrivateContext);
  const typeOptions = [
    {
      label: cms("common.label.vendor"),
      value: VENDOR,
    },
  ];

  const returnOptions = [
    {
      label: cms("section.returns.label.returnsAllowed"),
      value: true,
    },
    {
      label: cms("section.returns.label.noReturnsAllowed"),
      value: false,
    },
  ];

  const paymentOptions = [
    {
      label: cms("section.payment.label.preFulfilment"),
      value: PRE_FULFILLMENT,
    },
    {
      label: cms("section.payment.label.postFulfilment"),
      value: POST_FULFILLMENT,
    },
    {
      label: cms("section.payment.label.postReturnsExpiry"),
      value: POST_RETURN,
    },
  ];

  const advanceValueOptions = props.isReturn ? returnOptions : paymentOptions;

  const availableOptions = [...typeOptions];
  if (!allSuppliers.length) {
    availableOptions.pop();
  }

  const checkBoolean = (value) => {
    if (props.isReturn) {
      if (value === "true") {
        return true;
      }

      if (value === "false") {
        return false;
      }
    }
    return value;
  };

  const arr = Array.from(Array(count).keys());
  return arr.map((item) => {
    const returnOrType = props.isReturn ? "isReturn" : "type";

    const advanceItem = advancedValue && advancedValue[item];
    if (!advanceItem) {
      return null;
    }

    if (!advanceItem.option) {
      updateVal("option", "vendor", item);
    }

    const advanceItemType = "vendor";
    const advanceItemValue = advanceItem[returnOrType];
    const advanceItemVendor = advanceItem.vendorId;
    const getTypeOptions = [...allSuppliers];

    const typeSelect = `${TYPE}${item}`;
    const optionSelect = `${OPTION}${item}`;
    const valueSelect = `${VALUE}${item}`;

    const returnOrDays = props.isReturn ? "returnPeriod" : "days";

    return (
      <div key={item}>
        <Stack wrap={false} key={item} spacing="extraTight" distribution="fillEvenly" alignment="center">
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={availableOptions}
                id={typeSelect}
                placeholder={cms("common.placeholder.type")}
                // onChange={(val) => {
                //   updateVal("option", val, item);
                // }}
                disabled
                value={(advanceItem && advanceItemType) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={getTypeOptions}
                placeholder={cms("common.label.options")}
                id={optionSelect}
                onChange={(val) => {
                  updateVal("vendorId", val, item);
                }}
                value={(advanceItem && advanceItemVendor) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={advanceValueOptions}
                id={valueSelect}
                placeholder={cms("common.label.value")}
                onChange={(val) => {
                  updateVal(returnOrType, checkBoolean(val), item);
                }}
                value={advanceItemValue || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <TextField
                required
                min={0}
                type="number"
                disabled={
                  props.isReturn ? !advanceItemValue : advanceItemValue === "preFulfillment" || !advanceItemValue
                }
                suffix="days"
                onChange={(val) => {
                  updateVal(returnOrDays, Number(val), item);
                }}
                value={
                  (advanceItem &&
                    (advanceItem[returnOrDays] === 0 || advanceItem[returnOrDays]) &&
                    advanceItem[returnOrDays].toString()) ||
                  ""
                }
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <Button id="deleteButton" plain onClick={() => removeItem(item)}>
              <i className="far fa-trash fa-lg redColor" />
            </Button>
          </Stack.Item>
        </Stack>
        <Stack.Item>
          <br />
        </Stack.Item>
      </div>
    );
  });
};

export default AdvanceItem;

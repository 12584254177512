import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions } from "@shopify/polaris";

// import hoc
import { withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

//  import components
import { Banner, SkeletonAnnotated } from "lib/components";

// contexts
import { PrivateContext } from "lib/context";

// gql
import { useMutation, useQuery } from "react-apollo";
import { ADD_VARIANT, UPLOAD_IMAGE } from "app/products/apollo/mutations";
import { GET_VENDOR_VARIANT } from "app/products/apollo/queries";

// helpers
import { baseHelper, errorHelper } from "lib/helpers";

// sub feature items
import { Image, Title, Pricing, Inventory, Shipping } from "./subFeatureItems";

const OperatorVariantAdd = () => {
  const { match, history } = useContext(PrivateContext);
  const { params } = match;
  const { productId = "" } = params;

  const { data: variantData, loading, error } = useQuery(GET_VENDOR_VARIANT, {
    variables: { input: { id: productId } },
  });
  const [addVariant, { loading: addVariantLoading }] = useMutation(ADD_VARIANT);
  const [uploadImage, { loading: imageLoading }] = useMutation(UPLOAD_IMAGE);

  // states
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [title, setTitle] = useState({});
  const [image, setImage] = useState("");
  const [pricing, setPricing] = useState({
    price: "",
    comparePrice: "",
  });
  const [inventory, setInventory] = useState({
    sku: "",
    barCode: "",
    inventoryPolicy: "",
    quantity: "",
  });
  const [shipping, setShipping] = useState({
    isPhysicalProduct: true,
    weight: "",
    weightUnit: "lb",
  });
  const [variantOption, setVariantOption] = useState([]);
  const [titleError, setTitleError] = useState({});
  const [productImages, setProductImages] = useState([]);

  useEffect(() => {
    if (error && Object.keys(error).length) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: "critical",
        title: "Unable to fetch variant options",
      }));
    }
  }, [error]);

  if (variantData && Object.keys(variantOption).length === 0) {
    const responseData = baseHelper.getResponseData(variantData, "getProduct");
    const responseError = baseHelper.getResponseError(variantData, "getProduct");
    const { variants = [], sku: productSKU, images = [] } = responseData || {};
    const variantPosition = variants.length + 1;
    if (responseError) {
      setBanner({
        ...banner,
        isOpen: true,
        status: "critical",
        title: responseError,
      });
    }
    let options = {
      option1: "Size",
      option2: "Color",
      option3: "Material",
    };
    if (variants.length) {
      const {
        option1Val = null,
        option2Val = null,
        option3Val = null,
        option1 = null,
        option2 = null,
        option3 = null,
      } = variants[0];
      const availableOptions = {
        option1: option1Val && option1,
        option2: option2Val && option2,
        option3: option3Val && option3,
      };
      options = availableOptions;
    }
    setVariantOption(options);
    setProductImages(images);
    if (productSKU) setInventory({ ...inventory, sku: `${productSKU}-${variantPosition}` });
  }

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (loading) {
    return <SkeletonAnnotated />;
  }

  const saveVariant = async (variantValue) => {
    try {
      const result = await addVariant({
        variables: {
          input: variantValue,
        },
      });

      const responseData = baseHelper.getResponseData(result.data, "addVariant");
      const responseError = baseHelper.getResponseError(result.data, "addVariant");

      if (responseError) {
        setBanner({
          isOpen: true,
          status: "critical",
          title: responseError,
        });
        return;
      }
      if (responseData) {
        setBanner({
          isOpen: true,
          status: "success",
          title: "Variant was created successfully. What’s next?",
        });
      }
    } catch (exception) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: "critical",
        title: errorHelper.parse(exception),
      }));
    }
  };

  const prepareData = () => {
    const { option1 = null, option2 = null, option3 = null } = title;
    const formValues = {
      productId,
      seller: "",
      image: {},
      option1Val: option1 || "",
      option2Val: option2 || "",
      option3Val: option3 || "",
      price: parseInt(pricing.price, 10),
      comparePrice: parseInt(pricing.comparePrice, 10),
      sku: inventory.sku,
      barcode: inventory.barCode,
      inventoryManagement: inventory.inventoryPolicy,
      inventoryQuantity: parseInt(inventory.quantity, 10),
      isShipping: shipping.isPhysicalProduct,
      weight: parseInt(shipping.weight, 10),
      weightUnit: shipping.weight && shipping.weight !== "" && shipping.weightUnit,
    };

    const requiredError = {};
    let hasError = false;

    if (variantOption.option1) {
      requiredError.option1 = !(option1 && option1.trim() !== "");
      hasError = hasError || requiredError.option1;
    }
    if (variantOption.option2) {
      requiredError.option2 = !(option2 && option2.trim() !== "");
      hasError = hasError || requiredError.option2;
    }
    if (variantOption.option3) {
      requiredError.option3 = !(option3 && option3.trim() !== "");
      hasError = hasError || requiredError.option3;
    }
    setTitleError(requiredError);

    return !hasError && formValues;
  };

  const onSubmit = async () => {
    const variantValue = prepareData();

    if (!variantValue) {
      setBanner((prev) => ({
        ...prev,
        isOpen: true,
        status: "critical",
        title: "Please fill the title",
      }));
      return;
    }

    if (image && image.length && image[0].url) {
      const imageUrl = image[0].url;
      variantValue.image = { url: imageUrl };
    }
    const isLocalImageUrl = image && image.upload === constant.LOCAL;
    if (!isLocalImageUrl) {
      saveVariant(variantValue);
      return;
    }
    if (image && image.length) {
      try {
        const response = await uploadImage({ variables: { input: { image: image[0],productId:match.params.productId } } });
        const responseData = baseHelper.getResponseData(response.data, "uploadImage");
        const responseError = baseHelper.getResponseError(response.data, "uploadImage");
        if (responseError) {
          setBanner((prev) => ({
            ...prev,
            isOpen: true,
            status: "critical",
            title: responseError,
          }));
          return;
        }
        const { imageUrl = null } = responseData || {};
        if (!imageUrl) {
          setBanner((prev) => ({
            ...prev,
            isOpen: true,
            status: "critical",
            title: "Image url not found",
          }));
          return;
        }
        variantValue.image = { url: imageUrl };
      } catch (exception) {
        setBanner((prev) => ({
          ...prev,
          isOpen: true,
          status: "critical",
          title: errorHelper.parse(exception),
        }));
        return;
      }
    }
    
    saveVariant(variantValue);
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title="Add Variant">
        <br />
        <Title data={title} onChange={setTitle} variantOption={variantOption} error={titleError} />
        <Image productImages={productImages} image={image} setImage={setImage} />
        <Pricing data={pricing} onChange={setPricing} />
        <Inventory data={inventory} onChange={setInventory} />
        <Shipping data={shipping} onChange={setShipping} />
      </Layout.AnnotatedSection>
      <Layout.Section>
        <PageActions
          primaryAction={{
            id: "submit",
            content: "Submit",
            onAction: () => onSubmit(),
            loading: addVariantLoading || imageLoading,
          }}
          secondaryActions={[
            {
              id: "cancel",
              content: "Cancel",
              onAction: () => history.push(`/products/edit/${productId}`),
            },
          ]}
        />
      </Layout.Section>
    </>
  );
};

export default withFeature(OperatorVariantAdd, { feature: constant.ADD_VARIANT });

import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Card } from "@shopify/polaris";
import { useQuery, useMutation, useLazyQuery } from "react-apollo";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import lib
import { ResourceList, Banner } from "lib/components";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import gql
import BULK_INVITE_VIEW from "app/invitations/apollo/subscription";
import { GET_BULK_INVITE_LIST, GET_BULK_EMAIL_TEMPLATE } from "app/invitations/apollo/query";
import { UPDATE_INVITATION_EMAIL_STATUS, BULK_INVITATION_BY_DATA } from "app/invitations/apollo/mutation";

// import subFeatures
import InvitationListView from "app/invitations/generic/subFeature/invitationListView";

const AdminInvitation = () => {
  const { history, currentUser } = useContext(PrivateContext);
  const { isReadOnly } = currentUser;
  const { t } = useTranslation();
  const prevFilter = baseHelper.queryParamsFromLocation(history);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevFilter.perPage, 10) || 10);
  const [currentPage, setCurrentPage] = useState(parseInt(prevFilter.page, 10) || 1);
  const [bulkInvitation, setBulkInvitation] = useState(false);
  const [selected, setSelected] = useState(parseInt(prevFilter.tab, 10) || 0);
  const [queryValue, setQueryValue] = useState(prevFilter.search || null);
  const selectedSort = prevFilter.sort_name && `${prevFilter.sort_name}_${prevFilter.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || "createdAt_desc");
  const [totalVendor, setTotalVendor] = useState(0);
  const [resendMail, setResendMail] = useState(null);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [activePopover, setActivePopover] = useState({});
  const [dataToFetch, setDataToFetch] = useState({
    search: prevFilter.search,
    filter: prevFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevFilter.sort_name || "createdAt",
    sort_order: prevFilter.sort_order || "desc",
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });
  const promotedBulkActions = [];
  const bulkActions = [];
  const tabs = [
    {
      id: "all-vendors",
      content: t("label.invitationList.all"),
      accessibilityLabel: "All vendors",
      panelID: "all-vendors-content",
      value: "all",
    },
    {
      id: "invited-vendors",
      content: "Invited",
      accessibilityLabel: "invited vendors",
      panelID: "invited-vendors-content",
      value: "invited",
    },
    {
      id: "joined-vendors",
      content: "Joined",
      accessibilityLabel: "joined vendors",
      panelID: "joined-vendors-content",
      value: "joined",
    },
    {
      id: "revoked-vendors",
      content: "Revoked",
      accessibilityLabel: "revoked vendors",
      panelID: "revoked-vendors-content",
      value: "revoked",
    },
    {
      id: "resent-vendors",
      content: "Resent",
      accessibilityLabel: "resent vendors",
      panelID: "resent-vendors-content",
      value: "resent",
    },
    {
      id: "pending-vendors",
      content: "Pending",
      accessibilityLabel: "pending vendors",
      panelID: "pending-vendors-content",
      value: "pending",
    },
  ];

  const sortOptions = [
    { label: t("label.invitationList.selectSort"), value: "" },
    { label: t("label.invitationList.sortByDateAsc"), value: constant.CREATED_ASC },
    { label: t("label.invitationList.sortByDateDesc"), value: constant.CREATED_DESC },
  ];

  const inputData = {
    search: dataToFetch.search,
    filter: dataToFetch.filter,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
  };

  const {
    error: errorOperatorInvitation,
    loading: loadingOperatorInvitation,
    data: dataOperatorInvitation,
    subscribeToMore,
    refetch,
  } = useQuery(GET_BULK_INVITE_LIST, {
    variables: {
      input: inputData,
    },
  });

  // Mutation Calls
  const [
    revokeInvitation,
    { error: errorRevokeInvitation, loading: loadingRevokeInvitation, data: dataRevokeInvitation },
  ] = useMutation(UPDATE_INVITATION_EMAIL_STATUS, {
    refetchQueries: [
      {
        query: GET_BULK_INVITE_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  const [resendInvitation, { loading: loadingResendInvitation }] = useMutation(BULK_INVITATION_BY_DATA, {
    refetchQueries: [
      {
        query: GET_BULK_INVITE_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  const [
    emailTemplate,
    { error: errorEmailTemplate, loading: loadingEmailTemplate, data: dataEmailTemplate },
  ] = useLazyQuery(GET_BULK_EMAIL_TEMPLATE);

  const handleCancel = async (vendorMail, buttonIndex) => {
    setSelectedButtonIndex(buttonIndex);
    const formData = { email: vendorMail };
    await revokeInvitation({ variables: { input: formData } });
  };

  const handleResend = (mail, buttonIndex) => {
    setSelectedButtonIndex(buttonIndex);
    setResendMail(mail);
    emailTemplate();
  };

  // Subscription for vendorList Page.
  useEffect(() => {
    subscribeToMore({
      document: BULK_INVITE_VIEW,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  useEffect(() => {
    if (errorEmailTemplate) {
      setBanner({ title: t("error.common.somethingWentWrong"), status: "critical", isOpen: true });
    }
  }, [errorEmailTemplate, t]);

  useEffect(() => {
    if (dataEmailTemplate) {
      const emailTemplateResponseData = baseHelper.getResponseData(
        dataEmailTemplate,
        constant.gql.GET_BULK_EMAIL_TEMPLATE
      );
      const emailTemplateResponseError = baseHelper.getResponseError(
        dataEmailTemplate,
        constant.gql.GET_BULK_EMAIL_TEMPLATE
      );
      if (emailTemplateResponseData) {
        const resendInviteData = {
          emails: [
            {
              email: resendMail,
            },
          ],
          message: {
            message: emailTemplateResponseData.message,
            subject: emailTemplateResponseData.subject,
          },
          isSingleInvite: true,
          isResent: true,
          isReadOnly,
        };
        resendInvitation({ variables: { input: { ...resendInviteData } } })
          .then((resendInviteRes) => {
            const resendInviteResponseData = baseHelper.getResponseData(
              resendInviteRes.data,
              constant.gql.BULK_INVITATION_BY_DATA
            );
            const resendInviteResponseError = baseHelper.getResponseError(
              resendInviteRes.data,
              constant.gql.BULK_INVITATION_BY_DATA
            );
            if (resendInviteResponseData) {
              setBanner({ title: t("message.providerInvitationList.requestSuccess"), status: "success", isOpen: true });
            }
            if (resendInviteResponseError) {
              setBanner({ title: resendInviteResponseError, status: "critical", isOpen: true });
            }
          })
          .catch((err) => {
            if (err) {
              setBanner({ title: t("error.common.somethingWentWrong"), status: "critical", isOpen: true });
            }
          });
      }
      if (emailTemplateResponseError) {
        setBanner({ title: emailTemplateResponseError, status: "critical", isOpen: true });
      }
    }
  }, [isReadOnly, dataEmailTemplate, emailTemplate.message, emailTemplate.subject, resendInvitation, resendMail, t]);

  useEffect(() => {
    if (dataRevokeInvitation) {
      const revokeInvitationResponseData = baseHelper.getResponseData(
        dataRevokeInvitation,
        constant.gql.UPDATE_INVITATION_EMAIL_STATUS
      );
      const revokeInvitationResponseError = baseHelper.getResponseError(
        dataRevokeInvitation,
        constant.gql.UPDATE_INVITATION_EMAIL_STATUS
      );
      if (revokeInvitationResponseData) {
        setBanner({ isOpen: true, status: "success", title: t("message.providerInvitationList.requestSuccess") });
      }
      if (revokeInvitationResponseError) {
        setBanner({ isOpen: true, status: "critical", title: revokeInvitationResponseError });
      }
    }
  }, [dataRevokeInvitation, setBanner, t]);

  useEffect(() => {
    if (errorRevokeInvitation) {
      if (errorRevokeInvitation) {
        setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
      }
    }
  }, [errorRevokeInvitation, setBanner, t]);

  function renderItem(item, _, index) {
    return (
      <InvitationListView
        item={item}
        keyIndex={index}
        setBanner={setBanner}
        cancelButtonLoading={loadingRevokeInvitation}
        resendButtonLoading={loadingResendInvitation || loadingEmailTemplate}
        handleCancel={handleCancel}
        handleResend={handleResend}
        selectedButtonIndex={selectedButtonIndex}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
      />
    );
  }

  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleQueryValueChange = (value) => {
    baseHelper.setUrl(history, { search: value });
    setQueryValue(value);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleTabChange = (selectedTabIndex) => {
    const { value: tabName } = tabs[selectedTabIndex];
    setSelected(selectedTabIndex);
    baseHelper.setUrl(history, { tab: selectedTabIndex, filter: tabName });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleSearchTextRemove = () => {
    setQueryValue(null);
    baseHelper.setUrl(history, { search: "" });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const handleClearAll = () => {
    handleSearchTextRemove();
  };

  const handleQueryValueRemove = () => {
    baseHelper.setUrl(history, { search: "" });
    setQueryValue("");
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedItem);
      const filteredObj = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filteredObj });
    }
  };

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: parseInt(value, 10) });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...result });
  };

  // GQL success response
  useEffect(() => {
    const responseDataInvitation = baseHelper.getResponseData(dataOperatorInvitation, "getBulkInviteList");
    const errorDataInvitation = baseHelper.getResponseError(dataOperatorInvitation, "getBulkInviteList");
    if (responseDataInvitation) {
      const { count: totalCount = 0 } = responseDataInvitation;
      setBulkInvitation(responseDataInvitation.bulkInviteList);
      setTotalVendor(totalCount);
    }
    if (errorDataInvitation) {
      setBanner({ status: "critical", title: errorDataInvitation, isOpen: true });
    }
  }, [dataOperatorInvitation]);

  // GQL Error
  useEffect(() => {
    if (errorOperatorInvitation) {
      setBanner({ status: "critical", title: t("error.common.somethingWentWrong"), isOpen: true });
    }
  }, [errorOperatorInvitation, t]);

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            status={banner.status}
            title={banner.title}
            isOpen={banner.isOpen}
            onDismiss={() => {
              setBanner({ status: "", title: "", isOpen: false });
            }}
          />
          <br />
        </>
      )}
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <ResourceList
            resourceName={{
              singular: t("label.invitationList.invitation"),
              plural: t("label.invitationList.invitations"),
            }}
            items={bulkInvitation || []}
            renderItem={renderItem}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            onQueryChange={handleQueryValueChange}
            onQueryClear={handleQueryValueRemove}
            handleClearAll={handleClearAll}
            queryValue={queryValue}
            sortValue={sortValue}
            sortOptions={sortOptions}
            onSortChange={sortFilter}
            loading={loadingOperatorInvitation}
            page={currentPage}
            count={Number(totalVendor)}
            perPage={selectedLimit}
            setPage={handlePerPage}
            setPerPage={handleSelectLimit}
          />
        </Tabs>
      </Card>
    </>
  );
};

export default withErrorBoundary(AdminInvitation);

const userKey = {
  accountAdminLevel: "account-admin",
  admin: "admin",
  operator: "operator",
  provider: "vendor",
  seller: "seller",
  systemAdminLevel: "system-admin",
  systemAdminRole: "systemAdmin",
  vendor: "vendor",
};

const featureError = {
  hideFeature: "hideFeature",
  needPlanUpgrade: "needPlanUpgrade",
  roleNotAllowed: "roleNotAllowed",
};

const featureVersion = {
  alpha: "alpha",
  beta: "beta",
  live: "live",
};

const symbol = {
  DOLLAR: "$",
  PERCENTAGE: "%",
};

const value = {
  MAX_AMOUNT: 99999,
  MAX_CHARACTER: 255,
  MAX_CSV_SIZE: 5 * 1024 * 1024,
  MAX_FLAT: 9999999999999,
  MAX_PERCENTAGE: 100,
  MIN_PRICE: 0,
  MIN_SEARCH_LENGTH: 3,
  ONE: 1,
  STEP: 0.01,
  ZERO: 0,
  FLOAT_ZERO: 0.0,
  MAX_MEASUREMENT: 999,
};

const color = {
  RED: "#ff0000",
};

const returnReason = {
  NOT_NEEDED: "notNeeded",
  NOT_AS_ADVERTISED: "notAsAdvertised",
  NOT_AS_SOLD: "notAsSold",
  FAULTY: "faulty",
  OTHER: "other",
};

const manageVendorDetails = {
  isField1Enabled: "isField1Enabled",
  isField2Enabled: "isField2Enabled",
  isField3Enabled: "isField3Enabled",
  isField4Enabled: "isField4Enabled",
  isField5Enabled: "isField5Enabled",
  label1: "label1",
  label2: "label2",
  label3: "label3",
  label4: "label4",
  label5: "label5",
};

const imageTypes = {
  THUMBNAIL: "thumbnail",
  PROFILE: "profile",
  HEADER_LOGO: "headerLogo",
};

const productVersion = {
  approveAll: "Accept and Publish",
  approveSelected: "Accept and Don't Publish",
  markAs: "Reject Changes",
  vendors: "Vendor's Value",
  sellers: "Seller's Value",
  images: "Images",
  variants: "Variants",
  title: "Title",
  vendor: "Vendor",
  barcode: "Bar Code",
  category: "Category",
  collections: "Collections",
  comparePrice: "Compare Price",
  description: "Description",
  status: "Status",
  handle: "Handle",
  images: "Images",
  inventoryManagement: "Inventory Management",
  isShipping: "Is Shipping",
  isTaxable: "Is Taxable",
  markAs: "Don't Accept Changes",
  no: "No",
  price: "Price",
  quantity: "Quantity",
  reviewChanges: "Review Product Changes",
  sellers: "Seller's Value",
  sku: "Sku",
  title: "Title",
  variants: "Variants",
  vendor: "Vendor",
  vendors: "Vendor's Value",
  weight: "Weight",
  weightUnit: "WeightUnit",
  yes: "Yes",
};

const displayStatus = {
  ACCEPTED: "Accepted",
  ALPHA: "Alpha",
  BETA: "Beta",
  BOUNCED: "Bounced",
  CANCELLED: "Cancelled",
  CLICKED: "Clicked",
  CLOSED: "Closed",
  DEFERRED: "Deferred",
  DELETED: "Deleted",
  DELETEDBYSELLER: "Deleted By Seller",
  DELIVERED: "Delivered",
  DISPUTE: "Dispute",
  DROPPED: "Dropped",
  FULFILLED: "Fulfilled",
  INVITED: "Invited",
  JOINED: "Joined",
  LIVE: "Live",
  NEEDSREVIEW: "Review",
  NEW: "New",
  OPENED: "Opened",
  PAID: "Paid",
  PARTIAL: "Partial",
  PARTIALLYFULFILLED: "Partially Fulfilled",
  PENDING: "Pending",
  PROCESSED: "Processed",
  PUBLISHED: "Published",
  QUOTALIMIT: "Qouta Exceeded",
  REJECTED: "Rejected",
  RESENT: "Resent",
  RETURN: "Return",
  REVIEWED: "Reviewed",
  REVOKED: "Cancelled",
  SHIPPED: "Shipped",
  SPAM: "Spam Report",
  UNFULFILLED: "Unfulfilled",
  UNPAID: "Unpaid",
};

const onboardingSteps = {
  WELCOME: "welcome",
  COMMISSION: "commission",
  TERMS: "terms",
  UPDATE_PROFILE: "updateProfile",
  ABOUT: "about",
  ADD_PROVIDER: "addProvider",
  ADD_PRODUCT: "product",
  FINISH: "finish",
  TERM: "term",
  VENDOR: "vendor",
};

const gql = {
  ADD_PRODUCT: "addProduct",
  APPROVE_ALL_CHANGES: "approveAllProductChanges",
  APPROVE_SELLER_CHANGES: "approveSellerChanges",
  ATTACH_UPLOAD: "attachUpload",
  BULK_ACCOUNT: "bulkAccount",
  BULK_EDIT_PRODUCT: "bulkEditProduct",
  BULK_INVITATION_BY_DATA: "bulkInvitationByData",
  BULK_INVITATION_DATA: "bulkInvitationByData",
  BULK_PRODUCT_CSV: "bulkProductUpload",
  BULK_PRODUCT: "bulkProduct",
  BULK_SELLER_ACTION: "bulkSellerAction",
  BULK_SUPPLIER_ACTION: "bulkSupplierAction",
  BULK_SUPPLIER_CSV: "bulkSupplierUsingCsv",
  BULK_UPDATE_VARIANT: "bulkUpdateVariant",
  CHANGE_PASSWORD: "changePassword",
  CHECK_ORDER_LINES: "checkOrderLines",
  CHECK_RESET_TOKEN: "checkResetToken",
  CONNECT_PAYPAL_SELLER: "connectPaypalSeller",
  CONNECT_PAYPAL: "connectPaypal",
  CONNECT_PAYPAL_VENDOR: "connectPaypalVendor",
  CONNECT_STRIPE: "connectStripe",
  CONNECT_STRIPE_VENDOR: "connectStripeVendor",
  CREATE_ASSOCIATION: "createAssociation",
  CREATE_BAND: "createShippingBand",
  CREATE_SHIPPING_OFFER: "createShippingOffer",
  CREATE_SHIPPING_RULE: "createShippingRule",
  CREATE_VENDOR: "createVendor",
  CUSTOMER: "customer",
  DELETE_BAND: "deleteShippingBand",
  DELETE_PRODUCT_IMAGE: "deleteProductImage", 
  DELETE_PRODUCT_ATTACHMENT: "deleteProductAttachment",
  DELETE_SHIPPING_OFFER: "deleteShippingOffer",
  DELETE_SHIPPING_RULE: "deleteShippingRule",
  DELETE_VARIANT: "deleteVariantById",
  DELETE_VENDOR_ASSOCIATION: "deleteVendorAssociation",
  DISABLE_ORDER_CONNECT: "disableOrderConnect",
  DISCONNECT_PAYPAL: "disconnectPaypal",
  DISCONNECT_SHOP: "disconnectShop",
  DISCONNECT_STRIPE: "disconnectStripe",
  DOWNLOAD_SHIPPING_LABEL: "downloadShippingLabel",
  EDIT_PRODUCT: "editProduct",
  EDIT_VARIANT: "editVariant",
  FORGET_PASSWORD: "forgetPassword",
  GENERATE_PASSWORD: "otp",
  GENERATE_SHIPPING_LABEL: "generateShippingLabel",
  GET_ACCOUNT_LIST: "getAccountList",
  GET_ASSOCIATED_SELLER: "getAssociatedSeller",
  GET_ACTIVITY: "getActivity",
  GET_ALL_METRICS: "getAllMetrics",
  GET_ALL_USER_FOR_FEATURE: "getAllUserForFeature",
  GET_ALL_USERS: "getAllUsers",
  GET_BAND: "getShippingBand",
  GET_BRAND_DETAILS_DATA: "getBrandDetails",
  GET_BRANDS: "getBrands",
  GET_BRAND_REPRORTING: "getBrandForReporting",
  GET_BULK_EMAIL_TEMPLATE: "getBulkEmailTemplate",
  GET_BULK_INVITED_USER: "getBulkInvitedUser",
  GET_BULK_LIST: "getBulkLog",
  GET_COMMISSION: "getCommission",
  GET_CONTENT: "getContent",
  GET_CONSIGNMENT_VENDOR_LIST: "getConsignmentVendorList",
  GET_COUNT: "getCount",
  GET_CURRENT_PLAN: "getCurrentPlan",
  GET_CURRENT_USER: "getCurrentUser",
  GET_CUSTOM_DOMAIN_LIST: "getCustomDomainList",
  GET_CUSTOMER: "getCustomer",
  GET_DISCOUNT: "getDiscount",
  GET_EMAIL_TEMPLATE_BY_ID: "getEmailTemplateById",
  GET_EMAIL_TEMPLATE: "getEmailTemplate",
  GET_ERROR_LIST: "getErrorLogList",
  GET_FEATURE_BY_PARENT_ID: "getFeatureByParentId",
  GET_FEATURE: "getFeature",
  GET_FILTER_OPTIONS: "getFilterOptions",
  GET_FULFILLMENT: "getFulfillment",
  GET_SELLER_FULFILLMENT: "getSellerFulfillment",
  GET_INTRODUCTORY_SELLER: "getIntroductorySeller",
  GET_INVOICE_DATA: "getInvoiceData",
  GET_MAINTENANCE: "getMaintenance",
  GET_MARKUP: "getMarkup",
  GET_MODULE: "getModule",
  GET_NOTIFICATION: "getNotificationSetting",
  GET_ONBOARD_ACTIVITY: "getOnboardActivity",
  GET_ORDER_DETAILS: "getOrderDetails",
  GET_ORDER_LINE_VIEW: "getOrderLineView",
  GET_ORDER_LINES_LIST: "getOrderLineList",
  GET_ORDER_LINES: "getOrderLines",
  GET_ORDER_METRICS: "getOrderMetrics",
  GET_ORDER_VIEW: "getOrderView",
  GET_PAYMENT_METRICS: "getPaymentMetrics",
  GET_PAYMENT_TRANS_LIST: "getPaymentTransList",
  GET_PLANS: "getPlans",
  GET_PRODUCT_AND_SELLER_PRODUCT: "getProductAndSellerProduct",
  GET_PRODUCT_BY_IDS: "getProductbyIds",
  GET_PRODUCT_LIST_SELLER: "getProductList",
  GET_PRODUCT_LIST: "getProductList",
  GET_PRODUCT_METRICS: "getProductMetrics",
  GET_PRODUCT_SELLER_PRODUCT: "getProductAndSellerProduct",
  GET_PRODUCT_SETTING: "getProductSetting",
  GET_PRODUCT_TAGS: "getProductTags",
  GET_PRODUCT_TYPE: "getProductType",
  GET_PRODUCT: "getProduct",
  GET_PRODUCT_VERSION: "getProductVersion",
  GET_PRODUCTS: "getProducts",
  GET_REGION: "getRegion",
  GET_SELLER_ASSOCIATION: "getSellerAssociation",
  GET_SELLER_BRANDING: "getSellerBranding",
  GET_SELLER_DATA: "getSellerData",
  GET_SELLER_FEATURE: "getSellerFeature",
  GET_SELLER_LIST: "getSellerList",
  GET_SELLER_PAYMENT: "getSellerPayment",
  GET_SELLER_SETTING: "getSellerShippingSetting",
  GET_SELLER_SHIPPING: "getSellerShipping",
  GET_SESSION_LIST: "getSessionList",
  GET_SHIPPING_BAND: "getShippingBand",
  GET_SHIPPING_OFFERS: "getShippingOffers",
  GET_SHIPPING_RULE: "getShippingRules",
  GET_SHIPPING: "getShipping",
  GET_SHIPPING_LABEL_STATUS: "getShippingLabelStatus",
  GET_TEMP_PRODUCT_LIST: "getTempProductList",
  GET_TERM: "getTermSetting",
  GET_USER: "getUser",
  GET_VENDOR_AUTH_CODE: "getVendorAuthCode",
  GET_VENDOR_BRAND_LIST: "getVendorBrandList",
  GET_VENDOR_LIST: "getVendorList",
  GET_VENDOR_METRICS: "getVendorMetrics",
  GET_VENDOR_PRODUCT: "getVendorAndProductTypes",
  GET_VENDOR_PROFILE: "getVendorProfile",
  GET_VENDOR_PROFILE_DATA: "getVendorProfileData",
  GET_VENDOR_FIELD: "getVendorField",
  INVITE_COUNT: "inviteCount",
  INVOICE: "invoice",
  IS_STORE_CONNECTED: "isStoreConnected",
  IS_WOOCOMMERCE_STORE_CONNECTED: "isWooCommerceConnected",
  LINE_RETURN: "lineReturn",
  LOGOUT: "logout",
  MANAGE_DISCOUNT: "manageDiscount",
  ORDER_COUNT: "orderCount",
  PAY_VENDOR: "payVendor",
  PAYPAL_CONNECTED: "paypalConnected",
  PRODUCT_COUNT: "productCount",
  PROXY_LOGIN: "proxyLogin",
  REGISTER_BY_INVITE: "registerByInvite",
  REGISTER: "register",
  RESEND_EMAIL: "resendEmail",
  RESEND_OTP_EMAIL: "reSendOtpEmail",
  RESET_PASSWORD: "resetPassword",
  SAVE_SHIPPING: "saveShipping",
  SAVE_SHOPIFY_VENDOR: "saveShopifyVendor",
  SEND_BULK_INVITE_BY_CSV: "sendBulkInviteByCSV",
  SEND_HELP_MESSAGE: "sendHelpMessage",
  SELLER_COUNT: "sellerCount",
  SET_HUB_CONTACT_PROPERTIES: "setHubContactProperties",
  STRIPE_CONNECTED: "stripeConnected",
  SYN_INVENTORY_VENDOR: "syncInventoryVendor",
  SYNC_INVENTORY_VENDOR: "syncInventoryVendor",
  SYNC_WOOCOMMERCE_VENDOR_PRODUCT: "syncWooCommerceVendorProduct",
  SYNC_ORDER: "syncOrder",
  SYNC_SHOPIFY_PRODUCT: "syncShopifyProduct",
  TAX: "tax",
  TOGGLE_AUTO_PAYOUT: "toggleAutoPayout",
  TOGGLE_DOMAIN: "toggleDomain",
  TOTAL: "total",
  UPDATE_ABOUT: "updateAbout",
  UPDATE_BAND: "updateShippingBand",
  UPDATE_BRANDING: "updateBranding",
  UPDATE_BUSINESS_INFO: "updateBusinessInfo",
  UPDATE_CHARGE_DETAILS: "updateChargeDetails",
  UPDATE_COMMISSION: "updateCommission",
  UPDATE_DISCOUNT: "manageDiscount",
  UPDATE_EMAIL_TEMPLATE: "updateEmailTemplate",
  UPDATE_FEATURE: "updateFeature",
  UPDATE_INVITATION_EMAIL_STATUS: "updateInvitationEmailStatus",
  UPDATE_MAINTENANCE: "updateMaintenance",
  UPDATE_MARKUP: "updateMarkup",
  UPDATE_NOTIFICATION: "notificationSetting",
  UPDATE_ORDER_LINE_STATUS: "updateOrderLineStatus",
  UPDATE_ORDER_LINE_TRACKING: "updateOrderLineTracking",
  UPDATE_ORDER_NOTE: "updateOrderNote",
  UPDATE_ORDER_SETTING: "updateOrderSetting",
  UPDATE_PLAN: "updatePlan",
  UPDATE_PRODUCT_ATTACHMENT_SETTING: "updateProductAttachmentSetting",
  UPDATE_PRODUCT_SETTING: "updateProductSetting",
  UPDATE_PRODUCT_VERSION: "updateProductVersion",
  UPDATE_PRODUCT_SYNC: "updateProductSync",
  UPDATE_PROFILE_SYSTEM_ADMIN: "updateSystemAdminProfile",
  UPDATE_PROFILE: "updateVendorProfile",
  UPDATE_SELLER_FEATURE: "updateSellerFeature",
  UPDATE_SELLER_PROFILE: "updateSellerProfile",
  UPDATE_SHIPPING_BAND: "updateShippingBand",
  UPDATE_SHIPPING_OFFER: "updateShippingOffer",
  UPDATE_SHIPPING_RULE: "updateShippingRule",
  UPDATE_VENDOR_FIELD: "updateVendorField",
  UPDATE_VENDOR_TRACKING: "updateVendorTracking",
  UPLOAD_IMAGE: "uploadImage",
  VENDOR_COUNT: "vendorCount",
  VERIFY_EMAIL: "verifyEmail",
  VERIFY_OTP: "verifyOtp",
};

const constant = {
  ACCEPT: "accept",
  ACCEPTED: "accepted",
  ACTIVITY_LIST: "activityList",
  ACTION: "Actions",
  ACCEPT_ORDER: "Accept Order",
  ACCEPTED_ORDER: "acceptOrder",
  ACCESS_KEY_ID: "id",
  ACCOUNT_NUMBER: "accountNumber",
  ACCOUNT_TYPE: "accountType",
  ACKNOWLEDGE: "acknowledge",
  ADDRESS: "address",
  ADD_BAND: "addBands",
  ADD_PAYMENT: "addPayment",
  ADD_PRODUCT: "addProduct",
  ADD_VARIANT: "addVariant",
  ADD_VENDOR: "addVendor",
  ADVANCE_COMMISSION: "advance",
  ADVANCE_CONSIGNMENT: "advanceConsignment",
  ADVANCE_VENDOR_PROFILE: "advanceVendorProfile",
  ADVANCE_SHIPPING: "advanceShipping",
  ALL: "all",
  ALLOWED_CURRENCY: ["$", "€"],
  ALLOWED_DAY: "allowedDay",
  ALLOWED_KEYS: [
    "title",
    "description",
    "images",
    "tags",
    "variants",
    "productType",
    "weight",
    "length",
    "width",
    "height",
  ],
  ALL_CUMULATIVE: "allCumulative",
  AMOUNT_LIMIT: "amountLimit",
  APPROVE: "approve",
  APPROVED: "Approved",
  APPROVE_ALL: "all",
  APPROVE_SELECTED: "selected",
  BUCKET: "bucket",
  ASK: "Ask",
  ASK_SELECT_VENDOR_SHIPPING: "askSelectVendorShipping",
  ASK_SELLER_TO_ADD_BAND: "askSellerToAddBands",
  ASSOCIATION: "association",
  ATTENTION: "attention",
  AUTOCOMPLETE: "autoComplete",
  AVAILABLE: "Available",
  AVAILABLE_OPTION: "Available Options",
  AVAILABILITY_STATUS: "Availability Status",
  BAND: "band",
  BANDS: "bands",
  BANK_NAME: "bankName",
  BARCODE: "barcode",
  BOOTSTRAP: "bootstrap",
  BRANDING: "branding",
  BRAND_FILTER: "brandFilter",
  BRAND_HANDLE: "brandHandle",
  BRAND_NAME: "brandName",
  BRAND_SLUG: "slug",
  BRAND_DESCRIPTION: "description",
  BUCKET_NAME: "bucket",
  BULK_CREATE: "BulkCreate",
  BULK_EDIT: "bulkEdit",
  BULK_INVITE: "bulkInvite",
  BULK_INVITE_FILE_NAME: "bulk-invite.csv",
  BULK_PRODUCT_CSV: "bulk-product.csv",
  BULK_PRODUCT_EDIT: "bulkProductEdit",
  BULK_SUPPLIER_CSV: "bulkSupplierUsingCsv",
  BUTTON: "button",
  BUTTON_TEXT: "buttonText",
  CANADA_POST: "canadaPost",
  CANCEL: "Cancel",
  CANCEL_MANAGE_SHIPPING: "cancelManageShipping",
  CATEGORY: "category",
  CHANGE_PASSWORD: "changePassword",
  CITY: "city",
  CLIENT_ID: "clientId",
  CLIENT_SECRET: "clientSecret",
  CLOSED: "closed",
  CM: "cm",
  COLOR: "color",
  COMING_SOON: "Coming Soon",
  COMMISSION: "commission",
  COMPARE_AT_PRICE: "Compare At Price",
  COMPARE_PRICE: "comparePrice",
  CONDITION: "condition",
  CONFIRM_PASSWORD: "confirmPassword",
  CONNECT: "connect",
  CONSUMER_KEY: "consumerKey",
  CONNECT_PAYMENT: "connectPayment",
  CONNECT_PAYPAL: "connectPaypal",
  CONNECT_SHOP: "connectShop",
  CONNECT_SHOP_PAGE: "/products/add/shop",
  CONNECT_STRIPE: "connectStripe",
  CONTACT: "contact",
  CONTACT_EMAIL: "contactEmail",
  CONTENT: "content",
  CONTRACT_ID: "contractId",
  CONSIGNMENT: "consignment",
  COPY: "copy",
  COUNTRY: "country",
  COUNTRY_CODE: "countryCode",
  CREATE: "create",
  CREATED_ASC: "createdAt_asc",
  CREATED_DESC: "createdAt_desc",
  CREATE_CSV: "createCSV",
  CREATE_FORM: "createForm",
  CREATE_VENDOR: "createVendor",
  CRITERIA: "criteria",
  CRITICAL: "critical",
  CSV: "csv",
  CUSTOMER_NUMBER: "customerNumber",
  DATE_FORMAT: "LL [at] hh:mma [(GMT)]",
  DAYS: "days",
  DEFAULT: "default",
  DEFAULT_BUTTON_COLOR: "#5c6ac4",
  DEFAULT_HEADER_COLOR: "#36454f",
  DEFAULT_TEXT_COLOR: "#ffffff",
  DELETE: "Delete",
  DELETED: "Deleted",
  DELETE_MARKET: "deleteMarket",
  DELETE_SHIPPING_BAND: "Delete Shipping Band - ",
  DELETE_SHOPIFY: "deleteShopify",
  DESCRIPTION: "description",
  DESTINATION: "destination",
  DESTINATION_CODE: "destinationCodes",
  DESTINATION_FILTER: "destinationFilter",
  DISABLED: "disabled",
  DISCONNECT_PAYPAL: "disconnectPaypal",
  DISCONNECT_STRIPE: "disconnectStripe",
  DISCONNECT_WOOCOMMERCE_SHOP: "disconnectWooCommerceShop",
  CONSUMER_SECRET:"consumerSecret",
  DISCOUNT: "discount",
  DISCOUNT_SETTING: "discountSetting",
  DISPUTE: "dispute",
  DOMAIN: "domain",
  DO_NOT_TRACK_INVENTORY: "Don't track inventory",
  DROPSHIP: "dropship",
  DOWN: "down",  
  EAZY_SHIP: "eazyShip",
  EDIT_LABEL: "Edit",
  EDIT_PRODUCT: "editProduct",
  EDIT_PROFILE: "editProfile",
  EDIT_VARIANT: "editVariant",
  EDIT_VENDOR: "editVendor",
  EMAIL: "email",
  EMAIL_TEMPLATE: "emailTemplate",
  ENABLED: "enabled",
  EQUAL: "equal",
  ERROR_LOGS: "errorLogs",
  ESHIP: "eShip",
  EXPORT: "export",
  EXPORT_ORDER: "exportOrder",
  EXPORT_PAYMENT: "exportPayment",
  EXTRA_TIGHT: "extraTight",
  FIRST_NAME: "firstName",
  FIXED: "fixed",
  FILE: "file",
  FLAT: "flat",
  FLVR_CLOUD: "flvrCloud",
  FOOTER: "footer",
  FOLDER_PATH: "path",
  FORMAT_DATE: "DD-MM-YYYY hh:mm:ss",
  FORM_CREATE: "formCreate",
  FREE: "free",
  FULFILLED: "fulfilled",
  FULFILLEDLINEITEMS: "fulFilledLineItems",
  FULFILLMENTS: "fulfillments",
  FULLFILLMENTSTATUS: "fullFillmentStatus",
  FULL_ACCESS: "fullAccess",
  GENERIC_LINK: "genericLink",
  GET_BAND: "getShippingBand",
  GET_CONTENT: "getContent",
  GET_CURRENT_PLAN: "getCurrentPlan",
  GET_CURRENT_USER: "getCurrentUser",
  GET_FEATURE: "getFeature",
  GET_INTRODUCTORY_SELLER: "getIntroductorySeller",
  GET_PLANS: "getPlans",
  GET_SHIPPING_OFFERS: "getShippingOffers",
  GET_VENDOR_AUTH_CODE: "getVendorAuthCode",
  GET_VENDOR_PRODUCT: "getVendorAndProductTypes",
  GLOBAL_COMMISSION: "global",
  GLOBAL_CONSIGNMENT: "globalConsignment",
  GREATER_THAN: "greaterThan",
  GREENDARK: "greenDark",
  GROWTH: "growth",
  JOINED: "Joined",
  HEADER: "header",
  HEADER_TEXT: "headerText",
  HEIGHT: "height",
  HEX: "hex",
  HIDE: "Hide",
  IMAGE: "image",
  IMAGES: "images",
  IMAGE_URL: "imageUrl",
  INPUT_ID_COST: "inputIdCost",
  INPUT_ID_DESCRIPTION: "inputIdDescription",
  INPUT_ID_TYPE: "inputIdType",
  INVENTORY_MANAGEMENT: "inventoryManagement",
  INVENTORY_QUANTITY: "inventoryQuantity",
  INVITATION: "invitation",
  INVITE: "invite",
  INVITE_CSV: "inviteCsv",
  INVITE_FORM: "inviteForm",
  INVOICE: "invoice",
  IN_PROGRESS: "in progress",
  IS_ACCEPT_POLICY: "isAcceptPolicy",
  IS_DEFAULT: "isDefault",
  IS_HEADER_COPY: "isHeaderCopy",
  IS_HIDE_CONTACT: "isHideContact",
  IS_SHIPPING: "isShipping",
  IS_SHIPPING_DISABLED: "isShippingDisabled",
  IS_UPDATE_ALLOWED: "isUpdateAllowed",
  ITEM: "item",
  ITEMS: "items",
  LARGE: "large",
  LABEL: "label",
  LAST_NAME: "lastName",
  LENGTH: "length",
  LESS_THAN: "lessThan",
  LIKE: "like",
  LINE_RETURN: "lineReturn",
  LOCAL: "local",
  LOGIN: "login",
  LOGIN_TEXT: "loginText",
  MAIL_SUBJECT: "mailSubject",
  MAINTENANCE: "maintenance",
  MANAGE_OFFER: "manage-offer",
  MANAGE_RULE: "manage-rule",
  MANAGE_SHIPPING: "manage-shipping",
  MANAGE_SHIPPING_TERMS: "manage-shipping-terms",
  MANUAL: "manual",
  MARKET_CUBE: "https://res.cloudinary.com/marketcube/image/upload/v1524897677/vkdji3bfwtal621btquo.jpg",
  MARKUP: "markUp",
  MARKUPKEY: "Mark Up",
  MARKUP_SETTING: "markupSetting",
  MATERIAL: "material",
  MAX: "max",
  MESSAGE: "message",
  MUTE_ALL: "muteAll",
  MUTE_NOTIFICATION: "muteNotification",
  NAME: "name",
  NEEDS_REVIEW: "Needs Review",
  NEW: "new",
  NEW_PASSWORD: "newPassword",
  NOIMAGESNAP: "https://via.placeholder.com/80x80/ffffff/000000?text=No+image",
  NONE: "none",
  NOTE: "note",
  NOT_ALLOWED_KEYS: ["_id", "isTaxable", "option1", "option2", "option3", "handle"],
  NOT_ALLOWED_TO_ADD: "notAllowedToAdd",
  NOT_TO_UPDATE: ["option1", "option2", "option3", "image", "vendorDiscount", "length", "width", "height"],
  NOT_SYNCED: "unsynced",
  NO_RETURN_ALLOWED: "noReturn",
  NUMBER: "number",
  NZPOST: "nzShip",
  OFFER: "offer",
  OFFERS: "offers",
  ON: "On",
  ONBOARD_ACTIVITY: "onboardActivity",
  ONBOARDED: "Onboarded",
  OPTION1_VAL: "option1Val",
  OPTION2_VAL: "option2Val",
  OPTION3_VAL: "option3Val",
  OPTION: "option",
  OPTIONS: ["lb", "oz", "kg", "g"],
  ORDER: "order",
  ORDERS: "orders",
  ORDER_SETTING: "orderSetting",
  ORDER_SLIP: "orderSlip",
  ORDER_VALUE: "orderValue",
  ORGANISATION_TAGS: "organisationTags",
  PAID: "paid",
  PASSWORD: "password",
  PAYMENT: "payment",
  PAYMENTS: "payments",
  PAYMENT_TERMS_DAY: "paymentTermsDay",
  PAYOUTS: "payouts",
  PAYPAL: "paypal",
  PAYPAL_BANNER_URL: "http://support.marketcube.io/en/articles/2554842-how-to-set-up-paypal-to-pay-your-vendors",
  PAYPAL_CLIENT_ID: "paypalClientId",
  PAYPAL_CONNECTED: "paypalConnected",
  PAYPAL_DEVELOPER_URL: "https://developer.paypal.com/",
  PAYPAL_SECRET: "paypalSecret",
  PAYPAL_URL: "https://test.marketcube.io/paypal/callback",
  PENDING: "Pending",
  PERCENTAGE: "percentage",
  PHONE_NUMBER: "phoneNumber",
  PINCODE: "pincode",
  POLICY_LINK: "policyLink",
  POLICY_SELECTION: "policySelection",
  POSTAL_CODE: "postalCode",
  POST_FULFILLMENT: "postFulfillment",
  POST_RETURN: "postReturn",
  PRE_FULFILLMENT: "preFulfillment",
  PRICE: "price",
  PRICE_TYPE: "priceType",
  PRIVACY: "privacy",
  PRO: "pro",
  PROCESSING: "Processing",
  PRODUCT: "product",
  PRODUCTS: "products",
  PRODUCT_BULK_CREATE: "bulkProductCreate",
  PRODUCT_CSV: "productCsv",
  PRODUCT_FILTER: "productFilter",
  PRODUCT_FROM_SHOPIFY: "productFromShopify",
  PRODUCT_IDS: "productIds",
  PRODUCT_IMPORT: "productImport",
  PRODUCT_PRICE: "productPrice",
  PRODUCT_QUANTITY: "productQuantity",
  PRODUCT_RULE_CONDITION: "productRuleCondition",
  PRODUCT_SETTING: "productSetting",
  PRODUCT_TAGS: "tags",
  PRODUCT_TYPE: "productType",
  PRODUCT_UC_FIRST: "Product",
  PRODUCT_URL: "/products",
  PRODUCT_URL_EDIT: "/products/bulk/edit",
  PRODUCT_URL_REVIEW: "/products/review/",
  PRODUCT_URL_SHOPIFY: "/products/shopify",
  PRODUCT_URL_WOOCOMMERCE: "/products/woocommerce",
  PRODUCT_URL_UPDATE: "/products/edit/",
  PRODUCT_USING_FORM: "addProductUsingForm",
  PRODUCT_VALUE_TYPE: "productValueType",
  PROGRESS: "progress",
  PROVIDER_VIEW: "/providers/view/",
  PROVINCE_CODE: "provinceCode",
  PUBLISH: "publish",
  PUBLISHED: "published",
  PUSH_TO_STORE_ID: "pushToStore",
  PUSH_TO_STORE_LABEL: "Publish to Store",
  QUANTITY: "quantity",
  QUANTITY_DISABLED: "quantityDisabled",
  RADIO_BUTTON: "radioButton",
  RADIO_BUTTON_WITH_AUTO_COMPLETE: "radioButtonWithAutoComplete",
  RANGE: "range",
  READ_ONLY: "Read-Only",
  READ_ONLY_ACCESS: "readOnlyAccess",
  READY_TO_PICK: "Ready to Pick",
  REASON: "reason",
  REGISTER: "register",
  REJECT: "reject",
  REJECTED: "rejected",
  REJECT_LABEL: "Reject",
  REJECT_ORDER: "Reject Order",
  RESEND: "Resend",
  RESEND_OTP: "resendOtp",
  RESET_ALL: "resetAll",
  RETURN: "return",
  RETURN_ALLOWED: "return",
  REVIEWED: "Reviewed",
  REVIEW_ALL: "review",
  REVIEW_PRODUCT: "reviewProduct",
  REVOKED: "revoked",
  RE_SUBMIT: "resubmit",
  RULE: "rule",
  RULES: "rules",
  SALES: "sales",
  SECRET_ACCESS_KEY: "secret",
  SELECTED: "selected",
  SELECTED_PAYMENT: "paymentTermsType",
  SELECTED_POLICY: "selectedPolicy",
  SELECTED_STANDARD_TYPE: "selectedStandardType",
  SELECT_CUMULATIVE: "selectedCumulative",
  SELECT_VENDOR_SHIPPING: "selectVendorShipping",
  SELECT_WEIGHT: "weightUnit",
  SELLER: "seller",
  SELLER_DISCOUNT: "sellerDiscount",
  SELLER_PRODUCTS: "sellerProducts",
  SESSION: "session",
  SETTINGS: "settings",
  SHIPPING: "shipping",
  SHIPPING_BAND: "shippingBand",
  SHIPPING_BAND_ID: "shippingBandId",
  SHIPPING_CHARGE_SELECT: "shippingChargeSelect",
  SHIPPING_CONDITION: "shippingCondition",
  SHIPPING_COST: "shippingCost",
  SHIPPING_FIXED: "fixed",
  SHIPPING_FREE: "free",
  SHIPPING_TYPE: "shippingType",
  SHIPPED_BY_VENDOR: "Shipped by Vendor",
  SHIPPO: "shippo",
  SHIP_STATION: "shipStation",
  SHOP_DOMAIN:"shopDomain",
  SHOPIFY: "shopify",
  SHOPIFY_PRODUCT: "shopifyProduct",
  SHOW: "Show",
  SHOW_ACCOUNT_TYPE: "showAccountType",
  SHOW_BRAND_NAME: "showBrandName",
  SINGULAR: "offer",
  SIZE: "size",
  SITE_CODE: "siteCode",
  SKU: "sku",
  SLIM: "slim",
  SMALL: "small",
  SORT_ORDER: "sortOrder",
  SORT_CODE: "sortCode",
  STANDARD_AMOUNT: "standardAmount",
  STATUS_NEW: "new",
  STREET_ADDRESS: "streetAddress",
  STRIPE: "stripe",
  STRIPE_ACCOUNT: "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=",
  STRIPE_CLIENT_ID: "stripeClientId",
  STRIPE_CONNECTED: "stripeConnected",
  STRIPE_DASHBOARD_URL: "https://dashboard.stripe.com/",
  STRIPE_SECRET: "stripeSecret",
  STRIPE_URL: "https://test.marketcube.io/stripe/callback",
  STYLE: "Style",
  SUBDUED: "subdued",
  SUBJECT: "subject",
  SUBMIT: "Submit",
  SUBMIT_MANAGE_SHIPPING: "submitManageShipping",
  SUCCESS: "success",
  SUPPLIERS_SHOPIFY: "suppliers/shopify-callback",
  SUPPORT: "support",
  SYNCED: "synced",
  SYSTEM_ADMIN_NAME: "MarketCube Support",
  TAGS: "tags",
  TERM: "term",
  TERMS: "terms",
  TERMS_LINK: "termsLink",
  TEXT: "text",
  TINYMCE: "tinyMCE",
  TITLE: "title",
  TYPE: "type",
  UNAVAILABLE: "Unavailable",
  UNFULFILLED: "unfulfilled",
  UNFULFILLEDLINEITEMS: "unfulfilledLineItems",
  UNPAID: "unpaid",
  UNDELETE: "undelete",
  UNPUBLISH: "unpublish",
  UNREJECT: "unreject",
  UN_DELETE: "Un-Delete",
  UN_REJECT: "Un-Reject",
  UP: "up",
  UPDATE_FULFILLMENT: "updateFulfillment",
  UPDATE_ORDER_SETTING: "updateOrderSetting",
  UPDATE_PLAN: "updatePlan",
  UPDATE_PRODUCT_SETTING: "updateProductSetting",
  UPDATE_SELLER_SETTING: "updateSellerShippingSetting",
  UPDATE_SHOPIFY_URL: "/update-payment?plan",
  UPDATE_TERM: "updateTerm",
  UPLOAD_IMAGE: "uploadImage",
  USER: "user",
  USER_NAME: "userName",
  USER_ROLE: "userRole",
  VALUE: "value",
  VARIANTS: "variants",
  VENDOR: "vendor",
  VENDORS: "vendors",
  VENDOR_ADD: "vendorAdd",
  VENDOR_CSV: "vendorCsv",
  VENDOR_CHARGE_SECTION: "vendorChargeSection",
  VENDOR_DISCOUNT: "vendorDiscount",
  VENDOR_IDS: "vendorIds",
  VENDOR_LABEL: "Vendor",
  VENDOR_MANAGE: "vendorManage",
  VENDOR_OPTIONS: "vendorOptions",
  VENDOR_PRODUCT_SETTING_MODAL: "vendorProductSettingModal",
  VENDOR_VALUE: "vendorValue",
  VERIFY: "verify",
  VIEW_ID: "view",
  VIEW_LABEL: "View",
  VERIFY_LOGIN: "verifyLogin",
  VIEW: "view",
  VIEW_ORDER: "viewOrder",
  VIEW_VENDOR: "viewVendor",
  WARNING: "warning",
  WEIGHT: "weight",
  WEIGHT_UNIT: "weightUnit",
  WIDTH: "width",
  SHOP_CONNECT: "shopConnect",
  WOOCOMMERCE: "woocommerce",
  UPDATED: "Updated",
  YELLOWDARK: "yellowDark",
  ZERO_PRICE: "0.0",
  items: "Total items: ",
  color,
  displayStatus,
  featureError,
  featureVersion,
  gql,
  imageTypes,
  manageVendorDetails,
  onboardingSteps,
  returnReason,
  symbol,
  userKey,
  value,
  productVersion,
};

export default constant;

import React from "react";
import { Card, RadioButton, Select, Stack, TextField } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import constant
import constant from "lib/constant/constant";

// import propType
import { offerConditionProp } from "../../../propTypes";

const RenderOfferCondition = (props) => {
  const {
    handleConditionChange,
    isConditionApplied,
    shippingOffer,
    handleCriteria,
    handleCondition,
    moneyFormat,
  } = props;
  const { t } = useTranslation();
  const isProductPrice = shippingOffer.condition === constant.PRODUCT_PRICE;
  const isProductQuantity = shippingOffer.condition === constant.PRODUCT_QUANTITY;
  const isOrderValue = shippingOffer.condition === constant.ORDER_VALUE;

  const criteriaText =
    (isProductPrice && t("label.shippingOffer.priceShouldBe")) ||
    (isProductQuantity && t("label.shippingOffer.quantityShouldBe")) ||
    t("label.shippingOffer.orderValueShouldBe");

  const criteriaValues =
    (isProductPrice && shippingOffer.productPrice) ||
    (isProductQuantity && shippingOffer.productQuantity) ||
    (isOrderValue && shippingOffer.orderValue) ||
    {};

  const criteriaKey =
    (isProductPrice && constant.PRODUCT_PRICE) ||
    (isProductQuantity && constant.PRODUCT_QUANTITY) ||
    constant.ORDER_VALUE;

  const conditionOptions = [
    {
      key: constant.PRODUCT_PRICE,
      label: t("label.shippingOffer.productPrice"),
      value: constant.PRODUCT_PRICE,
    },
    {
      key: constant.PRODUCT_QUANTITY,
      label: t("label.shippingOffer.productQuantity"),
      value: constant.PRODUCT_QUANTITY,
    },
    {
      key: constant.ORDER_VALUE,
      label: t("label.shippingOffer.orderValue"),
      value: constant.ORDER_VALUE,
    },
  ];

  const offerCriteriaOptions = [
    { key: constant.GREATER_THAN, label: t("label.shippingOffer.greaterThan"), value: constant.GREATER_THAN },
    { key: constant.LESS_THAN, label: t("label.shippingOffer.lessThan"), value: constant.LESS_THAN },
  ];

  return (
    <Stack.Item>
      <Card sectioned title={t("label.shippingOffer.offerCondition")}>
        <Stack vertical>
          <RadioButton
            id="allConditions"
            name="allConditions"
            label={t("label.shippingOffer.allConditions")}
            helpText={t("message.shippingOffer.allCondition")}
            checked={!isConditionApplied}
            onChange={handleConditionChange}
          />
          <RadioButton
            id="selectedConditions"
            name="selectedConditions"
            label={t("label.shippingOffer.productOrderCondition")}
            helpText={t("message.shippingOffer.selectedCondition")}
            checked={isConditionApplied}
            onChange={handleConditionChange}
          />
          {isConditionApplied && (
            <Stack.Item>
              <Stack.Item>
                <Select
                  id="shippingCondition"
                  name="shippingCondition"
                  options={conditionOptions}
                  label={t("label.shippingOffer.selectShippingOffer")}
                  labelHidden
                  placeholder={t("placeHolder.shippingOffer.selectShippingOffer")}
                  value={shippingOffer.condition || ""}
                  onChange={handleCondition}
                />
              </Stack.Item>
              {(isProductPrice || isProductQuantity || isOrderValue) && (
                <Stack.Item>
                  <br />
                  <Stack>
                    <Stack.Item>{criteriaText}</Stack.Item>
                    <Stack.Item>
                      <Select
                        id="criteria"
                        key="criteria"
                        options={offerCriteriaOptions}
                        label={t("label.shippingOffer.selectBand")}
                        labelHidden
                        placeholder={t("placeHolder.shippingOffer.selectCriteria")}
                        value={criteriaValues.criteria || ""}
                        onChange={(val) => handleCriteria(criteriaKey, "criteria", val)}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <TextField
                        id="criteriaValue"
                        type="number"
                        label={t("label.shippingOffer.descriptionAbout")}
                        labelHidden
                        placeholder={t("placeHolder.shippingOffer.selectValueCriteria")}
                        value={criteriaValues.value || ""}
                        min={constant.value.MIN_PRICE}
                        step={isProductPrice || isOrderValue ? constant.value.STEP : constant.value.MIN_PRICE}
                        prefix={(isProductPrice || isOrderValue) && moneyFormat}
                        onChange={(val) => handleCriteria(criteriaKey, "value", val)}
                      />
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              )}
            </Stack.Item>
          )}
        </Stack>
      </Card>
    </Stack.Item>
  );
};

RenderOfferCondition.propTypes = offerConditionProp.type;

export default RenderOfferCondition;

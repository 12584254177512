const config = {
  "/orders": [
    {
      caption: "Export your orders",
      id: "exportOrder",
      label: "Export Order",
      link: "/orders",
    },
  ],
  "/payments": [
    {
      caption: "Export your payments",
      id: "exportPayment",
      label: "Export Payment",
      link: "/payments",
    },
  ],
  "/products": [
    {
      caption: "Add your products",
      id: "addProduct",
      label: "Add Products",
      link: "/products/add",
    },
    {
      caption: "Import products from your store",
      id: "importProduct",
      label: "Import Products from Store",
      link: "/products/add",
    },
    {
      caption: "View your store products",
      id: "storeProduct",
      label: "E-commerce Store Products",
      link: "/products/shopify",
    },
    {
      caption: "Connect to your Shop",
      id: "disconnectShop",
      label: "Connect Shop",
      link: "/products/add/shop",
    },
  ],
};

export default config;

import React from "react";
import { Card, Stack, Select, Heading, Pagination } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import cmsSellerList from "../../cms/sellerListCMS";

const SellerListFooter = (props) => {
  const { optionsList, onSelectLimit, perPageCount, totalCount, onPerPage, currentPage } = props;

  const { t } = useTranslation();
  const sellerListCMS = cmsSellerList(t);
  const { label } = sellerListCMS;
  return (
    <>
      <Card.Section>
        <Stack>
          <Stack.Item fill>
            <Heading>
              {`${label.total}`}
              <span className="totalCount">{totalCount}</span>
            </Heading>
          </Stack.Item>
          <Stack.Item fill>
            <Pagination
              hasPrevious={(totalCount && currentPage !== 1 && true) || false}
              onPrevious={() => {
                if (currentPage !== 1) {
                  onPerPage(currentPage - 1);
                }
              }}
              hasNext={(currentPage < totalCount && true) || false}
              onNext={() => {
                if (currentPage <= totalCount) {
                  onPerPage(currentPage + 1);
                }
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <Select value={perPageCount} options={optionsList} onChange={onSelectLimit} />
          </Stack.Item>
        </Stack>
      </Card.Section>
    </>
  );
};

SellerListFooter.defaultProps = {
  optionsList: [],
  perPageCount: "",
  totalCount: "",
  currentPage: 1,
  onSelectLimit: () => {},
  onPerPage: () => {},
};

SellerListFooter.propTypes = {
  optionsList: PropTypes.arrayOf(PropTypes.object),
  perPageCount: PropTypes.number,
  totalCount: PropTypes.number,
  onSelectLimit: PropTypes.func,
  onPerPage: PropTypes.func,
  currentPage: PropTypes.number,
};

export default SellerListFooter;

import React, { useContext, useState } from "react";
import { TextField, Card, Layout, FormLayout, PageActions } from "@shopify/polaris";

import { useMutation } from "@apollo/react-hooks";

import { PublicContext } from "lib/context";
import constant from "lib/constant/constant";
import { Banner, Spinner } from "lib/components";
import { baseHelper } from "lib/helpers";

import { CHECK_RESET_TOKEN, UPDATE_PASSWORD } from "../../../../apollo/mutations";
import validate from "./yup";

const UpdatePassword = () => {
  const { history, cms, match } = useContext(PublicContext);
  const { params } = match || {};
  const { token } = params || {};
  const [checkResetToken, { loading: checkTokenLoading = false }] = useMutation(CHECK_RESET_TOKEN);
  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(UPDATE_PASSWORD);
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { PASSWORD, NEW_PASSWORD, CONFIRM_PASSWORD, gql } = constant;
  const [errorMessage, setErrorMessage] = useState(true);
  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const redirect = () => {
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  };

  const checkToken = async () => {
    if (isLoading) {
      try {
        const res = await checkResetToken({ variables: { input: { token } } });
        const resData = baseHelper.getResponseData(res.data, gql.CHECK_RESET_TOKEN);
        if (!resData) {
          const error = baseHelper.getResponseError(res.data, gql.CHECK_RESET_TOKEN);
          setBanner({
            status: "critical",
            isOpen: true,
            title: error,
          });
          redirect();
        }
      } catch (e) {
        setBanner({
          isOpen: true,
          status: "critical",
          title: cms("common.message.error.somethingWentWrong"),
        });
        redirect();
      }
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    await handleValidation(NEW_PASSWORD, values.newPassword);
    await handleValidation(CONFIRM_PASSWORD, {
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    });
    const isAnyValidationError = () => Object.values(errorMessage).some((error) => error);
    const isAllValuesFilled = () => Object.values(values).every((value) => value);
    if (!isAnyValidationError() && isAllValuesFilled()) {
      try {
        const res = await resetPassword({
          variables: {
            input: { newPassword: values.newPassword, token },
          },
        });
        const resData = baseHelper.getResponseData(res.data, gql.RESET_PASSWORD);
        let bannerValue = {
          status: "success",
          title: cms("message.success"),
        };
        let isResetPassword = true;
        if (!resData) {
          isResetPassword = false;
          const error = baseHelper.getResponseError(res.data, gql.RESET_PASSWORD);
          bannerValue = { status: "critical", title: error };
        }
        setBanner({
          status: bannerValue.status,
          isOpen: true,
          title: bannerValue.title,
        });
        if (isResetPassword) {
          redirect();
        }
      } catch (err) {
        setBanner({
          isOpen: true,
          status: "critical",
          title: cms("common.message.error.somethingWentWrong"),
        });
      }
    }
  };
  if (isLoading) {
    checkToken();
    setIsLoading(false);
  }

  return (
    <>
      {checkTokenLoading && <Spinner />}
      <Banner title={banner.title} status={banner.status} isOpen={banner.isOpen} />
      <br />
      <Layout.AnnotatedSection title={cms("title")}>
        <Card>
          <Card.Section>
            <FormLayout>
              <TextField
                id="newPassword"
                label={`${cms("label.newPassword")}*`}
                type={PASSWORD}
                value={values.newPassword || ""}
                placeholder={cms("placeholder.newPassword")}
                onChange={(value) => handleChange(NEW_PASSWORD, value)}
                onBlur={() => handleValidation(NEW_PASSWORD, values.newPassword)}
                error={errorMessage && errorMessage.newPassword}
              />
              <TextField
                id="confirmPassword"
                label={`${cms("label.confirmNewPassword")}*`}
                placeholder={cms("placeholder.confirmNewPassword")}
                type={PASSWORD}
                value={values.confirmPassword || ""}
                onChange={(value) => handleChange(CONFIRM_PASSWORD, value)}
                onBlur={() => {
                  handleValidation(CONFIRM_PASSWORD, {
                    newPassword: values.newPassword,
                    confirmPassword: values.confirmPassword,
                  });
                }}
                error={errorMessage && errorMessage.confirmPassword}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            id: "resetPassword",
            content: cms("button.primary"),
            onAction: onSubmit,
            loading: resetPasswordLoading,
          }}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default UpdatePassword;

import * as yup from "yup";

const validateData = {};
const schema = (label) => {
  const validationSchema = yup.object().shape({
    RadioGroup: yup.string().required(label.select),
  });
  validateData.validationSchema = validationSchema;
};

export { validateData, schema };

import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import schema from "./schema";

const validate = async (field, value, message) => {
  const { validateValue, handlePromiseError } = yupHelper;
  const { headerHexSchema, headerTextHexSchema, buttonHexSchema, buttonTextHexSchema } = schema(message);
  const { HEADER, HEADER_TEXT, BUTTON, BUTTON_TEXT } = constant;
  let promise = "";
  switch (field) {
    case HEADER:
      promise = validateValue(headerHexSchema, { header: value });
      break;
    case HEADER_TEXT:
      promise = validateValue(headerTextHexSchema, { headerText: value });
      break;
    case BUTTON:
      promise = validateValue(buttonHexSchema, { button: value });
      break;
    case BUTTON_TEXT:
      promise = validateValue(buttonTextHexSchema, { buttonText: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;

import React from "react";
import { Button } from "@shopify/polaris";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import { SYNC_WOOCOMMERCE_VENDOR_PRODUCT } from "app/products/apollo/mutations/connect";

// import props
import { syncWooCommerceProduct } from "./props";

const SyncWooCommerceProduct = (props) => {
  const { cms, history, setBanner } = props;
  const { gql } = constant;

  const { t } = useTranslation();
  const [syncWooCommerceVendorProduct, { loading: syncWooCommerceVendorProductLoading }] = useMutation(SYNC_WOOCOMMERCE_VENDOR_PRODUCT);
  const syncWooCommerceProducts = async () => {
    try {
      const response = await syncWooCommerceVendorProduct();
      const responseError = baseHelper.getResponseError(response.data, gql.SYNC_WOOCOMMERCE_VENDOR_PRODUCT);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      const onAction = () => history.push("/products/woocommerce");
      const bannerAction = {
        onAction,
        content: "Click here to view Woocommerce Product",
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: `${cms("section.connect.button.viewWoocommerceProduct")}`,
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  return (
    <Button loading={syncWooCommerceVendorProductLoading} onClick={(value) => syncWooCommerceProducts(value)}>
      {cms("section.connect.button.importWooCommerceProduct")}
    </Button>
  );
};

SyncWooCommerceProduct.propTypes = syncWooCommerceProduct.type;
SyncWooCommerceProduct.defaultProps = syncWooCommerceProduct.default;

export default SyncWooCommerceProduct;

import React, { useState, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";

import { PrivateLayout } from "layout";
import privateRouteCodes from "routes/private/routeCode/routeCode";

import { storageHelper, baseHelper, accessHelper, featureHelper, hooksHelper, chatHelper } from "lib/helpers";

import constant from "lib/constant/constant";
import { NotFound } from "layout/private/components";

// import context
import privatePageContent from "routes/private/pageContent/pageContent";
import { PrivateProvider } from "lib/context";
import PrivateBrandingContainer from "./privateBrandingContainer";

// import queries
import { GET_USER_CONTENT } from "./apollo/queries";

const { gql, featureError } = constant;

const PrivateContainer = (props) => {
  const [updatedPageData, setUpdatedPageData] = useState(null);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [onboardingTab, setOnboardingTab] = useState(null);
  const { component: Component, ...rest } = props;
  const { match, history } = rest;
  const { path } = match;
  const {
    cms = "",
    isTwoColumnLayout = false,
    parentModule = "dashboard",
    module = "",
    rolesAllowed = [],
    isPrivateLayoutHidden = false,
    dependentKey = [],
    isFullWidth = false,
  } = privateRouteCodes[path];

  const pageData = privatePageContent[module] || {};

  const {
    loading: privateContainerLoading,
    // error: privateContainerError,
    data: privateContainerData,
    networkStatus: privateContainerNetworkStatus,
    refetch: currentUserRefetch,
  } = useQuery(GET_USER_CONTENT, {
    variables: {
      getContentInput: {
        routeCode: cms,
      },
      getFeatureInput: {
        routeCode: module,
        dependentKey,
      },
      getPublicCMSInput: {
        language: "en",
        code: (isOnboarding && isOnboarding.cms) || cms,
        module: (isOnboarding && isOnboarding.module) || parentModule,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const isLoading = privateContainerNetworkStatus === 4 || privateContainerLoading;

  const cmsData = baseHelper.getResponseData(privateContainerData, gql.GET_CONTENT);
  const newCMSData = baseHelper.getResponseData(privateContainerData, "getPublicCMS");
  const currentUserData = baseHelper.getResponseData(privateContainerData, gql.GET_CURRENT_USER);
  const featureFlagData = baseHelper.getResponseData(privateContainerData, gql.GET_FEATURE);

  const isRootDataFetchedSuccessfully = !!currentUserData;
  const { useCMS } = hooksHelper;
  const CMS = useCMS(newCMSData);

  if (!isLoading && !isRootDataFetchedSuccessfully) {
    storageHelper.remove("token");
    storageHelper.remove("userRole");
    storageHelper.remove("isInitial");
    storageHelper.remove("setupProgress");
    storageHelper.removeAllCookie();
    history.push({ pathname: "/", state: { isUserNotFound: true } });
  }
  let isNotAllowed = false;
  let isNotFound = false;
  if (featureFlagData.key && currentUserData) {
    const checkPermission = featureHelper.checkPermission(currentUserData, featureFlagData);
    if (checkPermission === featureError.roleNotAllowed) {
      isNotAllowed = true;
    }
    if (checkPermission === featureError.hideFeature) {
      isNotFound = true;
    }
  }

  const setPageData = useCallback(
    (data) => {
      // NOTE: de-structure more data, as per your need
      const { title = "", primaryAction = null, subtitle = "" } = data;
      setUpdatedPageData({ ...pageData, title: pageData.title || title, primaryAction, subtitle });
    },
    [pageData]
  );

  let formattedCMSData = {};
  if (isRootDataFetchedSuccessfully) {
    formattedCMSData = baseHelper.getFormattedCMS(cmsData);
    const currentUserRole = accessHelper.getUserRole(currentUserData);

    // NOTE: Need to improve this logic
    if (currentUserData && currentUserData.setupProgress !== null) {
      storageHelper.set("setupProgress", currentUserData.setupProgress);
    } else {
      storageHelper.remove("setupProgress");
    }
    storageHelper.set("userRole", currentUserRole);
    storageHelper.set("lang", storageHelper.get("lang") || "en");
    storageHelper.set("isInitial", false);

    const userDetails = baseHelper.getChatUser(currentUserData);

    const isSeller = baseHelper.isSeller(currentUserData);

    if (isSeller) {
      userDetails.shop = currentUserData.shop;
    }

    chatHelper.setUserDetails(userDetails);
  }

  // check if the user is allowed the access the route/page
  // also works for identifying invalid private routes
  const isUserAllowed = rolesAllowed.find((role) => role === storageHelper.get("userRole"));
  if (!isUserAllowed && storageHelper.get("userRole")) {
    return (
      <PrivateProvider
        value={{
          ...rest,
        }}
      >
        <NotFound />
      </PrivateProvider>
    );
  }

  const FullLayout = () => (
    <PrivateBrandingContainer>
      <PrivateLayout setIsOnboarding={setIsOnboarding} onboardingTab={onboardingTab}>
        <Component />
      </PrivateLayout>
    </PrivateBrandingContainer>
  );

  const LayoutComponent = () => <Component />;
  return (
    <PrivateProvider
      value={{
        cms: CMS,
        oldCMS: formattedCMSData,
        currentUser: currentUserData,
        currentUserRefetch,
        isLoading,
        isNotAllowed,
        isNotFound,
        isTwoColumnLayout,
        isFullWidth,
        featureFlag: featureFlagData,
        pageData: updatedPageData || pageData,
        setPageData,
        setOnboardingTab,
        ...rest,
      }}
    >
      {isPrivateLayoutHidden ? LayoutComponent() : FullLayout()}
    </PrivateProvider>
  );
};

PrivateContainer.propTypes = {
  component: PropTypes.node,
};

PrivateContainer.defaultProps = {
  component: null,
};

export default PrivateContainer;

import React, { useState } from "react";

import Baby from "babyparse";
import fileDownload from "js-file-download";

import { Spinner } from "lib/components";
import { withErrorBoundary } from "lib/hoc";

const DownloadInviteCSV = () => {
  const [isDownload, setIsDownload] = useState(true);
  const data = [["email"], ["test@gmail.com"]];
  const csv = Baby.unparse(data);
  if (isDownload) {
    fileDownload(csv, "bulk-invite.csv");
    setIsDownload(false);
    setTimeout(() => {
      window.close();
    }, 500);
  }

  return <Spinner isFullPage />;
};

export default withErrorBoundary(DownloadInviteCSV);

const standardList = [
  {
    code: "marketplace",
    title: "Your Market Place",
    description:
      "Manage your marketplace here. Configure how you manage products and orders to notifications and fulfilment and shipping",
    thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
    list: [
      {
        title: "Products",
        description: "Manage your product related settings here",
        icon: "fal fa-tag fa-lg",
        code: "product",
        thumbnailHeader: "",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription: "",
        actions: [
          {
            label: "Live inventory update",
            link: "/setting/product",
            code: "productSettings",
            description: "Configure product add settings for your connected online store",
          },
          {
            label: "Discounts",
            link: "/setting/discount",
            code: "discount",
            description: "Apply discounts to products that you offer to the marketplace",
          },
        ],
      },
      {
        title: "Orders",
        description: "Manage your order related settings here",
        icon: "fal fa-shopping-cart fa-lg",
        code: "order",
        thumbnailHeader: "Order settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Disable live order syncing",
            link: "/setting/order",
            code: "orderSettings",
            description: "Manage live update to order statuses.",
          },
          {
            label: "Auto accept orders",
            link: "/setting/order",
            code: "orderSettings",
            description: "Manage how you wish to accept incoming orders, manually or automatically.",
          },
          {
            label: "Auto fulfil order",
            link: "/setting/order",
            code: "orderSettings",
            description: "Manage how you wish to fulfi accepted orders, manually or automatically.",
          },
        ],
      },
      {
        title: "Payments",
        description: "Manage your payment related settings here",
        icon: "fal fa-sack-dollar fa-lg",
        code: "payments",
        thumbnailHeader: "Notification settings",
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Stripe payments",
            link: "/payment-connect",
            code: "paymentService",
            description: "Connect your Stripe account for payouts",
          },
          {
            label: "PayPal payments",
            link: "/payment-connect",
            code: "paymentService",
            description: "Connect your PayPal account for payouts",
          },
        ],
      },
      {
        title: "Fulfilments",
        description: "Manage your shipping and Fulfiment settings here",
        icon: "fal fa-dolly fa-lg",
        code: "fulfilment",
        thumbnailHeader: "Notification settings",
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Shipping",
            link: "/shipping",
            code: "shipping",
            description: "Manage email notifications settings here",
          },
          {
            label: "Advance Shipping",
            link: "/shipping/advance",
            code: "advanceShipping",
            description: "",
          },
        ],
      },
      {
        title: "Notifications",
        description: "Manage email notifications settings here",
        icon: "fal fa-envelope fa-lg",
        code: "notifications",
        thumbnailHeader: "Notification settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Mute Notification",
            link: "/setting/notification",
            code: "notification",
            description: "Manage which emails you wish to recieve and which ones you wish to turn off ",
          },
        ],
      },
      {
        title: "User",
        description: "Manage your user account and profile settings here",
        icon: "fal fa-user fa-lg",
        code: "user",
        thumbnailHeader: "User settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Profile",
            link: "/profile",
            code: "profile",
            description: "Manage your profile information here",
          },
          {
            label: "Change password",
            link: "/change-password",
            code: "changePassword",
            description: "Manage your account login password here",
          },
        ],
      },
      {
        title: "Billing",
        description: "Billing Settings: etc",
        icon: "fal fa-money-bill fa-lg",
        code: "billing",
        thumbnailHeader: "Billing settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Billing",
            link: "",
            code: "billing",
            description: "",
          },
        ],
      },
    ],
  },
];

export default standardList;

import React, { useState, useContext } from "react";
import { DisplayText, TextField, Select, Checkbox, Modal, Stack } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import helpers
import { baseHelper } from "lib/helpers";

// import component
import { Banner } from "lib/components";
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import mutations
import { EDIT_OFFER } from "app/shipping/apollo/mutations";

import { commonValidation } from "./subFeatureItems/offerHelper";

import { editModalProp } from "../../propTypes";

const EditOffer = (props) => {
  const {
    shippingOffer: shippingOfferProp,
    onClose,
    show = false,
    bands = [],
    destinations = [],
    products = [],
    handleBanner,
    refetch,
  } = props || {};
  const { currentUser } = useContext(PrivateContext);

  const defaultBanner = {
    isOpen: false,
    status: "",
    title: "",
  };
  const { t } = useTranslation();
  const [shippingOffer, setShippingOffer] = useState(shippingOfferProp);
  const [banner, setBanner] = useState({ ...defaultBanner });
  const [updateOffer, { loading }] = useMutation(EDIT_OFFER);
  const closeModal = () => {
    setBanner({ ...defaultBanner });
    onClose();
  };

  const prepareRequestData = () => {
    const { _id, price, description, priceType, isCumulative } = shippingOffer;
    const reqData = {
      _id,
      description,
      price: (priceType && priceType === constant.FIXED && price) || 0,
      priceType,
      isCumulative,
    };
    return reqData;
  };

  const editShippingBand = async () => {
    const requestData = prepareRequestData();
    try {
      const response = await updateOffer({
        variables: { input: requestData },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.UPDATE_SHIPPING_OFFER);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      handleBanner({ isOpen: true, status: "success", title: t("message.shippingOffer.updateOfferSuccess") });
      closeModal();
      refetch();
    } catch (error) {
      setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
    }
  };

  const onSubmit = () => {
    const { price, description, priceType } = shippingOffer || {};
    const hasError = commonValidation(price, description, priceType, t);
    if (hasError) {
      setBanner({ isOpen: true, status: "critical", title: hasError });
      return false;
    }
    editShippingBand();
    return true;
  };

  const toggleBanner = () => setBanner({ ...defaultBanner });

  const handleShippingOffer = (key, value) => {
    setShippingOffer({ ...shippingOffer, [key]: value });
  };
  const toggleCumulativeShipping = () => {
    const toggleCumulative = !shippingOffer.isCumulative;
    setShippingOffer((prevState) => ({
      ...prevState,
      isCumulative: toggleCumulative,
    }));
  };

  const {
    shippingBandId,
    price,
    description,
    condition,
    destinationCode,
    productQuantity,
    productPrice,
    orderValue,
    productId,
    priceType,
    isCumulative = false,
  } = shippingOffer || {};

  const { moneyFormat = constant.symbol.DOLLAR } = currentUser || {};

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((item) => item.value === destinationCode);
  const product = products.find((item) => item.value === productId);
  const shippingBand = bands.find((band) => band.value === shippingBandId) || {};

  const isProductPrice = condition === constant.PRODUCT_PRICE;
  const isProductQuantity = condition === constant.PRODUCT_QUANTITY;
  const isOrderValue = condition === constant.ORDER_VALUE;

  const criteriaValues =
    (isProductPrice && { ...productPrice }) ||
    (isProductQuantity && { ...productQuantity }) ||
    (isOrderValue && { ...orderValue }) ||
    {};

  const criteriaText =
    (isProductPrice && t("label.shippingOffer.priceShouldBe")) ||
    (isProductQuantity && t("label.shippingOffer.quantityShouldBe")) ||
    t("label.shippingOffer.orderValueShouldBe");

  const priceTypeOptions = [
    {
      label: t("label.shippingOffer.fixedCost"),
      value: constant.FIXED,
    },
    {
      label: t("label.shippingOffer.freeShipping"),
      value: constant.FREE,
    },
  ];

  return (
    <Modal
      open={show}
      onClose={onClose}
      title={t("label.shippingOffer.editOffer")}
      primaryAction={{
        content: t("label.shippingOffer.updateShipping"),
        loading,
        onAction: () => onSubmit(),
      }}
    >
      <Modal.Section>
        <Banner onDismiss={toggleBanner} isOpen={banner.isOpen} status={banner.status} title={banner.title} />
        <br />
        <form>
          <Stack vertical>
            <Stack.Item>
              <DisplayText size={constant.SMALL}>
                {`${t("label.shippingOffer.shippingBandColon")} ${shippingBand.label}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <Stack vertical>
                <Stack.Item>
                  <DisplayText size={constant.SMALL}>
                    {`${t("label.shippingOffer.destinationColon")} ${
                      destination ? destination.label : t("label.shippingOffer.all")
                    }`}
                  </DisplayText>
                </Stack.Item>
                <Stack.Item>
                  <DisplayText size={constant.SMALL}>
                    {`${t("label.shippingOffer.productColon")} ${
                      product ? product.label : t("label.shippingOffer.all")
                    }`}
                  </DisplayText>
                </Stack.Item>
                {(isProductPrice || isProductQuantity || isOrderValue) && (
                  <Stack.Item>
                    <DisplayText size={constant.SMALL}>
                      {t("label.shippingOffer.criteriaColon")}
                      {criteriaText}
                      {criteriaValues.criteria === constant.GREATER_THAN
                        ? t("label.shippingOffer.greaterThan")
                        : t("label.shippingOffer.lessThan")}
                      {isProductPrice || isOrderValue ? moneyFormat : ""}
                      {criteriaValues.value}
                    </DisplayText>
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TextField
                id={constant.INPUT_ID_DESCRIPTION}
                type={constant.TEXT}
                label={t("label.shippingOffer.descriptionAbout")}
                placeholder={t("placeHolder.shippingOffer.descriptionAbout")}
                value={description}
                onChange={(val) => handleShippingOffer(constant.DESCRIPTION, val)}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Select
                    options={priceTypeOptions}
                    id={constant.INPUT_ID_TYPE}
                    label={t("label.shippingOffer.helpSelectShipping")}
                    name={constant.SHIPPING_TYPE}
                    placeholder={t("placeHolder.shippingOffer.selectShippingRate")}
                    value={priceType || ""}
                    onChange={(val) => handleShippingOffer(constant.PRICE_TYPE, val)}
                  />
                </Stack.Item>
                {priceType === constant.FIXED && (
                  <Stack.Item>
                    <TextField
                      id={constant.INPUT_ID_COST}
                      label={t("label.shippingOffer.helpAmount")}
                      type={constant.NUMBER}
                      min={constant.value.MIN_PRICE}
                      step={constant.value.STEP}
                      value={price.toString() || ""}
                      onChange={(val) => handleShippingOffer(constant.PRICE, Number(val))}
                      prefix={moneyFormat}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            {!isOrderValue && (
              <Stack.Item>
                <Checkbox
                  checked={isCumulative}
                  onChange={toggleCumulativeShipping}
                  label={t("label.shippingOffer.chargeCumulative")}
                />
              </Stack.Item>
            )}
          </Stack>
        </form>
      </Modal.Section>
    </Modal>
  );
};

EditOffer.propTypes = editModalProp.type;

export default EditOffer;

// import packages
import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Card, Layout, Collapsible } from "@shopify/polaris";

// import helper
import baseHelper from "lib/helpers/base";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import gql
import { FETCH_EMAIL_TEMPLATE_LIST } from "app/emailTemplate/apollo/queries";

// import components
import { Banner } from "lib/components";

const OperatorEmailTemplate = () => {
  const { history } = useContext(PrivateContext);
  const { gql } = constant;
  const { t } = useTranslation();
  const [listData, setListData] = useState({});
  const [listCount, setListCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [toggleButton, setToggleButton] = useState({});

  const queryParams = baseHelper.getQueryParams(history.location.search) || {};

  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });

  useEffect(() => {
    if (queryParams && queryParams.isNotFound && !banner.isOpen) {
      setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
    }
  }, [queryParams, t, banner.isOpen]);

  const { data: emailTemplateData, loading: emailTemplateLoading, error } = useQuery(FETCH_EMAIL_TEMPLATE_LIST);

  useEffect(() => {
    if (!emailTemplateLoading) {
      setLoading(false);
    }

    if (error) {
      // set banner with error message
      setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
    }
  }, [emailTemplateLoading, error, t]);

  const emailTemplateError = baseHelper.getResponseError(emailTemplateData, gql.GET_EMAIL_TEMPLATE);
  const emailTemplateResponse = baseHelper.getResponseData(emailTemplateData, gql.GET_EMAIL_TEMPLATE);
  const listingEmailTemplates = async (response) => {
    if (response && response.length) {
      await Promise.all(
        response.map((element) => {
          if (element.category) {
            const key = element.category;
            if (!listData[key]) {
              listData[key] = [];
              setToggleButton({
                ...toggleButton,
                [key]: false,
              });
            }
            listData[key].push(element);
          }
        })
      );
      setListData(listData);
      const totalCount = Object.entries(listData).length;
      setListCount(totalCount);
    } else {
      setBanner({ isOpen: true, status: "critical", title: t("error.emailTemplate.templatesNotFound") });
    }
  };

  useEffect(() => {
    if (emailTemplateError) {
      setBanner({ isOpen: true, status: "critical", title: emailTemplateError });
    }

    if (emailTemplateResponse) {
      listingEmailTemplates(emailTemplateResponse);
    }
  }, [emailTemplateError, emailTemplateResponse]);

  const toggleTemplate = (type) => {
    setToggleButton({
      ...toggleButton,
      [type]: !toggleButton[type],
    });
  };

  // function render category specific item
  const renderItem = (type, items) => {
    const listItem = items.map((item) => {
      let { _id: id } = item;
      id = baseHelper.mongoIdAsString(id);
      const shortcutActions = [
        {
          content: t("label.emailTemplate.edit"),
          onAction: () => history.push(`/email-template/edit/${id}`),
        },
      ];
      return (
        <Collapsible open={toggleButton[type]} id={`basic-collapsible-${type}`}>
          <Card title={item.label} actions={shortcutActions} key={item.label} sectioned />
        </Collapsible>
      );
    });
    return listItem;
  };

  // function to list email templates
  const renderEmailTemplates = () => {
    if (listCount) {
      const list = Object.keys(listData).map((item) => (
        <Card
          title={item}
          actions={[
            {
              content: toggleButton[item] ? constant.HIDE : constant.SHOW,
              disclosure: toggleButton[item] ? constant.UP : constant.DOWN,
              onAction: () => toggleTemplate(item),
            },
          ]}
          key={item}
        >
          <br />
          {renderItem(item, listData[item])}
        </Card>
      ));
      return list;
    }
  };

  const closeBanner = () => {
    const currentUrl = history.location.pathname;
    setBanner({ isOpen: false });
    history.push(currentUrl);
  };

  return [
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner isOpen={banner.isOpen} title={banner.title} status={banner.status} onDismiss={() => closeBanner()} />
        </Layout.Section>
      )}
      {(listCount && (
        <Layout.AnnotatedSection
          title={t("sectionContent.emailTemplate.title")}
          description={t("sectionContent.emailTemplate.description")}
        >
          {renderEmailTemplates()}
        </Layout.AnnotatedSection>
      )) ||
        ""}
    </>,
  ];
};

export default withFeature(withErrorBoundary(OperatorEmailTemplate), {
  feature: constant.EMAIL_TEMPLATE,
});

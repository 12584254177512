import React, { useContext } from "react";
import { SettingToggle } from "@shopify/polaris";

// import propTypes
import propType from "prop-types";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

const CardList = (props) => {
  const { currentUser, cms } = useContext(PrivateContext);
  const { isMuted, isLoadingLabel, labelData, handleClick, seller = {}, vendor = {} } = props;
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  let labelsList = isSeller && seller;
  if (isVendor) {
    labelsList = isVendor && vendor;
  }
  return (
    <SettingToggle
      key="settingToggle"
      action={{
        content: (isMuted && `${cms("button.unMute")}`) || `${cms("button.mute")}`,
        onAction: () => handleClick(labelData),
        destructive: !isMuted,
        loading: isLoadingLabel,
        disabled: false,
      }}
      enabled={!isMuted}
    >
      {labelsList[labelData]}
    </SettingToggle>
  );
};

CardList.defaultProps = propType.default;
CardList.propTypes = propType.type;

export default CardList;

import React, { useState, useContext, useEffect } from "react";
import { Card, FormLayout, PageActions, Banner, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import fileDownload from "js-file-download";
import Baby from "babyparse";

// import hoc
import { withFeature } from "lib/hoc";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context/privateContext";

import { baseHelper } from "lib/helpers";

// import component
import FileUpload from "lib/components/fileInput/fileUpload";
import { Sheet } from "lib/components";

import csvData from "../../addProductConfig";

// import query
import { BULK_UPLOAD } from "../../../../../../apollo/mutations";

const BulkCreate = (props) => {
  const { setBanner } = props;
  const { history, cms } = useContext(PrivateContext) || {};
  const { gql } = constant;
  const [values, setValues] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [bulkProductUploadRequest, { loading: bulkUploadLoading }] = useMutation(BULK_UPLOAD);
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");
console.log("values", values);

  const getCSVData = (csvValue) => {
    const regexForEnterKey = /\n,|\n/gi;
    const csvDataAfterRegex = csvValue.data.replace(regexForEnterKey, "\n");
    return csvDataAfterRegex;
  };

  const onSubmit = async () => {
    if (!values.data) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.csvIsRequired"),
        status: "critical",
      });
      return;
    }
    const modifiedFormat = getCSVData(values);
    const requestData = {
      file: {
        file: modifiedFormat,
        fileName: values.fileName,
      },
    };
    try {
      const res = await bulkProductUploadRequest({
        variables: {
          input: requestData,
        },
      });

      const resData = baseHelper.getResponseData(res.data, gql.BULK_PRODUCT_CSV);
      let banner = {
        isOpen: true,
        status: "success",
        title: cms("section.bulk.message.success.title"),
        children: cms("section.bulk.message.success.content"),
        action: {
          content: cms("section.bulk.message.success.action"),
          onAction: () => history.push("/activity/bulk"),
        },
      };

      if (!resData) {
        const error = baseHelper.getResponseError(res.data, gql.BULK_PRODUCT_CSV);
        banner = {
          action: null,
          isOpen: true,
          status: "critical",
          title: error,
        };
      }
      setIsSubmitDisabled(true);
      setBanner(banner);
    } catch (error) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  const primaryAction = {
    content: cms("common.label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: "Cancel",
    onAction: () => setSheetActive(false),
  };
  const downloadCSV = () => {
    const csv = Baby.unparse(csvData);
    fileDownload(csv, constant.BULK_PRODUCT_CSV);
  };
  const learnMore = (title, content) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(content);
  };

  useEffect(() => {
    if (values.data) {
      setIsSubmitDisabled(false);
    }
    if (!values.data) {
      setIsSubmitDisabled(true);
    }
  }, [values]);

  return (
    <>
      <Card
        title={cms("section.bulk.title")}
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => learnMore(cms("section.bulk.title"), "TODO:"),
          },
        ]}
      >
        <Card.Section>
          <TextContainer>
            {cms("section.bulk.description")}
            <br />
            <Banner status="info">
              <p>Please use our sample CSV to get started. </p>
            </Banner>
          </TextContainer>
          <br />
          <FormLayout>
            <FileUpload setValue={setValues} getCSV={downloadCSV} values={values} />
          </FormLayout>
        </Card.Section>
      </Card>
      <PageActions
        primaryAction={{
          content: cms("section.bulk.button.primary"),
          id: "submitButton",
          onAction: () => onSubmit(),
          loading: bulkUploadLoading,
          disabled: bulkUploadLoading || isSubmitDisabled,
        }}
        secondaryActions={[
          {
            content: cms("common.button.cancel"),
            id: "cancelButton",
            onAction: () => history.push("/"),
          },
        ]}
      />
      <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {sheetContent}
      </Sheet>
    </>
  );
};

BulkCreate.propTypes = {
  setBanner: PropTypes.func.isRequired,
};

export default withFeature(BulkCreate, { feature: constant.PRODUCT_BULK_CREATE });

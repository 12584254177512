import { gql } from "apollo-boost";

const GET_PAYMENT_METRICS = gql`
  query getPaymentMetrics($input: GetPaymentMetrics) {
    getPaymentMetrics(input: $input) {
      data {
        payment {
          month
          count
        }
      }
      error
      status
    }
  }
`;
export { GET_PAYMENT_METRICS };

import React, { useState, useContext, useEffect } from "react";
import { Layout, Card, Stack, Tabs } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
// context
import { PrivateContext } from "lib/context";
// helper
import { baseHelper, imageHelper } from "lib/helpers";
// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// constant
import constant from "lib/constant/constant";
// components
import { Spinner, Banner } from "lib/components";
import NotFound from "layout/private/components/notFound/notFound";
// query
import { FETCH_VENDOR, BRAND_DETAILS } from "../../../../apollo/queries";
// sub Features
import { Payment, Shipping, Additional, OrderList, ProductList, SocialLink } from "./subFeatures";

// cms
import cms from "./cms/view";

const VendorView = () => {
  const { t } = useTranslation();
  const { match } = useContext(PrivateContext);
  const { id } = match.params;
  const { gql } = constant;
  const { brandTabs } = cms;
  const { data: fetchVendorData, loading: fetchVendorLoading } = useQuery(FETCH_VENDOR, {
    variables: { input: { _id: id } },
  });
  const { data: brandDetailData, loading: brandLoading } = useQuery(BRAND_DETAILS, {
    variables: { input: { vendorId: id } },
  });
  const noImageURL = t("label.advanceVendor.noImageURL");
  const [selectedTab, setSelectedTab] = useState(0);
  const [vendorData, setVendorData] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(false);
  const [brandImage, setBrandImage] = useState(noImageURL);
  const [isBrandImage, setIsBrandImage] = useState(false);
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  useEffect(() => {
    const resData = baseHelper.getResponseData(fetchVendorData, gql.GET_USER);
    if (!fetchVendorLoading && resData === false) {
      setIsNotFound(true);
      return;
    }
    const vendorDetail = {
      name: `${baseHelper.ucFirst(resData.firstName)} ${baseHelper.ucFirst(resData.lastName)}`,
      email: resData.email && resData.email.address,
      phoneNumber: resData.phoneNumber,
      address: `${resData.address} ${resData.city} ${resData.country} ${resData.pinCode}`,
      image: resData.image,
      brandName: resData.brandName,
    };
    setVendorData(vendorDetail);
    const vendorDetailsError = baseHelper.getResponseError(brandDetailData, gql.GET_BRAND_DETAILS_DATA);
    if (vendorDetailsError) {
      setBanner({ title: vendorDetailsError, status: "critical", isOpen: true });
      return;
    }
    const vendorDetailsData = baseHelper.getResponseData(brandDetailData, gql.GET_BRAND_DETAILS_DATA);
    const { vendorDetails: vendorDetailsValues } = vendorDetailsData || {};
    setVendorDetails(vendorDetailsValues);
    if (vendorDetailsValues && vendorDetailsValues.brand && vendorDetailsValues.brand.imageUrl) {
      setBrandImage(vendorDetailsValues.brand.imageUrl);
      setIsBrandImage(true);
    }
  }, [fetchVendorData, brandDetailData, gql.GET_BRAND_DETAILS_DATA, gql.GET_USER, fetchVendorLoading]);

  const handleTabChange = (selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  };
  const getContactDetails = () => {
    const classValue = (isBrandImage && t("label.advanceVendor.vendorImage")) || t("label.advanceVendor.vendorNoImage");
    const profileImage =
      (isBrandImage && imageHelper.resize({ url: brandImage, type: constant.imageTypes.PROFILE })) || brandImage;
    return (
      <>
        <Card sectioned>
          <Stack>
            <Stack.Item>
              <img src={profileImage} alt={`${vendorData.name} picture`} className={classValue} />
            </Stack.Item>
            <Stack.Item>
              <h2 className="heading">{t("label.advanceVendor.contactDetails")}</h2>
              <p className="Profile-details">
                <div className="content-text-length">
                  <i className="fas fa-user fa-xs" />
                  <span className="content-text">{vendorData.name}</span>
                </div>
              </p>
              <p className="Profile-details">
                <div className="content-text-length">
                  <i className="fas fa-envelope fa-xs" />
                  <span className="content-text">{vendorData.email}</span>
                </div>
              </p>
              <p className="Profile-details">
                <div className="content-text-length">
                  <i className="fas fa-phone fa-xs" />
                  <span className="content-text">{vendorData.phoneNumber}</span>
                </div>
              </p>
              <p className="Profile-details">
                <div className="content-text-length">
                  <i className="fas fa-map-marker-alt fa-xs" />
                  <span className="content-text">{vendorData.address}</span>
                </div>
              </p>
            </Stack.Item>
          </Stack>
        </Card>
        <br />
      </>
    );
  };
  if (fetchVendorLoading || brandLoading) {
    return <Spinner fontSize={42} />;
  }
  if (isNotFound) {
    return <NotFound />;
  }
  const showTabPages = () => {
    switch (selectedTab) {
      case 0:
        return <ProductList />;
      case 1:
        return <OrderList />;
      case 2:
        return <Payment vendorDetails={vendorDetails} />;
      case 3:
        return <Shipping vendorDetails={vendorDetails} />;
      case 4:
        return <SocialLink vendorDetails={vendorDetails} />;
      case 5:
        return <Additional vendorDetails={vendorDetails} />;
      default:
        return null;
    }
  };
  return (
    <>
      <Layout.Section>
        <Banner
          title={banner.title}
          status={banner.status}
          isOpen={banner.isOpen}
          onDismiss={() => setBanner({ isOpen: !banner.isOpen })}
        />
        <br />
        {getContactDetails()}
        <Card>
          <Tabs tabs={brandTabs} selected={selectedTab} onSelect={handleTabChange} />
          {showTabPages()}
        </Card>
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(VendorView), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
});

import Bowser from "bowser";
import QueryString from "query-string";
import cryptoJS from "crypto";
import { isObject, isUndefined, isEqual } from "lodash";
import moment from "moment";

// import config
import constant from "lib/constant/constant";
import config from "../../config";

// import helper
import accessHelper from "./access";

const { displayStatus, userKey } = constant;
const { newZeaLandPostAPIUrl } = config;
class BaseHelper {
  constructor() {
    this.sellerRole = "seller";
    this.vendorRole = "vendor";
  }

  fulfillmentDetails = (fulfillment, currentUser) => {
    if (!fulfillment) {
      return false;
    }

    const { _id } = currentUser;
    const id = this.mongoIdAsString(_id);
    const { consignment = {} } = fulfillment;
    const { global = {}, addresses = [] } = consignment || {};
    const vendorDetails =
      (addresses &&
        addresses.length &&
        addresses
          .map((item) => {
            const existVendor = item.vendorIds.find((vendorItem) => vendorItem === id);
            if (existVendor) {
              const { address } = item;
              return address;
            }
            return false;
          })
          .filter((item) => item)) ||
      false;

    let shippingDetails = {};

    if (vendorDetails && vendorDetails.length) {
      shippingDetails = vendorDetails[0] || {};
    } else {
      shippingDetails = global;
    }

    return shippingDetails;
  };

  /**
   * @desc To get current browser name
   */
  getBrowserName = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserName();
  };

  /**
   * @desc To get current browser version
   */
  getBrowserVersion = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserVersion();
  };

  /**
   * @desc To get user details for chat
   * @param currentUser
   *
   * @return userDetails consists name, email, role, plan name and plan price
   */
  getChatUser = (currentUser) => {
    const role = accessHelper.getUserRole(currentUser);
    const userEmail = currentUser.email && currentUser.email.address;
    const name = this.getFullName(currentUser);
    const plan = {
      name: (currentUser.plan && currentUser.plan.name) || "",
      price: (currentUser.plan && currentUser.plan.price) || "",
    };

    const { _id: currentUserId = null, createdAt = "" } = currentUser || {};

    const dateFreeTrialStarted = moment.utc(createdAt).format("YYYY-MM-DD");

    const userDetails = {
      name,
      plan,
      role,
      city: currentUser.city,
      country: currentUser.country,
      email: userEmail,
      id: this.mongoIdAsString(currentUserId),
      dateFreeTrialStarted,
    };

    return userDetails;
  };

  /**
   * @desc To get current domain
   *
   * @return domain
   *
   */
  getCurrentDomain = (location, string = "") => `${location.protocol}//${location.host}/${string}`;

  /**
   * @desc To return res data/false for given object
   * @param res
   *
   * @return res data/false
   *
   */
  getData = (res) => {
    if (isUndefined(res) || !res || res.status !== "ok") {
      return false;
    }
    return res.data;
  };
  /**
   * @desc To get param from url
   * @param params object
   * @param key string
   *
   * @return url
   *
   */

  getParam = (_ref12, key = "id") => {
    const { params } = _ref12;
    return (key && params[key]) || "";
  };

  /**
   * @desc To get full name of user
   * @param user object
   *
   * @return full name of user
   *
   */
  /* @desc To get price details of order
   * @param {Array} lineData array of order line items
   *
   * @return newPrice price upto 2 decimals
   *
   */
  getPrice = (price) => {
    const newPrice = parseFloat(price || 0).toFixed(2);
    return newPrice;
  };

  /**
 * @desc To get price details of order
 * @param {Array} lineData array of order line items

/**
 * @desc To get price upto 2 decimals
 * @param price price
 *
 * @return newPrice price upto 2 decimals
 *
 */
  getOrderPrice = (lineData = [], orderData, isSeller) => {
    let price = 0;
    let markUp = 0;
    let markUpItems = 0;
    let discount = 0;
    let sellerDiscount = 0;
    let tax = 0;
    let isUpdatedOrderLine = false;
    let commissionSeller = 0;
    let commissionVendor = 0;
    let shippingAmount = 0;
    let orderDiscount = 0;
    lineData
      .filter((item) => !!item)
      .forEach((data) => {
        price += parseFloat(data.price) * data.quantity;
        if (data.markUpPrice && data.markUpPrice !== "0" && data.markUpPrice !== 0) {
          markUpItems += 1;
        }
        markUp += parseFloat(data.markUpPrice) * data.quantity;
        discount += parseFloat(data.totalDiscount || 0);
        sellerDiscount += discount + parseFloat(data.sellerDiscount || 0);
        shippingAmount += parseFloat(data.shippingAmount || 0);
        commissionSeller += parseFloat(data.commissionSeller || 0);
        commissionVendor += parseFloat(data.commissionVendor || 0);
        tax += parseFloat(data.totalTax) * data.quantity;
        data.discountAllocations &&
          data.discountAllocations.forEach(discount => {
            orderDiscount += parseFloat(discount.amount || 0);
          });
        const hasTotalTaxKey = this.hasKey(data, "totalTax");
        if (hasTotalTaxKey) {
          // to check if order line data is based upon new markUp changes or not
          isUpdatedOrderLine = true;
        }
      });

    const orderShipping = shippingAmount || (orderData.shipping && orderData.shipping.amount);

    // shippingAmount = isSeller && orderData ? (orderData.shipping && orderData.shipping.amount) || 0 : shippingAmount;

    let totalPrice = isSeller
      ? parseFloat(orderShipping) + parseFloat(price) + parseFloat(markUp) - parseFloat(sellerDiscount || orderDiscount)
      : parseFloat(orderShipping) + parseFloat(price) - parseFloat(discount || orderDiscount);

    const isTaxIncluded = !!orderData.taxesIncluded;
    if (!isTaxIncluded) {
      totalPrice += isSeller ? parseFloat(orderData.totalTax) : parseFloat(tax);
    }

    return {
      commissionSeller,
      commissionVendor,
      discount: discount || orderDiscount,
      isUpdatedOrderLine,
      markUp,
      markUpItems,
      price,
      sellerDiscount: sellerDiscount || orderDiscount,
      shippingAmount: orderShipping,
      tax,
      totalPrice,
    };
  };

  /**
   * @desc To check, if the data object has the given key
   * @param data in which key will be checked
   * @param key to be checked
   *
   * @return {Boolean} check if the data object has the given key
   */
  hasKey = (data, key) => Object.prototype.hasOwnProperty.call(data, key);

  /**
   * @desc To address and city of user
   * @param user object
   *
   * @return object
   *
   */

  getFullName = (user) => {
    const { firstName = "", lastName = "" } = user;
    const fullName = `${(!!firstName && firstName) || ""} ${(!!lastName && lastName) || ""}`.trim();
    return fullName;
  };

  /**
   * @desc To get query params
   * @param url
   *
   * @return query params
   *
   */
  /**
   * @desc To address and city of user
   * @param user object
   *
   * @return object
   *
   */
  getFullAddress = ({ address, city, country, pinCode }) => {
    return {
      address,
      city,
      country,
      pinCode,
    };
  };

  getQueryParams = (url) => QueryString.parse(url);

  /**
   * @desc To generate random password
   *
   * @return password
   */
  generateRandomPassword = () => cryptoJS.randomBytes(8).toString("hex");

  /**
   * @desc To return data from the key object
   * @param res Object
   * @param key String/Array of String
   * @return data/false
   *
   */
  getResponseData = (res = false, key = "") => {
    if (!(res && key)) {
      return false;
    }

    if (Array.isArray(key)) {
      const result = {};
      key.forEach((item) => {
        let data = false;
        if (res[item] && res[item].status === "ok") {
          data = { ...res[item].data };
        }
        result[item] = data;
      });
      return result;
    }
    if (typeof key === "string" && res[key].status === "ok") {
      return res[key].data;
    }
    return false;
  };

  /**
   * @desc To get error message
   * @param err error object
   * @param res response object
   *
   * @return error
   *
   */
  getResponseError = (res, key) => {
    if (!(res && key && res[key] && res[key].status === "error")) {
      return false;
    }
    return res[key].error;
  };

  /**
   * @desc To get url of shop - shopify
   * @param shop
   * @param string - to append to url
   *
   * @return url
   *
   */
  getShopifyURL = (shop, string = "") => {
    const isValidShop = this.validShop(shop);
    const updatedShop = !isValidShop ? `${shop}.myshopify.com` : shop;
    const clientId = config.shopifyAPIKey;
    const url = `https://${updatedShop}/admin/apps${string ? `/${clientId}${string}` : ""}`;
    return url;
  };

  /**
   * @desc To get seller plan
   *
   * @param {Object} user User data
   *
   * @return {String} seller plan(free, bootstrap, growth, pro)
   */
  getSellerPlan = (user) => this.isSeller(user) && user.plan && user.plan.name;

  /**
   * @desc To return hex code of HSL values
   * @param h
   * @param s
   * @param l
   *
   * @return
   *
   */
  hslToHex = (h, s, l) => {
    const hue = h / 360;
    const saturation = s / 100;
    const lightness = l / 100;
    let r;
    let g;
    let b;
    if (s === 0) {
      r = g = b = lightness; // achromatic
    } else {
      const hue2rgb = (p, q, w) => {
        let t = w;
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
      const p = 2 * lightness - q;
      r = hue2rgb(p, q, hue + 1 / 3);
      g = hue2rgb(p, q, hue);
      b = hue2rgb(p, q, hue - 1 / 3);
    }
    const toHex = (x) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  hexToHSL = (hex) => {
    let hexCode = hex;
    hexCode = hexCode.replace("#", "");
    if (hexCode.length === 3) {
      hexCode = this.updateHexCodeValue(hexCode);
    }
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
    if (result && result.length === 4) {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);

      r /= 255;
      g /= 255;
      b /= 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h = (max + min) / 2;
      let s = (max + min) / 2;
      const l = (max + min) / 2;

      if (max === min) {
        h = 0;
        s = 0; // achromatic
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
          default:
            break;
        }
        h /= 6;
      }

      h = Math.round(360 * h);
      return {
        hue: h,
        saturation: s,
        brightness: l,
        alpha: 1,
      };
    }
    return "";
  };

  /**
   * @desc To check if seller is branded
   *
   * @return bool
   *
   */
  isBranded = (user) => {
    if (!user) return false;
    const userRole = (user.roles && user.roles.name) || "";
    return userRole === userKey.seller && user.plan && user.plan.isBranding;
  };

  /**
   * @desc To check if browser cookies are enabled or not
   *
   * @return {Boolean} true/false
   *
   */
  isCookieEnabled = () => !!(window && window.navigator && window.navigator.cookieEnabled);

  /**
   * @desc To check if inside iframe
   *
   * @return bool
   *
   */
  isIframe = () => window.self !== window.top;

  /**
   * @desc To check if current path is a load url
   *
   * @return {Boolean} true/false
   *
   */
  isLoadUrl = (pathName) => {
    const installUrls = ["/shopify/install", "/store/plan", "/store/load"];
    return installUrls.includes(pathName);
  };

  /**
   * @desc To check if user is on pro plan
   * @param {Object} plan current user plan object
   *
   * @return {Boolean} true/false
   *
   */
  isProPlan = ({ plan }) => {
    return plan && plan.name === "pro";
  };

  /**
   * @desc To check if user is on growth plan
   * @param {Object} plan current user plan object
   *
   * @return {Boolean} true/false
   *
   */
  isGrowthPlan = ({ plan }) => {
    return plan && plan.name === "growth";
  };

  /**
   * @desc To check if user is on growth and above plan
   * @param {Object} plan current user plan object
   *
   * @return {Boolean} true/false
   *
   */
  isGrowthAndAbovePlan = ({ plan }) => {
    return this.isGrowthPlan({ plan }) || this.isProPlan({ plan });
  };

  /**
   * @desc To check if user is system admin
   *
   * @return bool
   */
  isSystemAdmin = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    return userLevel === "system-admin";
  };

  /**
   * @desc To check if user is seller
   *
   * @return bool
   */
  isSeller = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    const userRole = user.parentUserRole || (user.roles && user.roles.name) || "";
    return userLevel === "account-admin" && userRole === "seller";
  };

  /**
   * @desc To check if user is vendor
   *
   * @return bool
   */
  isVendor = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    const userRole = user.parentUserRole || (user.roles && user.roles.name) || "";
    return userLevel === "account-admin" && userRole === "vendor";
  };

  /**
   * @desc To get valid mongo id in string
   * @param id
   *
   * @return valid mongo id
   *
   */
  mongoIdAsString = (_id) => (isObject(_id) ? _id.valueOf() : _id);

  /**
   * @desc Regex which validates whole number like: 1, 2, 9999, etc.
   * @param value number
   * @return Boolean
   *
   */
  validateWholeNumber = (value) => {
    const priceRegex = /^[0-9]+$/;
    return priceRegex.test(value);
  };

  /**
   * @desc Regex which validates positive numeric values and can take upto 2 decimal places like: 1, 2, 3.99, 3.8, etc.
   * @param value number
   * @return Boolean
   *
   */
  validatePositiveNumericValues = (value) => {
    const endsWithDot = /^[0-9]+(\.)?$/;
    const endsWithDecimalValue = /^[0-9]+(\.[0-9]{1,2})?$/; // taking upto 2 decimal places
    const result = endsWithDot.test(value) || endsWithDecimalValue.test(value);
    return result;
  };

  /**
   * @desc To get url of shop - shopify
   * @param shop
   * @param string - to append to url
   *
   * @return url
   *
   */
  shopifyUrl = (shop, string = "") => {
    let url = `https://${shop}/admin/apps/${config.shopifyAPIKey}`;
    url += string;
    return url;
  };

  /**
   * @desc To set query params
   * @param contains history object
   * @param queryParams object
   *
   * @return query params
   *
   */
  setUrl = (history, queryParams, state = {}) => {
    const { location } = history;
    const search = this.setQueryParams(location, queryParams);
    history.push(`${location.pathname}?${search}`, { ...state });
  };

  /**
   * @desc To decode uri string
   * @param string - encoded uri string
   *
   * @return decoded string
   */
  decodeURI = (string) => {
    return (string && decodeURIComponent(string).replace(/\+/g, " ")) || "";
  };

  /**
   * @desc To parse url encoded string, returns array
   * @param string
   * @param sep - default ','
   *
   * @return array
   *
   */
  parseUrlString = (string, sep = ",") => {
    const decodedURI = this.decodeURI(string) || "";
    const stringArr = (decodedURI && decodedURI.split(sep)) || [];
    return stringArr;
  };

  /**
   * @desc To get sort array alphabetically
   * if key is passed then sort using that key in array of objects.
   * @param {Array} array array to sort
   *
   * @return {Array} sorted array
   *
   */
  sort = (array = [], key = "") => {
    if (!key) {
      return array.sort(Intl.Collator().compare);
    }

    const compare = (item1, item2) => {
      const str1 = (item1[key] && item1[key].toLowerCase()) || "";
      const str2 = (item2[key] && item2[key].toLowerCase()) || "";

      return (str1 > str2 && 1) || (str1 < str2 ? -1 : 0);
    };

    return array.sort(compare);
  };

  /**
   * @desc To parse url, returns all url properties like: hostname, protocol etc
   * @param {String} uri
   *
   * @return array
   *
   */
  parseUrl = (uri) => {
    const url = new URL(uri);
    return url;
  };

  /**
   * @desc To return hsl value of hex code
   * @param hex
   *
   * @return
   *
   */
  updateHexCodeValue = (hex) =>
    hex
      .split("")
      .map((value) => value + value)
      .join("");

  /**
   * @desc To return shopify shop name (excluding .myshopify.com)
   * @param shop shopify store name
   *
   * @return shop/false
   *
   */
  validShop = (shop) => {
    if (!shop) {
      return false;
    }

    const isValidShop = shop.endsWith(".myshopify.com");
    const shopIndex = shop.indexOf(".myshopify.com");

    if (shopIndex <= -1 || !isValidShop) {
      return false;
    }

    return shop.slice(0, shopIndex);
  };

  /**
   * @desc replaces the multiple occurrence of multiple words in the string
   * @param string the target string which is being modified
   * @param replacementList the array of objects having key and value where
   * key is the string which is being replace with value
   *
   * @return string
   *
   */
  wordReplacer = (string, replacementList) => {
    let newString = string;
    replacementList.forEach(({ key, value }) => {
      const regex = new RegExp(key, "g");
      newString = newString.replace(regex, value);
    });
    return newString;
  };

  /**
   * @desc To create action button
   *
   * @return banner button
   *
   */
  bannerButtonAction = (showLink = false, link = false, history = false) => {
    if (!(showLink && link && link.message && link.to)) {
      return null;
    }

    const pattern = /^((http|https|ftp):\/\/)/;
    const nextPath = (e, path) => {
      e.preventDefault();
      if (pattern.test(path)) {
        window.open(path);
      } else if (history) {
        history.push(path);
      }
    };
    const action = {
      content: link.message,
      onAction: (e) => nextPath(e, link.to),
    };
    return action;
  };

  /**
   * @desc To compare to items
   *
   * @desc Item can be object, array, strings, booleans, integers, etc
   *
   * @return boolean
   *
   */
  isItemEqual = (item1, item2) => isEqual(item1, item2);

  /**
   * @desc To get formatted CMS data
   *
   * @param object cmsData
   * @return object formatted CMS data
   */
  getFormattedCMS = (cmsData = {}) => {
    const formattedCMSData = {};
    const languageCode = "en";
    Object.keys(cmsData).forEach((key) => {
      if (cmsData[key] && Array.isArray(cmsData[key])) {
        const keyData = {};
        cmsData[key].forEach((element) => {
          keyData[element.code] = element[languageCode];
        });
        formattedCMSData[key] = keyData;
      }
    });
    return formattedCMSData;
  };

  /**
   * @desc To get query params
   * @param location
   *
   * @return query params
   *
   */
  queryParamsFromLocation = ({ location }) => {
    const search = (location && location.search) || "";
    const dataToFetch = (search && this.getQueryParams(search)) || {};

    return dataToFetch;
  };

  /**
   * @desc To set query params
   * @param location object
   * @param queryParams object
   *
   * @return query params
   *
   */
  setQueryParams = (location, queryParams) => {
    let { search } = location; // could be '?foo=bar'
    const trimmedSearch = search.trim();
    const params = new URLSearchParams(trimmedSearch);

    Object.keys(queryParams).forEach((key) => {
      params.set(key, queryParams[key]);
    });
    search = params.toString();
    return search.replace("%40", "@");
  };

  /* @desc To check if valid url
   * @param url
   *
   * @return bool
   *
   */
  isValidUrl = (url) => {
    // eslint-disable-next-line max-len
    const validURLRegex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return validURLRegex.test(url);
  };

  /**
   * @desc To get badge type
   * @param value status
   *
   * @return array
   *
   */
  getBadgeType = (value) => {
    const badgeType = {
      accepted: "success",
      alpha: "attention",
      appinstall: "success",
      approved: "success",
      appuninstall: "warning",
      beta: "info",
      create: "attention",
      completed: "success",
      closed: "",
      dispute: "attention",
      error: "warning",
      firstinvite: "info",
      firstlogin: "info",
      firstorder: "info",
      firstproduct: "info",
      firstvendor: "info",
      fulfilled: "",
      invited: "info",
      joined: "success",
      live: "success",
      "needs review": "attention",
      new: "info",
      onhold: "warning",
      paid: "success",
      pending: "attention",
      progress: "attention",
      published: "success",
      rejected: "warning",
      revoked: "warning",
      sellersignup: "attention",
      shipped: "new",
      suppliersignup: "attention",
      synced: "success",
      unfulfilled: "attention",
      unpaid: "info",
    };

    const retVal = value ? badgeType[value.toLowerCase()] : "";
    return retVal || value;
  };

  /**
   * @desc To format date
   * @param date
   *
   * @return date
   *
   */
  formatDate = (date, isTimeStamp = true) => {
    if (!date) return "";
    return isTimeStamp
      ? `${moment(date).format("LL")}  at ${moment(date).format("h:mma")} (GMT${moment().format("Z")})`
      : `${moment(date).format("LL")}`;
  };

  lastUpdateDate = (lastUpdatedDate) => {
    const lastDate = this.formatDate(lastUpdatedDate);
    return `Last updated at: On ${lastDate}`;
  };

  /**
   * @desc To return first letter in upper case
   * @param string
   *
   * @return string
   *
   */
  ucFirst = function ucFirst(string) {
    if (!string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  /* @desc To get current selected language from code
   *
   * @param string langCode (en, es, fr, etc)
   * @return string Language (English, Spanish, French, etc)
   */

  getLanguage = (langCode) => {
    const language = {
      en: "English",
      es: "Spanish",
    };
    return language[langCode];
  };

  /**
   * @desc To return five digit Random Number with Product Title
   *
   * @return string Language (English, Spanish, French, etc)
   */
  customHandleGenerator = (productTitle) => {
    // e.g mc-(5 digit random)-product-title
    const fiveDigitRandomNum = Math.floor(100000 + Math.random() * 900000);
    const Handler = `mc-${fiveDigitRandomNum}-${productTitle
      .trim()
      .replace(/[^\w\s]/gi, "")
      .replace(/\s+/g, "-")
      .toLowerCase()}`;
    return Handler;
  };

  /* @desc To convert values of object key string to Integer.
   *
   * @param object objData
   * @return object keyData ({abc:10}) here abc is a key in objData and 10 is default value.
   * if condition false it return default value
   */
  convertToInt = (objData, keyData) => {
    const prevObj = objData;
    if (Object.keys(prevObj).length > 0) {
      Object.keys(keyData).map((key) => {
        if (Object.keys(prevObj).length > 0 && Object.prototype.hasOwnProperty.call(prevObj, `${key}`)) {
          const convertedVal =
            (Object.prototype.hasOwnProperty.call(prevObj, `${key}`) && parseInt(prevObj[key], 10)) || keyData[key];
          prevObj[key] = convertedVal;
        }
        return prevObj;
      });
    }
    return prevObj;
  };

  /**
   * @desc To transform brand handle
   * @param value
   *
   * @return brandHandle
   *
   */
  generateBrandHandle = (value) => {
    const brandHandle = value
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9-_ ]/g, "")
      .replace(/ /g, "-");
    return brandHandle;
  };

  /**
   * @desc To get badge status
   * @param value status
   *
   * @return array
   *
   */
  getBadgeStatus = (value) => {
    const retVal = value ? displayStatus[value.toUpperCase()] : "";
    return retVal || value;
  };

  /**
   * @desc To get value in Interger
   * @param value numeric or string
   *
   * @return parsed value if given value is string then return 0 if not return Interger value.
   *
   */
  getInterger = (value, base) => {
    const parsed = parseInt(value, base);
    if (Number.isNaN(parsed)) {
      return 0;
    }
    return parsed;
  };

  /**
   * @desc To get initial letter  of string.
   * @param name contains string
   *
   * @return name
   */
  getInitials = (name) => {
    return name
      .split(" ")
      .map((surnameOrFamilyName) => {
        return surnameOrFamilyName.slice(0, 1).toUpperCase();
      })
      .join("");
  };

  /**
   * @desc To address and city of user
   * @param user object
   *
   * @return object
   *
   */
  getFullAddress = function getAddress({ address, city, country, pinCode }) {
    const addressFormatted = address ? `${address}, ` : "";
    return {
      addressFormatted,
      city,
      country,
      pinCode,
    };
  };

  /**
   * @desc To parse the data
   * @param data contains json data
   *
   * @return data
   */
  parseData = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  /**
   * @desc To cloneData the data
   * @param data contains json data
   *
   * @return data
   */
  cloneData = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  /**
   * @desc To removeDuplicate data from array of objects
   * @param data contains array of object
   *
   * @return data
   */
  removeDuplicateData = (data) =>
    data.filter((item, idx, self) => idx === self.findIndex((subItem) => subItem.id === item.id));

  /**
   * @desc To get price upto 2 decimals
   * @param price price
   *
   * @return newPrice price upto 2 decimals
   *
   */
  getPrice = (price) => {
    const newPrice = parseFloat(price || constant.value.ZERO).toFixed(2);
    return newPrice;
  };

  formatPrice = (price) => {
    const newPrice = parseFloat(price || 0).toFixed(2);
    return newPrice;
  };

  /**
   * @desc To check valid PDF, DOC and DOCX link
   * @param link link
   *
   * @return bool
   *
   */

  isValidDocLink = (link) => {
    // eslint-disable-next-line no-useless-escape
    const pdfRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+?\.pdf$/gm;
    // eslint-disable-next-line no-useless-escape
    const docRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+?\.doc$/gm;
    // eslint-disable-next-line no-useless-escape
    const docxRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+?\.docx$/gm;
    const isPdfLink = pdfRegex.test(link);
    const isDocLink = docRegex.test(link);
    const isDocxLink = docxRegex.test(link);
    return isPdfLink || isDocLink || isDocxLink;
  };

  /**
   * @desc To get template tags value
   * @param tags
   *
   * @return tagsFound
   *
   */
  templateTags = function templateOptions(content) {
    const regularExp = /{{\w+}}/g;

    const tagsFound = content.match(regularExp) || []; // an array to collect the strings that are found in content

    return tagsFound;
  };

  /**
   * @desc To validate email content
   * @param actualTags
   * @param foundTags
   *
   * @return isValidContent
   *
   */
  validateTags = function validateTags(actualTags, foundTags) {
    let isValidContent = true;
    const sortActualTags = actualTags.sort();
    const sortFoundTags = foundTags.sort();
    if (JSON.stringify(sortActualTags) !== JSON.stringify(sortFoundTags)) {
      isValidContent = false;
    }

    return isValidContent;
  };

  /**
   * @desc To get description message
   * @param value activity
   *
   * @return array
   *
   */
  activityDescription = function activityDescription({ entity, entityType, status, error, t }) {
    if (!entity) {
      return "No Error";
    }
    const baseCms = "sectionContent.activityView.".trim();

    switch (entityType) {
      case "products":
        if (status === "error" || status === "duplicate") {
          return `${t(`${baseCms}product.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${t(`${baseCms}product.success`, { entity: `${entity}` })}`;
      case "suppliers":
        if (status === "error" || status === "duplicate") {
          return `${t(`${baseCms}supplier.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${t(`${baseCms}supplier.success`, { entity: `${entity}` })}`;
      case "invite":
        if (status === "error" || status === "duplicate") {
          return `${t(`${baseCms}invite.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${t(`${baseCms}invite.success`, { entity: `${entity}` })}`;
      case "inviteCsv":
        if (status === "error" || status === "duplicate") {
          return `${t(`${baseCms}inviteCsv.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${t(`${baseCms}inviteCsv.success`, { entity: `${entity}` })}`;
      case "stripe":
        if (status === "error" || status === "duplicate") {
          return `${t(`${baseCms}stripe.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${t(`${baseCms}stripe.success`, { entity: `${entity}` })}`;
      case "paypal":
        if (status === "error" || status === "duplicate") {
          return `${t(`${baseCms}paypal.failure`, { entity: `${entity}`, error: `${error}` })}`;
        }
        return `${t(`${baseCms}paypal.success`, { entity: `${entity}` })}`;
      default:
        return `${entity}`;
    }
  };

  /**
   * @desc Returns newZealand post api url
   *
   * @return string
   *
   */
  getNewZeaLandPostAPIUrl = (consignmentId) => {
    const url = `${newZeaLandPostAPIUrl}parcellabel/v3/labels/${consignmentId}?format=PDF`;
    return url;
  };

  /**
   * @desc Returns is volumetric weight is large or not
   *
   * @return boolean
   *
   */
  isLargeVolumetricWeight = (length, width, height) => {
    let weight = (length * width * height) / 5000;
    weight = weight.toFixed(2);
    const splitWeight = weight.toString().split(".");
    let number = weight;
    if (splitWeight && splitWeight.length) {
      number = splitWeight && splitWeight[1];
      number /= 10;
      number = Math.round(number);
      if (number > 5) {
        weight = Math.round(weight);
      }
    }
    if (weight > 180) {
      return true;
    }
    return false;
  };

  /* @desc To check if valid domain
   * @param shopDomain
   *
   * @return bool
   *
   */
  isValidSubDomain = (shopDomain) => {
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
    return domainRegex.test(shopDomain);
  };
}

export default new BaseHelper();

import React, { useState, useContext, useEffect } from "react";
import { Layout, PageActions, List, Card, Checkbox } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import components
import { Banner, Spinner } from "lib/components";

// import helpers
import baseHelper from "lib/helpers/base";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import from gql
import { UPDATE_MAINTENANCE } from "app/userManagement/apollo/mutation";
import { GET_MAINTENANCE } from "app/userManagement/apollo/queries";

const { gql } = constant;

const AdminMaintenance = () => {
  const { history } = useContext(PrivateContext);
  const { t } = useTranslation();
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const [setUpdateMaintenance, { loading }] = useMutation(UPDATE_MAINTENANCE);
  const { loading: maintenanceLoading, error, data } = useQuery(GET_MAINTENANCE);
  const [checkedVal, setChecked] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);
  const [values, setValues] = useState({
    isApp: false,
    isSite: false,
  });
  const handleChange = (value) => {
    setCheckedValue(value);
    setValues((prevValues) => ({
      ...prevValues,
      isSite: value,
    }));
  };
  const handleCheck = (value) => {
    setChecked(value);
    setValues((prevValues) => ({
      ...prevValues,
      isApp: value,
    }));
  };
  const onSubmit = async () => {
    try {
      const res = await setUpdateMaintenance({
        variables: {
          input: {
            maintenance: {
              ...values,
            },
          },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_MAINTENANCE);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_MAINTENANCE);
        setBannerStatus({
          status: "critical",
          title: errorResponse,
          isOpen: true,
        });
      }
      setBannerStatus({
        status: "success",
        title: t("message.SavedSuccessfully"),
        isOpen: true,
      });
    } catch (err) {
      setBannerStatus({
        isOpen: true,
        status: "critical",
        title: t("error.common.somethingWentWrong"),
      });
    }
  };

  useEffect(() => {
    if (error) {
      setBannerStatus({
        isOpen: true,
        status: "critical",
        title: t("error.common.somethingWentWrong"),
      });
    }
  }, [error, t]);

  useEffect(() => {
    if (data) {
      const responseData = baseHelper.getResponseData(data, gql.GET_MAINTENANCE);
      if (!responseData) {
        const responseError = baseHelper.getResponseError(data, gql.GET_MAINTENANCE);
        setBannerStatus({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      setCheckedValue(responseData.isSite);
      setChecked(responseData.isApp);
    }
  }, [data]);

  if (maintenanceLoading) {
    return <Spinner />;
  }

  const standardLists = [
    `${t("maintenanceCaptions.App_Under_Maintenance")}`,
    `${t("maintenanceCaptions.Site_Under_Maintenance")}`,
  ];

  const renderCaptions = () => {
    return standardLists.map((listItem) => <List.Item key={listItem}>{listItem}</List.Item>);
  };
  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });

  return (
    <>
      <Layout style={{ opacity: loading ? 1 : 0.5 }}>
        {bannerStatus.isOpen && (
          <Layout.Section>
            <Banner
              isOpen={bannerStatus.isOpen}
              status={bannerStatus.status}
              title={bannerStatus.title}
              onDismiss={() => dismissBanner()}
            />
          </Layout.Section>
        )}
        <Layout.AnnotatedSection title={t("label.Maintenance_Title")} description={t("label.Maintenance_Description")}>
          <Layout.Section>
            <Card sectioned>
              <List type="bullet">{renderCaptions()}</List>
              <Checkbox
                label={t("label.App_Under_Maintenance")}
                checked={checkedVal}
                onChange={handleCheck}
                id="autoVerifyCheckBox"
              />
              <br />
              <Checkbox
                label={t("label.Site_Under_Maintenance")}
                checked={checkedValue}
                onChange={handleChange}
                id="readOnlyCheckbox"
              />
            </Card>
          </Layout.Section>
        </Layout.AnnotatedSection>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: t("common.Submit"),
              onAction: () => onSubmit(),
              loading,
            }}
            secondaryActions={[
              {
                content: t("common.Cancel"),
                onAction: () => history.push("/"),
              },
            ]}
          />
        </Layout.Section>
      </Layout>
    </>
  );
};

export default AdminMaintenance;

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-apollo";
import { Tabs, Card, Layout } from "@shopify/polaris";

// import hoc
import { PrivateContext } from "lib/context";
import { withFeature, withErrorBoundary } from "lib/hoc";

// import form lib
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { ResourceList, Banner } from "lib/components";

// import GQL
import { FETCH_PRODUCT_LIST } from "app/advanceVendor/apollo/queries";
import BULK_PRODUCT from "app/advanceVendor/apollo/mutations";
import productListCMS from "./cms/productList";

// import subFeature
import ProductListView from "./subFeatures/productListView";
import MoreFilters from "./moreFilters/moreFilters";

const AdvanceOperatorProductList = () => {
  const { history, match } = useContext(PrivateContext);
  const prevAppliedFilter = baseHelper.queryParamsFromLocation(history);
  const { t } = useTranslation();
  const { tabs, sortOptions, resourceName } = productListCMS(t);
  const selectedSort = prevAppliedFilter.sort_name && `${prevAppliedFilter.sort_name}_${prevAppliedFilter.sort_order}`;

  // react state
  const [queryValue, setQueryValue] = useState(prevAppliedFilter.search || null);
  const [selected, setSelected] = useState(parseInt(prevAppliedFilter.tab, 10) || 0);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevAppliedFilter.perPage, 10) || 10);
  const [currentPage, setCurrentPage] = useState(parseInt(prevAppliedFilter.page, 10) || 1);
  const [productList, setProductList] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [sortValue, setSortValue] = useState(selectedSort || "createdAt_desc");
  const [selectedItemStatus, setSelectedItemStatus] = useState(false);
  const [taggedWith, setTaggedWith] = useState(prevAppliedFilter.list_search || null);
  const [selectedFilter, setSelectedFilter] = useState(prevAppliedFilter.list_filter || null);
  const [dataToFetch, setDataToFetch] = useState({
    search: prevAppliedFilter.search,
    list_filter: prevAppliedFilter.list_filter,
    list_search: prevAppliedFilter.list_search,
    filter: prevAppliedFilter.filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevAppliedFilter.sort_name || "createdAt",
    sort_order: prevAppliedFilter.sort_order || "desc",
    vendorId: match.params.id,
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const inputData = {
    search: dataToFetch.search,
    list_filter: dataToFetch.list_filter,
    list_search: dataToFetch.list_search,
    filter: dataToFetch.filter,
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
    vendorId: dataToFetch.vendorId,
  };
  // GQL:Operations
  const { error: errorProductList, loading: loadingProductList, data: dataProductList } = useQuery(FETCH_PRODUCT_LIST, {
    variables: {
      input: inputData,
    },
  });

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT, {
    refetchQueries: [
      {
        query: FETCH_PRODUCT_LIST,
        variables: {
          input: inputData,
        },
      },
    ],
  });

  useEffect(() => {
    setSelectedItems([]);
    const productListResponseResponseData = baseHelper.getResponseData(dataProductList, constant.gql.GET_PRODUCT_LIST);
    const productListResponseResponseError = baseHelper.getResponseError(
      dataProductList,
      constant.gql.GET_PRODUCT_LIST
    );
    if (productListResponseResponseData) {
      setProductList(productListResponseResponseData.productList);
      setTotalProductCount(productListResponseResponseData.count);
    }
    if (productListResponseResponseError) {
      setBanner({ status: "critical", title: productListResponseResponseError, isOpen: true });
    }
  }, [dataProductList]);

  useEffect(() => {
    if (errorProductList) {
      setBanner({ status: "critical", title: t("error.common.somethingWentWrong"), isOpen: true });
    }
  }, [errorProductList, t]);

  const handleQueryValueChange = (value) => {
    setQueryValue(value);
    baseHelper.setUrl(history, { search: value });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleQueryValueRemove = () => {
    setQueryValue(null);
    baseHelper.setUrl(history, { search: "" });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: value });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handlePage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleTabChange = (selectedTabIndex) => {
    const tabName = tabs[selectedTabIndex].value;
    setSelected(selectedTabIndex);
    baseHelper.setUrl(history, { filter: tabName, tab: selectedTabIndex });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      setSortValue(selectedItem);
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      const filterApplied = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filterApplied });
    }
  };
  const bulkAction = async (
    eventKey,
    deleteFromShopify = false,
    id = false,
    reason = "",
    selectedStatusItem = false
  ) => {
    if (selectedStatusItem) {
      setSelectedItemStatus(selectedStatusItem);
    }
    let formData = {};
    formData = {
      key: eventKey,
      ids: selectedItems,
      all: totalProductCount === selectedItems.length,
      deleteFromShopify,
      reason,
    };
    if (id) {
      formData.ids = [id];
      formData.all = false;
    }
    try {
      const response = await bulkProductActions({
        variables: { input: { ...formData } },
      });
      setSelectedItems([]);
      const responseData = baseHelper.getResponseData(response.data, constant.gql.BULK_PRODUCT);
      const responseDataError = baseHelper.getResponseError(response.data, constant.gql.BULK_PRODUCT);
      if (responseData) {
        let title = t("message.productList.requestSuccess");
        if (selectedItemStatus.toLowerCase() === constant.APPROVED.toLowerCase() && eventKey === "reject") {
          title = t("message.productList.requestRejectSuccess");
        }
        setBanner({ isOpen: true, title, status: "success" });
        setSelectedItemStatus(false);
      }
      if (responseDataError) {
        let title = responseDataError;
        if (selectedItemStatus.toLowerCase() !== constant.NEW.toLowerCase() && eventKey === "approve") {
          title = t("message.productList.alreadyPush");
        }
        setBanner({ isOpen: true, title, status: "critical" });
        setSelectedItemStatus(false);
      }
    } catch (error) {
      setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
    }
    setSelectedItemStatus(false);
  };

  const handleTaggedWithRemove = () => {
    setTaggedWith("");
    setSelectedFilter("");
    baseHelper.setUrl(history, { list_search: "", list_filter: "", page: 1 });
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  const handleClearAll = () => {
    handleQueryValueRemove();
    handleTaggedWithRemove();
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = !isEmpty(taggedWith)
    ? [{ key: "taggedWith", label: disambiguateLabel("taggedWith", taggedWith), onRemove: handleTaggedWithRemove }]
    : [];

  const filters = [
    {
      key: t("taggedWith"),
      label: t("label.productList.filterBy"),
      filter: (
        <MoreFilters
          dataToFetch={dataToFetch}
          setDataToFetch={setDataToFetch}
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
  ];
  const renderItem = (item, _, keyIndex) => {
    return <ProductListView item={item} keyIndex={keyIndex} bulkAction={bulkAction} loading={loadingBulkProduct} />;
  };
  return (
    <>
      <Layout>
        {banner.isOpen && (
          <>
            <Layout.Section>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
              />
            </Layout.Section>
            <br />
          </>
        )}
        <Layout.Section>
          <Card>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <ResourceList
                resourceName={resourceName}
                items={productList || []}
                idForItem={(products) => {
                  const { productId: id, status } = products;
                  setSelectedItemStatus(status);
                  return id;
                }}
                selectedItems={selectedItems}
                renderItem={renderItem}
                loading={loadingProductList}
                onQueryChange={handleQueryValueChange}
                onQueryClear={handleQueryValueRemove}
                handleClearAll={handleClearAll}
                queryValue={queryValue}
                sortValue={sortValue}
                sortOptions={sortOptions}
                onSortChange={sortFilter}
                count={totalProductCount}
                page={currentPage}
                perPage={selectedLimit}
                setPerPage={handleSelectLimit}
                setPage={handlePage}
                filters={filters}
                appliedFilters={appliedFilters}
              />
            </Tabs>
          </Card>
        </Layout.Section>
      </Layout>
    </>
  );
};

export default withFeature(withErrorBoundary(AdvanceOperatorProductList), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
});

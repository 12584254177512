import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, TextField, PageActions } from "@shopify/polaris";

// import helpers
import { baseHelper } from "lib/helpers";

// import constants
import constant from "lib/constant/constant";

// import contexts
import { PublicContext } from "lib/context";

// import helper components
import { Banner } from "lib/components";

// import gql
import { RESEND_VERIFY_EMAIL } from "app/public/apollo/mutations";

// import yup validation
import validate from "../../yup/validate";

const ResendVerifyEmail = () => {
  const { cms, history } = useContext(PublicContext);
  const { EMAIL, gql } = constant;

  // states
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isReadyToSubmit, setSubmitState] = useState(false);

  // gql states
  const [resendVerificationEmail, { loading }] = useMutation(RESEND_VERIFY_EMAIL);

  const handleValidation = async () => {
    const validationError = await validate(EMAIL, email, cms);
    setErrorMessage(validationError);
    setSubmitState(email && !validationError);
  };

  const onSubmit = async () => {
    if (!isReadyToSubmit) {
      return;
    }
    let bannerContent = {};
    try {
      const result = await resendVerificationEmail({
        variables: { input: { email } },
      });
      bannerContent = {
        isOpen: true,
        status: "success",
        title: cms("message.success"),
      };
      const errorResponse = baseHelper.getResponseError(result.data, gql.RESEND_EMAIL);
      if (errorResponse) {
        bannerContent = { isOpen: true, status: "critical", title: errorResponse };
      }
      setBanner(bannerContent);
      setEmail("");
    } catch (error) {
      bannerContent = { isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") };
      setBanner(bannerContent);
    }
  };

  const dismissBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  return (
    <>
      <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={() => dismissBanner()} />
      <br />
      <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
        <Card sectioned>
          <FormLayout>
            <TextField
              id="email"
              type="email"
              placeholder={cms("common.placeholder.emailAddress")}
              value={email}
              onChange={setEmail}
              onBlur={handleValidation}
              error={errorMessage}
            />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            id: "resendVerifyEmail",
            content: cms("button.primary"),
            onAction: () => onSubmit(),
            disabled: !isReadyToSubmit,
            loading,
          }}
          secondaryActions={{
            id: "cancel",
            content: cms("common.button.cancel"),
            onAction: () => history.push("/login"),
          }}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default ResendVerifyEmail;

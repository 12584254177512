import React, { useState, useContext, useEffect } from "react";
import { Tabs } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { storageHelper } from "lib/helpers";
import { Spinner, SkeletonAnnotated } from "lib/components";

// sub features
import { Welcome, UpdateProfile, AddProduct, Finish } from "./features";

import tabs from "./stepperOnboardingConfig";

const { onboardingSteps } = constant;
const { WELCOME, UPDATE_PROFILE, ADD_PRODUCT, FINISH } = onboardingSteps;
const ProviderStepperOnboarding = () => {
  const { isLoading, setOnboardingTab } = useContext(PrivateContext);
  const initialSelectedTab = storageHelper.get("setupProgress") || "welcome";

  const indexToTabKeyMapper = {
    welcome: WELCOME,
    product: ADD_PRODUCT,
  };

  const findTab = (key) => tabs.find((tab) => tab.id === key);
  const tabKey = indexToTabKeyMapper[initialSelectedTab];
  const currentTab = findTab(tabKey);

  // states
  const [selected, setSelected] = useState(tabKey);
  const [selectedTabIndex, setSelectedTabIndex] = useState(currentTab.tabIndex);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [selected]);

  const changeTab = (value) => {
    setSelected(value);
    const activeTab = findTab(value);
    setSelectedTabIndex(activeTab.tabIndex);
    // HACK: We can do better than this, context: Manage cms on basis of onboarding tab change
    if (value !== FINISH) {
      setOnboardingTab(value);
    }
  };

  const getOnboardingStep = () => {
    if (isLoading) {
      if (selected === WELCOME || selected === FINISH) {
        return <Spinner />;
      }
      return <SkeletonAnnotated primaryLines={15} />;
    }
    switch (selected) {
      case WELCOME:
        return <Welcome setSelectedTab={changeTab} />;
      case UPDATE_PROFILE:
        return <UpdateProfile setSelectedTab={changeTab} />;
      case ADD_PRODUCT:
        return <AddProduct setSelectedTab={changeTab} />;
      case FINISH:
        return <Finish />;
      default:
        return null;
    }
  };

  const topMargin25px = {
    marginTop: "25px",
  };
  return (
    <>
      <Tabs tabs={tabs} selected={selectedTabIndex} fitted />
      <div style={topMargin25px}>{getOnboardingStep()}</div>
    </>
  );
};

export default ProviderStepperOnboarding;

import React, { useContext, useState, useCallback } from "react";
import _ from "lodash";
import {
  Badge,
  Button,
  Caption,
  Collapsible,
  Heading,
  Link,
  ResourceItem,
  SkeletonThumbnail,
  Stack,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import {
  DeleteMinor,
  EditMinor,
  ImportStoreMajorMonotone,
  CircleCancelMajorMonotone,
  CircleTickMajorMonotone,
  ViewMinor,
  UndoMajorMonotone,
  ListMajorMonotone,
} from "@shopify/polaris-icons";

import ProductVersioning from "./productVersioning";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { PrivateContext } from "lib/context";
import { baseHelper, imageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import LazyLoad from "react-lazyload";
import Popover from "lib/components/popover/popover";

const ProductListView = (props) => {
  const {
    keyIndex,
    item,
    bulkAction,
    handleRejectModal,
    loadingBulkProduct,
    handleDeleteModal,
    activePopover,
    setActivePopover,
    setListBanner,
    active,
    setActive,
  } = props;
  const { currentUser, history } = useContext(PrivateContext);
  const {
    _id: id,
    productId,
    title,
    vendor,
    createdAt,
    updatedAt,
    published,
    status,
    images,
    vendorId,
    variants = [],
  } = item;

  const size = constant.LARGE;
  const isUpdated = !(createdAt === updatedAt);
  const isShowUpdatedDate = ![constant.NEEDS_REVIEW.toLowerCase(), constant.NEW.toLowerCase()].includes(
    status.toLowerCase()
  );
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  const isSystemAdmin = baseHelper.isSystemAdmin(currentUser);
  const productID = isSeller ? productId : id;
  const thumbnailImage =
    images.length > 0
      ? imageHelper.resize({ url: images[0].imageUrl, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
  const thumbnail = <Thumbnail source={thumbnailImage} size={size} alt={`Image of ${title}`} />;
  const skeletalThumbnail = <SkeletonThumbnail size={size} />;
  const media = (
    <LazyLoad once height={80} offset={100} placeholder={skeletalThumbnail}>
      {thumbnail}
    </LazyLoad>
  );
  const { t } = useTranslation();
  const statusType = status.toLowerCase() === constant.APPROVED.toLowerCase() ? constant.PUBLISHED : status;
  const badgeStatus = baseHelper.ucFirst(baseHelper.getBadgeStatus(statusType));
  const badgeType = baseHelper.getBadgeType(status);

  const topMargin10px = {
    marginTop: "10px",
  };

  const isAvailablePublishment = _.isBoolean(published);

  const handleToggle = useCallback(() => setActive((active) => !active), []);

  const handleSelect = (status) => {
    if (status === constant.VIEW_ID) {
      history.push(`${constant.PRODUCT_URL_UPDATE}${productID}`);
    }

    if (status === constant.REVIEW_ALL) {
      history.push(`${constant.PRODUCT_URL_REVIEW}${productID}`);
    }

    if (status === constant.PUSH_TO_STORE_ID) {
      setSelectedButtonIndex(productID);
      bulkAction(constant.APPROVE, false, productID, "", null, constant.NEW);
    }

    if (status === constant.UNDELETE) {
      handleRejectModal(constant.UNDELETE, productID, status);
    }

    if (status === constant.UNREJECT) {
      handleRejectModal(constant.UNREJECT, productID, status);
    }

    if (status === constant.REJECT) {
      handleRejectModal(constant.REJECT, productID, status);
    }

    if (status === constant.RE_SUBMIT) {
      handleRejectModal(constant.RE_SUBMIT, productID, status);
    }

    if (status === constant.DELETED) {
      handleDeleteModal(constant.DELETE.toLowerCase(), productID, status);
    }

    // if(status === "available"){
    //   bulkAction(constant.PUBLISH, false, productID);
    // }

    // if(status === "unavailable"){
    //   bulkAction(constant.UNPUBLISH, false, productID);
    // }
  };

  const pushToStore = {
    content: constant.PUSH_TO_STORE_LABEL,
    value: constant.PUSH_TO_STORE_ID,
    disabled: false,
    icon: ImportStoreMajorMonotone,
    onAction: () => handleSelect(constant.PUSH_TO_STORE_ID),
  };
  const review = {
    content: constant.displayStatus.NEEDSREVIEW,
    value: constant.REVIEW_ALL,
    disabled: false,
    icon: ViewMinor,
    onAction: () => handleSelect(constant.REVIEW_ALL),
  };
  const view = {
    content: constant.EDIT_LABEL,
    value: constant.VIEW_ID,
    disabled: false,
    icon: EditMinor,
    onAction: () => handleSelect(constant.VIEW_ID),
  };
  const undelete = {
    content: constant.UN_DELETE,
    value: constant.UNDELETE,
    disabled: false,
    icon: UndoMajorMonotone,
    onAction: () => handleSelect(constant.UNDELETE),
  };
  const unreject = {
    content: constant.UN_REJECT,
    value: constant.UNREJECT,
    disabled: false,
    icon: CircleTickMajorMonotone,
    onAction: () => handleSelect(constant.UNREJECT),
  };
  const reject = {
    content: constant.REJECT_LABEL,
    value: constant.REJECT,
    disabled: false,
    icon: CircleCancelMajorMonotone,
    destructive: true,
    onAction: () => handleSelect(constant.REJECT),
  };
  const deleteOption = {
    content: constant.DELETE,
    value: constant.DELETED,
    disabled: false,
    destructive: true,
    icon: DeleteMinor,
    onAction: () => handleSelect(constant.DELETED),
  };
  // const markAvailable = { label: t("label.productList.markAvailable"), value: "available", disabled: false };
  // const markUnavailable = { label: t("label.productList.markUnavailable"), value: "unavailable", disabled: false };
  const reSubmit = {
    content: t("label.productList.reSubmitSelected"),
    value: constant.RE_SUBMIT,
    disabled: false,
    icon: ListMajorMonotone,
    onAction: () => handleSelect(constant.RE_SUBMIT),
  };

  const viewOption =
    status.toLowerCase() !== constant.REJECTED && status !== constant.DELETED ? [view] : [{ ...view, disabled: true }];
  const pushToStoreOption =
    status.toLowerCase() === constant.NEW || status === constant.REVIEWED
      ? [pushToStore]
      : [{ ...pushToStore, disabled: true }];
  const reviewOption = status === constant.NEEDS_REVIEW ? [review] : [{ ...review, disabled: true }];
  const undeleteOption = status === constant.DELETED ? [undelete] : [{ ...undelete, disabled: true }];
  const unrejectOption = status.toLowerCase() === constant.REJECTED ? [unreject] : [{ ...unreject, disabled: true }];

  const rejectOption =
    status.toLowerCase() === constant.NEW ? [reject] : [{ ...reject, disabled: true, destructive: false }];
  const deleteOptions =
    status !== constant.DELETED && status.toLowerCase() !== constant.REJECTED
      ? [deleteOption]
      : [{ ...deleteOption, disabled: true, destructive: false }];

  // const markAvailableOptions = (published && status.toLowerCase() !== constant.REJECTED && status !== constant.DELETED) ? [markAvailable] : [{ ...markAvailable, disabled: true }];
  // const markUnavailableOptions = (status === constant.REVIEWED || status === constant.NEEDS_REVIEW || status === constant.APPROVED) ? [markUnavailable] : [{ ...markUnavailable, disabled: true }];

  const reSubmitOption =
    status === constant.DELETED || status.toLowerCase() === constant.REJECTED
      ? [reSubmit]
      : [{ ...reSubmit, disabled: true }];
  let options = [];

  if (isSeller) {
    options = [...viewOption];
    if (status.toLowerCase() === constant.NEW || status === constant.REVIEWED) {
      options.unshift(...pushToStoreOption);
    }
    if (status === constant.NEEDS_REVIEW) {
      options.unshift(...reviewOption);
    }
    if (status === constant.DELETED) {
      options.unshift(...undeleteOption);
    }
    if (status.toLowerCase() === constant.REJECTED) {
      options.unshift(...unrejectOption);
    }
    if (!(status.toLowerCase() === constant.NEW || status === constant.REVIEWED)) {
      options.push(...pushToStoreOption);
    }
    if (!(status === constant.NEEDS_REVIEW)) {
      options.push(...reviewOption);
    }
    if (!(status === constant.DELETED)) {
      options.push(...undeleteOption);
    }
    if (!(status.toLowerCase() === constant.REJECTED)) {
      options.push(...unrejectOption);
    }
    if (!(status.toLowerCase() === constant.NEW)) {
      options.push(...rejectOption);
    }
    if (!(status !== constant.DELETED && status.toLowerCase() !== constant.REJECTED)) {
      options.push(...deleteOptions);
    }
    if (status.toLowerCase() === constant.NEW) {
      options.push(...rejectOption);
    }
    if (status !== constant.DELETED && status.toLowerCase() !== constant.REJECTED) {
      options.push(...deleteOptions);
    }
  }

  if (isVendor) {
    options = [...viewOption];
    if (status === constant.DELETED || status.toLowerCase() === constant.REJECTED) {
      options.unshift(...reSubmitOption);
    }
    if (!(status === constant.DELETED || status.toLowerCase() === constant.REJECTED)) {
      options.push(...reSubmitOption);
    }
    options.push(...deleteOptions);
  }

  if (isSystemAdmin) {
    options = [...viewOption, ...deleteOptions];
  }

  return (
    <>
      <ResourceItem
        id={productID}
        key={productID}
        accessibilityLabel={`${t("label.productList.viewDetailsFor")} ${title}`}
        persistActions
        recordID={id}
      >
        <Stack alignment="leading">
          <Stack.Item>{media}</Stack.Item>
          <Stack.Item fill Style="width: 200px">
            <div className="ellipsis">
              <Heading element="h2">
                <Link onClick={() => history.push(`${constant.PRODUCT_URL_UPDATE}${productID}`)}>
                  <div className="ellipsis">
                    <TextStyle>{title}</TextStyle>
                  </div>
                </Link>
              </Heading>
              <Caption>
                <TextStyle variation="subdued">
                  {`${constant.UPDATED}: ${constant.ON} `}
                  {isShowUpdatedDate && isUpdated
                    ? `${baseHelper.formatDate(updatedAt)}`
                    : `${baseHelper.formatDate(createdAt)}`}
                </TextStyle>
                <br />
                {constant.VENDOR_LABEL}
                {":"}
                {(isVendor && vendor) || <Link url={`/providers/view/${vendorId}`}>{vendor}</Link>}
                <br />
                <Caption>
                  <TextStyle variation="subdued">
                    {`${t("label.productList.numberOfVariants")}${variants.length}`}
                  </TextStyle>
                </Caption>
                {isAvailablePublishment && (
                  <Caption>
                    <TextStyle variation="subdued">
                      {`${constant.AVAILABILITY_STATUS}: `}
                      {published ? constant.AVAILABLE : constant.UNAVAILABLE}
                    </TextStyle>
                  </Caption>
                )}
              </Caption>
              <div style={topMargin10px}>
                <Stack>
                  <Stack.Item>
                    <Badge status={badgeType}>
                      <span className="text-capitalize">{badgeStatus}</span>
                    </Badge>
                  </Stack.Item>
                  <Stack.Item>
                    <div className="timelineLink">
                      <Button
                        plain
                        monochrome
                        disclosure={active[id] ? "up" : "down"}
                        onClick={() => {
                          setActive((prev) => {
                            return {
                              ...prev,
                              [id]: !active[id],
                            };
                          });
                        }}
                      >
                        History
                      </Button>
                    </div>
                  </Stack.Item>
                </Stack>
              </div>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Popover
              active={activePopover[id]}
              setActive={() => setActivePopover({ [id]: !activePopover[id] })}
              loading={selectedButtonIndex && loadingBulkProduct}
              options={options}
            />
          </Stack.Item>
        </Stack>
        <Collapsible
          open={active && active[id]}
          id="timeline"
          transition={{ duration: "150ms", timingFunction: "ease" }}
        >
          <ProductVersioning productId={productID} setListBanner={setListBanner} />
        </Collapsible>
      </ResourceItem>
    </>
  );
};
ProductListView.defaultProps = {
  keyIndex: "",
  item: {
    _id: "",
    title: "",
    vendor: "",
    createdAt: "",
    variantCount: "",
    published: "",
    productId: "",
    status: "",
    images: { imageUrl: null },
  },
  bulkAction: () => {},
  handleRejectModal: () => {},
  handleDeleteModal: () => {},
  loadingBulkProduct: "",
  activePopover: {},
};

ProductListView.propTypes = {
  keyIndex: PropTypes.number,
  item: PropTypes.shape(PropTypes.object),
  bulkAction: PropTypes.func,
  handleRejectModal: PropTypes.func,
  handleDeleteModal: PropTypes.func,
  loadingBulkProduct: PropTypes.string,
  activePopover: PropTypes.objectOf(),
  setActivePopover: PropTypes.func.isRequired,
};

export default ProductListView;

import React, { useState, useContext, useEffect } from "react";
import { Card, Tabs, FormLayout, TextField, Select } from "@shopify/polaris";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { PrivateContext } from "lib/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// helpers
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// lib component
import { ResourceList, Banner } from "lib/components";

// import from apollo
import { GET_VENDOR_LIST } from "app/vendors/apollo/queries";
import VENDOR_LIST_SUBSCRIPTION from "app/vendors/apollo/subscription";

// cms
import { DeleteProvider, LoginProvider } from "app/vendors/modules/generic/feature/list/subFeatures";
import cmsVendorList from "./cms/vendorListCMS";

// subFeatures
import ProviderList from "./subFeatures";

const OperatorProviderList = () => {
  const { history } = useContext(PrivateContext);
  const prevFilter = baseHelper.queryParamsFromLocation(history);
  const [selectedItems, setSelectedItems] = useState([]);
  const selectedSort = prevFilter.sort_name && `${prevFilter.sort_name}_${prevFilter.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || "createdAt_desc");
  const [selected, setSelected] = useState(parseInt(prevFilter.tab, 10) || 0);
  const [selectedFilter, setSelectedFilter] = useState(prevFilter.list_filter || null);
  const [singleVendor, setSingleVendor] = useState([]);
  const [currentPage, setCurrentPage] = useState(parseInt(prevFilter.page, 10) || 1);
  const [selectedLimit, setSelectedLimit] = useState(parseInt(prevFilter.perPage, 10) || 10);
  const [queryValue, setQueryValue] = useState(prevFilter.search || null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [isReasonField, setIsReasonField] = useState(false);
  const [taggedWith, setTaggedWith] = useState(prevFilter.list_search || null);
  const [vendorList, setVendorList] = useState([]);
  const [totalVendor, setTotalVendor] = useState(0);
  const [isVerifyVendors, setIsVerifyVendors] = useState(false);
  const [dataToFetch, setDataToFetch] = useState({
    list_search: prevFilter.list_search,
    search: prevFilter.search,
    filter: prevFilter.filter,
    list_filter: prevFilter.list_filter,
    perPage: selectedLimit,
    page: currentPage,
    sort_name: prevFilter.sort_name || "createdAt",
    sort_order: prevFilter.sort_order || "desc",
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [activePopover, setActivePopover] = useState({});
  const { t } = useTranslation();
  const vendorListCMS = cmsVendorList(t);
  const { message, label, tabs, filterOptions, resourceName, promotedBulkActions, sortOptions } = vendorListCMS;

  const inputData = {
    list_search: dataToFetch.list_search,
    search: dataToFetch.search,
    filter: dataToFetch.filter,
    list_filter: dataToFetch.list_filter,
    page: parseInt(dataToFetch.page, 10),
    perPage: parseInt(dataToFetch.perPage, 10),
    sort_name: dataToFetch.sort_name,
    sort_order: dataToFetch.sort_order,
  };

  // useQuery Calls to fetch data.
  const {
    error: errorVendorList,
    loading: loadingVendorList,
    data: dataVendorList,
    subscribeToMore,
    refetch,
  } = useQuery(GET_VENDOR_LIST, {
    variables: {
      input: inputData,
    },
  });

  const vendorListResponseData = baseHelper.getResponseData(dataVendorList, constant.gql.GET_VENDOR_LIST);
  const vendorListErrorData = baseHelper.getResponseError(dataVendorList, constant.gql.GET_VENDOR_LIST);

  useEffect(() => {
    if (vendorListResponseData) {
      const { vendors: vendorListData = [], count: totalCount = 0 } = vendorListResponseData;
      setVendorList(vendorListData);
      setTotalVendor(totalCount);
    }
  }, [vendorListResponseData]);

  useEffect(() => {
    if (vendorListErrorData) {
      setBanner({ status: "critical", title: vendorListErrorData, isOpen: true });
    }
    if (errorVendorList) {
      setBanner({ status: "critical", title: message.something_went_wrong, isOpen: true });
    }
  }, [vendorListErrorData, errorVendorList, message.something_went_wrong]);

  // function for more filter.
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    baseHelper.setUrl(history, { list_search: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  // function for remove values of more filter.
  const handleTaggedWithRemove = () => {
    setTaggedWith(null);
    setSelectedFilter("");
    baseHelper.setUrl(history, { list_search: "", list_filter: "" });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  // end

  const handleClearAll = () => {
    handleTaggedWithRemove();
  };

  const handleSelectLimit = (value) => {
    setSelectedLimit(parseInt(value, 10));
    baseHelper.setUrl(history, { perPage: parseInt(value, 10) });
    const result = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...result });
  };
  const handleQueryValueChange = (value) => {
    baseHelper.setUrl(history, { search: value });
    setQueryValue(value);
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };
  const handleQueryValueRemove = () => {
    baseHelper.setUrl(history, { search: "" });
    setQueryValue("");
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  // handle tab change filter.
  const handleTabChange = (selectedTabIndex) => {
    const { value: tabName } = tabs[selectedTabIndex];
    setSelected(selectedTabIndex);
    baseHelper.setUrl(history, { tab: selectedTabIndex, filter: tabName });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };
  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    baseHelper.setUrl(history, { list_filter: value });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  const sortFilter = (selectedItem) => {
    const sort = selectedItem.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1] });
      setSortValue(selectedItem);
      const filteredObj = baseHelper.queryParamsFromLocation(history);
      setDataToFetch({ ...dataToFetch, ...filteredObj });
    }
  };

  const handlePerPage = (page) => {
    setCurrentPage(parseInt(page, 10));
    baseHelper.setUrl(history, { page });
    const filteredObj = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filteredObj });
  };

  // function for delete vendor and verify vendor.
  const deleteBulkVendor = async (isDelete = false, isSingle = false, id = "") => {
    if (isDelete && !isSingle) {
      setIsVerifyVendors(false);
      setSingleVendor([]);
      setIsReasonField(true);
      setDeleteModal(true);
    } else if (isSingle && id) {
      setIsVerifyVendors(false);
      setSingleVendor([id]);
      setIsReasonField(false);
      setDeleteModal(true);
    } else {
      setIsVerifyVendors(true);
    }
  };

  // Subscription for vendorList Page.
  useEffect(() => {
    subscribeToMore({
      document: VENDOR_LIST_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return refetch();
      },
    });
  }, [subscribeToMore, refetch]);

  // login vendor function.
  const loginVendor = (id, brand, role) => {
    setLoginModal(true);
    setSelectedBrand({ id, brand, role });
  };

  function renderItem(item, _, index) {
    return (
      <ProviderList
        item={item}
        keyIndex={index}
        onDeleteVendor={(vendorId) => deleteBulkVendor(false, true, vendorId)}
        onLoginVendor={(vendorId, brandName, role) => loginVendor(vendorId, brandName, role)}
        activePopover={activePopover}
        setActivePopover={setActivePopover}
      />
    );
  }

  const bulkActions = [
    {
      content: label.verify_selected,
      onAction: () => deleteBulkVendor(false),
    },
    {
      content: label.delete_selected,
      onAction: () => deleteBulkVendor(true),
    },
  ];

  function disambiguateLabel(key, value) {
    switch (key) {
      case constant.BRAND_FILTER:
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilterArrObj = [
    {
      key: constant.BRAND_FILTER,
      label: disambiguateLabel(constant.BRAND_FILTER, taggedWith),
      onRemove: handleTaggedWithRemove,
    },
  ];
  const appliedFilters = !isEmpty(taggedWith) ? appliedFilterArrObj : [];

  const customFilter = (
    <FormLayout>
      <Select
        label={label.show_all_vendor}
        value={selectedFilter}
        onChange={handleSelectChange}
        options={filterOptions}
      />
      {(selectedFilter && <TextField label={label.like} value={taggedWith} onChange={handleTaggedWithChange} />) ||
        null}
    </FormLayout>
  );

  const filters = [
    {
      key: constant.BRAND_FILTER,
      label: label.filter_by,
      filter: customFilter,
      shortcut: false,
    },
  ];

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
          />
          <br />
        </>
      )}
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <ResourceList
            resourceName={resourceName}
            items={vendorList && vendorList.length > 0 ? vendorList : []}
            idForItem={(vendors) => {
              const { _id: id } = vendors;
              return id;
            }}
            renderItem={renderItem}
            selectedItems={selectedItems}
            onSelectionChange={setSelectedItems}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            sortValue={sortValue}
            sortOptions={sortOptions}
            onSortChange={sortFilter}
            filters={filters}
            loading={loadingVendorList}
            onQueryChange={handleQueryValueChange}
            onQueryClear={handleQueryValueRemove}
            handleClearAll={handleClearAll}
            queryValue={queryValue}
            selectable
            appliedFilters={appliedFilters}
            page={currentPage}
            count={Number(totalVendor)}
            perPage={selectedLimit}
            setPage={handlePerPage}
            setPerPage={handleSelectLimit}
          />
        </Tabs>
      </Card>
      <DeleteProvider
        openModal={deleteModal}
        isReasonField={isReasonField}
        dataToFetch={inputData}
        setDeleteModal={setDeleteModal}
        setBanner={setBanner}
        selectedItems={selectedItems}
        singleVendor={singleVendor}
        isVerifyVendors={isVerifyVendors}
        setIsVerifyVendors={setIsVerifyVendors}
      />
      <LoginProvider
        loginModalOpen={loginModal}
        closeLoginModal={() => {
          setSelectedBrand({});
          setLoginModal(false);
        }}
        brandName={selectedBrand}
        setBanner={setBanner}
        setLoginModal={setLoginModal}
        setSelectedBrand={setSelectedBrand}
        isAdvanceVendor={false}
      />
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProviderList), { feature: constant.VENDORS });

import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, ButtonGroup, Caption, Card, Modal, ResourceItem, Stack, TextStyle } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import helpers
import { baseHelper } from "lib/helpers";

// import helpers components
import { ResourceList } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import gql
import { GET_SHIPPING_BAND } from "app/shipping/apollo/queries";
import { DELETE_BAND } from "app/shipping/apollo/mutations";

// import components
import Option from "./option";

// import props
import { listProp } from "../props";

const List = (props) => {
  const { bands, handleBanner, loading: fetchShippingBandLoading } = props;
  const { currentUser, cms } = useContext(PrivateContext);
  const [state, setState] = useState({
    bands: [],
    searchValue: "",
    showModal: false,
    showDeleteModal: false,
    selectedBand: false,
    bandsToShow: [],
  });

  const [deleteShippingBand, { loading }] = useMutation(DELETE_BAND, {
    refetchQueries: [
      {
        query: GET_SHIPPING_BAND,
      },
    ],
  });

  useEffect(() => {
    if (bands) {
      setState((prevState) => ({
        ...prevState,
        bandsToShow: bands,
      }));
    }
  }, [bands]);

  const toggleModal = (id = false) => {
    setState((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
      selectedBand: prevState.showModal ? false : id,
    }));
  };

  const toggleDeleteModal = (id = false) => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: !prevState.showDeleteModal,
      selectedBand: prevState.showDeleteModal ? false : id,
    }));
  };
  const handleSearchChange = (searchValue) => {
    const search = searchValue.toLowerCase().trim();
    const bandsToShow = bands.filter((item) => item.name.toLowerCase().includes(search));
    setState((prevState) => ({
      ...prevState,
      bandsToShow,
      searchValue,
    }));
  };

  const onDelete = async () => {
    let bannerData = {};
    try {
      const { selectedBand } = state;
      const reqData = {
        shippingBandId: selectedBand,
      };
      const res = await deleteShippingBand({
        variables: { input: reqData },
      });
      const responseData = baseHelper.getResponseData(res.data, constant.gql.DELETE_BAND);
      const errorData = baseHelper.getResponseError(res.data, constant.gql.DELETE_BAND);
      if (!errorData) {
        bannerData = {
          isOpen: true,
          status: "success",
          title: cms("band.modal.delete.message.success"),
        };
      }
      if (!responseData) {
        bannerData = { isOpen: true, status: "critical", title: errorData };
      }
    } catch (err) {
      bannerData = { isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") };
    }
    handleBanner(bannerData);
    toggleDeleteModal();
  };
  const handleQueryClear = () => {
    setState((prevState) => ({
      ...prevState,
      bandsToShow: bands,
      searchValue: "",
    }));
  };
  const renderDeleteModal = () => {
    const { showDeleteModal, selectedBand } = state;

    const bandToUpdate = bands.find((item) => {
      const { _id: bandId } = item;
      return bandId === selectedBand;
    });
    if (!bandToUpdate) {
      return null;
    }
    return (
      <Modal
        open={showDeleteModal}
        onClose={toggleDeleteModal}
        title={`${cms("band.modal.delete.title")} ${bandToUpdate.name}`}
        primaryAction={{
          content: cms("band.modal.delete.button.primary"),
          destructive: true,
          loading,
          onAction: () => onDelete(),
        }}
      >
        <Modal.Section>{cms("band.modal.delete.content")}</Modal.Section>
      </Modal>
    );
  };

  const renderModal = () => {
    const { showModal, selectedBand = false } = state;
    const bandToUpdate = bands.find((item) => {
      const { _id: bandId } = item;
      return bandId === selectedBand;
    });
    return (
      <Option
        shippingBand={{ ...bandToUpdate }}
        isDefault={!selectedBand}
        show={showModal}
        onClose={() => toggleModal()}
        handleBanner={handleBanner}
      />
    );
  };

  const renderItem = (item) => {
    if (fetchShippingBandLoading) {
      return null;
    }
    const moneyFormat = currentUser.moneyFormat || constant.symbol.DOLLAR;
    const { _id, price, description, isUpdateAllowed, name, priceType } = item;
    const isFreeOption = priceType === constant.SHIPPING_FREE;
    return (
      <ResourceItem id={_id} key={_id}>
        <Stack wrap={false}>
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{name}</TextStyle>
            </h3>
            <Caption>{description}</Caption>
            <br />
            <Caption>
              {`${cms("common.label.type")}: ${isFreeOption ? cms("band.label.free") : cms("band.label.fixed")}`}
            </Caption>
            {!isFreeOption && <Caption>{`${cms("band.label.cost")}: ${moneyFormat} ${price}`}</Caption>}
            <Caption>
              {`${cms("band.label.vendorUpdate")}: ${
                isUpdateAllowed ? cms("band.label.allowed") : cms("band.label.notAllowed")
              }`}
            </Caption>
          </Stack.Item>
          <ButtonGroup>
            <Button
              size="slim"
              destructive={false}
              disabled={item.disabled || false}
              onClick={() => toggleModal(_id)}
              key={`${_id}`}
            >
              {cms("band.button.edit")}
            </Button>
            <Button
              size="slim"
              destructive="true"
              disabled={item.disabled || false}
              onClick={() => toggleDeleteModal(_id)}
              key={`${_id}`}
            >
              {cms("band.button.delete")}
            </Button>
          </ButtonGroup>
        </Stack>
      </ResourceItem>
    );
  };

  const resourceName = {
    singular: cms("common.label.band.singular") || constant.BAND,
    plural: cms("common.label.band.plural") || constant.BANDS,
  };

  const { bandsToShow } = state;

  return (
    <>
      {renderModal()}
      {renderDeleteModal()}
      <br />
      <Stack vertical>
        <Stack.Item>
          <Stack>
            <Stack.Item fill />
            <Stack.Item>
              <Button size="slim" primary onClick={() => toggleModal()}>
                {cms("band.button.primary")}
              </Button>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
      <br />
      <Card>
        <ResourceList
          resourceName={resourceName}
          items={bandsToShow}
          renderItem={renderItem}
          loading={fetchShippingBandLoading}
          queryValue={state.searchValue}
          onQueryChange={(searchValue) => handleSearchChange(searchValue)}
          onQueryClear={handleQueryClear}
          idForItem={(item) => {
            const { _id: id } = item;
            return id;
          }}
        />
      </Card>
    </>
  );
};
List.propTypes = listProp.type;

export default List;

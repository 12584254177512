// import packages
import React, { useContext, useState, useEffect } from "react";
import { Layout, Card, FormLayout, List, Select, PageActions, TextContainer } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import context
import { PrivateContext, OnboardingContext } from "lib/context";

// import constants
import constant from "lib/constant/constant";

// import components
import { Banner } from "lib/components";

import { baseHelper } from "lib/helpers";
import { BulkCreate, Connect, ManualForm, WooCommerceConnect } from "./subFeatures";

const ProviderProductAdd = () => {
  const { history, cms } = useContext(PrivateContext);
  const [isSelectChange, setIsSelectChange] = useState(false);
  const { isOnboarding, onPrevious } = useContext(OnboardingContext);
  const [value, setValue] = useState("");
  const { state, search } = history.location;
  const { shop: isShopConnected } = baseHelper.getQueryParams(search);
  const [count, setCount] = useState(0);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
    action: null,
  });

  useEffect(() => {
    setCount(count + 1);
  }, [value]);

  useEffect(() => {
    if (state && state.bannerData) {
      setBanner({
        isOpen: true,
        status: state.bannerData.status,
        title: state.bannerData.title,
      });
    }
  }, [state]);

  const addProductOptions = [
    {
      label: "Import products by connecting to a WooCommerce store",
      value: constant.WOOCOMMERCE,
    },
    {
      label: cms("section.addProductMethod.store"),
      value: constant.SHOPIFY,
    },
    {
      label: cms("section.addProductMethod.form"),
      value: constant.MANUAL,
    },
    {
      label: cms("section.addProductMethod.bulk"),
      value: constant.CSV,
      disabled: !!isOnboarding,
    },
  ];

  const renderComponent = (selected) => {
    if (selected === constant.WOOCOMMERCE ) {
      return <WooCommerceConnect setBanner={setBanner} />;
    }

    if (selected === constant.SHOPIFY || (isShopConnected && count === 1)) {
      return <Connect setBanner={setBanner} />;
    }

    if (selected === constant.MANUAL) {
      return <ManualForm setBanner={setBanner} />;
    }

    if (selected === constant.CSV) {
      return <BulkCreate setBanner={setBanner} />;
    }
    return null;
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const standardLists = cms("section.onboarding.caption") || [];
  const renderCaptions = () => {
    const list = standardLists.map((content) => (
      <List.Item>
        <b>{content.title}</b>
        {content.description}
      </List.Item>
    ));
    return list;
  };

  const getOptions = (options) => {
    if (isOnboarding) {
      return options.filter((option) => !([constant.SHOPIFY,constant.WOOCOMMERCE].includes(option.value)));
    }
    return options;
  };

  const handleSelectChange = (updatedValue) => {
    setIsSelectChange(true);
    setValue(updatedValue);
  };

  // const captions = [
  //   "You can import products by connecting to a Shopify store",
  //   "You can add products manually, one by one, using our form",
  //   "You can also bulk add products by uploading a CSV file",
  // ];

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.addProductMethod.title")}
        description={cms("section.onboarding.description")}
      >
        <Card title={cms("section.onboarding.subTitle")}>
          <Card.Section>
            {isOnboarding && (
              <>
                <TextContainer>
                  <p>{cms("section.onboarding.subDescription")}</p>
                  <p>{cms("section.onboarding.note")}</p>
                </TextContainer>

                <br />
                <TextContainer>
                  <Banner status="critical" isOpen>
                    <p>{cms("section.onboarding.message.error.manual")}</p>
                  </Banner>
                </TextContainer>
                <br />
              </>
            )}

            {!isOnboarding && (
              <>
                <TextContainer>
                  <p>{cms("section.onboarding.manual")}</p>

                  <p>{cms("section.onboarding.note")}</p>
                </TextContainer>
                <br />
              </>
            )}

            <FormLayout>
              {/* <div id="listContent">
                <List type="bullet">
                  {captions.map(caption => (
                    <List.Item>
                      <Caption>{caption}</Caption>
                    </List.Item>
                  ))}
                </List>
              </div> */}
              <Select
                id="selectImportType"
                options={getOptions(addProductOptions)}
                placeholder="Choose how would you like to add products"
                onChange={handleSelectChange}
                value={isShopConnected && count === 1 ? constant.SHOPIFY : value}
              />
              {isOnboarding && (
                <Banner status="info" isOpen>
                  <p>{cms("section.onboarding.options")}</p>
                  <br />
                  <p>
                    <List type="bullet">
                      <List type="bullet">{renderCaptions()}</List>
                    </List>
                  </p>
                </Banner>
              )}
            </FormLayout>
          </Card.Section>
        </Card>

        {renderComponent(value)}

        {!(isSelectChange || isShopConnected) && isOnboarding && (
          <PageActions
            secondaryActions={[
              {
                content: cms("common.button.previous"),
                onAction: onPrevious,
              },
            ]}
          />
        )}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(ProviderProductAdd), { feature: constant.ADD_PRODUCT });
import yup from "lib/helpers/yup";
import { validateData } from "./schema";

const validate = async (value) => {
  let promise = "";
  promise = yup.validateValue(validateData.validationSchema, { radioGroup: value });
  return yup.handlePromiseError(promise);
};

export default validate;

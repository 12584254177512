import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import PropTypes from "prop-types";
import {
  Caption,
  Card,
  FormLayout,
  Layout,
  List,
  Link,
  PageActions,
  Select,
  Stack,
  TextField,
  TextStyle,
  TextContainer,
} from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import helper
import { baseHelper } from "lib/helpers";

// import components
import { Banner, SkeletonAnnotated, Sheet } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import gql
import { GET_COMMISSION } from "../../../../../apollo/queries";
import { UPDATE_COMMISSION } from "../../../../../apollo/mutations";

// import yup validation
import validate from "./yup";
import LargeModalExample from "../components/modal";

const { onboardingSteps } = constant;

const Commission = (props) => {
  const { setSelectedTab } = props;
  const { currentUser = {}, cms = {}, currentUserRefetch = () => {} } = useContext(PrivateContext);

  const onNext = () => {
    setSelectedTab(onboardingSteps.TERMS);
    currentUserRefetch();
  };
  const onPrevious = () => {
    currentUserRefetch();
    setSelectedTab(onboardingSteps.UPDATE_PROFILE);
  };

  const {
    FLAT,
    PERCENTAGE: PERCENTAGE_TEXT,
    SELECTED_STANDARD_TYPE,
    STANDARD_AMOUNT,
    gql: { GET_COMMISSION: GET_COMMISSION_TEXT, UPDATE_COMMISSION: UPDATE_COMMISSION_TEXT },
    symbol: { DOLLAR, PERCENTAGE: PERCENTAGE_SYMBOL },
    value: { MAX_PERCENTAGE, MAX_FLAT, MIN_PRICE },
  } = constant;
  const { _id: currentUserId } = currentUser;

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [standardValue, setStandardValue] = useState({
    selectedStandardType: "",
    standardAmount: "",
  });

  const [errorMessage, setErrorMessage] = useState();

  const { loading: queryLoading, data: queryData, error } = useQuery(GET_COMMISSION, {
    variables: { input: { sellerId: currentUserId } },
  });

  const [setCommission, { loading: updateCommissionLoading }] = useMutation(UPDATE_COMMISSION);
  const [sheetActive, setSheetActive] = useState(false);

  useEffect(() => {
    if (!queryData) {
      return;
    }
    const responseData = baseHelper.getResponseData(queryData, GET_COMMISSION_TEXT);
    const responseError = baseHelper.getResponseError(queryData, GET_COMMISSION_TEXT);
    if (responseError) {
      setBanner({ isOpen: true, status: "critical", title: responseError });
      return;
    }
    if (!responseData) {
      return;
    }

    setStandardValue({
      selectedStandardType: responseData.type || "",
      standardAmount: String(responseData.commission) || "",
    });
  }, [queryData, GET_COMMISSION_TEXT]);

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
    }
  }, [error, cms]);

  if (queryLoading) {
    return <SkeletonAnnotated />;
  }

  const valueOptions = [
    {
      label: cms("common.label.flat"),
      value: FLAT,
    },
    {
      label: cms("common.label.percentage"),
      value: PERCENTAGE_TEXT,
    },
  ];

  const handleValidate = async (field, value) => {
    const message = {
      typeRequired: cms("message.error.type"),
      amountRequired: cms("message.error.amount"),
    };
    const validationError = await validate(field, value, message);

    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleAmountChange = (value) => {
    if (value.length && !baseHelper.validatePositiveNumericValues(value)) {
      return;
    }
    setStandardValue({ ...standardValue, standardAmount: value });
  };

  const handleChange = (value) => {
    setStandardValue({ standardAmount: "", selectedStandardType: value });
  };

  const standardLists = cms("section.onboardingCommission.optionContent") || [];
  const advanceLists = cms("section.onboardingCommission.advanceOptionContent") || [];

  // const standardLists = [
  //   { content: "Flat rate is a set amount charged on all orders(i.e $20).", key: FLAT },
  //   {
  //     content: "Percentage commission is a set percentage of order value charged on all orders(i.e 10 %).",
  //     key: PERCENTAGE_TEXT,
  //   },
  // ];

  const renderLists = () => {
    const list = standardLists.map((content) => (
      <List.Item>
        <b>{content.title}</b> 
{' '}
{content.description}
      </List.Item>
    ));
    return list;
  };

  const renderAdvanceLists = () => {
    const list = advanceLists.map((content) => (
      <>
        <p>
          <b>{content.title}</b> 
{' '}
{content.description}
        </p>
        <br />
      </>
    ));
    return list;
  };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  const maxAmount = (standardValue.selectedStandardType !== FLAT && MAX_PERCENTAGE) || MAX_FLAT;
  const { moneyFormat = DOLLAR } = currentUser;

  const isSubmitDisabled = !(
    standardValue.selectedStandardType !== "" && parseFloat(standardValue.standardAmount) >= 0
  );

  const saveCommission = async () => {
    const { selectedStandardType = "", standardAmount = "" } = standardValue;

    if (isSubmitDisabled) {
      handleValidate(SELECTED_STANDARD_TYPE, selectedStandardType);
      handleValidate(STANDARD_AMOUNT, standardAmount);
      return;
    }

    if (selectedStandardType === PERCENTAGE_TEXT && standardAmount > MAX_PERCENTAGE) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("message.error.percentage"),
      });
      return;
    }
    const requestData = {
      type: selectedStandardType,
      price: parseFloat(standardAmount),
      advancedValues: [],
    };

    try {
      const res = await setCommission({
        variables: { input: requestData },
      });

      const responseData = baseHelper.getResponseData(res.data, UPDATE_COMMISSION_TEXT);

      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, UPDATE_COMMISSION_TEXT);
        setBanner({ isOpen: true, status: "critical", title: errorResponse });
        return;
      }

      onNext();
    } catch (err) {
      setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
    }
  };

  const isAmountDisabled = standardValue.selectedStandardType === "";

  return (
    <Layout id="commission-settings">
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("section.onboardingCommission.title")}
        description={[<TextStyle variation="subdued">{cms("section.onboardingCommission.description")}</TextStyle>]}
      >
        {" "}
        <Card
          title={[
            cms("section.onboardingCommission.subTitle"),
            // <TextStyle variation="subdued">
            //   <Caption>Last updated: Monday 21st Dec 2019, 07:32am GMT</Caption>
            // </TextStyle>
          ]}
          // actions={[{ content: "Learn more",onAction: () => setSheetActive(!sheetActive) }]}
        >
          <Card.Section>
            <TextContainer>{cms("section.onboardingCommission.subDescription")}</TextContainer>
            <br />
            {/* <Stack spacing="loose" vertical> */}
            <FormLayout>
              <FormLayout.Group>
                <Select
                  id="standardType"
                  placeholder={cms("section.onboardingCommission.placeHolder.type")}
                  label={cms("section.onboardingCommission.label.type")}
                  value={standardValue.selectedStandardType}
                  options={valueOptions}
                  onChange={handleChange}
                  onBlur={() => handleValidate(SELECTED_STANDARD_TYPE, standardValue.selectedStandardType)}
                  error={errorMessage && errorMessage.selectedStandardType}
                />
                <TextField
                  id="standardAmount"
                  placeholder={cms("section.onboardingCommission.placeHolder.value")}
                  label={cms("section.onboardingCommission.label.value")}
                  value={standardValue.standardAmount}
                  min={MIN_PRICE}
                  max={maxAmount}
                  prefix={(!isAmountDisabled && standardValue.selectedStandardType === FLAT && moneyFormat) || ""}
                  suffix={(!isAmountDisabled && standardValue.selectedStandardType !== FLAT && PERCENTAGE_SYMBOL) || ""}
                  onChange={(value) => handleAmountChange(value)}
                  onBlur={() => handleValidate(STANDARD_AMOUNT, standardValue.standardAmount)}
                  error={errorMessage && errorMessage.standardAmount}
                  disabled={isAmountDisabled}
                />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Banner isOpen status="info">
              <p>{cms("section.onboardingCommission.label.options")}</p>
              <br />
              <p>
                <List type="bullet">{renderLists()}</List>
              </p>
            </Banner>
            <br />
            <Banner isOpen status="">
              {renderAdvanceLists()}
            </Banner>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            content: cms("common.button.nextStep"),
            onAction: () => saveCommission(),
            loading: updateCommissionLoading,
          }}
          secondaryActions={[
            {
              id: "previous",
              content: cms("common.button.previous"),
              onAction: () => onPrevious(),
            },
          ]}
        />
      </Layout.AnnotatedSection>

      <LargeModalExample />
      <Sheet
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: "Close", onAction: () => setSheetActive(false) }}
        primaryAction={{ content: "Submit", onAction: () => setSheetActive(false) }}
      />
    </Layout>
  );
};

Commission.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default Commission;

function actionData(t) {
  return {
    filterData: {
      label: "Filter payments",
      filters: [
        {
          key: "vendor",
          label: "Brand Name",
          operatorText: "like",
          type: "text",
        },
      ],
    },
    mainLink: {
      orderNumber: "/orders/view/",
    },
    links: {
      vendor: {
        link: "/providers/view/",
        id: "vendorId",
      },
    },
    sortOptions: [
      { label: t("label.providerList.sortByDateAsc"), value: "createdAt_asc" },
      { label: t("label.providerList.sortByDateDesc"), value: "createdAt_desc" },
      { label: t("label.providerList.sortByOrderIdAsc"), value: "orderNumber_asc" },
      { label: t("label.providerList.sortByOrderIdDesc"), value: "orderNumber_desc" },
    ],
    resourceName: {
      singular: "Payment",
      plural: "Payments",
    },
    PerPage: [
      {
        label: "10",
        value: "10",
      },
      {
        label: "20",
        value: "20",
      },
      {
        label: "50",
        value: "50",
      },
      {
        label: "100",
        value: "100",
      },
    ],
    tabsData: [
      {
        id: "all-customers",
        content: t("label.All"),
        accessibilityLabel: "All customers",
        panelID: "all-customers-content",
      },
      {
        id: "forecast",
        content: t("label.Forecasted"),
        panelID: "forecasted-content",
      },
      {
        id: "due",
        content: t("label.DueForPayment"),
        panelID: "due-for-payment-content",
      },
      {
        id: "paid",
        content: t("label.Paid"),
        panelID: "paid-content",
      },
      {
        id: "dispute",
        content: t("label.Disputed"),
        panelID: "disputed-content",
      },
    ],
    badge: ["accountingStatus"],
    // media: 'image',
    tableData: {
      tbody: [],
    },
  };
}
export default actionData;

import React, { useState, useEffect, useContext } from "react";
import { Tabs, Card } from "@shopify/polaris";
import { useQuery, useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";

// import helpers
import baseHelper from "lib/helpers/base";

// import constant
import constant from "lib/constant/constant";

// import helpers component
import { ResourceList } from "lib/components";

// import filter component
import MoreFilters from "app/payments/modules/generic/moreFilters/moreFilter";

// import context
import { PrivateContext } from "lib/context";

// import gql
import ACCOUNT_LIST from "../../../../../apollo/query";
import { PAYMENT_LIST } from "../../../../../apollo/subscriptions";

import actionData from "../cms/resourceTableData";

// import subFeature
import PaymentRow from "./paymentRow";
import { listProp } from "../propTypes";
import BULK_ACCOUNT from "../../../../../apollo/mutation/bulkAccount";

const PaymentList = (props) => {
  const { setBanner } = props;
  const { history } = useContext(PrivateContext);
  const { gql } = constant;
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [loading, setLoading] = useState(true);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || null);
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || null);
  const [paymentListData, setPaymentList] = useState([]);
  const [paymentCount, setPaymentCount] = useState(0);
  const [bulkAccount] = useMutation(BULK_ACCOUNT);
  const { t } = useTranslation();
  const resourceData = actionData(t);
  const [selectedItems, setSelectedItems] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [sortValue, setSortValue] = useState(constant.CREATED_DESC);
  const [tab, setTab] = useState(parseInt(queryParam.tab, 10) || 0);
  const [activePopover, setActivePopover] = useState({});

  const renderItem = (item) => {
    return (
      <PaymentRow item={item} setBanner={setBanner} activePopover={activePopover} setActivePopover={setActivePopover} />
    );
  };

  const inputData = {
    filter: queryParam.filter,
    path: queryParam.path,
    sort_name: queryParam.sort_name,
    sort_order: queryParam.sort_order,
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };
  const { data: orderLinesData, loading: orderLoading, error, subscribeToMore, refetch, networkStatus } = useQuery(
    ACCOUNT_LIST,
    {
      variables: {
        input: inputData,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (error) {
      setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
    }
  }, [error, setBanner, t]);

  const orderLineError = baseHelper.getResponseError(orderLinesData, gql.GET_ACCOUNT_LIST);
  useEffect(() => {
    if (orderLineError) {
      setBanner({ isOpen: true, status: "critical", title: orderLineError });
    }
  }, [orderLineError, setBanner]);

  const removeDuplicate = (newData, prevData) => {
    // eslint-disable-next-line no-underscore-dangle
    return prevData && prevData.filter((item) => item._id !== newData._id);
  };

  useEffect(() => {
    subscribeToMore({
      document: PAYMENT_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newPayments = subscriptionData.data.paymentListView.data.paymentData;
        const prevPayments = prev.getAccountList.data;
        const paymentData = removeDuplicate(newPayments, prevPayments.paymentList);
        const newPaymentData = [newPayments, ...paymentData];
        const totalCount = newPaymentData.length;
        return [setPaymentList(newPaymentData), setPaymentCount(totalCount)];
      },
    });
  }, [subscribeToMore]);

  const responseOrderLinesData = baseHelper.getResponseData(orderLinesData, gql.GET_ACCOUNT_LIST);

  useEffect(() => {
    if (responseOrderLinesData) {
      setPaymentList(responseOrderLinesData && responseOrderLinesData.paymentList);
      setPaymentCount(responseOrderLinesData && responseOrderLinesData.count);
    }
  }, [responseOrderLinesData]);

  const handleSelect = (item) => {
    setSelectedItems(item);
  };

  const handleTabChange = (tabSelected) => {
    setTab(parseInt(tabSelected, 10));
    if (resourceData.tabsData && resourceData.tabsData[tabSelected] && resourceData.tabsData[tabSelected].id) {
      baseHelper.setUrl(history, {
        tab: tabSelected,
        filter: resourceData.tabsData[tabSelected].id,
        page: 1,
      });
    }
  };

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handlePageLimitChange = (value) => {
    setPerPage(value);
    baseHelper.setUrl(history, { perPage: value, page: 1 });
  };
  const handlePageChange = (value) => {
    setPage(value);
    baseHelper.setUrl(history, { page: value });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1], page: 1 });
    }
  };
  const handleQueryClear = () => {
    setSearch("");
  };
  const handleBulkAccount = async (key) => {
    try {
      const response = await bulkAccount({ variables: { input: { ids: selectedItems, key } } });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.BULK_ACCOUNT);
      if (responseError) {
        setBanner({
          isOpen: true,
          title: responseError,
          status: "critical",
        });
      }
      const responseData = baseHelper.getResponseData(response.data, constant.gql.BULK_ACCOUNT);
      if (responseData) {
        setBanner({ isOpen: true, title: t("message.RequestRunningBackGround"), status: "success" });
        refetch();
        setSelectedItems([]);
      }
    } catch (e) {
      setBanner({
        isOpen: true,
        title: t("error.common.somethingWentWrong"),
        status: "critical",
      });
    }
  };
  const bulkActions = [
    {
      content: t("label.payViaPayPal"),
      onAction: () => handleBulkAccount("paidViaPaypal"),
    },
    {
      content: t("label.payViaStripe"),
      onAction: () => handleBulkAccount("paidViaStripe"),
    },
    {
      content: t("label.markAsDispute"),
      onAction: () => handleBulkAccount("dispute"),
    },
  ];

  const handleTaggedWithRemove = () => {
    setTaggedWith("");
    setSelectedFilter("");
    baseHelper.setUrl(history, { list_search: "", list_filter: "", page: 1 });
  };

  const handleClearAll = () => {
    handleTaggedWithRemove();
  };

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case "taggedWith":
        return `${value}`;
      default:
        return value;
    }
  };

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  };

  const appliedFilters = !isEmpty(taggedWith)
    ? [{ key: "taggedWith", label: disambiguateLabel("taggedWith", taggedWith), onRemove: handleTaggedWithRemove }]
    : [];

  const filters = [
    {
      key: t("taggedWith"),
      label: t("label.order.filterBy"),
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
  ];

  return (
    <Card>
      <Tabs tabs={resourceData.tabsData} selected={tab} onSelect={handleTabChange} />
      <ResourceList
        items={paymentListData}
        idForItem={(orderLine) => {
          const { _id: id } = orderLine;
          return id;
        }}
        renderItem={renderItem}
        loading={loading || orderLoading || networkStatus === 4}
        queryValue={search}
        onQueryClear={handleQueryClear}
        onQueryChange={handleSearchChange}
        sortOptions={resourceData.sortOptions}
        sortValue={sortValue}
        onSortChange={handleSort}
        selectedItems={selectedItems}
        onSelectionChange={handleSelect}
        bulkActions={bulkActions}
        filters={filters}
        appliedFilters={appliedFilters}
        handleClearAll={handleClearAll}
        selectable
        count={paymentCount}
        page={page}
        perPage={perPage}
        setPage={handlePageChange}
        setPerPage={handlePageLimitChange}
      />
    </Card>
  );
};
PaymentList.propTypes = listProp.type;
export default PaymentList;

import React from "react";
import { Button } from "@shopify/polaris";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";

// import helper
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import { SYNC_INVENTORY_VENDOR } from "app/products/apollo/mutations/connect";

// import props
import { syncProduct } from "./props";

const {
  gql: { SYNC_INVENTORY_VENDOR: SYNC_INVENTORY_VENDOR_GQL },
} = constant;

const SyncProduct = (props) => {
  const { history, setBanner } = props;

  const { t } = useTranslation();
  const [syncInventoryVendor, { loading: syncInventoryVendorLoading }] = useMutation(SYNC_INVENTORY_VENDOR);
  const syncProducts = async () => {
    try {
      const response = await syncInventoryVendor();
      const responseError = baseHelper.getResponseError(response.data, SYNC_INVENTORY_VENDOR_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      const onAction = () => history.push("/products/shopify");
      const bannerAction = {
        onAction,
        content: t("message.Product.SyncProduct.ViewShopifyProduct"),
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: t("message.Product.SyncProduct.ProductSyncBackground"),
        action: bannerAction,
      });
    } catch (error) {
      setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
    }
  };

  return (
    <Button loading={syncInventoryVendorLoading} onClick={(value) => syncProducts(value)}>
      {t("label.SyncProduct.ImportProduct")}
    </Button>
  );
};

SyncProduct.propTypes = syncProduct.type;
SyncProduct.defaultProps = syncProduct.default;

export default SyncProduct;

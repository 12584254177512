import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneNumberSchema,
  citySchema,
  countrySchema,
  postalCodeSchema,
  // provinceCodeSchema,
  streetAddressSchema,
  brandNameSchema,
  brandSlugSchema,
  brandDescriptionSchema,
  bankNameSchema,
  accountNumberSchema,
  sortCodeSchema,
} from "./schema";

const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  CITY,
  COUNTRY,
  POSTAL_CODE,
  STREET_ADDRESS,
  BRAND_NAME,
  BRAND_SLUG,
  BRAND_DESCRIPTION,
  BANK_NAME,
  ACCOUNT_NUMBER,
  SORT_CODE,
  // PROVINCE_CODE,
} = constant;

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case FIRST_NAME:
      promise = validateValue(firstNameSchema(cms), { firstName: value });
      break;
    case LAST_NAME:
      promise = validateValue(lastNameSchema(cms), { lastName: value });
      break;
    case EMAIL:
      promise = validateValue(emailSchema(cms), { email: value });
      break;
    case PHONE_NUMBER:
      promise = validateValue(phoneNumberSchema(cms), { phoneNumber: value });
      break;
    case CITY:
      promise = validateValue(citySchema(cms), { city: value });
      break;
    // case PROVINCE_CODE:
    //   promise = validateValue(provinceCodeSchema(cms), { provinceCode: value });
    //   break;
    case COUNTRY:
      promise = validateValue(countrySchema(cms), { country: value });
      break;
    case POSTAL_CODE:
      promise = validateValue(postalCodeSchema(cms), { postalCode: value });
      break;
    case STREET_ADDRESS:
      promise = validateValue(streetAddressSchema(cms), { streetAddress: value });
      break;
    case BRAND_NAME:
      promise = validateValue(brandNameSchema(cms), { brandName: value });
      break;
    case BRAND_SLUG:
      promise = validateValue(brandSlugSchema(cms), { slug: value });
      break;
    case BRAND_DESCRIPTION:
      promise = validateValue(brandDescriptionSchema(cms), { description: value });
      break;
    case BANK_NAME:
      promise = validateValue(bankNameSchema, { bankName: value });
      break;
    case ACCOUNT_NUMBER:
      promise = validateValue(accountNumberSchema, { accountNumber: value });
      break;
    case SORT_CODE:
      promise = validateValue(sortCodeSchema, { sortCode: value });
      break;
    default:
      return "";
  }

  return handlePromiseError(promise);
};
export default validate;

import React, { useContext } from "react";
import { Button, Layout, Stack } from "@shopify/polaris";
import PropTypes from "prop-types";

// import helper
import { baseHelper } from "lib/helpers";
// import context
import { PrivateContext } from "lib/context";
// import constant
import constant from "lib/constant/constant";

const ResetAllButton = (props) => {
  const resetAll = constant.RESET_ALL;
  const muteAll = constant.MUTE_ALL;
  const { currentUser, cms } = useContext(PrivateContext);
  const { mutedLabels, openConfirmationModal, seller = {}, vendor = {} } = props;
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  let labelsList = isSeller && seller;
  if (isVendor) {
    labelsList = isVendor && vendor;
  }
  return (
    <>
      <Layout.Section>
        <Stack distribution="trailing">
          <Button
            primary
            disabled={mutedLabels && mutedLabels.length === Object.keys(labelsList).length}
            onClick={() => openConfirmationModal(muteAll)}
          >
            {`${cms("button.muteAll")}`}
          </Button>
          <Button primary disabled={mutedLabels && !mutedLabels.length} onClick={() => openConfirmationModal(resetAll)}>
            {`${cms("button.resetAll")}`}
          </Button>
        </Stack>
      </Layout.Section>
    </>
  );
};

ResetAllButton.defaultProps = PropTypes.default;
ResetAllButton.propTypes = PropTypes.type;
export default ResetAllButton;

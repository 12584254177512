import React, { useState, useEffect } from "react";
import { Banner, Modal, TextContainer, TextField, TextStyle } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import constant from "lib/constant/constant";

const DeleteProduct = (props) => {
  const {
    openDeleteModal = false,
    id = false,
    loadingBulkProduct,
    selectedButton,
    setOpenDeleteModal,
    bulkAction,
    selectedItems,
    isSeller = false,
  } = props;
  const { t } = useTranslation();
  const [reason, setReason] = useState(null);
  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
    setReason(null);
  };
  const handleReasonChange = (value) => {
    setReason(value);
  };
  useEffect(() => {
    if (openDeleteModal) {
      setReason(null);
    }
  }, [openDeleteModal]);

  const productLabel = selectedItems.length > 1 ? t("sectionContent.jobText.product.plural") : t("sectionContent.jobText.product.singular");
  return (
    <>
      <Modal
        open={openDeleteModal}
        onClose={() => handleCloseDelete()}
        title={t("label.productList.deleteProducts")}
        primaryAction={{
          content: t("button.productList.deleteModal.primaryAction"),
          onAction: () => bulkAction(constant.DELETE.toLowerCase(), true, id, reason, constant.DELETE_SHOPIFY),
          destructive: true,
          loading: loadingBulkProduct,
        }}
        // primaryAction={{
        //   content: t("button.productList.deleteModal.primaryAction"),
        //   onAction: () => bulkAction(constant.DELETE.toLowerCase(), false, id, reason, constant.DELETE_MARKET),
        //   loading: loadingBulkProduct && selectedButton === constant.DELETE_MARKET,
        //   disabled: loadingBulkProduct && selectedButton !== constant.DELETE_MARKET,
        // }}
        // secondaryActions={[
        //   {
        //     content: t("button.productList.deleteModal.secondaryAction"),
        //     onAction: () => bulkAction(constant.DELETE.toLowerCase(), true, id, reason, constant.DELETE_SHOPIFY),
        //     loading: loadingBulkProduct && selectedButton === constant.DELETE_SHOPIFY,
        //     disabled: loadingBulkProduct && selectedButton !== constant.DELETE_SHOPIFY,
        //   },
        // ]}
      >
        <Modal.Section>
          <Banner status="critical">{t("message.productList.description")} {selectedItems.length} {productLabel}.</Banner>
          {/* <TextContainer>
            <p>{t("message.productList.description")}</p>
            <p>{t("message.productList.description1")}</p>
          </TextContainer> */}
          {isSeller && <Banner status="warning">{t("message.productList.operatorDelete")}</Banner>}
          <br />
          <TextField label={t("label.productList.reason")} value={reason} onChange={handleReasonChange} multiline={5} />
        </Modal.Section>
      </Modal>
    </>
  );
};

const RejectProduct = (props) => {
  const {
    openRejectModal = false,
    id = false,
    loadingBulkProduct,
    selectedButton,
    setOpenRejectModal,
    bulkAction,
    modalTitle,
    primaryActionTitle,
    secondaryActionsTitle,
    keyName,
  } = props;
  const { t } = useTranslation();
  const [reasonReject, setReasonReject] = useState(null);
  const handleCloseReject = () => {
    setOpenRejectModal(false);
    setReasonReject(null);
  };
  const handleReasonChange = (value) => {
    setReasonReject(value);
  };
  useEffect(() => {
    if (openRejectModal) {
      setReasonReject(null);
    }
  }, [openRejectModal]);
  return (
    <>
      <Modal
        open={openRejectModal}
        onClose={() => handleCloseReject()}
        title={modalTitle}
        primaryAction={{
          content: primaryActionTitle,
          onAction: () => bulkAction(keyName, false, id, reasonReject, keyName),
          loading: loadingBulkProduct && selectedButton === keyName,
          disabled: loadingBulkProduct && selectedButton !== keyName,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: secondaryActionsTitle,
            onAction: () => handleCloseReject(),
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label={t("label.productList.enterTheReason")}
            value={reasonReject}
            onChange={handleReasonChange}
            multiline={5}
          />
        </Modal.Section>
      </Modal>
    </>
  );
};

DeleteProduct.defaultProps = {
  bulkAction: () => {},
  id: "",
  isSeller: false,
  loadingBulkProduct: false,
  openDeleteModal: false,
  selectedButton: null,
  selectedItems: [],
  setOpenDeleteModal: () => {},
};

DeleteProduct.propTypes = {
  bulkAction: PropTypes.func,
  id: PropTypes.string,
  isSeller: PropTypes.bool,
  loadingBulkProduct: PropTypes.bool,
  openDeleteModal: PropTypes.bool,
  selectedButton: PropTypes.string,
  selectedItems: PropTypes.PropTypes.arrayOf(PropTypes.string),
  setOpenDeleteModal: PropTypes.func
};

RejectProduct.defaultProps = {
  bulkAction: () => {},
  id: "",
  keyName: null,
  loadingBulkProduct: false,
  modalTitle: null,
  openRejectModal: false,
  primaryActionTitle: null,
  secondaryActionsTitle: null,
  selectedButton: null,
  selectedItems: [],
  setOpenRejectModal: () => {},
};

RejectProduct.propTypes = {
  bulkAction: PropTypes.func,
  id: PropTypes.string,
  keyName: PropTypes.string,
  loadingBulkProduct: PropTypes.bool,
  modalTitle: PropTypes.string,
  openRejectModal: PropTypes.bool,
  primaryActionTitle: PropTypes.string,
  secondaryActionsTitle: PropTypes.string,
  selectedButton: PropTypes.string,
  setOpenRejectModal: PropTypes.func,
};

export { DeleteProduct, RejectProduct };

import React, { useContext } from "react";
import { Caption, ResourceItem, Stack, TextStyle } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import constant
import constant from "lib/constant/constant";

// import helper
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import { AdvanceShippingContext } from "../../context/context";

// import component
import Actions from "./action";

// import propType
import { offerItemProp } from "../propsType";

const OfferItem = (props) => {
  const { isVendorAllowed } = useContext(AdvanceShippingContext);
  const { currentUser = {} } = useContext(PrivateContext);
  const { moneyFormat, item, destinations = [], products = [], bands = [], toggleModal, toggleDeleteModal } =
    props || {};
  const { t } = useTranslation();
  const { _id: currentUserId = "" } = currentUser || {};
  const {
    _id,
    shippingBandId,
    description,
    condition,
    destinationCode,
    productQuantity = {},
    productPrice = {},
    orderValue = {},
    productId,
    priceType,
    price,
    isCumulative = false,
    createdBy,
  } = item || {};
  const { FIXED, FREE, ORDER_VALUE, PRODUCT_PRICE, PRODUCT_QUANTITY, GREATER_THAN } = constant;
  const isProductPrice = condition === PRODUCT_PRICE;
  const isProductQuantity = condition === PRODUCT_QUANTITY;
  const isOrderValue = condition === ORDER_VALUE;
  const isCreatedBySeller = baseHelper.mongoIdAsString(createdBy) !== baseHelper.mongoIdAsString(currentUserId);

  const shippingBand =
    (bands.length > 0 &&
      bands.find((band) => {
        return band.value === shippingBandId || band.parentId === shippingBandId;
      })) ||
    "";
  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((country) => country.value === destinationCode);
  const product = products.find((pItem) => pItem.value === productId);
  const isFreeOption = priceType === FREE;
  const priceTypeText = isFreeOption ? FREE : FIXED;
  const shortcutActions = [
    {
      content: t("label.Edit"),
      onAction: () => toggleModal(item),
      disabled: !isVendorAllowed,
    },
    {
      content: t("label.Delete"),
      destructive: true,
      onAction: () => toggleDeleteModal(_id, shippingBandId),
      disabled: isCreatedBySeller || !isVendorAllowed,
    },
  ];
  return (
    <ResourceItem id={_id}>
      <Stack alignment="leading">
        <Stack.Item fill>
          <h3>
            <TextStyle variation="strong">{(shippingBand && shippingBand.label) || ""}</TextStyle>
          </h3>
          <Caption>{description}</Caption>
          <br />
          <Caption>{` ${t("label.Destination")}: ${destination ? destination.label : t("label.All")}`}</Caption>
          <Caption>{` ${t("label.Products")}: ${product ? product.label : t("label.All")}`}</Caption>
          {isProductPrice && (
            <Caption>
              {` ${t("label.ProductPrice")}:
              ${productPrice.criteria === GREATER_THAN ? t("label.GreaterThan") : t("label.LessThan")}
               ${moneyFormat} ${productPrice.value}`}
            </Caption>
          )}
          {isProductQuantity && (
            <Caption>
              {`  ${t("label.ProductQuantity")}:
              ${productQuantity.criteria === GREATER_THAN ? t("label.GreaterThan") : t("label.LessThan")}
             ${productQuantity.value}`}
            </Caption>
          )}
          {isOrderValue && (
            <Caption>
              {` ${t("label.OrderValue")}:
              ${orderValue.criteria === GREATER_THAN ? t("label.GreaterThan") : t("label.LessThan")}
              ${` ${moneyFormat} ${orderValue.value}`}`}
            </Caption>
          )}
          <Caption>{` ${t("label.PriceType")}: ${priceTypeText}`}</Caption>
          <Caption>{` ${t("label.Cost")}: ${moneyFormat} ${isFreeOption ? "0" : price}`}</Caption>
          {!isOrderValue && (
            <Caption>{` ${t("label.ChargeCumulative")}: ${isCumulative ? t("label.Yes") : t("label.No")}`}</Caption>
          )}
        </Stack.Item>
        <Actions actions={shortcutActions} />
      </Stack>
    </ResourceItem>
  );
};

OfferItem.propTypes = offerItemProp.type;

export default OfferItem;

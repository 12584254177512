import React, { useContext } from "react";
import { ResourceList, Stack, TextStyle, Heading, Link, Caption, Badge } from "@shopify/polaris";

// import helpers
import baseHelper from "lib/helpers/base";

// import translation cms
import { useTranslation } from "react-i18next";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import cms
import paymentListData from "../cms/paymentListData";

// import propTypes
import { listProp } from "../propsType";

function List(props) {
  const { t } = useTranslation();
  const resourceData = paymentListData(t);
  const { mainLink, links } = resourceData;
  const { history } = useContext(PrivateContext);
  const { item } = props;
  const { _id, dueDate, errorMessage, gateway, status, orderNumber, vendor, createdAt, orderId } = item;
  let badgeColor = "success";
  switch (status.toLowerCase()) {
    case "pending":
      badgeColor = "new";
      break;
    case "failed":
    case "error":
      badgeColor = "warning";
      break;

    default:
      break;
  }
  return (
    <ResourceList.Item id={_id}>
      <Stack vertical={false} distribution="fillEvenly">
        <Stack distribution="equalSpacing" alignment="center">
          <Stack.Item fill>
            <Heading>
              {`${t("label.OrderId")}: `}
              <Link onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>{`#${orderNumber}`}</Link>
            </Heading>
          </Stack.Item>
          <Stack.Item>
            <Heading>{`(${baseHelper.ucFirst(gateway)})`}</Heading>
          </Stack.Item>
          <Stack.Item>
            <Badge status={badgeColor}>{baseHelper.ucFirst(status)}</Badge>
          </Stack.Item>
        </Stack>
      </Stack>
      <Caption>
        {`${t("label.Vendor")}: `}
        <Link plain onClick={() => history.push(`${links.vendor.link}${orderId}`)}>
          {vendor}
        </Link>
      </Caption>
      <Caption>{`${t("label.Reason")}: ${errorMessage}`}</Caption>
      <Caption>{`${t("label.DueDate")}: ${baseHelper.formatDate(dueDate)}`}</Caption>
      <Caption>
        <TextStyle variation="subdued">{`${t("label.CreatedOn")} ${baseHelper.formatDate(createdAt)} `}</TextStyle>
      </Caption>
    </ResourceList.Item>
  );
}

List.propTypes = listProp.type;
export default List;

const tabs = (t) => {
  return [
    {
      id: "all",
      content: t("label.onboardActivity.All"),
      accessibilityLabel: "All",
      panelID: "all",
    },
    {
      id: "firstLogin",
      content: t("label.onboardActivity.badgeStatus.firstLogin"),
      panelID: "first-login",
    },
    {
      id: "appInstall",
      content: t("label.onboardActivity.badgeStatus.appInstall"),
      panelID: "app-install",
    },
    {
      id: "appUninstall",
      content: t("label.onboardActivity.badgeStatus.appUninstall"),
      panelID: "app-uninstall",
    },
    {
      id: "vendorSignUp",
      content: t("label.onboardActivity.badgeStatus.supplierSignUp"),
      panelID: "vendor-sign-up",
    },
    {
      id: "sellerSignUp",
      content: t("label.onboardActivity.badgeStatus.sellerSignUp"),
      panelID: "seller-sign-up",
    },
  ];
};

export default tabs;

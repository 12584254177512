import PropTypes from "prop-types";

const policyProp = {
  isOpen: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

const termsProp = {
  isOpen: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export { policyProp, termsProp };

// import packages
import React, { useState, useContext, useEffect } from "react";
import { Layout, Card, Stack, List, Button } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import components
import { Spinner } from "lib/components";

// import context
import { PublicContext } from "lib/context";

// import helpers
import { baseHelper, storageHelper } from "lib/helpers";

// import gql
import { SYNC_PRODUCT_TYPE, REGISTER_SELLER, REGISTER_WEBHOOK } from "../../../apollo/mutations";

const ShopifyPayment = () => {
  const [isPaymentFailed, setPaymentStatus] = useState(false);

  const [registerSeller, { loading: registerSellerLoading }] = useMutation(REGISTER_SELLER);
  const [registerWebhook] = useMutation(REGISTER_WEBHOOK);
  const [syncProductType] = useMutation(SYNC_PRODUCT_TYPE);

  const { history } = useContext(PublicContext);
  const { shop = "", charge_id: chargeId = "", plan = "" } = baseHelper.getQueryParams(history.location.search) || {};

  const registerSellerInput = {
    shop,
    chargeId,
    plan,
  };

  useEffect(() => {
    registerSeller({ variables: { input: registerSellerInput } })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, "registerSeller");
        if (!(responseData && responseData.accepted)) {
          setPaymentStatus(true);
        } else {
          storageHelper.set("token", responseData.token);
          storageHelper.set("shop", shop);
          registerWebhook();
          syncProductType();
          history.push("/");
        }
      })
      .catch(() => console.log(" failed at seller registration "));
  }, []);

  const setUrl = (url) => {
    window.top.location.href = url;
  };

  const renderChoice = (choice) => {
    const route = choice.key === "appUninstall" ? `https://${shop}/admin/apps/` : baseHelper.shopifyUrl(shop);
    return (
      <Card key={choice.label} title={`${choice.label}`}>
        <Card.Section>
          <Stack alignment="trailing">
            <Stack.Item fill>
              <List>
                <List.Item>{choice.description}</List.Item>
              </List>
            </Stack.Item>
            <Stack.Item>
              <Button onClick={() => setUrl(route)} primary destructive={choice.destructive}>
                {choice.value}
              </Button>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    );
  };

  const getPaymentChoices = () => {
    const paymentOptions = [
      {
        label: "Choose another plan",
        key: "choosePlan",
        value: "Choose another plan",
        description: "Charge was declined would to like to choose another plan.",
        destructive: false,
      },
      {
        label: "App Uninstall",
        key: "appUninstall",
        value: "Go to app section",
        description: "To uninstall the app go to app page",
        destructive: true,
      },
    ];
    return paymentOptions.map(renderChoice);
  };

  if (registerSellerLoading) {
    return (
      <>
        <Spinner />
        <center>
          <h3>Please Wait. We are connecting to your Store. It may take upto a minute</h3>
        </center>
      </>
    );
  }

  if (!isPaymentFailed) {
    return null;
  }

  return (
    <Layout>
      <Layout.Section>{getPaymentChoices()}</Layout.Section>
    </Layout>
  );
};

export default ShopifyPayment;

import { gql } from "apollo-boost";

const BULK_SUPPLIER_ACTION = gql`
  mutation bulkSupplierAction($input: BulkAction!) {
    bulkSupplierAction(input: $input) {
      data
      status
      error
    }
  }
`;

const PROXY_LOGIN = gql`
  mutation proxyLogin($input: ProxyLogin) {
    proxyLogin(input: $input) {
      data {
        token
      }
      status
      error
    }
  }
`;

const DELETE_VENDOR_ASSOCIATION = gql`
  mutation deleteVendorAssociation($input: DeleteVendorAssociation!) {
    deleteVendorAssociation(input: $input) {
      status
      error
      data
    }
  }
`;

export { BULK_SUPPLIER_ACTION, PROXY_LOGIN, DELETE_VENDOR_ASSOCIATION };

const cmsSellerList = (t) => {
  const cmsData = {
    resourceName: {
      singular: t("label.operatorList.Seller"),
      plural: t("label.operatorList.Sellers"),
    },
    label: {
      show_all_seller: t("label.operatorList.showAllSeller"),
      select_filter: t("label.operatorList.selectFilter"),
      like: t("label.operatorList.like"),
      filter_by: t("label.operatorList.filterBy"),
      delete_selected: t("label.operatorList.deleteSelected"),
      search: t("label.operatorList.search"),
      tabKey: t("label.operatorList.tabKey"),
      sort_name: t("label.operatorList.sortName"),
      sort_order: t("label.operatorList.sortOrder"),
      perPage: t("label.operatorList.perPage"),
      list_filter: t("label.operatorList.listFilter"),
      list_search: t("label.operatorList.listSearch"),
      filter: t("label.operatorList.filter"),
      total: t("label.operatorList.total"),
      deleteModal: t("label.operatorList.deleteModal"),
      delete: t("label.operatorList.delete"),
      primaryDelete: t("label.operatorList.primaryDelete"),
      secondaryDelete: t("label.operatorList.secondaryDelete"),
      reason: t("label.operatorList.reason"),
      logInModal: t("label.operatorList.logInModal"),
      logInAs: t("label.operatorList.logInAs"),
      otpModal: t("label.operatorList.otpModal"),
      fullAccess: t("label.operatorList.fullAccess"),
      onlyReadAccess: t("label.operatorList.onlyReadAccess"),
      enterCode: t("label.operatorList.enterCode"),
      viewDetailsFor: t("label.operatorList.viewDetailsFor"),
      name: t("label.operatorList.name"),
      email: t("label.operatorList.email"),
      onBoardedOn: t("label.operatorList.onBoardedOn"),
      page: t("label.operatorList.page"),
      plan: t("label.operatorList.plan"),
      latestActivity: t("label.operatorList.latestActivity"),
      featuresUpdate: t("label.operatorList.featuresUpdate"),
      updateFeatures: t("label.operatorList.updateFeatures"),
      maxProducts: t("label.operatorList.maxProducts"),
      maxVendors: t("label.operatorList.maxVendors"),
      branding: t("label.operatorList.branding"),
    },
    sortOptions: [
      { label: t("label.operatorList.sortByDateAsc"), value: t("label.operatorList.createdAtAsc") },
      { label: t("label.operatorList.sortByDateDesc"), value: t("label.operatorList.createdAtDesc") },
      { label: t("label.operatorList.sortByShopNameAsc"), value: t("label.operatorList.shopNameAsc") },
      { label: t("label.operatorList.sortByShopNameDesc"), value: t("label.operatorList.shopNameDesc") },
    ],
    message: {
      something_went_wrong: t("error.common.somethingWentWrong"),
      running_background: t("message.operatorList.requestSuccess"),
      token_expire: t("message.operatorList.token_expire"),
      enterAccessCode: t("message.operatorList.enterAccessCode"),
      updatedPlanMessage: t("message.operatorList.updatedPlanMessage"),
    },
    promotedBulkActions: [
      {
        content: t("label.operatorList.bulkAction"),
      },
    ],
    button: {
      deleteFromMc: t("button.operatorList.deleteOperator.primaryAction"),
      deleteFromShop: t("button.operatorList.deleteOperator.secondaryAction1"),
      confirmOtp: t("button.operatorList.accessCodeVerify.primaryAction"),
      resendOtp: t("button.operatorList.accessCodeVerify.secondaryAction1"),
      readOnly: t("button.operatorList.accessCodeVerify.secondaryAction2"),
      updatePlan: t("button.operatorList.updatePlan.primaryAction"),
      loginSeller: t("button.operatorList.logIn.primaryAction"),
    },
    sectionContent: {
      deleteSellerSelected: t("sectionContent.operatorList.deleteSellerSelected"),
      logInAsAccess: t("sectionContent.operatorList.logInAsAccess"),
      otpNote: t("sectionContent.operatorList.otpNote"),
    },
    limitOptions: [
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
    ],
    filterOptions: [
      { label: t("label.operatorList.select"), value: "" },
      { label: t("label.operatorList.brand"), value: t("label.operatorList.brandName") },
    ],
    tabs: [
      {
        id: "all-operators",
        content: t("label.operatorList.all"),
        accessibilityLabel: "All operators",
        panelID: "all-operators-content",
        value: "all",
      },
      {
        id: "pending-approval",
        content: t("label.operatorList.pendingApproval"),
        panelID: "pending-approval-content",
        value: "pending",
      },
      {
        id: "approved",
        content: t("label.operatorList.approved"),
        panelID: "approved-content",
        value: "approved",
      },
      {
        id: "free",
        content: t("label.operatorList.startUp"),
        panelID: "free-content",
        value: "free",
      },
      {
        id: "bootstrap",
        content: t("label.operatorList.bootstrap"),
        panelID: "bootstrap-content",
        value: "bootstrap",
      },
      {
        id: "growth",
        content: t("label.operatorList.growth"),
        panelID: "growth-content",
        value: "growth",
      },
      {
        id: "pro",
        content: t("label.operatorList.pro"),
        panelID: "pro-content",
        value: "pro",
      },
      {
        id: "trial",
        content: t("label.operatorList.trial"),
        panelID: "trial-content",
        value: "trial",
      },
    ],
  };

  return cmsData;
};

export default cmsSellerList;

import React from "react";
import { Caption, Stack, ResourceList, TextStyle } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import constant
import constant from "lib/constant/constant";

import Actions from "./action";

// import propType
import { itemProp } from "../../../propTypes";

const OfferItem = (props) => {
  const {
    moneyFormat,
    item,
    bands = [],
    destinations,
    products,
    isVendorAllowed,
    toggleModal,
    toggleDeleteModal,
  } = props;
  const {
    _id: id,
    shippingBandId,
    price,
    description,
    condition,
    destinationCode,
    productId,
    priceType,
    productPrice,
    productQuantity,
    orderValue,
    isCumulative,
  } = item;
  const { t } = useTranslation();

  const isProductPrice = condition === constant.PRODUCT_PRICE;
  const isProductQuantity = condition === constant.PRODUCT_QUANTITY;
  const isOrderValue = condition === constant.ORDER_VALUE;
  const shippingBand = (bands.length > 0 && bands.find((key) => key.value === shippingBandId)) || {};

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((country) => country.value === destinationCode);
  const product = products.find((pItem) => pItem.value === productId);

  const isFreeOption = priceType === constant.FREE;
  const priceTypeText = isFreeOption ? constant.FREE : constant.FIXED;

  const shortcutActions = [
    {
      content: t("label.shippingOffer.edit"),
      onAction: () => toggleModal(id),
      disabled: !isVendorAllowed,
    },
    {
      content: t("label.shippingOffer.delete"),
      destructive: true,
      onAction: () => toggleDeleteModal(id),
      disabled: !isVendorAllowed,
    },
  ];
  return (
    <ResourceList.Item id={id} accessibilityLabel={`${t("label.shippingOffer.viewDetails")} ${shippingBand.label}`}>
      <Stack alignment="leading">
        <Stack.Item fill>
          <h3>
            <TextStyle variation="strong">{shippingBand.label}</TextStyle>
          </h3>
          <Caption>{description}</Caption>
          <br />
          <Caption>
            {`${t("label.shippingOffer.destinationColon")} ${
              destination ? destination.label : t("label.shippingOffer.all")
            }`}
          </Caption>
          <Caption>
            {`${t("label.shippingOffer.productColon")} ${product ? product.label : t("label.shippingOffer.all")}`}
          </Caption>
          {isProductPrice && (
            <Caption>
              {`${t("label.shippingOffer.productPriceColon")} ${
                productPrice.criteria === constant.GREATER_THAN
                  ? t("label.shippingOffer.greaterThan")
                  : t("label.shippingOffer.lessThan")
              }`}
              {` ${moneyFormat} ${productPrice.value}`}
            </Caption>
          )}
          {isProductQuantity && (
            <Caption>
              {`${t("label.shippingOffer.productQuantityColon")} ${
                productQuantity.criteria === constant.GREATER_THAN
                  ? t("label.shippingOffer.greaterThan")
                  : t("label.shippingOffer.lessThan")
              } ${productQuantity.value}`}
            </Caption>
          )}
          {isOrderValue && (
            <Caption>
              {`${t("label.shippingOffer.orderValueColon")} ${
                orderValue.criteria === constant.GREATER_THAN
                  ? t("label.shippingOffer.greaterThan")
                  : t("label.shippingOffer.lessThan")
              }`}
              {` ${moneyFormat} ${orderValue.value}`}
            </Caption>
          )}
          <Caption>{`${t("label.shippingOffer.priceTypeColon")} ${priceTypeText}`}</Caption>
          <Caption>
            {`${t("label.shippingOffer.costColon")} ${moneyFormat} ${isFreeOption ? constant.value.MIN_PRICE : price}`}
          </Caption>
          {!isOrderValue && (
            <Caption>
              {`${t("label.shippingOffer.chargeCumulativeColon")} ${
                isCumulative ? t("label.shippingOffer.yes") : t("label.shippingOffer.no")
              }`}
            </Caption>
          )}
        </Stack.Item>
        <Actions actions={shortcutActions} />
      </Stack>
    </ResourceList.Item>
  );
};

OfferItem.propTypes = itemProp.type;

export default OfferItem;

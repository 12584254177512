import React, { useState, useContext } from "react";

import {
  Card,
  Layout,
  Stack,
  TextContainer,
  FormLayout,
  TextField,
  ResourceItem,
  Thumbnail,
  ResourceList,
  Modal,
  DisplayText,
  TextStyle,
} from "@shopify/polaris";
import { EditMinor, DeleteMinor } from "@shopify/polaris-icons";
import constant from "lib/constant/constant";
import baseHelper from "lib/helpers/base";
import { useMutation } from "react-apollo";
import { PrivateContext } from "lib/context";
import { imageHelper } from "lib/helpers";
import Popover from "lib/components/popover/popover";
import { BULK_UPDATE_VARIANT, DELETE_VARIANT } from "../../../../../../apollo/mutations";
import { variantsProp } from "../../../../../generic/edit/propTypes";

const RenderVariants = (props) => {
  const { history } = useContext(PrivateContext);
  const { data, cms, currentUser = {}, handleBanner, refetch } = props || {};
  const { _id: productItemId } = data;
  const moneyFormat = currentUser.moneyFormat || "$";
  const [updatePrice, setPrice] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [active, setActive] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [activePopover, setActivePopover] = useState({});
  const { gql } = constant;

  const [updateMarkupVariant, { loading: loadingBulkUpdate }] = useMutation(BULK_UPDATE_VARIANT);
  const [deleteVariant, { loading: loadingDeleteVariant }] = useMutation(DELETE_VARIANT);

  const handleEditMarkup = () => {
    setActive(!active);
  };
  const handleDeleteModal = (index) => {
    setSelectedId(index);
    setDeleteActive(!deleteActive);
  };
  const bulkActions = [{ content: "Update Price", onAction: () => handleEditMarkup() }];
  const handleChange = (fieldValue) => {
    setPrice(fieldValue);
  };
  const handleUpdateMarkup = () => {
    const value = {
      price: updatePrice,
      productId: productItemId,
      positions: selectedItems,
    };
    updateMarkupVariant({ variables: { input: value } })
      .then((res) => {
        setSelectedItems([]);
        const responseError = baseHelper.getResponseError(res, gql.BULK_UPDATE_VARIANT);
        if (responseError) {
          handleBanner({ isOpen: true, status: "critical", title: responseError });
        }
        handleBanner({
          isOpen: true,
          status: "success",
          title: cms("section.variant.message.updatedPrice"),
        });
        refetch();
        handleEditMarkup();
      })
      .catch(() => {
        handleBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
      });
  };
  const handleDeleteVariant = () => {
    const value = {
      id: selectedId + 1,
      productId: productItemId,
    };
    deleteVariant({ variables: { input: value } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.DELETE_VARIANT);
        if (responseError) {
          handleDeleteModal();
          handleBanner({ isOpen: true, status: "critical", title: responseError });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.DELETE_VARIANT);
        if (responseData) {
          setSelectedId(null);
          handleDeleteModal();
          refetch();
          handleBanner({ isOpen: true, status: "success", title: cms("section.image.message.success.variantDelete") });
        }
      })
      .catch(() => {
        handleBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
        handleDeleteModal();
      });
  };
  const renderDeleteModal = () => {
    return (
      <Modal
        open={deleteActive}
        onClose={handleDeleteModal}
        title={cms("section.modal.title.delete")}
        primaryAction={{
          content: cms("section.modal.button.primary.delete"),
          onAction: handleDeleteVariant,
          loading: loadingDeleteVariant,
          disabled: loadingDeleteVariant,
        }}
      >
        <Modal.Section>{cms("section.modal.content")}</Modal.Section>
      </Modal>
    );
  };
  const renderModal = () => {
    return (
      <Modal
        open={active}
        onClose={handleEditMarkup}
        title="Update Variant Price"
        primaryAction={{
          content: "Update Price",
          onAction: handleUpdateMarkup,
          loading: loadingBulkUpdate,
          disabled: loadingBulkUpdate,
        }}
      >
        <Modal.Section>
          <TextField
            label={cms("section.price.label.price")}
            value={(updatePrice && updatePrice.toString()) || ""}
            placeholder={constant.ZERO_PRICE}
            type="number"
            min={0}
            step={0.01}
            onChange={(value) => handleChange(parseInt(value, 10))}
          />
        </Modal.Section>
      </Modal>
    );
  };
  const renderItem = (item, _, index) => {
    const {
      imageUrl,
      option1,
      option2,
      option3,
      option1Val,
      option2Val,
      option3Val,
      inventoryQuantity,
      price,
      sku = "",
    } = item;

    const media = (
      <Thumbnail
        source={imageHelper.resize({ url: imageUrl, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP}
        alt={`variant image ${index + 1}`}
      />
    );

    return (
      <ResourceItem id={index + 1} media={media}>
        <TextContainer>
          <Stack>
            <Stack.Item fill>
              <div>{option1Val && `${option1} : ${option1Val}`}</div>
              <div>{option2Val && `${option2} : ${option2Val}`}</div>
              <div>{option3Val && `${option3} : ${option3Val}`}</div>
            </Stack.Item>
            <Stack.Item>
              <Popover
                active={activePopover[index]}
                setActive={() => setActivePopover({ [index]: !activePopover[index] })}
                options={[
                  {
                    content: "Edit",
                    icon: EditMinor,
                    onAction: () => history.push(`/variants/edit/${productItemId}/${index + 1}`),
                  },
                  { content: "Delete", icon: DeleteMinor, destructive: true, onAction: () => handleDeleteModal(index) },
                ]}
              />
            </Stack.Item>
          </Stack>
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField
                label={cms("section.modal.label.inventory")}
                value={inventoryQuantity && inventoryQuantity.toString()}
                disabled
              />
              <TextField
                prefix={(moneyFormat === "$" && moneyFormat) || "Rs."}
                label={cms("section.variant.label.price")}
                value={price && price.toString()}
                disabled
              />
              <TextField label={cms("section.variant.label.sku")} value={sku} disabled />
            </FormLayout.Group>
          </FormLayout>
        </TextContainer>
      </ResourceItem>
    );
  };
  const view = (dataNew) => {
    const { _id: id, variants } = dataNew;
    return (
      <Layout.Section>
        <Card
          sectioned
          title={cms("section.variant.title")}
          actions={[
            {
              content: cms("section.variant.headerAction"),
              onAction: () => {
                history.push(`/variants/add/${id}`);
              },
            },
          ]}
        >
          {!(variants && variants.length) && (
            <>
              <TextContainer>
                <DisplayText size="extraSmall">{cms("section.variant.content")}</DisplayText>
                <br />
                <DisplayText size="extraSmall">
                  <TextStyle variation="strong">{`${cms("section.variant.note.label")}: `}</TextStyle>
                  {cms("section.variant.note.content")}
                </DisplayText>
              </TextContainer>
            </>
          )}
          {variants && variants.length > 0 && (
            <ResourceList
              items={variants}
              renderItem={renderItem}
              selectedItems={selectedItems}
              onSelectionChange={setSelectedItems}
              bulkActions={bulkActions}
              idForItem={(item, index) => index + 1}
            />
          )}
        </Card>
      </Layout.Section>
    );
  };
  return [renderModal(), renderDeleteModal(), view(data)];
};
RenderVariants.propTypes = variantsProp.type;
export default RenderVariants;

import React, { useContext } from "react";
import { PrivateContext } from "lib/context";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { QuickReport } from "app/reports";
import { SkeletonList } from "lib/components";
import { TwoColumnLayout } from "layout/private/components";

import { ShopifyProducts } from "./provider/features";

const { userKey } = constant;
const { provider } = userKey;

const ShopifyList = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <SkeletonList />;
    }
    if (role === provider) {
      return <ShopifyProducts />;
    }
    return null;
  };
  const getSecondaryComponent = () => (
    <>
      <QuickReport />
    </>
  );
  const currentUserRole = storageHelper.get("userRole");
  return <TwoColumnLayout primary={getComponent(currentUserRole)} secondary={getSecondaryComponent()} />;
};

export default ShopifyList;

import React, { useContext } from "react";
import { FooterHelp, Button } from "@shopify/polaris";
import { PublicContext } from "lib/context";
import { baseHelper, storageHelper } from "lib/helpers";
import config from "config";

const Footer = () => {
  const { history, cms } = useContext(PublicContext);
  const url = baseHelper.parseUrl(config.rootURL);
  const isCustomDomain = window.location.hostname !== url.hostname;
  const footerContent = cms("footer").map((item) => {
    if (item && item.link) {
      const redirectUri = isCustomDomain ? "/register" : item.link;
      return (
        <Button key={item.content} plain id="registerHere" onClick={() => history.push(redirectUri)}>
          {item.content}
        </Button>
      );
    }
    return (
      <React.Fragment key={item.content}>
        {isCustomDomain || storageHelper.get("hideMcDetails") ? "New Vendor? " : item.content}
      </React.Fragment>
    );
  });
  return <FooterHelp>{footerContent}</FooterHelp>;
};

export default Footer;

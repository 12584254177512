// import packages
import React, { useState, useContext, useEffect } from "react";
import { Banner as PolarisBanner, Caption, Form, FormLayout, Layout, List, Select, TextField } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import helper
import { baseHelper } from "lib/helpers";

// import components
import { Banner, Spinner, CardFooter } from "lib/components";

// import gql
import { UPDATE_COMMISSION } from "app/setup/apollo/mutations";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import hoc
import { withFeature } from "lib/hoc";

// import yup validation
import validate from "../../yup";

// import propTypes
import { standardCommissionProp } from "../../props";

const StandardCommission = (props) => {
  const {
    standardCommissionValue,
    advanceCommissionValue,
    refetchLoading,
    priorityValue,
    rangeCommissionValue,
    setStandardValue,
    isTaxInclusive,
  } = props;

  const { currentUser = {}, cms = {} } = useContext(PrivateContext);

  const {
    FLAT,
    PERCENTAGE: PERCENTAGE_TEXT,
    SELECTED_STANDARD_TYPE,
    STANDARD_AMOUNT,
    gql: { UPDATE_COMMISSION: UPDATE_COMMISSION_TEXT },
    symbol: { DOLLAR, PERCENTAGE: PERCENTAGE_SYMBOL },
    value: { MAX_PERCENTAGE, MAX_FLAT, MIN_PRICE },
  } = constant;

  const { moneyFormat = DOLLAR } = currentUser;

  const standardLists = cms("section.standardCommission.caption") || [];
  const valueOptions = [
    {
      label: cms("common.label.flat"),
      value: FLAT,
    },
    {
      label: cms("common.label.percentage"),
      value: PERCENTAGE_TEXT,
    },
  ];

  const [commissionValue, setCommissionValue] = useState({
    selectedStandardType: "",
    standardAmount: "",
  });
  const [errorMessage, setErrorMessage] = useState();
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [loading, setLoading] = useState(true);
  const [submitEnabled, setSubmitEnable] = useState(false);
  const [setCommission, { loading: updateCommissionLoading }] = useMutation(UPDATE_COMMISSION);

  useEffect(() => {
    if (!refetchLoading) {
      setLoading(false);
    }
  }, [refetchLoading]);
  useEffect(() => {
    const commissionExist = !!(
      standardCommissionValue &&
      standardCommissionValue.selectedStandardType !== "" &&
      standardCommissionValue.standardAmount !== ""
    );

    if (commissionExist) {
      setCommissionValue(standardCommissionValue);
    }
  }, [standardCommissionValue]);

  const handleValidate = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const renderLists = () => {
    const list = standardLists.map((caption, keyIndex) => {
      const keyName = `caption_${keyIndex}`;
      return (
        <List.Item key={keyName}>
          <Caption>
            <b>{caption.title}</b>
            {` ${caption.description}`}
          </Caption>
        </List.Item>
      );
    });
    return list;
  };

  const updateCommission = async (requestData) => {
    try {
      const res = await setCommission({
        variables: { input: requestData },
      });

      const responseData = baseHelper.getResponseData(res.data, UPDATE_COMMISSION_TEXT);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, UPDATE_COMMISSION_TEXT);
        setBanner({ isOpen: true, status: "critical", title: errorResponse });
        return;
      }
      setBanner({ isOpen: true, status: "success", title: cms("message.success") });
      setSubmitEnable(false);
    } catch (err) {
      setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
    }
  };
  const mapRangeCommission = () => {
    return rangeCommissionValue.map((obj) => {
      const { min, ...rest } = obj;
      return rest;
    });
  };

  const handleSubmit = () => {
    if (!submitEnabled) {
      return;
    }
    if (commissionValue.selectedStandardType === PERCENTAGE_TEXT && commissionValue.standardAmount > MAX_PERCENTAGE) {
      const bannerContent = {
        isOpen: true,
        status: "critical",
        title: cms("message.error.percentageUpperLimit"),
      };
      setBanner(bannerContent);
      return;
    }
    let advanceCommission = [];
    if (
      advanceCommissionValue &&
      advanceCommissionValue.advancedValues &&
      Array.isArray(advanceCommissionValue.advancedValues) &&
      advanceCommissionValue.advancedValues.length !== 0
    ) {
      advanceCommission = advanceCommissionValue.advancedValues.map((item) => {
        if (item.price) {
          // eslint-disable-next-line no-param-reassign
          item.price = parseFloat(item.price);
        }
        return item;
      });
    }
    const isAnyRangeValue =
      rangeCommissionValue && Array.isArray(rangeCommissionValue) && rangeCommissionValue.length !== 0;
    const requestData = {
      type: commissionValue.selectedStandardType,
      price: parseFloat(commissionValue.standardAmount),
      advancedValues: advanceCommission,
      ruleBy: priorityValue,
      rangeValues: (isAnyRangeValue && mapRangeCommission()) || [],
      isTaxInclusive,
    };
    updateCommission(requestData);
  };
  const maxAmount = (commissionValue.selectedStandardType !== FLAT && MAX_PERCENTAGE) || MAX_FLAT;

  if (loading || refetchLoading) {
    return (
      <Layout.Section>
        <Spinner />
      </Layout.Section>
    );
  }

  const handleAmountChange = (value) => {
    if (value.length && !baseHelper.validatePositiveNumericValues(value)) {
      return;
    }
    setCommissionValue({ ...commissionValue, standardAmount: value });
    setStandardValue({ ...commissionValue, standardAmount: value });
    setSubmitEnable(true);
  };
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });
  const handleChange = (value) => {
    setCommissionValue({ standardAmount: "", selectedStandardType: value });
    setStandardValue({ standardAmount: "", selectedStandardType: value });
    setSubmitEnable(true);
  };

  return (
    <>
      {banner.isOpen && (
        <Banner
          isOpen={banner.isOpen}
          status={banner.status}
          title={banner.title}
          isScrollTop={false}
          onDismiss={() => dismissBanner()}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <FormLayout.Group>
            <Select
              id="standardType"
              label="Commission type"
              placeholder={cms("common.placeholder.type")}
              value={commissionValue.selectedStandardType}
              options={valueOptions}
              onChange={handleChange}
              onBlur={() => handleValidate(SELECTED_STANDARD_TYPE, commissionValue.selectedStandardType)}
              error={errorMessage && errorMessage.selectedStandardType}
            />
            <TextField
              id="standardAmount"
              label="Commission value"
              value={commissionValue.standardAmount}
              min={MIN_PRICE}
              max={maxAmount}
              prefix={(commissionValue.selectedStandardType === FLAT && moneyFormat) || ""}
              suffix={(commissionValue.selectedStandardType !== FLAT && PERCENTAGE_SYMBOL) || ""}
              onChange={(value) => handleAmountChange(value)}
              onBlur={() => handleValidate(STANDARD_AMOUNT, commissionValue.standardAmount)}
              error={errorMessage && errorMessage.standardAmount}
            />
          </FormLayout.Group>
        </FormLayout>
        <br />
        <PolarisBanner status="info">
          <p>{cms("common.label.option")}</p>
          <br />
          <p>
            <List type="bullet">{renderLists()}</List>
          </p>
        </PolarisBanner>
        <CardFooter disabled={!submitEnabled} loading={updateCommissionLoading || false} />
      </Form>
    </>
  );
};

StandardCommission.propTypes = standardCommissionProp.type;
StandardCommission.defaultProps = standardCommissionProp.default;

export default withFeature(StandardCommission, { feature: constant.GLOBAL_COMMISSION });

import { yupHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneNumberSchema,
  citySchema,
  countrySchema,
  postalCodeSchema,
  streetAddressSchema,
  brandNameSchema,
  brandHandleSchema,
  // provinceCodeSchema,
} from "./schema";

const {
  BRAND_NAME,
  BRAND_SLUG,
  CITY,
  COUNTRY,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  POSTAL_CODE,
  // PROVINCE_CODE,
  STREET_ADDRESS,
} = constant;

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value) => {
  let promise = "";
  switch (field) {
    case FIRST_NAME:
      promise = validateValue(firstNameSchema, { firstName: value });
      break;
    case LAST_NAME:
      promise = validateValue(lastNameSchema, { lastName: value });
      break;
    case EMAIL:
      promise = validateValue(emailSchema, { email: value });
      break;
    case PHONE_NUMBER:
      promise = validateValue(phoneNumberSchema, { phoneNumber: value });
      break;
    case CITY:
      promise = validateValue(citySchema, { city: value });
      break;
    case COUNTRY:
      promise = validateValue(countrySchema, { country: value });
      break;
    case POSTAL_CODE:
      promise = validateValue(postalCodeSchema, { postalCode: value });
      break;
    // case PROVINCE_CODE:
    //   promise = validateValue(provinceCodeSchema, { provinceCode: value });
    //   break;
    case STREET_ADDRESS:
      promise = validateValue(streetAddressSchema, { streetAddress: value });
      break;
    case BRAND_NAME:
      promise = validateValue(brandNameSchema, { brandName: value });
      break;
    case BRAND_SLUG:
      promise = validateValue(brandHandleSchema, { brandHandle: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};
export default validate;

class Status {
  constructor() {
    this.displayStatus = {
      accepted: "Accepted",
      alpha: "Alpha",
      approved: "Approved",
      beta: "Beta",
      bounced: "Bounced",
      cancelled: "Cancelled",
      clicked: "Clicked",
      closed: "Closed",
      deferred: "Deferred",
      deleted: "Deleted",
      deletedbyseller: "Deleted By Seller",
      delivered: "Delivered",
      dispute: "Dispute",
      dropped: "Dropped",
      fulfilled: "Fulfilled",
      invited: "Invited",
      joined: "Joined",
      live: "Live",
      "needs review": "Review",
      new: "New",
      opened: "Opened",
      paid: "Paid",
      partial: "Partial",
      partiallyfulfilled: "Partially Fulfilled",
      pending: "Pending",
      processed: "Processed",
      quotalimit: "Qouta Exceeded",
      rejected: "Rejected",
      resent: "Resent",
      return: "Return",
      reviewed: "Reviewed",
      revoked: "Cancelled",
      shipped: "Shipped",
      spam: "Spam Report",
      unfulfilled: "Unfulfilled",
      unpaid: "Unpaid",
    };
  }

  /**
   * @desc To get badge status
   * @param value status
   *
   * @return array
   *
   */
  getBadgeStatus = (value = "") => {
    const badgeStatus = value ? this.displayStatus[value.toLowerCase()] : "";
    return badgeStatus || value;
  };
}

export default new Status();

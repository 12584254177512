import React from "react";
import PropTypes from "prop-types";
import { Card, FormLayout, TextField } from "@shopify/polaris";

const Title = (props) => {
  const { data, onChange, variantOption = [], error } = props;

  const handleTitle = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const renderTextField = () => {
    const textFields = [];
    Object.keys(variantOption).forEach((variantLabel) => {
      if (variantOption[variantLabel]) {
        const hasError =
          (!data[variantLabel] || (data[variantLabel] && data[variantLabel].trim() === "")) &&
          error[variantLabel] &&
          `Please fill ${variantOption[variantLabel]}`;
        const textField = (
          <TextField
            key={variantLabel}
            id={variantLabel}
            label={`${variantOption[variantLabel]}*`}
            value={data[variantLabel] || ""}
            onChange={(value) => handleTitle(variantLabel, value)}
            placeholder={variantOption[variantLabel]}
            error={hasError}
          />
        );
        textFields.push(textField);
      }
    });
    return textFields;
  };

  return (
    <Card title="Title" sectioned>
      <FormLayout>{renderTextField()}</FormLayout>
    </Card>
  );
};

Title.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  variantOption: PropTypes.objectOf(PropTypes.string),
  error: PropTypes.objectOf(PropTypes.bool).isRequired,
  onChange: PropTypes.func.isRequired,
};

Title.defaultProps = {
  variantOption: [],
};

export default Title;

import React from "react";
import Domain from "./domain";
import Description from "./description";
import Image from "./logo";
import Cover from "./cover";
import Color from "./color/color";
import Page from "./page/page";

const CustomPage = () => {
  return (
    <>
      <Domain />
      <Description />
      <Image />
      <Cover />
      <Color />
      <Page />
    </>
  );
};

export default CustomPage;

import React, { useState } from "react";
import { Card, Layout, TextContainer } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// helpers
import { baseHelper, errorHelper } from "lib/helpers";

import constant from "lib/constant/constant";

import { Banner } from "lib/components";
import FileComponent from "lib/components/dropZoneFile/dropZoneFile";

import { imageProp } from "../propTypes";

import { DELETE_PRODUCT_IMAGE, DELETE_PRODUCT_ATTACHMENT, UPLOAD_FILE } from "../../../../apollo/mutations";

const Attachment = props => {
  const { cms, data = {},  existingProductAttachments = [], setDisabledButton, setData, setExistingAttachments } = props;
  const { gql } = constant;
  const { attachments = [] } = data || {};
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
  });

  //TODO: To be added in CMS (DB).
  const fileDeleteStatus = {
    success: 'File deleted successfully.',
  }
  const [loadingId, setLoadingId] = useState("");
  const [fileStartUploading, setFileStartUploading] = useState(false);
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ editAttachments, setEditAttachments ] = useState([]);
  let editUploadedFiles = []; 
  const [deleteImage] = useMutation(DELETE_PRODUCT_IMAGE);

  const [deleteFile] = useMutation(DELETE_PRODUCT_ATTACHMENT);
  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);
  const handleBanner = () => {
    setBanner({ isOpen: false });
  };

  const handleDownload = file => {
      window.open(file.fileURL, "_blank");
  }

  const handleDeleteAttachment = (file, index) => {
    setLoadingId(index);
    const { fileId = null, fileURL = null } = file;
    const { _id: productId = "" } = data;

    const value = {
        fileId,
        fileURL,
        productId,
    };
    
    deleteFile({ variables: { input: value } })
      .then(res => {
        setLoadingId("");
        setDisabledButton(false);
        const responseError = baseHelper.getResponseError(res.data, gql.DELETE_PRODUCT_ATTACHMENT);
        if (responseError) {
          setBanner({ 
            isOpen: true,
            status: "critical",
            title: errorHelper.parse(responseError),
          });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.DELETE_PRODUCT_ATTACHMENT);
        if (responseData) {
          setBanner({
            isOpen: true,
            status: "success",
            title: fileDeleteStatus.success,
          });
          const productImages = [...existingProductAttachments];
          productImages.splice(index, 1);
          setExistingAttachments(productImages);
        }
      })
      .catch((exception) => {
        setLoadingId("");
        setBanner({ 
          isOpen: true,
          status: "critical",
          title: errorHelper.parse(exception),
         });
      });
  };

  const handleAddAttachment = selectedFiles => {
    setDisabledButton(false);
    const productfiles = (data && data.attachments) || [];
    const uploadedfiles = [...productfiles, ...selectedFiles];
    setData(prev => ({
      ...prev,
      attachments: uploadedfiles,
    }));
  };

 

  const removeAttachment = index => {
    setDisabledButton(false);
    const productAttachments = data.attachments || [];
    if (index < 0 || productAttachments.length <= 0) {
      return;
    }
    productAttachments.splice(index, 1);
    setData(prev => ({
      ...prev,
      attachments: productAttachments,
    }));
  };

  return (
    <Layout.Section>
      <Card title="Upload attachment" id="productFile" sectioned>
      <TextContainer>You can edit file(s) for the product.</TextContainer>
      <br />
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={handleBanner}
              isScrollTop={false}
            />
            <br />
          </>
        )}
        <FileComponent
          onAdd={handleAddAttachment}
          onRemove={removeAttachment}
          size={10}
          allowMultiple
          fileList={attachments || []}
          existingFileList={existingProductAttachments || []}
          //productId={productId}
          dataValue={uploadedFile}
          removeExistingFile={handleDeleteAttachment}
          downloadExistingFile={handleDownload}
         loadingPosition={loadingId}
        />
      </Card>
    </Layout.Section>
  );
};
Attachment.propTypes = imageProp.type;
export default Attachment;

import gql from "graphql-tag";

const updateOrderNote = gql`
  mutation updateOrderNote($input: UpdateOrderNote) {
    updateOrderNote(input: $input) {
      data
      status
      error
    }
  }
`;

const updateOrderLineStatus = gql`
  mutation updateOrderLineStatus($input: UpdateOrderLineStatus) {
    updateOrderLineStatus(input: $input) {
      data
      status
      error
    }
  }
`;

const updateOrderLineTracking = gql`
  mutation updateOrderLineTracking($input: UpdateOrderLineTracking) {
    updateOrderLineTracking(input: $input) {
      data
      status
      error
    }
  }
`;

const updateVendorTracking = gql`
  mutation updateVendorTracking($input: UpdateVendorTracking) {
    updateVendorTracking(input: $input) {
      data
      status
      error
    }
  }
`;

export { updateOrderNote, updateOrderLineStatus, updateOrderLineTracking, updateVendorTracking };

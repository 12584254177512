import React, { useState, useContext } from "react";
import { Layout, Tabs } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// subFeature
import ManageShipping from "./subFeatures/manageShipping";
import ShippingBand from "./subFeatures/shippingBand";

function OperatorShipping() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { cms } = useContext(PrivateContext);
  const [isShowingBandTab, setShowingBandTab] = useState(false);

  const tabs = [
    {
      id: constant.MANAGE_SHIPPING,
      content: cms("operator.tab.manage"),
      accessibilityLabel: cms("operator.tab.manage"),
      panelID: constant.MANAGE_SHIPPING_TERMS,
    },
  ];

  if (isShowingBandTab) {
    const shippingBand = {
      id: constant.SHIPPING_BAND,
      content: cms("operator.tab.band"),
      accessibilityLabel: cms("operator.tab.band"),
      panelID: constant.SHIPPING_BAND,
    };
    tabs.push(shippingBand);
  }

  return (
    <>
      <Layout.Section>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />
      </Layout.Section>
      <Layout.Section>
        {selectedTab === 0 && <ManageShipping setShowingBandTab={setShowingBandTab} setSelectedTab={setSelectedTab} />}
      </Layout.Section>
      <Layout.Section>{selectedTab === 1 && isShowingBandTab && <ShippingBand />}</Layout.Section>
    </>
  );
}

export default withFeature(withErrorBoundary(OperatorShipping));

const fieldData = (cms) => {
  const productSettingPageData = [
    {
      label: cms("operator.content.reviewAll.title"),
      helpText: cms("operator.content.reviewAll.description"),
      key: "review",
      name: "productSetting",
    },
    {
      label: cms("operator.content.expectPrice&Quantity.title"),
      helpText: cms("operator.content.expectPrice&Quantity.description"),
      key: "selected",
      name: "productSetting",
    },
    {
      label: cms("operator.content.autoApprove.title"),
      helpText: cms("operator.content.autoApprove.title"),
      key: "all",
      name: "productSetting",
    },
  ];
  return productSettingPageData;
};

export default fieldData;

import { gql } from "apollo-boost";

const ACCOUNT_LIST = gql`
  query getAccountList($input: GetListInput) {
    getAccountList(input: $input) {
      data {
        count
        paymentList {
          paidVia
          isShippingPaid
          _id
          accountingStatus
          commissionSeller
          commissionSysAdmin
          commissionVendor
          createdAt
          dueDate
          moneyFormat
          fulfillmentStatus
          orderId
          orderNumber
          shippingAmount
          status
          vendor
          vendorId
        }
      }
      status
      error
    }
  }
`;

export default ACCOUNT_LIST;

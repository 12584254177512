import React from "react";
import { Checkbox, TextField, Layout, Card, Caption, TextStyle, FormLayout, TextContainer } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { baseHelper } from "lib/helpers";

const ShippingSection = (props) => {
  const { t } = useTranslation();
  const {
    handleChange,
    shippingInformation,
    errorShippingMessage,
    handleShippingValidation,
    learnMore,
    lastUpdated,
  } = props;

  const getTextFields = () => {
    const fields = [
      {
        label: t("label.manageVendor.streetAddress"),
        placeholder: t("label.manageVendor.streetAddress"),
        isRequired: true,
        key: "streetAddress",
      },
      { label: t("label.manageVendor.city"), placeholder: t("label.manageVendor.city"), isRequired: true, key: "city" },
      {
        label: t("label.manageVendor.country"),
        placeholder: t("label.manageVendor.country"),
        isRequired: true,
        key: "country",
      },
      {
        label: t("label.manageVendor.postalCode"),
        placeholder: t("label.manageVendor.postalCode"),
        isRequired: false,
        key: "postalCode",
      },
    ];
    return fields.map(({ label, placeholder, isRequired, key }) => {
      const required = isRequired ? "*" : "";
      return (
        <>
          <TextField
            label={`${label}${required}`}
            placeholder={placeholder}
            value={shippingInformation[key]}
            onChange={(value) => handleChange(key, value)}
            onBlur={() => (isRequired && handleShippingValidation(key, shippingInformation[key])) || true}
            error={errorShippingMessage && errorShippingMessage[key]}
          />
          <br />
        </>
      );
    });
  };
  const getFields = () => {
    return (
      <>
        {getTextFields()}
        <Checkbox
          checked={shippingInformation.isSameAsBusinessAddress}
          label={t("label.manageVendor.sameAsBusinessAddress")}
          onChange={(value) => handleChange("isSameAsBusinessAddress", value)}
        />
      </>
    );
  };
  return (
    <Layout.AnnotatedSection
      title={t("sectionContent.manageVendor.shippingSection.title")}
      description={t("sectionContent.manageVendor.shippingSection.description")}
    >
      <Card
        title={[
          t("sectionContent.manageVendor.shippingSection.title"),
          lastUpdated && (
            <TextStyle variation="subdued">
              <Caption>{`Updated : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>
            </TextStyle>
          ),
        ]}
        actions={[
          {
            content: "Learn More",
            onAction: () => {
              learnMore(t("sectionContent.manageVendor.shippingSection.title"), "TODO:");
            },
          },
        ]}
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>
              Fields as present in shipping information section would appear in the vendor setup page
            </TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

ShippingSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleShippingValidation: PropTypes.func.isRequired,
  shippingInformation: PropTypes.shape({
    isSameAsBusinessAddress: PropTypes.bool,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  errorShippingMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  learnMore: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
};

ShippingSection.defaultProps = {
  errorShippingMessage: PropTypes.bool,
  lastUpdated: "",
};

export default ShippingSection;

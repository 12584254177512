import React, { useContext } from "react";
import { Card, TextField, Stack, ColorPicker, Layout, Button, Collapsible, TextContainer } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../../../context/context";

const ButtonText = () => {
  const { color, hex, errorMessage, handleChange, resetDefault, learnMore } = useContext(BrandingContext);
  const { cms = () => {} } = useContext(PrivateContext);
  const { DEFAULT_TEXT_COLOR, BUTTON_TEXT, HEX, COLOR } = constant;

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.buttonTextColor.title")}
        description={cms("section.buttonTextColor.description")}
      >
        <Card
          title={cms("section.buttonTextColor.title")}
          actions={[
            { content: "Learn more", onAction: () => learnMore(cms("section.buttonTextColor.title"), "TODO: ") },
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.buttonTextColor.subDescription")}</TextContainer>
            <br />
            <Stack>
              <Stack.Item fill>
                <TextField
                  label={cms("section.buttonTextColor.label.hexCode")}
                  value={hex.buttonTextHex}
                  onChange={(value) => handleChange(BUTTON_TEXT, value, HEX)}
                  labelAction={
                    hex.buttonTextHex !== DEFAULT_TEXT_COLOR && {
                      content: cms("label.reset"),
                      onClick: () => resetDefault(BUTTON_TEXT, DEFAULT_TEXT_COLOR, HEX),
                    }
                  }
                  error={errorMessage && errorMessage.buttonText}
                />
                <br />
                <Button primary>{cms("section.buttonTextColor.label.sampleText")}</Button>
              </Stack.Item>
              <Stack.Item>
                <ColorPicker onChange={(value) => handleChange(BUTTON_TEXT, value, COLOR)} color={color.buttonText} />
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default ButtonText;

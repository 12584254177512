import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, TextField, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";

// import constant
import constant from "lib/constant/constant";

// import helper components and context
import { SkeletonAnnotated } from "lib/components";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

// import yup validation
import { SET_HUB_CONTACT_PROPERTIES } from "app/onboarding/apollo/mutations";
import validate from "../../../../generic/editProfile/yup/validate";

// import gql
import { EDIT_PROFILE_SELLER } from "../../../../../apollo/mutation";

const EditBrand = (props) => {
  const { setBanner, learnMore } = props;
  const { currentUser, isLoading, history, cms } = useContext(PrivateContext);
  const { UPDATE_SELLER_PROFILE, SET_HUB_CONTACT_PROPERTIES: SET_HUB_CONTACT } = constant.gql;

  const [updateVendorProfile, { loading: updateProfileLoading }] = useMutation(EDIT_PROFILE_SELLER);
  const [setHubContactProperties] = useMutation(SET_HUB_CONTACT_PROPERTIES);

  const [sellerData, setSellerData] = useState({
    brandName: currentUser && currentUser.brandName,
    contactEmail: currentUser.contactEmail || currentUser.email.address,
  });

  const [errorMessage, setErrorMessage] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const isAnyError = () => Object.values(errorMessage).some((error) => error);

  const handleChangeEvent = (field, value) => {
    setIsSubmitEnabled(true);
    setSellerData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleValidation = async (field, value) => {
    setIsSubmitEnabled(true);
    const error = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const setHubContactPropertiesData = async () => {
    try {
      const userDetails = baseHelper.getChatUser(currentUser);
      const { id: sellerId, dateFreeTrialStarted } = userDetails;
      const response = await setHubContactProperties({
        variables: {
          input: {
            sellerId,
            dateFreeTrialStarted,
          },
        },
      });
      const responseError = baseHelper.getResponseError(response.data, SET_HUB_CONTACT);
      if (responseError) {
        console.log("Error while creating or updating hub contact is: ", responseError);
      }
    } catch (resError) {
      console.log("Error is:", resError);
    }
  };

  const onSubmit = async () => {
    setIsSubmitEnabled(false);
    if (isAnyError()) {
      return;
    }
    try {
      const res = await updateVendorProfile({
        variables: {
          input: {
            brandName: sellerData.brandName,
            contactEmail: sellerData.contactEmail,
          },
        },
      });
      const responseError = baseHelper.getResponseError(res.data, UPDATE_SELLER_PROFILE);
      if (responseError) {
        setBanner({
          title: responseError,
          status: "critical",
          isOpen: true,
        });
        return;
      }
      setHubContactPropertiesData();
      setBanner({
        title: cms("message.success.profileUpdated"),
        status: "success",
        isOpen: true,
      });
    } catch (e) {
      setBanner({
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
        isOpen: true,
      });
    }
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      <Layout.AnnotatedSection
        title={cms("section.editProfile.title")}
        description={cms("section.editProfile.description")}
      >
        <Card
          title={[
            cms("section.editProfile.title"),
            // lastUpdated && <Caption>{`Updated at : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>,
          ]}
          primaryFooterAction={{
            content: cms("common.button.submit"),
            key: "submitButton",
            onAction: () => onSubmit(),
            loading: updateProfileLoading,
            disabled: updateProfileLoading || !isSubmitEnabled,
          }}
          secondaryFooterActions={[
            {
              content: cms("common.button.cancel"),
              key: "cancelButton",
              onAction: () => history.push("/"),
            },
          ]}
          actions={[
            {
              content: cms("common.label.learnMore"),
              onAction: () => {
                learnMore(cms("section.editProfile.title"), "TODO");
              },
            },
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.editProfile.description")}</TextContainer>
            <br />
            <FormLayout>
              <TextField
                key="brandDetailId"
                label={`${cms("common.label.brandName")}*`}
                placeholder={cms("common.placeholder.brandName")}
                value={sellerData.brandName}
                onChange={(value) => handleChangeEvent(constant.BRAND_NAME, value)}
                onBlur={(value) => handleValidation(constant.BRAND_NAME, value)}
                error={errorMessage && errorMessage.brandName}
              />
              <TextField
                label={`${cms("section.brand.label.email")}*`}
                placeholder={cms("section.brand.placeholder.email")}
                key="email"
                value={sellerData.contactEmail}
                onChange={(value) => handleChangeEvent(constant.CONTACT_EMAIL, value)}
                onBlur={() => handleValidation(constant.EMAIL, sellerData.contactEmail)}
                error={errorMessage && errorMessage.email}
              />
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
};

EditBrand.defaultProps = {
  setBanner: () => {},
  learnMore: () => {},
};

EditBrand.propTypes = {
  setBanner: PropTypes.func,
  learnMore: PropTypes.func,
};

export default EditBrand;

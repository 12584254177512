import React, { useContext } from "react";
import { Card, Layout, Stack, Collapsible, TextField, RadioButton, TextContainer } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../../../context/context";

const LoginHeader = () => {
  const { cms = () => {} } = useContext(PrivateContext);
  const { LOGIN, LOGIN_TEXT, IS_DEFAULT } = constant;
  const { page, learnMore } = useContext(BrandingContext);
  const { login, handleChange, resetDefault } = page;

  const loginList = [
    {
      label: cms("section.loginPageHeader.label.default"),
      value: true,
      id: "loginDefault",
    },
    {
      label: cms("section.loginPageHeader.label.customized"),
      value: false,
      id: "loginCustom",
    },
  ];

  const renderList = () => {
    return loginList.map((list) => (
      <RadioButton
        label={list.label}
        checked={login.isDefault === list.value}
        id={list.id}
        key={list.id}
        name="login"
        onChange={() => handleChange(IS_DEFAULT, list.value, LOGIN)}
      />
    ));
  };

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.loginPageHeader.title")}
        description={cms("section.loginPageHeader.description")}
      >
        <Card
          title={cms("section.loginPageHeader.title")}
          actions={[
            { content: "Learn more", onAction: () => learnMore(cms("section.loginPageHeader.title"), "TODO: ") },
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.loginPageHeader.subDescription")}</TextContainer>
            <br />
            <Stack vertical>{renderList()}</Stack>
            <br />
            <Collapsible open={!login.isDefault} id="custom-login-collapsible">
              <TextField
                id="loginCustomText"
                label={cms("section.loginPageHeader.label.loginHeaderText")}
                value={login.loginText}
                onChange={(value) => handleChange(LOGIN_TEXT, value, LOGIN)}
                labelAction={
                  login.loginText === "" && {
                    content: cms("label.reset"),
                    onClick: () => resetDefault(LOGIN_TEXT, cms("section.loginPageHeader.value.defaultText"), LOGIN),
                  }
                }
              />
            </Collapsible>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default LoginHeader;

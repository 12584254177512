import React, { useContext, useState, useEffect } from "react";
import { Card, Checkbox, RadioButton, Layout, Stack } from "@shopify/polaris";

import { PrivateContext } from "lib/context";

// helper
import baseHelper from "lib/helpers/base";

import { Spinner, AutoComplete } from "lib/components";

import { useQuery } from "@apollo/react-hooks";

import {
  GET_SHIPPING_OFFER,
  GET_SHIPPING,
  GET_SHIPPING_BAND,
  GET_SHIPPING_RULE,
} from "../../../../../../../apollo/queries";

const ManageAggregateShipping = props => {
  const { manageAggregateShippingSettingProps, setBannerStatus } = props;
  const { currentUser, cms = {} } = useContext(PrivateContext);

  const [isGlobalShipping, setIsGlobalShipping] = useState(false);

  const [isAllowShippingBands, setIsAllowShippingBands] = useState(false);
  const [isAllowShippingRules, setIsAllowShippingRules] = useState(false);
  const [isAllowShippingOffers, setIsAllowShippingOffers] = useState(false);

  const [isAllowAllBands, setIsAllowAllBands] = useState(true);
  const [isAllowAllRules, setIsAllowAllRules] = useState(true);
  const [isAllowAllOffers, setIsAllowAllOffers] = useState(true);

  const [isAllowSelected, setIsAllowSelected] = useState(false);
  const [isAllowAll, setIsAllowAll] = useState(false);

  const [isAllowSelectedBands, setIsAllowSelectedBands] = useState(false);
  const [isAllowSelectedRules, setIsAllowSelectedRules] = useState(false);
  const [isAllowSelectedOffers, setIsAllowSelectedOffers] = useState(false);

  const [bandIds, setBandIds] = useState([]);
  const [offerIds, setOfferIds] = useState([]);
  const [ruleIds, setRuleIds] = useState([]);

  const [selectedRules, setSelectedRules] = useState([]);
  const [selectedBands, setSelectedBands] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);

  const isVendor = baseHelper.isVendor(currentUser);

  const { data: bandData, loading: bandLoading, error: bandError } = useQuery(GET_SHIPPING_BAND);

  // GET_SHIPPING_BAND data handling.
  const resBandData = baseHelper.getResponseData(bandData, "getShippingBand");
  const { shippingRows: shippingBandRows = [] } = resBandData || {};
  const bandDetail = shippingBandRows.map(shippingBand => {
    return {
      label: shippingBand.name,
      value: shippingBand._id,
    };
  });

  // GET_SHIPPING_BAND error handling.
  const resBandError = baseHelper.getResponseError(bandData, "getShippingBand");
  useEffect(() => {
    if (resBandError) {
      const banner = {
        title: resBandError,
        status: "critical",
        isOpen: true,
      };
      setBannerStatus(banner);
    }
  }, [resBandError]);

  useEffect(() => {
    if (bandError) {
      const banner = {
        title: cms("common.message.error.somethingWentWrong"),
        status: "critical",
        isOpen: true,
      };
      setBannerStatus(banner);
    }
  }, [bandError]);

  const { data: offerData, loading: offerLoading, error: offerError } = useQuery(GET_SHIPPING_OFFER);

  // GET_SHIPPING_OFFER data handling.
  const resOfferData = baseHelper.getResponseData(offerData, "getShippingOffers");
  const { shippingOffers: shippingOfferRows = [] } = resOfferData || {};
  const offerDetail = shippingOfferRows.map(shippingOffer => {
    return {
      label: shippingOffer.description,
      value: shippingOffer._id,
    };
  });

  // GET_SHIPPING_OFFER error handling.
  const resOfferError = baseHelper.getResponseError(offerData, "getShippingOffers");
  useEffect(() => {
    if (resOfferError) {
      const banner = {
        title: resOfferError,
        status: "critical",
        isOpen: true,
      };
      setBannerStatus(banner);
    }
  }, [resOfferError]);

  useEffect(() => {
    if (offerError) {
      const banner = {
        title: cms("common.message.error.somethingWentWrong"),
        status: "critical",
        isOpen: true,
      };
      setBannerStatus(banner);
    }
  }, [offerError]);

  const { data: ruleData, loading: ruleLoading, error: ruleError } = useQuery(GET_SHIPPING_RULE);

  // GET_SHIPPING_RULE data handling.
  const resRuleData = baseHelper.getResponseData(ruleData, "getShippingRules");
  const { shippingRules: shippingRuleRows = [] } = resRuleData || {};
  const ruleDetail = shippingRuleRows.map(shippingRule => {
    return {
      label: shippingRule.description,
      value: shippingRule._id,
    };
  });

  // GET_SHIPPING_RULE error handling.
  const resRuleError = baseHelper.getResponseError(ruleData, "getShippingRules");
  useEffect(() => {
    if (resRuleError) {
      const banner = {
        title: resRuleError,
        status: "critical",
        isOpen: true,
      };
      setBannerStatus(banner);
    }
  }, [resRuleError]);

  useEffect(() => {
    if (ruleError) {
      const banner = {
        title: cms("common.message.error.somethingWentWrong"),
        status: "critical",
        isOpen: true,
      };
      setBannerStatus(banner);
    }
  }, [ruleError]);

  const { data: shippingData, loading: shippingLoading, error: shippingError } = useQuery(GET_SHIPPING);

  // GET_SHIPPING data handling.
  const resShippingData = baseHelper.getResponseData(shippingData, "getShipping");

  useEffect(() => {
    if (resShippingData) {
      try {
        const { vendors = [], shipping = {}, vendorShipping = {} } = resShippingData || {};
        const { setting } = shipping || {};
        const { bandIds = [], offerIds = [], ruleIds = [], isAllBand = false, isAllRule = false, isAllOffer = false } =
          setting || {};

        const isAnyShippingBands = isAllBand || (bandIds && bandIds.length);
        const isAnyShippingRule = isAllRule || (ruleIds && ruleIds.length);
        const isAnyShippingOffer = isAllOffer || (offerIds && offerIds.length);

        const isGlobalShipping = setting ? !!(isAnyShippingBands || isAnyShippingRule || isAnyShippingOffer) : false;
        const isAllowAll = setting ? isAllBand && isAllRule && isAllOffer : true;
        const isAllowSelected = setting ? !isAllowAll : false;

        const isAllowSelectedBands = setting ? !!(bandIds && bandIds.length) : false;
        const isAllowSelectedOffers = setting ? !!(offerIds && offerIds.length) : false;
        const isAllowSelectedRules = setting ? !!(ruleIds && ruleIds.length) : false;

        setIsGlobalShipping(isGlobalShipping);

        setBandIds(bandIds);
        setOfferIds(offerIds);
        setRuleIds(ruleIds);

        setIsAllowAll(isAllowAll);
        setIsAllowSelected(isAllowSelected);

        setSelectedBands(bandIds);
        setSelectedOffers(offerIds);
        setSelectedRules(ruleIds);

        // handle selected-bands/offers/rules from bandIds/offerIds/ruleIds.
        setIsAllowAllBands(!isAllowSelectedBands);
        setIsAllowAllOffers(!isAllowSelectedOffers);
        setIsAllowAllRules(!isAllowSelectedRules);

        setIsAllowShippingBands(isAllowAllBands || (bandIds && bandIds.length));
        setIsAllowSelectedBands(bandIds && bandIds.length);
        setIsAllowShippingRules(isAllowAllRules || (ruleIds && ruleIds.length));
        setIsAllowSelectedRules(ruleIds && ruleIds.length);
        setIsAllowShippingOffers(isAllowAllOffers || (offerIds && offerIds.length));
        setIsAllowSelectedOffers(offerIds && offerIds.length);
      } catch (err) {
        const banner = {
          title: cms("common.message.error.somethingWentWrong"),
          status: "critical",
          isOpen: true,
        };
        setBannerStatus(banner);
      }
    }
  }, [resShippingData]);

  // GET_SHIPPING_RULE error handling.
  const resShippingError = baseHelper.getResponseError(shippingData, "getShipping");
  useEffect(() => {
    if (resShippingError) {
      const banner = {
        title: resShippingError,
        status: "critical",
        isOpen: true,
      };
      setBannerStatus(banner);
    }
  }, [resShippingError]);

  useEffect(() => {
    if (shippingError) {
      const banner = {
        title: cms("common.message.error.somethingWentWrong"),
        status: "critical",
        isOpen: true,
      };
      setBannerStatus(banner);
    }
  }, [shippingError]);

  const loading = bandLoading || ruleLoading || offerLoading || shippingLoading;

  const genericLabel = `${(!isVendor && `${cms("operator.section.manageAggregate.description.bands")}`) || ""} ${cms(
    "operator.section.manageAggregate.description.rules"
  )} ${cms("operator.section.manageAggregate.description.rules")}`;

  const shippingDescription = `${cms("operator.section.manageAggregate.description.textStart")}
      ${genericLabel} ${cms("operator.section.manageAggregate.description.textEnd")}`;

  const allowSelected = `${cms("operator.section.manageAggregate.helpText.allowSelected")}
    ${genericLabel} ${cms("operator.section.manageAggregate.helpText.selectedTypeVendor")}`;
  const shippingLabel = `${cms("operator.section.manageAggregate.label.shipping")} ${genericLabel}`;

  const handleCumulativeRules = selectedIds => setSelectedRules(selectedIds);
  const handleCumulativeBands = selectedIds => setSelectedBands(selectedIds);
  const handleCumulativeOffers = selectedIds => setSelectedOffers(selectedIds);

  const handleChange = () => {
    let settingData = {};
    if (isGlobalShipping) {
      if (isAllowAll) {
        settingData = {
          isAllBand: true,
          isAllOffer: true,
          isAllRule: true,
          isAllVendor: true,
          bandIds: [],
          offerIds: [],
          ruleIds: [],
          vendorIds: [],
        };
      } else {
        if (isAllowShippingBands) {
          if (isAllowAllBands) {
            settingData.isAllBand = isAllowAllBands;
            settingData.bandIds = [];
          } else {
            settingData.isAllBand = false;
            settingData.bandIds = selectedBands || bandIds;
          }
        }

        if (isAllowShippingRules) {
          if (isAllowAllRules) {
            settingData.isAllRule = isAllowAllRules;
            settingData.ruleIds = [];
          } else {
            settingData.isAllRule = false;
            settingData.ruleIds = selectedRules || ruleIds;
          }
        }

        if (isAllowShippingOffers) {
          if (isAllowAllOffers) {
            settingData.isAllOffer = isAllowAllOffers;
            settingData.offerIds = [];
          } else {
            settingData.isAllOffer = false;
            settingData.offerIds = selectedOffers || offerIds;
          }
        }
      }
    } else {
      settingData = {
        bandIds: [],
        offerIds: [],
        ruleIds: [],
        vendorIds: [],
      };
    }
    return manageAggregateShippingSettingProps(settingData);
  };

  useEffect(() => {
    handleChange();
  }, [
    isAllowAllBands,
    isAllowAllOffers,
    isAllowAllRules,
    selectedRules,
    selectedBands,
    selectedOffers,
    isGlobalShipping,
  ]);

  return (
    <Layout.AnnotatedSection title="" description={shippingDescription}>
      {(loading && <Spinner />) || (
        <Card sectioned>
          <Checkbox
            checked={isGlobalShipping}
            onChange={() => setIsGlobalShipping(!isGlobalShipping)}
            label={shippingLabel}
          />
          {isGlobalShipping && (
            <div>
              <RadioButton
                label={cms("operator.section.manageAggregate.label.allTypeVendor")}
                helpText={cms("operator.section.manageAggregate.helpText.allTypeVendor")}
                checked={isAllowAll}
                onChange={() => {
                  setIsAllowAll(true);
                  setIsAllowSelected(false);
                }}
              />

              <RadioButton
                label={cms("operator.section.manageAggregate.label.selectedTypeVendor")}
                helpText={allowSelected}
                checked={isAllowSelected}
                onChange={() => {
                  setIsAllowSelected(true);
                  setIsAllowAll(false);
                }}
              />

              {isAllowSelected && (
                <Card sectioned>
                  <Stack.Item>
                    <Checkbox
                      label={cms("operator.section.manageAggregate.label.bands")}
                      checked={isAllowShippingBands}
                      onChange={() => setIsAllowShippingBands(!isAllowShippingBands)}
                    />
                    {isAllowShippingBands && (
                      <div>
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.allBands")}
                          checked={isAllowAllBands}
                          onChange={() => {
                            setIsAllowAllBands(true);
                            setIsAllowSelectedBands(false);
                          }}
                        />
                        <br />
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.selectedBands")}
                          checked={isAllowSelectedBands}
                          onChange={() => {
                            setIsAllowSelectedBands(true);
                            setIsAllowAllBands(false);
                          }}
                        />
                        {!!isAllowSelectedBands && (
                          <AutoComplete
                            label={cms("operator.section.manageAggregate.label.bands")}
                            labelHidden
                            placeholder={cms("operator.section.manageAggregate.placeHolder.bands")}
                            values={bandDetail}
                            selected={bandIds}
                            onChange={handleCumulativeBands}
                            minimumSearchLength={3}
                          />
                        )}
                      </div>
                    )}
                    <br />
                    <Checkbox
                      label={cms("operator.section.manageAggregate.label.rules")}
                      checked={isAllowShippingRules}
                      onChange={() => setIsAllowShippingRules(!isAllowShippingRules)}
                    />
                    {isAllowShippingRules && (
                      <div>
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.allRules")}
                          checked={isAllowAllRules}
                          onChange={() => {
                            setIsAllowAllRules(true);
                            setIsAllowSelectedRules(false);
                          }}
                        />
                        <br />
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.selectedRules")}
                          checked={isAllowSelectedRules}
                          onChange={() => {
                            setIsAllowSelectedRules(true);
                            setIsAllowAllRules(false);
                          }}
                        />
                        {!!isAllowSelectedRules && (
                          <AutoComplete
                            label={cms("operator.section.manageAggregate.label.rules")}
                            labelHidden
                            placeholder={cms("operator.section.manageAggregate.placeHolder.rules")}
                            values={ruleDetail}
                            selected={ruleIds}
                            onChange={handleCumulativeRules}
                            minimumSearchLength={3}
                          />
                        )}
                      </div>
                    )}
                    <br />
                    <Checkbox
                      label={cms("operator.section.manageAggregate.label.offers")}
                      checked={isAllowShippingOffers}
                      onChange={() => setIsAllowShippingOffers(!isAllowShippingOffers)}
                    />
                    <br />
                    {isAllowShippingOffers && (
                      <div>
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.allOffers")}
                          checked={isAllowAllOffers}
                          onChange={() => {
                            setIsAllowAllOffers(true);
                            setIsAllowSelectedOffers(false);
                          }}
                        />
                        <br />
                        <RadioButton
                          label={cms("operator.section.manageAggregate.label.selectedOffers")}
                          checked={isAllowSelectedOffers}
                          onChange={() => {
                            setIsAllowSelectedOffers(true);
                            setIsAllowAllOffers(false);
                          }}
                        />
                        {!!isAllowSelectedOffers && (
                          <AutoComplete
                            label={cms("operator.section.manageAggregate.label.offers")}
                            labelHidden
                            placeholder={cms("operator.section.manageAggregate.placeHolder.offers")}
                            values={offerDetail}
                            selected={offerIds}
                            onChange={handleCumulativeOffers}
                            minimumSearchLength={3}
                          />
                        )}
                      </div>
                    )}
                  </Stack.Item>
                </Card>
              )}
            </div>
          )}
        </Card>
      )}
    </Layout.AnnotatedSection>
  );
};

export default ManageAggregateShipping;

import React from "react";
import { Stack, List, DisplayText } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { destinationProp } from "../propsType/index";

const Destinations = (props) => {
  const { t } = useTranslation();
  const { destinationData, destinationCodes } = props;
  const list = destinationData.map((item) => {
    const { title, options = [] } = item;
    const selectedOptions = options.filter((option) => {
      return destinationCodes.includes(option.value);
    });
    if (!selectedOptions.length) {
      return null;
    }
    return (
      <List.Item key={`${item.title}${selectedOptions.length}`}>
        {title}
        <List>
          {selectedOptions.map((option) => (
            <List.Item key={option.value}>{option.label}</List.Item>
          ))}
        </List>
      </List.Item>
    );
  });
  const destinationList = list.filter((item) => item);

  if (!destinationList.length) {
    return null;
  }

  return (
    <Stack>
      <Stack.Item>
        <DisplayText>{t("label.SelectedDestinations")}</DisplayText>
      </Stack.Item>
      <Stack.Item>
        <List>{destinationList}</List>
      </Stack.Item>
    </Stack>
  );
};

Destinations.propTypes = destinationProp.type;

export default Destinations;

import React, { useContext, useState } from "react";
import { Layout, Card, Select, FormLayout, TextContainer } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context/privateContext";

// import components
import Banner from "lib/components/banner/banner";
import { SkeletonAnnotated } from "lib/components";
import { BulkCreate, ManualForm } from "./subFeatures";

const { CREATE_FORM, CREATE_CSV } = constant;

const OperatorProductAdd = () => {
  const { cms, isLoading } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
  });
  const [selected, setSelected] = useState();
  const option = [
    { label: cms("section.addProductMethod.form"), value: CREATE_FORM },
    { label: cms("section.addProductMethod.bulk"), value: CREATE_CSV },
  ];
  const renderComponent = (value) => {
    if (value === CREATE_FORM) {
      return <ManualForm setBanner={setBanner} />;
    }

    if (value === CREATE_CSV) {
      return <BulkCreate setBanner={setBanner} />;
    }
    return null;
  };
  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection
        title={cms("section.addProductMethod.title")}
        description={cms("section.addProductMethod.description")}
      >
        <Card title="Add products">
          <Card.Section>
            <FormLayout>
              <TextContainer>
                <p>Use our manual form, bulk CSV upload to add products to your marketplace account.</p>
                <p>
                  Note: Any products that you add will need to be approved by the markerplace operator before they are
                  made live on their store. Until such time products will remain in a `new` state.
                </p>
              </TextContainer>
              <Select
                id="addProduct"
                options={option}
                placeholder={cms("section.addProductMethod.placeholder.addProduct")}
                onChange={(value) => setSelected(value)}
                value={selected}
              />
            </FormLayout>
          </Card.Section>
        </Card>
        {renderComponent(selected)}
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProductAdd), { feature: constant.ADD_PRODUCT });

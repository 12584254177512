import React, { useState, useContext } from "react";
import { Card, FormLayout, Layout, TextField, PageActions, Checkbox, TextContainer } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";

import { PrivateContext, OnboardingContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import formConfig from "./formConfig";
import validate from "./yup";

import { CREATE_VENDOR } from "../../../../../../apollo/mutations";

const FormCreate = (props) => {
  const { t } = useTranslation();
  const { setBanner, learnMore } = props;
  const { gql, BRAND_HANDLE, BRAND_NAME } = constant;
  const [errorMessage, setErrorMessage] = useState({});
  const [createVendorProfile, { loading }] = useMutation(CREATE_VENDOR);
  const { history, cms } = useContext(PrivateContext);
  const { isOnboarding = false, onNext, onPrevious } = useContext(OnboardingContext);
  const { textFields, brandFields } = formConfig(cms);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    brandHandle: "",
    brandName: "",
    streetAddress: "",
    city: "",
    country: "",
    postalCode: "",
    isOtp: true,
    isVerified: true,
    isReadOnly: false,
    isVendor: true,
    password: baseHelper.generateRandomPassword(),
  });

  const handleBrandChange = (value) => {
    const manipulatedBrandHandleValue = baseHelper.generateBrandHandle(value);
    setValues((prevState) => ({
      ...prevState,
      [BRAND_NAME]: value,
      [BRAND_HANDLE]: manipulatedBrandHandleValue,
    }));
  };

  const handleChangeEvent = (field, value) => {
    if (field === constant.PHONE_NUMBER && !baseHelper.validateWholeNumber(value) && value) {
      return;
    }

    if (field === BRAND_NAME) {
      handleBrandChange(value);
    }

    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const isAnyError = () => Object.values(errorMessage).some((error) => error);
  const isAllValuesFilled = () => Object.values(values).every((value) => (typeof value === "boolean" ? true : value));

  const toggleBanner = () =>
    setBanner({
      action: null,
      isOpen: false,
      status: "",
      title: "",
      onDismiss: null,
    });

  const onSubmit = async () => {
    try {
      const res = await createVendorProfile({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            brand: {
              slug: values.brandHandle,
            },
            brandName: values.brandName,
            address: values.streetAddress,
            city: values.city,
            country: values.country,
            pinCode: values.postalCode,
            password: values.password,
            isOtp: values.isOtp,
            isVerified: values.isVerified,
            isReadOnly: values.isReadOnly,
            isVendor: values.isVendor,
          },
        },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.CREATE_VENDOR);
      let bannerValue = {
        status: "success",
        title: cms("message.success"),
        children: t("message.updateVendorProfile.shopifyPushMessage"),
        onDismiss: toggleBanner,
        action: {
          id: "viewProviders",
          content: cms("message.successAction"),
          onAction: () => history.push("/providers"),
        },
      };
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.CREATE_VENDOR);
        bannerValue = { status: "critical", title: errorResponse };
      }
      setBanner({
        ...bannerValue,
        isOpen: true,
      });
      if (isOnboarding && responseData) {
        onNext();
      }
    } catch (error) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  const cmsError = {
    brandHandle: {
      required: t("message.updateVendorProfile.brandHandle.required"),
      regx: t("message.updateVendorProfile.brandHandle.match"),
    },
  };

  const handleBrandValidation = async (value) => {
    const { brandHandle } = values;
    const brandNameError = await validate({ field: BRAND_NAME, value });
    const brandHandleError = await validate({
      field: BRAND_HANDLE,
      value: brandHandle,
      errorMessage: cmsError,
    });
    setErrorMessage((prevError) => ({
      ...prevError,
      [BRAND_NAME]: brandNameError,
      [BRAND_HANDLE]: brandHandleError,
    }));
  };

  const handleValidation = async (field, value) => {
    if (field === BRAND_NAME) {
      handleBrandValidation(value);
    }
    const error = await validate({ field, value, cms });
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  return (
    <div>
      <Layout.AnnotatedSection
        title={cms("section.createForm.title")}
        description={cms("section.createForm.description")}
      >
        <Card
          title="Brand information"
          actions={[{ content: "Learn more", onAction: () => learnMore("Brand information", "TODO: ") }]}
        >
          <Card.Section>
            <TextContainer>
              {`A brand represents a vendor in our platform hence this information is key to mapping a vendor to a
              marketplace operator's store. A brand name is also used 'as is' meaning brand names such as "Super Fly",
              "SuperFly" and "superFly" will all be different and unique names within our platform.`}
            </TextContainer>
            <br />
            <FormLayout>
              {brandFields.map((brandField) => (
                <TextField
                  label={brandField.label}
                  placeholder={brandField.placeholder}
                  key={brandField.key}
                  id={brandField.key}
                  value={values[brandField.key]}
                  onChange={(value) => handleChangeEvent(brandField.key, value)}
                  onBlur={() => handleValidation(brandField.key, values[brandField.key])}
                  error={errorMessage && errorMessage[brandField.key]}
                  helpText={brandField.helpText}
                />
              ))}
            </FormLayout>
          </Card.Section>
        </Card>

        <Card
          title="Vendor details"
          actions={[{ content: "Learn more", onAction: () => learnMore("Vendor details", "TODO: ") }]}
        >
          <Card.Section>
            <FormLayout>
              {textFields.map((textField) => (
                <TextField
                  label={textField.label}
                  placeholder={textField.placeholder}
                  key={textField.key}
                  id={textField.key}
                  value={values[textField.key]}
                  onChange={(value) => handleChangeEvent(textField.key, value)}
                  onBlur={() => handleValidation(textField.key, values[textField.key])}
                  error={errorMessage && errorMessage[textField.key]}
                />
              ))}
            </FormLayout>
          </Card.Section>
        </Card>

        <Card title="Vendor verification">
          <Card.Section>
            <TextContainer>
              <p>
                {`A vendor is required to verify their email when joining the platform however, 
                when creating a vendor you can choose to 'Auto verify vendor' thereby avoiding 
                the need to validate their email. This is useful when you are 'creating' vendor 
                accounts and hence is selected by default.`}
              </p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={cms("common.label.autoVerifyVendor")}
                checked={values.isVerified}
                onChange={(value) => handleChangeEvent("isVerified", value)}
                id="autoVerifyCheckBox"
              />
            </FormLayout>
          </Card.Section>
        </Card>

        <Card title="Vendor's access permission">
          <Card.Section>
            <TextContainer>
              <p>
                {`By default a vendor has full write access in their account however you can set a vendor's access to be
                'read only'. With 'read only' access a vendor can log into their account but they are not able to
                perform any create, update or delete actions ie. add or manage products and orders or change any
                settings.`}
              </p>
              <p>
                This permission can be revoked or added at any time by you after a vendor has been created. This feature
                is particulariy useful when you are looking to manage on behalf of your vendors.
              </p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={cms("common.label.isReadOnlyVendor")}
                checked={values.isReadOnly}
                onChange={(value) => handleChangeEvent("isReadOnly", value)}
                id="readOnlyCheckbox"
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            content: cms("common.button.submit"),
            id: "submitButton",
            onAction: () => onSubmit(),
            disabled: !isAllValuesFilled() || isAnyError(),
            loading,
          }}
          secondaryActions={[
            {
              content: isOnboarding ? "Previous" : cms("common.button.cancel"),
              id: "cancelButton",
              onAction: () => (isOnboarding ? onPrevious() : history.push("/")),
            },
          ]}
        />
      </Layout.AnnotatedSection>
    </div>
  );
};
FormCreate.propTypes = {
  setBanner: PropType.func.isRequired,
  learnMore: PropType.func.isRequired,
};

export default FormCreate;

import React from "react";

// import hoc
import { withFeature } from "lib/hoc";

// import polaris packages
import { Card } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

const ProviderMaintenance = () => {
  return (
    <Card>
      <Card.Section>This is FulfilledLinesItems page</Card.Section>
    </Card>
  );
};

export default withFeature(ProviderMaintenance, { feature: constant.MAINTENANCE });

import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex, handleBrandRegex } = yupHelper;

const firstNameSchema = (cms) =>
  yup.object().shape({
    firstName: yup.string().required(cms("common.message.error.firstNameIsRequired")),
  });

const lastNameSchema = (cms) =>
  yup.object().shape({
    lastName: yup.string().required(cms("common.message.error.lastNameIsRequired")),
  });

const emailSchema = (cms) =>
  yup.object({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail")),
  });

const phoneNumberSchema = (cms) =>
  yup.object().shape({
    phoneNumber: yup
      .string()
      .required(cms("common.message.error.phoneNumberIsRequired"))
      .min(7, cms("common.message.error.minPhoneNumber"))
      .max(15, cms("common.message.error.maxPhoneNumber")),
  });

const citySchema = (cms) =>
  yup.object().shape({
    city: yup.string().required(cms("common.message.error.cityIsRequired")),
  });

const mailSubjectSchema = (cms) =>
  yup.object().shape({
    mailSubject: yup.string().required(cms("common.message.error.subject")),
  });

const countrySchema = (cms) =>
  yup.object().shape({
    country: yup.string().required(cms("common.message.error.countryIsRequired")),
  });

const postalCodeSchema = (cms) =>
  yup.object().shape({
    postalCode: yup.string().required(cms("common.message.error.postalCodeIsRequired")),
  });

const streetAddressSchema = (cms) =>
  yup.object().shape({
    streetAddress: yup.string().required(cms("common.message.error.streetAddressIsRequired")),
  });

const brandNameSchema = (cms) =>
  yup.object().shape({
    brandName: yup.string().required(cms("common.message.error.brandNameIsRequired")),
  });

const brandHandleSchema = () => {
  return yup.object({
    brandHandle: yup
      .string()
      .required("Brand handle is required")
      .matches(
        handleBrandRegex,
        "Only lower case characters (a-z), numbers, hyphen (-) and underscore (_) are allowed in brand handle."
      ),
  });
};

export {
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  citySchema,
  countrySchema,
  brandHandleSchema,
  brandNameSchema,
  postalCodeSchema,
  streetAddressSchema,
  mailSubjectSchema,
};

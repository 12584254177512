import React, { useContext } from "react";
import {
  Banner,
  Caption,
  Card,
  FormLayout,
  Layout,
  List,
  Select,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { PrivateContext } from "lib/context";

// constants
import constant from "lib/constant/constant";

// import hoc
import { withFeature } from "lib/hoc";

// propTypes
import { baseHelper } from "lib/helpers";
import { returnProp } from "../../props";

const { ALLOWED_DAY, SELECTED_POLICY, RETURN_ALLOWED, NO_RETURN_ALLOWED } = constant;

const Returns = (props) => {
  const { cms } = useContext(PrivateContext);
  const returnOptions = [
    {
      label: cms("section.returns.label.returnsAllowed"),
      value: RETURN_ALLOWED,
    },
    {
      label: cms("section.returns.label.noReturnsAllowed"),
      value: NO_RETURN_ALLOWED,
    },
  ];

  const label = [cms("section.returns.label.returnsAllowed"), cms("section.returns.label.noReturnsAllowed")];

  const renderCaptions = (captions = []) => (
    <>
      {captions &&
        captions.map((caption, index) => (
          <List.Item key={caption}>
            <Caption>
              <b>{label[index]}</b>
              {` ${caption}`}
            </Caption>
          </List.Item>
        ))}
    </>
  );

  const { data, handleChange, handleValidation, errorMessage, learnMore, lastUpdated } = props;
  return (
    <Layout.AnnotatedSection title={cms("section.returns.title")} description={cms("section.returns.description")}>
      <Card
        title={[
          cms("section.returns.title"),
          <TextStyle variation="subdued">
            {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
          </TextStyle>,
        ]}
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => learnMore(cms("section.returns.title")),
          },
        ]}
        sectioned
      >
        <FormLayout>
          <TextContainer>{cms("section.returns.description")}</TextContainer>

          <Stack wrap={false}>
            <Select
              id="returnValue"
              placeholder={cms("common.placeholder.returnsType")}
              onChange={(value) => handleChange(SELECTED_POLICY, value)}
              options={returnOptions}
              value={data.selectedPolicy}
            />
            <TextField
              id="allowedDay"
              placeholder={cms("common.placeholder.zero")}
              suffix={(data.allowedDay && <div className="suffixColor">{constant.DAYS}</div>) || constant.DAYS}
              min={0}
              onChange={(value) => handleChange(ALLOWED_DAY, value)}
              onBlur={() => handleValidation(ALLOWED_DAY, data.allowedDay)}
              value={data.allowedDay}
              disabled={data.selectedPolicy !== RETURN_ALLOWED}
              error={errorMessage && errorMessage.allowedDay}
              type="number"
            />
          </Stack>
          <Banner status="info">
            <p>{cms("common.label.option")}</p>
            <br />
            <List type="bullet">{renderCaptions(cms("section.returns.caption"))}</List>
          </Banner>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
};
Returns.propTypes = returnProp.type;
export default withFeature(Returns, { feature: constant.RETURN });

import { UPDATE_MARKUP, UPDATE_TERM, UPDATE_DISCOUNT, UPDATE_FULFILLMENT } from "./mutations";
import {
  GET_ASSOCIATED_SELLER,
  GET_VENDOR_AND_PRODUCT_TYPES,
  GET_MARKUP,
  GET_DISCOUNT,
  STANDARD_DISCOUNT,
  GET_USER,
  GET_FULFILLMENT,
  GET_SELLER_FULFILLMENT,
} from "./queries";

export {
  GET_ASSOCIATED_SELLER,
  GET_MARKUP,
  UPDATE_TERM,
  GET_VENDOR_AND_PRODUCT_TYPES,
  UPDATE_MARKUP,
  UPDATE_DISCOUNT,
  GET_DISCOUNT,
  STANDARD_DISCOUNT,
  GET_USER,
  GET_FULFILLMENT,
  UPDATE_FULFILLMENT,
  GET_SELLER_FULFILLMENT,
};

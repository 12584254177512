import React, { useCallback, useContext, useState } from "react";
import { Button, Card, Stack, Caption, Link, List, TextStyle, Collapsible } from "@shopify/polaris";

// config
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import quickLinkConfig from "./quickLinksConfig";
import { config, title } from "../quickSetting/quickSettingConfig";

const AdminQuickLinks = () => {
  const { match, history, cms } = useContext(PrivateContext);
  const { path } = match;
  const [openActionLink, setActive] = useState(true);
  const [actionLinkExpanded, setExpanded] = useState(false);
  const handleToggle = useCallback(() => setActive((actionLink) => !actionLink), []);

  const actionCard = (link) => {
    return (
      <List>
        <List.Item>
          <Stack>
            <Link onClick={() => history.push(link.link)}>
              <TextStyle variation="strong">{link.label}</TextStyle>
            </Link>
          </Stack>
          <Stack>
            <Caption>{link.caption}</Caption>
          </Stack>
        </List.Item>
      </List>
    );
  };

  const getQuickLinks = (links = []) => {
    return links.map((link) => actionCard(link));
  };

  const getQuickSettings = (settings = []) => {
    return settings.map((setting) => actionCard(setting));
  };

  return (
    <Card>
      <Card.Header title={constant.ACTION}>
        <Button
          plain
          disclosure={actionLinkExpanded ? "down" : "up"}
          onClick={() => {
            handleToggle();
            setExpanded(!actionLinkExpanded);
          }}
        >
          {actionLinkExpanded ? "Show" : "Hide"}
        </Button>
      </Card.Header>

      <br />

      <Collapsible
        open={openActionLink}
        id="collapsibleQuickLink"
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <Card>
          <Card.Section subdued>{getQuickLinks(quickLinkConfig[path])}</Card.Section>

          {config[path] && (
            <Card.Section subdued title={title[path] || cms("common.actions.title.setting")}>
              {getQuickSettings(config[path])}
            </Card.Section>
          )}
        </Card>
      </Collapsible>
    </Card>
  );
};

export default AdminQuickLinks;

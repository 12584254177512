import { gql } from "apollo-boost";

const GET_TEMP_PRODUCT_LIST = gql`
  query getTempProductList($input: GetListInput) {
    getTempProductList(input: $input) {
      data {
        count
        tempProductList {
          _id
          id
          approved
          status
          title
          vendor
          vendorId
          updatedAt
          images {
            imageUrl
            url
          }
          published
        }
      }
      error
      status
    }
  }
`;

export default GET_TEMP_PRODUCT_LIST;

import React from "react";

import constant from "lib/constant/constant";

import GenericExportOrder from "./genaricExport/export";

import { storageHelper } from "../../../lib/helpers";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const ExportOrder = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <GenericExportOrder />;
    }
    if (role === operator) {
      return <GenericExportOrder />;
    }
    if (role === provider) {
      return <GenericExportOrder />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const ExportOrderComponent = getComponent(currentUserRole);
  return ExportOrderComponent;
};

export default ExportOrder;

import React, { useContext } from "react";
import { useQuery } from "react-apollo";
import { FETCH_MAINTENANCE } from "app/public/apollo/queries";
import { PublicContext } from "lib/context";
import { Spinner } from "lib/components";
import { baseHelper } from "lib/helpers";
import config from "config";

import UnderMaintenance from "./feature/underMaintenance";

const Install = () => {
  const { data: maintenanceData, loading: maintenanceLoading } = useQuery(FETCH_MAINTENANCE);
  const { history } = useContext(PublicContext);

  if (maintenanceLoading) {
    return <Spinner isFullPage />;
  }

  const redirect = () => {
    const queryParams = baseHelper.getQueryParams(history.location.search);
    let { shop } = queryParams;
    const { hmac = "" } = queryParams;

    if (history.location.state) {
      shop = history.location.state.shop;
    }

    const clientId = config.shopifyAPIKey;
    const redirectURI = `${config.rootURL}/shopify/plan`;

    const scope =
      "read_products, write_products, read_customers, write_customers, " + // read_orders
      // eslint-disable-next-line max-len
      "write_orders, read_fulfillments, read_orders, read_inventory, write_inventory, write_fulfillments, read_shipping, write_shipping ";

    // eslint-disable-next-line max-len
    const url = `https://${shop}/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectURI}&state=${hmac}`;
    window.top.location.href = url;
  };

  const { getMaintenance = {} } = maintenanceData || {};
  const { isApp = false } = getMaintenance.data || {};

  if (isApp) {
    return <UnderMaintenance />;
  }

  redirect();

  if (baseHelper.isIframe()) {
    return null;
  }
  return <div className="text-center">Redirecting to Install accept Page</div>;
};

export default Install;

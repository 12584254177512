// import user related string constants
import constant from "../constant/constant";

const { userKey } = constant;

class AccessHelper {
  /**
   * @desc To get user level
   *
   * @param {Object} user User data
   *
   * @return {String} userLevel
   */
  getLevel = (user) => (user && (user.parentUserLevel || user.userLevel)) || "";

  /**
   * @desc To get user role
   *
   * @param {Object} user User data
   *
   * @return {String} userLevel
   */
  getRole = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    const userRole = user.parentUserRole || (user.roles && user.roles.name) || "";

    if (userLevel === userKey.systemAdminLevel) {
      return userKey.systemAdminRole;
    }

    if (userLevel === userKey.accountAdminLevel) {
      return userRole;
    }

    return "";
  };

  /**
   * @desc To check if user is system admin
   *
   * @param {Object} user User data
   *
   * @return {Boolean} true/false
   */
  isSystemAdmin = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    return userLevel === userKey.systemAdminLevel;
  };

  /**
   * @desc To check if user is seller
   *
   * @param {Object} user User data
   *
   * @return {Boolean} true/false
   */
  isSeller = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    const userRole = user.parentUserRole || (user.roles && user.roles.name) || "";
    return userLevel === userKey.accountAdminLevel && userRole === userKey.sellerRole;
  };

  /**
   * @desc To check if user is vendor
   *
   * @param {Object} user User data
   *
   * @return {Boolean} true/false
   */
  isVendor = (user) => {
    if (!user) return false;
    const userLevel = user.parentUserLevel || user.userLevel;
    const userRole = user.parentUserRole || (user.roles && user.roles.name) || "";
    return userLevel === userKey.accountAdminLevel && userRole === userKey.vendorRole;
  };

  /**
   * @desc To get the updated (admin, operator, provider) user role
   *
   * @param user
   *
   * @return string (admin, operator, provider)
   * @return "" if user is invalid
   *
   */
  getUserRole = (user) => {
    if (!user) {
      return "";
    }
    const userLevel = user.parentUserLevel || user.userLevel;
    const userRole = user.parentUserRole || (user.roles && user.roles.name) || "";

    if (userLevel === userKey.systemAdminLevel) {
      return userKey.admin;
    }

    if (userLevel === userKey.accountAdminLevel && userRole === userKey.seller) {
      return userKey.operator;
    }

    if (userLevel === userKey.accountAdminLevel && userRole === userKey.vendor) {
      return userKey.provider;
    }

    return "";
  };
}

export default new AccessHelper();

import React, { useContext } from "react";
import { Badge, Caption, Link, ResourceItem, Stack, TextStyle } from "@shopify/polaris";
import { DeleteMinor, LogOutMinor, ImageMajorMonotone } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// import from lib
import { baseHelper } from "lib/helpers";
import Popover from "lib/components/popover/popover";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";

import appConfig from "config";

// import cms
import cmsVendorList from "../../cms/vendorListCMS";

const { brandPageURL } = appConfig;

const VendorListView = (props) => {
  const { currentUser } = useContext(PrivateContext);
  const { item, keyIndex, onDeleteVendor, onLoginVendor, activePopover, setActivePopover } = props;
  const { _id, lastName, firstName, email, brandName, createdAt, roles } = item;
  const { address = "", verified } = email || {};
  const { name: role } = roles;
  const DELETE = "delete";
  const LOGIN = "login";
  const BRANDPAGE = "brandPage";

  const { t } = useTranslation();
  const vendorListCMS = cmsVendorList(t);
  const { button, label } = vendorListCMS;

  const onBrandPage = (brandName) => {
    const url = `${brandPageURL}/brand/${currentUser.shop}/${brandName}`
    window.open(url);
  }

  const handleSelectAction = (action) => {
    if (action === BRANDPAGE) {
      onBrandPage(brandName);
    }
    if (action === DELETE) {
      onDeleteVendor(_id);
    }
    if (action === LOGIN) {
      onLoginVendor(_id, brandName, role);
    }
  };

  const topMargin10px = {
    marginTop: "10px",
  };
  return (
    <>
      <ResourceItem id={_id} sortOrder={keyIndex} accessibilityLabel={`${label.viewDetailsFor} ${brandName}`}>
        <Stack>
          <Stack.Item fill>
            <TextStyle>
              <div className="ellipsis">
                <Link url={`/providers/view/${_id}`}>
                  <TextStyle>{(brandName && baseHelper.ucFirst(brandName)) || "No Brandname"}</TextStyle>
                </Link>
              </div>
            </TextStyle>
            <Caption>
              <div className="ellipsis">
                <TextStyle variation="subdued">
                  {`${constant.ONBOARDED} ${constant.ON} ${baseHelper.formatDate(createdAt)}`}
                </TextStyle>
                <br />
                {`${label.name} ${firstName || "No Name"} ${lastName || ""}`}
                <br />
                {`${label.email} ${address}`}
              </div>
            </Caption>
            <div style={topMargin10px}>
              <Stack>
                <Stack.Item>
                  <Badge status={verified ? "success" : "new"}>{verified ? constant.APPROVED : constant.PENDING}</Badge>
                </Stack.Item>
              </Stack>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Popover
              // eslint-disable-next-line no-underscore-dangle
              active={activePopover[_id]}
              // eslint-disable-next-line no-underscore-dangle
              setActive={() => setActivePopover({ [_id]: !activePopover[_id] })}
              options={[
                {
                  content: button.loginVendor,
                  icon: LogOutMinor,
                  onAction: () => handleSelectAction(LOGIN),
                },
                {
                  content: button.brandPage || "Brand page",
                  icon: ImageMajorMonotone,
                  onAction: () => handleSelectAction(BRANDPAGE),
                },
                {
                  content: button.deleteVendor,
                  destructive: true,
                  icon: DeleteMinor,
                  onAction: () => handleSelectAction(DELETE),
                },
              ]}
            />
          </Stack.Item>
        </Stack>
      </ResourceItem>
    </>
  );
};

VendorListView.defaultProps = {
  item: {},
  keyIndex: "",
  onDeleteVendor: () => { },
  onLoginVendor: () => { },
  activePopover: {},
};

VendorListView.propTypes = {
  item: PropTypes.shape(PropTypes.object),
  keyIndex: PropTypes.number,
  onDeleteVendor: PropTypes.func,
  onLoginVendor: PropTypes.func,
  activePopover: PropTypes.objectOf(),
  setActivePopover: PropTypes.func.isRequired,
};

export default VendorListView;

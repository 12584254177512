import React, { useState, useContext } from "react";
import { Modal, Stack, Select, Checkbox, TextField, DisplayText } from "@shopify/polaris";
import { useMutation } from "react-apollo";

// import helper
import baseHelper from "lib/helpers/base";

// import constant
import constant from "lib/constant/constant";

// import lib component
import Banner from "lib/components/banner/banner";

// import context
import { PrivateContext } from "lib/context";
import { template } from "@babel/core";
import { AdvanceShippingContext } from "../../context/context";

// import gql
import { EDIT_RULE } from "../../../../../../../apollo/mutations";

// import prop
import { editProp } from "../props/index";

const EditRule = (props) => {
  const { isVendorAllowed, setBannerOuter } = useContext(AdvanceShippingContext);
  const { currentUser = {} } = useContext(PrivateContext);
  const { editModal, setEditModal, item, refetch, destinations = [], products = [], bands = [], t, moneyFormat } =
    props || {};

  const [shippingRule, setShippingRule] = useState(item);
  const { value, gql } = constant;
  const [editBanner, setEditBanner] = useState({
    isOpen: false,
    title: "",
    message: "",
    status: "",
  });
  const [editRule, { loading }] = useMutation(EDIT_RULE);
  const {
    _id,
    shippingBandId,
    price,
    description,
    condition,
    destinationCode,
    productId,
    priceType,
    isShippingDisabled,
  } = shippingRule || {};

  const option = [
    { label: t("label.Fixed"), value: constant.FIXED },
    { label: t("label.FreeShipping"), value: constant.FREE },
  ];

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((key) => key.value === destinationCode)
    ) || {};
  const destination = countries.find((key) => key.value === destinationCode);
  const product = products.find((key) => key.value === productId);
  const shippingBand = bands.find((band) => band.value === shippingBandId || band.parentId === shippingBandId) || false;
  const isDestination = condition === constant.DESTINATION;
  const isProduct = condition === constant.PRODUCT;

  const handleShippingRule = (key, val) => {
    setShippingRule({ ...shippingRule, [key]: val });
  };
  const toggleModal = () => {
    setEditModal(!editModal);
  };
  const handleDismiss = () => {
    setEditBanner({ isOpen: false });
  };
  const checkValidation = () => {
    if (!description) {
      return t("error.advanceShipping.AddDescriptionForRule");
    }
    if (description.length > 255) {
      return t("error.advanceShipping.DescriptionCharacter");
    }
    if (!priceType) {
      return t("error.advanceShipping.ProvidePriceType");
    }
    if (priceType !== constant.FREE && !price) {
      return t("error.advanceShipping.ProvidePrice");
    }
    if (priceType !== constant.FREE && price < 0) {
      return t("error.advanceShipping.ProvideValidPrice");
    }
    return false;
  };

  const handleUpdate = () => {
    const hasError = checkValidation();
    if (hasError) {
      return setEditBanner({
        isOpen: true,
        title: hasError,
        status: constant.CRITICAL,
      });
    }

    const data = {
      _id,
      parentId: item && item.parentId,
      condition,
      description,
      destinationCode,
      isShippingDisabled,
      price: (priceType && priceType === constant.FIXED && price) || 0,
      priceType,
      productId,
      shippingBandId,
    };

    const { userId: ruleCreatedBy = null } = item || {};
    const { _id: currentUserId = null } = currentUser || {};
    if (baseHelper.mongoIdAsString(ruleCreatedBy) !== baseHelper.mongoIdAsString(currentUserId)) {
      data.parentId = _id;
    }

    return editRule({ variables: { input: data } })
      .then((response) => {
        const responseError = baseHelper.getResponseError(response.data, gql.UPDATE_SHIPPING_RULE);
        if (responseError) {
          setEditBanner({ isOpen: true, status: constant.CRITICAL, title: responseError });
          return;
        }

        const responseData = baseHelper.getResponseData(response.data, gql.UPDATE_SHIPPING_RULE);
        if (responseData) {
          refetch();
          setBannerOuter({ isOpen: true, title: t("message.advanceShipping.ruleUpdated"), status: "success" });
          setEditModal(false);
        }
      })
      .catch(() => {
        setEditBanner({ isOpen: true, status: constant.CRITICAL, title: t("error.common.somethingWentWrong") });
      });
  };

  if (!isVendorAllowed) {
    return null;
  }

  return (
    <>
      <Modal
        open={editModal}
        onClose={toggleModal}
        title={t("label.EditRule")}
        primaryAction={{
          loading,
          content: t("label.Update"),
          onAction: handleUpdate,
        }}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              {editBanner.isOpen && (
                <Banner
                  isOpen={editBanner.isOpen}
                  title={editBanner.title}
                  message={editBanner.message}
                  status={editBanner.status}
                  onDismiss={handleDismiss}
                />
              )}
            </Stack.Item>
            <Stack.Item>
              <DisplayText size="small">
                {`${t("label.ShippingBand")}: ${shippingBand && shippingBand.label}`}
              </DisplayText>
            </Stack.Item>
            <Stack.Item>
              <Stack>
                {isDestination && (
                  <Stack.Item>
                    <DisplayText size={constant.SMALL}>
                      {`${t("label.Destination")}: ${(destination && destination.label) || t("label.NotAvailable")} `}
                    </DisplayText>
                  </Stack.Item>
                )}
                {isProduct && (
                  <Stack.Item>
                    <DisplayText size={constant.SMALL}>
                      {`${t("label.product")}: ${(product && product.label) || t("label.NotAvailable")}`}
                    </DisplayText>
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TextField
                label={t("label.Description")}
                value={description}
                onChange={(val) => handleShippingRule(constant.DESCRIPTION, val)}
                placeholder={t("placeHolder.Description")}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Select
                    label={t("label.FixedOrFreeShipping")}
                    options={option}
                    onChange={(val) => handleShippingRule(constant.PRICE_TYPE, val)}
                    value={priceType}
                    placeholder={t("placeHolder.SelectShippingRate")}
                  />
                </Stack.Item>
                {priceType === constant.FIXED && (
                  <Stack.Item>
                    <TextField
                      prefix={moneyFormat}
                      label={t("label.Amount")}
                      type="number"
                      onChange={(val) => handleShippingRule(constant.PRICE, Number(val))}
                      value={price.toString() || ""}
                      min={value.MIN_PRICE}
                      step={value.STEP}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                label={t("label.NotAvailableForShipping")}
                checked={isShippingDisabled}
                onChange={() => handleShippingRule(constant.IS_SHIPPING_DISABLED, !isShippingDisabled)}
              />
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};
EditRule.propTypes = editProp.type;
export default EditRule;

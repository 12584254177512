// import packages
import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Card,
  Layout,
  Modal,
  PageActions,
  SettingToggle,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import context
import { PrivateContext } from "lib/context";

// import components
import { Banner } from "lib/components/index";

// import helpers
import { baseHelper } from "lib/helpers/index";

// import constants
import constant from "lib/constant/constant";

// import gql
import { UPDATE_PRODUCT_SYNC } from "../../../../apollo/mutations";

const ProviderProductSetting = () => {
  const [updateProductSyncMutation] = useMutation(UPDATE_PRODUCT_SYNC);
  const { currentUser, history, cms } = useContext(PrivateContext);
  const [banner, setBanner] = useState({});
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false);
  const [isProductSyncEnabled, setIsProductSyncEnabled] = useState(currentUser.isProductSync);
  const { wooCommerce: wooCommerceConfig = {} } = (currentUser && currentUser.storeConfig) || {};
  const { shop: woocommerceVendorShop = "" } = wooCommerceConfig || {};
  const isShopConnected = currentUser.shop || woocommerceVendorShop;

  const toggleProductSync = async () => {
    setIsConfirmButtonLoading(true);
    const isProductSync = !isProductSyncEnabled;
    const res = await updateProductSyncMutation({
      variables: {
        input: { isProductSync },
      },
    });
    const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PRODUCT_SYNC);
    const bannerObj = {};
    if (!responseData) {
      bannerObj.title = cms("common.message.error.somethingWentWrong");
      bannerObj.status = "critical";
      bannerObj.isOpen = true;
      setBanner({ ...bannerObj });
      setIsConfirmationModalVisible(!isConfirmationModalVisible);
      setIsConfirmButtonLoading(false);
      return;
    }
    bannerObj.title = cms("provider.message.success");
    bannerObj.status = "success";
    bannerObj.isOpen = true;
    setBanner({ ...bannerObj });
    setIsConfirmationModalVisible(!isConfirmationModalVisible);
    setIsConfirmButtonLoading(false);
    setIsProductSyncEnabled(!isProductSyncEnabled);
  };
  const toggleConfirmationModal = () => {
    setIsConfirmationModalVisible(!isConfirmationModalVisible);
  };
  const renderConfirmationModal = () => {
    return (
      <Modal
        key={constant.VENDOR_PRODUCT_SETTING_MODAL}
        open={isConfirmationModalVisible}
        onClose={toggleConfirmationModal}
        title={cms("provider.modal.title")}
        primaryAction={{
          content: cms("provider.modal.button.primary"),
          onAction: toggleProductSync,
          loading: isConfirmButtonLoading,
        }}
        secondaryActions={[
          {
            content: cms("common.button.cancel"),
            onAction: toggleConfirmationModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>{cms("provider.modal.content")}</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  };

  return (
    <Layout.Section>
      {renderConfirmationModal()}
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}

      <Layout.AnnotatedSection title={cms("provider.title")} description={cms("provider.description")}>
        <Layout.Section>
          {!isShopConnected && (
            <Card sectioned>
              <Stack>
                <Stack.Item fill>{cms("provider.content.connect")}</Stack.Item>
                <Stack.Item>
                  <Button onClick={() => history.push(constant.CONNECT_SHOP_PAGE)}>
                    {cms("provider.button.connect")}
                  </Button>
                </Stack.Item>
              </Stack>
            </Card>
          )}
          {isShopConnected && (
            <SettingToggle
              action={{
                content: isProductSyncEnabled ? cms("provider.button.disable") : cms("provider.button.enable"),
                onAction: () => toggleConfirmationModal(),
                destructive: isProductSyncEnabled,
              }}
              enabled={isProductSyncEnabled}
            >
              {`${cms("provider.content.autoProduct")}`}
              <TextStyle variation="strong">
                {isProductSyncEnabled ? cms("provider.label.enabled") : cms("provider.label.disabled")}
              </TextStyle>
            </SettingToggle>
          )}
        </Layout.Section>
      </Layout.AnnotatedSection>
      <Layout.Section>
        <PageActions
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              id: "cancelButton",
              onClick: () => history.push("/"),
            },
          ]}
        />
      </Layout.Section>
    </Layout.Section>
  );
};
export default withFeature(withErrorBoundary(ProviderProductSetting), { feature: constant.PRODUCT_SETTING });

import React, { useState } from "react";
import { Card, Layout, TextContainer, Caption } from "@shopify/polaris";
import { useMutation } from "react-apollo";

import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";

import { Banner } from "lib/components";
import ImageComponent from "lib/components/dropZone/dropZone";

import { imageProp } from "../propTypes";

import { DELETE_PRODUCT_IMAGE } from "../../../../apollo/mutations";

const Image = (props) => {
  const {
    cms,
    data = {},
    existingProductImages = [],
    setDisabledButton,
    setData,
    setExistingImages,
    learnMore,
  } = props;
  const { gql } = constant;
  const { images = [] } = data || {};
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
  });
  const [loadingId, setLoadingId] = useState("");
  const [deleteImage] = useMutation(DELETE_PRODUCT_IMAGE);

  const handleBanner = () => {
    setBanner({ isOpen: false });
  };

  const handleDeleteImage = (image) => {
    const { _id: productId = "" } = data;
    const { position = 0, url = "" } = image;

    if (!position || url === "") {
      return;
    }

    const value = {
      id: position || "",
      productId,
      url,
    };
    setLoadingId(position);
    deleteImage({ variables: { input: value } })
      .then((res) => {
        setLoadingId("");
        setDisabledButton(false);
        const responseError = baseHelper.getResponseError(res.data, gql.DELETE_PRODUCT_IMAGE);
        if (responseError) {
          setBanner({ isOpen: true, status: "critical", title: responseError });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.DELETE_PRODUCT_IMAGE);
        if (responseData) {
          setBanner({
            isOpen: true,
            status: "success",
            title: cms("section.image.message.success.imageDeleted"),
          });
          let productImages = [...existingProductImages];
          productImages = productImages.map(img => {
            if (img.position < position) {
              return img;
            }
            const pos = img.position - 1;
            img.position = pos;
            return ((img.position - position + 1) !== 0) ? img : null;
          }).filter(item => item);
          setExistingImages(productImages);
        }
      })
      .catch(() => {
        setLoadingId("");
        setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
      });
  };

  const handleAddImage = (selectedImages) => {
    setDisabledButton(false);
    const productImages = (data && data.images) || [];
    const uploadedImages = [...productImages, ...selectedImages];
    setData((prev) => ({
      ...prev,
      images: uploadedImages,
    }));
  };

  const removeImage = (index) => {
    setDisabledButton(false);
    const productImages = data.images || [];
    if (index < 0 || productImages.length <= 0) {
      return;
    }
    productImages.splice(index, 1);
    setData((prev) => ({
      ...prev,
      images: productImages,
    }));
  };

  return (
    <Layout.Section>
      <Card
        title={[
          cms("section.image.title"),
          data && data.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(data.updatedAt)}`}</Caption>,
        ]}
        id="productImage"
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => {
              learnMore(cms("section.image.title"), "TODO:");
            },
          },
        ]}
      >
        <Card.Section>
          <TextContainer>You can edit image(s) for the product.</TextContainer>
          <br />
          {banner.isOpen && (
            <>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={handleBanner}
                isScrollTop={false}
              />
              <br />
            </>
          )}
          <ImageComponent
            onAdd={handleAddImage}
            onRemove={removeImage}
            size={10}
            allowMultiple
            fileList={images || []}
            existingImageList={existingProductImages || []}
            removeExistingImage={handleDeleteImage}
            loadingPosition={loadingId}
          />
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};
Image.propTypes = imageProp.type;
export default Image;

import React, { useContext, useState, useEffect } from "react";
import { TopBar } from "@shopify/polaris";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import config from "config";

// import components
import { useMutation } from "@apollo/react-hooks";
import { PrivateContext, PrivateBrandingContext } from "lib/context";
import constant from "lib/constant/constant";

// import helpers
import { storageHelper, baseHelper, accessHelper } from "lib/helpers";

// import mutation
import { LOGOUT_USER } from "../../apollo/mutations";

const Header = (props) => {
  const { t } = useTranslation();
  const { history, currentUser, isLoading } = useContext(PrivateContext);

  const isVendor = baseHelper.isVendor(currentUser);
  const isSeller = baseHelper.isSeller(currentUser);
  const { branding } = useContext(PrivateBrandingContext);
  const { isHideMc } = branding || {};

  const {
    gql: { LOGOUT },
  } = constant;

  const [hideMcDetails, setHideMcDetails] = useState(storageHelper.get("hideMcDetails"));
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [logoutMutation, { loading }] = useMutation(LOGOUT_USER);
  const { setLogoutLoading } = props;
  const [setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const logout = async () => {
    storageHelper.remove("token");
    storageHelper.remove("isInitial");
    storageHelper.remove("userRole");
    storageHelper.remove("setupProgress");
    storageHelper.removeAllCookie();
    const {
      buttonCSS = "",
      headerColor = "",
      loginText = "",
      logoUrl = "",
      register = {},
      brandName = "",
      headerFont = "",
    } = branding;
    if (isVendor) {
      // baseHelper.setBranding(this.props.branding);
      if (buttonCSS && buttonCSS !== "") {
        storageHelper.set("buttonCSS", buttonCSS);
      }
      if (headerColor && headerColor !== "") {
        storageHelper.set("headerColor", headerColor);
      }
      if (loginText && loginText !== "") {
        storageHelper.set("loginText", loginText);
      }
      if (logoUrl && logoUrl !== "") {
        storageHelper.set("logoUrl", logoUrl);
      }
      if (register && (register.title || register.desc)) {
        storageHelper.set("register", register);
      }
      if (brandName && brandName !== "") {
        storageHelper.set("brandName", brandName);
      }
      if (headerFont && headerFont !== "") {
        storageHelper.set("headerFont", headerFont);
      }
      if (isHideMc) {
        storageHelper.set("hideMcDetails", isHideMc);
      }
    }
    window.location.href = `${config.rootURL}/login`;
  };

  const userMenuActions = [
    // {
    //   items: [{ content: t("label.ChangePassword"), onAction: () => history.push("/change-password") }],
    // },
    // {
    //   items: [{ content: t("label.Profile"), onAction: () => history.push("/profile") }],
    // },
    {
      items: [{ content: t("label.ContactUs"), onAction: () => history.push("/contact-us") }],
    },
    {
      items: [{ content: t("label.Logout"), onAction: () => logout() }],
    },
  ];

  useEffect(() => {
    if (typeof isHideMc === "undefined") {
      return;
    }
    if (isHideMc !== hideMcDetails) {
      setHideMcDetails(isHideMc);
    }
  }, [hideMcDetails, isHideMc]);

  if (hideMcDetails) {
    // removing ContactUs from userMenuActions
    userMenuActions.splice(2, 1);
  }
  if (loading) {
    setLogoutLoading(loading);
  }

  const renderHeader = () => {
    if (isLoading) {
      return null;
    }
    const { shop = "", firstName = "", lastName = "", brandName = "" } = currentUser || {};
    const firstNameInitial = (firstName && firstName.charAt(0).toUpperCase()) || "";
    const lastNameInitial = (lastName && lastName.charAt(0).toUpperCase()) || "";
    const fullNameInitials = `${firstNameInitial}${lastNameInitial}`;
    return (
      <>
        <TopBar.UserMenu
          actions={userMenuActions}
          open={isUserMenuOpen}
          name={(isSeller && brandName) || `${firstName} ${lastName}`}
          detail={accessHelper.getUserRole(currentUser)}
          initials={fullNameInitials}
          onToggle={() => setIsUserMenuOpen(!isUserMenuOpen)}
        />
      </>
    );
  };

  return renderHeader();
};

Header.propTypes = {
  setLogoutLoading: PropTypes.func.isRequired,
};

export default Header;

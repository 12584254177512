import React, { useContext } from "react";
import { Caption, Stack, TextStyle, Link, Badge, ResourceItem } from "@shopify/polaris";
import { ReceiptMajorMonotone } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import props
import Popover from "lib/components/popover/popover";
import { rowProp } from "../propTypes";

// import cms and subFeature
import actionData from "../cms/payment";

const topMargin10px = {
  marginTop: "10px",
};

function PaymentRow(props) {
  const { history } = useContext(PrivateContext);
  const { t } = useTranslation();
  const { item, activePopover, setActivePopover } = props;
  const { _id: id, orderId } = item;
  const { mainLink } = actionData(t);

  const renderOrderRow = (items) => {
    const {
      vendor,
      dueDate,
      status,
      orderNumber,
      accountingStatus,
      moneyFormat,
      commissionSeller,
      createdAt,
      commissionVendor,
      paidVia,
      shippingAmount,
      _id,
    } = items;

    let isDueDate = true;
    if (dueDate === "" || dueDate === null) {
      isDueDate = false;
    }
    const badgeStatus = baseHelper.ucFirst(status);
    const badgeType = baseHelper.getBadgeType(status);
    const accountingBadgeStatus = baseHelper.ucFirst(accountingStatus);
    const accountingBadgeType = baseHelper.getBadgeType(accountingStatus);
    return (
      <>
        <Stack alignment="leading">
          <Stack.Item fill>
            <TextStyle>
              <Link onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>{`#${orderNumber}`}</Link>
            </TextStyle>
            <Caption>
              <TextStyle variation="subdued">
                {`${t("label.CreatedOn")}: ${baseHelper.formatDate(createdAt, true)}`}
              </TextStyle>
              <br />
              <TextStyle>{`${t("label.Vendor")}: ${vendor}`}</TextStyle>
              <br />
              <TextStyle>
                {` ${t("label.SellerCommisson")}: ${moneyFormat} ${baseHelper.getPrice(commissionSeller)}`}
              </TextStyle>
              <br />
              <TextStyle>
                {` ${t("label.VendorAmount")}: ${moneyFormat} ${baseHelper.getPrice(commissionVendor)}`}
              </TextStyle>
              <br />
              {!!shippingAmount && (
                <>
                  <TextStyle>
                    {`${t("label.Shipping")}: ${moneyFormat} ${baseHelper.getPrice(shippingAmount)}`}
                  </TextStyle>
                  <br />
                </>
              )}

              <TextStyle>{`${t("label.DueDate")}: ${baseHelper.formatDate(dueDate)}`}</TextStyle>
              <br />
              {!paidVia && (
                <TextStyle>{`${t("label.paidManually")}: ${baseHelper.formatDate(createdAt, false)}`}</TextStyle>
              )}
            </Caption>
          </Stack.Item>
          <Stack.Item>
            <Popover
              // eslint-disable-next-line no-underscore-dangle
              active={activePopover[_id]}
              // eslint-disable-next-line no-underscore-dangle
              setActive={() => setActivePopover({ [_id]: !activePopover[_id] })}
              options={[
                {
                  content: t("label.Invoice"),
                  icon: ReceiptMajorMonotone,
                  onAction: () => window.open(`/invoice/${orderId}/${id}`, "_blank"),
                },
              ]}
            />
          </Stack.Item>
        </Stack>
        <Stack.Item style={topMargin10px}>
          <br />
          <div>
            <Stack>
              <Stack.Item>
                <Badge status={badgeType}>{badgeStatus}</Badge>
              </Stack.Item>
              <Stack.Item>
                <Badge status={accountingBadgeType}>{accountingBadgeStatus}</Badge>
              </Stack.Item>
            </Stack>
          </div>
        </Stack.Item>
      </>
    );
  };

  return (
    <>
      <ResourceItem id={id}>
        <Stack vertical={false} distribution="fillEvenly">
          {renderOrderRow(item)}
        </Stack>
      </ResourceItem>
    </>
  );
}

PaymentRow.propTypes = rowProp.type;
export default PaymentRow;

import React, { useEffect, useCallback, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";

// import helper
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import {
  GET_SHIPPING_BAND,
  GET_SHIPPING_REGION,
  GET_SHIPPING_PRODUCT,
  GET_SHIPPING_RULE,
} from "app/shipping/apollo/queries";

// import subFeatures
import { PrivateContext } from "lib/context";
import List from "./subFeatures/list/list";

// import propType
import { shippingRuleProp } from "./propTypes";

const OperatorShippingRule = (props) => {
  const { cms } = useContext(PrivateContext);
  const { setBanner, isVendorAllowed } = props;

  const { loading: ruleLoading, data: ruleData, error: ruleError, networkStatus, refetch } = useQuery(
    GET_SHIPPING_RULE,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  const { loading: bandLoading, data: bandData, error: bandError } = useQuery(GET_SHIPPING_BAND);
  const { loading: destinationLoading, data: destinationData, error: destinationError } = useQuery(GET_SHIPPING_REGION);
  const { loading: productLoading, data: productData, error: productError } = useQuery(GET_SHIPPING_PRODUCT);

  const queryLoading = ruleLoading || networkStatus === 4 || bandLoading || destinationLoading || productLoading;
  const isError = ruleError || bandError || destinationError || productError;

  useEffect(() => {
    if (isError) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.somethingWentWrong"),
        status: "critical",
      });
    }
  }, [isError, setBanner]);

  const setErrorBanner = useCallback(
    (errorMessage) => {
      setBanner({
        isOpen: true,
        title: errorMessage,
        status: "critical",
      });
    },
    [setBanner]
  );

  // shipping rule
  const ruleDataError = baseHelper.getResponseError(ruleData, constant.gql.GET_SHIPPING_RULE);
  useEffect(() => {
    if (ruleDataError) {
      setErrorBanner(ruleDataError);
    }
  }, [ruleDataError, setErrorBanner]);

  const ruleDataResponse = baseHelper.getResponseData(ruleData, constant.gql.GET_SHIPPING_RULE);
  // shipping band
  const bandDataError = baseHelper.getResponseError(bandData, constant.gql.GET_SHIPPING_BAND);
  useEffect(() => {
    if (bandDataError) {
      setErrorBanner(bandDataError);
    }
  }, [bandDataError, setErrorBanner]);
  const bandDataResponse = baseHelper.getResponseData(bandData, constant.gql.GET_SHIPPING_BAND);
  const { shippingRows = [] } = bandDataResponse || {};
  const shippingBands = shippingRows.map(({ _id: bandId, name }) => ({
    label: name,
    value: bandId,
  }));

  // shipping destination
  const destinationDataError = baseHelper.getResponseError(destinationData, constant.gql.GET_REGION);
  useEffect(() => {
    if (destinationDataError) {
      setErrorBanner(destinationDataError);
    }
  }, [destinationDataError, setErrorBanner]);
  const destinationDataResponse = baseHelper.getResponseData(destinationData, constant.gql.GET_REGION) || [];

  // shipping products
  const productDataError = baseHelper.getResponseError(productData, constant.gql.GET_PRODUCTS);
  useEffect(() => {
    if (productDataError) {
      setErrorBanner(productDataError);
    }
  }, [productDataError, setErrorBanner]);
  const productDataResponse = baseHelper.getResponseData(productData, constant.gql.GET_PRODUCTS) || [];

  return (
    <>
      <List
        setBanner={setBanner}
        isVendorAllowed={isVendorAllowed}
        ruleDataResponse={ruleDataResponse || []}
        bands={shippingBands || []}
        products={productDataResponse || []}
        destinations={destinationDataResponse || []}
        loading={queryLoading}
        refetch={refetch}
      />
    </>
  );
};

OperatorShippingRule.propTypes = shippingRuleProp.type;

export default OperatorShippingRule;

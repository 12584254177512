import React, { useEffect, useState, useContext } from "react";
import { Modal, TextContainer, Link } from "@shopify/polaris";

import config from "config";

import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";
import { useTranslation } from "react-i18next";

const PopupModal = () => {
  const { t } = useTranslation();
  const { currentUser } = useContext(PrivateContext);
  const isSeller = baseHelper.isSeller(currentUser);

  const isIframe = baseHelper.isIframe();

  const { shopifyAccessToken, shop } = currentUser;
  const [isOtp, setIsOtp] = useState(false);

  useEffect(() => {
    if (isSeller && shop && shopifyAccessToken) {
      if (!isIframe && currentUser.isOtp && !currentUser.requestedBy) {
        setIsOtp(true);
      }
      if (isIframe) {
        window.ShopifyApp.init({
          apiKey: config.shopifyAPIKey,
          shopOrigin: `https://${currentUser.shop}.myshopify.com`,
          debug: false,
          forceRedirect: true,
        });
      }
    }
  }, [currentUser.isOtp, currentUser.shop, currentUser.requestedBy, isIframe, isSeller, shop, shopifyAccessToken]);

  useEffect(() => {
    if (isIframe && isSeller && currentUser.shop && currentUser.shopifyAccessToken) {
      const title = currentUser.shop;
      window.ShopifyApp.Bar.initialize({ title });
    }
  }, [currentUser.shop, currentUser.shopifyAccessToken, isIframe, isSeller]);

  const sellerLogin = (e) => {
    e.preventDefault();
    if (isSeller && currentUser.isOtp) {
      window.ShopifyApp.init({
        apiKey: config.shopifyAPIKey,
        shopOrigin: `https://${currentUser.shop}.myshopify.com`,
        debug: false,
        forceRedirect: true,
      });
    }
  };

  return (
    <>
      <Modal
        open={isOtp}
        title
        primaryAction={{
          content: t("message.privateModal.asSeller"),
          onAction: (e) => sellerLogin(e),
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              {`${t("message.privateModal.para1")} `}
              <Link url="https://www.marketcube.io">Marketcube.io</Link>
              {` ${t("message.privateModal.para2")}`}
            </p>
            <p>{`${t("message.privateModal.para3")}`}</p>
            <p>
              {`${t("message.privateModal.para4")} `}
              {/* eslint-disable-next-line max-len */}
              <Link url="http://support.marketcube.io/faqs/vendors-cannot-login-as-they-keep-getting-redirected-to-marketcubeios-shopify-app-into-the-sellers-account">
                {` ${t("message.privateModal.para5")} `}
              </Link>
              {` ${t("message.privateModal.para6")}`}
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default PopupModal;

import { gql } from "apollo-boost";

const SET_SHOPIFY_DETAILS = gql`
  mutation shopifyDetail($input: ShopifyDetailInput) {
    shopifyDetail(input: $input) {
      status
      error
      data {
        _id
        shop
        accessToken
        chargeId
        plan
        token
      }
    }
  }
`;

const SELECT_PLAN = gql`
  mutation selectPlan($input: SelectPlanInput!) {
    selectPlan(input: $input) {
      status
      error
      data {
        confirmation_url
        firstLogin
        id
        shop
        token
        price
      }
    }
  }
`;

export { SET_SHOPIFY_DETAILS, SELECT_PLAN };

import React, { useContext, useState } from "react";
import { Layout, Card, TextField, Stack, Button, TextStyle, Toast, Frame } from "@shopify/polaris";
import PropTypes from "prop-types";

import config from "config";
import { PrivateContext } from "lib/context";

const GenericLink = ({ learnMore }) => {
  const { currentUser, cms = {} } = useContext(PrivateContext);
  const { _id: userId, shop, domain, isDomainActive } = currentUser || {};
  const genericLink = isDomainActive ? `${domain}/join/${userId}/${shop}` : `${config.rootURL}/join/${userId}/${shop}`;

  const [toastActive, setToastActive] = useState(false);
  const copy = () => {
    const copyText = window.document.getElementById("TextFieldId").value;
    const textField = window.document.createElement("textarea");
    textField.innerText = copyText;
    window.document.body.appendChild(textField);
    textField.select();
    window.document.execCommand("copy");
    textField.remove();
    setToastActive(true);
  };
  return (
    <div>
      <Layout.AnnotatedSection title={cms("section.link.title")} description={cms("section.link.description")}>
        <Card
          title={cms("section.link.title")}
          actions={[{ content: "Learn more", onAction: () => learnMore(cms("section.link.title"), "TODO: ") }]}
        >
          <Card.Section>
            <Stack>
              <Stack.Item fill>
                <TextField id="TextFieldId" disabled value={genericLink} />
              </Stack.Item>
              <Stack.Item>
                <Button primary onClick={() => copy()} id="copyLinkButton">
                  {cms("section.link.button.copyToClipboard")}
                </Button>
              </Stack.Item>
            </Stack>
            <br />
            <p id="copyLinkText">
              {cms("section.link.content")}
              <br />
              <TextStyle variation="code">{cms("section.link.embedCode")}</TextStyle>
            </p>
          </Card.Section>
        </Card>
        {/*
           Note: Need to wrap the <Toast /> component with <Frame />
           Ref: https://github.com/Shopify/polaris-react/issues/591#issuecomment-529170318
        */}
        <div className="toast">
          <Frame>
            {toastActive && <Toast content={cms("section.link.toast")} onDismiss={() => setToastActive(false)} />}
          </Frame>
        </div>
      </Layout.AnnotatedSection>
    </div>
  );
};

GenericLink.propTypes = {
  learnMore: PropTypes.func.isRequired,
};

export default GenericLink;

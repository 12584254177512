import React, { useState, useEffect, useContext } from "react";
import { Modal, TextField, Card, TextStyle } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import { updateOrderNote } from "app/orders/apollo/updateOrderNote";

import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

const Notes = (props) => {
  const { t } = useTranslation();
  const { match } = useContext(PrivateContext);
  const { note, dataItem, onRefetchOrderView, setBanner } = props;
  const [notes, setNotes] = useState(note);
  const [initialNotes, setInitialNotes] = useState(note);
  const [notesModal, setNotesModal] = useState(false);
  const [updateNewNotes, setUpdateNewNotes] = useState(true);

  const [updateNotes, { loading: loadingNote, error: errorNote, data: dataNote }] = useMutation(updateOrderNote);

  const updateNote = () => {
    const formValues = {
      note: initialNotes.trim(),
      sellerId: dataItem.sellerId,
      shopifyOrderId: dataItem.shopifyOrderId,
      id: match.params.id,
    };
    updateNotes({ variables: { input: formValues } });
    setUpdateNewNotes(true);
  };
  useEffect(() => {
    if (dataNote && updateNewNotes) {
      const responseNote = baseHelper.getResponseData(dataNote, constant.gql.UPDATE_ORDER_NOTE);
      const responseNoteError = baseHelper.getResponseError(dataNote, constant.gql.UPDATE_ORDER_NOTE);
      if (responseNote) {
        setNotesModal(false);
        setNotes(initialNotes);
        setBanner({
          status: constant.SUCCESS,
          title: t("message.noteUpdated"),
          isOpen: true,
        });
        onRefetchOrderView();
      }
      if (responseNoteError) {
        setNotesModal(false);
        setBanner({
          status: "critical",
          title: t("error.common.somethingWentWrong"),
          isOpen: true,
        });
      }
      setUpdateNewNotes(false);
    }
  }, [dataNote, initialNotes, onRefetchOrderView, setBanner, t, updateNewNotes]);

  useEffect(() => {
    setNotesModal(false);
    if (errorNote) {
      setBanner({
        status: "critical",
        title: t("error.common.somethingWentWrong"),
        isOpen: true,
      });
    }
  }, [errorNote, setBanner, t]);
  const renderUpdateNotes = () => {
    return (
      <>
        <Modal
          open={notesModal}
          onClose={() => {
            setNotesModal(false);
            setInitialNotes(notes);
          }}
          title={t("label.updateNote")}
          primaryAction={{
            content: t("label.updateNote"),
            onAction: () => updateNote(),
            loading: loadingNote,
          }}
          sectioned
        >
          <Modal.Section>
            <TextField
              label={t("label.noteHead")}
              value={initialNotes}
              onChange={(val) => {
                setInitialNotes(val);
              }}
            />
          </Modal.Section>
        </Modal>
      </>
    );
  };

  return [
    renderUpdateNotes(),
    <Card
      title={t("label.noteHead")}
      actions={[
        {
          content: t("label.Edit"),
          onAction: () => {
            setNotesModal(true);
          },
        },
      ]}
    >
      <Card.Section>
        <TextStyle variation={!notes && constant.SUBDUED}>{notes.trim() || t("message.noNotes")}</TextStyle>
      </Card.Section>
    </Card>,
  ];
};

export default Notes;

import React, { useContext, useState, useCallback, useMutation } from "react";
import { Card, TextContainer } from "@shopify/polaris";

import constant from "lib/constant/constant";

 import baseHelper from "lib/helpers/base";

// import components
import { DropZoneFile } from "lib/components";

// import context
import { PrivateContext } from "lib/context";
import { FormContext } from "../../../context/context";

import { DELETE_PRODUCT_ATTACHMENT } from "../../../../../../../apollo/mutations";

const Attachment = () => {
  const { handleChange, data, uploadedFile, learnMore } = useContext(FormContext);
  const { cms } = useContext(PrivateContext);
  const [loadingId, setLoadingId] = useState("");
 const [ existingAttachments, setExistingAttachments] = useState([])
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
  });
  const addFile = value => {
    const uploadedFiles = [ ...value]; 
    handleChange(constant.FILE, uploadedFiles);
  };
  const removeFile = index => {
    const productFiles = data.file || [];
    if (index < 0 || productFiles.length <= 0) {
      return;
    }
    productFiles.splice(index, 1);
    handleChange(constant.FILE, productFiles);
  };
  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    [],
  );
 
  const handleDownload = file => {
    window.open(file.fileURL, "_blank");
}

const handleDeleteImage =  (image,index) => {
   setLoadingId(index);
   const productFiles = [...uploadedFile];
         productFiles.splice(index, 1);
         setExistingAttachments(productFiles);
  };

  return (
      <Card
        title="Product Attachment" 
        id="productAttachment"
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => {
              learnMore("productImage", cms("section.form.section.image.title"));
            },
          },
        ]}
        >
        <Card.Section>
        <TextContainer>{"You can add files for the product."}</TextContainer>
        <br />  
        <DropZoneFile id="productAttachmentId" dataValue={uploadedFile}
        size={10}
        onAdd={addFile}
        onRemove={removeFile}
        removeExistingFile={handleDeleteImage}
        downloadExistingFile={handleDownload}
        allowMultiple >   
        </DropZoneFile>
        </Card.Section>
      </Card>
   
  );
};

export default Attachment;

import React from "react";
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { FormCreate } from "app/vendors/modules/generic/feature/add/subFeatures";

const AdminFormCreate = (props) => {
  const { setBanner, learnMore } = props;
  return <FormCreate setBanner={setBanner} learnMore={learnMore} />;
};

AdminFormCreate.propTypes = {
  setBanner: PropTypes.func.isRequired,
  learnMore: PropTypes.func.isRequired,
};

export default withFeature(AdminFormCreate, { feature: constant.FORM_CREATE });

import { gql } from "apollo-boost";

const GET_PLANS = gql`
  {
    getPlans {
      status
      data {
        _id
        code
        maxVendors
        maxProducts
        monthlyFee
        name
        sortOrder
      }
      error
    }
  }
`;

const GET_CURRENT_PLAN = gql`
  query {
    getCurrentPlan {
      data {
        code
        monthlyFee
      }
      status
      error
    }
  }
`;

const GET_USER_DATA = gql`
  query getUser($input: GetUser) {
    getUser(input: $input) {
      data {
        chargeId
      }
      status
      error
    }
  }
`;

export { GET_CURRENT_PLAN, GET_PLANS, GET_USER_DATA };

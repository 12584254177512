import React, { useState, useEffect, useContext } from "react";
import { Tabs, Modal, Stack, RadioButton } from "@shopify/polaris";
import { useLazyQuery } from "@apollo/react-hooks";
import { useQuery } from "react-apollo";

// import helper
import { baseHelper, statusHelper } from "lib/helpers";

import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";

// import components
import { Banner } from "lib/components";
import List from "./subFeatures/list";

// import queries
import { GET_ORDER_LINES, CHECK_ORDER_LINES } from "../../../../apollo/queries";

// import props
import { exportProps } from "./props";

const AdminExportPayment = (props) => {
  const { openModal, setOpenModal } = props;
  const { oldCMS: cms } = useContext(PrivateContext);
  const [isExportDone, setExportDone] = useState(false);
  const [queryData, setQueryData] = useState({});
  const dataToFetch = {};
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [
    getCheckOrderList,
    { data: checkOrderList, loading: checkOrderLoading, error: checkOrderError },
  ] = useLazyQuery(CHECK_ORDER_LINES, { variables: { input: queryData } });
  const { data: orderData, loading: orderLoading, error } = useQuery(GET_ORDER_LINES, {
    variables: {
      input: {
        projection: {
          status: 1,
          fulfillmentStatus: 1,
          vendorId: 1,
          vendor: 1,
          accountingStatus: 1,
        },
      },
    },
  });
  const [selected, setSelected] = useState(0);
  const [vendorList, setVendorList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [exportedAll, setExportedAll] = useState(true);
  const [exportedLink, setExportedLink] = useState(null);
  const [selectedItems, setSelectedItems] = useState([[], []]);
  const [params, setParams] = useState();
  const exportPaymentURL = "/export/payment";

  const { label = {}, message = {} } = cms || {};

  const handleStates = () => {
    setExportedAll(true);
    setExportedLink(null);
    setExportDone(false);
    setSelectedItems([[], []]);
    setSelected(0);
  };
  useEffect(() => {
    if (error) {
      setBanner({ title: message.somethingWrong, status: "critical", isOpen: true });
      return;
    }
    if (!orderLoading && !error && orderData) {
      let getVendor = [];
      let getStatus = [];
      const res = baseHelper.getResponseData(orderData, constant.gql.GET_ORDER_LINES);
      if (!res.length) {
        return;
      }
      res.forEach(({ accountingStatus, vendor, vendorId }) => {
        getStatus.push({
          id: accountingStatus,
          name: statusHelper.getBadgeStatus(accountingStatus),
        });
        getVendor.push({
          id: vendorId,
          name: baseHelper.ucFirst(vendor),
        });
      });
      getVendor = baseHelper.removeDuplicateData(getVendor);
      getStatus = baseHelper.removeDuplicateData(getStatus);
      getVendor = [...baseHelper.sort(getVendor, constant.NAME)];
      getStatus = [...baseHelper.sort(getStatus, constant.NAME)];
      setVendorList(getVendor);
      setStatusList(getStatus);
    }
  }, [orderData, orderLoading, error, message.somethingWrong]);

  useEffect(() => {
    let bannerData = false;
    if (checkOrderList && !checkOrderLoading) {
      const checkOrderData = baseHelper.getResponseData(checkOrderList, constant.gql.CHECK_ORDER_LINES);
      if (!checkOrderData) {
        const resError = baseHelper.getResponseError(checkOrderList, constant.gql.CHECK_ORDER_LINES);
        bannerData = { title: resError || label.noPaymentToExport, status: "critical", isOpen: true };
        setQueryData({});
      }
      const csvDownloadRoute = exportedAll ? exportPaymentURL : `${exportPaymentURL}${params}`;
      setExportedLink((isExportDone && csvDownloadRoute) || null);
    }
    if (checkOrderError) {
      bannerData = { title: message.somethingWrong, status: "critical", isOpen: true };
    }
    if (bannerData) {
      setBanner({ title: bannerData.title, status: bannerData.status, isOpen: bannerData.isOpen });
      handleStates();
    }
  }, [
    checkOrderError,
    checkOrderList,
    checkOrderLoading,
    exportedAll,
    isExportDone,
    label.noPaymentToExport,
    message.somethingWrong,
    params,
  ]);

  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
    setExportedLink(null);
    setExportDone(false);
  };

  const onExport = () => {
    let accountingStatus = [];
    let vendor = [];
    vendor = [...selectedItems[0]];
    accountingStatus = [...selectedItems[1]];
    const vendorString = vendor.join(",");
    const statusString = accountingStatus.join(",");
    if (!(exportedAll || vendorString || statusString)) {
      setBanner({ title: message.pleaseSelectVendorPayment, status: "critical", isOpen: true });
      return;
    }
    setParams(`?vendor=${vendorString}&accountingStatus=${statusString}`);
    if (accountingStatus.length > 0) {
      dataToFetch.accountingStatus = accountingStatus;
    }
    if (vendor.length > 0) {
      dataToFetch.vendor = vendor;
    }
    setQueryData(dataToFetch);
    getCheckOrderList();
    setExportDone(true);
  };

  const tabs = [
    { id: "vendors", content: label.supplier, panelId: "panel-vendor" },
    { id: "statuses", content: label.status, panelId: "panel-status" },
  ];

  const getResourceListTabs = () => {
    const exportModalTabs = baseHelper.cloneData(tabs);
    const singularItem = constant.ITEM;
    const pluralItem = constant.ITEMS;
    const changeIndex = 0;
    selectedItems.forEach((item, index) => {
      if (item.length > 0) {
        exportModalTabs[index + changeIndex].content += ` (${item.length} ${
          item.length === 1 ? singularItem : pluralItem
        } Selected)`;
      }
    });
    return exportModalTabs;
  };

  const items = [vendorList, statusList];

  const handleSelection = (event) => {
    const prevSelection = [...selectedItems];
    prevSelection[selected] = [...event];
    setSelectedItems(prevSelection);
    setExportedLink(null);
    setExportDone(false);
  };

  const onCloseModal = () => {
    handleStates();
    setOpenModal(false);
    setBanner("");
  };

  const downloadCSV = (link) => {
    window.open(link);
    onCloseModal();
  };
  const tab = getResourceListTabs();

  return (
    <Modal
      open={openModal}
      onClose={() => onCloseModal()}
      title="Export Payments"
      loading={orderLoading}
      primaryAction={{
        content: exportedLink ? label.downloadCSV : label.generateCSV,
        onAction: () => (exportedLink ? downloadCSV(exportedLink) : onExport()),
        loading: checkOrderLoading,
      }}
      secondaryActions={{
        content: "Cancel",
        onAction: () => onCloseModal(),
      }}
    >
      <Modal.Section>
        <Banner
          title={banner.title}
          status={banner.status}
          isOpen={banner.isOpen}
          onDismiss={() => setBanner({ isOpen: !banner.isOpen })}
        />
        <Stack vertical>
          <RadioButton
            id="exportAllRadio"
            name={constant.EXPORT}
            label={label.exportAllPayment}
            helpText="All payments will be exported"
            checked={exportedAll}
            onChange={() => {
              setExportedAll(!exportedAll);
              setExportDone(false);
            }}
          />
          <RadioButton
            id="exportSelectedRadio"
            name={constant.EXPORT}
            label={label.exportOnePayment}
            helpText={message.selectedPayment}
            checked={!exportedAll}
            onChange={() => {
              setExportedAll(!exportedAll);
              setExportDone(false);
            }}
          />
        </Stack>
        {!exportedAll && (
          <Tabs tabs={tab} selected={selected} onSelect={handleTabChange} fitted>
            <List
              items={items}
              selected={selected}
              handleSelection={handleSelection}
              orderLoading={orderLoading}
              selectedItems={selectedItems || []}
              onExport={onExport}
            />
          </Tabs>
        )}
      </Modal.Section>
    </Modal>
  );
};

AdminExportPayment.propTypes = exportProps.type;
AdminExportPayment.defaultProps = exportProps.default;

export default AdminExportPayment;

import { ApolloClient, InMemoryCache } from "apollo-boost";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { storageHelper } from "../lib/helpers";
import config from "../config";

const authLink = setContext((request, { headers }) => {
  const token = storageHelper.get("token") || "";
  return {
    headers: {
      ...headers,
      token,
    },
  };
});
const httpLink = new HttpLink({
  uri: config.apiURL,
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: config.webSocketUrl,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: {
      token: storageHelper.get("token") || "",
    },
  },
});

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
  wsLink.subscriptionClient.maxConnectTimeGenerator.max;

const newLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const cache = new InMemoryCache({ addTypename: false });
cache.writeData({
  data: {
    isLoggedIn: !!localStorage.getItem("token"),
    user: null,
  },
});

const client = new ApolloClient({
  cache,
  link: newLink,
  resolvers: {},
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
    query: {
      fetchPolicy: "network-only",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
});

export default client;

import PropTypes from "prop-types";

const editProp = {
  type: {
    editModal: PropTypes.bool,
    destinationData: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(PropTypes.object),
    bands: PropTypes.arrayOf(PropTypes.object),
    item: PropTypes.object,
    moneyFormat: PropTypes.string,
    setEditModal: PropTypes.func,
    setBannerOuter: PropTypes.func,
    refetch: PropTypes.func,
    t: PropTypes.func,
  },
};
const deleteProp = {
  type: {
    deleteModal: PropTypes.bool,
    band: PropTypes.arrayOf(PropTypes.object),
    toggleDeleteModal: PropTypes.func,
    setBannerOuter: PropTypes.func,
    refetch: PropTypes.func,
    item: PropTypes.object,
    t: PropTypes.func,
  },
};

const ruleItemProp = {
  type: {
    moneyFormat: PropTypes.string,
    item: PropTypes.object,
    band: PropTypes.arrayOf(PropTypes.object),
    destinations: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(PropTypes.object),
    editModal: PropTypes.func,
    deleteModal: PropTypes.func,
    t: PropTypes.func,
  },
};
const addProp = {
  type: {
    addNewRuleModal: PropTypes.bool,
    setAddNewRuleModal: PropTypes.func,
    destinations: PropTypes.arrayOf(PropTypes.object),
    band: PropTypes.arrayOf(PropTypes.object),
    setBannerOuter: PropTypes.func,
    refetch: PropTypes.func,
    t: PropTypes.func,
    products: PropTypes.arrayOf(PropTypes.object),
    moneyFormat: PropTypes.string,
  },
};
const providerProp = {
  type: {
    setBannerOuter: PropTypes.func,
  },
};

const actionProp = {
  type: {
    actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  },
};

export { ruleItemProp, actionProp, editProp, providerProp, addProp, deleteProp };

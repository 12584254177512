import React from "react";
import { Card, RadioButton, Select, Stack, TextField, FormLayout } from "@shopify/polaris";
import constant from "lib/constant/constant";
import { useTranslation } from "react-i18next";
import { renderConditon } from "../propsType/index";

const RenderOfferCondition = (props) => {
  const { t } = useTranslation();
  const { GREATER_THAN, LESS_THAN, ORDER_VALUE, PRODUCT_PRICE, PRODUCT_QUANTITY } = constant;
  const {
    shippingOffer,
    handleSelectChangeDropdown,
    selectedDropdown,
    handleCriteria,
    handleCondition,
    checkedCondition,
    moneyFormat,
    offerCriteria,
    offerCriteriaValue,
    handleCriteriaValue,
  } = props;

  const { productPrice, productQuantity, orderValue } = shippingOffer || {};

  const optionsDropdown = [
    { label: t("label.ProductPrice"), value: PRODUCT_PRICE },
    { label: t("label.ProductQuantity"), value: PRODUCT_QUANTITY },
    { label: t("label.OrderValue"), value: ORDER_VALUE },
  ];

  const offerCriteriaOptions = [
    { label: t("label.GreaterThan"), value: GREATER_THAN },
    { label: t("label.LessThan"), value: LESS_THAN },
  ];

  const isProductPrice = selectedDropdown === PRODUCT_PRICE;
  const isProductQuantity = selectedDropdown === PRODUCT_QUANTITY;
  const isOrderValue = selectedDropdown === ORDER_VALUE;

  const criteriaValues =
    (isProductPrice && { ...productPrice }) ||
    (isProductQuantity && { ...productQuantity }) ||
    (isOrderValue && { ...orderValue }) ||
    {};
  const criteriaText =
    (isProductPrice && t("label.PriceShouldBe")) ||
    (isProductQuantity && t("label.QuantityShouldBe")) ||
    t("label.OrderValueShouldBe");

  const criteriaKey = (isProductPrice && PRODUCT_PRICE) || (isProductQuantity && PRODUCT_QUANTITY) || ORDER_VALUE;

  return (
    <Card title={t("label.OfferCondition")} sectioned>
      <Stack.Item>
        <RadioButton
          label={t("label.AllConditions")}
          helpText={t("message.AllCondition")}
          checked={checkedCondition}
          id="all-conditions"
          name="all-conditions"
          onChange={handleCondition}
        />
      </Stack.Item>
      <Stack.Item>
        <RadioButton
          label={t("label.ProductOrderCondition")}
          id="selected-conditions"
          name="selected-conditions"
          checked={!checkedCondition}
          onChange={handleCondition}
        />
        {!checkedCondition && [
          <Select
            placeholder={`${t("placeHolder.SelectShippingOffer")} *`}
            options={optionsDropdown}
            value={selectedDropdown}
            onChange={handleSelectChangeDropdown}
          />,
          (isProductPrice || isProductQuantity || isOrderValue) && (
            <FormLayout>
              <Stack>
                <Stack.Item>
                  <p>{criteriaText}</p>
                </Stack.Item>
                <Stack.Item>
                  <Select
                    placeholder={t("placeHolder.SelectCriteria")}
                    options={offerCriteriaOptions}
                    value={offerCriteria || criteriaValues.criteria}
                    onChange={(val) => handleCriteria(criteriaKey, val)}
                  />
                </Stack.Item>
                <Stack.Item>
                  <TextField
                    type="number"
                    labelHidden
                    value={offerCriteriaValue || criteriaValues.value}
                    min={0}
                    step={isProductPrice || isOrderValue ? 0.01 : 0}
                    prefix={(isProductPrice || isOrderValue) && moneyFormat}
                    onChange={(val) => handleCriteriaValue(criteriaKey, val)}
                  />
                </Stack.Item>
              </Stack>
            </FormLayout>
          ),
        ]}
      </Stack.Item>
    </Card>
  );
};

RenderOfferCondition.propTypes = renderConditon.type;

export default RenderOfferCondition;

import { baseHelper } from "lib/helpers";

class OrderHelper {
  /**
   * @desc To return orders csv data
   * @param orderLines array of orderlines
   *
   * @return csv data
   *
   */
  orderCSVData = (orderLines = []) => {
    const orderArray = [];
    orderArray[0] = [
      "Order Id",
      "Lineitem Id",
      "Product",
      "Order Status",
      "Fulfillment Status",
      "Vendor",
      "Quantity",
      "Price",
      "Created At",
    ];
    orderLines.forEach((item, idx) => {
      orderArray[idx + 1] = [
        `#${item.orderNumber}`,
        `#${item.shopifyLineItemId}`,
        item.name,
        item.status,
        item.fulfillmentStatus,
        item.vendor,
        item.quantity,
        `${item.moneyFormat} ${baseHelper.formatPrice(item.price || 0)}`,
        baseHelper.formatDate(item.createdAt),
      ];
    });
    return orderArray;
  };
}

export default new OrderHelper();

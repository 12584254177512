import React, { useContext, useState } from "react";
import { Layout, Card, Select, FormLayout, List, TextContainer, Banner as PolarisBanner } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import components
import { Banner, Sheet } from "lib/components";

// import cms and constants
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary } from "lib/hoc";

import CreateForm from "./subFeatures/form/form";
import InviteVendor from "./subFeatures/invite/invite";
import VendorBulkInvite from "./subFeatures/bulkInvite/bulkInvite";
import BulkCreate from "./subFeatures/bulkCreate/bulkCreate";

const { CREATE_FORM, INVITE_CSV, CREATE_CSV, INVITE_FORM } = constant;

const AdminVendorAdd = () => {
  const { cms = {} } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    action: null,
    children: null,
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [selected, setSelected] = useState();
  const option = [
    { label: cms("section.addVendorMethod.label.invite"), value: INVITE_FORM },
    {
      label: cms("section.addVendorMethod.label.bulkInvite"),
      value: INVITE_CSV,
    },
    { label: cms("section.addVendorMethod.label.createForm"), value: CREATE_FORM },
    { label: cms("section.addVendorMethod.label.bulkCreate"), value: CREATE_CSV },
  ];
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetContent, setSheetContent] = useState("");
  const [sheetTitle, setSheetTitle] = useState("");

  const learnMore = (title, content) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(content);
  };
  const renderComponent = (value) => {
    switch (value) {
      case CREATE_FORM:
        return <CreateForm setBanner={setBanner} learnMore={learnMore} />;
      case INVITE_CSV:
        return <VendorBulkInvite setBanner={setBanner} learnMore={learnMore} />;
      case CREATE_CSV:
        return <BulkCreate setBanner={setBanner} learnMore={learnMore} />;
      case INVITE_FORM:
        return <InviteVendor setBanner={setBanner} learnMore={learnMore} />;
      default:
        return "";
    }
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <br />
      <Layout.AnnotatedSection
        title={cms("section.addVendorMethod.title")}
        description={cms("section.addVendorMethod.description")}
      >
        <Card title={cms("section.onboarding.addSection.title")}>
          <Card.Section>
            <FormLayout>
              <TextContainer>
                Your marketplace cannot function without vendors hence there are many ways for you to add them.
              </TextContainer>
              <Select
                id="addVendorType"
                options={option}
                placeholder={cms("section.addVendorMethod.placeholder.selectOption")}
                onChange={(value) => {
                  setSelected(value);
                  setBanner({ isOpen: false, status: "", title: "" });
                }}
                value={selected}
              />
            </FormLayout>
            <br />
            <PolarisBanner status="info">
              <p>
                <b>All options for adding vendors. </b>
                These options will include the following:
              </p>
              <br />
              <p>
                <List type="bullet">
                  <List.Item>
                    <b>Invite vendors via email</b>
                    This option will allow you want invite vendors to self-register, by sending them an email.
                  </List.Item>
                  <List.Item>
                    <b>Create vendors</b>
                    This option will allow you to create a vendors, this is allow enable you to add products on their
                    behalf.
                  </List.Item>
                  <List.Item>
                    <b>Bulk add vendors</b>
                    This option will allow you to bulk invite and/or create vendors through a CSV file upload.
                  </List.Item>
                </List>
              </p>
            </PolarisBanner>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
      <br />
      {renderComponent(selected)}
      <Sheet
        isOpen={sheetActive}
        title={sheetTitle}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: "Close", onAction: () => setSheetActive(false) }}
        primaryAction={{ content: "Done", onAction: () => setSheetActive(false) }}
      >
        {sheetContent}
      </Sheet>
    </>
  );
};
export default withErrorBoundary(AdminVendorAdd);

import React from "react";
import { DisplayText, List, Stack } from "@shopify/polaris";

import constant from "lib/constant/constant";

const commonValidation = (price, description, priceType, t) => {
  if (!description) {
    return t("error.shippingOffer.addDescriptionForOffer");
  }
  if (description.length > 255) {
    return t("error.shippingOffer.descriptionCharacter");
  }
  if (!priceType) {
    return t("error.shippingOffer.selectShippingOfferType");
  }
  if (priceType !== constant.FREE && !price) {
    return t("error.shippingOffer.provideCost");
  }
  if (priceType !== constant.FREE && price < 0) {
    return t("error.shippingOffer.provideValidPrice");
  }
  return false;
};

const checkValidation = (shippingOffer, t) => {
  const { shippingBandId, price, description, priceType, productQuantity, productPrice, orderValue } =
    shippingOffer || {};
  const isProductPrice = shippingOffer.condition === constant.PRODUCT_PRICE;
  const isProductQuantity = shippingOffer.condition === constant.PRODUCT_QUANTITY;
  const isOrderValue = shippingOffer.condition === constant.ORDER_VALUE;
  if (!shippingBandId) {
    return t("error.shippingOffer.selectShippingBand");
  }
  if (isProductQuantity && !(productQuantity && constant.CRITERIA in productQuantity && productQuantity.criteria)) {
    return t("error.shippingOffer.selectCriteriaQuantity");
  }
  if (
    isProductQuantity &&
    !(productQuantity && t("label.shippingOffer.value") in productQuantity && productQuantity.value)
  ) {
    return t("error.shippingOffer.enterValidQuantity");
  }
  if (isProductQuantity && productQuantity.value <= 0) {
    return t("error.shippingOffer.enterValidQuantity");
  }
  if (isProductPrice && !(productPrice && t("label.shippingOffer.criteria") in productPrice && productPrice.criteria)) {
    return t("error.shippingOffer.selectCriteriaProduct");
  }
  if (isProductPrice && !(productPrice && t("label.shippingOffer.value") in productPrice && productPrice.value)) {
    return t("error.shippingOffer.providePrice");
  }
  if (isProductPrice && productPrice.value <= 0) {
    return t("error.shippingOffer.enterValidPrice");
  }
  if (isOrderValue && !(orderValue && t("label.shippingOffer.criteria") in orderValue && orderValue.criteria)) {
    return t("error.shippingOffer.selectCriteriaOrder");
  }
  if (isOrderValue && !(orderValue && t("label.shippingOffer.value") in orderValue && orderValue.value)) {
    return t("error.shippingOffer.provideOrder");
  }
  if (isOrderValue && orderValue.value <= 0) {
    return t("error.enterValidOrderAmount");
  }
  return commonValidation(price, description, priceType, t) || false;
};

const destinationsShow = (destinations, shippingOffer, t) => {
  const list = destinations.map((item) => {
    const { title, options = [] } = item;
    const selectedOptions = options.filter((option) => shippingOffer.destinationCodes.includes(option.value));
    if (!selectedOptions.length) {
      return null;
    }
    return (
      <List.Item key={`${item.title}${selectedOptions.length}`}>
        {title}
        <List type="bullet">
          {selectedOptions.map((option) => (
            <List.Item key={option.value}>{option.label}</List.Item>
          ))}
        </List>
      </List.Item>
    );
  });
  const destinationList = list.filter((item) => item);

  if (!destinationList.length) {
    return null;
  }
  return (
    <Stack>
      <Stack.Item>
        <DisplayText size={constant.SMALL}>{t("label.shippingOffer.selectedDestinations")}</DisplayText>
      </Stack.Item>
      <Stack.Item>
        <List type={constant.TYPE}>{destinationList}</List>
      </Stack.Item>
    </Stack>
  );
};

export { checkValidation, destinationsShow, commonValidation };

import React, { useContext, useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { TextField, Select, Modal, Stack } from "@shopify/polaris";

// import helper
import { baseHelper } from "lib/helpers";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import gql
import { UPDATE_SHIPPING_BAND } from "app/shipping/apollo/mutations";

// import props
import { optionProp } from "../../propTypes";

const Option = (props) => {
  const { currentUser, cms } = useContext(PrivateContext);
  const { FIXED, FREE, symbol } = constant;
  const { show, onClose, isDefault, shippingBand, setBanner, refetch, networkStatus } = props;
  const isVendor = baseHelper.isVendor(currentUser);
  const moneyFormat = currentUser.moneyFormat || symbol.DOLLAR;
  const [updateShippingBand, { loading: updateLoading }] = useMutation(UPDATE_SHIPPING_BAND);

  const [errorMessage, setErrorMessage] = useState({});

  const [state, setState] = useState({
    shippingBand,
  });
  const options = [
    {
      label: cms("provider.label.fixed"),
      value: FIXED,
    },
    {
      label: cms("provider.label.free"),
      value: FREE,
    },
  ];

  useEffect(() => {
    if (shippingBand) {
      setState((prevState) => ({
        ...prevState,
        shippingBand,
      }));
    }
  }, [shippingBand]);

  const handleChange = (field, value) => {
    if (networkStatus !== 4) {
      setState((prevState) => ({
        shippingBand: {
          ...prevState.shippingBand,
          price: 0,
          [field]: value,
        },
      }));
    }
  };
  const { _id = "", name = "", price = "", description = "", priceType = "", isUpdateAllowed = false, parentId = "" } =
    state.shippingBand || {};

  const isFieldDisabled = isVendor && !isUpdateAllowed;

  const isDisabled = () => {
    let isAllFieldEmpty = !(name && description && priceType);
    if (priceType === FIXED) {
      isAllFieldEmpty = isAllFieldEmpty || !price || !!errorMessage.price;
    }
    return isAllFieldEmpty;
  };

  const closeModal = () => {
    setErrorMessage({ errorMessage });
    onClose();
  };

  // edit shipping band
  const editShippingBand = async () => {
    let bannerData = {};
    try {
      let priceValue = "0";
      if (state.shippingBand.price !== null) {
        priceValue = parseFloat(state.shippingBand.price);
      }
      const res = await updateShippingBand({
        variables: { input: { _id, name, price: priceValue, description, priceType, isUpdateAllowed, parentId } },
      });

      const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_SHIPPING_BAND);
      const errorData = baseHelper.getResponseError(res.data, constant.gql.UPDATE_SHIPPING_BAND);

      if (errorData) {
        bannerData = { isOpen: true, status: constant.CRITICAL, title: errorData };
      }

      if (responseData) {
        bannerData = {
          isOpen: true,
          status: constant.SUCCESS,
          title: cms("band.modal.edit.message.success"),
        };
        refetch();
        setBanner(bannerData);
        return;
      }
    } catch (err) {
      bannerData = { isOpen: true, status: constant.CRITICAL, title: cms("common.message.error.somethingWentWrong") };
    }
    onClose();
    setBanner(bannerData);
  };

  return (
    <>
      <Modal
        open={show}
        onClose={() => closeModal()}
        title={isDefault ? cms("band.modal.add.title") : `${cms("band.modal.edit.title")} ${shippingBand.name}`}
        primaryAction={{
          content: isDefault ? cms("band.modal.add.button.primary") : cms("band.modal.edit.button.primary"),
          loading: updateLoading || networkStatus === 4,
          disabled: isDisabled(),
          onAction: () => {
            editShippingBand();
          },
        }}
      >
        <Modal.Section>
          <Stack vertical>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <TextField
                    id="shippingOption"
                    label={cms("provider.label.option")}
                    labelHidden
                    disabled={isVendor}
                    value={name}
                    placeholder={cms("provider.placeholder.name")}
                    error={errorMessage && errorMessage.name}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TextField
                id="description"
                type="text"
                label={`${cms("band.modal.add.label.description")}*`}
                placeholder={cms("band.modal.add.label.description")}
                value={description}
                onChange={(value) => handleChange("description", value)}
                disabled={isVendor}
                error={errorMessage && errorMessage.description}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item key="main">
                  <Select
                    options={options}
                    id="shippingType"
                    name="shippingType"
                    label={cms("band.modal.add.label.shippingType")}
                    placeholder={cms("provider.placeholder.rate")}
                    value={priceType}
                    onChange={(value) => handleChange("priceType", value)}
                    error={errorMessage && errorMessage.priceType}
                    disabled={isFieldDisabled}
                  />
                </Stack.Item>
                {priceType === FIXED && (
                  <Stack.Item>
                    <TextField
                      id="costId"
                      type="number"
                      label={cms("band.modal.add.label.amount")}
                      min={0}
                      step={0.01}
                      value={price.toString()}
                      prefix={moneyFormat}
                      onChange={(value) => handleChange("price", value)}
                      error={errorMessage && errorMessage.price}
                      disabled={isFieldDisabled}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>
    </>
  );
};

Option.propTypes = optionProp.type;
Option.defaultProps = optionProp.default;

export default Option;

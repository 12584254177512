const publicRouteCodes = {
  "/join/:id/:shop": {
    module: "linkRegister",
    cms: "linkRegister",
  },
  "/login": {
    module: "login",
    cms: "login",
  },
  "/verify-email/:token": {
    module: "verifyEmail",
    cms: "verifyEmail",
  },
  "/resend-verify-email": {
    module: "resendVerifyEmail",
    cms: "resendVerifyEmail",
  },
  "/register": {
    module: "register",
    cms: "register",
  },
  "/reset-password": {
    module: "resetPassword",
    cms: "resetPassword",
  },
  "/reset-password/:token": {
    module: "resetPassword",
    cms: "resetPasswordConfirmation",
  },
  "/register/role": {
    module: "registerRole",
    cms: "registerRole",
  },
  "/shopify/install": {
    module: "shopifyRedirect",
    cms: "shopifyRedirect",
  },
  "/shopify/plan": {
    module: "selectPlan",
    cms: "selectPlan",
  },
  "/shopify/payment": {
    module: "shopifyPayment",
    cms: "shopifyPayment",
  },
  "/otp": {
    module: "otp",
    cms: "otp",
  },
};

export default publicRouteCodes;

import React from "react";
import Dashboard from "app/dashboard/modules";
import Invitation from "app/invitations/modules";

// playground
import Playground from "app/playground";
import Voyager from "app/voyager";

// order features
import { OrderList, OrderSetting, OrderView, OrderSlip, ExportOrder, ManageCustomerDetails } from "app/orders/modules";

// provider features
import {
  VendorList,
  VendorAdd,
  VendorEdit,
  DownloadVendorCSV,
  VendorView,
  DownloadInviteCSV,
} from "app/vendors/modules";

// provider advance features
import { AdvanceProviderList, AdvanceProviderView, ManageVendor } from "app/advanceVendor/modules";

import Reporting from "app/reporting";

// sellers features
import { SellerList, SellerProfileView } from "app/sellers/modules";

// product features
import {
  BulkProductEdit,
  ProductList,
  ProductAdd,
  ProductSetting,
  ProductAttachmentSetting,
  ProductEdit,
  ProductReview,
  ProductConnectShop,
  ShopifyCallback,
  ShopifyProducts,
  VariantAdd,
  VariantEdit,
  WooCommerceProducts,
} from "app/products/modules";

// onboarding features
import {
  Settings,
  Branding,
  Terms,
  Discount,
  PaymentService,
  Commission,
  Markup,
  PaymentConnect,
  PaypalCallBack,
  StripeCallBack,
  FeaturePanel,
} from "app/onboarding/modules";

// setting features
import { UpdatePlan, UpdatePayment } from "app/plans";

// user management features
import {
  ActivityList,
  Association,
  AutomaticPayouts,
  BulkActivity,
  ChangePassword,
  ContactUs,
  CustomDomain,
  EditProfile,
  Maintenance,
  Notification,
  OnboardingList,
  UserSession,
  ViewActivity,
} from "app/userManagement/modules";

import PrivateRoute from "routes/private/privateRoute";

// shipping features
import { Shipping, AdvanceShipping } from "app/shipping/modules";
// payment features
import { PaymentList, Invoice, ExportPayment } from "app/payments/modules";
import { EmailTemplate, EditEmailTemplate } from "app/emailTemplate/modules";

import { Fulfillment } from "app/setup/modules";

const NotFound = () => <h1>Not Found Component</h1>;

const privateRoutes = [
  // { path: "/", component: Dashboard },
  // { path: "/activity", component: ActivityList },
  // { path: "/activity/bulk", component: BulkActivity },
  // { path: "/activity/view/:id", component: ViewActivity },
  // { path: "/association", component: Association },
  // { path: "/branding", component: Branding },
  // { path: "/commission", component: Commission },
  { path: "/contact-us", component: ContactUs },
  // { path: "/change-password", component: ChangePassword },
  // { path: "/domain", component: CustomDomain },
  // { path: "/file-download/vendor", component: DownloadVendorCSV },
  // { path: "/file-download/invite", component: DownloadInviteCSV },
  // { path: "/profile", component: EditProfile },
  // { path: "/invitation", component: Invitation },
  // { path: "/invoice/:id/:lineItemId", component: Invoice },
  // { path: "/onboard-activity", component: OnboardingList },
  // { path: "/orders", component: OrderList },
  // { path: "/order-slip/:id", component: OrderSlip },
  // { path: "/orders/view/:id", component: OrderView },
  // { path: "/export/order", component: ExportOrder },
  // { path: "/maintenance", component: Maintenance },
  // { path: "/export/payment", component: ExportPayment },
  // { path: "/payment-connect", component: PaymentConnect },
  // { path: "/payments", component: PaymentList },
  // { path: "/payouts", component: AutomaticPayouts },
  // { path: "/paypal-callback", component: PaypalCallBack }, // deprecated by /paypal/callback
  // { path: "/paypal/callback", component: PaypalCallBack },
  // { path: "/products", component: ProductList },
  // { path: "/products/add", component: ProductAdd },
  // { path: "/products/add/shop", component: ProductConnectShop },
  // { path: "/products/edit/:id", component: ProductEdit },
  // { path: "/products/view/:id", component: ProductEdit },
  // { path: "/products/review/:id", component: ProductReview },
  // { path: "/products/shopify", component: ShopifyProducts },
  // { path: "/products/woocommerce", component: WooCommerceProducts },
  // { path: "/products/bulk/edit", component: BulkProductEdit },
  { path: "/", component: Playground },
  // { path: "/operators", component: SellerList },
  // { path: "/operators/view/:id", component: SellerProfileView },
  // { path: "/reporting", component: Reporting },
  // { path: "/session", component: UserSession },
  // { path: "/setting", component: Settings },
  // { path: "/setting/customer", component: ManageCustomerDetails },
  // { path: "/setting/discount", component: Discount },
  // { path: "/setting/fulfillment", component: Fulfillment },
  // { path: "/setting/markup", component: Markup },
  // { path: "/setting/notification", component: Notification },
  // { path: "/setting/order", component: OrderSetting },
  // { path: "/setting/payment", component: PaymentService },
  // { path: "/setting/product", component: ProductSetting },
  // { path: "/setting/attachment", component: ProductAttachmentSetting },
  // { path: "/shipping", component: Shipping },
  // { path: "/shipping/advance", component: AdvanceShipping },
  // { path: "/stripe/callback", component: StripeCallBack },
  // { path: "/providers/shopify-callback", component: ShopifyCallback },
  // { path: "/terms", component: Terms },
  // { path: "/update/plan", component: UpdatePlan },
  // { path: "/update-payment", component: UpdatePayment },
  // { path: "/variants/add/:productId", component: VariantAdd },
  // { path: "/variants/edit/:productId/:id", component: VariantEdit },
  // { path: "/providers", component: VendorList },
  // { path: "/providers/add", component: VendorAdd },
  // { path: "/providers/edit/:id", component: VendorEdit },
  // { path: "/providers/view/:id", component: VendorView },
  // { path: "/feature", component: FeaturePanel },
  // { path: "/email-template", component: EmailTemplate },
  // { path: "/email-template/edit/:id", component: EditEmailTemplate },
  // { path: "/advance-providers", component: AdvanceProviderList },
  // { path: "/providers/profile/:id", component: AdvanceProviderView },
  // { path: "/provider/manage", component: ManageVendor },
  { path: "*", component: NotFound },
];

const routes = privateRoutes.map((route) => (
  <PrivateRoute exact path={route.path} component={route.component} key={route.path} />
));

export default routes;

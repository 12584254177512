import React from "react";
import { Card, TextField, Layout, TextContainer, Caption } from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";

import constant from "lib/constant/constant";

import { baseHelper } from "lib/helpers";
import { titleProp } from "../propTypes";

const TitleDescription = (props) => {
  const { data, handleChange, cms, learnMore } = props;
  return (
    <Layout.Section>
      <Card
        title={[
          "Product information",
          data && data.updatedAt && (
            <Caption>{`Last updated at : On ${baseHelper.formatDate(data.updatedAt)}`}</Caption>
          ),
        ]}
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => {
              learnMore("Product information", "TODO:");
            },
          },
        ]}
      >
        <Card.Section>
          <TextContainer>
            You can edit title and description of product. Every Product must have title. Additionally, you can give
            description to the product.
          </TextContainer>
          <br />
          <TextField
            id="title"
            label={`${cms("section.information.label.title")}*`}
            value={data.title || ""}
            onChange={(value) => handleChange(constant.TITLE, value)}
            placeholder={cms("section.information.placeholder.title")}
          />
          <br />
          {cms("section.information.label.description")}
          <Editor
            id="idTiny"
            textareaName="Description"
            value={data.description}
            onEditorChange={(value) => handleChange(constant.DESCRIPTION, value)}
            init={{
              menubar: true,
              plugins: ["autolink link image lists print preview"],
              toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
              entity_encoding: "raw",
            }}
          />
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};

TitleDescription.propTypes = titleProp.type;

export default TitleDescription;

// import packages
import React, { useContext } from "react";
import { Button, TextField, Select, Stack } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import propsTypes
import { AdvanceItemProp } from "../../../../props";

const AdvanceItem = (props) => {
  const { count, updateVal, advancedValue, allTypes, allSuppliers, removeItem } = props;
  const {
    CATEGORY,
    COMMISSION,
    FLAT,
    OPTION,
    PERCENTAGE: PERCENTAGE_TEXT,
    PRICE,
    TYPE,
    VALUE,
    VENDOR,
    color: { RED },
    symbol: { DOLLAR, PERCENTAGE },
    value: { MAX_FLAT, MAX_PERCENTAGE },
  } = constant;
  const { currentUser = {}, cms = {} } = useContext(PrivateContext);
  const { moneyFormat = DOLLAR } = currentUser;
  const typeOptions = [
    {
      label: cms("common.label.category"),
      value: CATEGORY,
    },
    {
      label: cms("common.label.vendor"),
      value: VENDOR,
    },
  ];
  const advanceValueOptions = [
    {
      label: cms("common.label.flat"),
      value: FLAT,
    },
    {
      label: cms("common.label.percentage"),
      value: PERCENTAGE_TEXT,
    },
  ];

  const availableOptions = [...typeOptions];
  if (!allSuppliers.length) {
    availableOptions.pop();
  }
  if (!allTypes.length) {
    availableOptions.shift();
  }
  const arr = Array.from(Array(count).keys());
  return arr.map((item) => {
    const advanceItem = advancedValue[item];
    const advanceItemType = advanceItem.type;
    const advanceItemValue = advanceItem.value;
    const advanceItemCategory = advanceItem.category;
    const isFlatType = advanceItem && advanceItemValue === FLAT;
    const getMaxValue = isFlatType ? MAX_FLAT : MAX_PERCENTAGE;
    const getValuePrefix = isFlatType ? moneyFormat || DOLLAR : "";
    const getValueSuffix = isFlatType ? "" : PERCENTAGE;
    const getTypeOptions = advanceItem && advanceItemType === CATEGORY ? [...allTypes] : [...allSuppliers];

    if (!advanceItem) {
      return null;
    }
    const typeSelect = `${TYPE}${item}`;
    const optionSelect = `${OPTION}${item}`;
    const valueSelect = `${VALUE}${item}`;
    const commissionText = `${COMMISSION}${item}`;

    return (
      <div key={item}>
        <Stack wrap={false} key={item} spacing="extraTight" distribution="fillEvenly" alignment="center">
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={availableOptions}
                id={typeSelect}
                placeholder={cms("common.placeholder.type")}
                onChange={(val) => {
                  updateVal(TYPE, val, item);
                }}
                value={(advanceItem && advanceItemType) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={getTypeOptions}
                placeholder={cms("common.label.options")}
                id={optionSelect}
                onChange={(val) => {
                  updateVal(CATEGORY, val, item);
                }}
                value={(advanceItem && advanceItemCategory) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <Select
                options={advanceValueOptions}
                id={valueSelect}
                placeholder={cms("common.label.value")}
                onChange={(val) => {
                  updateVal(VALUE, val, item);
                }}
                value={(advanceItem && advanceItemValue) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <div className="formWidth125px">
              <TextField
                required
                min={0}
                id={commissionText}
                max={getMaxValue}
                prefix={getValuePrefix}
                suffix={getValueSuffix}
                onChange={(val) => {
                  updateVal(PRICE, val, item);
                }}
                value={(advanceItem && Number(advanceItem.price) && advanceItem.price.toString()) || ""}
              />
            </div>
          </Stack.Item>
          <Stack.Item>
            <Button id="deleteButton" plain onClick={() => removeItem(item)}>
              <i className="far fa-trash fa-lg" style={{ color: RED }} />
            </Button>
          </Stack.Item>
        </Stack>
        <Stack.Item>
          <br />
        </Stack.Item>
      </div>
    );
  });
};

AdvanceItem.propTypes = AdvanceItemProp.type;

export default AdvanceItem;

const standardList = [
  {
    code: "marketplace",
    title: "Your Market Place",
    description:
      "Manage your marketplace here. Configure how you manage products and orders to notifications and fulfilment and shipping. You can also manage your account and profile information too.",
    thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
    isVideo: false,
    list: [
      {
        title: "Products",
        description: "Manage your product related settings here",
        icon: "fal fa-tag fa-lg",
        code: "product",
        thumbnailHeader: "Product Setting",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Product Settings",
            link: "/setting/product",
            code: "productSettings",
            description: "Configure product approval settings for pushing products to your store",
          },
          {
            label: "Product Attachment Settings",
            link: "/setting/attachment",
            code: "productAttachmentSettings",
            description: "Configure product approval settings for pushing products to your store",
          },
          {
            label: "Markup",
            link: "/setting/markup",
            code: "markupSettings",
            description: "Add a markup to the product price offered by a vendor",
          },
          {
            label: "Discount",
            link: "/setting/discount",
            description: "Apply discounts to products offered to the marketplace by your vendors",
          },
        ],
      },
      {
        title: "Orders",
        description: "Manage your order related settings here",
        icon: "fal fa-shopping-cart fa-lg",
        code: "order",
        thumbnailHeader: "Order settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Commission",
            link: "/commission",
            code: "commission",
            description: "Manage how much you wish to earn by way of commission on each sale.",
          },
          {
            label: "Auto accept orders",
            link: "/setting/order",
            code: "orderSettings",
            description: "Manage how you wish to accept incoming orders, manually or automatically.",
          },
          {
            label: "Auto fulfil order",
            link: "/setting/order",
            code: "orderSettings",
            description: "Manage how you wish to fulfi accepted orders, manually or automatically.",
          },
          {
            label: "Manage Customer Details",
            link: "/setting/customer",
            code: "orderSettings",
            description: "Manage Customer details, manually or automatically.",
          },
        ],
      },
      {
        title: "Branding",
        description: "Manage your vendor facing branding and look and feel related settings here",
        icon: "fal fa-copyright fa-lg",
        code: "branding",
        thumbnailHeader: "Branding settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Branding",
            link: "/branding",
            code: "branding",
            description:
              "Customise the look and feel, add a custom sub-domain, privacy policies and terms, hide Marketcube branding",
          },
        ],
      },
      {
        title: "Payments",
        description: "Manage your payments related settings here",
        icon: "fal fa-sack-dollar fa-lg",
        code: "payments",
        thumbnailHeader: "Payout settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Payment Terms and Payout settings",
            link: "/terms",
            code: "terms",
            description:
              "Manage how and when you wish to pay your vendors and how you wish to manage additional charges, e.g, Shipping fees",
          },
          {
            label: "Payout via Stripe payments",
            link: "/setting/payment",
            code: "paymentService",
            description: "Connect your Stripe account for vendor payouts and configure auto payouts settings",
          },
          {
            label: "Payout via PayPal payments",
            link: "/setting/payment",
            code: "paymentService",
            description: "Connect your PayPal account for payouts and configure auto payouts settings",
          },
        ],
      },
      {
        title: "Vendors",
        description: "Manage your vendors related settings here",
        icon: "fal fa-user-friends fa-lg",
        thumbnailHeader: "Vendor settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Vendor",
            link: "/provider/manage",
            code: "vendor",
            description:
              "Manage the information that you wish to gather from your vendors and well as enable custom fields and advanced information blocks",
          },
        ],
      },
      {
        title: "Fulfillments",
        description: "Manage your shipping and Fulfiment settings here",
        icon: "fal fa-dolly fa-lg",
        code: "fulfilment",
        thumbnailHeader: "Fulfillment settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Fulfillment",
            link: "/setting/fulfillment",
            code: "fulfillmentSetting",
            description: "",
          },
          {
            label: "Shipping",
            link: "/shipping",
            code: "shipping",
            description: "",
          },
          {
            label: "Advance Shipping",
            link: "/shipping/advance",
            code: "advanceShipping",
            description: "",
          },
        ],
      },
      {
        title: "Notifications",
        description: "Manage email notifications settings here",
        icon: "fal fa-envelope fa-lg",
        code: "notifications",
        thumbnailHeader: "Notification settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Notification",
            link: "/setting/notification",
            code: "notificationSetting",
            description: "Manage which emails you wish to recieve and which ones you wish to turn off ",
          },
          {
            label: "Customize Email Templates",
            link: "/email-template",
            code: "emailTemplate",
            description: "Update the content for all emails that are sent out to your vendors",
          },
        ],
      },
      {
        title: "User",
        description: "Manage your user account and profile settings here",
        icon: "fal fa-user fa-lg",
        code: "user",
        thumbnailHeader: "User settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Profile",
            link: "/profile",
            code: "profile",
            description: "Manage your profile information here",
          },
        ],
      },
      {
        title: "Billing",
        description: "Billing Settings: etc",
        icon: "fal fa-money-bill fa-lg",
        code: "billing",
        thumbnailHeader: "Billing settings",
        isVideo: false,
        thumbnail: "https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg",
        thumbnailDescription:
          "Your marketplace cannot function without vendors hence there are many ways for you to add them to the account.",
        actions: [
          {
            label: "Billing",
            link: "",
            code: "billingSetting",
            description: "",
          },
        ],
      },
    ],
  },
];

export default standardList;

import React, { useEffect, useState } from "react";
import { Avatar, ResourceItem, TextStyle, Stack, Caption, Tooltip, Badge } from "@shopify/polaris";
import { CancelSmallMinor, SendMajorMonotone } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// import from lib
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import Popover from "lib/components/popover/popover";

const InvitationListView = (props) => {
  const {
    item,
    handleCancel,
    handleResend,
    cancelButtonLoading,
    resendButtonLoading,
    selectedButtonIndex,
    activePopover,
    setActivePopover,
  } = props;
  const { CANCEL, RESEND } = constant;
  const { _id, email, storeName, createdAt, emailStatus, status } = item;
  const { t } = useTranslation();
  const [selected, setSelected] = useState("");
  const pageContent = {
    label: {
      status: t("label.invitationList.status"),
      emailStatus: t("label.invitationList.emailStatus"),
      invitedBy: t("label.invitationList.invitedBy"),
      invitedOn: t("label.invitationList.invitedOn"),
      cancel: t("label.invitationList.cancel"),
      resend: t("label.invitationList.resend"),
    },
  };

  const badgeStatus = baseHelper.ucFirst(status);
  const badgeType = baseHelper.getBadgeType(status);

  const emailBadgeStatus = baseHelper.ucFirst(emailStatus);
  const emailBadgeType = baseHelper.getBadgeType(emailStatus);
  const media = <Avatar customer size="medium" name="" />;

  const handleSelectChange = (value, id) => {
    setSelected(value);
    if (value === CANCEL) {
      handleCancel(email, id);
    }
    if (value === RESEND) {
      handleResend(email, id);
    }
  };

  useEffect(() => {
    if (selected && (!resendButtonLoading || !cancelButtonLoading)) {
      setSelected("");
    }
  }, [cancelButtonLoading, resendButtonLoading, selected]);

  const topMargin10 = {
    marginTop: "10px",
  };

  return (
    <ResourceItem
      id={_id}
      media={media}
      accessibilityLabel={`${t("label.invitationList.viewDetailsFor")} ${storeName}`}
      persistActions
    >
      <Stack alignment="leading">
        <Stack.Item fill>
          <>
            <TextStyle>
              <div className="ellipsis">{email}</div>
            </TextStyle>
            <Caption>
              <TextStyle>{`${pageContent.label.invitedOn} ${baseHelper.formatDate(createdAt)}`}</TextStyle>
              <>
                <br />
              </>
              {`${pageContent.label.invitedBy} :`}
              <TextStyle>{` ${storeName}`}</TextStyle>
            </Caption>
          </>
        </Stack.Item>
        <Stack.Item>
          <Popover
            // eslint-disable-next-line no-underscore-dangle
            active={activePopover[_id]}
            // eslint-disable-next-line no-underscore-dangle
            setActive={() => setActivePopover({ [_id]: !activePopover[_id] })}
            loading={_id === selectedButtonIndex && (resendButtonLoading || cancelButtonLoading)}
            disabled={status && status.toLowerCase() === constant.JOINED.toLowerCase()}
            options={[
              { content: "Resend", icon: SendMajorMonotone, onAction: () => handleSelectChange("Resend", _id) },
              { content: "Cancel", icon: CancelSmallMinor, onAction: () => handleSelectChange("Cancel", _id) },
            ]}
          />
        </Stack.Item>
      </Stack>
      <div style={topMargin10}>
        <Stack>
          <Stack.Item>
            <Tooltip content={pageContent.label.emailStatus} preferredPosition="above">
              <Badge status={emailBadgeType}>
                <span className="text-capitalize" id="emailStatus">
                  {emailBadgeStatus}
                </span>
              </Badge>
            </Tooltip>
          </Stack.Item>
          <Stack.Item>
            <Tooltip content={pageContent.label.status} preferredPosition="above">
              <Badge status={badgeType}>
                <span className="text-capitalize" id="inviteStatus">
                  {badgeStatus}
                </span>
              </Badge>
            </Tooltip>
          </Stack.Item>
        </Stack>
      </div>
    </ResourceItem>
  );
};

InvitationListView.defaultProps = {
  item: {},
  handleCancel: () => {},
  handleResend: () => {},
  cancelButtonLoading: false,
  resendButtonLoading: false,
  selectedButtonIndex: "",
  activePopover: false,
  setActivePopover: () => {},
};

InvitationListView.propTypes = {
  item: PropTypes.shape(PropTypes.object),
  handleCancel: PropTypes.func,
  handleResend: PropTypes.func,
  cancelButtonLoading: PropTypes.bool,
  resendButtonLoading: PropTypes.bool,
  selectedButtonIndex: PropTypes.number,
  activePopover: PropTypes.bool,
  setActivePopover: PropTypes.func,
};

export default InvitationListView;

// import packages
import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Caption,
  Card,
  Layout,
  Stack,
  TextContainer,
  TextStyle,
  FormLayout,
  Select,
  TextField,
  List,
  Form,
} from "@shopify/polaris";

// import components
import { Banner, CardFooter } from "lib/components/index";

// import helpers
import { baseHelper } from "lib/helpers/index";

// import cms and constants
import constant from "lib/constant/constant";
import { UPDATE_SETTING } from "../../../../../apollo/mutations/index";
import { priceProp } from "../props/index";

const { APPROVE_SELECTED, REVIEW_ALL, APPROVE_ALL, gql } = constant;

const Price = (props) => {
  const { lastUpdated, setSelected, setRate, rate, selected, cms, learnMore, radioButtonValue, setBannerStatus } =
    props || {};
  const [updateProductSetting, { loading: productSettingLoading }] = useMutation(UPDATE_SETTING);

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [error, setError] = useState("");

  const returnOptions = [
    {
      label: "Tax inclusive",
      value: "true",
    },
    {
      label: "Tax exclusive",
      value: "false",
    },
  ];

  const renderLists = () => {
    const standardLists = [
      {
        description: "This setting will only work if you have commission based on percentage.",
      },
    ];

    const list = standardLists.map((caption, keyIndex) => {
      const keyName = `caption_${keyIndex}`;
      return (
        <List.Item key={keyName}>
          <Caption>{` ${caption.description}`}</Caption>
        </List.Item>
      );
    });
    return list;
  };

  const handleValidation = () => {
    if (!rate && selected === "true") {
      setSubmitEnabled(false);
      setError("Field is required");
      return;
    }
    setError("");
  };

  const handleSelectChange = (value) => {
    setSelected(value);
    setRate("");
    if (value === "false") {
      setError("");
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  };

  const handleSubmit = async () => {
    if (!submitEnabled || error) {
      return;
    }
    setSubmitEnabled(false);
    updateProductSetting({
      variables: {
        input: {
          reviewAll: radioButtonValue === REVIEW_ALL,
          approveSelected: radioButtonValue === APPROVE_SELECTED,
          approveAll: radioButtonValue === APPROVE_ALL,
          isPriceTaxInclusive: selected === "true",
          rate: parseFloat(rate),
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_PRODUCT_SETTING);
        let bannerValue = { status: "success", title: cms("operator.message.success") };
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_PRODUCT_SETTING);
          bannerValue = { status: "critical", title: errorResponse };
        }
        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: true,
        });
      })
      .catch(() => {
        setBannerStatus({
          status: "critical",
          title: cms("common.message.error.somethingWentWrong"),
          isOpen: true,
        });
      });
  };

  return (
    <Layout.AnnotatedSection
      title={"Product Price"}
      description={"You can set whether your product price has tax inclusive or exclusive"}
    >
      <Card
        title={[
          "Product Price",
          <TextStyle variation="subdued">
            {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
          </TextStyle>,
        ]}
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => learnMore("Tax"),
          },
        ]}
        sectioned
      >
        <Form onSubmit={() => handleSubmit()}>
          <FormLayout>
            <TextContainer>{"You can set whether your product price has tax inclusive or exclusive"}</TextContainer>
            <Stack wrap={false}>
              <Select
                placeholder={"Type"}
                options={returnOptions}
                id="isPriceTaxInclusive"
                onChange={(value) => handleSelectChange(value)}
                value={selected}
              />
              <TextField
                id="rate"
                placeholder={cms("common.placeholder.zero")}
                suffix={<div className="suffixColor">%</div>}
                min={0}
                step={0.01}
                value={rate.toString()}
                onChange={(value) => {
                  setRate(value);
                  setSubmitEnabled(true);
                }}
                onBlur={() => handleValidation()}
                disabled={selected === "false" || !selected}
                type="number"
                error={error}
              />
            </Stack>
            <Banner isOpen={true} status="critical">
              <p>{cms("common.label.option")}</p>
              <br />
              <List type="bullet">{renderLists()}</List>
            </Banner>
          </FormLayout>
          <CardFooter disabled={!submitEnabled} loading={productSettingLoading} />
        </Form>
      </Card>
    </Layout.AnnotatedSection>
  );
};

Price.propTypes = priceProp.type;

export default Price;

import React, { useContext } from "react";
import { Card, Layout, Caption, Collapsible, TextContainer } from "@shopify/polaris";
import { DropZone } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../context/context";

const Image = () => {
  const { IMAGE } = constant;
  const { handleChange, image, learnMore } = useContext(BrandingContext);
  const { cms = () => {} } = useContext(PrivateContext);

  return (
    <Collapsible open>
      <Layout.AnnotatedSection title={cms("section.logo.title")} description={cms("section.logo.description")}>
        <Card
          title={cms("section.logo.title")}
          actions={[{ content: "Learn more", onAction: () => learnMore(cms("section.logo.title"), "TODO: ") }]}
        >
          <Card.Section>
            <TextContainer>
              {cms("section.logo.subDescription")} <small>(recommended size 200px X 110px)</small>
            </TextContainer>
            <br />
            <DropZone
              id="dropZone"
              onAdd={(value) => handleChange(IMAGE, (value && value.length && value[0]) || "", IMAGE)}
              onRemove={() => handleChange(IMAGE, "", IMAGE)}
              fileList={(image !== "" && [image]) || []}
              allowMultiple={false}
            />
            <br />
            <Caption>{cms("section.logo.caption")}</Caption>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default Image;

import React, { useContext, useState } from "react";
import {
  Card,
  Stack,
  TextStyle,
  DisplayText,
  Caption,
  SkeletonBodyText,
  Button,
  Collapsible,
  Link,
} from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";
import { useQuery } from "react-apollo";
import { FETCH_PLANS } from "app/plans/apollo/queries";

const styleFix = {
  margin: "10px",
  display: "inline-block",
};

const OperatorPlanCard = () => {
  const { history, isLoading, currentUser, cms } = useContext(PrivateContext);
  const [openPlanCard, setOpenPlanCard] = useState(true);

  const { plan = {} } = currentUser;
  const { name = "", price = "" } = plan;

  const { loading: planLoading, data: planData } = useQuery(FETCH_PLANS);

  // FETCH_PLANS data handling.
  const resPlanData = baseHelper.getResponseData(planData, "getPlans");
  let planName;
  if (resPlanData) {
    planName = resPlanData.find((item) => item.code === name);
  }

  if (isLoading) {
    return <SkeletonBodyText lines={3} />;
  }

  return (
    <Card>
      <Card.Header title={cms("common.label.planCard.title")}>
        <Button
          plain
          disclosure={openPlanCard ? "up" : "down"}
          onClick={() => {
            setOpenPlanCard(!openPlanCard);
          }}
        >
          {openPlanCard ? cms("common.label.hide") : cms("common.label.show")}
        </Button>
      </Card.Header>
      <br />
      <Collapsible
        open={openPlanCard}
        id="collapsiblePlanCard"
        transition={{ duration: "150ms", timingFunction: "ease" }}
      >
        <Card>
          <Card.Section subdued>
            <Stack>
              <Stack.Item fill>
                <TextStyle>{planName && baseHelper.ucFirst(planName.name || "")}</TextStyle>
                <Caption>
                  {cms("common.label.planCard.currentPlan")}
                  <div style={styleFix}>
                    <Link url="/update/plan">
                      <TextStyle variation="strong"> Upgrade</TextStyle>
                    </Link>
                  </div>
                </Caption>
              </Stack.Item>
              <Stack.Item>
                <DisplayText>{`$${price}/m`}</DisplayText>
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </Collapsible>
    </Card>
  );
};

export default OperatorPlanCard;

const cmsVendorList = (t) => {
  const cmsData = {
    sortOptions: [
      { label: t("label.providerList.selectSort"), value: "" },
      { label: t("label.providerList.sortByDateAsc"), value: t("label.providerList.createdAtAsc") },
      { label: t("label.providerList.sortByDateDesc"), value: t("label.providerList.createdAtDesc") },
      { label: t("label.providerList.sortByBrandNameAsc"), value: t("label.providerList.brandNameAsc") },
      { label: t("label.providerList.sortByBrandNameDesc"), value: t("label.providerList.brandNameDesc") },
    ],
    promotedBulkActions: [
      {
        content: t("label.providerList.bulkAction"),
      },
    ],
    resourceName: {
      singular: t("label.providerList.Vendor"),
      plural: t("label.providerList.Vendors"),
    },
    filterOptions: [
      { label: t("label.providerList.select"), value: "" },
      { label: t("label.providerList.brand"), value: t("label.providerList.brandName") },
    ],
    message: {
      something_went_wrong: t("error.common.somethingWentWrong"),
      running_background: t("message.providerList.requestSuccess"),
      token_expire: t("message.providerList.token_expire"),
      enterAccessCode: t("message.providerList.enterAccessCode"),
    },
    label: {
      show_all_vendor: t("label.providerList.showAllVendor"),
      select_filter: t("label.providerList.selectFilter"),
      like: t("label.providerList.like"),
      filter_by: t("label.providerList.filterBy"),
      verify_selected: t("label.providerList.verifySelected"),
      delete_selected: t("label.providerList.deleteSelected"),
      search: t("label.providerList.search"),
      tabKey: t("label.providerList.tabKey"),
      sort_name: t("label.providerList.sortName"),
      sort_order: t("label.providerList.sortOrder"),
      perPage: t("label.providerList.perPage"),
      list_filter: t("label.providerList.listFilter"),
      list_search: t("label.providerList.listSearch"),
      filter: t("label.providerList.filter"),
      total: t("label.providerList.total"),
      deleteModal: t("label.providerList.deleteModal"),
      delete: t("label.providerList.delete"),
      primaryDelete: t("label.providerList.primaryDelete"),
      secondaryDelete: t("label.providerList.secondaryDelete"),
      reason: t("label.providerList.reason"),
      logInModal: t("label.providerList.logInModal"),
      logInAs: t("label.providerList.logInAs"),
      fullAccess: t("label.providerList.fullAccess"),
      onlyReadAccess: t("label.providerList.onlyReadAccess"),
      otpModal: t("label.providerList.otpModal"),
      enterCode: t("label.providerList.enterCode"),
      viewDetailsFor: t("label.providerList.viewDetailsFor"),
      name: t("label.providerList.name"),
      email: t("label.providerList.email"),
      onBoardedOn: t("label.providerList.onBoardedOn"),
      page: t("label.providerList.page"),
    },
    sectionContent: {
      deleteVendorSelected: t("sectionContent.providerList.deleteVendorSelected"),
      deleteVendorNote: t("sectionContent.providerList.deleteVendorNote"),
      logInAsAccess: t("sectionContent.providerList.logInAsAccess"),
      otpNote: t("sectionContent.providerList.otpNote"),
    },
    button: {
      deleteFromMc: t("button.providerList.deleteProvider.primaryAction"),
      deleteFromShop: t("button.providerList.deleteProvider.secondaryAction1"),
      confirmOtp: t("button.providerList.accessCodeVerify.primaryAction"),
      resendOtp: t("button.providerList.accessCodeVerify.secondaryAction1"),
      readOnly: t("button.providerList.accessCodeVerify.secondaryAction2"),
      deleteVendor: t("button.providerList.providerList.primaryAction"),
      loginVendor: t("button.providerList.providerList.secondaryAction1"),
    },
    limitOptions: [
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
    ],
    tabs: [
      {
        id: "all-vendors",
        content: t("label.providerList.all"),
        accessibilityLabel: "All vendors",
        panelID: "all-vendors-content",
        value: "all",
      },
      {
        id: "pending-approval",
        content: t("label.providerList.pendingApproval"),
        panelID: "pending-approval-content",
        value: "pending",
      },
      {
        id: "approved",
        content: t("label.providerList.approved"),
        panelID: "approved-content",
        value: "approved",
      },
    ],
  };

  return cmsData;
};

export default cmsVendorList;

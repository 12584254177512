// import packages
import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Layout, Card, RadioButton, Stack,
  PageActions } from "@shopify/polaris";
// import context
import { PrivateContext } from "lib/context";

// import provider
import { FormProvider } from "../add/context/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import helpers
import { baseHelper } from "lib/helpers/index";

// import cms and constants
import constant from "lib/constant/constant";
import { useTranslation } from "react-i18next";
import fieldData from "./cms/fieldData";

import Bucket from "./subFeatures/bucket/bucket"

// import components
import { Banner } from "lib/components/index";
// import gql
import { UPDATE_PRODUCT_ATTACHMENT_SETTING } from "../../../../apollo/mutations/index";

const { APPROVE_SELECTED, BUCKET } = constant;

const OperatorProductAttachmentSetting =() => {
  const { t } = useTranslation();
  const [updateProductAttachmentSetting, { loading: productAttachmentSettingLoading }] = useMutation(UPDATE_PRODUCT_ATTACHMENT_SETTING);
  const { history, currentUser, cms } = useContext(PrivateContext);
  const { aws } = currentUser;
  const [buttonValue, setButtonValue] = useState(true);
  const [value, setValue] = useState({});
  
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  
  const handleChange = (fieldName, fieldValue) => {
    setButtonValue(false);
    setValue({
      ...value,
      [fieldName]: fieldValue,
    });
  };

  const onSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };
    if (!value.bucket || value.bucket.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: "critical",
        title: "S3 Bucket name is required.",
      };
      setBannerStatus(banner);
      return;
    }
    if (!value.id || value.id.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: "critical",
        title: "S3 bucket access key is required.",
      };
      setBannerStatus(banner);
      return;
    }
    if (!value.secret || value.secret.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: "critical",
        title: "S3 bucket secret key is required.",
      };
      setBannerStatus(banner);
      return;
    }
    submitCallBack();
  };

  const submitCallBack = () => {
    const {
      bucket,
      path,
      id,
      secret,
    } = value;

    const formValues = {
      bucket,
      path:'/',
      id,
      secret,
     }
    saveProductAttachmentInfo(formValues);
  }

  const saveProductAttachmentInfo = async formValues => {
    setButtonValue(true);

      const val = updateProductAttachmentSetting({
        variables: {
          input: formValues,
        },
      })
      .then(res => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PRODUCT_ATTACHMENT_SETTING);
        let bannerValue = { status: "success", title: "Product attachment setting updated successfully." };
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PRODUCT_ATTACHMENT_SETTING);
          bannerValue = { status: "critical", title: errorResponse };
        }
        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: true,
        });
      })
      .catch(() => {
        setBannerStatus({
          status: "critical",
          title: cms("common.message.error.somethingWentWrong"),
          isOpen: true,
        });
      });
    }

  useEffect(() => {
    setValue(aws);
  }, [aws]);

  const renderBanner = () => {
    const { status, title, isOpen } = bannerStatus;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBannerStatus({ isOpen: false, status: "", title: "" })}
      />
    );
  };

  return (
    <>
      {bannerStatus.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection title= "Product Attachment Setting" description="Product Attachment Setting">
      <Layout.Section>
      <FormProvider value={{ handleChange, data: value, aws }}>
      <Bucket />
      </FormProvider>

        <PageActions
          primaryAction={{
            id: "submitProductAttachmentSetting",
            content: t("label.Update"),
            disabled: productAttachmentSettingLoading || buttonValue,
            loading: productAttachmentSettingLoading,
            onAction: () => onSubmit(),
          }}
          secondaryActions={[
            {
              id: "cancelProductAttachmentSetting",
              content: cms("common.button.cancel"),
              onAction: () => history.push("/"),
            },
          ]}
        />
      </Layout.Section>
                 
         
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProductAttachmentSetting), { feature: constant.PRODUCT_SETTING });

import React, { useState, useEffect, useContext, useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import { PageActions, Layout } from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import components
import { Banner, Sheet } from "lib/components";

// import helper
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import contexts
import { PrivateBrandingContext, PrivateContext } from "lib/context";
import { BrandingProvider } from "./context/context";

import validate from "./yup";

import CustomPage from "./subFeatures";

// imports mutations
import { UPDATE_BRANDING } from "../../../../apollo/mutations";

const {
  BRANDING,
  BUTTON_TEXT,
  BUTTON,
  COLOR,
  CONTACT,
  COPY,
  DEFAULT_BUTTON_COLOR,
  DEFAULT_HEADER_COLOR,
  DEFAULT_TEXT_COLOR,
  DOMAIN,
  EMAIL,
  FOOTER,
  HEADER_TEXT,
  HEADER,
  HEX,
  IMAGE,
  LOGIN,
  BRAND_DESCRIPTION,
  IMAGE_URL,
  REGISTER,
  gql: { UPDATE_BRANDING: UPDATE_BRANDING_GQL },
} = constant;

const OperatorBranding = () => {
  const { setBrandingData = () => {}, branding = {} } = useContext(PrivateBrandingContext);
  const { currentUser = {}, history, cms = () => {} } = useContext(PrivateContext);

  const {
    shop,
    style,
    register: userRegister,
    isHideMcInfo = false,
    loginText = "",
    footerText = "",
    emailSign = "",
    logo = "",
    brand = {},
    plan = {},
  } = currentUser;

  const { cover: brandCover = {}, description: brandDescription = "" } = brand || {};
  const { imageUrl = "" } = brandCover || {};

  const [isSubmitEnable, setIsSubmitEnable] = useState(false);
  const [prevButtonHex, setPrevButtonHex] = useState("");
  const [prevButtonTextHex, setPrevButtonTextHex] = useState("");
  const [prevHeaderHex, setPrevHeaderHex] = useState("");
  const [prevHeaderTextHex, setPrevHeaderTextHex] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [image, setImage] = useState((logo && { url: logo }) || "");
  const [coverImageURL, setCoverImage] = useState(imageUrl);
  const [description, setDescription] = useState(brandDescription);
  const [domain, setDomain] = useState(currentUser.domain || "");
  const termsLink = brand && brand.termsLink;
  const policyLink = brand && brand.policyLink;
  const [isHeaderCopy, setHeaderCopy] = useState(false);
  const [isHideContact, setContact] = useState(isHideMcInfo);
  const [header, setHeaderColor] = useState(
    style && style.header && style.header.color
      ? baseHelper.hexToHSL(style.header.color)
      : baseHelper.hexToHSL(DEFAULT_HEADER_COLOR)
  );
  const [headerHex, setHeaderHex] = useState(
    style && style.header && style.header.color ? style.header.color : DEFAULT_HEADER_COLOR
  );
  const [headerText, setHeaderTextColor] = useState(
    style && style.header && style.header.font
      ? baseHelper.hexToHSL(style.header.font)
      : baseHelper.hexToHSL(DEFAULT_TEXT_COLOR)
  );
  const [headerTextHex, setHeaderTextHex] = useState(
    style && style.header && style.header.font ? style.header.font : DEFAULT_TEXT_COLOR
  );
  const [button, setButtonColor] = useState(
    baseHelper.hexToHSL((style && style.button && style.button.color) || DEFAULT_BUTTON_COLOR)
  );
  const [buttonHex, setButtonHex] = useState((style && style.button && style.button.color) || DEFAULT_BUTTON_COLOR);
  const [buttonText, setButtonTextColor] = useState(
    style && style.button && style.button.font
      ? baseHelper.hexToHSL(style.button.font)
      : baseHelper.hexToHSL(DEFAULT_TEXT_COLOR)
  );
  const [buttonTextHex, setButtonTextHex] = useState(
    style && style.button && style.button.font ? style.button.font : DEFAULT_TEXT_COLOR
  );
  const [login, setStateLogin] = useState({
    isDefault: !loginText,
    loginText: loginText || cms("section.loginPageHeader.value.defaultText"),
  });
  const [register, setStateRegister] = useState({
    isDefault: !(userRegister && userRegister.title),
    title: userRegister && userRegister.title ? userRegister.title : cms("section.registerPageContent.value.title"),
    description:
      userRegister && userRegister.desc ? userRegister.desc : cms("section.registerPageContent.value.description"),
  });
  const [email, setStateEmail] = useState({
    isDefault: !emailSign,
    content: emailSign || cms("section.emailSignature.value.defaultText", { shop }),
  });
  const [footer, setStateFooter] = useState({
    isDefault: !footerText,
    content: footerText || cms("section.footerText.value.defaultText"),
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [sheetActive, setSheetActive] = useState(false);
  const [sheetContent, setSheetContent] = useState("");
  const [sheetTitle, setSheetTitle] = useState("");

  const learnMore = (title, content) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(content);
  };

  const [setBranding, { loading: brandingLoading }] = useMutation(UPDATE_BRANDING);

  const handleDomainChange = (value) => {
    setDomain(value);
  };

  const handleLoginChange = (field, value) => {
    setStateLogin((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleRegisterChange = (field, value) => {
    setStateRegister((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleEmailChange = (field, value) => {
    setStateEmail((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleFooterChange = (field, value) => {
    setStateFooter((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleContactChange = (value) => {
    setContact(value);
  };

  const updateBranding = useCallback(
    async (requestData) => {
      try {
        const response = await setBranding({
          variables: { input: requestData },
        });
        const responseError = baseHelper.getResponseError(response.data, UPDATE_BRANDING_GQL);
        if (responseError) {
          setBanner({ isOpen: true, status: "critical", title: responseError });
          return null;
        }
        setBanner({ isOpen: true, status: "success", title: cms("message.success") });
      } catch (err) {
        setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
      }
      return null;
    },
    [setBranding, cms]
  );
  const getStateData = useCallback(
    (updatedParams) => {
      const {
        base64Image = null,
        resetHeaderColor = null,
        resetHeaderTextColor = null,
        resetButtonColor = null,
        resetButtonTextColor = null,
        imageUrl,
        description,
      } = updatedParams || {};
      const requestData = {
        buttonColor: resetButtonColor || buttonHex,
        buttonFont: resetButtonTextColor || buttonTextHex,
        color: resetHeaderColor || headerHex,
        brand: {
          termsLink: termsLink || "",
          policyLink: policyLink || "",
          cover: {},
          description,
        },
        brandCoverImage: {
          url: imageUrl,
        },
        emailSign: email.isDefault ? "" : email.content,
        footer: footer.isDefault ? "" : footer.content,
        headerFont: resetHeaderTextColor || headerTextHex,
        hideMcContactInfo: isHideContact,
        imageId: null,
        imageUrl: null,
        loginText: login.isDefault ? "" : login.loginText,
        logo: base64Image,
        register: {
          desc: register.isDefault ? "" : register.description,
          title: register.isDefault ? "" : register.title,
        },
      };

      if (baseHelper.isGrowthAndAbovePlan({ plan })) {
        requestData.domain = domain;
      }

      return requestData;
    },
    [
      buttonHex,
      buttonTextHex,
      headerHex,
      plan,
      domain,
      termsLink,
      policyLink,
      email.isDefault,
      email.content,
      footer.isDefault,
      footer.content,
      headerTextHex,
      isHideContact,
      login.isDefault,
      login.loginText,
      register.isDefault,
      register.description,
      register.title,
    ]
  );

  const postBranding = useCallback(
    (updatedParams) => {
      const requestData = getStateData(updatedParams);
      updateBranding(requestData);
    },
    [getStateData, updateBranding]
  );

  const handleColorAndHexChange = (field, color, hexCode, reset = false) => {
    switch (field) {
      case HEADER:
        setHeaderColor(color);
        setHeaderHex(hexCode);
        if (reset) {
          postBranding({ resetHeaderColor: hexCode });
        }
        break;
      case HEADER_TEXT:
        setHeaderTextColor(color);
        setHeaderTextHex(hexCode);
        if (reset) {
          postBranding({ resetHeaderTextColor: hexCode });
        }
        break;
      case BUTTON:
        setButtonColor(color);
        setButtonHex(hexCode);
        if (reset) {
          postBranding({ resetButtonColor: hexCode });
        }
        break;
      case BUTTON_TEXT:
        setButtonTextColor(color);
        setButtonTextHex(hexCode);
        if (reset) {
          postBranding({ resetButtonTextColor: hexCode });
        }
        break;
      default:
        break;
    }
  };

  const validateHexCode = async (field, value) => {
    const validationError = await validate(field, value, cms("message.error"));
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
    return validationError;
  };

  const convertHexToHSL = async (field, value) => {
    const isAnyError = await validateHexCode(field, value);
    if (isAnyError !== "") {
      return false;
    }
    const color = baseHelper.hexToHSL(value);
    return color;
  };

  const convertHslToHex = (field, value) => {
    const { hue, saturation, brightness } = value;
    if (!(hue || saturation || brightness)) {
      switch (field) {
        case BUTTON:
          return buttonHex;
        case HEADER:
          return headerHex;
        case BUTTON_TEXT:
          return buttonTextHex;
        case HEADER_TEXT:
          return headerTextHex;
        default:
          return "";
      }
    }
    const hexCode = baseHelper.hslToHex(hue, parseFloat(saturation * 100), parseFloat(brightness * 100));
    return hexCode;
  };

  const setHexAndColorData = async (field, value, state, reset = false) => {
    let color = {};
    let hexCode = "";
    if (value !== "") {
      if (state === COLOR) {
        hexCode = convertHslToHex(field, value);
        color = value;
      }
      if (state === HEX) {
        color = await convertHexToHSL(field, value);
        hexCode = value;
      }
    }
    handleColorAndHexChange(field, color, hexCode, reset);
  };

  const handleCopyChange = (value) => {
    setHeaderCopy(value);
    if (!headerHex) {
      return;
    }
    let hexCode = headerHex;
    if (!value) {
      hexCode = "";
    }
    setHexAndColorData(BUTTON, hexCode, HEX);
  };

  const handleImageChange = (field, value) => {
    setImage(value);
  };

  const handleCoverChange = (field, value) => {
    setCoverImage(value);
  };

  const handleDescriptionChange = (field, value) => {
    setDescription(value);
  };

  const handleStateChange = (field, value, state, reset = false) => {
    setIsSubmitEnable(true);
    switch (state) {
      case BRAND_DESCRIPTION:
        return handleDescriptionChange(field, value);
      case DOMAIN:
        return handleDomainChange(value);
      case LOGIN:
        return handleLoginChange(field, value);
      case IMAGE:
        return handleImageChange(field, value);
      case IMAGE_URL:
        return handleCoverChange(field, value);
      case REGISTER:
        return handleRegisterChange(field, value);
      case EMAIL:
        return handleEmailChange(field, value);
      case FOOTER:
        return handleFooterChange(field, value);
      case CONTACT:
        return handleContactChange(value);
      case COLOR:
      case HEX:
        return setHexAndColorData(field, value, state, reset);
      case COPY:
        return handleCopyChange(value);
      default:
        return null;
    }
  };

  const handleTiny = (field, value, state) => {
    return handleStateChange(field, value, state);
  };

  const handleChange = (field, value, state) => {
    return handleStateChange(field, value, state);
  };

  useEffect(() => {
    if ((isHeaderCopy && headerHex !== buttonHex) || (!isHeaderCopy && headerHex === buttonHex)) {
      setHeaderCopy(!isHeaderCopy);
    }
  }, [buttonHex, headerHex, isHeaderCopy]);

  if ((buttonHex && prevButtonHex !== buttonHex) || (buttonTextHex && prevButtonTextHex !== buttonTextHex)) {
    setPrevButtonHex(buttonHex);
    setPrevButtonTextHex(buttonTextHex);
    setBrandingData({ ...branding, buttonColor: buttonHex, buttonFont: buttonTextHex });
  }

  if ((headerHex && prevHeaderHex !== headerHex) || (headerTextHex && prevHeaderTextHex !== headerTextHex)) {
    setPrevHeaderHex(headerHex);
    setPrevHeaderTextHex(headerTextHex);
    setBrandingData({ ...branding, headerColor: headerHex, headerFont: headerTextHex });
  }

  const resetDefault = (field, value, state) => {
    handleStateChange(field, value, state, true);
  };
  const onSubmit = async () => {
    setIsSubmitEnable(false);
    if (image && image.url) {
      return postBranding({ imageUrl: coverImageURL, description });
    }

    return postBranding({ base64Image: image, imageUrl: coverImageURL, description });
  };

  const color = { header, headerText, button, buttonText };
  const hex = { headerHex, headerTextHex, buttonHex, buttonTextHex, DEFAULT_HEADER_COLOR, DEFAULT_TEXT_COLOR };
  const page = { login, register, email, footer, isHideContact, handleChange, resetDefault, handleTiny };

  const dismissBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  return (
    <>
      <Layout.Section>
        <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={dismissBanner} />
        <BrandingProvider
          value={{
            domain,
            color,
            hex,
            page,
            isHeaderCopy,
            errorMessage,
            handleChange,
            resetDefault,
            image,
            handleTiny,
            setBanner,
            coverImageURL,
            description,
            learnMore,
          }}
        >
          <CustomPage />
        </BrandingProvider>
        <Sheet
          isOpen={sheetActive}
          title={sheetTitle}
          onClose={() => setSheetActive(false)}
          secondaryAction={{ content: "Close", onAction: () => setSheetActive(false) }}
          primaryAction={{ content: "Done", onAction: () => setSheetActive(false) }}
        >
          {sheetContent}
        </Sheet>
        <Layout.Section>
          <PageActions
            primaryAction={{
              id: "submit",
              content: cms("common.button.submit"),
              onAction: () => onSubmit(),
              loading: brandingLoading,
              disabled: brandingLoading || !isSubmitEnable,
            }}
            secondaryActions={[
              { id: "cancel", content: cms("common.button.cancel"), onAction: () => history.push("/") },
            ]}
          />
        </Layout.Section>
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorBranding), { feature: BRANDING });

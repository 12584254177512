const viewListData = {
  brandTabs: [
    {
      id: "vendor-products",
      content: "Products",
      panelId: "product-details",
      accessibilityLabel: "Product-Details",
    },
    {
      id: "vendor-orders",
      content: "Orders",
      panelId: "order-details",
      accessibilityLabel: "Order-Details",
    },
    {
      id: "vendor-payment",
      content: "Payment",
      panelId: "payment-details",
      accessibilityLabel: "Payment-Details",
    },
    {
      id: "vendor-shipping",
      content: "Shipping",
      panelId: "shipping-details",
      accessibilityLabel: "Shipping-details",
    },
    {
      id: "vendor-socialLink",
      content: "Social Link",
      panelId: "socialLink-details",
      accessibilityLabel: "socialLink-details",
    },
    {
      id: "vendor-other",
      content: "Other",
      panelId: "other-details",
      accessibilityLabel: "Other-details",
    },
  ],
};

export default viewListData;

import constant from "lib/constant/constant";

const { onboardingSteps } = constant;
const { ABOUT, WELCOME, COMMISSION, TERMS, VENDOR, FINISH, UPDATE_PROFILE } = onboardingSteps;

const tabs = [
  {
    id: WELCOME,
    content: "Welcome",
    accessibilityLabel: "welcome-to-marketcube",
    panelID: "welcome-content",
    tabIndex: 0,
  },
  {
    id: ABOUT,
    content: "Step 1",
    accessibilityLabel: "about",
    panelID: "about-content",
    tabIndex: 1,
  },
  {
    id: UPDATE_PROFILE,
    content: "Step 2",
    accessibilityLabel: "updateProfile",
    panelID: "updateProfile-content",
    tabIndex: 2,
  },
  {
    id: COMMISSION,
    content: "Step 3",
    accessibilityLabel: "Commission settings",
    panelID: "commission-content",
    tabIndex: 3,
  },
  {
    id: TERMS,
    content: "Step 4",
    panelID: "terms-content",
    tabIndex: 4,
  },
  {
    id: VENDOR,
    content: "Step 5",
    panelID: "addProvider-content",
    tabIndex: 5,
  },
  {
    id: FINISH,
    content: "Finish Setup",
    panelID: "finish-content",
    tabIndex: 6,
  },
];

export default tabs;

import React from "react";
import { Card, Stack, Select, Heading, Pagination } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ActivityListFooter = (props) => {
  const { optionsList, onSelectLimit, perPageCount, totalCount, onPerPage, currentPage } = props;
  const { t } = useTranslation();
  let maxPage = (totalCount && totalCount && parseInt(totalCount / perPageCount, 10)) || 0;
  maxPage = (maxPage && ((totalCount % perPageCount && maxPage + 1) || maxPage)) || 0;
  return (
    <>
      <Card.Section>
        <Stack>
          <Stack.Item fill>
            <Heading>
              {`${t("label.logsList.totalCount")}:`}
              <span className="totalCount">{totalCount}</span>
            </Heading>
          </Stack.Item>
          <Stack.Item fill>
            {(maxPage && (
              <Pagination
                hasPrevious={(maxPage && parseInt(currentPage, 10) !== 1 && true) || false}
                onPrevious={() => {
                  if (parseInt(currentPage, 10) !== 1) {
                    onPerPage(parseInt(currentPage, 10) - 1);
                  }
                }}
                hasNext={(parseInt(currentPage, 10) < maxPage && true) || false}
                onNext={() => {
                  if (parseInt(currentPage, 10) <= totalCount) {
                    onPerPage(parseInt(currentPage, 10) + 1);
                  }
                }}
              />
            )) ||
              ""}
          </Stack.Item>
          <Stack.Item>
            <Select value={perPageCount} options={optionsList} onChange={onSelectLimit} />
          </Stack.Item>
        </Stack>
      </Card.Section>
    </>
  );
};

ActivityListFooter.defaultProps = {
  optionsList: [],
  perPageCount: "",
  totalCount: "",
  currentPage: 1,
  onSelectLimit: () => {},
  onPerPage: () => {},
};

ActivityListFooter.propTypes = {
  optionsList: PropTypes.arrayOf(PropTypes.object),
  perPageCount: PropTypes.number,
  totalCount: PropTypes.number,
  onSelectLimit: PropTypes.func,
  onPerPage: PropTypes.func,
  currentPage: PropTypes.number,
};

export default ActivityListFooter;

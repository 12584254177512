import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const firstNameSchema = (cms) => {
  return yup.object().shape({
    firstName: yup.string().required(cms("common.message.error.firstNameIsRequired")).nullable(),
  });
};

const lastNameSchema = (cms) => {
  return yup.object().shape({
    lastName: yup.string().required(cms("common.message.error.lastNameIsRequired")).nullable(),
  });
};

const { emailRegex, handleBrandRegex } = yupHelper;
const emailSchema = (cms) => {
  return yup.object({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail"))
      .nullable(),
  });
};

const phoneNumberSchema = (cms) => {
  return yup.object().shape({
    phoneNumber: yup
      .string()
      .required(cms("common.message.error.phoneNumberIsRequired"))
      .matches(/^[0-9]+$/, cms("common.message.error.positiveNumber"))
      .min(7, cms("common.message.error.minPhoneNumber"))
      .max(15, cms("common.message.error.maxPhoneNumber"))
      .nullable(),
  });
};

const citySchema = (cms) => {
  return yup.object().shape({
    city: yup.string().required(cms("common.message.error.cityIsRequired")).nullable(),
  });
};

const countrySchema = (cms) => {
  return yup.object().shape({
    country: yup.string().required(cms("common.message.error.countryIsRequired")).nullable(),
  });
};

const postalCodeSchema = (cms) => {
  return yup.object().shape({
    postalCode: yup
      .string()
      .required(cms("common.message.error.postalCodeIsRequired"))
      .matches(/^[a-zA-Z0-9]+$/, cms("common.message.error.alphanumeric"))
      .min(4, cms("common.message.error.minPostalCode"))
      .max(6, cms("common.message.error.maxPostalCode"))
      .nullable(),
  });
};

// const provinceCodeSchema = (cms) => {
//   return yup.object().shape({
//     provinceCode: yup.string().required(cms("common.message.error.provinceCode")).nullable(),
//   });
// };

const streetAddressSchema = (cms) => {
  return yup.object().shape({
    streetAddress: yup.string().required(cms("common.message.error.streetAddressIsRequired")).nullable(),
  });
};
const brandNameSchema = (cms) => {
  return yup.object().shape({
    brandName: yup.string().required(cms("common.message.error.brandNameIsRequired")).nullable(),
  });
};
const brandSlugSchema = (cms) => {
  return yup.object().shape({
    slug: yup
      .string()
      .required(cms("common.message.error.slug"))
      .matches(handleBrandRegex, cms("common.message.error.slugValidation"))
      .nullable(),
  });
};
const brandDescriptionSchema = () => {
  return yup.object().shape({
    description: yup.string().required("Brand Description is required").nullable(),
  });
};
const bankNameSchema = yup.object().shape({
  bankName: yup.string().required("required.errorMessage.bankName").nullable(),
});
const accountNumberSchema = yup.object().shape({
  accountNumber: yup.string().required("required.errorMessage.accountNumber").nullable(),
});
const sortCodeSchema = yup.object().shape({
  sortCode: yup.string().required("required.errorMessage.sortCode").nullable(),
});

export {
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  citySchema,
  countrySchema,
  postalCodeSchema,
  // provinceCodeSchema,
  streetAddressSchema,
  brandNameSchema,
  brandSlugSchema,
  brandDescriptionSchema,
  bankNameSchema,
  accountNumberSchema,
  sortCodeSchema,
};

import React, { useContext } from "react";
import { Card, TextStyle, Stack, Link, Caption, DataTable } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { customerProp } from "../propsType";

const Pickup = (props) => {
  const { lineData } = props;
  const { cms } = useContext(PrivateContext);
  const getFulfillmentStack = (item) => {
    const { address, city, country, pinCode } = item.consignmentPickAddress || {};
    return [
      <Stack.Item fill>
        <Link url="">{item.name}</Link>
        <Caption>
          <TextStyle variation="subdued">
            {cms("label.vendor")}
            <Link>{item.vendor}</Link>
          </TextStyle>
        </Caption>
      </Stack.Item>,

      <Stack.Item>
        {address ? (city && `${address},`) || address : ""}
        <br />
        {city ? (country && `${city},`) || city : ""}
        <br />
        {country ? (pinCode && `${country},`) || country : ""}
        {pinCode ? [<br />, pinCode || ""] : ""}
      </Stack.Item>,
    ];
  };

  const fulfillments = lineData
    .filter((item) => item.isVendorFulfilled && item.isConsignmentPick && item.fulfillmentStatus !== constant.FULFILLED)
    .map((selectedItem) => getFulfillmentStack(selectedItem));

  return (
    <div className="add-ellipsis">
      <Card.Section subdued>
        <DataTable
          columnContentTypes={["text", "text"]}
          headings={[cms("label.product"), cms("label.pickupLocation")]}
          rows={fulfillments}
        />
      </Card.Section>
      <Card>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{cms("label.totalFulfillments")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">
                {fulfillments.length} of
                {lineData.length}
              </TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};

Pickup.propTypes = customerProp.type;
export default Pickup;

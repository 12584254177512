import React, { useContext } from "react";
import { Card, TextField, Layout, FormLayout, Select, TextContainer, Caption } from "@shopify/polaris";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

import { priceProp } from "../../../../generic/edit/propTypes";

const Price = (props) => {
  const { cms, data, handleDiscount, handleChange, learnMore } = props;
  const { discount = {} } = data || {};
  const { price: vendorPrice = "", type: vendorType = "" } = discount || {};
  const { currentUser } = useContext(PrivateContext);
  const {
    value: { MIN_PRICE = 0, MAX_FLAT = 99999999, MAX_AMOUNT, MAX_PERCENTAGE },
  } = constant;
  const maxDiscount = (vendorType === constant.PERCENTAGE && MAX_PERCENTAGE) || MAX_AMOUNT;

  const acceptOnlyValidInput = (value, prevValue, max = MAX_FLAT, min = MIN_PRICE) => {
    if (value >= min && value <= max) {
      return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
    }
  };

  return (
    <Layout.Section>
      <Card
        title={[
          cms("section.price.title"),
          data && data.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(data.updatedAt)}`}</Caption>,
        ]}
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => {
              learnMore(cms("section.price.title"), "TODO:");
            },
          },
        ]}
        id="productPrice"
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>You can edit price and compare price for the product.</TextContainer>
            <FormLayout.Group>
              <TextField
                label={cms("section.price.label.price")}
                value={(data.price && data.price.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                prefix={currentUser.moneyFormat}
                onChange={(value) => handleChange(constant.PRICE, acceptOnlyValidInput(value, data.price))}
              />
              <TextField
                label={cms("section.price.label.comparePrice")}
                value={(data.comparePrice && data.comparePrice.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                prefix={currentUser.moneyFormat}
                onChange={(value) =>
                  handleChange(constant.COMPARE_PRICE, acceptOnlyValidInput(value, data.comparePrice))}
              />
              <TextField
                label={cms("section.price.label.discount")}
                value={(vendorPrice && vendorPrice.toString()) || ""}
                placeholder={constant.ZERO_PRICE}
                max={maxDiscount}
                prefix={vendorType === constant.FLAT && currentUser.moneyFormat}
                suffix={vendorType === constant.PERCENTAGE && "%"}
                onChange={(value) =>
                  handleDiscount(constant.PRICE, acceptOnlyValidInput(value, vendorPrice, maxDiscount))}
                disabled={!vendorType}
                connectedRight={
                  <Select
                    value={vendorType || ""}
                    options={[
                      {
                        label: cms("common.label.flat"),
                        value: constant.FLAT,
                      },
                      {
                        label: cms("common.label.percentage"),
                        value: constant.PERCENTAGE,
                      },
                    ]}
                    placeholder={cms("section.price.placeholder.selectType")}
                    onChange={(value) => handleDiscount(constant.TYPE, value)}
                  />
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};
Price.propTypes = priceProp.type;
export default Price;

import { yupHelper } from "lib/helpers";
import emailSchema from "./schema";

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  const promise = validateValue(emailSchema(cms), { email: value });
  return handlePromiseError(promise);
};

export default validate;

import React, { useContext } from "react";
import { Card, Layout, Caption, Collapsible, TextContainer } from "@shopify/polaris";
import { DropZone } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../context/context";

const Cover = () => {
  const { IMAGE_URL } = constant;
  const { handleChange, coverImageURL: imageUrl, learnMore } = useContext(BrandingContext);
  const { cms = () => {} } = useContext(PrivateContext);
  return (
    <Collapsible open>
      <Layout.AnnotatedSection title={cms("section.cover.title")} description={cms("section.cover.description")}>
        <Card
          title={cms("section.cover.title")}
          actions={[{ content: "Learn more", onAction: () => learnMore("Updated your brand cover", "TODO: ") }]}
        >
          <Card.Section>
            <TextContainer>
              {cms("section.cover.subDescription")} <small> (recommended size 2000px X 400px) </small>
            </TextContainer>
            <br />
            <DropZone
              id="dropZone"
              onAdd={(value) => handleChange(IMAGE_URL, (value && value.length && value[0]) || "", IMAGE_URL)}
              onRemove={() => handleChange(IMAGE_URL, "", IMAGE_URL)}
              fileList={(imageUrl !== "" && [imageUrl]) || []}
              allowMultiple={false}
            />
            <br />
            <Caption>{cms("section.cover.value.defaultText")}</Caption>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default Cover;

import React, { useContext } from "react";
import { Card, Layout, Stack, RadioButton, Collapsible, TextField, TextContainer } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../../../context/context";

const RegisterContent = () => {
  const { cms = () => {} } = useContext(PrivateContext);
  const { REGISTER, IS_DEFAULT, TITLE, DESCRIPTION } = constant;
  const { page, learnMore } = useContext(BrandingContext);
  const { register, handleChange, resetDefault } = page;

  const registerList = [
    {
      label: cms("section.registerPageContent.label.default"),
      value: true,
      id: "registerDefault",
    },
    {
      label: cms("section.registerPageContent.label.customized"),
      value: false,
      id: "registerCustom",
    },
  ];

  const renderList = () => {
    return registerList.map((list) => (
      <RadioButton
        label={list.label}
        checked={register.isDefault === list.value}
        id={list.id}
        key={list.id}
        name="register"
        onChange={() => handleChange(IS_DEFAULT, list.value, REGISTER)}
      />
    ));
  };

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.registerPageContent.title")}
        description={cms("section.registerPageContent.description")}
      >
        <Card
          title={cms("section.registerPageContent.title")}
          actions={[
            { content: "Learn more", onAction: () => learnMore(cms("section.registerPageContent.title"), "TODO: ") },
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("section.registerPageContent.subDescription")}</TextContainer>
            <br />
            <Stack vertical>{renderList()}</Stack>
            <br />
            <Collapsible open={!register.isDefault} id="custom-register-collapsible">
              <TextField
                id="customTitle"
                label={cms("section.registerPageContent.label.title")}
                value={register.title}
                onChange={(value) => handleChange(TITLE, value, REGISTER)}
                labelAction={
                  register.title === "" && {
                    content: cms("label.reset"),
                    onClick: () => resetDefault(TITLE, cms("section.registerPageContent.value.title"), REGISTER),
                  }
                }
              />
              <br />
              <TextField
                id="customDescription"
                label={cms("section.registerPageContent.label.description")}
                value={register.description}
                onChange={(value) => handleChange(DESCRIPTION, value, REGISTER)}
                labelAction={
                  register.description === "" && {
                    content: cms("label.reset"),
                    onClick: () =>
                      resetDefault(DESCRIPTION, cms("section.registerPageContent.value.description"), REGISTER),
                  }
                }
              />
            </Collapsible>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default RegisterContent;

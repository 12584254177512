import React from "react";
import { Layout, Card, DescriptionList } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// constant
import constant from "lib/constant/constant";

// propTypes
import vendorProfileProps from "../../propTypes";

const Shipping = (props) => {
  const { t } = useTranslation();
  const { vendorDetails = {} } = props;
  const { shipping = [] } = vendorDetails || {};
  let shippingTabData = [];
  const shippingInformation = [
    {
      term: t("label.advanceVendor.streetAddress"),
      description: shipping.address,
    },
    {
      term: t("label.advanceVendor.streetCity"),
      description: shipping.city,
    },
    {
      term: t("label.advanceVendor.country"),
      description: shipping.country,
    },
    {
      term: t("label.advanceVendor.postalCode"),
      description: shipping.pinCode,
    },
  ];
  shippingTabData = shippingInformation.map((item) => {
    const updatedItem = { ...item };
    if (!updatedItem.description) {
      updatedItem.description = t("label.advanceVendor.NoDetailsFound");
    }
    return updatedItem;
  });

  const getDescriptionList = () => {
    if (Object.keys(shipping).length === 0) {
      return <div className="noData">{t("label.advanceVendor.NoDataAvailable")}</div>;
    }
    return <DescriptionList items={shippingTabData} />;
  };
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>{getDescriptionList()}</Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

Shipping.propTypes = vendorProfileProps.type;

export default withFeature(withErrorBoundary(Shipping), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
});

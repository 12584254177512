import engine from "store/src/store-engine";
import cookieStorage from "store/storages/cookieStorage";
import localStorage from "store/storages/localStorage";
import defaultPlugins from "store/plugins/defaults";

const store = engine.createStore([localStorage], defaultPlugins);
const storeCookie = engine.createStore([cookieStorage], defaultPlugins);

class StorageHelper {
  get = (key) => store.get(key);

  getAll = () => {
    const storage = {};
    store.each((value, key) => {
      storage[key] = value;
    });
    return storage;
  };

  remove = (key) => store.remove(key);

  removeAll = () => store.clearAll();

  set = (key, value) => store.set(key, value);

  // For Cookies
  removeAllCookie = () => storeCookie.clearAll();

  setCookie = (key, value, SameSite = "None", Secure) => storeCookie.set(key, value, SameSite, Secure);
}

export default new StorageHelper();

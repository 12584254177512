const config = {
  "/": [
    {
      label: "Activate custom domain",
      caption: "Manage custom domain of sellers",
      link: "/domain",
    },
    {
      label: "Feature Plan",
      caption: "Manage Feature Configurations",
      link: "/feature",
    },
    {
      caption: "Manage Plan",
      label: "Manage Plan",
      link: "",
    },
  ],
};

const title = {
  "/": "Settings",
  "/orders": "Order Settings",
  "/payments": "Payment Settings",
  "/products": "Product Settings",
  "/providers": "Vendor Settings",
};

export { config, title };

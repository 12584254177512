const publicPageContent = {
  verifyEmail: {},
  resendVerifyEmail: {},
  login: {},
  register: {},
  resetPassword: {},
  registerRole: {},
  shopifyRedirect: {},
  plan: {},
  shopifyPayment: {},
  linkRegister: {},
};

export default publicPageContent;

import React, { useContext, useState } from "react";
import { TextContainer, Layout, Card, Select, FormLayout, List, Banner as PolarisBanner } from "@shopify/polaris";

// import context
import { PrivateContext, OnboardingContext } from "lib/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import components
import { Banner, Sheet } from "lib/components";

// import constants
import constant from "lib/constant/constant";

import {
  OperatorBulkCreate,
  OperatorBulkInvite,
  OperatorFormCreate,
  OperatorGenericLink,
  OperatorInvite,
} from "./subFeatures";

const { GENERIC_LINK, CREATE_FORM, INVITE_CSV, CREATE_CSV, INVITE_FORM } = constant;

const OperatorVendorAdd = () => {
  const { cms = {} } = useContext(PrivateContext);
  const { setIsSelectChange = () => {}, isOnboarding } = useContext(OnboardingContext);
  const [banner, setBanner] = useState({
    action: null,
    isOpen: false,
    status: "",
    title: "",
    onDismiss: null,
  });
  const [selected, setSelected] = useState(CREATE_FORM);
  const addProviderOptions = [
    { label: cms("section.addVendorMethod.label.invite"), value: INVITE_FORM },
    {
      label: cms("section.addVendorMethod.label.bulkInvite"),
      value: INVITE_CSV,
    },
    { label: cms("section.addVendorMethod.label.createForm"), value: CREATE_FORM },
    { label: cms("section.addVendorMethod.label.bulkCreate"), value: CREATE_CSV },
    {
      label: cms("section.addVendorMethod.label.link"),
      value: GENERIC_LINK,
    },
  ];
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetContent, setSheetContent] = useState("");
  const [sheetTitle, setSheetTitle] = useState("");

  const learnMore = (title, content) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(content);
  };

  const renderComponent = (value) => {
    switch (value) {
      case CREATE_FORM:
        return <OperatorFormCreate setBanner={setBanner} learnMore={learnMore} />;
      case GENERIC_LINK:
        return <OperatorGenericLink setBanner={setBanner} learnMore={learnMore} />;
      case INVITE_FORM:
        return <OperatorInvite setBanner={setBanner} learnMore={learnMore} />;
      case CREATE_CSV:
        return <OperatorBulkCreate setBanner={setBanner} learnMore={learnMore} />;
      case INVITE_CSV:
        return <OperatorBulkInvite setBanner={setBanner} learnMore={learnMore} />;
      default:
        return null;
    }
  };

  const onDismissBanner = () => {
    setBanner({
      action: null,
      children: null,
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const getOptions = (options) => {
    if (isOnboarding) {
      return options.map((option) => {
        const modifiedOptions = option;

        if (option.value !== CREATE_FORM) {
          modifiedOptions.disabled = true;
        }

        return modifiedOptions;
      });
    }
    return options;
  };

  const handleSelect = (updatedValue) => {
    if (isOnboarding) {
      setIsSelectChange(true);
    }
    setSelected(updatedValue);
    setBanner({ isOpen: false, status: "", title: "" });
  };

  const onBoardingBulkActivityMessage = (
    <TextContainer>
      <Banner status="critical" isOpen>
        {cms("message.error.onBoardingBulkOption")}
      </Banner>
    </TextContainer>
  );

  const getTitle = () => {
    if (isOnboarding) {
      return cms("section.onboarding.title");
    }
    return cms("section.onboarding.secondaryTitle");
  };

  const getDescription = () => {
    if (isOnboarding) {
      return cms("section.onboarding.description");
    }
    return cms("section.onboarding.description");
  };

  // const captions = cms("section.onboarding.description") || [];

  const getCaptionContent = () => {
    if (isOnboarding) {
      return <TextContainer>{cms("section.onboarding.addSection.description")}</TextContainer>;
    }
    return null;
  };

  const standardLists = cms("section.onboarding.addSection.listCaptions") || [];
  const renderCaptions = () => {
    const list = standardLists.map((content) => (
      <List.Item>
        <b>{content.title}</b>
        {` ${content.description}`}
      </List.Item>
    ));
    return list;
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            action={banner.action}
            onDismiss={onDismissBanner}
          >
            {banner.children}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={getTitle()} description={getDescription()}>
        <Card
          title={cms("section.onboarding.addSection.title")}
          // actions={isOnboarding ? [{ content: "Learn more", onAction: () => setSheetActive(!sheetActive) }] : []}
        >
          <Card.Section>
            <FormLayout>
              {getCaptionContent()}
              <TextContainer>
                Your marketplace cannot function without vendors hence there are many ways for you to add them.
              </TextContainer>
              {isOnboarding && onBoardingBulkActivityMessage}
              <Select
                id="addVendorType"
                options={getOptions(addProviderOptions)}
                placeholder={cms("section.onboarding.addSection.placeHolder")}
                onChange={handleSelect}
                value={selected}
              />
            </FormLayout>
            {isOnboarding && (
              <>
                <br />
                <PolarisBanner>
                  <p>
                    <b>{cms("section.onboarding.addSection.options")}</b>
                    {cms("section.onboarding.addSection.following")}
                  </p>
                  <br />
                  <p>
                    <List type="bullet">{renderCaptions()}</List>
                  </p>
                </PolarisBanner>
              </>
            )}
            {!isOnboarding && (
              <>
                <br />
                <PolarisBanner status="info">
                  <p>
                    <b>All options for adding vendors. </b>
                    These options will include the following:
                  </p>
                  <br />
                  <p>
                    <List type="bullet">
                      <List.Item>
                        <b>Invite vendors via email</b>
                        {` This option will allow you want invite vendors to self-register, 
                        by sending them an email.`}
                      </List.Item>
                      <List.Item>
                        <b>Create vendors</b>
                        {` This option will allow you to create a vendors, this is allow enable you to add products on
                        their behalf.`}
                      </List.Item>
                      <List.Item>
                        <b>Bulk add vendors</b>
                        {` This option will allow you to bulk invite and/or create 
                        vendors through a CSV file upload.`}
                      </List.Item>
                      <List.Item>
                        <b>Generate link</b>
                        {` This option will allow you to add a registration link on your website 
                        helping you to drive your vendors to signup on your very own branded marketplace 
                        registration page.`}
                      </List.Item>
                    </List>
                  </p>
                </PolarisBanner>
              </>
            )}
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>

      {renderComponent(selected)}
      <Sheet
        isOpen={sheetActive}
        title={sheetTitle}
        onClose={() => setSheetActive(false)}
        secondaryAction={{ content: "Close", onAction: () => setSheetActive(false) }}
        primaryAction={{ content: "Done", onAction: () => setSheetActive(false) }}
      >
        {sheetContent}
      </Sheet>
    </>
  );
};
export default withFeature(withErrorBoundary(OperatorVendorAdd), { feature: constant.ADD_VENDOR });

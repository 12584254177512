import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Stack, Button } from "@shopify/polaris";

// import from lib
import { PrivateContext } from "lib/context";
import { baseHelper } from "lib/helpers";

// GQL
import BULK_EDIT_PRODUCT from "app/products/apollo/mutations/bulkEditProduct";
import constant from "lib/constant/constant";

const {
  value: { MAX_MEASUREMENT = 999 },
} = constant;

function EditProduct(props) {
  const { formData, editBanner, addField } = props;
  const { t } = useTranslation();
  const { history, currentUser } = useContext(PrivateContext);
  const [updateProducts, { loading }] = useMutation(BULK_EDIT_PRODUCT);
  const isSeller = baseHelper.isSeller(currentUser);
  const discountKey = isSeller ? constant.SELLER_DISCOUNT : constant.VENDOR_DISCOUNT;

  const updateProduct = async () => {
    if (Object.keys(formData).length > 0) {
      let isInvalidRow = false;
      let isInvalidDimension = false;
      let isGreaterVolumetricWeight = false;
      const data = formData
        .filter((filterItem) => {
          const itemData = filterItem;
          if (isSeller) {
            const hasPriceKey = baseHelper.hasKey(itemData, "price");
            if (hasPriceKey) {
              delete itemData.price;
            }
            itemData.markUp = { price: parseFloat(filterItem.markUp.price), type: filterItem.markUp.type };
            itemData.sellerDiscount = {
              price: parseFloat(filterItem.sellerDiscount.price),
              type: filterItem.sellerDiscount.type,
            };
          } else {
            itemData.vendorDiscount = {
              price: parseFloat(filterItem.vendorDiscount.price),
              type: filterItem.vendorDiscount.type,
            };
          }
          return itemData;
        })
        .map((item) => {
          const { _id: id, productId, position } = item;
          const pItem = {
            _id: isSeller ? productId : id,
          };
          addField.forEach((hItem) => {
            let label = hItem.toLowerCase();
            if (label === constant.DISCOUNT) {
              label = discountKey;
            }
            if (label === constant.MARKUPKEY.toLowerCase()) {
              label = constant.MARKUP;
            }
            if (label === constant.COMPARE_AT_PRICE.toLowerCase()) {
              label = constant.COMPARE_PRICE;
            }
            if (item[label]) {
              pItem[label] = item[label];
            }
          });

          if (item.position) {
            pItem.position = position;
          }

          if (item.measurement) {
            const { length = {}, width = {}, height = {} } = item.measurement || {};
            const { value: lengthValue = 0 } = length || {};
            const { value: widthValue = 0 } = width || {};
            const { value: heightValue = 0 } = height || {};

            if (lengthValue > MAX_MEASUREMENT || widthValue > MAX_MEASUREMENT || heightValue > MAX_MEASUREMENT) {
              isInvalidDimension = true;
            }
            // check volumetric weight is greater than 180 or not if yes then through error
            if (baseHelper.isLargeVolumetricWeight(lengthValue, widthValue, heightValue)) {
              isGreaterVolumetricWeight = true;
            }

            pItem.measurement = item.measurement || {};
          }

          if (pItem.markUp) {
            isInvalidRow =
              isInvalidRow || !!(pItem.markUp.type === constant.PERCENTAGE && parseFloat(pItem.markUp.price) > 100);
          }

          if (pItem[discountKey]) {
            isInvalidRow =
              isInvalidRow ||
              !!(pItem[discountKey].type === constant.PERCENTAGE && parseFloat(pItem[discountKey].price) > 100);
          }

          return pItem;
        });

      if (isInvalidRow) {
        editBanner({ isOpen: true, title: t("message.bulkEditProduct.greaterThan"), status: "critical" });
        return;
      }

      if (isInvalidDimension || isGreaterVolumetricWeight) {
        const bannerData = {
          isOpen: true,
          status: constant.CRITICAL,
          title: isInvalidDimension
            ? t("message.bulkEditProduct.inValidDimension")
            : t("message.bulkEditProduct.greaterVolumetricWeight"),
        };
        editBanner(bannerData);
        return;
      }
      editBanner({ isOpen: false, title: "", status: "" });

      await updateProducts({
        variables: {
          input: {
            products: data,
          },
        },
      })
        .then((res) => {
          const responseData = baseHelper.getResponseData(res.data, constant.gql.BULK_EDIT_PRODUCT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_EDIT_PRODUCT);
          if (responseData) {
            setTimeout(() => {
              history.push("/products");
            }, 2000);
            editBanner({ isOpen: true, title: t("message.bulkEditProduct.updateSuccess"), status: "success" });
          }
          if (responseDataError) {
            editBanner({ isOpen: true, title: responseDataError, status: "critical" });
          }
        })
        .catch((err) => {
          if (err) {
            editBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
          }
        });
    }
  };
  return (
    <Stack>
      <Stack.Item fill>
        <Button onClick={() => history.push(constant.PRODUCT_URL)}>
          {t("button.bulkEditProduct.secondaryAction")}
        </Button>
      </Stack.Item>
      <Stack.Item>
        <Button primary onClick={() => updateProduct()} loading={loading}>
          {t("button.bulkEditProduct.primaryAction")}
        </Button>
      </Stack.Item>
    </Stack>
  );
}

EditProduct.defaultProps = {
  formData: [{}],
  editBanner: () => {},
  addField: [],
};
EditProduct.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.object),
  editBanner: PropTypes.func,
  addField: PropTypes.arrayOf(PropTypes.string),
};

export default EditProduct;

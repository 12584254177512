import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { PrivateContext } from "lib/context";

// import polaris packages
import {
  Avatar,
  Button,
  Collapsible,
  Card,
  Stack,
  Badge,
  Caption,
  TextStyle,
  Thumbnail,
  Link,
  EmptyState,
} from "@shopify/polaris";

// import helper components
import { baseHelper, storageHelper } from "lib/helpers";
import { SkeletonList, Banner } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import gql
import { BULK_PRODUCT } from "app/products/apollo/mutations";
import { GET_BULK_EMAIL_TEMPLATE } from "app/invitations/apollo/query";
import { UPDATE_INVITATION_EMAIL_STATUS, BULK_INVITATION_BY_DATA } from "app/invitations/apollo/mutation";
import Popover from "lib/components/popover/popover";
import {
  CancelSmallMinor,
  ImportStoreMajorMonotone,
  RefreshMinor,
  ViewMajorMonotone,
  ViewMinor,
} from "@shopify/polaris-icons";
import ProductVersioning from "../../../../products/modules/generic/feature/list/productVersioning";
import { GET_ACTIVITY } from "../../../apollo/queries";

const Activity = () => {
  const { currentUser, history } = useContext(PrivateContext);
  const { isReadOnly } = currentUser;
  const currentUserRole = storageHelper.get("userRole");
  const { displayStatus } = constant;
  const { t } = useTranslation();
  const {
    gql,
    DELETED,
    PROGRESS,
    IN_PROGRESS,
    APPROVED,
    PUBLISHED,
    userKey: { provider },
  } = constant;
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [expanded, setExpanded] = useState({});
  const [active, setActive] = useState({});
  const [resendMail, setResendMail] = useState(null);
  const [activePopover, setActivePopover] = useState({});
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    message: "",
    status: "",
  });

  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  const isSystemAdmin = baseHelper.isSystemAdmin(currentUser);
  const typeInvite = "invite";
  const typeOrder = "order";
  const typeProduct = "product";
  const typeProductCsv = "productCsv";
  const typeShopifyProduct = "productImport";
  const typeVendorCsv = "vendorCsv";

  const DELETE = DELETED.toLowerCase();
  const { data: activityData, loading: activityLoading, error: activityError } = useQuery(GET_ACTIVITY, {
    variables: {
      input: {
        isIgnoreCount: true,
      },
    },
  });

  const [
    revokeInvitation,
    { error: errorRevokeInvitation, loading: loadingRevokeInvitation, data: dataRevokeInvitation },
  ] = useMutation(UPDATE_INVITATION_EMAIL_STATUS);

  const [resendInvitation, { loading: loadingResendInvitation }] = useMutation(BULK_INVITATION_BY_DATA);

  const [bulkProductActions, { loading: loadingBulkProduct }] = useMutation(BULK_PRODUCT);

  const [
    emailTemplate,
    { error: errorEmailTemplate, loading: loadingEmailTemplate, data: dataEmailTemplate },
  ] = useLazyQuery(GET_BULK_EMAIL_TEMPLATE);

  const responseData = baseHelper.getResponseData(activityData, gql.GET_ACTIVITY);
  const { activity = [] } = responseData;

  const responseError = baseHelper.getResponseError(activityError, gql.GET_ACTIVITY);
  useEffect(() => {
    if (responseError) {
      setBanner({
        title: responseError,
        status: "critical",
        isOpen: true,
      });
    }
  }, [responseError]);

  const bulkAction = async (eventKey, deleteFromShopify = false, id = false, reason = "") => {
    let formData = {};
    formData = {
      key: eventKey,
      ids: [],
      all: true,
      deleteFromShopify,
      reason,
    };
    if (id) {
      formData.ids = [id];
      formData.all = false;
    }

    await bulkProductActions({ variables: { input: { ...formData } } })
      .then((res) => {
        if (res) {
          const response = baseHelper.getResponseData(res.data, constant.gql.BULK_PRODUCT);
          const responseDataError = baseHelper.getResponseError(res.data, constant.gql.BULK_PRODUCT);
          if (response) {
            const title = t("message.productList.requestSuccess");
            setBanner({ isOpen: true, title, status: "success" });
          }
          if (responseDataError) {
            setBanner({ isOpen: true, title: responseDataError, status: "critical" });
          }
        }
      })
      .catch((err) => {
        if (err) {
          setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
        }
      });
  };

  useEffect(() => {
    if (dataRevokeInvitation) {
      const revokeInvitationResponseData = baseHelper.getResponseData(
        dataRevokeInvitation,
        constant.gql.UPDATE_INVITATION_EMAIL_STATUS
      );
      const revokeInvitationResponseError = baseHelper.getResponseError(
        dataRevokeInvitation,
        constant.gql.UPDATE_INVITATION_EMAIL_STATUS
      );
      if (revokeInvitationResponseData) {
        setBanner({ isOpen: true, status: "success", title: t("message.providerInvitationList.requestSuccess") });
      }
      if (revokeInvitationResponseError) {
        setBanner({ isOpen: true, status: "critical", title: revokeInvitationResponseError });
      }
    }
  }, [dataRevokeInvitation, setBanner, t]);

  useEffect(() => {
    if (errorRevokeInvitation) {
      if (errorRevokeInvitation) {
        setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
      }
    }
  }, [errorRevokeInvitation, setBanner, t]);

  useEffect(() => {
    if (dataEmailTemplate) {
      const emailTemplateResponseData = baseHelper.getResponseData(
        dataEmailTemplate,
        constant.gql.GET_BULK_EMAIL_TEMPLATE
      );
      const emailTemplateResponseError = baseHelper.getResponseError(
        dataEmailTemplate,
        constant.gql.GET_BULK_EMAIL_TEMPLATE
      );
      if (emailTemplateResponseData) {
        const resendInviteData = {
          emails: [
            {
              email: resendMail,
            },
          ],
          message: {
            message: emailTemplateResponseData.message,
            subject: emailTemplateResponseData.subject,
          },
          isSingleInvite: true,
          isReadOnly,
          isResent: true,
        };
        resendInvitation({ variables: { input: { ...resendInviteData } } })
          .then((resendInviteRes) => {
            const resendInviteResponseData = baseHelper.getResponseData(
              resendInviteRes.data,
              constant.gql.BULK_INVITATION_BY_DATA
            );
            const resendInviteResponseError = baseHelper.getResponseError(
              resendInviteRes.data,
              constant.gql.BULK_INVITATION_BY_DATA
            );
            if (resendInviteResponseData) {
              setBanner({ title: t("message.providerInvitationList.requestSuccess"), status: "success", isOpen: true });
            }
            if (resendInviteResponseError) {
              setBanner({ title: resendInviteResponseError, status: "critical", isOpen: true });
            }
          })
          .catch((err) => {
            if (err) {
              setBanner({ title: t("error.common.somethingWentWrong"), status: "critical", isOpen: true });
            }
          });
      }
      if (emailTemplateResponseError) {
        setBanner({ title: emailTemplateResponseError, status: "critical", isOpen: true });
      }
    }
  }, [isReadOnly, dataEmailTemplate, emailTemplate.message, emailTemplate.subject, resendInvitation, resendMail, t]);

  useEffect(() => {
    if (errorEmailTemplate) {
      setBanner({ title: t("error.common.somethingWentWrong"), status: "critical", isOpen: true });
    }
  }, [errorEmailTemplate, t]);

  const handleCancel = async (email) => {
    const formData = { email };
    revokeInvitation({ variables: { input: formData } });
  };

  const handleResend = (mail) => {
    setResendMail(mail);
    emailTemplate();
  };

  const handleSelectChange = (value, id, email, selectedId, linkCard = "", isProduct = false) => {
    setSelectedButtonIndex(selectedId);
    if (value === constant.CANCEL) {
      handleCancel(email);
    }
    if (value === constant.RESEND) {
      handleResend(email);
    }

    if (value === constant.VIEW_ID) {
      history.push(linkCard);
    }

    if (!isProduct && value === constant.VIEW_ID) {
      history.push(linkCard);
    }

    if (value === constant.REVIEW_ALL) {
      history.push(`${constant.PRODUCT_URL_REVIEW}${id}`);
    }

    if (value === constant.PUSH_TO_STORE_ID) {
      bulkAction(constant.APPROVE, false, id, "", null, constant.NEW);
    }
  };

  if (activityLoading) {
    return <SkeletonList />;
  }

  const renderCard = () => {
    if (isSeller && responseData && activity && activity.length === 0) {
      return (
        <EmptyState
          heading="Manage your products and vendors"
          action={{ content: "Add Products", onAction: () => history.push("/products/add") }}
          secondaryAction={{ content: "Add Vendors", onAction: () => history.push("/providers/add") }}
          image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        >
          <p>Not sure what to do next?</p>
        </EmptyState>
      );
    }

    if ((isVendor || isSystemAdmin) && responseData && activity && activity.length === 0) {
      return (
        <EmptyState
          heading="Manage your products"
          action={{ content: "Add Products", onAction: () => history.push("/products/add") }}
          image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        >
          <p>Not sure what to do next?</p>
        </EmptyState>
      );
    }

    if (responseData) {
      return activity.map((data) => {
        const {
          _id,
          label,
          status,
          createdAt,
          by,
          title,
          type,
          activityId,
          collectionName,
          image = "",
          vendor = "",
          vendorId = "",
        } = data;

        const isOrder = type === typeOrder;
        const isProduct = [typeProduct, typeShopifyProduct].includes(type);
        const isInvite = [typeInvite].includes(type);
        const brandName = isSeller || isVendor ? vendor : by;
        const isProductCsv = type === typeProductCsv;
        const isVendorCsv = type === typeVendorCsv;

        const activityLogId = activityId;
        const thumbnailUrl = image || "https://via.placeholder.com/80x80/ffffff/000000?text=No+image";

        const badge =
          status &&
          status.map((item) => {
            let badgeItem = item.toLowerCase();
            if (badgeItem === APPROVED.toLowerCase()) {
              badgeItem = PUBLISHED;
            }
            const filedStatus = baseHelper.getBadgeStatus(badgeItem);
            const badgeStatus = filedStatus.toLowerCase();
            if (badgeItem === IN_PROGRESS) {
              badgeItem = PROGRESS;
            }
            const badgeType = baseHelper.getBadgeType(badgeItem);
            return {
              badgeType,
              badgeStatus,
            };
          });
        const { badgeType: firstBadgeType, badgeStatus: firstBadgeStatus } = (badge.length && badge[0]) || {};
        const showLink = !(status && status[0] && status[0].toLowerCase() === DELETE);
        const productList = showLink && status && status[0] && status[0].toLowerCase() === "new";

        let linkCard = isOrder ? `/orders/view/${activityLogId}` : `/products/edit/${activityLogId}`;
        if (collectionName === "errorLogs") {
          linkCard = `/activity/view/${activityId}`;
        }

        if (isSeller && collectionName === "sellerProducts" && productList) {
          linkCard = "/products";
        }

        if (isSeller && isInvite) {
          linkCard = "/invitation";
        }

        const inviteOptions = [
          {
            content: "Resend",
            value: "Resend",
            icon: RefreshMinor,
            onAction: () => handleSelectChange("Resend", activityLogId, title, _id, linkCard, isProduct),
          },
          {
            content: "Cancel",
            value: "Cancel",
            icon: CancelSmallMinor,
            onAction: () => handleSelectChange("Cancel", activityLogId, title, _id, linkCard, isProduct),
          },
        ];

        const viewOption = [
          {
            content: constant.VIEW_LABEL,
            value: constant.VIEW_ID,
            disabled: false,
            icon: ViewMajorMonotone,
            onAction: () => handleSelectChange(constant.VIEW_ID, activityLogId, title, _id, linkCard, isProduct),
          },
        ];

        let options;

        if (isInvite) {
          options = inviteOptions;
        }

        if (isProduct) {
          const pushToStore = {
            content: constant.PUSH_TO_STORE_LABEL,
            value: constant.PUSH_TO_STORE_ID,
            disabled: false,
            icon: ImportStoreMajorMonotone,
            onAction: () =>
              handleSelectChange(constant.PUSH_TO_STORE_ID, activityLogId, title, _id, linkCard, isProduct),
          };
          const review = {
            content: displayStatus.NEEDSREVIEW,
            value: constant.REVIEW_ALL,
            disabled: false,
            icon: ViewMinor,
            onAction: () => handleSelectChange(constant.REVIEW_ALL, activityLogId, title, _id, linkCard, isProduct),
          };
          const pushToStoreOption =
            status && status[0] && status[0].toLowerCase() === constant.NEW
              ? [pushToStore]
              : [{ ...pushToStore, disabled: true }];
          const reviewOption =
            status && status[0] && status[0] === constant.NEEDS_REVIEW ? [review] : [{ ...review, disabled: true }];

          if (isSeller) {
            options = [...viewOption];

            if (status && status[0] && status[0].toLowerCase() === constant.NEW) {
              options.unshift(...pushToStoreOption);
            }
            if (!(status && status[0] && status[0].toLowerCase() === constant.NEW)) {
              options.push(...pushToStoreOption);
            }

            if (status && status[0] && status[0] === constant.NEEDS_REVIEW) {
              options.unshift(...reviewOption);
            }
            if (!(status && status[0] && status[0] === constant.NEEDS_REVIEW)) {
              options.push(...reviewOption);
            }
          }

          if (!isSeller) {
            options = [...viewOption];
          }
        }

        if (isOrder || isProductCsv || isVendorCsv) {
          options = viewOption;
        }

        if (isReadOnly && currentUserRole === provider) {
          options.forEach((option) => {
            if (option.content === constant.PUSH_TO_STORE_LABEL) {
              // eslint-disable-next-line no-param-reassign
              option.disabled = true;
            }
          });
        }

        const topMargin10 = {
          marginTop: "10px",
        };

        const id = _id;

        return (
          <Card key={_id}>
            <div className="dashboard">
              <Stack>
                <Stack.Item>
                  {(isInvite && <Avatar customer size="medium" name="" />) || (
                    <Thumbnail size="large" source={thumbnailUrl} />
                  )}
                </Stack.Item>
                <Stack.Item fill Style="width: 200px">
                  <div className="ellipsis">
                    <TextStyle>
                      {!showLink ? (
                        `${title}`
                      ) : (
                        <Link url={linkCard}>
                          <TextStyle>{title}</TextStyle>
                        </Link>
                      )}
                    </TextStyle>
                    <Caption>
                      <TextStyle variation="subdued">
                        {constant.UPDATED}
                        {":"}
                        {t("common.On")}
                        {` ${baseHelper.formatDate(createdAt)}`}
                      </TextStyle>
                      <br />
                      {!isInvite && `${constant.VENDOR_LABEL}: `}
                      {(isVendor && <Link url="/profile">{brandName}</Link>) || (
                        <Link url={`/providers/view/${vendorId}`}>{brandName}</Link>
                      )}
                    </Caption>
                    <div style={topMargin10}>
                      <Stack>
                        <Stack.Item>
                          <Badge status="info">{baseHelper.ucFirst(label)}</Badge>
                        </Stack.Item>
                        <Stack.Item>
                          <Badge status={firstBadgeType}>{baseHelper.ucFirst(firstBadgeStatus)}</Badge>
                        </Stack.Item>
                        {isProduct ? (
                          <Stack.Item>
                            <div className="timelineLink ">
                              <Button
                                plain
                                monochrome
                                disclosure={expanded[id] ? "up" : "down"}
                                onClick={() => {
                                  setActive((prev) => {
                                    return {
                                      ...prev,
                                      [id]: !active[id],
                                    };
                                  });
                                  setExpanded((prev) => {
                                    return {
                                      ...prev,
                                      [id]: !expanded[id],
                                    };
                                  });
                                }}
                              >
                                History
                              </Button>
                            </div>
                          </Stack.Item>
                        ) : null}
                      </Stack>
                    </div>
                  </div>
                </Stack.Item>
                <Stack.Item>
                  <Popover
                    active={activePopover[id]}
                    setActive={() => setActivePopover({ [id]: !activePopover[id] })}
                    loading={
                      _id === selectedButtonIndex &&
                      (loadingRevokeInvitation || loadingResendInvitation || loadingEmailTemplate || loadingBulkProduct)
                    }
                    options={options}
                    disabled={isInvite && status && status[0].toLowerCase() === constant.JOINED.toLowerCase()}
                  />
                </Stack.Item>
              </Stack>
              <Collapsible open={active[id]} id="timeline" transition={{ duration: "150ms", timingFunction: "ease" }}>
                <div className="activity">
                  <ProductVersioning productId={activityId} setListBanner={setBanner} />
                </div>
              </Collapsible>
            </div>
          </Card>
        );
      });
    }
    return null;
  };

  return (
    <>
      {banner.isOpen && (
        <>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
          <br />
        </>
      )}
      {renderCard()}
    </>
  );
};

export default Activity;

import React, { useContext } from "react";
import { Card } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import components
import ImageComponent from "lib/components/dropZone/dropZone";

// import context
import { FormContext } from "../../../context/context";

const Image = () => {
  const { handleChange, data } = useContext(FormContext);
  const addImage = (value) => {
    const productImages = data.image || [];
    productImages.push(value);
    handleChange(constant.IMAGE, productImages);
  };
  const removeImage = (index) => {
    const productImages = data.image || [];
    if (index < 0 || productImages.length <= 0) {
      return;
    }
    productImages.splice(index, 1);
    handleChange(constant.IMAGE, productImages);
  };

  return (
    <Card title="Images" id="productImage" sectioned>
      <ImageComponent onAdd={addImage} onRemove={removeImage} size={10} allowMultiple fileList={data.image || []} />
    </Card>
  );
};

export default Image;

// import packages
import React, { useState, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Caption, Card, Layout, RadioButton, Stack, TextContainer, TextStyle } from "@shopify/polaris";
// import context
import { PrivateContext } from "lib/context";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import components
import { Banner, Sheet, SkeletonAnnotated } from "lib/components/index";
import Price from "./subFeature/price";
// import helpers
import { baseHelper } from "lib/helpers/index";

// import cms and constants
import constant from "lib/constant/constant";
import { useTranslation } from "react-i18next";
import GET_PRODUCT_SETTING from "app/setup/apollo/queries/getProductSetting";
import fieldData from "./cms/fieldData";

// import gql
import { UPDATE_SETTING } from "../../../../apollo/mutations/index";

const { APPROVE_SELECTED, REVIEW_ALL, APPROVE_ALL, gql } = constant;

const OperatorProductSetting = () => {
  const { t } = useTranslation();
  const [updateProductSetting, { loading: productSettingLoading }] = useMutation(UPDATE_SETTING);
  const { history, cms } = useContext(PrivateContext);
  const { data: productSettingsData, loading } = useQuery(GET_PRODUCT_SETTING);
  const [radioButtonValue, setRadioButtonValue] = useState(false);
  const [buttonValue, setButtonValue] = useState(true);
  const [lastUpdated, SetLastUpdated] = useState("");
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  const productSettingData = fieldData(cms);
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");

  const [selected, setSelected] = useState("");
  const [rate, setRate] = useState("");

  const handleChange = (checked, newValue) => {
    setRadioButtonValue(newValue);
    setButtonValue(false);
  };

  const onSubmit = () => {
    setButtonValue(true);
    updateProductSetting({
      variables: {
        input: {
          reviewAll: radioButtonValue === REVIEW_ALL,
          approveSelected: radioButtonValue === APPROVE_SELECTED,
          approveAll: radioButtonValue === APPROVE_ALL,
          isPriceTaxInclusive: selected === "true",
          rate: parseFloat(rate),
        },
      },
    })
      .then((res) => {
        const responseData = baseHelper.getResponseData(res.data, constant.gql.UPDATE_PRODUCT_SETTING);
        let bannerValue = { status: "success", title: cms("operator.message.success") };
        if (!responseData) {
          const errorResponse = baseHelper.getResponseError(res.data, constant.gql.UPDATE_PRODUCT_SETTING);
          bannerValue = { status: "critical", title: errorResponse };
        }
        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: true,
        });
      })
      .catch(() => {
        setBannerStatus({
          status: "critical",
          title: cms("common.message.error.somethingWentWrong"),
          isOpen: true,
        });
      });
  };

  const renderBanner = () => {
    const { status, title, isOpen } = bannerStatus;
    return (
      <Banner
        isOpen={isOpen}
        status={status}
        title={title}
        onDismiss={() => setBannerStatus({ isOpen: false, status: "", title: "" })}
      />
    );
  };
  useEffect(() => {
    const responseData = baseHelper.getResponseData(productSettingsData, gql.GET_PRODUCT_SETTING);
    if (!responseData) {
      return;
    }
    const { productApproval = "", updatedAt = "", isPriceTaxInclusive = false, rate } = responseData;
    setRadioButtonValue(productApproval);
    SetLastUpdated(updatedAt);
    if (!isPriceTaxInclusive) {
      setSelected("false");
      return;
    }
    setSelected("true");
    setRate(rate || 0);
  }, [productSettingsData, gql.GET_PRODUCT_SETTING]);

  const primaryAction = {
    content: cms("common.label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };

  const learnMore = (title) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(`TODO: Add description about ${title}`);
  };
  if (loading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      {bannerStatus.isOpen && <Layout.Section>{renderBanner()}</Layout.Section>}
      <Layout.AnnotatedSection title={cms("operator.title")} description={cms("operator.description")}>
        <Card
          title={[
            cms("operator.title"),
            <TextStyle variation="subdued">
              {lastUpdated && <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>}
            </TextStyle>,
          ]}
          actions={[
            {
              content: cms("common.label.learnMore"),
              onAction: () => learnMore(cms("operator.title")),
            },
          ]}
          sectioned
          primaryFooterAction={{
            id: "submitProductSetting",
            content: t("label.Update"),
            disabled: productSettingLoading || buttonValue,
            loading: productSettingLoading,
            onAction: () => onSubmit(),
          }}
          secondaryFooterActions={[
            {
              id: "cancelProductSetting",
              content: cms("common.button.cancel"),
              onAction: () => history.push("/"),
            },
          ]}
        >
          <Stack vertical>
            <TextContainer>{cms("operator.description")}</TextContainer>
            {productSettingData.map((item) => {
              const { label: labelItem, helpText, key, name } = item;
              return (
                <RadioButton
                  id={key}
                  key={key}
                  label={labelItem}
                  helpText={helpText}
                  name={name}
                  checked={radioButtonValue === key}
                  onChange={handleChange}
                />
              );
            })}
          </Stack>
        </Card>
        <Sheet
          title={sheetTitle}
          isOpen={sheetActive}
          onClose={() => setSheetActive(false)}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
        >
          {sheetContent}
        </Sheet>
      </Layout.AnnotatedSection>
      <br />
      <Price
        lastUpdated={lastUpdated}
        setSelected={setSelected}
        setRate={setRate}
        selected={selected}
        rate={rate}
        cms={cms}
        learnMore={learnMore}
        radioButtonValue={radioButtonValue}
        setBannerStatus={setBannerStatus}
      />
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorProductSetting), { feature: constant.PRODUCT_SETTING });

import React, { useContext } from "react";
import { AppProvider, Frame, Page, TopBar, DisplayText } from "@shopify/polaris";
import PropTypes from "prop-types";

import { PublicContext, PublicBrandingContext } from "lib/context";

// import helper
import { storageHelper, baseHelper, imageHelper } from "lib/helpers";

import logo from "asset/images/logo_and_text.png";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import SEO from "lib/components/seo/seo";

const PublicLayout = (props) => {
  const { pageData = {}, isLoading, history } = useContext(PublicContext);
  const { brandingLoading } = useContext(PublicBrandingContext);
  const { branding = {} } = useContext(PublicBrandingContext);
  const { breadcrumbs = [] } = pageData;
  const { children = null } = props;
  const vendorLogo = (branding && branding.logoUrl) || storageHelper.get("logoUrl");
  const headerFont = (branding && branding.headerFont) || storageHelper.get("headerFont");
  const headerColor = (branding && branding.headerColor) || storageHelper.get("headerColor");
  const initialBranding = {
    logoUrl: "",
    headerFont: "",
    headerColor: "",
    brandName: "",
  };
  const theme = {
    colors: {
      topBar: {
        background: headerColor && headerColor !== "" ? headerColor : "#36454f",
        color: headerFont && headerFont !== "" ? headerFont : "",
      },
    },
  };

  const { location = {} } = history;
  const { pathname = "/login" } = location;
  const isLoginPage = pathname === "/login";

  const getComponent = () => {
    if (isLoading || brandingLoading) {
      return <SkeletonAnnotated annotatedLines={5} primaryLines={5} />;
    }
    return children;
  };

  const getMenuLogo = (brandNameText) => {
    const titleStyle = {
      color: headerFont || "#ffffff",
    };
    const url = (vendorLogo && vendorLogo.trim() !== "" && vendorLogo) || (!brandNameText && logo);
    if (url) {
      return (
        <img
          className="brandLogo"
          src={imageHelper.resize({ url, type: constant.imageTypes.HEADER_LOGO })}
          alt="Brand Logo"
        />
      );
    }
    return (
      <div className="brandTitle" style={titleStyle}>
        <DisplayText>{baseHelper.ucFirst(brandNameText)}</DisplayText>
      </div>
    );
  };

  const linkStyle = {
    textDecorationLine: "none",
    cursor: "pointer",
  };

  const menuText = (name) => (
    <a style={linkStyle} href={(!isLoginPage && "/login") || false}>
      {getMenuLogo(name)}
    </a>
  );
  if (baseHelper.isIframe()) {
    Object.keys(initialBranding).forEach((key) => {
      storageHelper.set(key, initialBranding[key]);
    });
  }
  const brandName = storageHelper.get("brandName") || "Marketcube.io";
  return (
    <AppProvider theme={theme}>
      <Frame topBar={<TopBar contextControl={menuText(brandName)} />}>
        <SEO title={baseHelper.ucFirst(storageHelper.get("brandName"))} favicon={storageHelper.get("logo")} />
        <Page breadcrumbs={breadcrumbs}>{getComponent()}</Page>
      </Frame>
    </AppProvider>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PublicLayout;

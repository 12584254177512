import React from "react";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import generic component
import { ContactUs } from "../../../generic";

const OperatorContactUs = () => {
  return <ContactUs />;
};

export default withFeature(OperatorContactUs, { feature: constant.SUPPORT });

import React from "react";
import { Card, FormLayout, TextField } from "@shopify/polaris";
import PropTypes from "prop-types";

const Pricing = (props) => {
  const { data, onChange } = props;
  const { price, comparePrice } = data;

  const handlePricing = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Card sectioned title="Pricing">
      <FormLayout>
        <TextField
          id="price"
          label="Price"
          value={price}
          onChange={(value) => handlePricing("price", value)}
          placeholder="0"
        />
        <TextField
          id="comparePrice"
          label="Compare at price"
          value={comparePrice}
          onChange={(value) => handlePricing("comparePrice", value)}
          placeholder="0"
        />
      </FormLayout>
    </Card>
  );
};

Pricing.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pricing;

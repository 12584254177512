// import packages
import React, { useState, useContext, useEffect } from "react";
import { Layout } from "@shopify/polaris";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import helpers
import { baseHelper } from "lib/helpers";

// import gql
import { GET_PLANS, GET_CURRENT_PLAN, GET_USER_DATA } from "app/plans/apollo/queries/updatePlan";
import { UPDATE_PLAN } from "app/plans/apollo/mutations/updatePlan";

// import context
import { PrivateContext } from "lib/context";

// import hoc
import { withErrorBoundary, withFeature } from "lib/hoc";

// import components
import { Banner, Spinner } from "lib/components";

// import cms and constants
import constant from "lib/constant/constant";

import Plan from "./plan";

const { gql, FREE, CRITICAL, SORT_ORDER, SUCCESS } = constant;
const isIframe = baseHelper.isIframe();

const OperatorUpdatePlan = () => {
  const { t } = useTranslation();
  const { currentUser, location } = useContext(PrivateContext);
  const queryParams = baseHelper.getQueryParams(location.search);
  const isPlanUpdated = queryParams && queryParams.plan;
  const isSeller = baseHelper.isSeller(currentUser);

  // states
  const [selectedPlan, setSelectedPlan] = useState("");
  const [chargeIdDetails, setChargeIdDetails] = useState("");
  const [planData, setPlanData] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  // gql
  const { loading: getUserLoading, data: getUserData } = useQuery(GET_USER_DATA);

  const { loading: fetchPlanLoading, data: fetchPlanData, error: fetchPlanError } = useQuery(GET_PLANS);
  const {
    loading: fetchCurrentPlanLoading,
    data: fetchCurrentPlanData,
    error: fetchCurrentPlanError,
    refetch,
  } = useQuery(GET_CURRENT_PLAN);
  const [updatePlan] = useMutation(UPDATE_PLAN);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(fetchPlanData, gql.GET_PLANS);
    if (!responseData) {
      const responseError = baseHelper.getResponseError(responseData.data, gql.GET_PLANS);
      setBanner({ status: CRITICAL, title: responseError });
      return;
    }
    setPlanData(responseData);
  }, [fetchPlanData, fetchPlanError]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(getUserData, gql.GET_USER);
    if (!responseData) {
      const responseError = baseHelper.getResponseError(getUserData, gql.GET_USER);
      setBanner({ status: CRITICAL, title: responseError });
      return;
    }
    setChargeIdDetails(responseData.chargeId || "");
  }, [getUserData]);

  useEffect(() => {
    const getCurrentPlanData = baseHelper.getResponseData(fetchCurrentPlanData, gql.GET_CURRENT_PLAN);
    if (!getCurrentPlanData) {
      const responseError = baseHelper.getResponseError(fetchCurrentPlanError, gql.GET_CURRENT_PLAN);
      setBanner({ status: CRITICAL, title: responseError });
    }
    setCurrentPlan(getCurrentPlanData);
  }, [fetchCurrentPlanData, fetchCurrentPlanError]);

  useEffect(() => {
    if (isSeller && isPlanUpdated) {
      setBanner({ status: SUCCESS, title: "Plan is updated successfully.", isOpen: true });
    }
  }, [isPlanUpdated, isSeller, t]);

  if (fetchPlanLoading || fetchCurrentPlanLoading || getUserLoading) {
    return <Spinner />;
  }

  const updateCurrentPlan = () => {
    refetch(fetchCurrentPlanData);
    const getCurrentPlanData = baseHelper.getResponseData(fetchCurrentPlanData, gql.GET_CURRENT_PLAN);

    setCurrentPlan(getCurrentPlanData);
  };
  const submitPlan = async (plan) => {
    setSelectedPlan(plan.code);
    const { monthlyFee: price, name, code, _id: planId } = plan;
    const requestData = {
      code,
      name,
      price,
      planId,
    };
    try {
      const res = await updatePlan({
        variables: {
          input: requestData,
        },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_PLAN);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_PLAN);
        setBanner({
          isOpen: true,
          status: CRITICAL,
          title: errorResponse,
        });
      }
      if (plan.code === FREE && responseData) {
        updateCurrentPlan();
        return;
      }
      if (isIframe) {
        window.top.location.href = responseData.confirmation_url;
      } else {
        window.location.href = responseData.confirmation_url || "";
      }
    } catch (err) {
      setBanner({
        isOpen: true,
        status: CRITICAL,
        title: `${t("error.common.somethingWentWrong")}`,
      });
    }
  };
  const getUpdatedPlans = (plans) => {
    const { products, vendors } = currentUser;
    return plans.map((plan) => {
      const updatedPlan = { ...plan };
      updatedPlan.disabled = false;
      updatedPlan.currentPlan = false;
      const isPlanLimitExceeded =
        products &&
        vendors &&
        plan.monthlyFee <= currentPlan.monthlyFee &&
        (plan.maxProducts < products.used || plan.maxVendors < vendors.associated);
      if (isPlanLimitExceeded) {
        updatedPlan.disabled = true;
        const productCount = products.used - updatedPlan.maxProducts;
        const vendorCount = vendors.associated - updatedPlan.maxVendors;
        updatedPlan.message =
          productCount > 0
            ? `${t("message.unable")} ${productCount} ${t("message.moreProducts")}`
            : `${t("message.unable")} ${vendorCount} ${t("message.moreVendors")}`;
      }
      if (updatedPlan.monthlyFee === currentPlan.monthlyFee) {
        updatedPlan.currentPlan = true;
        updatedPlan.disabled = true;
      }
      return updatedPlan;
    });
  };

  const getSortedPlans = (plans = [], key) => {
    return plans.sort((first, second) => first[key] - second[key]);
  };
  const getPlans = () => {
    const sortedPlans = getSortedPlans(planData, SORT_ORDER);
    const updatedPlans = getUpdatedPlans(sortedPlans);
    return updatedPlans;
  };

  if (fetchPlanLoading) {
    return <Spinner />;
  }

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => {
              setBanner({
                isOpen: false,
                title: "",
                status: "",
              });
            }}
          />
          <br />
        </Layout.Section>
      )}
      <Layout>
        <Plan plans={getPlans()} onSelect={(plan) => submitPlan(plan)} selectedPlan={selectedPlan} />
      </Layout>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorUpdatePlan), { feature: constant.UPDATE_PLAN });

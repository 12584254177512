import React from "react";

import constant from "lib/constant/constant";

import { AdminSellerProfile } from "./admin";

import { storageHelper } from "../../../lib/helpers";

const { userKey } = constant;
const { admin } = userKey;

const SellerProfileView = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminSellerProfile />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const SellerProfileViewComponent = getComponent(currentUserRole);
  return SellerProfileViewComponent;
};

export default SellerProfileView;

import React, { useContext } from "react";
import { FooterHelp, Link } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";

import { PrivateContext } from "lib/context";

import { storageHelper } from "lib/helpers";

const Footer = (props) => {
  const { footerText } = props;
  const { history } = useContext(PrivateContext);
  const { t } = useTranslation();

  const getFooterHTML = (text) => {
    const updatedText = text.replace(/{{here}}/i, '<a href="/contact-us">here</a>');
    return `<div>${updatedText}</div>`;
  };

  if (storageHelper.get("setupProgress")) {
    return null;
  }

  return (
    <FooterHelp>
      Checkout our <Link external url="https://docs.google.com/document/d/1VCSOnMTPppoQQriBVb5ttPoRsBtQZVW6iBYO3QoeERs/edit">illustration doc</Link> for commonly used GQL queries and mutations.
    </FooterHelp>
  );
};

Footer.propTypes = {
  footerText: PropTypes.string,
};

Footer.defaultProps = {
  footerText: "",
};

export default Footer;

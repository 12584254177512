import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { AppProvider, Badge, Frame, Layout, Page, TopBar, DisplayText } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { useTranslation } from "react-i18next";

import { PrivateContext, PrivateBrandingContext } from "lib/context";
import { Spinner, SkeletonPage } from "lib/components";
import logo from "asset/images/logo_and_text.png";
import { baseHelper, storageHelper, accessHelper, imageHelper, chatHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// NOTE: Upcoming onboarding component
import { StepperOnboarding } from "app/onboarding/modules";
import SEO from "lib/components/seo/seo";
import PopupModal from "./components/popupModal/popupModal";

import { Tabs, Footer, Header, NotAllowed, NotFound } from "./components";

const PrivateLayout = (props) => {
  const { t } = useTranslation();
  const {
    isLoading,
    currentUser,
    pageData = {},
    history,
    match = {},
    isNotAllowed = false,
    isNotFound = false,
    cms,
    isFullWidth = false,
  } = useContext(PrivateContext);
  const { branding } = useContext(PrivateBrandingContext);
  const {
    headerColor: brandingHeaderColor = "",
    headerFont: brandingHeaderFontColor = "",
    footerText: brandingFooterText,
    logoUrl: brandingLogo = "",
    brandName: sellerBrandName,
    isHideMc = false,
    isDefaultLogo = false,
  } = branding || {};
  const { title = "", breadcrumbs = [], pageClassName = "", subtitle = "" } = pageData;
  const [logoutLoading, setLogoutLoading] = useState(false);
  const { children = null, setIsOnboarding, onboardingTab } = props;
  const { userKey, VENDOR } = constant;
  const [headerColor, setHeaderColor] = useState(storageHelper.get("headerColor"));
  const [headerFontColor, setHeaderFontColor] = useState(storageHelper.get("headerFontColor"));
  const [hideMcDetails, setHideMcDetails] = useState(storageHelper.get("hideMcDetails"));
  const [logoUrl, setLogoUrl] = useState(storageHelper.get("logoUrl"));
  const [footerText, setFooterText] = useState(storageHelper.get("footerText"));

  const isOnboardingCompleted =
    (currentUser && currentUser.setupProgress === null) || typeof storageHelper.get("setupProgress") === "undefined";
  const onboardingStep = currentUser && currentUser.setupProgress;

  const isVendor = baseHelper.isVendor(currentUser);
  const isSeller = baseHelper.isSeller(currentUser);

  // When Vendor has readonly permission
  const { name } = currentUser && currentUser.roles;
  const onboardingNotRequired =
    (currentUser && name === VENDOR && currentUser.isReadOnly) || typeof currentUser.isReadOnly === "undefined";

  if (hideMcDetails) {
    chatHelper.hide();
  }

  const theme = {
    colors: {
      topBar: {
        background: headerColor && headerColor !== "" ? headerColor : "#36454f",
        color: headerFontColor && headerFontColor !== "" ? headerFontColor : "",
      },
    },
  };

  const { path = "/" } = match;

  useEffect(() => {
    const currentStep = onboardingTab || (currentUser && currentUser.setupProgress) || "welcome";

    const sellerTabCMS = {
      welcome: null,
      about: null,
      businessInfo: null,
      commission: { cms: "commission", module: "setting" },
      terms: { cms: "terms", module: "setting" },
      vendor: { cms: "addVendor", module: "provider" },
    };
    const vendorTabCMS = {
      welcome: null,
      updateProfile: { cms: "editProfile", module: "setting" },
      product: { cms: "addProduct", module: "product" },
    };
    if (!isOnboardingCompleted) {
      if (isSeller) {
        setIsOnboarding(sellerTabCMS[currentStep]);
      }
      if (isVendor) {
        setIsOnboarding(vendorTabCMS[currentStep]);
      }
    }
  }, [isOnboardingCompleted, currentUser, isSeller, setIsOnboarding, isVendor, onboardingTab]);

  useEffect(() => {
    /*
      brandingFooterText should have some value &&
      brandingFooterText should not be equal to footerText
    */
    if (brandingFooterText && footerText !== brandingFooterText) {
      storageHelper.set("footerText", brandingFooterText);
      setFooterText(brandingFooterText);
    }

    /*
      brandingHeaderColor should have some value &&
      brandingHeaderColor should not be equal to headerColor
    */
    if (brandingHeaderColor && brandingHeaderColor.trim() !== "" && headerColor !== brandingHeaderColor) {
      storageHelper.set("headerColor", brandingHeaderColor);
      setHeaderColor(brandingHeaderColor);
    }

    /*
      brandingHeaderFontColor should have some value &&
      brandingHeaderFontColor should not be equal to headerFontColor
    */
    if (
      brandingHeaderFontColor &&
      brandingHeaderFontColor.trim() !== "" &&
      headerFontColor !== brandingHeaderFontColor
    ) {
      storageHelper.set("headerFontColor", brandingHeaderFontColor);
      setHeaderFontColor(brandingHeaderFontColor);
    }

    /*
      (brandingLogo should have some value &&
      brandingLogo should not be equal to logoUrl)
      OR isDefaultLogo is true and brandingLogo should not be equal to logoUrl
    */
    if ((isDefaultLogo || (brandingLogo && brandingLogo.trim() !== "")) && brandingLogo !== logoUrl) {
      storageHelper.set("logoUrl", brandingLogo);
      setLogoUrl(brandingLogo);
    }

    // show/hide support chat according to branding values
    if (isHideMc) {
      setHideMcDetails(true);
    }
  }, [
    brandingHeaderColor,
    brandingHeaderFontColor,
    brandingLogo,
    logoUrl,
    headerColor,
    headerFontColor,
    brandingFooterText,
    footerText,
    isDefaultLogo,
    isOnboardingCompleted,
    setIsOnboarding,
    onboardingTab,
    onboardingStep,
    isVendor,
    isSeller,
    isHideMc,
  ]);

  const getPageTitle = () => {
    if (isLoading) {
      return null;
    }
    const isAdmin = currentUser.role === userKey.systemAdminRole;
    if (isAdmin) {
      return `${cms("title")}, System Admin`;
    }
    const fullName = baseHelper.getFullName(currentUser);
    return fullName ? `${cms("common.label.goodDay")}, ${fullName}` : `${cms("title")}`;
  };

  const getSubTitle = (pageSubTitle = "") => {
    const subTitle = pageSubTitle;
    return subTitle;
  };

  const getPrimaryAction = (primaryAction) => {
    if (!primaryAction) {
      return null;
    }
    const updatedPrimaryAction = { ...primaryAction };
    if (!updatedPrimaryAction.onAction) {
      updatedPrimaryAction.onAction = () => history.push(primaryAction.url);
      delete updatedPrimaryAction.url;
    }
    return updatedPrimaryAction;
  };

  const getUserRole = () => {
    if (isLoading) {
      return null;
    }
    return [
      <Badge status="success">{baseHelper.ucFirst(accessHelper.getUserRole(currentUser))}</Badge>,
      isSeller && currentUser.plan && currentUser.plan.name && (
        <Badge status="success">{baseHelper.ucFirst(currentUser.plan.name)}</Badge>
      ),
      currentUser.isReadOnly && <Badge status="warning">{baseHelper.ucFirst(constant.READ_ONLY)}</Badge>,
    ];
  };

  // TODO: move to styles
  const topMargin25px = {
    marginTop: "25px",
  };

  if (logoutLoading) {
    return <Spinner isFullPage />;
  }

  const getChildren = () => {
    const { admin } = userKey;
    const isSystemAdmin = storageHelper.get("userRole") === admin;
    if (isSystemAdmin || isOnboardingCompleted || onboardingNotRequired) {
      return (
        <>
          {/* <Tabs /> */}
          <div style={topMargin25px} className={`${pageClassName}_${storageHelper.get("userRole")}`}>
            {isFullWidth ? children : <Layout>{children}</Layout>}
          </div>
        </>
      );
    }
    return <StepperOnboarding />;
  };

  const getComponent = () => {
    if (isLoading && storageHelper.get("isInitial")) {
      return <SkeletonPage />;
    }

    // redirect to "/" until onboarding is completed
    const isRootRoute = path === "/";
    const { admin } = userKey;
    const isSystemAdmin = storageHelper.get("userRole") === admin;
    if (!isSystemAdmin && !(isOnboardingCompleted || isRootRoute || onboardingNotRequired)) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    if (isNotAllowed) {
      return <NotAllowed />;
    }
    if (isNotFound) {
      return <NotFound />;
    }

    return (
      <>
        <SEO title={sellerBrandName} favicon={logoUrl} />
        <Page
          breadcrumbs={breadcrumbs}
          title={title ? t(`pageTitle.${title}`, title) : getPageTitle()}
          titleMetadata={getUserRole()}
          primaryAction={getPrimaryAction(pageData.primaryAction)}
          subtitle={getSubTitle(subtitle)}
          fullWidth={isFullWidth}
        >
          {getChildren()}
          <Footer footerText={footerText || ""} />
        </Page>
      </>
    );
  };

  const getMenuLogo = (brandName) => {
    const titleStyle = {
      color: headerFontColor || "#ffffff",
    };

    const url = (logoUrl && logoUrl.trim() !== "" && logoUrl) || (!brandName && logo);
    if (url) {
      return (
        <img
          className="brandLogo"
          src={imageHelper.resize({ url, type: constant.imageTypes.HEADER_LOGO })}
          alt="Brand Logo"
        />
      );
    }
    return (
      <div className="brandTitle" style={titleStyle}>
        <DisplayText>{baseHelper.ucFirst(brandName)}</DisplayText>
      </div>
    );
  };

  const linkStyle = {
    textDecorationLine: "none",
    cursor: "pointer",
  };

  const menuText = (brandName) => (
    <a style={linkStyle} href="/">
      {getMenuLogo(brandName)}
    </a>
  );

  const getPage = () => {
    const Component = getComponent();
    if (baseHelper.isIframe()) {
      return Component;
    }
    const userBrandName = sellerBrandName || "";
    return (
      <Frame
        topBar={
          <TopBar userMenu={<Header setLogoutLoading={setLogoutLoading} />} contextControl={menuText(userBrandName)} />
        }
      >
        {Component}
      </Frame>
    );
  };

  return (
    <AppProvider i18n={enTranslations} theme={theme}>
      {!baseHelper.isIframe() && <PopupModal />}
      {getPage()}
    </AppProvider>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.node,
  setIsOnboarding: PropTypes.func,
  onboardingTab: PropTypes.string,
};

PrivateLayout.defaultProps = {
  children: null,
  setIsOnboarding: () => {},
  onboardingTab: null,
};

export default PrivateLayout;

import { gql } from "apollo-boost";

const SYNC_INVENTORY_VENDOR = gql`
  mutation syncInventoryVendor {
    syncInventoryVendor {
      status
      data
      error
    }
  }
`;

const DISCONNECT_WOOCOMMERCE_SHOP = gql`
  mutation disconnectWooCommerceShop($input: DisconnectShop) {
    disconnectWooCommerceShop(input: $input) {
      status
      data
      error
    }
  }
`;

const IS_WOOCOMMERCE_STORE_CONNECTED = gql`
  mutation isWooCommerceConnected($input: IsWooCommerceConnected!) {
    isWooCommerceConnected(input: $input) {
      status
      data {
        webhooks
      }
      error
    }
  }
`;

export { SYNC_INVENTORY_VENDOR, DISCONNECT_WOOCOMMERCE_SHOP , IS_WOOCOMMERCE_STORE_CONNECTED };
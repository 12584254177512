import React from "react";
import { Layout, Card, DescriptionList } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// constant
import constant from "lib/constant/constant";

// propTypes
import vendorProfileProps from "../../propTypes";

const SocialLink = (props) => {
  const { t } = useTranslation();
  const { vendorDetails = {} } = props;
  const { socialLink = [] } = vendorDetails || {};
  let socialLinkData = [];
  const paymentInformation = [
    {
      term: t("label.advanceVendor.facebook"),
      description: socialLink.facebook,
    },
    {
      term: t("label.advanceVendor.instagram"),
      description: socialLink.instagram,
    },
    {
      term: t("label.advanceVendor.twitter"),
      description: socialLink.twitter,
    },
    {
      term: t("label.advanceVendor.youTube"),
      description: socialLink.youtube,
    },
    {
      term: t("label.advanceVendor.linkedIn"),
      description: socialLink.linkedin,
    },
  ];
  socialLinkData = paymentInformation.map((item) => {
    const updatedItem = { ...item };
    if (!item.description) {
      updatedItem.description = t("label.advanceVendor.NoDetailsFound");
    }
    return updatedItem;
  });

  const getDescriptionList = () => {
    if (Object.keys(socialLink).length === 0) {
      return <div className="noData">{t("label.advanceVendor.NoDataAvailable")}</div>;
    }
    return <DescriptionList items={socialLinkData} />;
  };
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>{getDescriptionList()}</Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

SocialLink.propTypes = vendorProfileProps.type;

export default withFeature(withErrorBoundary(SocialLink), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
});

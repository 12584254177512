import React, { useContext } from "react";
import { Stack, Card, TextStyle, Badge, Link, Thumbnail, Caption } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper } from "lib/helpers";

const FulfilledLinesItems = (props) => {
  const { t } = useTranslation();
  const { lineData, data } = props;
  const { userData, history } = useContext(PrivateContext);
  const currentUserData = baseHelper.getResponseData(userData, constant.gql.GET_CURRENT_USER);

  const amount = [];
  const vendorsSum = [];
  lineData.filter(({ vendor, price }) => {
    vendorsSum.push(vendor);
    amount.push(price);
    return [vendorsSum, amount];
  });
  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${t("label.provider")}` : `${setSize} ${t("label.vendors")}`;
  const lineDataItems = lineData.length;

  const stringtoNum = amount.map((item) => parseFloat(item));
  const totalAmount = stringtoNum.reduce((cur, acc) => cur + acc, constant.value.ZERO);

  const isVendor = baseHelper.isVendor(currentUserData);
  const lineItems = lineData.map((lineItem, idx) => {
    if (!lineItem || lineItem.fulfillmentStatus === constant.UNFULFILLED) {
      return null;
    }
    const lineImage =
      imageHelper.resize({ url: lineItem.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;
    const lineItemKey = `lineItem${idx}`;
    const { isProductDeleted, isVendorDeleted } = lineItem;

    const moneyFormat = lineItem.moneyFormat || constant.symbol.DOLLAR;
    const lineTotalPrice = lineItem.price * lineItem.quantity;
    const status = statusHelper.getBadgeStatus(lineItem.status);
    const badgeType = baseHelper.getBadgeType(lineItem.status);
    const lineItemName = <div className="productName">{lineItem.name}</div>;
    return (
      <div key={lineItemKey} className="fulfilled-Secn">
        <Card>
          <Card.Section subdued>
            <Stack wrap={false}>
              <Stack.Item>
                <Thumbnail size="large" source={lineImage} alt={lineItem.name} />
              </Stack.Item>
              <Stack.Item fill>
                {(isProductDeleted && (lineItemName || "")) || (
                  <Link onClick={() => history.push(`/products/edit/${lineItem.productId}`)}>
                    {lineItem.name || ""}
                  </Link>
                )}

                <Caption>
                  <TextStyle variation={constant.SUBDUED}>
                    {lineItem.vendor && lineItem.vendorId
                      ? isVendor || isVendorDeleted
                        ? [`${t("label.provider")}: `, lineItem.vendor, <br />]
                        : [
                            `${t("label.provider")}: `,
                          <Link onClick={() => history.push(constant.PROVIDER_VIEW + lineItem.vendorId)}>
                              {lineItem.vendor ? lineItem.vendor : ""}
                            </Link>,
                          <br />,
                          ]
                      : ""}
                  </TextStyle>
                  <TextStyle variation={constant.SUBDUED}>
                    {t("label.skuList")}
                    {lineItem.sku}
                  </TextStyle>
                </Caption>

                <Stack>
                  <Stack.Item>
                    <Badge status={badgeType} className="text-capitalize">
                      {status}
                    </Badge>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>
                {`${t("label.qty")}:`}
                {lineItem.quantity}
              </Stack.Item>
              <Stack.Item>
                {moneyFormat}
                {(lineTotalPrice && lineTotalPrice.toFixed(2)) || constant.value.ZERO}
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </div>
    );
  });
  const moneyFormat = data && data.moneyFormat;
  const totalFulfill = (
    <Card.Section>
      <Stack>
        <Stack.Item fill>
          <TextStyle variation="strong">{t("label.totalOrderSlip")}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Caption>{`${lineDataItems} ${t("label.items")} from ${addVendorTxt}`}</Caption>
        </Stack.Item>
        <Stack.Item>
          <TextStyle variation="strong">
            {`${moneyFormat || constant.symbol.DOLLAR} ${totalAmount.toFixed(2)}`}
          </TextStyle>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );
  return [lineItems, totalFulfill];
};

export default FulfilledLinesItems;

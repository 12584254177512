import { gql } from "apollo-boost";

const FETCH_ORDER_LINE_LIST = gql`
  query getOrderLineList($input: GetListInput) {
    getOrderLineList(input: $input) {
      status
      error
      data {
        count
        orderLineList {
          _id
          isShippingPaid
          isConsignmentPick
          isVendorFulfilled
          vendor
          vendorId
          shop
          orderId
          shopifyLineItemId
          createdAt
          title
          shopifyOrderId
          shopifyOrderDate
          status
          orderNumber
          fulfillmentStatus
          image
          isReturn
          return {
            reason
            requestedAt
            updatedAt
            sellerNote
            vendorNote
            status
            isSellerManaged
          }
        }
      }
    }
  }
`;
export { FETCH_ORDER_LINE_LIST };

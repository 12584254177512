import React, { useState, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Card, Checkbox, FormLayout, InlineError, Layout, Link, PageActions, Stack, TextField } from "@shopify/polaris";

import { SkeletonAnnotated, Banner } from "lib/components";
import constant from "lib/constant/constant";
import { baseHelper, storageHelper } from "lib/helpers";
import { PublicContext } from "lib/context/publicContext";
import validate from "../yup";

// import gql
import { GET_SELLER_DATA } from "../../../apollo/queries";
import { REGISTER_BY_INVITE } from "../../../apollo/mutations";

import { PrivacyPolicy, TermsPolicy } from "../subFeatures";

const { EMAIL, PASSWORD, EXTRA_TIGHT, gql, PRIVACY, TERMS, POLICY_LINK, TERMS_LINK } = constant;
const LinkRegister = () => {
  const { cms, history, match } = useContext(PublicContext);
  const { params } = match || {};
  const { shop, id } = params || {};
  const { data: sellerData, error: sellerError, loading: sellerLoading } = useQuery(GET_SELLER_DATA, {
    variables: { input: { shop } },
  });

  const [registerUser, { loading }] = useMutation(REGISTER_BY_INVITE);
  const termsLink = storageHelper.get(TERMS_LINK);
  const policyLink = storageHelper.get(POLICY_LINK);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    isAcceptPolicy: false,
  });
  const [errorMessage, setErrorMessage] = useState(true);
  const [modal, setModal] = useState({
    privacy: false,
    terms: false,
  });
  const [registerInfo, setRegisterInfo] = useState({
    title: cms("title"),
    description: cms("description"),
  });

  useEffect(() => {
    if (sellerError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  }, [cms, sellerError]);

  const responseData = baseHelper.getResponseData(sellerData, gql.GET_SELLER_DATA) || false;
  const responseError = baseHelper.getResponseError(sellerData, gql.GET_SELLER_DATA);

  useEffect(() => {
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
    }
  }, [responseError]);

  useEffect(() => {
    if (responseData && responseData.register) {
      const registerTitle =
        responseData.register.title && responseData.register.title !== "" && responseData.register.title;
      const registerDescription =
        responseData.register.desc && responseData.register.desc !== "" && responseData.register.desc;
      setRegisterInfo((prevInfo) => ({
        title: registerTitle || prevInfo.title,
        description: registerDescription || prevInfo.description,
      }));
    }
  }, [responseData]);

  if (sellerLoading) {
    return <SkeletonAnnotated annotatedLines={5} primaryLines={5} />;
  }

  const isDisabled = () => {
    const isAllValuesFilled = !(values.email && values.password && values.confirmPassword && values.isAcceptPolicy);
    return isAllValuesFilled;
  };

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const redirectToLogin = () => {
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  };

  const onSubmit = async () => {
    await handleValidation("email", values.email);
    await handleValidation("password", values.password);
    await handleValidation("confirmPassword", {
      password: values.password,
      confirmPassword: values.confirmPassword,
    });
    await handleValidation("isAcceptPolicy", values.isAcceptPolicy);
    try {
      const res = await registerUser({
        variables: { input: { email: values.email, password: values.password, shop, seller: id } },
      });
      const resData = baseHelper.getResponseData(res.data, gql.REGISTER_BY_INVITE);
      let bannerValue = {
        status: "success",
        title: cms("message.success.registered"),
      };
      let redirect = true;
      if (!resData) {
        redirect = false;
        const error = baseHelper.getResponseError(res.data, gql.REGISTER_BY_INVITE);
        bannerValue = { status: "critical", title: error, isOpen: true };
      }
      setBanner({
        status: bannerValue.status,
        isOpen: true,
        title: bannerValue.title,
      });
      if (redirect) {
        redirectToLogin();
      }
    } catch (err) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  const toggleModal = (modalType) => {
    if (modalType === PRIVACY && policyLink) {
      window.open(policyLink);
      return;
    }
    if (modalType === TERMS && termsLink) {
      window.open(termsLink);
      return;
    }
    setModal((prevState) => ({
      ...prevState,
      [modalType]: !prevState[modalType],
    }));
  };

  const policyLabel = cms("label.policyTerms").map((item) => {
    if (item && item.type && item.type === "action") {
      return (
        <Link key={item.content} plain id="linkTerms" onClick={() => toggleModal(item.key)}>
          {item.content}
        </Link>
      );
    }
    return <React.Fragment key={item.content}>{item.content}</React.Fragment>;
  });

  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  return (
    <>
      <Banner title={banner.title} status={banner.status} isOpen={banner.isOpen} onDismiss={closeBanner} />
      <PrivacyPolicy isOpen={modal.privacy} onClose={() => toggleModal(PRIVACY)} />
      <TermsPolicy isOpen={modal.terms} onClose={() => toggleModal(TERMS)} />
      <br />
      <Layout>
        <Layout.AnnotatedSection title={registerInfo.title} description={registerInfo.description}>
          <Card sectioned>
            <FormLayout>
              <TextField
                id="email"
                label={`${cms("common.label.email")}*`}
                type={EMAIL}
                value={values.email || ""}
                placeholder={cms("common.placeholder.emailAddress")}
                onChange={(value) => handleChange(constant.EMAIL, value)}
                onBlur={() => handleValidation(constant.EMAIL, values.email)}
                error={errorMessage && errorMessage.email}
              />
              <TextField
                id="password"
                label={`${cms("common.label.password")}*`}
                placeholder={cms("common.placeholder.password")}
                type={PASSWORD}
                value={values.password || ""}
                onChange={(value) => handleChange(constant.PASSWORD, value)}
                onBlur={() => handleValidation(constant.PASSWORD, values.password)}
                error={errorMessage && errorMessage.password}
              />
              <TextField
                id="confirmPassword"
                label={`${cms("common.label.confirmPassword")}*`}
                placeholder={cms("common.placeholder.confirmPassword")}
                type={PASSWORD}
                value={values.confirmPassword || ""}
                onChange={(value) => handleChange(constant.CONFIRM_PASSWORD, value)}
                onBlur={() => {
                  handleValidation(constant.CONFIRM_PASSWORD, {
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                  });
                }}
                error={errorMessage && errorMessage.confirmPassword}
              />
              <Stack wrap spacing={EXTRA_TIGHT}>
                <Checkbox
                  id="privacyPolicyTermsOfUse"
                  checked={values.isAcceptPolicy}
                  onChange={(value) => handleChange(constant.IS_ACCEPT_POLICY, value)}
                  onBlur={() => handleValidation(constant.IS_ACCEPT_POLICY, values.isAcceptPolicy)}
                />
                <div className="checkBoxLabel">
                  <span>{policyLabel}</span>
                </div>
              </Stack>
              <InlineError message={errorMessage && errorMessage.isAcceptPolicy} fieldID="privacyPolicyTermsOfUse" />
            </FormLayout>
          </Card>
          <PageActions
            primaryAction={{
              content: cms("button.primary"),
              onAction: () => onSubmit(),
              disabled: isDisabled(),
              loading,
            }}
          />
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
};
export default LinkRegister;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ResourceItem, TextStyle, FormLayout } from "@shopify/polaris";
import { PrivateContext } from "lib/context";

// import from lib
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

function ProductListView(props) {
  const { item, keyIndex, textField, addField } = props;
  const { currentUser } = useContext(PrivateContext);
  const isSeller = baseHelper.isSeller(currentUser);
  const {
    productId,
    title,
    price = 0,
    comparePrice = 0,
    markUp,
    quantity = 0,
    sellerDiscount,
    vendorDiscount,
    inventoryManagement,
    position = false,
    measurement = {},
  } = item;

  const { price: markUpPrice = 0, type: markUpType = constant.FLAT } = markUp || {};
  const { price: sellerDiscountPrice = 0, type: sellerDiscountType = constant.FLAT } = sellerDiscount || {};
  const { price: vendorDiscountPrice = 0, type: vendorDiscountType = constant.FLAT } = vendorDiscount || {};

  let { length = {}, width = {}, height = {} } = measurement || {};
  length = (length && length.value) || 0;
  width = (width && width.value) || 0;
  height = (height && height.value) || 0;

  return (
    <ResourceItem id={keyIndex}>
      <FormLayout.Group condensed>
        <TextStyle variation="strong">{title}</TextStyle>
        {addField.indexOf(baseHelper.ucFirst(constant.PRICE)) !== -1 && (
          <>{textField(productId, parseInt(price, 10), constant.PRICE, false, inventoryManagement, position)}</>
        )}
        {addField.indexOf(baseHelper.ucFirst(constant.QUANTITY)) !== -1 && (
          <>{textField(productId, parseInt(quantity, 10), constant.QUANTITY, false, inventoryManagement, position)}</>
        )}
        {isSeller && addField.indexOf(constant.MARKUPKEY) !== -1 && (
          <>{textField(productId, markUpPrice, constant.MARKUP, markUpType, inventoryManagement, position)}</>
        )}
        {!isSeller && addField.indexOf(constant.COMPARE_AT_PRICE) !== -1 && (
          <>
            {textField(
              productId,
              parseInt(comparePrice, 10),
              constant.COMPARE_PRICE,
              false,
              inventoryManagement,
              position
            )}
          </>
        )}
        {addField.indexOf(baseHelper.ucFirst(constant.DISCOUNT)) !== -1 && (
          <>
            {(isSeller &&
              textField(
                productId,
                sellerDiscountPrice,
                constant.SELLER_DISCOUNT,
                sellerDiscountType,
                inventoryManagement,
                position
              )) ||
              textField(
                productId,
                vendorDiscountPrice,
                constant.VENDOR_DISCOUNT,
                vendorDiscountType,
                inventoryManagement,
                position
              )}
          </>
        )}
        {addField.indexOf(baseHelper.ucFirst(constant.LENGTH)) !== -1 && (
          <>{textField(productId, parseInt(length, 10), constant.LENGTH, false, inventoryManagement, position)}</>
        )}
        {addField.indexOf(baseHelper.ucFirst(constant.WIDTH)) !== -1 && (
          <>{textField(productId, parseInt(width, 10), constant.WIDTH, false, inventoryManagement, position)}</>
        )}
        {addField.indexOf(baseHelper.ucFirst(constant.HEIGHT)) !== -1 && (
          <>{textField(productId, parseInt(height, 10), constant.HEIGHT, false, inventoryManagement, position)}</>
        )}
      </FormLayout.Group>
    </ResourceItem>
  );
}

ProductListView.defaultProps = {
  item: {},
  keyIndex: 0,
  textField: () => {},
  addField: [],
};
ProductListView.propTypes = {
  item: PropTypes.shape(PropTypes.object),
  keyIndex: PropTypes.number,
  textField: PropTypes.func,
  addField: PropTypes.arrayOf(PropTypes.string),
};

export default ProductListView;

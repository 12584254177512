import { gql } from "apollo-boost";

const FETCH_PLANS = gql`
  {
    getPlans {
      status
      data {
        _id
        code
        isHidden
        maxVendors
        maxProducts
        monthlyFee
        name
        sortOrder
      }
      error
    }
  }
`;

const FETCH_CURRENT_PLAN = gql`
  query {
    getCurrentPlan {
      data {
        code
        monthlyFee
      }
      status
      error
    }
  }
`;

export { FETCH_CURRENT_PLAN, FETCH_PLANS };

import React from "react";
import { Badge, Caption, Link, Stack, TextStyle, ResourceItem } from "@shopify/polaris";
import { DeleteMinor, LogOutMinor, ViewMajorMonotone } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// import from lib
import { baseHelper } from "lib/helpers";
import Popover from "lib/components/popover/popover";
import constant from "lib/constant/constant";

// import cms
import cmsVendorList from "../../cms/vendorListCMS";

const VendorListView = (props) => {
  const { item, keyIndex, onDeleteVendor, onLoginVendor, onAssocProvider, activePopover, setActivePopover } = props;
  const { _id, lastName, firstName, email, brandName, createdAt, roles } = item;
  const { address = "", verified } = email || {};
  const { name: role } = roles;
  const DELETE = "delete";
  const LOGIN = "login";
  const VIEW = "view";

  const { t } = useTranslation();
  const vendorListCMS = cmsVendorList(t);
  const { button, label } = vendorListCMS;
  const handleSelectAction = (action) => {
    if (action === VIEW) {
      onAssocProvider(_id);
    }
    if (action === DELETE) {
      onDeleteVendor(_id);
    }
    if (action === LOGIN) {
      onLoginVendor(_id, brandName, role);
    }
  };

  const topMargin10px = {
    marginTop: "10px",
  };

  return (
    <>
      <ResourceItem id={_id} sortOrder={keyIndex} accessibilityLabel={`${label.viewDetailsFor} ${brandName}`}>
        <Stack>
          <Stack.Item fill>
            <div className="ellipsis">
              <Link url={`/providers/view/${_id}`}>
                <TextStyle>
                  <div className="ellipsis">{(brandName && baseHelper.ucFirst(brandName)) || "No Brandname"}</div>
                </TextStyle>
              </Link>
              <Caption>
                <TextStyle variation="subdued">
                  {`${constant.ONBOARDED} ${constant.ON} ${baseHelper.formatDate(createdAt)}`}
                </TextStyle>
                <br />
                <div className="ellipsis">{`${label.name} ${firstName || "No Name"} ${lastName || ""}`}</div>
                <div className="ellipsis">{`${label.email} ${address}`}</div>
              </Caption>
              <div style={topMargin10px}>
                <Stack>
                  <Stack.Item>
                    <Badge status={verified ? "success" : "new"}>
                      {verified ? constant.APPROVED : constant.PENDING}
                    </Badge>
                  </Stack.Item>
                </Stack>
              </div>
            </div>
          </Stack.Item>
          <Stack.Item>
            <Popover
              // eslint-disable-next-line no-underscore-dangle
              active={activePopover[_id]}
              // eslint-disable-next-line no-underscore-dangle
              setActive={() => setActivePopover({ [_id]: !activePopover[_id] })}
              options={[
                {
                  content: button.viewSeller,
                  icon: ViewMajorMonotone,
                  onAction: () => handleSelectAction(VIEW),
                },
                {
                  content: button.loginVendor,
                  icon: LogOutMinor,
                  onAction: () => handleSelectAction(LOGIN),
                },
                {
                  content: button.deleteVendor,
                  destructive: true,
                  icon: DeleteMinor,
                  onAction: () => handleSelectAction(DELETE),
                },
              ]}
            />
          </Stack.Item>
        </Stack>
      </ResourceItem>
    </>
  );
};

VendorListView.defaultProps = {
  item: {},
  keyIndex: "",
  onDeleteVendor: () => {},
  onLoginVendor: () => {},
  onAssocProvider: () => {},
  activePopover: {},
};

VendorListView.propTypes = {
  item: PropTypes.shape(PropTypes.object),
  keyIndex: PropTypes.number,
  onDeleteVendor: PropTypes.func,
  onLoginVendor: PropTypes.func,
  onAssocProvider: PropTypes.func,
  activePopover: PropTypes.objectOf(),
  setActivePopover: PropTypes.func.isRequired,
};

export default VendorListView;

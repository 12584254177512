import React from "react";
import { Layout, Card, DescriptionList } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";
// constant
import constant from "lib/constant/constant";

// propTypes
import vendorProfileProps from "../../propTypes";

const Payment = (props) => {
  const { t } = useTranslation();
  const { vendorDetails = {} } = props;
  const { payment = [] } = vendorDetails || {};
  let paymentTabData = [];

  const paymentInformation = [
    {
      term: t("label.advanceVendor.bankName"),
      description: payment.bank,
    },
    {
      term: t("label.advanceVendor.accountNumber"),
      description: payment.account,
    },
    {
      term: t("label.advanceVendor.sortCode"),
      description: payment.sortCode,
    },
    {
      term: t("label.advanceVendor.country"),
      description: payment.country,
    },
  ];
  paymentTabData = paymentInformation.map((item) => {
    const updatedItem = { ...item };
    if (!updatedItem.description) {
      updatedItem.description = t("label.advanceVendor.NoDetailsFound");
    }
    return updatedItem;
  });

  const getDescriptionList = () => {
    if (Object.keys(payment).length === 0) {
      return <div className="noData">{t("label.advanceVendor.NoDataAvailable")}</div>;
    }
    return <DescriptionList items={paymentTabData} />;
  };
  return (
    <Layout>
      <Layout.Section>
        <Card>
          <Card.Section>{getDescriptionList()}</Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

Payment.propTypes = vendorProfileProps.type;

export default withFeature(withErrorBoundary(Payment), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
});

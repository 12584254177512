import React from "react";
import { Card, OptionList, RadioButton, Popover, Stack } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { renderDestination } from "../propsType/index";

const RenderOfferDestination = (props) => {
  const { t } = useTranslation();
  const {
    checkedDestination,
    renderRegion,
    handleDestination,
    popoverActive,
    activator,
    handleShippingDestination,
    destinationData,
    destinationCodes,
    togglePopoverActive,
  } = props;

  return (
    <Card title={t("label.Destination")} sectioned>
      <Stack.Item>
        <RadioButton
          label={t("label.AllDestinations")}
          helpText={t("message.AllowAllDestination")}
          checked={checkedDestination}
          id="all-destinations"
          name="all-destinations"
          onChange={handleDestination}
        />
      </Stack.Item>
      <Stack.Item>
        <RadioButton
          label={t("label.OnlySelectedDestinations")}
          helpText={t("message.AllowSelectedDestination")}
          id="selected-destinations"
          name="selected-destinations"
          checked={!checkedDestination}
          onChange={handleDestination}
        />
      </Stack.Item>

      {!checkedDestination && [
        <Stack.Item>
          <Stack.Item>
            <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
              <OptionList
                onChange={(value) => {
                  handleShippingDestination(value);
                }}
                sections={destinationData}
                selected={destinationCodes}
                allowMultiple
              />
            </Popover>
          </Stack.Item>
          <Stack.Item>{renderRegion()}</Stack.Item>
        </Stack.Item>,
      ]}
    </Card>
  );
};

RenderOfferDestination.propTypes = renderDestination.type;

export default RenderOfferDestination;

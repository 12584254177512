import constant from "lib/constant/constant";

const { admin, operator, provider } = constant.userKey;

const allRoles = [admin, operator, provider];

const privateRouteCodes = {
  "/": {
    module: "dashboard",
    cms: "dashboard",
    parentModule: "dashboard",
    isTwoColumnLayout: true,
    rolesAllowed: [...allRoles],
    isFullWidth: true,
  },
  "/activity": {
    module: "activity",
    cms: "activity",
    rolesAllowed: [...allRoles],
  },
  "/activity/bulk": {
    module: "bulkActivity",
    cms: "bulkActivity",
    rolesAllowed: [...allRoles],
  },
  "/activity/view/:id": {
    module: "viewActivity",
    cms: "viewActivity",
    rolesAllowed: [...allRoles],
  },
  "/association": {
    module: "association",
    cms: "association",
    parentModule: "setting",
    rolesAllowed: [admin],
  },
  "/branding": {
    module: "branding",
    cms: "branding",
    rolesAllowed: [operator],
    parentModule: "setting",
  },
  "/products/bulk/edit": {
    module: "bulkProductEdit",
    cms: "bulkProductEdit",
    rolesAllowed: [...allRoles],
    isFullWidth: true,
  },
  "/commission": {
    module: "commission",
    cms: "commission",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/change-password": {
    module: "changePassword",
    cms: "changePassword",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
  },
  "/profile": {
    module: "editProfile",
    cms: "editProfile",
    parentModule: "setting",
    rolesAllowed: [...allRoles],
    dependentKey: ["advanceVendorProfile"],
  },
  "/invitation": {
    module: "invitation",
    cms: "invitation",
    rolesAllowed: [operator, admin],
  },
  "/invoice/:id/:lineItemId": {
    module: "invoice",
    cms: "invoice",
    isPrivateLayoutHidden: true,
    rolesAllowed: [admin, operator, provider],
  },
  "/maintenance": {
    module: "maintenance",
    cms: "maintenance",
    rolesAllowed: [...allRoles],
  },
  "/orders": {
    module: "orders",
    cms: "orders",
    rolesAllowed: [...allRoles],
  },
  "/order-slip/:id": {
    module: "orderSlip",
    cms: "orderSlip",
    rolesAllowed: [...allRoles],
    isPrivateLayoutHidden: true,
  },
  "/orders/view/:id": {
    module: "viewOrder",
    cms: "viewOrder",
    parentModule: "order",
    rolesAllowed: [...allRoles],
  },
  "/export/order": {
    module: "exportOrder",
    cms: "exportOrder",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/export/payment": {
    module: "exportPayment",
    cms: "exportPayment",
    isPrivateLayoutHidden: true,
    rolesAllowed: [...allRoles],
  },
  "/onboard-activity": {
    module: "onboardActivity",
    cms: "onboardActivity",
    rolesAllowed: [admin],
  },
  "/reporting": {
    module: "reporting",
    cms: "reporting",
    rolesAllowed: [operator],
  },
  "/update/plan": {
    module: "updatePlan",
    cms: "updatePlan",
    rolesAllowed: [operator],
  },
  "/payments": {
    module: "payments",
    cms: "payment",
    rolesAllowed: [...allRoles],
  },
  "/payment-connect": {
    module: "connectPayment",
    cms: "connectPayment",
    rolesAllowed: [provider],
  },
  "/paypal-callback": { // deprecated by /paypal/callback
    module: "paypalCallback",
    cms: "paypalCallback",
    rolesAllowed: [provider],
  },
  "/paypal/callback": {
    module: "paypalCallback",
    cms: "paypalCallback",
    rolesAllowed: [provider],
  },
  "/products": {
    module: "products",
    cms: "listProduct",
    parentModule: "product",
    rolesAllowed: [...allRoles],
  },
  "/products/add": {
    module: "addProduct",
    cms: "addProduct",
    parentModule: "product",
    rolesAllowed: [...allRoles],
  },
  "/products/add/shop": {
    module: "connectShop",
    cms: "connectShop",
    rolesAllowed: [provider],
  },
  "/products/edit/:id": {
    module: "editProduct",
    cms: "productEdit",
    parentModule: "product",
    rolesAllowed: [...allRoles],
  },
  "/products/review/:id": {
    module: "reviewProduct",
    cms: "reviewProduct",
    rolesAllowed: [operator],
  },
  "/products/view/:id": {
    module: "viewProduct",
    cms: "viewProduct",
    rolesAllowed: [admin, provider],
  },
  "/products/shopify": {
    module: "shopifyProduct",
    cms: "shopifyProduct",
    rolesAllowed: [provider],
  },
  "/products/woocommerce": {
    module: "woocommerceProduct",
    cms: "woocommerceProduct",
    rolesAllowed: [provider],
    parentModule: "product",
  },
  "/playground": {
    module: "playground",
    cms: "playground",
    rolesAllowed: [admin, operator],
    // iisPrivateLayoutHidden: true,
    isFullWidth: true,
  },
  "/operators": {
    module: "listSeller",
    cms: "listSeller",
    rolesAllowed: [admin],
  },
  "/operators/view/:id": {
    module: "viewSeller",
    cms: "viewSeller",
    rolesAllowed: [admin],
  },
  "/setting": {
    module: "settings",
    cms: "settings",
    rolesAllowed: [...allRoles],
  },
  "/setting/discount": {
    module: "discountSetting",
    cms: "discountSetting",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/fulfillment": {
    module: "fulfillment",
    cms: "fulfillment",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/markup": {
    module: "markupSetting",
    cms: "markupSetting",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/setting/notification": {
    module: "muteNotification",
    cms: "muteNotification",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/order": {
    module: "orderSetting",
    cms: "orderSetting",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/payment": {
    module: "addPayment",
    cms: "addPayment",
    rolesAllowed: [operator],
  },
  "/setting/product": {
    module: "productSetting",
    cms: "productSetting",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/setting/attachment": {
    module: "productAttachmentSetting",
    cms: "productAttachmentSetting",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/shipping": {
    module: "shipping",
    cms: "shipping",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/session": {
    module: "session",
    cms: "session",
    rolesAllowed: [admin],
  },
  "/providers/shopify-callback": {
    main: "shopifyCallback",
    cmsCode: "shopifyCallback",
    rolesAllowed: [provider],
  },
  "/stripe/callback": {
    module: "stripeCallback",
    cms: "stripeCallback",
    rolesAllowed: [provider],
  },
  "/contact-us": {
    module: "contactUs",
    cms: "contactUs",
    parentModule: "userManagement",
    rolesAllowed: [admin, operator, provider],
  },
  "/terms": {
    module: "term",
    cms: "terms",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "/variants/add/:productId": {
    module: "addVariant",
    cms: "addVariant",
    rolesAllowed: [...allRoles],
  },
  "/variants/edit/:productId/:id": {
    module: "editVariant",
    cms: "editVariant",
    rolesAllowed: [...allRoles],
  },
  "/providers": {
    module: "vendors",
    cms: "listVendor",
    rolesAllowed: [operator, admin],
  },
  "/providers/add": {
    module: "addVendor",
    cms: "addVendor",
    parentModule: "provider",
    rolesAllowed: [operator, admin],
  },
  "/providers/edit/:id": {
    module: "editVendor",
    cms: "editVendor",
    rolesAllowed: [operator, admin],
  },
  "/payouts": {
    module: "payouts",
    cms: "payouts",
    rolesAllowed: [...allRoles],
  },
  "/shipping/advance": {
    module: "advanceShipping",
    cms: "advanceShipping",
    parentModule: "setting",
    rolesAllowed: [operator, provider],
  },
  "/feature": {
    module: "feature",
    cms: "feature",
    rolesAllowed: [admin],
  },
  "/domain": {
    module: "domain",
    cms: "domain",
    rolesAllowed: [admin],
  },
  "/file-download/vendor": {
    module: "downloadVendorFile",
    cms: "downloadVendorFile",
    isPrivateLayoutHidden: true,
    rolesAllowed: [operator, admin],
  },
  "/file-download/invite": {
    module: "downloadInviteFile",
    cms: "downloadInviteFile",
    isPrivateLayoutHidden: true,
    rolesAllowed: [operator, admin],
  },
  "/providers/view/:id": {
    module: "viewVendor",
    cms: "viewVendor",
    rolesAllowed: [operator, admin],
  },
  "/update-payment": {
    module: "updatePayment",
    cms: "updatePayment",
    rolesAllowed: [operator],
  },
  "/email-template": {
    module: "emailTemplate",
    cms: "emailTemplate",
    rolesAllowed: [operator, admin],
  },
  "/email-template/edit/:id": {
    module: "customizeEmailTemplate",
    cms: "customizeEmailTemplate",
    rolesAllowed: [operator],
  },
  "/advance-providers": {
    module: "advanceVendorProfile",
    cms: "vendorAdvance",
    rolesAllowed: [operator],
  },
  "/providers/profile/:id": {
    module: "advanceVendorProfile",
    cms: "vendorAdvance",
    rolesAllowed: [operator],
  },
  "/provider/manage": {
    module: "manageVendor",
    cms: "manageVendor",
    parentModule: "provider",
    rolesAllowed: [operator],
  },
  "/setting/customer": {
    module: "manageCustomer",
    cms: "manageCustomer",
    parentModule: "setting",
    rolesAllowed: [operator],
  },
  "*": {},
};

export default privateRouteCodes;

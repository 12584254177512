// import react packages;
import React, { useState, useEffect } from "react";

// import polaris components
import { Banner, Card, Checkbox, Layout, Modal, Scrollable, Stack, Subheading } from "@shopify/polaris";

// import config
import { termConfig } from "../../../config";

// import props
import { termProp } from "../../../props";

const TermModal = (props) => {
  const { isOpen, setIsOpen, useTranslation, loading, onSubmit } = props;
  const { t } = useTranslation();
  const { subHeading, boldTag, paraTag } = termConfig.message;
  const [isEnabled, setIsEnabled] = useState(false);

  const handleCheckbox = () => {
    setIsEnabled(!isEnabled);
  };
  useEffect(() => {
    setIsEnabled(false);
  }, [isOpen]);

  return (
    <Modal
      large
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={t("label.ProductAdd.TermsTitle")}
      primaryAction={{
        content: t("label.ProductAdd.Continue"),
        onAction: () => onSubmit(),
        disabled: !isEnabled,
        loading,
      }}
      secondaryActions={[
        {
          content: t("label.ProductAdd.Cancel"),
          plain: true,
          onAction: () => setIsOpen(false),
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Layout>
            <Layout.Section>
              <Banner status="warning">
                <p>{paraTag.para1}</p>
              </Banner>
            </Layout.Section>
            <Layout.Section>
              <Card sectioned>
                <Scrollable shadow className="scrollHeight">
                  <b>{boldTag.b1}</b>
                  {paraTag.para2}
                  <br />
                  <br />
                  {paraTag.para3}
                  <b>{boldTag.b1}</b>
                  {paraTag.para4}
                  <br />
                  <br />
                  {paraTag.para5}
                  <b>{boldTag.b1}</b>
                  {paraTag.para6}
                  <br />
                  <br />
                  <Subheading>{subHeading.sub1}</Subheading>
                  {paraTag.para12}
                  <br />
                  <br />
                  <Subheading>{subHeading.sub2}</Subheading>
                  {paraTag.para7}
                  <br />
                  <br />
                  <Subheading>{subHeading.sub3}</Subheading>
                  {paraTag.para8}
                  <br />
                  <br />
                  <Subheading>{subHeading.sub4}</Subheading>
                  {paraTag.para13}
                  <br />
                  <br />
                  <b>{`${boldTag.b2}${boldTag.b1}`}</b>
                  {paraTag.para9}
                  <br />
                  <br />
                  {paraTag.para10}
                  <b>{boldTag.b1}</b>
                  {paraTag.para11}
                </Scrollable>
              </Card>
            </Layout.Section>
          </Layout>
          <Checkbox checked={isEnabled} label={t("label.ProductAdd.AcceptTerm")} onChange={() => handleCheckbox()} />
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

TermModal.propTypes = termProp.type;
TermModal.defaultProps = termProp.default;

export default TermModal;

import React from "react";
import PropTypes from "prop-types";
import { Button, Badge, Card, Layout, List, Stack, DisplayText, TextStyle, Heading } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import cms and constants
import constant from "lib/constant/constant";

const { PRO, GROWTH, ASK, FREE } = constant;

const propTypes = {
  onSelect: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPlan: PropTypes.string.isRequired,
};

const free = FREE;

const Plan = (props) => {
  const { t } = useTranslation();

  const renderTitle = (plan) => {
    const capitalizeName = plan.name.toUpperCase();
    const isAsk = plan.monthlyFee === ASK;
    return (
      <Stack>
        <Stack.Item fill>
          {`${capitalizeName}`}
          &nbsp;
          {isAsk && <Badge status="warning">{t("label.comingSoon")}</Badge>}
          {plan.code === GROWTH ? <Badge status="success">{t("label.popular")}</Badge> : ""}
          {plan.currentPlan && <Badge status="info">{t("label.active")}</Badge>}
        </Stack.Item>
      </Stack>
    );
  };

  const renderPlanButton = (plan) => {
    const isAsk = plan.monthlyFee === ASK;
    const { selectedPlan = "" } = props;
    return (
      <Button
        disabled={selectedPlan === plan.code || plan.disabled || isAsk}
        onClick={() => props.onSelect(plan)}
        primary
        fullWidth
        loading={selectedPlan === plan.code}
      >
        {t("label.updatePlan")}
      </Button>
    );
  };

  const renderListItem = (item) => (
    <List.Item key={item}>
      <TextStyle>{item}</TextStyle>
    </List.Item>
  );

  const getValuesFromObject = (obj = {}) => Object.values(obj);
  const comFeature = {};
  comFeature.advancedCommission = t("label.commonFeatures.advancedCommission");
  comFeature.customBranding = t("label.commonFeatures.customBranding");
  const comValues = getValuesFromObject(comFeature);

  const growthFeature = {};
  growthFeature.basicShippingOptions = t("label.growth.basicShippingOptions");
  growthFeature.customDomain = t("label.growth.customDomain");
  growthFeature.pricingManagement = t("label.growth.pricingManagement");
  const growthValues = getValuesFromObject(growthFeature);

  const proFeature = {};
  proFeature.advancedPricingManagement = t("label.pro.advancedPricingManagement");
  proFeature.advancedShippingOptions = t("label.pro.advancedShippingOptions");
  proFeature.apiAccess = t("label.pro.apiAccess");
  proFeature.customDomain = t("label.pro.customDomain");
  proFeature.returnsManagement = t("label.pro.returnsManagement");
  proFeature.vendorPages = t("label.pro.vendorPages");
  const proValues = getValuesFromObject(proFeature);

  const renderCommonFeatures = () => comValues.map((val) => renderListItem(val));

  const renderGrowthPlanFeatures = () => growthValues.map((val) => renderListItem(val));

  const renderProPlanFeatures = () => proValues.map((val) => renderListItem(val));

  const renderBodySection = (plan) => (
    <Card.Section title={t("label.features")}>
      <List>
        {plan.maxProducts && (
          <List.Item>
            <TextStyle>{t("label.maxProducts")}</TextStyle>
            {`: ${plan.maxProducts}`}
          </List.Item>
        )}
        {plan.maxVendors && (
          <List.Item>
            <TextStyle>{t("label.maxVendors")}</TextStyle>
            {`: ${plan.maxVendors}`}
          </List.Item>
        )}
        {renderCommonFeatures()}
        {plan.code === GROWTH && renderGrowthPlanFeatures()}
        {plan.code === PRO && renderProPlanFeatures()}
      </List>
      <br />
      <Stack>
        <Stack.Item fill>{renderPlanButton(plan)}</Stack.Item>
      </Stack>
    </Card.Section>
  );

  const renderHeadingSection = (plan) => (
    <Card.Section>
      <Stack alignment="center" wrap={false}>
        <Stack.Item fill>
          <DisplayText size="small" element="h1">
            {renderTitle(plan)}
          </DisplayText>
        </Stack.Item>
        <Stack.Item>
          <DisplayText size="large">{`$${plan.monthlyFee}/m`}</DisplayText>
        </Stack.Item>
      </Stack>
      {plan.message && <Badge status="warning">{plan.message}</Badge>}
    </Card.Section>
  );

  const renderPlanCard = (plan) => {
    if (!plan) {
      return null;
    }
    const { _id: planId } = plan;
    return (
      <Layout.Section key={plan.code} oneThird>
        <Card key={`renderShopifyPlanCard${planId}`} className="Polaris-Page__AppInstall">
          {renderHeadingSection(plan)}
          {renderBodySection(plan)}
        </Card>
      </Layout.Section>
    );
  };

  const renderPlans = () => {
    const { plans = [] } = props;
    const filteredPlans = plans.filter((plan) => plan.code !== free);
    return filteredPlans.map(renderPlanCard);
  };

  return <>{renderPlans()}</>;
};

Plan.propTypes = propTypes;

export default Plan;

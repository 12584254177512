import React, { useContext, useEffect, useState } from "react";
import { Caption, Card, Collapsible, FormLayout, Layout, List, PageActions, Select, TextField } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";

// import context
import { PrivateContext } from "lib/context";

// components
import { Banner, Spinner } from "lib/components";

// constants
import constant from "lib/constant/constant";

// import helpers
import { baseHelper } from "lib/helpers";

// import gql
import { GET_SHIPPING } from "app/shipping/apollo/queries";
import { SAVE_SHIPPING } from "app/shipping/apollo/mutations";

import { withFeature, withErrorBoundary } from "lib/hoc";

import VendorManager from "./vendorManager";

const ManageShipping = (props) => {
  const { setShowingBandTab = () => { }, setSelectedTab } = props;
  const { history, cms, currentUser } = useContext(PrivateContext);
  const [term, setTerm] = useState(constant.value.ZERO);
  const [amount, setAmount] = useState(0);
  const [type, setType] = useState(cms("common.label.flat").toLowerCase());
  const [vendors, setVendors] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [allowVendor, setAllowVendor] = useState(constant.APPROVE_ALL);
  const [isFreeForCustomer, setIsFreeForCustomer] = useState(false);
  const [shippingPerVendor, setShippingPerVendor] = useState({});
  const [isAllVendorsAllowed, setIsAllVendorsAllowed] = useState(true);
  const [allowCumulativeVendor, setAllowCumulativeVendor] = useState(false);
  const [isHideDescription, setIsHideDescription] = useState(false);
  const [isShippingBandAvailable, setIsShippingBandAvailable] = useState(true);
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [setting, setSetting] = useState({});

  const captions = cms("operator.section.general.caption");

  const options = [
    {
      label: cms("operator.section.general.label.noShippingCharge"),
      value: constant.NONE,
    },
    {
      label: cms("operator.section.general.label.flatShippingCharge"),
      value: constant.FLAT,
    },
    {
      label: cms("operator.section.general.label.equalShippingChargeSplit"),
      value: constant.EQUAL,
    },
    {
      label: cms("operator.section.general.label.vendorManageShippingCharges"),
      value: constant.VENDOR_MANAGE,
    },
  ];
  const { loading: loadingShipping, data: shippingData } = useQuery(GET_SHIPPING);

  const [saveShipping, { loading: isSaveShippingLoading }] = useMutation(SAVE_SHIPPING);
  const handleVendors = (selected) => {
    setSelectedVendors(selected);
  };

  const renderCaptions = (captionsList) => {
    return captionsList.map((caption) => (
      <List.Item key={caption}>
        <Caption>{caption}</Caption>
      </List.Item>
    ));
  };

  const handleAllowVendor = (_, newValue) => {
    setAllowVendor(newValue);
  };

  const handleShippingTypeSelect = (value) => {
    setTerm(value);
    if (value !== cms("common.label.flat").toLowerCase()) {
      setAmount(0);
    }
  };

  const toggleFreeForCustomer = () => {
    setIsFreeForCustomer(!isFreeForCustomer);
  };

  const toggleAllowed = () => {
    const { isAllowed = false } = shippingPerVendor || {};
    setShippingPerVendor((prevState) => ({
      ...prevState,
      isAllowed: !isAllowed,
    }));
  };

  const handleAllowCumulativeShipping = (_checked, newValue) => {
    setAllowCumulativeVendor(newValue);
    setIsAllVendorsAllowed(!isAllVendorsAllowed);
  };
  const handleAllowAllCumulativeShipping = () => {
    setAllowCumulativeVendor(false);
    setIsAllVendorsAllowed(!isAllVendorsAllowed);
  };

  const handleCumulativeVendors = (selectedIds) => {
    setSelectedVendors(selectedIds);
    setShippingPerVendor((prevState) => ({
      ...prevState,
      vendorIds: [...selectedIds],
    }));
  };
  const handleIsHideDescription = (value) => {
    setIsHideDescription(!value);
  };

  const manageSetupRuleProps = {
    vendors,
    selectedVendors,
    allowVendor,
    onChangeRadio: handleAllowVendor,
    onChangeAutoComplete: handleVendors,
  };
  const managePaymentProps = {
    checked: isFreeForCustomer,
    onChange: toggleFreeForCustomer,
  };

  const manageShippingChargeProps = {
    checked: (shippingPerVendor && shippingPerVendor.isAllowed) || false,
    onChange: toggleAllowed,
    vendors,
    selectedVendors,
    isAllVendorsAllowed,
    onChangeRadioOne: handleAllowCumulativeShipping,
    onChangeRadioTwo: handleAllowAllCumulativeShipping,
    onChangeAutoComplete: handleCumulativeVendors,
  };

  const manageIsHideDescriptionProps = {
    isHideDescription,
    onChange: handleIsHideDescription,
  };

  useEffect(() => {
    const responseError = baseHelper.getResponseError(shippingData, constant.gql.GET_SHIPPING);
    if (responseError) {
      const banner = {
        title: responseError,
        status: "critical",
        isOpen: true,
      };
      setBannerStatus(banner);
      return;
    }
    const responseData = baseHelper.getResponseData(shippingData, constant.gql.GET_SHIPPING);
    const { shipping, vendors: resVendors, isShippingBandAvailable: isBandAvailable } = responseData || {};
    const {
      term: resTerm,
      amount: resAmount,
      type: resShippingType,
      allowVendor: resAllowVendor,
      vendorIds,
      isHideDescription: isHideShipping,
      isFreeForCustomer: resIsFreeForCustomer,
      shippingPerVendor: resShippingPerVendor = {},
    } = shipping || {};
    const { vendorIds: shippingPerVendorIds = [] } = resShippingPerVendor || {};
    setTerm(resTerm || constant.NONE);
    setType(resShippingType || cms("common.label.flat").toLowerCase());
    if (shipping) {
      setShowingBandTab(resTerm === constant.VENDOR_MANAGE || false);
    }
    setAmount(resAmount || constant.value.ZERO);
    setVendors(resVendors || []);
    setSelectedVendors(vendorIds || []);
    setAllowVendor(resAllowVendor);
    setIsFreeForCustomer(resIsFreeForCustomer);
    setShippingPerVendor(resShippingPerVendor);
    setIsAllVendorsAllowed(!shippingPerVendorIds.length);
    setIsHideDescription(isHideShipping);
  }, [shippingData, setShowingBandTab, cms]);

  const isValid = () => {
    if (!amount && term === constant.FLAT) {
      setBannerStatus({ isOpen: true, title: cms("operator.message.error.amountIsRequired"), status: "critical" });
      return false;
    }
    return true;
  };

  const manageAggregateShippingSettingProps = (setting) => {
    setSetting(setting);
  };

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };

  const submitForm = async () => {
    const data = {};
    const isAmount = isValid();
    if (!isAmount) {
      return;
    }
    const checkIsVendorManageShipping = term === constant.VENDOR_MANAGE;

    if (checkIsVendorManageShipping) {
      const { isAllowed, vendorIds } = shippingPerVendor || {};
      data.allowVendor = allowVendor;
      data.vendorIds = selectedVendors;
      data.isFreeForCustomer = isFreeForCustomer;
      data.shippingPerVendor = {
        isAllowed: !!isAllowed,
        vendorIds: isAllVendorsAllowed ? [] : vendorIds,
      };
      if (allowCumulativeVendor && data.vendorIds.length === 0) {
        data.allowVendor = constant.APPROVE_SELECTED;
        data.shippingPerVendor.isAllowed = false;
      }
    }
    if (setting) {
      data.setting = setting;
    }
    try {
      const res = await saveShipping({
        variables: {
          input: {
            term,
            amount: parseFloat(amount, 10),
            type,
            allowVendor: data.allowVendor,
            vendorIds: data.vendorIds,
            isFreeForCustomer: data.isFreeForCustomer,
            shippingPerVendor: data.shippingPerVendor,
            isHideDescription,
            setting: data.setting,
          },
        },
      });

      const responseError = baseHelper.getResponseError(res.data, constant.gql.SAVE_SHIPPING);
      if (responseError) {
        setBannerStatus({
          title: responseError,
          status: "critical",
          isOpen: true,
        });
        return;
      }
      setBannerStatus({
        title: cms("operator.message.success"),
        status: "success",
        isOpen: true,
      });
      setShowingBandTab(term === constant.VENDOR_MANAGE || false);
    } catch (e) {
      setBannerStatus({
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
        isOpen: true,
      });
    }
  };

  if (loadingShipping) {
    return <Spinner />;
  }

  return (
    <>
      {(bannerStatus.isOpen || !isShippingBandAvailable) && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => {
              setBannerStatus({ isOpen: false, title: "", status: "" });
            }}
          />
          <Banner
            isOpen={!isShippingBandAvailable}
            status="warning"
            title="Add Shipping Band"
            action={{ content: "Add Shipping Band", onAction: () => setSelectedTab(1) }}
          >
            You need to have atleast one shipping band otherwise FREE shipping will be applied to all your incoming
            orders
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("operator.section.general.title")}
        description={cms("operator.section.general.description")}
      >
        <Card sectioned>
          <FormLayout>
            <List type="bullet">{renderCaptions(captions)}</List>
            <FormLayout.Group>
              <Select
                options={options}
                id={constant.SHIPPING_CHARGE_SELECT}
                placeholder={cms("operator.section.general.placeholder.chooseShippingDistribution")}
                value={term}
                onChange={handleShippingTypeSelect}
              />
              {term === cms("common.label.flat").toLowerCase() && (
                <TextField
                  id={constant.SHIPPING_COST}
                  prefix={currentUser.moneyFormat}
                  onChange={(value) => {
                    setAmount(acceptOnlyValidInput(value, amount));
                  }}
                  value={(amount || "").toString()}
                  min={constant.value.ZERO}
                  step={1}
                />
              )}
            </FormLayout.Group>
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>

      <Collapsible open={term === constant.VENDOR_MANAGE} id={constant.VENDOR_CHARGE_SECTION}>
        <VendorManager
          manageSetupRuleProps={manageSetupRuleProps}
          managePaymentProps={managePaymentProps}
          manageShippingChargeProps={manageShippingChargeProps}
          manageIsHideDescriptionProps={manageIsHideDescriptionProps}
          manageAggregateShippingSettingProps={manageAggregateShippingSettingProps}
          setBannerStatus={setBannerStatus}
        />
      </Collapsible>

      <Layout.Section>
        <PageActions
          primaryAction={{
            id: constant.SUBMIT_MANAGE_SHIPPING,
            content: cms("common.button.submit"),
            onClick: () => submitForm(),
            loading: isSaveShippingLoading,
          }}
          secondaryActions={{
            id: constant.CANCEL_MANAGE_SHIPPING,
            content: cms("common.button.cancel"),
            onClick: () => history.push("/"),
          }}
        />
      </Layout.Section>
    </>
  );
};

ManageShipping.propTypes = {
  setShowingBandTab: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default withFeature(withErrorBoundary(ManageShipping));

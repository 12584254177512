import React from "react";
import { Card, TextField, FormLayout } from "@shopify/polaris";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

const Title = (props) => {
  const { t } = useTranslation();
  const {
    variantData = {},
    handleChange = () => {},
    options,
    handleValidation = () => {},
    errorMessage = false,
  } = props;
  const {
    option1 = "Size",
    option2 = "Color",
    option3 = "Material",
    option1Val = null,
    option2Val = null,
    option3Val = null,
  } = options || {};

  const renderTextField = (optionLabel, optionValue, id) => {
    return (
      <TextField
        id={`optionValue${id}`}
        label={`${optionLabel}*`}
        value={optionValue || ""}
        onChange={(value) => handleChange(`option${id}Val`, value)}
        onBlur={() => handleValidation(`option${id}` || optionLabel.toLowerCase(), optionValue)}
        placeholder={optionLabel}
        error={
          errorMessage &&
          ((errorMessage[`option${id}`] && t("error.common.fieldRequired")) ||
            (errorMessage[optionLabel.toLowerCase()] && t("error.common.fieldRequired")))
        }
      />
    );
  };

  return (
    <Card title="" id="title" sectioned>
      <FormLayout>
        {option1 && option1Val && renderTextField(option1, variantData.option1Val, "1")}
        {option2 && option2Val && renderTextField(option2, variantData.option2Val, "2")}
        {option3 && option3Val && renderTextField(option3, variantData.option3Val, "3")}
      </FormLayout>
    </Card>
  );
};

Title.propTypes = {
  variantData: PropTypes.objectOf(PropTypes.shape).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.string),
  errorMessage: PropTypes.shape(PropTypes.object).isRequired,
};

Title.defaultProps = {
  options: {},
};

export default Title;

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Filters,
  ResourceList as PolarisResourceList,
  Stack,
  TextStyle,
  Pagination,
  Select,
} from "@shopify/polaris";
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const ResourceList = (props) => {
  // NOTE: Add more props as per your need
  const {
    resourceName,
    items,
    renderItem,
    promotedBulkActions,
    bulkActions,
    loading,
    selectable,
    showHeader,
    totalItemsCount,
    sortValue,
    sortOptions,
    onSortChange,
    filters,
    appliedFilters,
    onFiltersChange,
    queryValue,
    onQueryChange,
    onSelectionChange,
    onQueryClear,
    selectedItems,
    page,
    count,
    perPage,
    setPage,
    setPerPage,
    idForItem,
    isFilter,
    handleClearAll,
    hasMoreItems,
    totalCountLabel,
  } = props;

  const { history } = useContext(PrivateContext);
  const [selectedPerPage, setSelectedPerPage] = useState(perPage.toString() || "20");
  const PerPage = [
    { label: "10", value: "10" },
    { label: "20", value: "20" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
  ];

  let maxPage = (count && count && parseInt(count / perPage, 10)) || 0;
  maxPage = (maxPage && ((count % perPage && maxPage + 1) || maxPage)) || 0;

  const changePage = (value) => {
    const newPage = parseInt(page, 10) + parseInt(value, 10);
    const search = baseHelper.setQueryParams(history.location, { page: newPage });
    setPage(newPage);
    history.push(`${history.location.pathname}?${search}`);
  };

  const changePageLimit = (value) => {
    setPerPage(value);
    setSelectedPerPage(value);
    const search = baseHelper.setQueryParams(history.location, { perPage: value });
    history.push(`${history.location.pathname}?${search}`);
  };

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onFilterQueryChange={onFiltersChange}
      onQueryChange={onQueryChange}
      onFiltersChange={onFiltersChange}
      onQueryClear={onQueryClear}
      onClearAll={handleClearAll}
    />
  );

  return (
    <Card>
      <PolarisResourceList
        resourceName={resourceName}
        items={items}
        renderItem={renderItem}
        selectedItems={selectedItems}
        onSelectionChange={onSelectionChange}
        promotedBulkActions={promotedBulkActions}
        bulkActions={bulkActions}
        sortValue={sortValue}
        sortOptions={sortOptions}
        onSortChange={onSortChange}
        filterControl={(isFilter && filterControl) || null}
        loading={loading}
        selectable={selectable}
        showHeader={showHeader}
        totalItemsCount={totalItemsCount}
        idForItem={idForItem}
        hasMoreItems={hasMoreItems}
      />
      {(count && (
        <Card sectioned>
          <Stack wrap={false} alignment="center">
            <Stack.Item fill>
              <TextStyle>{`${totalCountLabel || "Total Count"}: ${count}`}</TextStyle>
            </Stack.Item>
            <Stack.Item fill>
              {(maxPage && (
                <Pagination
                  hasPrevious={parseInt(page, 10) !== 1}
                  onPrevious={() => changePage(-1)}
                  hasNext={parseInt(page, 10) !== maxPage}
                  onNext={() => changePage(1)}
                />
              )) ||
                ""}
            </Stack.Item>
            <Stack.Item>
              <Select options={PerPage} onChange={changePageLimit} value={selectedPerPage} />
            </Stack.Item>
          </Stack>
        </Card>
      )) ||
        ""}
    </Card>
  );
};

ResourceList.propTypes = {
  resourceName: PropTypes.shape({
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
  }),
  items: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
  promotedBulkActions: PropTypes.arrayOf(PropTypes.object),
  bulkActions: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  queryValue: PropTypes.string,
  selectable: PropTypes.bool,
  showHeader: PropTypes.bool,
  onQueryClear: PropTypes.func,
  totalItemsCount: PropTypes.number,
  onSelectionChange: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  sortValue: PropTypes.string,
  sortOptions: PropTypes.arrayOf(PropTypes.object),
  appliedFilters: PropTypes.arrayOf(PropTypes.object),
  onSortChange: PropTypes.func,
  onQueryChange: PropTypes.func,
  count: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
  onFiltersChange: PropTypes.func,
  idForItem: PropTypes.func,
  isFilter: PropTypes.bool,
  handleClearAll: PropTypes.func,
  hasMoreItems: PropTypes.bool,
  totalCountLabel: PropTypes.string,
};

ResourceList.defaultProps = {
  resourceName: {
    singular: "item",
    plural: "items",
  },
  items: [],
  promotedBulkActions: [],
  bulkActions: [],
  loading: false,
  queryValue: "",
  selectable: false,
  showHeader: false,
  onQueryClear: () => {},
  totalItemsCount: 0,
  sortValue: "",
  sortOptions: [],
  selectedItems: [],
  onSelectionChange: () => {},
  onSortChange: () => {},
  onQueryChange: () => {},
  setPerPage: () => {},
  setPage: () => {},
  count: 0,
  page: 0,
  perPage: 0,
  filters: [],
  appliedFilters: [],
  onFiltersChange: () => {},
  idForItem: (item) => item.id,
  isFilter: true,
  handleClearAll: () => {},
  hasMoreItems: false,
  totalCountLabel: null,
};

export default ResourceList;

import React, { useContext, useState, useEffect, useCallback } from "react";
import { useQuery } from "react-apollo";
import {
  ResourceItem,
  TextStyle,
  Stack,
  Heading,
  Link,
  Caption,
  Tooltip,
  Badge,
  Tabs,
  Card,
  Layout,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import from lib
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { ResourceList, Banner } from "lib/components";
import { baseHelper } from "lib/helpers";

// gql operations
import { FETCH_ORDER_LINE_LIST } from "app/advanceVendor/apollo/queries";
import ORDER_LIST from "app/advanceVendor/apollo/subscriptions";
import MoreFilters from "./moreFilters/moreFilters";
import listData from "./cms/orderList";

const AdvanceOperatorOrderList = () => {
  const { history, match } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { gql } = constant;
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { mainLink, links } = listData(t);
  const [selectedItems, setSelectedItems] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [orderList, setOrderList] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const selectedSort = queryParam.sort_name && `${queryParam.sort_name}_${queryParam.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || null);
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || null);

  const inputData = {
    filter: queryParam.filter,
    path: queryParam.path,
    sort_name: queryParam.sort_name || "createdAt",
    sort_order: queryParam.sort_order || "desc",
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10) || 1,
    perPage: parseInt(queryParam.perPage, 10) || 10,
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
    vendorId: match.params.id,
  };
  const { data: order, loading: orderLoading, error: orderError, subscribeToMore } = useQuery(FETCH_ORDER_LINE_LIST, {
    variables: {
      input: inputData,
    },
  });
  useEffect(() => {
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (orderError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: t("error.common.somethingWentWrong"),
      });
    }
  }, [orderError, t]);
  const responseError = baseHelper.getResponseError(order, gql.GET_ORDER_LINES_LIST);
  useEffect(() => {
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
    }
  }, [responseError]);
  const responseData = baseHelper.getResponseData(order, gql.GET_ORDER_LINES_LIST);
  useEffect(() => {
    if (responseData) {
      setOrderList(responseData && responseData.orderLineList);
      setOrderCount(responseData && responseData.count);
    }
  }, [responseData]);

  const setFilters = useCallback(
    (newFilter, select) => {
      const getFilter = baseHelper.setQueryParams(history.location, { filter: newFilter, tab: select });
      history.push(`${history.location.pathname}?${getFilter}`);
    },
    [history]
  );
  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1], page: 1 });
    }
  };
  const handleQueryClear = () => {
    setSearch("");
    baseHelper.setUrl(history, { search: "" });
  };

  const handleTaggedWithRemove = () => {
    setTaggedWith("");
    setSelectedFilter("");
    baseHelper.setUrl(history, { list_search: "", list_filter: "", page: 1 });
  };

  const getTabs = {
    0: "all",
    1: "pending",
    2: "accepted",
    3: "rejected",
    4: "shipped",
    5: "closed",
  };
  const handleSelect = (item) => {
    setSelectedItems(item);
  };
  const handleTabChange = (select) => {
    setSelectedTab(select);
    setFilters(getTabs[select], select);
  };
  useEffect(() => {
    subscribeToMore({
      document: ORDER_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newOrder = subscriptionData.data.orderLineView.data.orderLine;
        const prevOrder = prev.getOrderLineList.data.orderLineList;
        const filteredOrder = prevOrder.filter((item) => item.shopifyLineItemId !== newOrder.shopifyLineItemId);
        const OrderData = [newOrder, ...filteredOrder];
        const totalCount = OrderData.length;
        return [setOrderList(OrderData), setOrderCount(totalCount)];
      },
    });
  }, [subscribeToMore]);
  const pageContent = {
    label: {
      status: t("tooltip.status"),
      fulfillmentStatus: t("tooltip.fulfillmentStatus"),
    },
  };

  const renderItem = (item) => {
    const {
      orderId,
      vendorId,
      orderNumber,
      fulfillmentStatus,
      status,
      createdAt,
      shopifyLineItemId,
      vendor,
      title,
    } = item;
    return (
      <ResourceItem id={orderId} persistActions>
        <Stack>
          <Stack.Item fill Style="width: 200px">
            <Heading element="h2">
              <Link onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>
                <div className="ellipsis">{`#${orderNumber}- ${title}`}</div>
              </Link>
            </Heading>
          </Stack.Item>
          <Stack.Item>
            <Tooltip content={pageContent.label.status} preferredPosition="above">
              <Badge status={baseHelper.getBadgeType(status)}>{baseHelper.ucFirst(status)}</Badge>
            </Tooltip>
          </Stack.Item>
          <Stack.Item>
            <Tooltip content={pageContent.label.fulfillmentStatus} preferredPosition="above">
              <Badge status={baseHelper.getBadgeType(fulfillmentStatus)}>{baseHelper.ucFirst(fulfillmentStatus)}</Badge>
            </Tooltip>
          </Stack.Item>
        </Stack>
        <Caption>
          {`${t("label.VendorName")} :`}
          <Link onClick={() => history.push(`${links.vendor.link}${vendorId}`)}>{vendor}</Link>
        </Caption>
        <Caption>
          {`${t("label.LineItemId")} : `}
          <span>{`#${shopifyLineItemId}`}</span>
        </Caption>
        <Caption>
          <TextStyle variation="subdued">{` ${t("label.CreatedOn")} ${baseHelper.formatDate(createdAt)}`}</TextStyle>
        </Caption>
      </ResourceItem>
    );
  };
  const handleClearAll = () => {
    handleTaggedWithRemove();
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return !value;
  }

  const appliedFilters = !isEmpty(taggedWith)
    ? [{ key: "taggedWith", label: disambiguateLabel("taggedWith", taggedWith), onRemove: handleTaggedWithRemove }]
    : [];

  const filters = [
    {
      key: t("taggedWith"),
      label: t("label.order.filterBy"),
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
  ];
  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };
  return (
    <>
      <Layout>
        {banner.isOpen && (
          <>
            <Layout.Section>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={() => closeBanner()}
              />
            </Layout.Section>
            <br />
          </>
        )}
        <Layout.Section>
          <Card>
            <Tabs tabs={listData(t).tabsData} selected={selectedTab} onSelect={handleTabChange} />
            <ResourceList
              items={orderList}
              renderItem={renderItem}
              loading={loading || orderLoading}
              queryValue={search}
              onQueryClear={handleQueryClear}
              onQueryChange={handleSearchChange}
              sortOptions={listData(t).sortOptions}
              sortValue={sortValue}
              onSortChange={handleSort}
              selectedItems={selectedItems}
              onSelectionChange={handleSelect}
              bulkActions={listData(t).bulkAction}
              filters={filters}
              appliedFilters={appliedFilters}
              handleClearAll={handleClearAll}
              count={orderCount}
              page={page}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </>
  );
};

export default withFeature(withErrorBoundary(AdvanceOperatorOrderList), {
  feature: constant.ADVANCE_VENDOR_PROFILE,
});

import React, { useContext } from "react";
import { Card, Layout, TextField, FormLayout, Collapsible } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../context/context";

const Description = () => {
  const { BRAND_DESCRIPTION } = constant;
  const { handleChange, description, learnMore } = useContext(BrandingContext);
  const { cms = () => {} } = useContext(PrivateContext);

  return (
    <Collapsible open>
      <Layout.AnnotatedSection title={cms("section.brandDescription.title")}>
        <Card
          title={cms("section.brandDescription.subTitle")}
          actions={[{ content: "Learn more", onAction: () => learnMore("Description", "TODO: ") }]}
        >
          <Card.Section>
            <FormLayout>
              <TextField
                id="description"
                placeholder={cms("section.brandDescription.placeHolder.description")}
                value={description}
                onChange={(value) => handleChange(BRAND_DESCRIPTION, value, BRAND_DESCRIPTION)}
              />
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default Description;

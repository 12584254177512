import * as yup from "yup";

import cms from "../cms/yup";

export const clientIdSchema = yup.object().shape({
  clientId: yup.string().required(cms.error.clientId),
});

export const secretSchema = yup.object().shape({
  secret: yup.string().required(cms.error.secret),
});

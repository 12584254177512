import { gql } from "apollo-boost";

const GET_PRODUCT_LIST = gql`
  query getProductList($input: GetListInput) {
    getProductList(input: $input) {
      status
      error
      data {
        ... on SellerProductListData {
          count
          productList {
            _id
            approved
            status
            title
            updatedAt
            vendor
            vendorId
            images {
              imageUrl
            }
            productId
            createdAt
            published
            variants {
              barcode
              comparePrice
              customRule
              customLabel
              customCharge
              vendorDiscount {
                price
                type
              }
            }
          }
        }
      }
    }
  }
`;
const GET_PRODUCT_LIST_PROVIDER = gql`
  query getProductList($input: GetListInput) {
    getProductList(input: $input) {
      status
      error
      data {
        ... on ProductListData {
          count
          productList {
            _id
            approved
            status
            title
            vendor
            vendorId
            images {
              imageUrl
            }
            createdAt
            updatedAt
            variants {
              barcode
              comparePrice
              customRule
              customLabel
              customCharge
              vendorDiscount {
                price
                type
              }
            }
            productId
          }
        }
      }
    }
  }
`;
export { GET_PRODUCT_LIST, GET_PRODUCT_LIST_PROVIDER };

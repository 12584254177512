import React, { useContext, useState, useEffect } from "react";
import { Card, TextField, Checkbox, FormLayout, Layout, PageActions, TextContainer } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";

import config from "config";

// import component
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";

import { PrivateContext, OnboardingContext } from "lib/context";

import { baseHelper } from "lib/helpers";

import { SEND_BULK_INVITE_BY_CSV } from "app/vendors/apollo/mutations";
import { GET_EMAIL_TEMPLATE } from "app/vendors/apollo/queries";
import FileUpload from "lib/components/fileInput/fileUpload";

const BulkInvite = (props) => {
  const { history, cms } = useContext(PrivateContext);
  const { isOnboarding = false, onNext, onPrevious } = useContext(OnboardingContext);
  const { setBanner, learnMore } = props;
  const { data, loading } = useQuery(GET_EMAIL_TEMPLATE);
  const [bulkInviteRequest, { loading: bulkInviteLoading }] = useMutation(SEND_BULK_INVITE_BY_CSV);
  const [messageData, setMessageData] = useState(null);
  const [subject, setSubject] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [values, setValues] = useState();

  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (messageData || subject || fileData) {
      setBanner({
        isOpen: false,
        status: "",
        title: "",
      });
    }
  }, [messageData, subject, fileData, setBanner]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const resError = baseHelper.getResponseError(data, constant.gql.GET_BULK_EMAIL_TEMPLATE);
    if (resError) {
      setBanner({ isOpen: true, title: resError, status: "critical" });
      return;
    }
    const resData = baseHelper.getResponseData(data, constant.gql.GET_BULK_EMAIL_TEMPLATE);
    setSubmitButtonLoading(false);
    setMessageData(resData.message);
    setSubject(resData.subject);
  }, [data, setBanner]);

  useEffect(() => {
    if (values && values.data) {
      setFileName(values.fileName);
      const csvValue = values.data.trim();
      const regexForEnterKey = /\r\n/g;
      const regexForComma = /,/g;
      let csvDataAfterRegex = csvValue.replace(regexForEnterKey, "\r\n");
      csvDataAfterRegex = csvDataAfterRegex.replace(regexForComma, "\r");
      setFileData(csvDataAfterRegex);
    }
  }, [values]);

  const onSubmit = async () => {
    if (!fileData) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("message.error.csvIsRequired"),
      });
      return;
    }
    if (!subject) {
      return;
    }

    const requestData = {
      file: {
        file: fileData,
        fileName,
        isReadOnly,
      },
      message: { message: messageData, subject },
    };
    try {
      const res = await bulkInviteRequest({
        variables: {
          input: requestData,
        },
      });
      const resData = baseHelper.getResponseData(res.data, constant.gql.SEND_BULK_INVITE_BY_CSV);
      let banner = {
        action: {
          id: "viewLogs",
          content: cms("section.bulkInvite.message.success.action"),
          onAction: () => history.push("/activity/bulk"),
        },
        isOpen: true,
        status: "success",
        title: cms("section.bulkInvite.message.success.title"),
        children: cms("section.bulkInvite.message.success.content"),
      };

      if (!resData) {
        const error = baseHelper.getResponseError(res.data, constant.gql.SEND_BULK_INVITE_BY_CSV);
        banner = {
          action: null,
          children: null,
          isOpen: true,
          status: "critical",
          title: error,
        };
      }
      setIsSubmitDisabled(true);
      setBanner({
        action: banner.action,
        children: banner.children,
        isOpen: banner.isOpen,
        status: banner.status,
        title: banner.title,
      });
      if (isOnboarding && resData) {
        onNext();
      }
    } catch (error) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  const isAnyEmptyField = () => {
    return !(fileData && subject && messageData);
  };

  const description = [cms("section.bulkInvite.description.para1"), cms("section.bulkInvite.description.para2")];
  return (
    <>
      {loading || submitButtonLoading ? (
        <SkeletonAnnotated />
      ) : (
        <div>
          <Layout.AnnotatedSection
            title={cms("section.bulkInvite.title")}
            description={description.map((text) => (
              <React.Fragment key={text}>
                <p>{text}</p>
                <br />
              </React.Fragment>
            ))}
          >
            <Card
              title={cms("section.bulkInvite.title")}
              actions={[
                { content: "Learn more", onAction: () => learnMore(cms("section.bulkInvite.title"), "TODO: ") },
              ]}
            >
              <Card.Section>
                <FormLayout>
                  <TextContainer>
                    To invite new and perspective vendors to join your marketplace fill in the required form fields and
                    hit ‘Submit.’
                  </TextContainer>
                  <FileUpload
                    setFileData={setFileData}
                    setValue={setValues}
                    downloadCSV={`${config.rootURL}/file-download/invite`}
                    values={values}
                  />
                  <TextField
                    id="textField"
                    label={`${cms("section.invite.label.subject")}*`}
                    value={subject}
                    onChange={(value) => setSubject(value)}
                    error={!subject && "Subject is required"}
                  />
                  <div id="csvSection">
                    <label htmlFor="idTiny">
                      <b>{`${cms("section.bulkInvite.label.message")}*`}</b>
                    </label>
                    <Editor
                      id="idTiny"
                      textareaName="Description"
                      value={messageData}
                      onEditorChange={(value) => setMessageData(value)}
                      init={{
                        menubar: true,
                        plugins: ["autolink link image lists print preview"],
                        toolbar: "undo redo | bold italic | alignleft aligncenter alignright",
                      }}
                    />
                  </div>
                </FormLayout>
              </Card.Section>
            </Card>
            <Card title="Vendor's access permission">
              <Card.Section>
                <TextContainer>
                  <p>
                    {`By default a vendor has full write access in their account however you can set a 
                    vendor's access to be 'read only'. With 'read only' access a vendor can log into their 
                    account but they are not able to perform any create, update or delete actions ie. 
                    add or manage products and orders or change any settings.`}
                  </p>
                  <p>
                    This permission can be revoked or added at any time by you after a vendor has been created. This
                    feature is particulariy useful when you are looking to manage on behalf of your vendors.
                  </p>
                </TextContainer>
                <br />
                <FormLayout>
                  <Checkbox
                    id="readPermission"
                    label={cms("common.label.isReadOnlyVendor")}
                    checked={isReadOnly}
                    onChange={() => setIsReadOnly(!isReadOnly)}
                  />
                </FormLayout>
              </Card.Section>
            </Card>
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                id: "submitButton",
                onAction: () => onSubmit(),
                loading: bulkInviteLoading,
                disabled: isAnyEmptyField() || isSubmitDisabled,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  id: "cancelButton",
                  onAction: () => (isOnboarding ? onPrevious() : history.push("/")),
                },
              ]}
            />
          </Layout.AnnotatedSection>
        </div>
      )}
    </>
  );
};
BulkInvite.propTypes = {
  setBanner: PropTypes.func.isRequired,
  learnMore: PropTypes.func.isRequired,
};
export default BulkInvite;

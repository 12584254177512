import React, { useContext } from "react";

import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { AdminOrderView } from "./admin/features";
import { OperatorOrderView } from "./operator/features";
import { ProviderOrderView } from "./provider/features";

import { storageHelper } from "../../../lib/helpers";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const OrderView = () => {
  const { isLoading } = useContext(PrivateContext);

  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }

    if (role === admin) {
      return <AdminOrderView />;
    }
    if (role === operator) {
      return <OperatorOrderView />;
    }
    if (role === provider) {
      return <ProviderOrderView />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const OrderViewComponent = getComponent(currentUserRole);
  return OrderViewComponent;
};

export default OrderView;

import { gql } from "apollo-boost";

const LOGIN = gql`
  mutation loginCheck($input: Credential!) {
    login(input: $input) {
      data {
        token
        isOtp
      }
      status
      error
    }
  }
`;
export default LOGIN;

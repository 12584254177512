import React from "react";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

// import generic components
import { ContactUs } from "../../../generic";

const ProviderContactUs = () => {
  return <ContactUs />;
};

export default withFeature(ProviderContactUs, { feature: constant.SUPPORT });

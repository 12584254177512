import { gql } from "apollo-boost";

const GET_SELLER_LIST = gql`
  query getSellerList($input: GetListInput) {
    getSellerList(input: $input) {
      data {
        count
        sellers {
          email {
            address
            verified
          }
          brandName
          createdAt
          plan {
            name
          }
          updatedAt
          _id
          roles {
            name
          }
        }
      }
      status
      error
    }
  }
`;

const VERIFY_OTP = gql`
  query verifyOtp($input: VerifyOtp) {
    verifyOtp(input: $input) {
      data {
        token
      }
      status
      error
    }
  }
`;

export { GET_SELLER_LIST, VERIFY_OTP };

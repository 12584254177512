import React, { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import {
  Modal,
  FormLayout,
  TextField,
  Tooltip,
  Stack,
  Card,
  ButtonGroup,
  Button,
  TextStyle,
  Badge,
  Link,
  Thumbnail,
  Caption,
  Icon,
} from "@shopify/polaris";

import { updateOrderLineTracking, updateOrderLineStatus } from "app/orders/apollo/updateOrderNote";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper } from "lib/helpers";
import { NoteMajorMonotone } from "@shopify/polaris-icons";

const UnfulfilledLineItems = (props) => {
  const { t } = useTranslation();
  const { itemData, onRefetchOrderLineView, onRefetchOrderView, setBanner, data } = props;
  const [trackingCompany] = useState("");
  const [trackingNumber] = useState("");
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");

  const [trackingModal, setTrackingModal] = useState(false);

  const [selectedItemData, setSelectedItemData] = useState({});
  const [isAcceptOrder, setIsAcceptedOrder] = useState("");

  const [updateOrderLine, { data: dataUpdateOrderLineTracking, loading: loadingOrderLineTracking }] = useMutation(
    updateOrderLineTracking
  );
  const [updateOrderStatus, { data: updateOrderLineData, loading: orderLineLoading }] = useMutation(
    updateOrderLineStatus
  );
  const { setPageData, pageData, history, match } = useContext(PrivateContext);
  const { primaryAction, title, breadcrumbs, subtitle } = pageData;
  const processRow = (value, isAccept) => {
    const { _id: id, orderId } = value;
    const formData = { lineItemId: id, orderId, isAccept };

    setIsAcceptedOrder(isAccept);
    updateOrderStatus({ variables: { input: formData } });
  };
  useEffect(() => {
    if (updateOrderLineData) {
      const updateOrderStatusData = baseHelper.getResponseData(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      const updateOrderStatusError = baseHelper.getResponseError(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      if (updateOrderStatusData && isAcceptOrder) {
        primaryAction.disabled = false;
        setPageData({ title, breadcrumbs, subtitle, primaryAction });
        setBanner({
          status: constant.SUCCESS,
          title: t("message.orderLineApproved"),
          isOpen: true,
        });
        onRefetchOrderView();
        onRefetchOrderLineView();
      } else if (updateOrderStatusData && !isAcceptOrder) {
        setBanner({
          status: constant.SUCCESS,
          title: t("message.orderLineRejected"),
          isOpen: true,
        });
        onRefetchOrderView();
        onRefetchOrderLineView();
      }
      if (updateOrderStatusError) {
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderStatusError,
          isOpen: true,
        });
      }
    }
  }, [
    updateOrderLineData,
    breadcrumbs,
    isAcceptOrder,
    onRefetchOrderLineView,
    onRefetchOrderView,
    primaryAction,
    setBanner,
    setPageData,
    subtitle,
    t,
    title,
  ]);
  const openTrackingModel = (value) => {
    setSelectedItemData(value);
    setTrackingModal(true);
  };
  const addTrackingDetails = () => {
    if (!initialTrackingCompany) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: t("message.trackingDetail.trackingCompany"), status: "critical" });
      return false;
    }

    if (!initialTrackingNumber) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: t("message.trackingDetail.trackingNumber"), status: "critical" });
      return false;
    }
    const data = {
      trackingNumber: initialTrackingNumber,
      trackingCompany: initialTrackingCompany,
    };
    const { _id: id, orderId } = selectedItemData;
    data.id = orderId;
    data.lineItemId = id;
    updateOrderLine({ variables: { input: data } });
  };
  useEffect(() => {
    if (dataUpdateOrderLineTracking) {
      const updateOrderTracking = baseHelper.getResponseData(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );
      const updateOrderTrackingError = baseHelper.getResponseError(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );

      if (updateOrderTracking) {
        primaryAction.disabled = true;
        setPageData({ title, breadcrumbs, subtitle, primaryAction });
        setTrackingModal(false);
        setInitialTrackingCompany("");
        setInitialTrackingNumber("");
        setBanner({
          status: constant.SUCCESS,
          title: t("message.orderFulfilled"),
          isOpen: true,
        });
        onRefetchOrderView();
        onRefetchOrderLineView();
      }
      if (updateOrderTrackingError) {
        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
  }, [
    dataUpdateOrderLineTracking,
    breadcrumbs,
    onRefetchOrderLineView,
    onRefetchOrderView,
    primaryAction,
    setBanner,
    setPageData,
    subtitle,
    t,
    title,
  ]);
  const renderTracking = () => {
    return (
      <>
        <Modal
          open={trackingModal}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber(trackingNumber);
            setInitialTrackingCompany(trackingCompany);
          }}
          title={t("label.addTracking")}
          primaryAction={{
            content: t("label.saveTrackingDetails"),
            onAction: () => addTrackingDetails(),
            loading: loadingOrderLineTracking,
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label={t("label.company")}
                value={initialTrackingCompany}
                onChange={(val) => {
                  setInitialTrackingCompany(val);
                }}
              />
              <TextField
                label={t("label.number")}
                value={initialTrackingNumber}
                onChange={(val) => {
                  setInitialTrackingNumber(val);
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      </>
    );
  };
  const renderTotalAmount = () => {
    const amount = [];
    const vendorsSum = [];
    const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;

    itemData.filter(({ vendor, price }) => {
      vendorsSum.push(vendor);
      amount.push(price);
      return [vendorsSum, amount];
    });

    const vendorSet = new Set(vendorsSum);
    const setSize = vendorSet.size;
    const addVendorTxt =
      setSize === constant.value.ONE ? `${setSize} ${t("label.provider")}` : `${setSize} ${t("label.vendors")}`;
    const lineDataItems = itemData.length;

    const stringtoNum = amount.map((item) => parseFloat(item));
    const totalAmount = stringtoNum.reduce((cur, acc) => cur + acc, constant.value.ZERO);

    return (
      <>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{t("label.totalOrderSlip")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Caption>
                (
                {`${lineDataItems}
                ${t("label.items")} from ${addVendorTxt}`}
                )
              </Caption>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">{`${moneyFormat} ${totalAmount.toFixed(2)}`}</TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </>
    );
  };
  const renderUnfullfilled = () => {
    const UnfulFilled = itemData.map((value, key) => {
      if (!value || value.fulfillmentStatus !== constant.UNFULFILLED) {
        return null;
      }
      const lineImage =
        imageHelper.resize({ url: value.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

      const lineItemKey = `lineItem${key}`;
      const { isProductDeleted, isVendorDeleted } = value;

      let isAccepted = false;
      let isFulfilled = false;
      switch (value.status) {
        case constant.ACCEPTED:
          isAccepted = true;
          break;
        case constant.REJECTED:
          isAccepted = false;
          break;
        case constant.STATUS_NEW:
          isAccepted = true;
          break;
        case constant.CLOSED:
          isAccepted = null;
          break;
        default:
          break;
      }
      if (value.fulfillmentStatus === constant.UNFULFILLED) {
        switch (value.status) {
          case constant.ACCEPTED:
            isFulfilled = false;
            break;
          case constant.REJECTED:
            isFulfilled = false;
            break;
          case constant.STATUS_NEW:
            isFulfilled = true;
            break;
          case constant.CLOSED:
            isFulfilled = null;
            break;
          default:
            break;
        }
      }
      const moneyFormat = value.moneyFormat || constant.symbol.DOLLAR;
      const lineTotalPrice = value.price * value.quantity;
      const status = statusHelper.getBadgeStatus(value.status);
      const badgeType = baseHelper.getBadgeType(value.status);
      const lineItemName = <div className="productName">{value.name}</div>;

      return (
        <div className="badge-gap" key={lineItemKey}>
          <Card.Section subdued>
            <Stack wrap={false}>
              <Stack.Item>
                <Thumbnail size="large" source={lineImage} alt={value.name} />
              </Stack.Item>
              <Stack.Item fill>
                {(isProductDeleted && (lineItemName || "")) || (
                  <Link onClick={() => history.push(`/products/edit/${value.productId}`)}>{value.name || ""}</Link>
                )}

                <Caption>
                  <TextStyle variation={constant.SUBDUED}>
                    {value.vendor && value.vendorId && `${t("label.provider")}: `}
                    {isVendorDeleted && value.vendor}
                    {((value.vendor && value.vendorId) || !isVendorDeleted) && (
                      <Link onClick={() => history.push(constant.PROVIDER_VIEW + value.vendorId)}>{value.vendor}</Link>
                    )}
                  </TextStyle>
                  <br />
                  <TextStyle variation={constant.SUBDUED}>
                    {t("label.skuList")}
                    {value.sku}
                  </TextStyle>
                </Caption>

                <Stack alignment="center">
                  <Stack.Item>
                    <Badge
                      status={
                        (isAccepted && value.status === t("label.accepted").toLowerCase() && constant.SUCCESS) ||
                        (isAccepted === false && constant.WARNING) ||
                        (isAccepted && value.status === t("label.new").toLowerCase() && constant.ATTENTION) ||
                        badgeType
                      }
                      className="text-capitalize"
                    >
                      {(isAccepted && value.status === t("label.accepted").toLowerCase() && t("label.accepted")) ||
                        (isAccepted === false && t("label.rejected")) ||
                        (isAccepted && value.status === t("label.new").toLowerCase() && t("label.new")) ||
                        status}
                    </Badge>
                  </Stack.Item>
                </Stack>

                {isAccepted && isFulfilled && (
                  <Stack alignment="center">
                    <Stack.Item>
                      <ButtonGroup>
                        <Tooltip content={constant.REJECT_ORDER}>
                          <div style={{ color: "#bf0711" }}>
                            <Button
                              plain
                              monochrome
                              onClick={() => processRow(value, false, key)}
                              loading={orderLineLoading}
                            >
                              {baseHelper.ucFirst(t("label.rejectThisOrder"))}
                            </Button>
                          </div>
                        </Tooltip>
                        <Tooltip content={constant.ACCEPT_ORDER}>
                          <Button
                            size="slim"
                            monochrome
                            outline
                            primary
                            onClick={() => processRow(value, true, key)}
                            loading={orderLineLoading}
                          >
                            {baseHelper.ucFirst(t("label.acceptThisOrder"))}
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Stack.Item>
                  </Stack>
                )}

                {isAccepted && !isFulfilled && (
                  <FormLayout>
                    <Stack alignment="center">
                      <Stack.Item>
                        <ButtonGroup>
                          <Button size="slim" onClick={() => openTrackingModel(value)}>
                            {baseHelper.ucFirst(t("label.FulfillLineItem"))}
                          </Button>
                          <Button plain size="slim" onClick={() => history.push(`/order-slip/${match.params.id}`)}>
                            <Stack spacing="extraTight">
                              <Stack.Item>
                                <Icon source={NoteMajorMonotone} />
                              </Stack.Item>
                              <Stack.Item>{`${t("label.muteNotification.provider.packingSlip")}`}</Stack.Item>
                            </Stack>
                          </Button>
                        </ButtonGroup>
                      </Stack.Item>
                    </Stack>
                  </FormLayout>
                )}
              </Stack.Item>
              <Stack.Item>
                {`${t("label.qty")}:`}
                {value.quantity}
              </Stack.Item>
              <Stack.Item>
                {moneyFormat}
                {(lineTotalPrice && lineTotalPrice.toFixed(2)) || constant.value.ZERO}
              </Stack.Item>
            </Stack>
          </Card.Section>
        </div>
      );
    });
    return UnfulFilled;
  };

  return [renderUnfullfilled(), renderTotalAmount(), renderTracking()];
};

export default UnfulfilledLineItems;

class ShippingHelper {
  /**
   * @desc To get array of objects for rule (label and value)
   * @param shippingRules array of rules
   * @param shippingBands array of bands
   * @param destinations array of destinations
   * @param products array of products
   *
   * @return label and value
   *
   */

  getRuleLabel = (shippingRules, shippingBands, destinations, products) => {
    if (
      !(
        shippingRules &&
        shippingRules.length &&
        shippingBands &&
        shippingBands.length &&
        destinations &&
        destinations.length &&
        products &&
        products.length
      )
    ) {
      return [];
    }

    const ruleDetails =
      (shippingRules &&
        shippingRules.length &&
        shippingRules.map((rules) => {
          const { condition, destinationCode = "", productId = "", _id, shippingBandId } = rules;
          // eslint-disable-next-line no-underscore-dangle
          const ruleData = shippingBands.find((bands) => bands._id === shippingBandId);
          let destinationName = "";
          let productName = "";
          if (condition === "destination") {
            const destinationOptions =
              (destinationCode && destinations.map((destination) => destination.options).flat()) || false;
            const destinationData =
              (destinationOptions && destinationOptions.find((option) => option.value === destinationCode)) || false;
            destinationName = (destinationData && destinationData.label) || "";
          } else {
            const productData = (productId && products.find((product) => product.value === productId)) || false;
            productName = (productData && productData.label) || "";
          }
          const labelForRule = `${ruleData && ruleData.name} - ${
            condition === "destination" ? `Destination: ${destinationName}` : `Product: ${productName}`
          }`;
          return {
            value: _id,
            label: labelForRule,
          };
        })) ||
      [];

    return ruleDetails;
  };

  /**
   * @desc To get array of objects for offer(label and value)
   * @param shippingOffers array of offers
   * @param shippingBands array of bands
   * @param destinations array of destinations
   * @param products array of products
   *
   * @return label and value
   *
   */

  getOfferLabel(shippingOffers, shippingBands, destinations, products) {
    if (
      !(
        shippingOffers &&
        shippingOffers.length &&
        shippingBands &&
        shippingBands.length &&
        destinations &&
        destinations.length &&
        products &&
        products.length
      )
    ) {
      return [];
    }
    const offerDetail =
      (shippingOffers &&
        shippingOffers.length &&
        shippingOffers.map((offer) => {
          const { shippingBandId, _id, destinationCode = "", productId = "" } = offer;
          const bandData = (shippingBandId && shippingBands.find((bands) => bands.value === shippingBandId)) || false;
          const bandLabel = (bandData && bandData.label) || false;
          const destinationOptions =
            (destinationCode && destinations.map((destination) => destination.options).flat()) || false;
          const destinationData =
            (destinationOptions && destinationOptions.find((option) => option.value === destinationCode)) || false;
          const destinationName = (destinationData && destinationData.label) || "";

          const productData = (productId && products.find((product) => product.value === productId)) || false;
          const productName = (productData && productData.label) || "";

          const conditionLabel = this.getOfferConditionLabel(offer);
          const labelforOffer = `${bandLabel} -
        ${destinationName && ` ${destinationName},`} ${productName && `${productName},`} ${conditionLabel}`;

          return {
            label: labelforOffer,
            value: _id,
          };
        })) ||
      [];

    return offerDetail;
  }

  /**
   * @desc To get condition label for offer
   * @param offer offer details
   *
   * @return condition label
   *
   */

  getOfferConditionLabel = (offer) => {
    const { condition, productPrice = {}, productQuantity = {}, orderValue = {} } = offer;

    if (!condition) {
      return "";
    }

    let conditionValue = 0;
    let conditionCriteria = "";
    let conditionTitle = "";

    switch (condition) {
      case "productPrice":
        conditionValue = (productPrice && productPrice.value) || "";
        conditionCriteria = (productPrice && productPrice.criteria) || "";
        conditionTitle = (productPrice && "Product Price") || "";
        break;
      case "productQuantity":
        conditionValue = (productQuantity && productQuantity.value) || "";
        conditionCriteria = (productQuantity && productQuantity.criteria) || "";
        conditionTitle = (productQuantity && "Product Quantity") || "";
        break;
      case "orderValue":
        conditionValue = (orderValue && orderValue.value) || "";
        conditionCriteria = (orderValue && orderValue.criteria) || "";
        conditionTitle = (orderValue && "Order Value By Vendor") || "";
        break;
      default:
        conditionValue = 0;
        conditionCriteria = "";
        conditionTitle = "";
    }

    const criteria = (conditionCriteria && (conditionCriteria === "greaterThan" ? "Greater Than" : "Less Than")) || "";

    const conditionLabel = `${conditionTitle} ${criteria} ${conditionValue}`;
    return conditionLabel;
  };
}

export default new ShippingHelper();

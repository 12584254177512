import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// Import Polaris Components
import { Stack, Modal } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import gql
import {
  SYNC_INVENTORY_VENDOR,
  DISCONNECT_SHOP,
  SYNC_WOOCOMMERCE_VENDOR_PRODUCT,
} from "app/products/apollo/mutations/connect";
import { DISCONNECT_WOOCOMMERCE_SHOP } from "app/products/apollo/mutations/wooCommerceConnect";

// import report
import { QuickAction, QuickReport } from "app/reports";
import { AddProductMajorMonotone, ImportStoreMajorMonotone, OnlineStoreMajorMonotone, StoreMajorMonotone } from "@shopify/polaris-icons";

const {
  gql: { SYNC_INVENTORY_VENDOR: SYNC_INVENTORY_VENDOR_GQL, DISCONNECT_SHOP: DISCONNECT, DISCONNECT_WOOCOMMERCE_SHOP: DISCONNECT_WOO_SHOP },
} = constant;

const Widget = (props) => {
  const { setBanner = () => {}, cms } = props;
  const { currentUser = {}, history = {}, currentUserRefetch = () => {} } = useContext(PrivateContext);
  const { t } = useTranslation();

  const { shop = null } = currentUser;
  const { wooCommerce: wooCommerceConfig = {} } = (currentUser && currentUser.storeConfig) || {};
  const { shop: woocommerceVendorShop = "" } = wooCommerceConfig || {};

  const [syncInventoryVendor, { loading: syncInventoryVendorLoading }] = useMutation(SYNC_INVENTORY_VENDOR);
  const [syncWooCommerceVendorProduct, { loading: syncWooCommerceVendorProductLoading }] = useMutation(
    SYNC_WOOCOMMERCE_VENDOR_PRODUCT
  );
  const [disconnectShop, { loading: disconnectLoading }] = useMutation(DISCONNECT_SHOP);
  const [disconnectWooCommerceShop, { loading: disconnectWooCommerceLoading }] = useMutation(
    DISCONNECT_WOOCOMMERCE_SHOP
  );

  const [isDisconnectActive, setIsDisconnectActive] = useState(false);
  const [isWooCommerceDisconnectActive, setIsWooCommerceDisconnectActive] = useState(false);

  if (!(shop || woocommerceVendorShop)) {
    return (
      <>
        <QuickAction />
        <QuickReport />
      </>
    );
  }

  const syncProducts = async () => {
    try {
      const response = await syncInventoryVendor();
      const responseError = baseHelper.getResponseError(response.data, SYNC_INVENTORY_VENDOR_GQL);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      const onAction = () => history.push("/products/shopify");
      const bannerAction = {
        onAction,
        content: t("message.Product.SyncProduct.ViewShopifyProduct"),
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: t("message.Product.SyncProduct.ProductSyncBackground"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  const syncWooCommerceProducts = async () => {
    try {
      const response = await syncWooCommerceVendorProduct();
      const responseError = baseHelper.getResponseError(response.data, "syncWooCommerceVendorProduct");
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      const onAction = () => history.push("/products/woocommerce");
      const bannerAction = {
        onAction,
        content: `${cms("section.connect.button.viewWoocommerceProduct")}`,
      };
      setBanner({
        isOpen: true,
        status: "info",
        title: t("message.Product.SyncProduct.ProductSyncBackground"),
        action: bannerAction,
      });
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  const disconnectShopConfirm = async () => {
    try {
      const response = await disconnectShop({
        variables: { input: { id: currentUser.id } },
      });
      setIsDisconnectActive(!isDisconnectActive);
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: "success", title: t("message.ProductAdd.Disconnected") });
      setTimeout(() => {
        currentUserRefetch();
      }, 1000);
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  const disconnectWooCommerceShopConfirm = async () => {
    try {
      const response = await disconnectWooCommerceShop({
        variables: { input: { id: currentUser.id } },
      });
      setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive);
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT_WOO_SHOP);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: "success", title: `${cms("section.connect.message.success.disconnected")}` });
      setTimeout(() => {
        currentUserRefetch();
      }, 1000);
    } catch (exception) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(exception) });
    }
  };

  const linkData = [
    {
      action: () => history.push("/products/add"),
      caption: "Add your products",
      id: "addProduct",
      icon: AddProductMajorMonotone,
      label: "Add Products",
      shop: true,
    },
  ];

  if (shop) {
    linkData.unshift(
      {
        action: () => syncProducts(),
        caption: "Import products from your store",
        id: "importProduct",
        label: "Import Products from Store",
        icon: ImportStoreMajorMonotone,
        loading: syncInventoryVendorLoading,
        shop,
      },
      {
        action: () => history.push("/products/shopify"),
        caption: "View your store products",
        id: "storeProduct",
        label: "E-commerce Store Products",
        icon: OnlineStoreMajorMonotone,
        shop,
      },
      {
        action: () => setIsDisconnectActive(!isDisconnectActive),
        caption: `Disconnect from your ${shop} Shop`,
        id: "disconnectShop",
        label: `Disconnect ${shop} Shop`,
        icon: StoreMajorMonotone,
        shop,
      }
    );
  }

  if (woocommerceVendorShop) {
    linkData.unshift(
      {
        action: () => syncWooCommerceProducts(),
        caption: "Import products from your WooCommerce store",
        id: "importProduct",
        icon: ImportStoreMajorMonotone,
        label: "Import Products from your WooCommerce Store",
        loading: syncWooCommerceVendorProductLoading,
        shop: woocommerceVendorShop,
      },
      {
        action: () => history.push("/products/woocommerce"),
        caption: "View your store products",
        id: "storeProduct",
        icon: OnlineStoreMajorMonotone,
        label: "WooCommerce Store Products",
        shop: woocommerceVendorShop,
      },
      {
        action: () => setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive),
        caption: `Disconnect from ${woocommerceVendorShop} Shop`,
        id: "disconnectWooCommerceShop",
        icon: StoreMajorMonotone,
        label: `Disconnect ${woocommerceVendorShop} Shop`,
        shop: woocommerceVendorShop,
      }
    );
  }

  return (
    <>
      <Stack.Item>
        <Modal
          open={isDisconnectActive}
          onClose={() => setIsDisconnectActive(!isDisconnectActive)}
          title={t("label.ProductAdd.Disconnect")}
          primaryAction={{
            content: `${t("label.ProductAdd.disconnect")} ${currentUser.shop}`,
            onAction: disconnectShopConfirm,
            destructive: true,
            loading: disconnectLoading,
          }}
          secondaryActions={[
            {
              content: t("label.ProductAdd.Cancel"),
              onAction: () => setIsDisconnectActive(!isDisconnectActive),
            },
          ]}
        >
          <Modal.Section>{`${t("message.ProductAdd.DisconnectShop")}`}</Modal.Section>
        </Modal>
      </Stack.Item>

      <Stack.Item>
        <Modal
          open={isWooCommerceDisconnectActive}
          onClose={() => setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive)}
          title={t("label.ProductAdd.Disconnect")}
          primaryAction={{
            content:  `${cms("section.connect.label.disconnectShop")}`,
            onAction: disconnectWooCommerceShopConfirm,
            destructive: true,
            loading: disconnectWooCommerceLoading,
          }}
          secondaryActions={[
            {
              content: `${cms("section.connect.button.cancel")}`,
              onAction: () => setIsWooCommerceDisconnectActive(!isWooCommerceDisconnectActive),
            },
          ]}
        >
          <Modal.Section>{`${cms("section.connect.label.disconnectWoocommerceShop")}`} <b>{`${woocommerceVendorShop}`}</b>{"?"}</Modal.Section>
        </Modal>
      </Stack.Item>

      <QuickAction link={linkData} />
      <QuickReport />
    </>
  );
};

Widget.propTypes = {
  setBanner: PropTypes.func.isRequired,
};

export default Widget;
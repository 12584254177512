import React from "react";

// import components
import Header from "./subFeatureItems/header";
import HeaderText from "./subFeatureItems/headerText";
import Button from "./subFeatureItems/button";
import ButtonText from "./subFeatureItems/buttonText";

const Color = () => {
  return (
    <>
      <Header />
      <HeaderText />
      <Button />
      <ButtonText />
    </>
  );
};

export default Color;

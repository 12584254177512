import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MediaCard, List } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const { onboardingSteps } = constant;

const Welcome = (props) => {
  const { setSelectedTab } = props;
  const { currentUserRefetch = () => {}, cms = {} } = useContext(PrivateContext);
  return (
    <MediaCard
      title={cms("common.onboarding.vendorWelcome.title")}
      primaryAction={{
        content: cms("common.onboarding.vendorWelcome.button"),
        onAction: () => {
          setSelectedTab(onboardingSteps.UPDATE_PROFILE);
          currentUserRefetch();
        },
      }}
      description={[
        <>
          <p>{cms("common.onboarding.vendorWelcome.description")}</p>
          <br />
          <p>
            <List type="number">
              {cms("common.onboarding.vendorWelcome.list") &&
                cms("common.onboarding.vendorWelcome.list").map((list) => <List.Item>{list}</List.Item>)}
            </List>
          </p>
          <br />
          <p>{cms("common.onboarding.vendorWelcome.paragraph")}</p>
        </>,
      ]}
    >
      <img
        alt=""
        width="100%"
        height="100%"
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        src="https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg"
      />
    </MediaCard>
  );
};

Welcome.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default Welcome;

// import packages
import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Layout,
  FormLayout,
  Form,
  Caption,
  List,
  Button,
  Banner as PolarisBanner,
  Card,
  TextContainer,
} from "@shopify/polaris";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import gql
import { GET_VENDOR_PRODUCT_TYPES } from "app/setup/apollo/queries";

// import hoc
import { withFeature } from "lib/hoc";

// import components
import { Banner, Spinner, SkeletonAnnotated } from "lib/components";
import AdvanceItem from "./advanceLine";

// import propTypes
// import { advanceProp } from "../../props";

const AdvanceCommission = (props) => {
  const {
    PRODUCT_TYPE,
    gql: { GET_VENDOR_PRODUCT, UPDATE_COMMISSION: UPDATE_COMMISSION_TEXT },
    value: { MAX_PERCENTAGE },
  } = constant;
  const { cms = {} } = useContext(PrivateContext);

  const { advanceTermData, module: componentModule, bannerData, setValues, setIsSubmit } = props;
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [state, setState] = useState({
    count: 1,
    advancedValue: [],
  });
  const isReturn = componentModule === "return";
  const [banner, setBanner] = useState({ isOpen: false, status: "", title: "", message: "" });
  const { loading, data: vendorAndProductTypesData } = useQuery(GET_VENDOR_PRODUCT_TYPES);
  useEffect(() => {
    if (bannerData && bannerData.isOpen) {
      setBanner(bannerData);
    }
  }, [bannerData]);
  useEffect(() => {
    if (loading) {
      return;
    }
    const responseData = baseHelper.getResponseData(vendorAndProductTypesData, GET_VENDOR_PRODUCT);
    const responseError = baseHelper.getResponseError(vendorAndProductTypesData, GET_VENDOR_PRODUCT);
    const { types = [], supplierRows = [] } = responseData || {};
    if (responseError || !responseData || (!types.length && !supplierRows.length)) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: "Advance option unavailable",
      });
      return;
    }

    if (!supplierRows.length) {
      setBanner({
        isOpen: true,
        status: "warning",
        title: "Vendors unavailable",
      });
    }

    const allSupplierValues = supplierRows
      .filter((item) => item.brandName && item)
      .map(({ _id: supplierId, brandName }) => ({
        label: brandName,
        value: supplierId,
      }));
    setAllSuppliers(allSupplierValues);
  }, [vendorAndProductTypesData, loading, GET_VENDOR_PRODUCT, cms]);

  useEffect(() => {
    const advancedValueLength = advanceTermData.length || 0;
    const isEqualArray = baseHelper.isItemEqual(advanceTermData, state.advancedValue);
    if (advancedValueLength && !isEqualArray) {
      setState({
        count: advancedValueLength,
        advancedValue: advanceTermData,
      });
    }
  }, [PRODUCT_TYPE, advanceTermData, state.advancedValue]);

  if (loading) {
    return (
      <Layout.Section>
        <SkeletonAnnotated />
      </Layout.Section>
    );
  }

  const updateVal = (option, val, item) => {
    const returnOrDays = isReturn ? "returnPeriod" : "days";
    const returnOrType = isReturn ? "isReturn" : "type";
    setBanner({ isOpen: false, status: "", title: "" });
    const { advancedValue } = state;
    let duplicate = false;
    if (option === "option" && advancedValue[item]) {
      advancedValue[item].vendorId = null;
    }
    if (option === "vendorId") {
      advancedValue.forEach((advanceData) => {
        if (advanceData.vendorId === val && advancedValue[item]) {
          advancedValue[item].vendorId = null;
          setBanner({ isOpen: true, status: "critical", title: "Row already exists" });
          duplicate = true;
        }
        return null;
      });
    }
    if (duplicate) {
      return;
    }
    if (option === returnOrDays && advancedValue[item]) {
      if (val.length && !baseHelper.validatePositiveNumericValues(val)) {
        return;
      }
      advancedValue[item][returnOrDays] = val;
    } else if (advancedValue[item]) {
      advancedValue[item][option] = val;
    }

    if (option === returnOrType) {
      if (val === "preFulfillment" || val === false) {
        advancedValue[item][returnOrDays] = null;
      }
    }
    setState({ ...state, advancedValue });
    setValues(advancedValue);
    setIsSubmit(false);
  };

  const add = () => {
    const { advancedValue, count } = state;
    const returnOrType = isReturn ? "isReturn" : "type";
    const returnOrDays = isReturn ? "returnPeriod" : "days";
    const row = { [returnOrType]: null, [returnOrDays]: null, option: null, vendorId: null };
    advancedValue.push(row);
    setState({ ...state, count: count + 1, advancedValue });
  };

  const removeItem = (item) => {
    const { advancedValue } = state;
    advancedValue.splice(item, 1);
    setState({ ...state, count: state.count - 1, advancedValue });
  };

  const renderAdvanceItems = () => {
    const { count, advancedValue } = state;
    return (
      <AdvanceItem
        advancedValue={advancedValue}
        allSuppliers={allSuppliers}
        count={count}
        removeItem={removeItem}
        updateVal={updateVal}
        isReturn={isReturn}
      />
    );
  };

  const paymentCaption = [
    {
      title: "Pre fulfilment",
      description: "You will pay your vendors once they accept the order but before they ship out the product.",
    },
    { title: "Post fulfilment", description: "You will pay your vendors once they add shipment tracking information." },
    {
      title: "Post returns",
      description:
        "expiry Post returns period expiry - you will pay your vendors once the product has been delivered has been delivered and the returns period has elapsed.",
    },
  ];

  const returnCaption = [
    { title: "Advance returns by vendor", description: "is set on the basis of a specific vendor." },
    { title: "", description: "Please enter every value in each row, else it will not be saved." },
  ];

  const captions = isReturn ? returnCaption : paymentCaption;
  const renderCaptions = () => {
    return captions.map((caption, keyIndex) => {
      const keyName = `caption_${keyIndex}`;
      return (
        <List.Item key={keyName}>
          <Caption>
            <b>{caption.title}</b>
            {` ${caption.description}`}
          </Caption>
        </List.Item>
      );
    });
  };

  const { advancedValue } = state || [];

  const noValues = !(allSuppliers.length || advancedValue.length < 1);
  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "", message: "" });

  return (
    <Layout.AnnotatedSection
      title={isReturn ? "Advance Return Term" : "Advance Payment Term"}
      description={
        isReturn
          ? "You can set returns terms for specific vendor of yours. There's no limit how many you can add, " +
            "so go knock yourself out! "
          : "Select when you pay your vendors to complete the configuration of your vendors payment schedule"
      }
    >
      <Card title={isReturn ? "Advance Return Term" : "Advance Payment Term"}>
        <Card.Section>
          <TextContainer>
            {isReturn
              ? "You can set returns terms for specific vendor of yours. There's no limit how many you can add, " +
                "so go knock yourself out! "
              : "Select when you pay your vendors to complete the configuration of your vendors payment schedule"}
          </TextContainer>
          <br />
          {banner.isOpen && (
            <>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                isScrollTop={isReturn}
                onDismiss={() => dismissBanner()}
              >
                {banner.message}
              </Banner>
              <br />
            </>
          )}
          <Form>
            <FormLayout>
              {!noValues && renderAdvanceItems()}
              {!noValues && (
                <Button plain id="addLink" onClick={add}>
                  {cms("common.button.addMore")}
                </Button>
              )}
            </FormLayout>
            <br />
            <PolarisBanner status="info">
              <p>{cms("common.label.option")}</p>
              <br />
              <p>
                <List type="bullet">{renderCaptions()}</List>
              </p>
            </PolarisBanner>
          </Form>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default withFeature(AdvanceCommission, { feature: constant.ADVANCE_COMMISSION });

import React, { useState, useContext } from "react";
import { Banner, Button, Card, Heading, Link, Stack, Spinner } from "@shopify/polaris";

import AutoComplete from "lib/components/autocomplete/autocomplete";

// import constant
import constants from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import Address from "./address";

const Vendor = (props) => {
  const { cms } = useContext(PrivateContext);
  const {
    addresses,
    countries,
    destinations,
    loading,
    selectedVendors,
    setAddressValueOption,
    setAdvanceError,
    setError,
    setIsSubmit,
    setSelectedVendors,
    vendors,
  } = props;

  const [addressValue, setAddressValue] = useState(
    addresses && addresses.length ? addresses : [{ address: {}, vendorIds: [] }]
  );

  if (addresses && addresses.length) {
    setAddressValueOption(addresses);
  }

  const addItem = () => {
    const { length } = addressValue;

    if (length) {
      const line = addressValue[length - 1];
      const keys = Object.keys(line.address);
      let isInvalid = false;
      if (!(line.vendorIds && line.vendorIds.length)) {
        isInvalid = true;
      }
      if (!(line.address && line.address.address)) {
        isInvalid = true;
      }
      keys.forEach((key) => {
        if (!line.address[key]) {
          isInvalid = true;
        }
      });
      if (isInvalid) {
        setAdvanceError(true);
        return false;
      }
    }

    const row = { address: {}, vendorIds: [] };
    const updatedAddressValue = [...addressValue];
    updatedAddressValue.push(row);
    setAddressValue(updatedAddressValue);
    setAddressValueOption(updatedAddressValue);
  };

  const removeItem = (item) => {
    const updatedAddressValue = [...addressValue];
    const { vendorIds } = addresses[item];
    const vendors = selectedVendors.filter((id) => vendorIds.indexOf(id) === -1);
    updatedAddressValue.splice(item, 1);
    setSelectedVendors(vendors);
    setAddressValue(updatedAddressValue);
    setAddressValueOption(updatedAddressValue);
    setIsSubmit(true);
  };

  const updateAddress = (field, value, item) => {
    const country = "";

    if (field === "vendorIds") {
      addresses[item][field] = value;
    } else {
      if (field === "countryCode") {
        const existCountry = countries.find((country) => country.value === value);
        const { label = "" } = existCountry || false;
        addresses[item].address.country = label;
      }
      addresses[item].address[field] = value;
    }

    const selectedVendors = [];
    addresses.forEach((address) => {
      selectedVendors.push(address.vendorIds || []);
    });

    setAddressValue([...addresses]);
    setSelectedVendors([...new Set([...selectedVendors.flat()])]);
    setAddressValueOption(addresses);
    setIsSubmit(true);
  };

  const renderVendorFormLayout = () => {
    return addresses.map((addressItem, item) => {
      if (!addressItem) {
        return null;
      }

      const addressText = `${cms("helpText.additionalConsignment")}${item}`;
      const { vendorIds = [], error = {} } = addressItem;
      const availableVendors = vendors.filter(({ value }) => {
        return vendorIds.includes(value) || !selectedVendors.includes(value);
      });
      return (
        <Card>
          <Card.Section>
            <Stack>
              <Stack.Item fill>
                <Heading>{cms("label.consignmentAddress")}</Heading>
              </Stack.Item>
              <Stack.Item>
                <Button destructive onClick={() => removeItem(item)} size="slim">
                  <i className="far fa-trash fa-lg" style={{ color: "white" }} />
                </Button>
              </Stack.Item>
            </Stack>
          </Card.Section>
          <Card.Section id={item}>
            <AutoComplete
              label={cms("label.vendor")}
              placeholder={cms("placeholder.vendor")}
              values={availableVendors}
              selected={(addressItem && addressItem.vendorIds) || []}
              onChange={(val) => updateAddress("vendorIds", val, item)}
              minimumSearchLength={constants.value.MIN_SEARCH_LENGTH}
              error={
                (setError &&
                  !(addressItem && addressItem.vendorIds && addressItem.vendorIds.length) &&
                  setError.vendorIds) ||
                false
              }
            />
          </Card.Section>
          <Address
            id={addressText}
            addressData={(addressItem && addressItem.address) || {}}
            destinations={destinations}
            handleChange={(field, val) => updateAddress(field, val, item)}
            setError={item === 0 ? (vendorIds && vendorIds.length && setError) || false : setError}
          />
        </Card>
      );
    });
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      {renderVendorFormLayout()}
      <br />
      <Banner>
        <Link onClick={addItem}>{cms("label.addAddress")}</Link>
      </Banner>
    </>
  );
};

export default Vendor;

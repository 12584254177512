const cms = {
  message: {
    paraTag: {
      para1: "Important information regarding connecting your Shopify store to our Vendor Live Connect by Marketcube",
      para2: `makes it easy for you to sell your products in the
        marketplace and fulfil customer orders; once enabled, it lets you sync the products from your store to
        the marketplace and keep them up to date automatically (incl. stock levels).`,
      para3: "With ",
      para4: ` your marketplace orders instantly sync to your Shopify
      store, where you can see, fulfil and report on them as usual, hence eliminating the need for manual
      checks or updates.`,
      para5: "For ",
      para6: ` to work it needs to access your Shopify stores account
      data, by requesting these specific permissions:`,
      para7: `We ask for this permission to access information about your products in your store, which we sync into
      our platform so that we can allow the marketplace operator to publish to their store.`,
      para8: ` We ask for this permission to access information about your create and manage orders in your store,
      specifically generated via the marketplace, We do not track on store any order in your store that are
      not linked to the marketplace.`,
      para9: `will never share, own or manipulate your data; it will solely
      pass it between the marketplace and your online store.`,
      para10: `All your data (incl. orders and customers) that’s not created in the marketplace will not be accessed
      by the marketplace or any third party apps, including `,
      para11: `. Vendor Live Connect by Marketcube will not own or share
      your customer data with third parties and will never target or contact your customers for any
      purposes.`,
      para12: `We ask for this permission to access information about your Shopify store,
         this enables us to create a connection between us and your store.`,
      para13: `We ask for this permission to allow us to create an customer in your store, this enables is to then
      create an order in your store. We create the marketplace as the customer in your store, we do not use
      the actual marketplace’s customer. Similarly we do not under and circumstance request any of your
      existent customer information in to the marketplace either`,
      para14: `We ask for this permission to access information about your Shopify store,
       this enables us to create a connection between us and your store.`,
    },
    boldTag: {
      b1: "Vendor Live Connect by Marketcube ",
      b2: "Please note ",
    },
    subHeading: {
      sub1: "Vendor Shopify account data ",
      sub2: "Manage products ",
      sub3: "Manage orders ",
      sub4: "Manage customer ",
    },
    acceptTerm: "I understand and agree to the Terms of service for Vendor Live Connect by Marketcube",
  },
};
export default cms;

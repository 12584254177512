import React, { useContext } from "react";
import { Caption, ResourceList, Stack, TextStyle } from "@shopify/polaris";
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { AdvanceShippingContext } from "../context/context";

// import component
import Actions from "./action";

// import propType
import { ruleItemProp } from "./props";

const RuleItem = (props) => {
  const { isVendorAllowed } = useContext(AdvanceShippingContext);
  const { currentUser = {} } = useContext(PrivateContext);
  const { moneyFormat, item = {}, band = [], destinations = [], products = [], editModal, deleteModal, t } =
    props || {};
  const {
    id,
    shippingBandId,
    price,
    description,
    condition,
    destinationCode,
    productId,
    priceType,
    isShippingDisabled,
  } = item;
  const isDestination = condition === constant.DESTINATION;
  const isProduct = condition === constant.PRODUCT;

  if (item.condition === null) return null;
  const shippingBand =
    (band.length > 0 && band.find((key) => key.value === shippingBandId || key.parentId === shippingBandId)) || false;

  const { options: countries = [] } =
    destinations.find(
      (destination) => destination && destination.options.find((option) => option.value === destinationCode)
    ) || {};
  const destination = countries.find((country) => country.value === destinationCode);
  const product = products.find((pItem) => pItem.value === productId);
  const isFreeOption = priceType === constant.FREE;
  const priceTypeText = isFreeOption ? t("label.Free") : t("label.Fixed");

  const { _id: currentUserId = null } = currentUser || {};
  const { userId: ruleCreatedBy = null } = item || {};
  const shortcutActions = [
    {
      content: t("button.advanceShippingList.primaryAction"),
      onAction: () => editModal(item),
      disabled: !isVendorAllowed,
    },
    {
      content: t("button.advanceShippingList.secondaryAction"),
      destructive: true,
      onAction: () => deleteModal(item),
      disabled: !isVendorAllowed || ruleCreatedBy !== currentUserId,
    },
  ];

  return (
    <ResourceList.Item id={id} accessibilityLabel={`${t("label.ViewDetails")} `}>
      <Stack alignment="leading">
        <Stack.Item fill>
          <TextStyle variation="strong">{(shippingBand && shippingBand.label) || ""}</TextStyle>
          <Caption>{description}</Caption>
          <br />
          {isDestination && (
            <Caption>{`${t("label.Destination")}: ${(destination && destination.label) || ""}`}</Caption>
          )}
          {isProduct && product && <Caption>{`${t("label.Products")}: ${(product && product.label) || ""}`}</Caption>}
          <Caption>{`${t("label.PriceType")}: ${priceTypeText}`}</Caption>
          <Caption>{`${t("label.Cost")}: ${moneyFormat} ${isFreeOption ? "0" : price}`}</Caption>
          <Caption>
            {`${t("label.Shipping")}: ${isShippingDisabled ? t("label.Disabled") : t("label.Enabled")}`}
          </Caption>
        </Stack.Item>
        <Actions actions={shortcutActions} />
      </Stack>
    </ResourceList.Item>
  );
};
RuleItem.propTypes = ruleItemProp.type;
export default RuleItem;

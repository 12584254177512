import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Tooltip, Button, ButtonGroup } from "@shopify/polaris";

import constant from "lib/constant/constant";

const propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const Actions = (props) => {
  const { t } = useTranslation();
  const tooltip = {
    isNewOrder: t("tooltip.isNewOrder"),
    isPaid: t("tooltip.isPaid"),
    isPending: t("tooltip.isPending"),
    isRejectedOrder: t("tooltip.isRejectedOrder"),
  };
  const { actions } = props;
  if (!(Array.isArray(actions) && actions.length)) {
    return null;
  }

  const filterAction = actions.filter((item) => item);
  const buttons = filterAction.map((item) => {
    const button = (
      <Button
        size={constant.SLIM}
        destructive={item.destructive || false}
        disabled={item.disabled || false}
        loading={!!item.loading}
        onClick={() => item.onAction()}
        key={item.content}
      >
        {item.content}
      </Button>
    );

    return item.tooltip ? <Tooltip content={tooltip[item.tooltip]}>{button}</Tooltip> : button;
  });
  return <ButtonGroup>{buttons}</ButtonGroup>;
};

Actions.propTypes = propTypes;

export default Actions;

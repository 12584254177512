import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { TextField, Select, Checkbox, Modal, Heading, Caption, Stack, Badge } from "@shopify/polaris";

import { useTranslation } from "react-i18next";

// import mutation
import { LINE_RETURN } from "app/orders/apollo/mutations";

// import from lib
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import banner
import { Banner } from "lib/components";

// import yup
import validate from "app/orders/modules/operator/features/lineReturn/yup";

// import props
import operatorLineReturnProps from "./props";

const OperatorLineReturn = (props) => {
  const { gql, value, displayStatus, returnReason, REASON, NOTE, PRODUCT, PRODUCT_UC_FIRST } = constant;
  const { oldCMS: cms } = useContext(PrivateContext);
  const { message = {}, label = {}, button = {}, placeHolder = {} } = cms;
  const { t } = useTranslation();
  const { showModal, orderToInitiate, onClose } = props;
  const { return: returnStatus } = orderToInitiate;
  const isReturnObject = returnStatus && Object.keys(returnStatus).length;
  // react state
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [vendorNote, setVendorNote] = useState("");
  const [isSellerManaged, setIsSellerManaged] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [acceptReturnLoading, setAcceptReturnLoading] = useState(false);
  const [requestReturnLoading, setRequestReturnLoading] = useState(false);
  const [returnInitiateLoading, setReturnInitiateLoading] = useState(false);
  const [buttonValue, setButtonValue] = useState(isReturnObject);
  const [isInitiate, setIsInitiate] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    tittle: "",
  });

  // gql state
  const [lineReturn] = useMutation(LINE_RETURN);

  useEffect(() => {
    if (returnStatus) {
      setReason(returnStatus.reason);
      setIsSellerManaged(returnStatus.isSellerManaged);
      setVendorNote(returnStatus.vendorNote);
      if (isInitiate) {
        setButtonValue(false);
      }
    }
  }, [returnStatus, isInitiate]);

  const setLoadingState = (isRequestAccept, loadingState) => {
    if (isRequestAccept) {
      setAcceptReturnLoading(loadingState);
      return;
    }
    if (isReturnObject) {
      setRequestReturnLoading(loadingState);
      return;
    }
    setReturnInitiateLoading(loadingState);
  };

  const setSuccessBanner = (data) => {
    if (data.isAccept) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: message.returnAccepted });
      return;
    }
    if (isReturnObject) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: message.returnReinitiate });
      return;
    }
    if (data.isInitiate) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: message.lineReturnSuccess });
    }
  };

  const orderLineReturn = async (requestData, isRequestAccept) => {
    try {
      const res = await lineReturn({
        variables: { input: requestData },
      });

      const responseData = baseHelper.getResponseData(res.data, gql.LINE_RETURN);
      const errorData = baseHelper.getResponseError(res.data, gql.LINE_RETURN);
      if (!errorData) {
        setSuccessBanner(requestData);
        if (requestData.isInitiate || requestData.isAccept) {
          setTimeout(() => {
            onClose();
          }, 2000);
        }
      }
      if (!responseData) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorData });
      }
    } catch (err) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: t("error.common.somethingWentWrong") });
    }
    setLoadingState(isRequestAccept, false);
  };

  const reasons = [
    {
      label: label.productNotRequired,
      value: returnReason.NOT_NEEDED,
    },
    {
      label: label.productNotAsAdvertised,
      value: returnReason.NOT_AS_ADVERTISED,
    },
    {
      label: label.productNotAsSold,
      value: returnReason.NOT_AS_SOLD,
    },
    {
      label: label.productFaulty,
      value: returnReason.FAULTY,
    },
    {
      label: label.others,
      value: returnReason.OTHER,
    },
  ];

  const handleSelectChange = (selectedValue) => setReason(selectedValue);

  const handleNoteChange = (noteText) => setNote(noteText);

  const handleSellerManaged = () => setIsSellerManaged(!isSellerManaged);

  const handleValidate = async (field, event) => {
    const validationError = await validate(field, event.target.value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const isDisabled = () => {
    const isAllFieldEmpty = !(reason && note);
    return isAllFieldEmpty;
  };

  const renderTitle = (item) => {
    if (!item) {
      return null;
    }
    const { orderNumber, shopifyLineItemId } = item;

    return (
      <>
        <Heading>{`#${orderNumber}`}</Heading>
        <Caption>
          {`${t("label.LineItemId")} : `}
          <span>{`#${shopifyLineItemId}`}</span>
        </Caption>
      </>
    );
  };
  const onClickRequestReturn = (isRequestAccept = false) => {
    setLoadingState(isRequestAccept, true);
    const { orderId, shopifyLineItemId } = orderToInitiate;
    const reqData = {
      isSellerManaged,
      orderId,
      orderLineItemId: shopifyLineItemId,
      reason,
      note,
      isInitiate: !isRequestAccept,
      isAccept: isRequestAccept,
    };

    orderLineReturn(reqData, isRequestAccept);
    return true;
  };

  const renderPrimaryAction = () => {
    const initiateButton = [
      {
        content: button.requestReturn,
        onAction: () => {
          setIsInitiate(true);
          onClickRequestReturn();
        },
        disabled: isDisabled(),
        loading: returnInitiateLoading,
      },
    ];
    const requestButton = [
      {
        content: button.accept,
        onAction: () => onClickRequestReturn(true),
        disabled: isDisabled(),
        loading: acceptReturnLoading,
      },
      {
        content: button.rejectRequestAgain,
        onAction: () => onClickRequestReturn(),
        disabled: isDisabled(),
        loading: requestReturnLoading,
      },
    ];
    if (buttonValue) {
      return requestButton;
    }
    return initiateButton;
  };
  return (
    <Modal
      open={showModal}
      onClose={onClose}
      title={renderTitle(orderToInitiate)}
      primaryAction={renderPrimaryAction()}
      secondaryActions={{
        content: label.cancel,
        onAction: () => onClose(),
      }}
    >
      {banner && banner.isOpen && (
        <Banner
          isOpen={banner.isOpen}
          status={banner.status}
          title={banner.title}
          onDismiss={() => setBanner({ ...banner, isOpen: false })}
        />
      )}
      <Modal.Section>
        <Stack>
          <Stack.Item fill>
            <h2>{`${PRODUCT_UC_FIRST} - ${(orderToInitiate && orderToInitiate.title) || PRODUCT}`}</h2>
            <h5>{`${label.supplier} - ${orderToInitiate.vendor}`}</h5>
          </Stack.Item>
          <Stack.Item>
            <Badge>{displayStatus.FULFILLED || orderToInitiate.fulfillmentStatus}</Badge>
          </Stack.Item>
        </Stack>
        <br />
        <Select
          placeholder={placeHolder.reason}
          options={reasons}
          onChange={handleSelectChange}
          value={reason}
          onBlur={(e) => handleValidate(REASON, e)}
          error={errorMessage && errorMessage.reason}
        />
        <br />
        {vendorNote && (
          <TextField
            placeholder={placeHolder.noteAdd}
            value={`${label.vendorNoteColon} ${vendorNote}`}
            disabled
            multiline
          />
        )}
        <br />
        <TextField
          label={label.note}
          labelHidden
          placeholder={placeHolder.noteAdd}
          value={note}
          onChange={(noteValue) => handleNoteChange(noteValue)}
          onBlur={(e) => handleValidate(NOTE, e)}
          error={errorMessage && errorMessage.note}
          maxLength={value.MAX_CHARACTER}
          multiline
        />
        <br />
        <Checkbox label={label.sellerManaged} checked={isSellerManaged} onChange={handleSellerManaged} />
      </Modal.Section>
    </Modal>
  );
};

OperatorLineReturn.propTypes = operatorLineReturnProps.type;

export default OperatorLineReturn;

import React from "react";

import { SelectPlan } from "app/plans";
import {
  LinkRegister,
  Login,
  Register,
  ResendVerifyEmail,
  ResetPassword,
  VerifyEmail,
  Install,
  ShopifyPayment,
  ResetPasswordConfirmation,
  RegisterRole,
  GeneratePassword,
} from "app/public";

import PublicRoute from "./publicRoute";

const routes = [
  { path: "/join/:id/:shop", component: LinkRegister },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/register/role", component: RegisterRole },
  { path: "/resend-verify-email", component: ResendVerifyEmail },
  { path: "/reset-password", component: ResetPassword },
  { path: "/reset-password/:token", component: ResetPasswordConfirmation },
  { path: "/shopify/install", component: Install },
  { path: "/shopify/plan", component: SelectPlan },
  { path: "/shopify/payment", component: ShopifyPayment },
  { path: "/verify-email/:token", component: VerifyEmail },
  { path: "/otp", component: GeneratePassword },
];

const publicRoutes = routes.map((route) => (
  <PublicRoute exact path={route.path} component={route.component} key={route.path} />
));

export default publicRoutes;

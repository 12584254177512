import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

// import helpers
import { storageHelper } from "lib/helpers";

import PublicContainer from "./publicContainer";

const PublicRoute = (props) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(routeProps) => {
        return storageHelper.get("token") ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location },
            }}
          />
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <PublicContainer component={Component} {...routeProps} />
        );
      }}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PublicRoute;

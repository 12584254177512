import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { TextField, Select, Checkbox, Modal, Heading, Caption, Stack, Badge } from "@shopify/polaris";

import { useTranslation } from "react-i18next";

// import mutation
import { LINE_RETURN } from "app/orders/apollo/mutations";

// import from lib
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import banner
import { Banner } from "lib/components";

// import yup
import validate from "app/orders/modules/provider/features/lineReturn/yup";

// import props
import providerLineReturnProps from "./props";

const ProviderLineReturn = (props) => {
  const { gql, value, displayStatus, returnReason, REJECT, ACKNOWLEDGE, NOTE, PRODUCT, PRODUCT_UC_FIRST } = constant;
  const { oldCMS: cms } = useContext(PrivateContext);
  const { message = {}, label = {}, button = {}, placeHolder = {} } = cms;

  const { t } = useTranslation();
  const { showModal, orderToInitiate, onClose } = props;
  const { return: returnData, orderId, shopifyLineItemId } = orderToInitiate;
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [isSellerManaged, setIsSellerManaged] = useState(false);
  const [inventoryCheck, setInventoryCheck] = useState(false);
  const [isAcknowledge, setIsAcknowledge] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    tittle: "",
  });

  const [lineReturn] = useMutation(LINE_RETURN);

  const [acknowledgeLoading, setAcknowledgeLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const setSuccessBanner = (data) => {
    if (data.isAccept) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: message.returnAccepted });
      return;
    }
    if (data.isAcknowledge) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: message.returnAcknowledge });
      return;
    }
    if (data.isReject) {
      setBanner({ isOpen: true, status: constant.SUCCESS, title: message.returnRejected });
    }
  };

  const orderLineReturn = async (requestData, IsButtonLoading) => {
    try {
      const res = await lineReturn({
        variables: { input: requestData },
      });
      const responseData = baseHelper.getResponseData(res.data, gql.LINE_RETURN);
      const errorData = baseHelper.getResponseError(res.data, gql.LINE_RETURN);
      if (!errorData) {
        setSuccessBanner(requestData);
        if (requestData.isReject || requestData.isAccept) {
          setTimeout(() => {
            onClose();
          }, 2000);
        }
      }
      if (!responseData) {
        setBanner({ isOpen: true, status: constant.CRITICAL, title: errorData });
      }
    } catch (err) {
      setBanner({ isOpen: true, status: constant.CRITICAL, title: t("error.common.somethingWentWrong") });
    }
    if (IsButtonLoading === REJECT) {
      setRejectLoading(false);
    }
    if (IsButtonLoading === ACKNOWLEDGE) {
      setAcknowledgeLoading(false);
    }
  };

  useEffect(() => {
    if (returnData && returnData.status === ACKNOWLEDGE) {
      setNote(returnData.vendorNote);
      setIsAcknowledge(true);
    }
    if (returnData) {
      setReason(returnData.reason);
      setIsSellerManaged(returnData.isSellerManaged);
    }
  }, [returnData, ACKNOWLEDGE]);

  const reasons = [
    {
      label: label.productNotRequired,
      value: returnReason.NOT_NEEDED,
    },
    {
      label: label.productNotAsAdvertised,
      value: returnReason.NOT_AS_ADVERTISED,
    },
    {
      label: label.productNotAsSold,
      value: returnReason.NOT_AS_SOLD,
    },
    {
      label: label.productFaulty,
      value: returnReason.FAULTY,
    },
    {
      label: label.others,
      value: returnReason.OTHER,
    },
  ];

  const handleSelectChange = (selectedValue) => setReason(selectedValue);

  const handleNoteChange = (noteText) => setNote(noteText);

  const handleSellerManaged = (checked) => {
    setIsSellerManaged(checked);
  };

  const handelInventoryCheck = (checked) => {
    setInventoryCheck(checked);
  };

  const handleValidate = async (field, event) => {
    const validationError = await validate(field, event.target.value);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const isDisabled = () => {
    const isAllFieldEmpty = !(reason && note);
    return isAllFieldEmpty;
  };

  const renderTitle = (item) => {
    if (!item) {
      return null;
    }
    const { orderNumber, shopifyLineItemId: shopifyLineItemIdValue } = item;

    return (
      <>
        <Heading>{`#${orderNumber}`}</Heading>
        <Caption>
          {`${t("label.LineItemId")} : `}
          <span>{`#${shopifyLineItemIdValue}`}</span>
        </Caption>
      </>
    );
  };
  const onClickRejectReturn = () => {
    if (inventoryCheck) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: message.addToInventoryCheck,
      });
      return;
    }
    setRejectLoading(true);
    const requestData = {
      orderId,
      reason,
      note,
      orderLineItemId: shopifyLineItemId,
      isSellerManaged,
      isAddToInventory: inventoryCheck,
      isReject: true,
    };
    orderLineReturn(requestData, REJECT);
  };

  const onClickAcknowledgeReturn = () => {
    setAcknowledgeLoading(true);
    const requestData = {
      orderId,
      reason,
      note,
      isSellerManaged,
      orderLineItemId: shopifyLineItemId,
      isAddToInventory: inventoryCheck,
      isAcknowledge: !isAcknowledge,
      isAccept: isAcknowledge,
    };
    orderLineReturn(requestData, ACKNOWLEDGE);
  };
  return (
    <Modal
      open={showModal}
      onClose={onClose}
      title={renderTitle(orderToInitiate)}
      primaryAction={[
        {
          content: button.rejectReturn,
          destructive: true,
          onAction: () => onClickRejectReturn(),
          disabled: isDisabled(),
          loading: rejectLoading,
        },
        {
          content: (!isAcknowledge && button.acknowledgeReturn) || button.acceptReturn,
          onAction: () => onClickAcknowledgeReturn(),
          disabled: isDisabled(),
          loading: acknowledgeLoading,
        },
      ]}
      secondaryActions={{
        content: label.cancel,
        onAction: () => onClose(),
      }}
    >
      {banner && banner.isOpen && (
        <Banner
          isOpen={banner.isOpen}
          status={banner.status}
          title={banner.title}
          onDismiss={() => setBanner({ ...banner, isOpen: false })}
        />
      )}

      <Modal.Section>
        <Stack>
          <Stack.Item fill>
            <h2>{`${PRODUCT_UC_FIRST} - ${(orderToInitiate && orderToInitiate.title) || PRODUCT}`}</h2>
            <h5>{`${label.supplier} - ${orderToInitiate.vendor}`}</h5>
          </Stack.Item>
          <Stack.Item>
            <Badge>{displayStatus.FULFILLED || orderToInitiate.fulfillmentStatus}</Badge>
          </Stack.Item>
        </Stack>
        <br />
        <Select
          placeholder={placeHolder.reason}
          options={reasons}
          onChange={handleSelectChange}
          value={reason}
          disabled
        />
        <br />
        <TextField
          label={label.note}
          labelHidden
          placeholder={placeHolder.noteAdd}
          value={note}
          onChange={(noteValue) => handleNoteChange(noteValue)}
          onBlur={(e) => handleValidate(NOTE, e)}
          error={errorMessage && errorMessage.note}
          maxLength={value.MAX_CHARACTER}
          multiline
        />
        <br />
        <Stack>
          <Stack.Item>
            <Checkbox
              label={label.sellerManaged}
              id="sellerManaged"
              checked={isSellerManaged}
              onChange={handleSellerManaged}
            />
          </Stack.Item>
          {isAcknowledge && (
            <Stack.Item>
              <Checkbox
                label={label.addInventory}
                checked={inventoryCheck}
                id="addToInventory"
                onChange={handelInventoryCheck}
              />
            </Stack.Item>
          )}
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

ProviderLineReturn.propTypes = providerLineReturnProps.type;

export default ProviderLineReturn;

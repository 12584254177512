import React, { useContext } from "react";
import {
  Card,
  TextField,
  Checkbox,
  Stack,
  ColorPicker,
  Layout,
  Button,
  Collapsible,
  TextContainer,
} from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../../../context/context";

const ButtonColor = () => {
  const { cms = () => {} } = useContext(PrivateContext);
  const { COPY, IS_HEADER_COPY, BUTTON, HEX, DEFAULT_BUTTON_COLOR, COLOR } = constant;
  const {
    color = {},
    hex = {},
    isHeaderCopy = false,
    errorMessage = {},
    handleChange = () => {},
    resetDefault = () => {},
    learnMore = () => {},
  } = useContext(BrandingContext);

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.buttonColor.title")}
        description={cms("section.buttonColor.description")}
      >
        <Card
          title={cms("section.buttonColor.title")}
          actions={[{ content: "Learn more", onAction: () => learnMore(cms("section.buttonColor.title"), "TODO: ") }]}
        >
          <Card.Section>
            <TextContainer>{cms("section.buttonColor.subDescription")}</TextContainer>
            <br />
            <Checkbox
              label={cms("section.buttonColor.label.useSameColor")}
              checked={isHeaderCopy}
              onChange={(value) => handleChange(IS_HEADER_COPY, value, COPY)}
            />
            <Stack>
              <Stack.Item fill>
                <TextField
                  label={cms("section.buttonColor.label.hexCode")}
                  value={hex.buttonHex}
                  onChange={(value) => handleChange(BUTTON, value, HEX)}
                  labelAction={
                    hex.buttonHex !== DEFAULT_BUTTON_COLOR && {
                      content: cms("label.reset"),
                      onClick: () => resetDefault(BUTTON, DEFAULT_BUTTON_COLOR, HEX),
                    }
                  }
                  error={errorMessage && errorMessage.button}
                />
                <br />
                <Button primary>{cms("section.buttonColor.label.sampleButton")}</Button>
              </Stack.Item>
              <Stack.Item>
                <ColorPicker onChange={(value) => handleChange(BUTTON, value, COLOR)} color={color.button} />
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default ButtonColor;

import gql from "graphql-tag";

const getOrderView = gql`
  query getOrderView($input: GetOrderDetail!) {
    getOrderView(input: $input) {
      status
      error
      data {
        appId
        billingAddress {
          address1
          address2
          city
          company
          country
          country_code
          country_name
          customer_id
          default
          first_name
          id
          last_name
          name
          phone
          province
          province_code
          zip
        }
        confirmed
        contactEmail
        currency
        customer {
          accepts_marketing
          currency
          default_address {
            address1
            address2
            city
            company
            country
            country_code
            country_name
            customer_id
            default
            first_name
            id
            last_name
            name
            phone
            province
            province_code
            zip
          }
          email
          first_name
          id
          last_name
          last_order_id
          last_order_name
          latitude
          longitude
          marketing_opt_in_level
          multipass_identifier
          note
          orders_count
          phone
          state
          tags
          tax_exempt
          total_spent
          verified_email
        }
        note
        createdAt
        customerLocale
        deviceId
        discountApplications {
          type
          value
          value_type
          allocation_method
          target_selection
          target_type
          code
        }
        discountCodes {
          amount
          code
          type
        }
        email
        financialStatus
        fulfillments
        fulfillmentStatus
        gateway
        id
        isBuyerAcceptsMarketing
        isShippingPaid
        isTest
        landingSite
        landingSiteRef
        locationId
        moneyFormat
        name
        noteAttributes {
          name
          value
        }
        number
        orderNumber
        orderStatusUrl
        paymentGateway
        phone
        presentmentCurrency
        processingMethod
        reference
        referringSite
        refunds
        sellerId
        sellerShippingAmount
        shipping {
          amount
          perVendorAmount
          term
        }
        shippingAddress {
          address1
          address2
          city
          company
          country
          country_code
          country_name
          customer_id
          default
          first_name
          id
          last_name
          name
          phone
          province
          province_code
          zip
        }
        shippingLines {
          title
          price
          code
          source
          requested_fulfillment_service_id
          carrier_identifier
          discounted_price
        }
        shop
        shopifyOrderId
        shopifyShippingCharge
        sourceIdentifier
        sourceName
        sourceUrl
        status
        subtotal
        subtotalPriceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        tags
        taxesIncluded
        taxLines {
          price
          rate
          title
        }
        token
        totalDiscount
        totalDiscountsSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalLineItemsPrice
        totalLineItemsPriceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalPrice
        totalPriceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalPriceUsd
        totalShippingPriceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalTax
        totalTaxSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalTipReceived
        totalWeight
      }
    }
  }
`;

const getOrderLineView = gql`
  query getOrderLineView($input: GetOrderLineView!) {
    getOrderLineView(input: $input) {
      data {
        _id
        accountingStatus
        commissionSeller
        commissionSysAdmin
        commissionVendor
        consignmentPickAddress {
          address
          city
          country
          pinCode
        }
        createdAt
        customCharge
        customLabel
        discountAllocations {
          amount
          discount_application_index
        }
        dueDate
        fulfillableQuantity
        fulfillmentService
        fulfillmentStatus
        grams
        id
        image
        isConsignmentPick
        isDeleted
        isGiftCard
        isProductDeleted
        isShippingPaid
        isVendorDeleted
        isVendorFulfilled
        markUpPrice
        moneyFormat
        name
        orderId
        orderNumber
        paidVia
        price
        priceSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        productExists
        productId
        properties {
          name
          value
        }
        quantity
        requiresShipping
        sellerDiscount
        sellerId
        shippingAmount
        shop
        shopifyLineItemId
        shopifyOrderDate
        shopifyOrderId
        shopifyOrderUpdateDate
        shopifyProductId
        sku
        status
        taxable
        taxLines {
          price
          rate
          title
        }
        title
        totalDiscount
        totalDiscountSet {
          shopMoney {
            amount
            currencyCode
          }
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalTax
        updatedAt
        trackingCompany
        trackingNumber
        variantId
        variantInventoryManagement
        variantTitle
        vendor
        vendorDiscount
        vendorId
        vendorTrackingCompany
        vendorTrackingNumber
        consignmentId
        consignmentUrl
      }
      status
      error
    }
  }
`;

export { getOrderView, getOrderLineView };

import { gql } from "apollo-boost";

const GET_USER_CONTENT = gql`
  query PrivateContainer($getContentInput: GetContent, $getFeatureInput: GetFeature, $getPublicCMSInput: GetCMS!) {
    getContent(input: $getContentInput) {
      data {
        button {
          code
          en
        }
        error {
          code
          en
        }
        label {
          code
          en
        }
        message {
          code
          en
        }
        pageTitle {
          code
          en
        }
        placeHolder {
          code
          en
        }
        sectionContent {
          code
          en
        }
        sectionLabel {
          code
          en
        }
      }
      status
      error
    }
    getCurrentUser {
      status
      data {
        _id
        aws {
          bucket
          path
          id
          secret
        }
        isOtp
        firstlogin
        firstName
        lastName
        isFirstProduct
        payoutSetting {
          isAutoStripe
          isAutoPaypal
        }
        email {
          address
          verified
        }
        contactEmail
        roles {
          name
        }
        moneyFormat
        shop
        brandName
        vendors {
          max
          associated
        }
        products {
          max
          used
        }
        isOrderConnect
        isReadOnly
        plan {
          isBranding
          name
          price
        }
        stripe {
          accountId
        }
        paypalKey {
          clientId
          secret
        }
        paypal {
          emailId
          payerId
          status
        }
        setupProgress
        phoneNumber
        address
        city
        country
        pinCode
        provinceCode
        commissionType
        setting {
          isAcceptOrder
          isFulfillOrder
        }
        logo
        shop
        shopifyAccessToken
        generalInfo {
          isSellOnline
          storeType
          isVendorOnboarding
          marketplaceUnderstanding
          annualRevenue
          alternateEmail
          brandDescription
        }
        introductorySeller
        introductorySellerName
        paymentGateway
        planId
        userLevel
        isProductSync
        isHideMcInfo
        emailSign
        footerText
        loginText
        domain
        isDomainActive
        register {
          title
          desc
        }
        style {
          header {
            color
            font
          }
          button {
            color
            font
          }
        }
        maintenance {
          isApp
          isSite
        }
        brand {
          termsLink
          policyLink
          slug
          description
          cover {
            imageUrl
          }
        }
        isAwsCredsAvailable
        createdAt
        requestedBy
        updatedAt
        storeConfig {
          wooCommerce {
            shop,
            accessKey,
          }
          bigCommerce {
            shopDomain,
            accessToken,
          }
        }
      }
    }
    getFeature(input: $getFeatureInput) {
      error
      status
      data {
        _id
        moduleId
        parentId
        key
        label
        description
        hide {
          permission {
            level
            role
          }
        }
        user {
          permission {
            level
            role
          }
          tier
          group {
            seller
            vendor
            systemAdmin
          }
        }
        version
        time {
          duration {
            date
            days
          }
        }
        location {
          country
          region
          city
        }
        device {
          web {
            browser
            os
          }
          mobile {
            browser
            os
          }
        }
        features {
          _id
          moduleId
          parentId
          key
          label
          description
          hide {
            permission {
              level
              role
            }
          }
          user {
            permission {
              level
              role
            }
            tier
            group {
              seller
              vendor
              systemAdmin
            }
          }
          version
          time {
            duration {
              date
              days
            }
          }
          location {
            country
            region
            city
          }
          device {
            web {
              browser
              os
            }
            mobile {
              browser
              os
            }
          }
        }
        dependency {
          _id
          moduleId
          parentId
          key
          label
          description
          hide {
            permission {
              level
              role
            }
          }
          user {
            permission {
              level
              role
            }
            tier
            group {
              seller
              vendor
              systemAdmin
            }
          }
          version
          time {
            duration {
              date
              days
            }
          }
          location {
            country
            region
            city
          }
          device {
            web {
              browser
              os
            }
            mobile {
              browser
              os
            }
          }
          features {
            _id
            moduleId
            parentId
            key
            label
            description
            hide {
              permission {
                level
                role
              }
            }
            user {
              permission {
                level
                role
              }
              tier
              group {
                seller
                vendor
                systemAdmin
              }
            }
            version
            time {
              duration {
                date
                days
              }
            }
            location {
              country
              region
              city
            }
            device {
              web {
                browser
                os
              }
              mobile {
                browser
                os
              }
            }
          }
        }
      }
    }
    getPublicCMS(input: $getPublicCMSInput) {
      status
      error
      data {
        commonContent
        pageContent
      }
    }
  }
`;

export default GET_USER_CONTENT;

// import packages
import React, { useState, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  Link,
  Card,
  Layout,
  FormLayout,
  TextField,
  Caption,
  List,
  PageActions,
  Select,
  Stack,
  Modal,
  Banner as PolarisBanner,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import query
import { GET_DISCOUNT, UPDATE_DISCOUNT, GET_VENDOR_AND_PRODUCT_TYPES } from "app/setup/apollo";

// import helpers
import { baseHelper } from "lib/helpers";

// import components
import { Banner, SkeletonAnnotated, Sheet } from "lib/components";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import components
import { AdvanceOption } from "app/setup/modules/generic";

const OperatorDiscount = () => {
  const { currentUser, history, cms } = useContext(PrivateContext);
  const { PERCENTAGE, PRODUCT_TYPE, FLAT, gql } = constant;
  const { moneyFormat = "" } = currentUser || {};
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [standardType, setStandardType] = useState(FLAT);
  const [isShowAdvanced, setIsShowAdvanced] = useState(false);
  const [standardAmount, setStandardAmount] = useState("");
  const [isInvalidRow, setIsInvalidRow] = useState(false);
  const [sheetActive, setSheetActive] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");
  const [advancedValues, setAdvancedValues] = useState([
    {
      type: null,
      category: null,
      value: null,
      price: 0,
    },
  ]);
  const bannerProps = {
    status: "",
    title: "",
  };
  const [ruleBy, setRuleBy] = useState(PRODUCT_TYPE);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [submitEnabled, setSubmitEnable] = useState(false);
  const formWidth120px = { width: "120px" };

  const { loading: discountLoading = false, error: discountError, data: discountData } = useQuery(GET_DISCOUNT);

  const [updateDiscount, { loading: updateDiscountLoading }] = useMutation(UPDATE_DISCOUNT);

  const {
    loading: vendorAndProductTypesLoading,
    error: vendorAndProductTypesError,
    data: vendorAndProductTypesData,
  } = useQuery(GET_VENDOR_AND_PRODUCT_TYPES);

  useEffect(() => {
    if (discountError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  }, [cms, discountError]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(discountData, gql.GET_DISCOUNT);
    const responseError = baseHelper.getResponseError(discountData, gql.GET_DISCOUNT);
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
      return;
    }
    if (!responseData) {
      return;
    }
    const { advance, type = "", ruleBy: discountRuleBy = "", price = "", updatedAt = "" } = responseData;
    const isAdvancedValues = advance && advance.length;
    if (isAdvancedValues) {
      const formattedAdvanceValues = [];
      advance.forEach((item) => {
        if (item.vendorId) {
          formattedAdvanceValues.push({
            type: constant.VENDOR,
            category: item.vendorId,
            value: item.type,
            price: item.price,
          });
        } else if (item.productTypeId) {
          formattedAdvanceValues.push({
            type: constant.CATEGORY,
            category: item.productTypeId,
            value: item.type,
            price: item.price,
          });
        }
      });
      setAdvancedValues(formattedAdvanceValues);
    }
    setStandardType(type);
    setStandardAmount(price);
    setIsShowAdvanced(!!isAdvancedValues);
    setRuleBy(discountRuleBy);
    setLastUpdated(updatedAt);
  }, [discountData, gql.GET_DISCOUNT, setBanner]);

  const handleSubmit = () => {
    setIsModalOpen(true);
  };

  const submitCallback = (data) => {
    const inputData = {
      advancedValues: data.advancedValues,
      ruleBy: data.ruleBy,
      price: Number(standardAmount),
      type: standardType,
    };
    updateDiscount({
      variables: { input: inputData },
    })
      .then((result) => {
        setSubmitEnable(false);
        const responseError = baseHelper.getResponseError(result.data, gql.UPDATE_DISCOUNT);
        const responseData = baseHelper.getResponseData(result.data, gql.UPDATE_DISCOUNT);

        if (responseData) {
          bannerProps.status = "success";
          bannerProps.title = cms("operator.message.success");
          setIsInvalidRow(false);
        }

        if (responseError) {
          bannerProps.status = "critical";
          bannerProps.title = responseError;
        }
        setBanner({
          isOpen: true,
          status: bannerProps.status,
          title: bannerProps.title,
        });
      })
      .catch(() => {
        setSubmitEnable(false);
        setBanner({
          isOpen: true,
          status: "critical",
          title: cms("common.message.error.somethingWentWrong"),
        });
      });
  };

  const onFormSubmit = () => {
    setIsModalOpen(false);
    if (!(standardAmount === 0 || (standardAmount && standardType))) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.standardIsRequired", { item: "Discount" }),
        status: "critical",
      });
      return;
    }

    let isInvalidValue = false;
    if (standardType === PERCENTAGE && standardAmount > constant.value.MAX_PERCENTAGE) {
      isInvalidValue = true;
    }

    let invalidRowValues = false;
    let index = 0;
    let fieldValues = false;
    if (advancedValues && advancedValues.length) {
      advancedValues.forEach((row) => {
        const keys = Object.keys(row);
        keys.forEach((key) => {
          if (row[key]) {
            fieldValues = true;
          }
        });

        if (fieldValues) {
          keys.forEach((key) => {
            if (!row[key] && row[key] !== 0) {
              setIsInvalidRow(true);
              invalidRowValues = true;
            }
          });
          if (row.value === PERCENTAGE && row.price > constant.value.MAX_PERCENTAGE) {
            isInvalidValue = true;
          }
          advancedValues[index].price = Number(row.price);
          index += 1;
        }
      });
    }

    if (isInvalidValue) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.percentageUpperLimit"),
        status: "critical",
      });
      return;
    }

    const updatedAdvancedValue = advancedValues.filter((item) => item.type !== null);

    const data = {
      advancedValues: updatedAdvancedValue,
      ruleBy,
    };

    if (!invalidRowValues) {
      submitCallback(data);
    }
  };

  const onCloseBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const renderModal = () => {
    return (
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={cms("operator.modal.button.title")}
        primaryAction={{
          content: cms("operator.modal.button.primary"),
          onAction: () => onFormSubmit(),
        }}
        sectioned
      >
        <Modal.Section>{cms("operator.modal.content")}</Modal.Section>
      </Modal>
    );
  };

  const isFlatType = standardType === FLAT;
  const getAmountMax = isFlatType ? constant.value.MAX_FLAT : constant.value.MAX_PERCENTAGE;
  const getAmountPrefix = isFlatType ? (currentUser && moneyFormat) || constant.symbol.DOLLAR : "";
  const getAmountSuffix = isFlatType ? "" : constant.symbol.PERCENTAGE;

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };
  const handleStandardAmountChange = (value) => {
    setStandardAmount(value);
    setSubmitEnable(true);
  };

  const handleStandardTypeChange = (value) => {
    setStandardType(value);
    setSubmitEnable(true);
    setStandardAmount("");
  };

  const valueOptions = [
    {
      label: cms("common.label.flat"),
      value: FLAT,
    },
    {
      label: cms("common.label.percentage"),
      value: PERCENTAGE,
    },
  ];
  if (discountLoading) {
    return <SkeletonAnnotated />;
  }
  const standardCaption = cms("operator.standard.caption") || [];
  const renderList = () => {
    const list = standardCaption.map((content) => (
      <List.Item>
        <b>{content.title}</b>
        {content.description}
      </List.Item>
    ));
    return list;
  };
  if (vendorAndProductTypesLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      {renderModal()}
      {banner.isOpen && (
        <Layout.Section>
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onCloseBanner} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection
        title={cms("operator.standard.title")}
        description={cms("operator.standard.description")}
      >
        <Card
          title={[
            "Standard Discount",
            lastUpdated && (
              <Caption>
                <TextStyle variation="subdued">{`${baseHelper.lastUpdateDate(lastUpdated)}`}</TextStyle>
              </Caption>
            ),
          ]}
          actions={[
            {
              content: cms("common.label.learnMore"),
              onAction: () => {
                setSheetActive(true);
              },
            },
          ]}
        >
          <Card.Section>
            <TextContainer>{cms("operator.standard.subDescription")}</TextContainer>
            <Sheet
              title="Standard Discount"
              isOpen={sheetActive}
              onClose={() => setSheetActive(false)}
              primaryAction={{
                content: cms("common.label.done"),
                onAction: () => setSheetActive(false),
              }}
              secondaryAction={{
                content: cms("common.button.cancel"),
                onAction: () => setSheetActive(false),
              }}
            >
              TODO:
            </Sheet>
            <FormLayout>
              <Stack wrap={false}>
                <div style={formWidth120px}>
                  <Select
                    id="standardTypeSelect"
                    options={valueOptions}
                    placeholder={cms("common.label.value")}
                    value={standardType}
                    onChange={(selectedValue) => handleStandardTypeChange(selectedValue)}
                  />
                </div>
                <div style={formWidth120px}>
                  <TextField
                    id="standardAmount"
                    type={constant.NUMBER}
                    min={0}
                    max={getAmountMax}
                    prefix={getAmountPrefix}
                    suffix={getAmountSuffix}
                    value={standardAmount.toString()}
                    onChange={(value) => handleStandardAmountChange(acceptOnlyValidInput(value, standardAmount))}
                  />
                </div>
              </Stack>
              <PolarisBanner status="info">
                <p>{cms("common.label.option")}</p>
                <br />
                <div id="listContent">
                  <List type="bullet">{renderList()}</List>
                </div>
              </PolarisBanner>
              <Link onClick={() => setIsShowAdvanced(!isShowAdvanced)}>
                {cms("operator.standard.button.toggleAdvance", { item: "discount" })}
              </Link>
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
      {discountData && (
        <AdvanceOption
          setSubmitEnable={setSubmitEnable}
          isOpen={isShowAdvanced}
          advancedValues={advancedValues}
          ruleBy={ruleBy}
          setParentBanner={setBanner}
          setAdvancedValueOption={setAdvancedValues}
          setAdvancedRuleByOption={setRuleBy}
          isInvalidRow={isInvalidRow}
          moneyFormat={moneyFormat}
          lastUpdated={lastUpdated}
          method="discount"
          vendorAndProductTypesLoading={vendorAndProductTypesLoading}
          vendorAndProductTypesError={vendorAndProductTypesError}
          vendorAndProductTypesData={vendorAndProductTypesData}
        />
      )}
      <Layout.Section>
        <PageActions
          primaryAction={{
            content: "Update",
            onAction: () => handleSubmit(),
            loading: updateDiscountLoading,
            disabled: !submitEnabled,
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              onAction: () => history.push("/"),
            },
          ]}
        />
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorDiscount), { feature: constant.DISCOUNT_SETTING });

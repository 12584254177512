import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const schema = (message) => {
  const { hexRegex } = yupHelper;

  const headerHexSchema = yup.object().shape({
    header: yup.string().matches(hexRegex, message.enterValid),
  });

  const headerTextHexSchema = yup.object().shape({
    headerText: yup.string().matches(hexRegex, message.enterValid),
  });

  const buttonHexSchema = yup.object().shape({
    button: yup.string().matches(hexRegex, message.enterValid),
  });

  const buttonTextHexSchema = yup.object().shape({
    buttonText: yup.string().matches(hexRegex, message.enterValid),
  });

  return { headerHexSchema, headerTextHexSchema, buttonHexSchema, buttonTextHexSchema };
};

export default schema;

const config = {
  "/": [
    {
      caption: "Update your profile",
      label: "Profile",
      link: "/profile",
    },
    {
      caption: "Update commission",
      label: "Commission",
      link: "/commission",
    },
    {
      caption: "Update terms",
      label: "Terms",
      link: "/terms",
    },
  ],
  "/orders": [
    {
      caption: "Manage order auto-accept settings",
      label: "Order Acceptance",
      link: "/setting/order",
    },
  ],
  "/payments": [
    {
      caption: "Manage payout settings",
      label: "Payouts",
      link: "/payouts",
    },
    {
      caption: "Connect to stripe for payments",
      label: "Payout using Stripe",
      link: "/setting/payment",
    },
    {
      caption: "Connect to paypal for payments",
      label: "Payout using Paypal",
      link: "/setting/payment",
    },
  ],
  "/products": [
    {
      caption: "Manage product approval settings",
      label: "Product approval",
      link: "/setting/product",
    },
    {
      caption: "Manage product discount settings",
      label: "Discount",
      link: "/setting/discount",
    },
    {
      caption: "Manage product mark-up settings",
      label: "Markup",
      link: "/setting/markup",
    },
    {
      caption: "Manage product shipping settings",
      label: "Shipping",
      link: "/shipping",
    },
  ],
  "/providers": [
    {
      caption: "Manage vendor settings",
      label: "Manage Vendor Profile",
      link: "/provider/manage",
    },
    {
      caption: "Manage mute notifications for incoming mails",
      label: "Manage Notifications",
      link: "/setting/notification",
    },
  ],
};

const title = {
  "/": "Settings",
  "/orders": "Order Settings",
  "/payments": "Payment Settings",
  "/products": "Product Settings",
  "/providers": "Vendor Settings",
};

export { config, title };

// import packages
import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Card, Layout, TextField, Heading, List, PageActions } from "@shopify/polaris";

// import components
import { Editor } from "@tinymce/tinymce-react";
import { Banner, Spinner } from "lib/components";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import helper
import baseHelper from "lib/helpers/base";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import gql
import { FETCH_EMAIL_TEMPLATE_BY_ID } from "app/emailTemplate/apollo/queries";
import { UPDATE_EMAIL_TEMPLATE } from "app/emailTemplate/apollo/mutations";

const OperatorEditEmailTemplate = () => {
  const { history, match } = useContext(PrivateContext);
  const { params } = match;
  const { gql } = constant;

  const listRoute = "/email-template";
  const templateId = params.id;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState({});
  const [subjectTags, setSubjectTags] = useState({});
  const [state, setState] = useState({
    label: "",
    subject: "",
    message: "",
    prevMessage: "",
    prevSubject: "",
  });

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const [isNotFound, setNotFound] = useState(false);

  const [customizeEmailTemplate, { loading: updateTemplateLoading }] = useMutation(UPDATE_EMAIL_TEMPLATE);

  const { data: emailTemplateData, loading: emailTemplateLoading, error } = useQuery(FETCH_EMAIL_TEMPLATE_BY_ID, {
    variables: {
      input: {
        id: templateId,
      },
    },
  });

  const emailTemplateError = baseHelper.getResponseError(emailTemplateData, gql.GET_EMAIL_TEMPLATE_BY_ID);
  const emailTemplateResponse = baseHelper.getResponseData(emailTemplateData, gql.GET_EMAIL_TEMPLATE_BY_ID);

  const getEmailTemplate = (response) => {
    if (response && !response.label) {
      setLoading(false);
      setNotFound(true);
      return;
    }

    if (response) {
      const { label = "", message = "", subject = "", tags: messageTags = {}, subjectTags: subTags = {} } = response;

      setState({
        ...state,
        label,
        subject,
        message,
        prevMessage: message,
        prevSubject: subject,
      });

      setTags(messageTags);
      setSubjectTags(subTags);
    }
  };

  useEffect(() => {
    if (!emailTemplateLoading) {
      setLoading(false);
    }
    if (error) {
      setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
    }
  }, [emailTemplateLoading, error, t]);

  if (isNotFound) {
    history.push(`${listRoute}?isNotFound=${isNotFound}`);
  }

  useEffect(() => {
    if (emailTemplateError) {
      setBanner({ isOpen: true, status: "critical", title: emailTemplateError });
      setNotFound(true);
    }

    if (emailTemplateResponse) {
      getEmailTemplate(emailTemplateResponse);
    }
  }, [emailTemplateError, emailTemplateResponse]);

  const renderList = (item) => {
    const list = Object.keys(item).map((key) => {
      const handleBar = `{{${key}}}`;
      return <List.Item key={key}>{`${handleBar}:${item[key]}`}</List.Item>;
    });
    return list;
  };

  const subjectDescription = () => {
    let isEmptyString = false;

    if (subjectTags && Object.entries(subjectTags).length) {
      const values = Object.values(subjectTags);

      if (values.includes("")) {
        isEmptyString = true;
      }

      if (!isEmptyString) {
        return (
          <>
            <Heading>Subject Tags</Heading>
            <List type="bullet">{renderList(subjectTags)}</List>
          </>
        );
      }
    }
  };

  const messageDescription = () => {
    let isEmptyString = false;

    if (tags && Object.entries(tags).length) {
      const values = Object.values(tags);

      if (values.includes("")) {
        isEmptyString = true;
      }

      if (!isEmptyString) {
        return (
          <>
            <Heading>Message Tags</Heading>
            <List type="bullet">{renderList(tags)}</List>
          </>
        );
      }
    }
  };

  const showDescription = () => [subjectDescription(), <br />, messageDescription()];

  const { label = "", message = "", subject = "", prevSubject = "", prevMessage = "" } = state;
  if (loading) {
    return <Spinner />;
  }

  const handleEditorChange = (content) => {
    setState({ ...state, message: content });
  };

  const handleSubjectChange = (value) => setState({ ...state, subject: value });

  const submitCallback = async (reqData) => {
    // call mutation
    try {
      const res = await customizeEmailTemplate({
        variables: { input: reqData },
      });

      const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_EMAIL_TEMPLATE);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_EMAIL_TEMPLATE);
        setBanner({ isOpen: true, status: "critical", title: errorResponse });
        return false;
      }
      setBanner({ isOpen: true, status: "success", title: t("label.emailTemplate.success") });
    } catch (err) {
      setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
    }
  };

  const onsubmit = async () => {
    const data = {
      id: templateId,
      subject,
      message,
    };

    const prevSubjectTags = baseHelper.templateTags(prevSubject);
    const currentSubjectTags = baseHelper.templateTags(subject);
    const isValidSubject = baseHelper.validateTags(prevSubjectTags, currentSubjectTags);

    const prevMessageTags = baseHelper.templateTags(prevMessage);
    const currentMessageTags = baseHelper.templateTags(message);
    const isValidContent = baseHelper.validateTags(prevMessageTags, currentMessageTags);

    if (!isValidSubject || !isValidContent) {
      setBanner({
        isOpen: true,
        title: "Incorrect tags provided!",
        status: "critical",
      });
      return false;
    }
    await submitCallback(data);
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            title={banner.title}
            status={banner.status}
            onDismiss={() => setBanner({ isOpen: false })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={label} description={showDescription()}>
        <Card>
          <Card.Section>
            <TextField id="template-label" label="Label" value={label} placeholder="Label" disabled />
            <br />
            <TextField
              id="template-subject"
              label={`${t("label.emailTemplate.subject")}*`}
              value={subject}
              placeholder={`${t("placeHolder.emailTemplate.subject")}`}
              onChange={(value) => handleSubjectChange(value)}
            />
            <br />
            <label htmlFor="content">{`${t("label.emailTemplate.message")}*`}</label>
            <div className="form-group">
              <Editor
                initialValue={message || ""}
                init={{
                  removed_menuitems: "newdocument visualaid",
                  plugins: "link",
                  toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
                  themes: "modern",
                  elementpath: false,
                  branding: false,
                }}
                onEditorChange={(value) => handleEditorChange(value)}
              />
            </div>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            content: t("common.Submit"),
            id: "submitButton",
            onAction: () => {
              onsubmit();
            },
            loading: updateTemplateLoading,
          }}
          secondaryActions={[
            {
              content: t("common.Cancel"),
              id: "cancelButton",
              onAction: () => history.push("/email-template"),
            },
          ]}
        />
      </Layout.AnnotatedSection>
    </>
  );
};

export default withFeature(withErrorBoundary(OperatorEditEmailTemplate), {
  feature: constant.EMAIL_TEMPLATE,
});

import React, { useContext } from "react";

import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { AdminOrderSlip } from "./admin/features";
import { OperatorOrderSlip } from "./operator/features";
import { ProviderOrderSlip } from "./provider/features";

import { storageHelper } from "../../../lib/helpers";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const OrderSlip = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }

    if (role === admin) {
      return <AdminOrderSlip />;
    }
    if (role === operator) {
      return <OperatorOrderSlip />;
    }
    if (role === provider) {
      return <ProviderOrderSlip />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const OrderSlipComponent = getComponent(currentUserRole);
  return OrderSlipComponent;
};

export default OrderSlip;

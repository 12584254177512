import { emailSchema, passwordSchema } from "app/public/modules/login/yup/schema";
import { yupHelper } from "lib/helpers";

const { validateValue, handlePromiseError } = yupHelper;

const validate = async (field, value, cms) => {
  let promise = "";
  switch (field) {
    case "email":
      promise = validateValue(emailSchema(cms), { email: value });
      break;
    case "password":
      promise = validateValue(passwordSchema(cms), { password: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;

/* eslint-disable max-len */
const register = {
  message: {
    title: "Welcome to Marketcube.io",
    description:
      "Join the world's largest dropshipping community and sell to millions of businesses and customers worldwide",
    privacyTag: {
      para1:
        "We understand that reading policy agreement is a boring and time-consuming exercise and for your piece of mind we outline our key privacy principles below ",
      para2: "Last updated: June 25th, 2018. \n This page contains the current privacy policy.",
      para3: ` This Privacy Policy applies to the processing of personal data
                concerning the users ("Users") of services provided by
                Marketcube ("Service(s)"). This policy explains what personal
                data we collect and how we use it. The Users can be
                Marketcube's customers ("Customer"), who operate their own
                marketplace, representatives of Customer or Customer's
                customers ("End-Users"). The marketplace operated by the
                Customer is subject to its own privacy policy. Marketcube does
                not take any responsibility of its Customers' or other third
                parties' privacy policies or processing of personal data in
                its Customers' or third parties' operations.`,
      para4: `The data controller in accordance with the applicable data protection law is Marketcube Limited
                ("Marketcube", "we", "us", "our"). Marketcube is responsible for ensuring that the processing of personal
                data complies with this Privacy Policy and the applicable data protection laws.
                Contact details of the data controller:
                - Marketcube Limited
                - Address: 77 New Cavendish Street, W1W6XB, London, UNITED KINGDOM
                - Internet: www.marketcube.io
                - Email: admin@marketcube.io`,
      para5: `We collect Users' personal data through different means, which are explained below:
                Most of the personal data we process is collected directly from the Users. Marketcube may collect following
                personal data in the process of providing the Service:
                * Basic information such as name, postal address, email address and phone number
                * Details about User’s employer (name, address, phone number, ID and VAT ID) should such information be
                considered to be personal data
                * Authentication information such as marketcube.io username and password, and if any third party service
                (e.g. Shopify, Facebook) is used to sign in, the user ID of that service
                * Information relating to the customer relationship such as bought services, their start and end times and
                information on their use
                * Billing information
                Following data is processed by Marketcube's payments processor (e.g. Paypal and Stripe, subject to change):
                Payment data, such as credit card number and expiration date. Marketcube automatically collects and processes
                the following technical data about the User: IP address, Geographical location data based on the IP address,
                Service access times, Statistics on page views, Any other automatically collected technical data. Furthermore,
                Marketcube may also collect other anonymized statistics about the way the User uses the Services provided by
                Marketcube.`,
      para6: `Marketcube processes personal data for three main purposes:
                (1) Service
                The primary purpose of collecting personal data is to provide the website marketcube.io and Services,
                to issue accurate charges for the use of the Services and to manage the customer relationship.
                The processing of personal data in this case is based on the agreement between the Customer and Marketcube.
                This concerns, for example, the data collected during the signing up for the Service, during the use of the
                Service and the technical data needed to run the Service in a secure manner and communications with Users.
                (2) Marketing and other communications
                Marketcube may occasionally send its Customers (and End-Users when the relevant personal data has been
                collected by Marketcube itself) email to inform about new features, solicit feedback, or provide information on
                what is going on with Marketcube and its products, as well as the various marketplaces built using the
                marketcube.io service. We base this on our legitimate interest to provide Users with relevant information
                as part of the Services and to promote our Services.
                (3) Service development and research
                We always work to make our Services better. Therefore, we may use the User's data to improve
                the Services. We base this processing on our legitimate interest to grow and develop. Marketcube may also
                collect technical data for statistical purposes to compile anonymous, aggregated statistics, such as about
                the most popular marketplaces. Marketcube may display information publicly to others in an aggregate format
                that doesnot reveal statistics of any single marketplace. Marketcube does not disclose personal data other
                than as described below.`,
      para7: `We may disclose personal data to third parties in the following cases:
                * Marketcube may use subcontractors when providing its Services. Our trusted subcontractors work
                on our behalf, and do not have an independent right to use the personal data we disclose to them.
                Marketcube discloses personal data to its subcontractors on a need to know basis when necessary to process
                such data on Marketcube’s behalf in order to provide Services. Subcontractors are subject to appropriate
                security and confidentiality requirements.
                * When required by law such as to comply with requests by competent authorities or in response
                to a subpoena, court order or other governmental request.
                * When we believe in good faith that disclosure is necessary to protect our rights, investigate
                fraud or to protect our Users’ safety or rights.
                * If Marketcube, or substantially all of its assets, are acquired, or in the unlikely event
                that Marketcube goes out of business or enters bankruptcy, User information would be one of the
                assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur,
                and that any acquirer of Marketcube may continue to use your personal data as set forth in this Privacy Policy. `,
      para9: `When necessary and to the extent required for the successful provision of the Services, personal data
                may be transferred to third parties outside of EU/EEA. Marketcube or its subcontractors shall not transfer
                personal data outside of EU/EEA without adequate safeguards required by applicable data protection law,
                such as standard contractual clauses. Marketcube may also use Privacy Shield-certified subcontractors
                located in the U.S. For more information about the Privacy Shield framework developed by the U.S.
                Department of Commerce and the EU Commission and the related principles concerning processing of
                personal data, please see here.`,
      para10: `Cookies are pieces of data which are received and transmitted by a User's device when the User is using
                  Marketcube's Services or website. Marketcube may use cookies and similar techniques to provide
                  functionality of the Services, to enhance the user experience and to identify Users and how they use the
                  Services. We use cookies, for example, to login and logout authentication, to remember session allowing
                  Users to continue the session in case of time-out and to prevent unauthorized use of the Services. The
                  User may prohibit the use of cookies or remove cookies from the browser settings. However, cookies are
                  important part of how the Service works, so limiting the use of cookies may affect the functionality of the
                  Service.
                  Marketcube uses third party cookies for various purposes. We may use third party cookies for statistical
                  purposes to compile anonymous, aggregated statistics, such as to estimate number of Users and detect
                  which parts of the Service Users find most useful, and identify features that could be improved. For such
                  purposes, we use third party cookies, such as Google Analytics, to help analyze how our Users use the
                  Service. The technical usage information (including IP address) generated by the cookie about use of the
                  Service is transmitted to such third parties to compile statistical reports.
                  Marketcube may also use third party cookies for marketing purposes to collect information about Users'
                  browsing habits and to remember visits in order to make advertising more relevant to that User and to his
                  or her interests. These cookies are usually placed by third party advertising networks and social media
                  providers.`,
      para11: `The User's personal data will be retained only for as long as necessary to fulfil the purposes defined in
                  this Privacy Policy. When the User's personal data is no longer required by law or rights or obligations by
                  us or the User, we will delete the personal data. In most cases, personal data will be deleted upon
                  termination or expiry of the agreement between the Customer and Marketcube or upon the Customer’s
                  written request. Please note that Marketcube may have a right to retain some data even after termination
                  of the agreement for billing purposes.`,
      para12: `A User has the right to access the data on him/her and upon request, obtain a copy of the data. Such
                  request shall include information that is necessary for retrieving the data and shall be made using a
                  personally signed document which can be addressed to the contact person provided in this Privacy Policy.
                  Marketcube rectifies, erases or supplements possibly erroneous, unnecessary, incomplete or
                  obsolete personal data at its own initiative or at the request of User concerned. The User is advised to
                  contact Marketcube at admin@marketcube.io to correct any possibly erroneous data.
                  Users have the right to prohibit Marketcube to process personal data for purposes of direct marketing.
                  Marketcube advises the Users to notify Marketcube’s contact person provided in this Privacy Policy of
                  such possible prohibitions in writing. Users also have a right to data portability to the extent granted by
                  applicable data protection rules. Data portability refers to a right to receive own personal data in a
                  structured, commonly used machine- readable format and transmit your personal data to another data
                  controller.
                  If a User thinks there is a problem with the way Marketcube is processing personal data, the User has a
                  right to file in a complaint to a data protection authority in the EU.`,
      para13: `Marketcube may change this Privacy Policy from time to time. Marketcube recommends that the Users
                  regularly access the Privacy Policy to obtain knowledge of any possible changes to it. We will provide the
                  date of the Privacy Policy to allow Users to see changes. Marketcube will inform Users of
                  possible changes by using reasonable and available channels.`,
    },
    privacyHTag: {
      h2Tag1: "Data controller",
      h2Tag2: "Collection of Personal Data",
      h2Tag3: "Legal basis and purpose of processing personal data",
      h2Tag4: "Disclosures of Personal Data",
      h2Tag5: "Transfer of personal data outside of EU/EEA",
      h2Tag6: "Cookies",
      h2Tag7: "Retention of personal data",
      h2Tag8: "Users' rights",
      h2Tag9: "Privacy Policy Changes",
    },
    privacyLiTag: {
      liTag1: `We don’t ask you for your personal data unless we truly
        need it.`,
      liTag2: `We don’t share your personal data with anyone except to
        comply with the law, develop our products, or protect our
        rights.`,
      liTag3: `We don’t store personal data on our servers unless
        required for the on-going operation of one of our
        services.`,
    },
    paraField: {
      paraField1: `These Terms of Use are applicable to the agreement between Marketcube Limited, a limited liability
        company incorporated and existing under the laws of United Kingdom, having its principal place of
        business at 77 New Cavendish Street, W1W6XB, London, UNITED KINGDOM (“Marketcube”) and
        yourself as the other party entering into this agreement ("You").
        You and Marketcube are hereinafter jointly referred to as the "Parties" and each separately as a "Party".
        The agreement consisting of these Terms of Use and other documentation referred to in these Terms of
        Use (“Agreement”) shall govern Your use of the Marketcube service that enables You to setup an online
        marketplace (the “Service“). This Agreement governs Your use of any products and services provided by
        Marketcube.
        By using the Service in any way, You acknowledge, represent and warrant that You have reviewed and
        accept this Agreement and, if You have indicated that You act on behalf of an entity, are authorized to act
        on behalf of such entity.
        If You do not wish to be bound by this Agreement, do not use, access or register with the Service.`,
      paraField2: `You may use the Service to set up Your own online marketplace (the “Marketplace”) subject to the terms
        and conditions of this Agreement and any policies of Marketcube as in force from time to time as posted
        on the website of the Service at marketcube.io.
        Unless agreed to the contrary, the Service will be hosted by Marketcube and may have its own dedicated
        Internet domain name (agreed separately with You and provided by You). It may be agreed separately
        that Marketcube only provides parts of the Service to You, such as only the back-end of the Service,
        with a front-end supplied by Yourself. For the avoidance of any doubt, in such a case, Marketcube shall only
        be responsible for the operation and performance of the Service as provided by Marketcube, and
        Marketcube expressly disclaims any liability with regards to any products and/or services provided by
        You.
        Marketcube only provides the Service for the purpose of enabling You to set up the Marketplace.
        Marketcube does not operate the Marketplace in any way and does assume any responsibility or liability
        for any business, transactions and activities carried out in the Marketplace. You are responsible for
        determining the theme, contents, focus and business idea of the Marketplace. The Marketplace may not
        be used for business, transactions and activities that are illegal. Furthermore, You agree not to use the
        Service in any other way that harms or may harm the reputation of Marketcube, or which may endanger
        the provision of the Service to other parties. Such activities include, but are not limited to, any
        unauthorized load testing, security testing or any other use of the Service that causes an
        unreasonable load on the resources used by Marketcube to provide the Service.
        You are solely responsible for providing the required terms of use, terms of sale, other terms as
        well as legally mandated documents (including without limitation privacy policies)applicable to the
        use of the Marketplace by the end-users of the Marketplace (together the “Marketplace Terms”). You shall ensure
        that the Marketplace Terms are available to the end-users of the Marketplace at all times and that such
        end-users have accepted and shall comply with the Marketplace Terms. You may determine the actual
        terms and conditions of the Marketplace Terms, but You are solely responsible for ensuring that such
        terms and conditions comply with all applicable laws, rules and regulations and the terms and conditions
        of this Agreement at all times.
        You are solely responsible and liable for the Marketplace and any business, transactions and activities
        carried out on the Marketplace. You are solely responsible for any content created for or posted on the
        Marketplace and understand that Marketcube does not control such content. Without limiting the
        foregoing, You are solely responsible for operating the Marketplace in accordance with all applicable laws,
        rules and regulations and the terms and conditions of this Agreement. Marketcube reserves the right to
        remove any and all content from the Marketplace or temporarily suspend the Marketplace when such
        removal or suspension is necessary to remove content that is not compliant with the terms and conditions
        of this Agreement from the Marketplace.
        You are solely responsible for ensuring that the personal data relating to the end-users of the Marketplace
        is collected and processed in accordance with all applicable laws. All processing of personal data by
        Marketcube on Your behalf shall be subject to the Data Processing Agreement between Yourself and
        Marketcube which Data Processing Agreement is incorporated by reference to this Agreement here.`,
      paraField3: `The Service is based on the Marketcube's software platform and will evolve as the platform gets updated.
        Marketcube shall have the right to change the Service or any part thereof at any time. Marketcube shall
        use reasonable efforts to inform You in advance of any material changes that may affect Your use of
        the Service in an adverse way. If You do not wish to use the changed Service, You may terminate Your use of
        the Service and this Agreement effective as of the date on which such changes to the Service would take
        effect by informing Marketcube of the termination prior such date.`,
      paraField4: `Fees may be applicable to the use of the Service. Any fees chargeable for the use of the Service shall be
        agreed separately between the Parties and shall be based on the price lists of Marketcube as in force
        from time to time.
        Marketcube reserves the right to change its price lists. Marketcube shall notify You of a change in the fees
        charged for the Service at least fourteen (14) days in advance. Should You wish not to accept such change in fees,
        You may terminate this Agreement in accordance with Section 7 below.
        Unless separately agreed otherwise, any fees shall be charged monthly in advance. For invoices sent separately,
        the payment term is fourteen (14) days net from the date of the invoice.
        Interest to late payments shall accrue in accordance with the Interest Act (20.8.1982/633, as amended).
        Marketcube reserves the right to suspend the provision of the Service due to any payments that remain unpaid after
        fourteen (14) days from the payment date of the invoice until such due amounts have been paid in full.
        All listed fees and any amounts payable are net amounts exclusive of possibly applicable VAT, sales tax, or any
        other applicable taxes and charges imposed by any government entity in connection with Your use of the Service.
        You are solely liable for any such taxes and charges.`,
      paraField5: `Marketcube will use its reasonable commercial efforts to keep the Service available 24/7 with an uptime
        percentage as high as possible.
        If the Service is available less than 95% of time during any calendar month, You are entitled to service
        credit (“Service Credit”) in accordance with the following table by requesting the Service Credit from
        Marketcube in writing within thirty (30) days from the end of the relevant calendar month:
        Service Availability during the Calendar Month Service Credit as % of the Monthly Fee `,
      paraField21: `Marketcube will deduct the Service Credit from Your next invoice. Service Credits are always
        compensated by way of price reductions from future invoices, and are not paid out as refunds. If this Agreement
        is terminated for any reason, all liability of Marketcube as regards Service Credit shall terminate upon the
        termination of this Agreement. The Service Credits are calculated only based on the fixed monthly fee, and shall
        not affect any transaction commissions or other forms of payments paid to the Service Provider.
        When calculating the monthly availability, any downtime of the Service shall not be taken into account if it
        is caused by any of the following:
        Factors outside of Marketcube's reasonable control, including any force majeure events; any reason attributable
        to any third party used in provision or in connection to providing the service, e.g. payment gateways, hosting
        providers, name server provider etc.; any actions or inactions by You, any party acting on Your behalf, or any
        other third party; planned maintenance.
        Service levels and Service Credits set out in this Section 3 are not applied to any Service offerings that are
        made available to You free of charge. This Section 3 sets out the entire liability of Marketcube and Your sole
        remedy for any downtime of the Service. For the avoidance of doubt, Marketcube assumes no
        liability as regards any downtime of any Service offerings that are made available to You free of charge.`,
      paraField6: `Any and all intellectual property rights to the Service remain the sole and exclusive property of
        Marketcube or third parties. Subject to any fees agreed to be paid for the Service, You are granted, for the
        duration of this Agreement, a non-exclusive, limited, non- transferable, non-sublicensable right to use the
        Service for the sole purpose of setting up the Marketplace.
        You may choose to use third party services (such as payment services) offered to You in connection with the
        Service. Such third party services shall be solely subject to their applicable third party terms and conditions.`,
      paraField7: `Any and all intellectual property rights to any material You have provided to the Marketplace shall
        remain Your sole and exclusive property. You grant Marketcube a perpetual, irrevocable, royalty-free,
        non-exclusive, sublicensable and transferable license to use, copy, reproduce, modify, make available and
        distribute such material for the purposes of making available the Service.
        Any and all intellectual property rights to any material provided by end-users of the Marketplace shall remain
        the property of such end-users or third parties. You undertake to ensure, in the Marketplace Terms, that such
        end-users grant (and have the right to grant) to You a perpetual, irrevocable, royalty-free, non-exclusive,
        sublicensable and transferable license to use, copy, reproduce, modify, make available and distribute such
        material so that You may grant a similar license to Marketcube for the purposes of making available the Service.
        You hereby agree to grant such licenses to Marketcube as you acquire such rights Yourself from the end-users or
        from other third parties.
        You may, at any time during the term of this Agreement, request that Marketcube provides You with a copy of the
        material provided by You or by the end-users of the Marketplace. Such material shall be provided by making it
        available to You in a reasonable manner separately agreed by You and Marketcube. Marketcube shall have the right
        to invoice you any reasonable expenses incurred by Marketcube as a result of making the material available to You.`,
      paraField8: `You agree to indemnify, defend and hold Marketcube, its affiliates, subsidiaries, directors,
        officers and employees (collectively “Indemnified Person(s)“) harmless from and against any and all third party
        claims and any related liability, loss, and expense (including damage awards, settlement amounts, and reasonable
        legal fees), brought against any Indemnified Person(s), arising out of, related to or which may arise from Your
        breach of the terms and conditions of this Agreement or in relation to the Marketplace or
        any business, activity or transactions carried out or performed on the Marketplace.`,
      paraField9: `Subject to any applicable service levels set out in Section 3, the Service is provided on an “as is”
        basis, and Your sole recourse in the event Your dissatisfaction with the Service is to terminate this Agreement
        in accordance with Section 7 below. Marketcube makes no warranty, express or implied, and expressly disclaims
        the warranties or conditions of availability, correctness, non-infringement, merchantability, and fitness of
        the Service for any particular purpose.
        In no event shall Marketcube be liable for any consequential, special, indirect, exemplary, or punitive damages
        (including without limitation loss of profits or damages caused due to decrease or interruption in turnover or
        production) whether in contract, tort or any other legal theory, even if Marketcube has been advised of the
        possibility of such damages.
        The total aggregate liability of Marketcube under this Agreement is limited to the aggregate amount paid by
        You as monthly fees for the Service during the three month period preceding the incident on which the relevant
        claim is based.
        Without limiting the foregoing, Marketcube shall not have any liability for any failure or delay resulting from
        any condition beyond the reasonable control of Marketcube, including but not limited to: strikes or other labor
        conditions; fire, thunderstorms, incidents of severeweather and natural disasters; outages of power, network or
        network connections; failures, breaches, delays or nonperformance of cloud service providers or other suppliers
        used in connection with the Service.`,
      paraField10: "The Agreement is valid until terminated by either party in accordance with the following:",
      paraField11: `Either Party may terminate this Agreement for cause by a written notice to the other Party, in case
        such other Party is in material breach of this Agreement and has not remedied such breach within thirty (30) days
        of the receipt of a written notice detailing such breach.
        Upon the termination of this Agreement for any reason:`,
      paraField12: `If Marketcube terminates this Agreement for convenience in the middle of a subscription period for
        which You have paid advance fees, or if You terminate this Agreement due to change(s) made unilaterally by
        Marketcube, Marketcube shall refund You any fees actually paid for the Service in respect of such calendar
        months during which You cannot use the Service due to the termination. Payment of refund shall be made upon
        Your request only and provided that You furnish Marketcube with Your account number and other such information
        as is required to make the refund.
        Any Sections of this Agreement that by which their nature are intended to survive the termination of this Agreement,
        shall so survive.`,
      paraField13: `Marketcube may use general information (such as the name and/or logo) related to You and the
        Marketplace in its marketing of the Service pursuant to good business practices. You may present Yourself
        on the Marketplace and in public as a user of the Servicepursuant to good business practises and reasonable
        guidelines submitted by Marketcube from time to time.`,
      paraField14: `Marketcube may change the terms and conditions of this Agreement or the price list at any time by
        posting the changed information and documents at marketcube.io and by using its reasonable efforts to inform You
        of the change via email, at marketcube.io or otherwise. Should You not wish to continue the use of the Service
        under the amended Agreement, You can terminate this Agreement to end on the date on which the changes would take
        effect by informing Marketcube of the termination before such date. By continuing to use the Service following
        such changes You agree to be bound by the amended Agreement. Any other modifications to this Agreement must be
        made in writing executed by both Parties.`,
      paraField15: `This Agreement (together with a possible separate written agreement referring to and incorporating
        these terms and conditions) constitutes the entire agreement between the Parties with respect to the subject
        matter hereof, and supersedes all proposals, oral or written, all previous negotiations, and all other
        communications between the Parties with respect to the subject matter of the Agreement.
        For the avoidance of doubt, third party terms and conditions may be applicable to the Service or parts
        thereof if such third party services are used by You as a part of the Service. In such case any third party
        terms and condition shall be solely applicable to such third party services.`,
      paraField16: `Nothing in this Agreement shall be construed as creating a partnership, agency, joint venture or any
        legal entity between Marketcube and You. Marketcube is not acting as Your representative or agent with respect
        to the Service. The relationship between Marketcube and You is one of independent contractors.
        For the avoidance of any doubt, You may not make any commitments on the behalf of Marketcube.`,
      paraField17: `The failure to require performance of any provision shall not affect a Party’s right to require
        performance at any time thereafter, nor shall a waiver of any breach or default of this Agreement constitute
        a waiver of any subsequent breach or default or a waiver of the provision itself.`,
      paraField18: `If any provision of this Agreement is held unenforceable, then such provision will be modified to reflect
        the Parties’ intention, and the remaining provisions of this Agreement will remain in full force and effect.`,
      paraField19: `You may not assign this Agreement or any of Your rights and obligations hereunder to any third Party.
        Marketcube may assign this Agreement and any of its rights and obligations under this Agreement to its affiliate
        or a third party at any time without notice.`,
      paraField20: `This Agreement shall be governed in accordance with English law.
        Any dispute, controversy or claim arising out of or relating to this Agreement, or the breach, termination or
        validity thereof, shall be finally settled by the courts of England. The seat of arbitration shall be London,
        United Kingdom. The language of the arbitration shall be English.
        Notwithstanding the foregoing, Marketcube reserves the right to claim any unpaid fees in a public court.`,
    },
    h2Field: {
      h2Field1: "1. Service",
      h2Field2: "2. Fees and Payment Terms",
      h2Field3: "3. Service Levels",
      h2Field4: "4. Intellectual Property Rights",
      h2Field5: "5. Indemnification",
      h2Field6: "6. No Warranty, Limitation of Liability",
      h2Field7: "7. Termination",
      h2Field8: "8. Other Provisions",
    },
    boldField: {
      b: "1.1. Marketplace",
      b1: "1.2. Changes to the Service",
      b2: "4.1. The Service",
      b3: "4.2. Marketplace",
      b4: "8.1. Reference",
      b5: "8.2. Amendments to this Agreement",
      b6: "8.3. Entire Agreement",
      b7: "8.4. Independent Contractors",
      b8: "8.5. No Waiver",
      b9: "8.6. Severability",
      b10: "8.7. Assignment",
      b11: "8.8. Governing Law, Resolution of Disputes",
    },
    liField: {
      liField1: "< 95% 10%",
      liField2: "< 93% 25%",
      liField3: "< 90% 50%",
      liField4: `Any offerings or parts of the Service provided to You free of charge may be terminated by You at any
        time by informing Marketcube of such termination in writing;`,
      liField5: `Any offerings subject to fees may be terminated by You by informing Marketcube of such termination in
        writing at least three (3) days prior to the end of the current invoicing period;`,
      liField6: `Any offerings subject to fees may be terminated by You by informing Marketcube of such termination in
        writing at least three (3) days prior to the end of the current invoicing period;`,
      liField7: "Your right to use the Service shall cease immediately;",
      liField8: "Marketcube shall have the right to remove the Marketplace and all associated data from the Service;",
      liField9: `Marketcube shall keep any material provided by You or the end-users of the Marketplace to the
        Service for a period of thirty (30) days, during which time You may request that Marketcube makes
        such material available to You. After such thirty (30) days' period, Marketcube shall have the right to
        delete such material without any liability. Marketcube shall have the right to invoice you any
        reasonable expenses incurred by Marketcube as a result of making the material available to You.`,
    },
  },
  error: {
    email: {
      required: "Email is required",
      matches: "Please enter a valid email",
    },
    password: {
      required: "Password is required",
      matches: "Password must be  minimum of 8 characters contain at least 1 number, and 1 special character.",
      maxMessage: "Password  should not be more than 20 characters",
    },
    confirmPassword: {
      required: "Confirm Password is required",
      matches: "Confirm Password don’t match with Password",
    },
    policy: "Must accept Terms & Conditions",
  },
};

export default register;

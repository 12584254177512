import React, { useContext } from "react";

import { OperatorUpdatePlan } from "app/plans/modules/operator/features";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { Spinner } from "@shopify/polaris";

const { userKey } = constant;
const { operator } = userKey;

const UpdatePlan = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (role === operator) {
      return <OperatorUpdatePlan />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const UpdatePlanComponent = getComponent(currentUserRole);
  return UpdatePlanComponent;
};

export default UpdatePlan;

import { gql } from "apollo-boost";

const GET_ORDER_DETAILS = gql`
  query getOrderDetails($input: GetOrderDetail!) {
    getOrderDetails(input: $input) {
      data {
        isNotFound
        orderDataRow {
          createdAt
          moneyFormat
          orderNumber
          shopifyOrderId
          shipping {
            amount
          }
          shippingAddress {
            name
            phone
            address1
            address2
            city
            province
            country
            zip
          }
          totalPrice
          totalTax
          taxesIncluded
        }
        orderLineItemsData {
          createdAt
          commissionVendor
          commissionSeller
          markUpPrice
          name
          price
          quantity
          sellerDiscount
          shippingAmount
          totalDiscount
          totalTax
          sku
        }
        seller {
          address
          brandName
          firstName
          lastName
          phoneNumber
          city
          logo
          plan {
            isBranding
          }
        }
      }
      status
      error
    }
  }
`;

export default GET_ORDER_DETAILS;

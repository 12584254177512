import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";

import constant from "lib/constant/constant";

import { OperatorProductSetting } from "./operator/features";
import { ProviderProductSetting } from "./provider/features";

const { userKey } = constant;
const { operator, provider } = userKey;

const ProductSetting = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorProductSetting />;
    }
    if (role === provider) {
      return <ProviderProductSetting />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const ProductSettingComponent = getComponent(currentUserRole);
  return ProductSettingComponent;
};

export default ProductSetting;

import React, { useState, useContext } from "react";
import { Card, Checkbox, FormLayout, Layout, PageActions, TextContainer } from "@shopify/polaris";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";

import constant from "lib/constant/constant";
import { PrivateContext, OnboardingContext } from "lib/context";
import { baseHelper } from "lib/helpers";

// import component
import config from "config";
import FileUpload from "lib/components/fileInput/fileUpload";
import { BULK_SUPPLIER_CSV } from "../../../../../../apollo/mutations";

const getCSVData = (csvData) => {
  const regexForEnterKey = /\n,|\n/gi; // TODO: Need updation after encoding resolution
  const csvDataAfterRegex = csvData.data.replace(regexForEnterKey, "\n");
  return csvDataAfterRegex;
};
const BulkCreate = (props) => {
  const { setBanner, learnMore } = props;
  const { gql } = constant;
  const { history, cms = {} } = useContext(PrivateContext) || {};
  const { isOnboarding = false, onNext, onPrevious } = useContext(OnboardingContext);
  const [values, setValues] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [shippingLabel, setShippingLabel] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [bulkSupplierUsingCSVRequest] = useMutation(BULK_SUPPLIER_CSV);

  const onSubmit = async () => {
    setSubmitButtonLoading(true);
    if (!values.data) {
      setBanner({
        isOpen: true,
        title: `${cms("message.error.csvIsRequired")}`,
        status: "critical",
      });
      setSubmitButtonLoading(false);
      return;
    }
    setBanner({
      isOpen: true,
      title: cms("section.bulkCreate.message.success"),
      status: "info",
    });
    const modifiedFormat = getCSVData(values);
    const requestData = {
      file: {
        file: [modifiedFormat],
        fileNames: [values.fileName],
      },
      isVerified: shippingLabel,
      isReadOnly: readOnly,
    };
    try {
      const res = await bulkSupplierUsingCSVRequest({
        variables: {
          input: requestData,
        },
      });

      const resData = baseHelper.getResponseData(res.data, gql.BULK_SUPPLIER_CSV);
      let banner = {
        action: {
          id: "viewLogs",
          content: cms("message.successAction"),
          onAction: () => history.push("/activity/bulk"),
        },
        isOpen: true,
        status: "success",
        title: cms("section.bulkCreate.message.successAction"),
        children: cms("section.bulkCreate.message.success"),
      };
      setIsSubmitted(true);
      setSubmitButtonLoading(false);
      if (!resData) {
        const error = baseHelper.getResponseError(res.data, gql.BULK_SUPPLIER_CSV);
        banner = {
          action: null,
          isOpen: true,
          children: null,
          status: "critical",
          title: error,
        };
      }
      setBanner({
        action: banner.action,
        children: banner.children,
        isOpen: banner.isOpen,
        status: banner.status,
        title: banner.title,
      });
      if (isOnboarding && resData) {
        onNext();
      }
    } catch (error) {
      setSubmitButtonLoading(false);
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  const handleChangeReadOnly = () => {
    setReadOnly(!readOnly);
  };

  const handleChangeShippingLabel = () => {
    setShippingLabel(!shippingLabel);
  };
  const isAnyEmptyField = () => {
    return !values.data;
  };

  return (
    <div>
      <Layout.AnnotatedSection
        title={cms("section.bulkCreate.title")}
        description={cms("section.bulkCreate.description")}
      >
        <Card
          title={cms("section.bulkCreate.title")}
          actions={[{ content: "Learn more", onAction: () => learnMore(cms("section.bulkCreate.title"), "TODO: ") }]}
        >
          <Card.Section>
            <FormLayout>
              <TextContainer>
                If you want to setup your vendors yourself then create them using manually or by uploading your list as
                a CSV.
              </TextContainer>
              <FileUpload setValue={setValues} downloadCSV={`${config.rootURL}/file-download/vendor`} values={values} />
            </FormLayout>
          </Card.Section>
        </Card>
        <Card title="Vendor verification">
          <Card.Section>
            <TextContainer>
              <p>
                {`A vendor is required to verify their email when joining the platform however, when creating 
                a vendor you can choose to 'Auto verify vendor' thereby avoiding the need to validate their 
                email. This is useful when you are 'creating' vendor accounts and hence is selected by default.`}
              </p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={cms("common.label.autoVerifyVendor")}
                checked={shippingLabel}
                onChange={handleChangeShippingLabel}
                id="autoVerifyCheckBox"
              />
            </FormLayout>
          </Card.Section>
        </Card>

        <Card title="Vendor's access permission">
          <Card.Section>
            <TextContainer>
              <p>
                {`By default a vendor has full write access in their account however you can set a vendor's access to be
                'read only'. With 'read only' access a vendor can log into their account but they are not able to
                perform any create, update or delete actions ie. add or manage products and orders or change any
                settings.`}
              </p>
              <p>
                This permission can be revoked or added at any time by you after a vendor has been created. This feature
                is particulariy useful when you are looking to manage on behalf of your vendors.
              </p>
            </TextContainer>
            <br />
            <FormLayout>
              <Checkbox
                label={cms("common.label.isReadOnlyVendor")}
                checked={readOnly}
                onChange={handleChangeReadOnly}
                id="readOnlyCheckbox"
              />
            </FormLayout>
          </Card.Section>
        </Card>
        <PageActions
          primaryAction={{
            content: cms("common.button.upload"),
            id: "submitButton",
            onAction: () => onSubmit(),
            loading: submitButtonLoading,
            disabled: isAnyEmptyField() || isSubmitted,
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              id: "cancelButton",
              onAction: () => (isOnboarding ? onPrevious() : history.push("/")),
            },
          ]}
        />
      </Layout.AnnotatedSection>
      <br />
    </div>
  );
};

BulkCreate.propTypes = {
  setBanner: PropTypes.func.isRequired,
  learnMore: PropTypes.func.isRequired,
};

export default BulkCreate;

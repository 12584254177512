import React from "react";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

import { OperatorProductList } from "./operator/features";
import { ProviderProductList } from "./provider/features";
import { AdminProductList } from "./admin/features";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const ProductList = () => {
  const currentUserRole = storageHelper.get("userRole");
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminProductList />;
    }
    if (role === operator) {
      return <OperatorProductList />;
    }
    if (role === provider) {
      return <ProviderProductList />;
    }
    return null;
  };

  const OrderListComponent = getComponent(currentUserRole);
  return OrderListComponent;
};

export default ProductList;

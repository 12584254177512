import React, { useContext } from "react";
import { Card, TextField, FormLayout, TextContainer } from "@shopify/polaris";

import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
import { FormContext } from "../../../context/context";

const {
  PRICE = "",
  COMPARE_PRICE = "",
  value: { MIN_PRICE = 0, MAX_FLAT = 9999 },
  symbol: { DOLLAR },
} = constant;

const Price = () => {
  const {
    cms,
    currentUser: { moneyFormat = DOLLAR },
  } = useContext(PrivateContext);
  const { data, handleChange, learnMore } = useContext(FormContext);

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue);
  };

  return (
    <Card
      title={cms("section.form.section.price.title")}
      id="productPrice"
      actions={[
        {
          content: cms("common.label.learnMore"),
          onAction: () => {
            learnMore("productPrice", cms("section.form.section.price.title"));
          },
        },
      ]}
    >
      <Card.Section>
        <FormLayout>
          <TextContainer>{cms("section.form.section.price.description")}</TextContainer>
          <FormLayout.Group condensed>
            <TextField
              id="price"
              label={cms("section.form.section.price.label.price")}
              min={MIN_PRICE}
              max={MAX_FLAT}
              value={data.price || ""}
              placeholder="0.00"
              prefix={`${moneyFormat || ""}`}
              onChange={(value) => {
                handleChange(PRICE, acceptOnlyValidInput(value, data.price));
              }}
            />
            <TextField
              id="comparePrice"
              label={cms("section.form.section.price.label.comparePrice")}
              min={MIN_PRICE}
              max={MAX_FLAT}
              prefix={`${moneyFormat || ""}`}
              placeholder="0.00"
              value={data.comparePrice || ""}
              onChange={(value) => {
                handleChange(COMPARE_PRICE, acceptOnlyValidInput(value, data.comparePrice));
              }}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    </Card>
  );
};

export default Price;

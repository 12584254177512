import React from "react";
// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

import {
  ManageSetupRule,
  ManagePayment,
  ManageShippingCharge,
  HideShippingDescription,
  ManageAggregateShipping,
} from "./subItems";

const VendorManager = (props) => {
  const {
    manageSetupRuleProps,
    managePaymentProps,
    manageShippingChargeProps,
    manageIsHideDescriptionProps,
    manageAggregateShippingSettingProps,
    setBannerStatus,
  } = props;
  return (
    <>
      <ManageSetupRule manageSetupRuleProps={manageSetupRuleProps} />
      <ManagePayment managePaymentProps={managePaymentProps} />
      <ManageShippingCharge manageShippingChargeProps={manageShippingChargeProps} />
      <HideShippingDescription manageIsHideDescriptionProps={manageIsHideDescriptionProps} />
      <ManageAggregateShipping
        manageAggregateShippingSettingProps={manageAggregateShippingSettingProps}
        setBannerStatus={setBannerStatus}
      />
    </>
  );
};

// export default VendorManager;
export default withFeature(withErrorBoundary(VendorManager), { feature: "vendorManageShipping" });

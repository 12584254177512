import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/react-hooks";

import Moment from "moment";
import Baby from "babyparse";
import FileDownload from "js-file-download";

// import hoc
import { withFeature } from "lib/hoc";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import components
import { Banner, Spinner } from "lib/components";

// import helpers
import { baseHelper } from "lib/helpers";
import paymentHelper from "./helper/payment";

// import query
import { GET_ORDER_LINES } from "../../../apollo/queries";

const Export = () => {
  const data = useContext(PrivateContext);
  const { t } = useTranslation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [
    getOrderLines,
    { data: getOrderData, loading: getOrderLoading, error: getOrderError },
  ] = useLazyQuery(GET_ORDER_LINES, { variables: { input: queryData } });
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
  });

  useEffect(() => {
    if (!getOrderLoading && !getOrderError && getOrderData) {
      const resData = baseHelper.getResponseData(getOrderData, constant.gql.GET_ORDER_LINES);
      let bannerData = false;
      if (!resData) {
        const resError = baseHelper.getResponseError(getOrderData, constant.gql.GET_ORDER_LINES);
        bannerData = {
          title: resError,
          status: "critical",
          isOpen: true,
        };
      }
      if (!resData.length) {
        bannerData = {
          title: t("message.exportPayment.noPayment"),
          status: "critical",
          isOpen: true,
        };
      }
      if (bannerData) {
        setBanner(bannerData);
        setTimeout(() => {
          window.close();
        }, 2000);
        return;
      }
      const csvData = paymentHelper.paymentCSVData(resData);
      const csv = Baby.unparse(csvData);
      const date = Moment(new Date(), "DD-MM-YYYY").format("LL");
      FileDownload(csv, `${"payment"} ${date}.csv`);
      setTimeout(() => {
        window.close();
      }, 500);
    }
  }, [getOrderData, getOrderError, getOrderLoading, t]);

  useEffect(() => {
    const exportPayment = () => {
      const dataToFetch = {};
      const { status, fulfillmentStatus, vendor, accountingStatus } = baseHelper.queryParamsFromLocation(data);
      if (status && status.length) {
        dataToFetch.status = status.split(",");
      }
      if (fulfillmentStatus && fulfillmentStatus.length) {
        dataToFetch.fulfillmentStatus = fulfillmentStatus.split(",");
      }
      if (vendor && vendor.length) {
        dataToFetch.vendor = vendor.split(",");
      }
      if (accountingStatus && accountingStatus.length) {
        dataToFetch.accountingStatus = accountingStatus.split(",");
      }
      setQueryData(dataToFetch);
      getOrderLines();
    };
    if (isPageLoading) {
      exportPayment();
      setIsPageLoading(false);
    }
  }, [data, getOrderLines, isPageLoading]);

  return (
    <Layout>
      {getOrderLoading && <Spinner isFullPage />}
      <Layout.Section>
        {banner.isOpen && (
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        )}
      </Layout.Section>
    </Layout>
  );
};

export default withFeature(Export, { feature: constant.EXPORT_PAYMENT });

// import packages
import React, { useState, useEffect, useContext } from "react";
import { Layout, Page } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";

// import helpers components
import { Banner, Spinner } from "lib/components";
import { NotFound } from "layout/private/components";
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import helpers
import { baseHelper } from "lib/helpers";

// import gql
import { GET_FULFILLMENT } from "app/setup/apollo";
import { INVOICE_DATA } from "../../../apollo/queries";

// import sub Feature
import OrderSlipView from "../view/view";

const InvoiceDownload = () => {
  const [banner, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    onDismiss: null,
  });
  const [sellerInfo, setSellerInfo] = useState(false);
  const [vendorInfo, setVendorInfo] = useState(false);
  const { t } = useTranslation();
  const defaultLogo = constant.MARKET_CUBE;

  const { gql } = constant;
  const { match, currentUser } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const { introductorySeller = "", _id } = currentUser;
  const currentUserId = isVendor ? introductorySeller : _id;
  const { params } = match;
  const { id } = params;
  const { lineItemId } = params;
  const [isInvoiceFound, setIsInvoiceFound] = useState(true);
  const [fulfillmentItem, setFulfillmentItems] = useState({});

  const [logo, setLogo] = useState(false);
  const [orderData, setOrderData] = useState(false);
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const { error: queryDataError, loading: queryDataLoading, data: queryData } = useQuery(INVOICE_DATA, {
    variables: { input: { id, lineItemId } },
  });

  const { data: fulfillmentData } = useQuery(GET_FULFILLMENT, {
    variables: {
      input: { ownerId: currentUserId },
    },
  });

  const imageToDataURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  useEffect(() => {
    const responseData = baseHelper.getResponseData(queryData, gql.GET_INVOICE_DATA);
    const fulfillmentResponseData = baseHelper.getResponseData(fulfillmentData, constant.gql.GET_FULFILLMENT) || {};
    const responseError = baseHelper.getResponseError(queryData, gql.GET_INVOICE_DATA);
    if (responseData) {
      setIsInvoiceFound(true);
      const { seller, vendor, order, orderLine } = responseData;
      if (seller && seller.plan && seller.plan.isBranding) {
        imageToDataURL(seller.logo, (url) => {
          setFulfillmentItems(fulfillmentResponseData);
          setOrderData(order);
          setOrderLineItems(orderLine);
          setSellerInfo(seller);
          setVendorInfo(vendor);
          setLogo(seller.logo && url);
        });
      } else {
        imageToDataURL(defaultLogo, (url) => {
          setFulfillmentItems(fulfillmentResponseData);
          setOrderData(order);
          setOrderLineItems(orderLine);
          setSellerInfo(seller);
          setVendorInfo(vendor);
          setLogo(url);
        });
      }
    }
    if (responseError) {
      setIsInvoiceFound(false);
      setNotFound(true);
      setBanner({ isOpen: true, title: responseError, status: "critical" });
    }
  }, [defaultLogo, queryData, gql.GET_INVOICE_DATA, fulfillmentData]);

  useEffect(() => {
    if (queryDataError) {
      setIsInvoiceFound(false);
      setBanner({ isOpen: true, title: t("error.common.somethingWentWrong"), status: "critical" });
    }
  }, [queryDataError, t]);

  if (notFound) {
    return (
      <div className="Polaris-Page__OrderSlip">
        <NotFound />
      </div>
    );
  }
  if (queryDataLoading || (isInvoiceFound && !sellerInfo)) {
    return <Spinner isFullPage />;
  }

  return (
    <div className="Polaris-Page__Invoice wrapper">
      <Page>
        {banner.isOpen && (
          <Layout.Section>
            <Banner
              title={banner.title}
              status={banner.status}
              message={banner.message}
              onDismiss={() => {
                setBanner({ isOpen: false, title: "", status: "" });
              }}
            />
          </Layout.Section>
        )}
        {isInvoiceFound && sellerInfo && (
          <OrderSlipView
            sellerInfo={sellerInfo}
            orderData={orderData}
            logo={logo}
            vendorInfo={vendorInfo}
            orderLineItems={orderLineItems}
            fulfillment={fulfillmentItem}
          />
        )}
      </Page>
    </div>
  );
};

export default InvoiceDownload;

import { gql } from "apollo-boost";

const UPDATE_TERM = gql`
  mutation setupTerm($input: UpdateTerm) {
    updateTerm(input: $input) {
      data
      status
      error
    }
  }
`;

export { UPDATE_TERM };

const cmsCustomDomain = (t) => {
  const cmsData = {
    mainLink: {
      shop: "/sellers/view/",
    },
    sortOptions: [
      { label: t("label.customDomainList.sortByDateAsc"), value: "createdAt_asc" },
      { label: t("label.customDomainList.sortByDateDesc"), value: "createdAt_desc" },
      { label: t("label.customDomainList.sortByShopAsc"), value: "shop_asc" },
      { label: t("label.customDomainList.sortByShopDesc"), value: "shop_desc" },
    ],
    resourceName: {
      singular: t("label.customDomainList.singular"),
      plural: t("label.customDomainList.plural"),
    },
    tabs: [
      {
        id: "all-sellers",
        content: t("label.customDomainList.all"),
        accessibilityLabel: "All sellers",
        panelID: "all-sellers-content",
        value: "all",
      },
      {
        id: "active-domain",
        content: t("label.customDomainList.activeDomains"),
        panelID: "active-domain-content",
        value: "active",
      },
      {
        id: "inactive-domain",
        content: t("label.customDomainList.inActiveDomains"),
        panelID: "inactive-domain-content",
        value: "inactive",
      },
      {
        id: "trial",
        content: t("label.customDomainList.trial"),
        panelID: "trial-content",
        value: "trial",
      },
    ],
    badge: ["verified"],
    tableData: {
      tbody: [],
    },
    message: {
      somethingWentWrong: t("error.common.somethingWentWrong"),
    },
    label: {
      approved: t("label.customDomainList.approved"),
      pending: t("label.customDomainList.pending"),
      disable: t("label.customDomainList.disable"),
      disabled: t("label.customDomainList.disabled"),
      domain: t("label.customDomainList.domain"),
      email: t("label.customDomainList.email"),
      enable: t("label.customDomainList.enable"),
      enabled: t("label.customDomainList.enabled"),
      onBoardedOn: t("label.customDomainList.onBoardedOn"),
      qoutaLimit: t("label.customDomainList.qoutaLimit"),
      successfullyEnabledCustomDomain: "Successfully enabled custom domain.",
      successfullyDisabledCustomDomain: "Successfully disabled custom domain."
    },
  };
  return cmsData;
};

export default cmsCustomDomain;

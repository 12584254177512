import { gql } from "apollo-boost";

const GET_ASSOCIATED_SELLER = gql`
  query getAssociatedSeller($input: AssociatedInput) {
    getAssociatedSeller(input: $input) {
      data {
        email {
          address
        }
        firstName
        lastName
        phoneNumber
      }
      status
      error
    }
  }
`;

export default GET_ASSOCIATED_SELLER;

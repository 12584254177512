import React, { useContext } from "react";
import { MediaCard, List, Caption } from "@shopify/polaris";

import { storageHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

import finishImage from "asset/images/onBoarding/finish.png";

const Finish = () => {
  const { currentUserRefetch, isLoading, cms = {} } = useContext(PrivateContext);
  return (
    // <Layout>
    //   <Layout.Section>
    //     <Card
    //       title="Awesome"
    //       primaryFooterAction={{
    //         content: "Start using your marketplace",
    //         onAction: () => {
    //           storageHelper.remove("setupProgress");
    //           currentUserRefetch();
    //         },
    //         loading: isLoading,
    //       }}
    //       sectioned
    //     >
    //       <p>You have just finished setting up your account</p>
    //       <br />
    //       <br />
    //       <center>
    //         <img height="auto" width="32%" src={finishImage} alt="marketcube domain" />
    //       </center>
    //     </Card>
    //   </Layout.Section>
    // </Layout>
    <MediaCard
      title={cms("common.onboarding.finish.title")}
      primaryAction={{
        content: cms("common.onboarding.finish.button.primary"),
        button: "primary",
        onAction: () => {
          storageHelper.remove("setupProgress");
          currentUserRefetch();
        },
      }}
      secondaryAction={{
        content: cms("common.onboarding.finish.button.secondary"),
        url: "https://meeting.marketcube.io/meetings/marketcube/white-glove-assisted-setup",
        external: true,
        onAction: () => {},
      }}
      description={[
        <>
          <p>{cms("common.onboarding.finish.description")}</p>
          <br />
          <p>{cms("common.onboarding.finish.nextStep")}</p>
          <br />
          <p>
            <List type="number">
              {cms("common.onboarding.finish.list") &&
                cms("common.onboarding.finish.list").map((list) => <List.Item>{list}</List.Item>)}
              <List.Item>
                {cms("common.onboarding.finish.bookSession")}
                <sup>*</sup>
              </List.Item>
            </List>
          </p>
          <br />
          <p>
            <Caption>
              <sup>*</sup>
              {cms("common.onboarding.finish.paragraph")}
            </Caption>
          </p>
          <br />
          <p>
            {/* <!-- Start of Meetings Embed Script --> */}
            {/* <div class="meetings-iframe-container" data-src="https://meeting.marketcube.io/meetings/marketcube/white-glove-assisted-setup?embed=true"></div>
            <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script> */}
            {/* <!-- End of Meetings Embed Script --> */}
          </p>
        </>,
      ]}
    >
      <img
        alt=""
        width="100%"
        height="100%"
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        src="https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg"
      />
    </MediaCard>
  );
};

export default Finish;

import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Card, FormLayout, Layout, TextField } from "@shopify/polaris";

// import constant and helper components
import constant from "lib/constant/constant";
import { Banner, SkeletonAnnotated } from "lib/components";

// import helper and context
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

// import yup validation
import validate from "../yup";

// import gql
import { CHANGE_PASSWORD } from "../../../apollo/mutation";

const ChangePassword = () => {
  const { history, isLoading, cms } = useContext(PrivateContext);
  const [updatePassword, { loading }] = useMutation(CHANGE_PASSWORD);
  const { PASSWORD, NEW_PASSWORD, CONFIRM_PASSWORD, gql } = constant;
  const [values, setValues] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [errorMessage, setErrorMessage] = useState(true);
  const isDisabled = () => {
    const isAllValuesFilled = !(values.password && values.newPassword && values.confirmPassword);
    return isAllValuesFilled;
  };
  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const handleChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const redirect = () => {
    setTimeout(() => {
      history.push("/setting");
    }, 2000);
  };

  const onSubmit = async () => {
    await handleValidation(PASSWORD, values.password);
    await handleValidation(NEW_PASSWORD, values.newPassword);
    await handleValidation(CONFIRM_PASSWORD, {
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    });
    try {
      const res = await updatePassword({
        variables: {
          input: { newPassword: values.newPassword, password: values.password },
        },
      });
      const resData = baseHelper.getResponseData(res.data, gql.CHANGE_PASSWORD);
      let bannerValue = {
        status: "success",
        title: cms("message.success"),
      };
      let isChangedPassword = true;
      if (!resData) {
        isChangedPassword = false;
        const error = baseHelper.getResponseError(res.data, gql.CHANGE_PASSWORD);
        bannerValue = { status: "critical", title: error };
      }
      setBanner({
        status: bannerValue.status,
        isOpen: true,
        title: bannerValue.title,
      });
      if (isChangedPassword) {
        redirect();
      }
    } catch (err) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }
  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            title={banner.title}
            status={banner.status}
            isOpen={banner.isOpen}
            onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
          />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("title")}>
        <Card
          sectioned
          primaryFooterAction={{
            content: cms("common.button.submit"),
            id: "submitButton",
            onAction: () => onSubmit(),
            disabled: isDisabled(),
            loading,
          }}
          secondaryFooterActions={[
            {
              id: "cancelButton",
              content: cms("common.button.cancel"),
              onAction: () => history.push("/"),
            },
          ]}
        >
          <FormLayout>
            <TextField
              id="password"
              label={`${cms("common.label.password")}*`}
              placeholder={cms("common.placeholder.password")}
              type={PASSWORD}
              value={values.password || ""}
              onChange={(value) => handleChange(PASSWORD, value)}
              onBlur={() => handleValidation(PASSWORD, values.password)}
              error={errorMessage && errorMessage.password}
            />
            <TextField
              id="newPassword"
              label={`${cms("label.newPassword")}*`}
              type={PASSWORD}
              value={values.newPassword || ""}
              placeholder={cms("placeholder.newPassword")}
              onChange={(value) => handleChange(NEW_PASSWORD, value)}
              onBlur={() => handleValidation(NEW_PASSWORD, values.newPassword)}
              error={errorMessage && errorMessage.newPassword}
            />
            <TextField
              id="confirmPassword"
              label={`${cms("label.confirmNewPassword")}*`}
              placeholder={cms("placeholder.confirmNewPassword")}
              type={PASSWORD}
              value={values.confirmPassword || ""}
              onChange={(value) => handleChange(CONFIRM_PASSWORD, value)}
              onBlur={() => {
                handleValidation(CONFIRM_PASSWORD, {
                  newPassword: values.newPassword,
                  confirmPassword: values.confirmPassword,
                });
              }}
              error={errorMessage && errorMessage.confirmPassword}
            />
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
};
export default ChangePassword;

import { gql } from "apollo-boost";

const SYNC_VENDOR_WOOCOMMERCE_PRODUCT = gql`
  mutation syncVendorWooCommerceProduct($input: BulkAction!) {
    syncVendorWooCommerceProduct(input: $input) {
      data
      status
      error
    }
  }
`;

export default SYNC_VENDOR_WOOCOMMERCE_PRODUCT;
import React, { useState, useContext, useEffect } from "react";
import { Layout } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import gql
import { GET_SHIPPING_BAND } from "app/shipping/apollo/queries";

// helper
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import Banner from "lib/components/banner/banner";
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import List from "./list";

const ProviderShippingBand = () => {
  const { cms } = useContext(PrivateContext);
  const [bannerStatus, setBanner] = useState({
    isOpen: false,
    title: "",
    status: "",
    message: "",
  });

  const { loading, data, error, refetch, networkStatus } = useQuery(GET_SHIPPING_BAND, {
    notifyOnNetworkStatusChange: true,
  });

  const responseData = baseHelper.getResponseData(data, constant.gql.GET_SHIPPING_BAND);
  const errorData = baseHelper.getResponseError(data, constant.gql.GET_SHIPPING_BAND);
  const { shippingRows = [], shippingTerm = "" } = responseData || {};

  useEffect(() => {
    if (responseData && !shippingRows.length) {
      const bannerData = {
        isOpen: true,
        status: constant.WARNING,
        title: cms("provider.message.addShippingBand.title"),
        message: cms("provider.message.addShippingBand.content"),
      };
      setBanner(bannerData);
    }
    if (errorData) {
      const bannerData = {
        isOpen: true,
        status: constant.CRITICAL,
        title: errorData,
      };
      setBanner(bannerData);
    }
  }, [responseData, errorData]);

  useEffect(() => {
    if (error) {
      const bannerData = {
        isOpen: true,
        status: constant.CRITICAL,
        title: cms("common.message.error.somethingWentWrong"),
      };
      setBanner(bannerData);
    }
  }, [error]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <Layout.Section>
      <Banner
        isOpen={bannerStatus.isOpen}
        status={bannerStatus.status}
        title={bannerStatus.title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      >
        {bannerStatus.message}
      </Banner>
      <br />
      <List
        bands={shippingRows.length ? shippingRows : []}
        term={shippingTerm}
        loading={loading}
        setBanner={setBanner}
        refetch={refetch}
        networkStatus={networkStatus}
      />
    </Layout.Section>
  );
};

export default withFeature(withErrorBoundary(ProviderShippingBand), { feature: constant.SHIPPING });

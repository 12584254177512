import { gql } from "apollo-boost";

const GET_ORDER_LINES = gql`
  query getOrderLines($input: GetOrderLines) {
    getOrderLines(input: $input) {
      data {
        _id
        vendor
        status
        fulfillmentStatus
        vendorId
        quantity
        price
        orderId
        orderNumber
        accountingStatus
        commissionSeller
        commissionVendor
        createdAt
        shopifyLineItemId
        moneyFormat
        name
      }
      status
      error
    }
  }
`;

const CHECK_ORDER_LINES = gql`
  query checkOrderLines($input: CheckOrderLines) {
    checkOrderLines(input: $input) {
      data {
        _id
        fulfillmentStatus
        vendorId
      }
      status
      error
    }
  }
`;

export { GET_ORDER_LINES, CHECK_ORDER_LINES };

import React, { useContext } from "react";
import { Card, Stack, Button } from "@shopify/polaris";

// import context
import { PrivateContext } from "lib/context/privateContext";

const OtherCurrency = () => {
  const { oldCMS } = useContext(PrivateContext);
  const { message, label } = oldCMS;
  const { currencyBlockPaypal, connectPaypalAccount, payoutCurrency } = message;
  return (
    <>
      {/* <Card sectioned title={connectStripe}>
        <Stack vertical>
          <Stack.Item>
            <p>{currencyBlockStripe}</p>
          </Stack.Item>
          <Stack.Item>
            <p>{payoutCurrency}</p>
          </Stack.Item>
        </Stack>
        <Stack>
          <Stack.Item fill />
          <Stack.Item>
            <Button primary disabled>
              {label.connect}
            </Button>
          </Stack.Item>
        </Stack>
      </Card>
     */}
      <Card sectioned title={connectPaypalAccount}>
        <Stack vertical>
          <Stack.Item>
            <p>{currencyBlockPaypal}</p>
          </Stack.Item>
          <Stack.Item>
            <p>{payoutCurrency}</p>
          </Stack.Item>
        </Stack>
        <Stack>
          <Stack.Item fill />
          <Stack.Item>
            <Button primary disabled>
              {label.connect}
            </Button>
          </Stack.Item>
        </Stack>
      </Card>
    </>
  );
};
export default OtherCurrency;

import React, { useContext } from "react";
import { Select } from "@shopify/polaris";
import PropTypes from "prop-types";

import { PublicContext } from "lib/context";

import constant from "lib/constant/constant";

const Brand = (props) => {
  const { oldCMS: cms } = useContext(PublicContext);
  const { brandName, brands, handleChange, handleValidation, errorMessage } = props;

  const { label = {} } = cms || {};
  return (
    <Select
      id="brandName"
      label={`${label.brandName}*`}
      value={brandName}
      options={brands}
      placeholder={label.brandName}
      onChange={(value) => handleChange(constant.BRAND_NAME, value)}
      onBlur={() => handleValidation(constant.BRAND_NAME, brandName)}
      error={errorMessage && errorMessage.brandName}
    />
  );
};

Brand.propTypes = {
  brandName: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
  errorMessage: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Brand;

import React, { useContext } from "react";
import { Card, TextField, Stack, ColorPicker, Layout, TextStyle, Collapsible, TextContainer } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../../../context/context";

const Header = () => {
  const { color, hex, errorMessage, handleChange, resetDefault, learnMore } = useContext(BrandingContext);
  const { cms = () => {} } = useContext(PrivateContext);
  const { HEX, COLOR, HEADER, DEFAULT_HEADER_COLOR } = constant;

  return (
    <Collapsible open>
      <Layout.AnnotatedSection
        title={cms("section.headerColor.title")}
        description={cms("section.headerColor.description")}
      >
        <Card
          title={cms("section.headerColor.title")}
          actions={[{ content: "Learn more", onAction: () => learnMore(cms("section.headerColor.title"), "TODO: ") }]}
        >
          <Card.Section>
            <TextContainer>{cms("section.headerColor.subDescription")}</TextContainer>
            <br />
            <Stack>
              <Stack.Item fill>
                <TextField
                  label={cms("section.headerColor.label.hexCode")}
                  value={hex.headerHex}
                  onChange={(value) => handleChange(HEADER, value, HEX)}
                  labelAction={
                    hex.headerHex !== DEFAULT_HEADER_COLOR && {
                      content: cms("label.reset"),
                      onClick: () => resetDefault(HEADER, DEFAULT_HEADER_COLOR, HEX),
                    }
                  }
                  error={errorMessage && errorMessage.header}
                />
                <br />
                <TextStyle>
                  <div
                    style={{
                      width: "120px",
                      height: "50px",
                      background: hex.headerHex,
                      color: hex.headerTextHex,
                      textAlign: "center",
                      paddingTop: "13px",
                    }}
                  >
                    {cms("section.headerColor.label.sampleHeader")}
                  </div>
                </TextStyle>
              </Stack.Item>
              <Stack.Item>
                <ColorPicker onChange={(value) => handleChange(HEADER, value, COLOR)} color={color.header} />
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Collapsible>
  );
};

export default Header;

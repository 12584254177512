import React from "react";
import PropTypes from "prop-types";

// import hoc
import { withFeature } from "lib/hoc";

// import constant
import constant from "lib/constant/constant";

import { Invite } from "app/vendors/modules/generic/feature/add/subFeatures";

const AdminInvite = (props) => {
  const { setBanner, learnMore } = props;
  return <Invite setBanner={setBanner} learnMore={learnMore} />;
};

AdminInvite.propTypes = {
  setBanner: PropTypes.func.isRequired,
  learnMore: PropTypes.func.isRequired,
};
export default withFeature(AdminInvite, { feature: constant.INVITE });

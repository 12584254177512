import React from "react";
import { ApolloProvider } from "react-apollo";
import { AppProvider } from "@shopify/polaris";

import "@shopify/polaris/styles.css";
import "./i18n";

import Routes from "./routes";
import client from "./apollo";

const App = () => (
  <ApolloProvider client={client}>
    <AppProvider>
        <Routes />
    </AppProvider>
  </ApolloProvider>
);

export default App;

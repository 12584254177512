import React, { useContext, useState, useEffect } from "react";
import { Layout, Thumbnail, Stack, PageActions, DisplayText } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-apollo";
import _ from "lodash";

// import hoc
import { withFeature } from "lib/hoc";
import constant from "lib/constant/constant";

import { PrivateContext } from "lib/context";
import { Spinner, Banner } from "lib/components";
import { baseHelper, imageHelper } from "lib/helpers";

import GET_PRODUCT_AND_SELLER_PRODUCT from "app/products/apollo/queries/reviewProduct";
import { GET_PRODUCT_SETTING } from "app/products/apollo/queries";
import { APPROVE_ALL_CHANGES, APPROVE_SELLER_CHANGES } from "app/products/apollo/mutations/reviewProduct";

import Variant from "./subFeature/variant";

import content from "./reviewContent";
import ReviewProduct from "./subFeature/reviewProduct";

const noImageUrl = "https://via.placeholder.com/80x80/ffffff/000000?text=No+image";

const OperatorProductReview = () => {
  const { t } = useTranslation();
  const { match, history, currentUser } = useContext(PrivateContext);
  const { params } = match;
  const { id } = params;

  const { labels } = content;

  const [diff, setDiff] = useState({});
  const [keys, setKeys] = useState([]);
  const [vendorProducts, setVendorProducts] = useState({});
  const [sellerProducts, setSellerProducts] = useState({});
  const [isVariant, setIsVariant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [approveLoading, setApproveLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [notApproveLoading, setNotApproveLoading] = useState(false);
  const [unselectedKeys, setUnselectedKeys] = useState([]);
  const [isVariantIdAvailable, setIsVariantIdAvailable] = useState(false);
  const [isHideTagAndType, setIsHideTagAndType] = useState(true);

  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { data, loading, error } = useQuery(GET_PRODUCT_AND_SELLER_PRODUCT, { variables: { input: { _id: id } } });
  const { data: productSettingData, loading: productSettingLoading, error: productSettingError } = useQuery(GET_PRODUCT_SETTING);
  const [approveAllChanges] = useMutation(APPROVE_ALL_CHANGES);
  const [approveSeller] = useMutation(APPROVE_SELLER_CHANGES);

  useEffect(() => {
    const difference = (object, base) =>
      _.transform(object, (result, value, key) => {
        if (value !== base[key]) {
          // eslint-disable-next-line no-param-reassign
          result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
        }
      });
    if (!error && !loading && !productSettingLoading && !productSettingError && data && productSettingData && isLoading) {
      setIsLoading(false);
      const resData = baseHelper.getResponseData(data, constant.gql.GET_PRODUCT_SELLER_PRODUCT);
      const settingData = baseHelper.getResponseData(productSettingData, constant.gql.GET_PRODUCT_SETTING);
      const { isHideTagAndType } = settingData || {};
      setIsHideTagAndType(!!isHideTagAndType);
      const vendorProduct = { ...resData.product };
      const sellerProduct = { ...resData.sellerProduct };

      const { length: productLength = {}, width: productWidth = {}, height: productHeight = {} } =
        (vendorProduct && vendorProduct.measurement) || {};

      vendorProduct.length = (productLength && productLength.value) || 0;
      vendorProduct.width = (productWidth && productWidth.value) || 0;
      vendorProduct.height = (productHeight && productHeight.value) || 0;

      const { length: sellerProductLength = {}, width: sellerProductWidth = {}, height: sellerProductHeight = {} } =
        (sellerProduct && sellerProduct.measurement) || {};

      sellerProduct.length = (sellerProductLength && sellerProductLength.value) || 0;
      sellerProduct.width = (sellerProductWidth && sellerProductWidth.value) || 0;
      sellerProduct.height = (sellerProductHeight && sellerProductHeight.value) || 0;

      const key = _.keys(vendorProduct);

      const sellerImages = (sellerProduct && sellerProduct.images && sellerProduct.images.length) || 0;
      const vendorImages = (vendorProduct && vendorProduct.images && vendorProduct.images.length) || 0;

      const sellerVariants = (sellerProduct && sellerProduct.variants && sellerProduct.variants.length) || 0;
      const vendorVariants = (vendorProduct && vendorProduct.variants && vendorProduct.variants.length) || 0;

      const { images = [] } =
        sellerImages > vendorImages
          ? difference(sellerProduct, vendorProduct)
          : difference(vendorProduct, sellerProduct);

      const diffValue =
        sellerVariants > vendorVariants
          ? difference(sellerProduct, vendorProduct)
          : difference(vendorProduct, sellerProduct);
      delete diffValue.images;
      diffValue.images = [...images];
      const variant = !!(sellerVariants || vendorVariants);
      setDiff(diffValue);
      setKeys(key);
      setVendorProducts(vendorProduct);
      setSellerProducts(sellerProduct);
      setIsVariant(variant);
    }
  }, [data, error, isLoading, keys, loading, productSettingLoading, productSettingError, productSettingData]);

  const getDataInHTMLForm = (value) => ({ __html: value });

  const getData = (value) => (_.isBoolean(value) && (value ? labels.yes : labels.no)) || value;
  const difference = (object, base) =>
    _.transform(object, (result, value, key) => {
      if (value !== base[key]) {
        // eslint-disable-next-line
        result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
      }
    });
  let variantDiffs = [];
  const variantIdAvailable = {};
  const getTableRows = (value) => {
    const tableRows = [];
    const label = [];
    const vendorRow = [];
    const sellerRow = [];
    if (!loading && !error && data) {
      Object.keys(value).map((key) => {        
        const allowedKeys = constant.ALLOWED_KEYS;
        if (isVariant && !allowedKeys.includes(key)) {
          return null;
        }
        if (!(vendorProducts[key] || sellerProducts[key])) {
          return null;
        }
        if(isHideTagAndType && (key === constant.PRODUCT_TAGS || key === constant.PRODUCT_TYPE)) {
          return null;
        }
        if (_.includes(keys, key) && labels[key] && key !== constant.IMAGES && key !== constant.VARIANTS) {
          const isKeyDescription = key === constant.DESCRIPTION;
          const isKeyTags = key === constant.TAGS;
          const vendorData = isKeyTags ? baseHelper.sort(vendorProducts[key]).join(", ") : getData(vendorProducts[key]);
          const sellerData = isKeyTags ? baseHelper.sort(sellerProducts[key]).join(", ") : getData(sellerProducts[key]);
          if (isKeyTags && (!(vendorData || sellerData) || vendorData === sellerData)) {
            return null;
          }
          const vendorProductData = isKeyDescription ? (
            // eslint-disable-next-line react/no-danger
            <p dangerouslySetInnerHTML={getDataInHTMLForm(vendorData)} />
          ) : (
            vendorData
          );
          const sellerProductData = isKeyDescription ? (
            // eslint-disable-next-line react/no-danger
            <p dangerouslySetInnerHTML={getDataInHTMLForm(sellerData)} />
          ) : (
            sellerData
          );
          tableRows.push([labels[key], vendorProductData, sellerProductData, key]);
          label.push(labels[key]);
          vendorRow.push(vendorProductData);
          sellerRow.push(sellerProductData);
        }

        if (key === constant.IMAGES && diff.images && diff.images.length) {
          const vImages = [];
          const sImages = [];
          diff.images.map((image, idx) => {
            const vendorImage = (vendorProducts.images[idx] && vendorProducts.images[idx].imageUrl) || noImageUrl;
            const sellerImage = (sellerProducts.images[idx] && sellerProducts.images[idx].imageUrl) || noImageUrl;
            if (vendorImage !== sellerImage) {
              vImages.push([
                <Thumbnail
                  source={imageHelper.resize({ url: vendorImage, type: constant.imageTypes.THUMBNAIL })}
                  alt={t("label.productReview.vendorImage")}
                />,
              ]);
              sImages.push([
                <Thumbnail
                  source={imageHelper.resize({ url: sellerImage, type: constant.imageTypes.THUMBNAIL })}
                  alt={t("label.productReview.sellerImage")}
                />,
              ]);
            }
            return null;
          });
          if (vImages.length && sImages.length) {
            tableRows.push([labels[key], <Stack>{vImages}</Stack>, <Stack>{sImages}</Stack>, key]);
            label.push(labels[key]);
            vendorRow.push(<Stack>{vImages}</Stack>);
            sellerRow.push(<Stack>{sImages}</Stack>);
          }
        }
        const sellerVariantData =
          sellerProducts && sellerProducts.variants && sellerProducts.variants.length ? sellerProducts.variants : null;
        const vendorVariantData =
          vendorProducts && vendorProducts.variants && vendorProducts.variants.length ? vendorProducts.variants : null;
        const sellerVariantLength = sellerProducts && sellerProducts.variants && sellerProducts.variants.length;
        const vendorVariantLength = vendorProducts && vendorProducts.variants && vendorProducts.variants.length;

        if (isVariant) {
          const isSellerHasMoreVariants = sellerVariantLength > vendorVariantLength;
          const sellerVariants = [...(sellerVariantData || [])];
          const vendorVariants = [...(vendorVariantData || [])];
          const compareFromVariants = isSellerHasMoreVariants ? [...sellerVariants] : [...vendorVariants];
          const compareWithVariants = isSellerHasMoreVariants ? [...vendorVariants] : [...sellerVariants];
          const isVariantId = sellerVariants.every((item) => !!item.variantId);
          const isEqualVariants = sellerVariantLength === vendorVariantLength;

          variantDiffs = compareFromVariants.map((variantFrom) => {
            const variantId = baseHelper.mongoIdAsString(
              isSellerHasMoreVariants ? variantFrom.variantId : variantFrom._id
            );

            const { measurement = {} } = variantFrom;
            const { length = {} , width = {}, height = {} } = measurement || {};
            variantFrom.length = length && length.value || 0;
            variantFrom.width = width && width.value || 0;
            variantFrom.height = height && height.value || 0; 

            const variantChanges = {};
            let variantWith = false;
            if (isVariantId && variantId) {
              variantChanges.variantId = variantId;
              variantWith = compareWithVariants.find((item) => {
                const variantIdWith = baseHelper.mongoIdAsString(item[isSellerHasMoreVariants ? "_id" : "variantId"]);
                return variantIdWith === variantId;
              });
              if (variantWith) {
                const { measurement = {} } = variantWith;
                const { length = {}, width = {}, height = {} } = measurement || {};
                variantWith.length = (length && length.value) || 0;
                variantWith.width = (width && width.value) || 0;
                variantWith.height = (height && height.value) || 0;
              }
            } else {
              const { position } = variantFrom;
              variantChanges.position = position;
              variantWith = compareWithVariants.find((item) => item.position === position);
              if (variantWith) {
                const { measurement = {} } = variantWith;
                const { length = {}, width = {}, height = {} } = measurement || {};
                variantWith.length = (length && length.value) || 0;
                variantWith.width = (width && width.value) || 0;
                variantWith.height = (height && height.value) || 0;
              }
            }

            if (!variantWith) {
              variantChanges[isSellerHasMoreVariants ? "isDeleted" : "isAdded"] = true;
            } else {
              variantChanges.variantDiff = difference(variantFrom, variantWith);
              variantChanges.isUpdated = true;
              variantChanges.position = isSellerHasMoreVariants ? variantFrom.position : variantWith.position;
            }

            variantChanges.sellerVariant = isSellerHasMoreVariants
              ? { ...variantFrom }
              : (variantWith && { ...variantWith }) || null;
            variantChanges.vendorVariant = isSellerHasMoreVariants
              ? (variantWith && { ...variantWith }) || null
              : { ...variantFrom };
            variantChanges.isVariantId = isVariantId;
            variantIdAvailable.isId = isVariantId;
            if (isEqualVariants && !variantWith && isVariantId && variantId) {
              const avalaibleVariantIds = compareFromVariants.map((item) => item.variantId);
              const variantAdded = vendorVariants.find(
                (item) => !avalaibleVariantIds.includes(baseHelper.mongoIdAsString(item._id))
              );
              if (variantAdded) {
                const extraVariantChange = { ...variantChanges };
                extraVariantChange.sellerVariant = null;
                extraVariantChange.vendorVariant = variantAdded && { ...variantAdded };
                extraVariantChange.isAdded = true;
                delete extraVariantChange.isDeleted;
                delete extraVariantChange.isUpdated;
                return extraVariantChange;
              }
            }

            return variantChanges;
          });
        }
        return null;
      });
    }

    if (variantIdAvailable.isId !== isVariantIdAvailable) {
      setIsVariantIdAvailable(variantIdAvailable.isId);
    }
    return tableRows;
  };
  const setURL = () => {
    setTimeout(() => {
      history.push("/products");
    }, 2500);
  };

  const onToggleSelect = (key) => {
    const allUnselectedKeys = unselectedKeys;
    const keyIndex = allUnselectedKeys.findIndex((item) => item === key);
    if (keyIndex !== -1) {
      allUnselectedKeys.splice(keyIndex, 1);
    } else {
      allUnselectedKeys.push(key);
    }
    setUnselectedKeys(allUnselectedKeys);
  };

  const approveAll = (_id, isToPublish = true) => {
    setPublishLoading(!isToPublish);
    setApproveLoading(isToPublish);
    setNotApproveLoading(false);
    approveAllChanges({ variables: { input: { id: _id, isToPublish, unselectedKeys } } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, constant.gql.APPROVE_ALL_CHANGES);
        const bannerData = {};
        bannerData.url = true;
        bannerData.status = "success";
        bannerData.title = t("message.productReview.approved");
        if (resError) {
          bannerData.url = false;
          bannerData.title = resError;
          bannerData.status = "critical";
        }
        setBanner({ isOpen: true, status: bannerData.status, title: bannerData.title });
        setPublishLoading(false);
        setApproveLoading(false);
        if (bannerData.url) {
          setURL();
        }
      })
      .catch(() => {
        setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
      });
  };
  const approveSellerChanges = (_id) => {
    setNotApproveLoading(true);
    setPublishLoading(false);
    setApproveLoading(false);
    approveSeller({ variables: { input: { id: _id } } })
      .then((res) => {
        const resError = baseHelper.getResponseError(res.data, constant.gql.APPROVE_SELLER_CHANGES);
        const bannerData = {};
        bannerData.url = true;
        bannerData.status = "success";
        bannerData.title = t("message.productReview.declined");
        if (resError) {
          bannerData.url = false;
          bannerData.title = resError;
          bannerData.status = "critical";
        }
        setBanner({ isOpen: true, status: bannerData.status, title: bannerData.title });
        setNotApproveLoading(false);
        if (bannerData.url) {
          setURL();
        }
      })
      .catch(() => {
        setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
      });
  };

  const rows = getTableRows(diff);

  const { _id } = vendorProducts;

  if (loading) {
    return <Spinner isFullPage />;
  }

  return (
    <>
      <Layout.Section>
        <Banner
          isOpen={banner.isOpen}
          status={banner.status}
          title={banner.title}
          onDismiss={() => setBanner({ isOpen: false })}
        />
      </Layout.Section>
      <Layout.Section>
        {sellerProducts && sellerProducts.title && <DisplayText size="large">{sellerProducts.title}</DisplayText>}
      </Layout.Section>
      <Layout.Section>
        {rows.map((productData) => {
          return (
            <ReviewProduct
              store={currentUser && currentUser.shop}
              vendor={vendorProducts && vendorProducts.vendor}
              onToggleSelect={onToggleSelect}
              date={baseHelper.formatDate(vendorProducts.updatedAt)}
              {...productData}
            />
          );
        })}
        {variantDiffs.map((variantsData) => {
          return (
            <Variant
              store={currentUser && currentUser.shop}
              vendor={vendorProducts && vendorProducts.vendor}
              onToggleSelect={onToggleSelect}
              date={baseHelper.formatDate(vendorProducts.updatedAt)}
              isVariantIdAvailable={isVariantIdAvailable}
              {...variantsData}
            />
          );
        })}
      </Layout.Section>
      <Layout.Section>
        <PageActions
          primaryAction={{
            content: t("label.productReview.Accept"),
            onAction: () => approveAll(_id),
            loading: approveLoading,
            disabled: notApproveLoading || publishLoading,
          }}
          secondaryActions={[
            {
              content: t("label.productReview.notPublish"),
              onAction: () => approveAll(_id, false),
              loading: publishLoading,
              disabled: notApproveLoading || approveLoading,
              destructive: true,
              size: "slim",
            },
            {
              content: t("label.productReview.notAccept"),
              onAction: () => approveSellerChanges(_id, false),
              loading: notApproveLoading,
              disabled: approveLoading || publishLoading,
              size: "slim",
            },
          ]}
        />
      </Layout.Section>
    </>
  );
};

export default withFeature(OperatorProductReview, { feature: constant.REVIEW_PRODUCT });

import * as yup from "yup";
import { yupHelper } from "lib/helpers";

import errorMessage from "./errorMessage";

const firstNameSchema = yup.object().shape({
  firstName: yup.string().required(errorMessage.firstName).nullable(),
});

const lastNameSchema = yup.object().shape({
  lastName: yup.string().required(errorMessage.lastName).nullable(),
});

const { emailRegex, handleBrandRegex } = yupHelper;
const emailSchema = yup.object({
  email: yup
    .string()
    .required(errorMessage.email.requiredMessage)
    .matches(emailRegex, errorMessage.email.matches)
    .nullable(),
});

const phoneNumberSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required(errorMessage.phoneNumber)
    .matches(/^[0-9]+$/, errorMessage.positive)
    .min(7, errorMessage.minPhoneNumber)
    .max(15, errorMessage.maxPhoneNumber)
    .nullable(),
});

const citySchema = yup.object().shape({
  city: yup.string().required(errorMessage.city).nullable(),
});

const countrySchema = yup.object().shape({
  country: yup.string().required(errorMessage.country).nullable(),
});

const postalCodeSchema = yup.object().shape({
  postalCode: yup
    .string()
    .required(errorMessage.postalCode)
    .matches(/^[a-zA-Z0-9]+$/, errorMessage.alphanumeric)
    .min(4, errorMessage.minPostalCode)
    .max(6, errorMessage.maxPostalCode)
    .nullable(),
});

// const provinceCodeSchema = yup.object().shape({
//   provinceCode: yup.string().required(errorMessage.provinceCode).nullable(),
// });

const streetAddressSchema = yup.object().shape({
  streetAddress: yup.string().required(errorMessage.streetAddress).nullable(),
});
const brandNameSchema = yup.object().shape({
  brandName: yup.string().required(errorMessage.brandName).nullable(),
});

const brandHandleSchema = yup.object().shape({
  brandHandle: yup
    .string()
    .required(errorMessage.slug)
    .matches(handleBrandRegex, errorMessage.slugValidation)
    .nullable(),
});

export {
  brandHandleSchema,
  brandNameSchema,
  citySchema,
  countrySchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  postalCodeSchema,
  // provinceCodeSchema,
  streetAddressSchema,
};

import React, { useContext } from "react";
import { FormLayout, Select, TextField } from "@shopify/polaris";
import PropsType from "prop-types";
import { useTranslation } from "react-i18next";

// import lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const MoreFilters = (props) => {
  const { dataToFetch, setDataToFetch, setTaggedWith, taggedWith, selectedFilter, setSelectedFilter } = props;
  const { t } = useTranslation();
  const { history } = useContext(PrivateContext);

  const filterOptions = [
    { label: t("label.productList.Select"), value: "" },
    { label: t("label.productList.Handle"), value: t("label.productList.Handle").toLowerCase() },
    { label: t("label.productList.ProductStatus"), value: t("label.productList.Status") },
  ];
  const filterStatus = [
    { label: t("label.productList.Select"), value: "" },
    { label: t("label.productList.New"), value: t("label.productList.New").toLowerCase() },
    { label: t("label.productList.Review"), value: t("label.productList.Review").toLowerCase() },
    { label: t("label.productList.Approved"), value: t("label.productList.Approved").toLowerCase() },
    { label: t("label.productList.Rejected"), value: t("label.productList.Rejected").toLowerCase() },
  ];

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    let listFilter = baseHelper.setQueryParams(history.location, { list_filter: value });
    if (value !== t("label.productList.Status")) {
      setTaggedWith("");
      listFilter = baseHelper.setQueryParams(history.location, { list_filter: value, list_search: "" });
    }
    history.push(`${history.location.pathname}?${listFilter}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    const listSearch = baseHelper.setQueryParams(history.location, { list_search: value });
    history.push(`${history.location.pathname}?${listSearch}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  return (
    <>
      <FormLayout>
        <Select
          label={t("label.productList.ShowAllProducts")}
          value={selectedFilter}
          onChange={handleSelectChange}
          options={filterOptions}
          placeholder={t("label.productList.selectFilter")}
        />
        {(selectedFilter && selectedFilter !== t("label.productList.Status") && (
          <TextField label={t("label.productList.like")} value={taggedWith} onChange={handleTaggedWithChange} />
        )) ||
          (selectedFilter === t("label.productList.Status") && (
            <Select
              label={baseHelper.ucFirst(t("label.productList.Status"))}
              value={taggedWith}
              onChange={handleTaggedWithChange}
              options={filterStatus}
              placeholder={t("label.productList.selectStatus")}
            />
          )) ||
          null}
      </FormLayout>
    </>
  );
};

MoreFilters.defaultProps = {
  dataToFetch: {},
  setDataToFetch: () => {},
  setTaggedWith: () => {},
  taggedWith: "",
  setSelectedFilter: () => {},
  selectedFilter: "",
};

MoreFilters.propTypes = {
  dataToFetch: PropsType.shape(PropsType.object),
  setDataToFetch: PropsType.func,
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};

export default MoreFilters;

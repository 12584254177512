import React, { useContext, useState } from "react";
import { Card, Link, Collapsible, Caption, Layout, Stack, Heading, TextStyle, VideoThumbnail } from "@shopify/polaris";

import { withErrorBoundary, withFeature } from "lib/hoc";
import constant from "lib/constant/constant";
// helper
import { baseHelper } from "lib/helpers";

import { PrivateContext } from "../../../../lib/context";
import standardList from "./standardConfig";

const OperatorSettings = () => {
  const { history, currentUser } = useContext(PrivateContext);
  const [openSetup, setOpenSetup] = useState({});
  const sellerPlan = baseHelper.getSellerPlan(currentUser);

  const handleSetupToggle = (key) => {
    setOpenSetup((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const borderSet = {
    border: "0",
  };

  const iconHeightFix = {
    lineHeight: "2.2em",
  };
  const hiddenCards = ["Billing", "General"];
  return standardList.map((settingList) => {
    return (
      <Layout.AnnotatedSection
        title={settingList.title}
        description={[
          <div>
            {settingList.isVideo && (
              <>
                <VideoThumbnail
                  videoLength={80}
                  thumbnailUrl="https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg"
                />
                <br />
              </>
            )}
            <TextStyle variation="subdued">{settingList.description}</TextStyle>
          </div>,
        ]}
      >
        {settingList.list.map((actionList) => {
          const isSellerBootstrapPlan = sellerPlan === constant.BOOTSTRAP && actionList.code === constant.USER;
          let actionData = actionList.actions;
          if (isSellerBootstrapPlan) {
            actionData = actionData.filter((item) => item.code !== constant.EMAIL_TEMPLATE);
          }
          // NOTE: Remove this condition, once the development and QA is completed
          if (process.env.REACT_APP_ENV === "prod") {
            actionData = actionData.filter((item) => item.code !== "productAttachmentSettings");
          }
          return (
            !hiddenCards.includes(actionList.title) && (
              <Card>
                <Card.Header
                  sectioned
                  title={(
                    <Stack>
                      <i className={actionList.icon} style={iconHeightFix} />
                      <Heading>
                        {actionList.title}
                        <TextStyle variation="subdued">
                          <Caption>{actionList.description}</Caption>
                        </TextStyle>
                      </Heading>
                    </Stack>
                  )}
                  actions={[
                    {
                      content: openSetup[actionList.code] ? "Hide" : "Show",
                      onAction: () => handleSetupToggle(actionList.code),
                      disclosure: openSetup[actionList.code] ? "up" : "down",
                    },
                  ]}
                />
                <br />
                <Collapsible open={openSetup[actionList.code]} id={`collapsible${actionList.code}`}>
                  <Card.Section subdued>
                    {actionData.map((step) => {
                      return (
                        <div style={borderSet}>
                          <Card.Section subdued>
                            <Stack>
                              <Stack.Item>
                                <Link url={step.link}>{step.label}</Link>
                                <TextStyle variation="">
                                  <Caption>{step.description}</Caption>
                                </TextStyle>
                              </Stack.Item>
                            </Stack>
                          </Card.Section>
                        </div>
                      );
                    })}
                    {actionList.isVideo && (
                      <Layout.AnnotatedSection
                        title={actionList.thumbnailHeader}
                        description={(
                          <div>
                            <VideoThumbnail videoLength={80} thumbnailUrl={actionList.thumbnail} />
                            <br />
                            <TextStyle variation="">{actionList.thumbnailDescription}</TextStyle>
                          </div>
                        )}
                      />
                    )}
                  </Card.Section>
                </Collapsible>
              </Card>
            )
          );
        })}
      </Layout.AnnotatedSection>
    );
  });
};

export default withFeature(withErrorBoundary(OperatorSettings), { feature: constant.SETTINGS });

import React, { useContext } from "react";
import { RadioButton } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// propTypes
import { withFeature } from "lib/hoc";
import { consignmentProp } from "../../props";

// import hoc

const Consignment = (props) => {
  const { cms } = useContext(PrivateContext);
  const { data, handleChange } = props;
  const { isConsignment } = data;

  return (
    <RadioButton
      label={cms("label.consignment")}
      helpText={cms("helpText.consignment")}
      checked={isConsignment}
      onChange={() => handleChange("consignment")}
    />
  );
};

Consignment.propTypes = consignmentProp.type;
export default withFeature(Consignment, { feature: constant.CONSIGNMENT });

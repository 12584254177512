import { gql } from "apollo-boost";

const DOWNLOAD_LABEL = gql`
  query downloadShippingLabel($input: ShippingLabelInput!) {
    downloadShippingLabel(input: $input) {
      data
      status
      error
    }
  }
`;
export { DOWNLOAD_LABEL };

// import packages
import React from "react";
import { Modal, TextContainer, Heading } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
// cms
import termsPolicyCMS from "app/public/modules/register/cms/register";

// props
import { termsProp } from "../props";

const TermsUsed = (props) => {
  const { t } = useTranslation();
  const { isOpen, onClose } = props;
  const { message } = termsPolicyCMS;
  return (
    <>
      <Modal
        large
        id="termsPolicy"
        title={t("label.terms")}
        open={isOpen}
        onClose={onClose}
        primaryAction={{
          content: "Close",
          onAction: onClose,
        }}
      >
        <Modal.Section>
          <TextContainer>
            <div className="textFormat">
              <p>{message.paraField.paraField1}</p>
              <Heading>{message.h2Field.h2Field1}</Heading>
              <b>{message.boldField.b}</b>
              <p>{message.paraField.paraField2}</p>
              <b>{message.boldField.b1}</b>
              <p>{message.paraField.paraField3}</p>
              <Heading>{message.h2Field.h2Field2}</Heading>
              <p>{message.paraField.paraField4}</p>
              <Heading>{message.h2Field.h2Field3}</Heading>
              <p>{message.paraField.paraField5}</p>
              <ul>
                <li>{message.liField.liField1}</li>
                <li>{message.liField.liField2}</li>
                <li>{message.liField.liField3}</li>
              </ul>
              <p>{message.paraField.paraField21}</p>
              <Heading>{message.h2Field.h2Field4}</Heading>
              <b>{message.boldField.b2}</b>
              <p>{message.paraField.paraField6}</p>
              <b>{message.boldField.b3}</b>
              <p>{message.paraField.paraField7}</p>
              <Heading>{message.h2Field.h2Field5}</Heading>
              <p>{message.paraField.paraField8}</p>
              <Heading>{message.h2Field.h2Field6}</Heading>
              <p>{message.paraField.paraField9}</p>
              <Heading>{message.h2Field.h2Field7}</Heading>
              <p>{message.paraField.paraField10}</p>
              <ul>
                <li>{message.liField.liField4}</li>
                <li>{message.liField.liField5}</li>
                <li>{message.liField.liField6}</li>
              </ul>
              <p>{message.paraField.paraField11}</p>
              <ul>
                <li>{message.liField.liField7}</li>
                <li>{message.liField.liField8}</li>
                <li>{message.liField.liField9}</li>
              </ul>
              <p>{message.paraField.paraField12}</p>
              <Heading>{message.h2Field.h2Field8}</Heading>
              <b>{message.boldField.b4}</b>
              <p>{message.paraField.paraField13}</p>
              <b>{message.boldField.b5}</b>
              <p>{message.paraField.paraField14}</p>
              <b>{message.boldField.b6}</b>
              <p>{message.paraField.paraField15}</p>
              <b>{message.boldField.b7}</b>
              <p>{message.paraField.paraField16}</p>
              <b>{message.boldField.b8}</b>
              <p>{message.paraField.paraField17}</p>
              <b>{message.boldField.b9}</b>
              <p>{message.paraField.paraField18}</p>
              <b>{message.boldField.b10}</b>
              <p>{message.paraField.paraField19}</p>
              <b>{message.boldField.b11}</b>
              <p>{message.paraField.paraField20}</p>
            </div>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};
TermsUsed.propTypes = termsProp.type;
export default TermsUsed;

import React, { useState, useEffect, useContext } from "react";
import { Layout, SettingToggle, TextStyle } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import components
import { Banner } from "lib/components";

// import helpers
import baseHelper from "lib/helpers/base";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import gql
import { UPDATE_ORDER_SETTING } from "../../../../apollo";

const { gql, ACCEPTED_ORDER, FULFILLMENTS } = constant;

const OperatorOrderSetting = () => {
  const { currentUser, cms } = useContext(PrivateContext);
  const { _id: currentUserId, setting } = currentUser;
  const { isAcceptOrder, isFulfillOrder } = setting || {};
  const [toggleOrderStatus, setToggleOrderStatus] = useState(false);
  const [autoOrdersEnabled, setAutoOrdersEnabled] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [bannerStatus, setBannerStatus] = useState({
    title: "",
    status: "",
    isOpen: false,
  });
  const [setSettingOrder] = useMutation(UPDATE_ORDER_SETTING);
  const contentStatus = toggleOrderStatus
    ? cms("section.autoAccept.button.disable")
    : cms("section.autoAccept.button.enable");
  const textStatus = toggleOrderStatus
    ? cms("section.autoAccept.label.enabled")
    : cms("section.autoAccept.label.disabled");
  const autoFullfillStatus = autoOrdersEnabled
    ? cms("section.autoFulfillOrders.button.disable")
    : cms("section.autoFulfillOrders.button.enable");
  const textAutoStatus = autoOrdersEnabled
    ? cms("section.autoAccept.label.enabled")
    : cms("section.autoAccept.label.disabled");
  const updateOrderSetting = (orderStatus, key = false) => {
    const requestData = {
      id: currentUserId,
    };
    requestData.isAcceptOrder = orderStatus;
    requestData.isFulfillOrder = autoOrdersEnabled;

    if (!orderStatus && key === ACCEPTED_ORDER) {
      requestData.isAcceptOrder = orderStatus;
      requestData.isFulfillOrder = orderStatus;
    }
    if (key === FULFILLMENTS) {
      requestData.isAcceptOrder = toggleOrderStatus;
      requestData.isFulfillOrder = orderStatus;
    }
    setButtonLoading(key);
    setSettingOrder({
      variables: { input: requestData },
    })
      .then((res) => {
        setButtonLoading(false);
        const errorResponse = baseHelper.getResponseError(res.data, gql.UPDATE_ORDER_SETTING);
        let bannerValue = { status: "critical", title: errorResponse };
        if (!errorResponse) {
          const responseData = baseHelper.getResponseData(res.data, gql.UPDATE_ORDER_SETTING);
          bannerValue = { status: "success", title: cms("message.success") };
          setToggleOrderStatus(responseData.setting.isAcceptOrder);
          setAutoOrdersEnabled(responseData.setting.isFulfillOrder);
        }

        setBannerStatus({
          status: bannerValue.status,
          title: bannerValue.title,
          isOpen: true,
        });
      })
      .catch(() => {
        setButtonLoading(false);
        setBannerStatus({
          status: "critical",
          title: cms("common.message.error.somethingWentWrong"),
          isOpen: true,
        });
      });
  };
  const onSubmit = () => {
    const orderStatus = toggleOrderStatus;
    updateOrderSetting(!orderStatus, ACCEPTED_ORDER);
  };
  const onAutoSubmit = () => {
    const orderStatus = autoOrdersEnabled;
    updateOrderSetting(!orderStatus, FULFILLMENTS);
  };
  useEffect(() => {
    setToggleOrderStatus(isAcceptOrder);
  }, [isAcceptOrder]);
  useEffect(() => {
    if (isFulfillOrder) {
      setAutoOrdersEnabled(isFulfillOrder);
    }
  }, [isFulfillOrder]);
  let setButtonColor = false;
  let setFullFillButtonColor = false;
  if (autoOrdersEnabled) {
    setFullFillButtonColor = true;
  }
  if (toggleOrderStatus) {
    setButtonColor = true;
  }
  const dismissBanner = () => setBannerStatus({ isOpen: false, status: "", title: "" });
  return (
    <>
      {bannerStatus.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={bannerStatus.isOpen}
            status={bannerStatus.status}
            title={bannerStatus.title}
            onDismiss={() => dismissBanner()}
          />
        </Layout.Section>
      )}

      <Layout.AnnotatedSection
        title={cms("section.autoAccept.title")}
        description={cms("section.autoAccept.description")}
      >
        <Layout.Section>
          <SettingToggle
            action={{
              id: "submit",
              content: contentStatus,
              onAction: () => onSubmit(),
              destructive: setButtonColor,
              loading: buttonLoading === ACCEPTED_ORDER,
            }}
            enabled={setButtonColor}
          >
            {cms("section.autoAccept.content")}
            <TextStyle variation="strong">{textStatus}</TextStyle>
          </SettingToggle>
        </Layout.Section>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title={cms("section.autoFulfillOrders.title")}
        description={cms("section.autoFulfillOrders.description")}
      >
        <Layout.Section>
          <SettingToggle
            action={{
              id: "submit",
              content: autoFullfillStatus,
              onAction: () => onAutoSubmit(),
              disabled: !toggleOrderStatus,
              destructive: setFullFillButtonColor,
              loading: buttonLoading === FULFILLMENTS,
            }}
            enabled={setFullFillButtonColor}
          >
            {cms("section.autoFulfillOrders.content")}
            <TextStyle variation="strong">{` ${textAutoStatus}`}</TextStyle>
          </SettingToggle>
        </Layout.Section>
      </Layout.AnnotatedSection>
    </>
  );
};
export default withFeature(withErrorBoundary(OperatorOrderSetting), { feature: constant.ORDER_SETTING });

import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import {
  Modal,
  FormLayout,
  TextField,
  Tooltip,
  Stack,
  Card,
  ButtonGroup,
  Button,
  TextStyle,
  Badge,
  Link,
  Layout,
  RadioButton,
  Thumbnail,
  Caption,
  Icon,
} from "@shopify/polaris";

import {
  updateOrderLineTracking,
  updateOrderLineStatus,
  updateVendorTracking,
} from "app/orders/apollo/updateOrderNote";

import { GET_SHIPPING_LABEL_STATUS } from "app/orders/apollo/queries";
import { ReceiptMajorMonotone } from "@shopify/polaris-icons";
import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import { statusHelper, baseHelper, imageHelper } from "lib/helpers";

const { gql } = constant;

const UnfulfilledLineItems = (props) => {
  const { t } = useTranslation();
  const { currentUser, cms, match } = useContext(PrivateContext);
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  const {
    itemData,
    onRefetchOrderLineView,
    onRefetchOrderView,
    setBanner,
    fulfillment = {},
    data,
    setConsignmentValue,
  } = props;
  const consignmentLabel = cms("label.consignment");
  const shipLabel = cms("label.ship");
  const pickupLabel = cms("label.pickup");

  const [trackingCompany] = useState("");
  const [trackingNumber] = useState("");
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");

  // const { type = "", isThirdPartyShipping = false } = fulfillment || {};
  const { dropship = {}, type = "" } = fulfillment || {};
  const { isThirdPartyShipping = false, nzPost = {} } = dropship || {};
  const isConsignment = type === consignmentLabel || false;

  const [consignmentType, setConsignmentType] = useState(shipLabel);
  const [trackingModal, setTrackingModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [selectedItemData, setSelectedItemData] = useState({});
  const [isAcceptOrder, setIsAcceptedOrder] = useState("");

  const [isConsignmentPick, setIsConsignmentPick] = useState(false);

  const [updateOrderLine, { data: dataUpdateOrderLineTracking, loading: loadingOrderLineTracking }] = useMutation(
    updateOrderLineTracking
  );

  const [updateVendorOrder, { data: dataVendorTracking, loading: loadingVendorTracking }] = useMutation(
    updateVendorTracking
  );
  const [updateOrderStatus, { data: updateOrderLineData, loading: orderLineLoading }] = useMutation(
    updateOrderLineStatus
  );
  const { setPageData, pageData, history } = useContext(PrivateContext);
  const { title, breadcrumbs, subtitle } = pageData;

  const [getShippingLabelStatus, { loading: checkShippingLabelLoading, data: checkShippingLabelData }] = useLazyQuery(
    GET_SHIPPING_LABEL_STATUS
  );

  const [isfulfillLineItemLoading, setFulFillLineItemLoading] = useState({});

  const processRow = (value, isAccept) => {
    const { _id: id, orderId } = value;
    const formData = { lineItemId: id, orderId, isAccept };

    setIsAcceptedOrder(isAccept);
    updateOrderStatus({ variables: { input: formData } });
  };
  useEffect(() => {
    if (updateOrderLineData) {
      const updateOrderStatusData = baseHelper.getResponseData(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      const updateOrderStatusError = baseHelper.getResponseError(
        updateOrderLineData,
        constant.gql.UPDATE_ORDER_LINE_STATUS
      );
      if (updateOrderStatusData && isAcceptOrder) {
        setPageData({ title, breadcrumbs, subtitle });
        setBanner({
          status: constant.SUCCESS,
          title: t("message.orderLineApproved"),
          isOpen: true,
        });
        onRefetchOrderView();
        onRefetchOrderLineView();
      } else if (updateOrderStatusData && !isAcceptOrder) {
        setBanner({
          status: constant.SUCCESS,
          title: t("message.orderLineRejected"),
          isOpen: true,
        });
      }
      if (updateOrderStatusError) {
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderStatusError,
          isOpen: true,
        });
      }
    }
  }, [
    updateOrderLineData,
    breadcrumbs,
    isAcceptOrder,
    onRefetchOrderLineView,
    onRefetchOrderView,
    setBanner,
    setPageData,
    subtitle,
    t,
    title,
  ]);

  useEffect(() => {
    let bannerData = false;
    if (!checkShippingLabelLoading && checkShippingLabelData) {
      const { _id: orderLineItemId = "" } = selectedItemData || {};
      setFulFillLineItemLoading({
        [orderLineItemId]: checkShippingLabelLoading,
      });
      const responseData = baseHelper.getResponseData(checkShippingLabelData, gql.GET_SHIPPING_LABEL_STATUS);
      if (!responseData) {
        bannerData = {
          title:
            baseHelper.getResponseError(checkShippingLabelData, gql.GET_SHIPPING_LABEL_STATUS) ||
            t("error.common.somethingWentWrong"),
          status: constant.CRITICAL,
        };
      }

      if (responseData && !responseData.consignmentStatus) {
        bannerData = {
          title: cms("message.labelNotGenerated"),
          status: constant.WARNING,
        };
      }

      if (responseData && responseData.consignmentStatus === constant.PROCESSING) {
        bannerData = {
          title: cms("message.labelUnderProcess"),
          status: constant.WARNING,
        };
      }

      if (bannerData) {
        setBanner({ ...bannerData, isOpen: true });
        setTrackingModal(false);
        return;
      }
      setConsignmentValue(true);
      setTrackingModal(true);
      const { consignmentId = "", labels = [] } = responseData;
      const trackingReference = labels && labels.length && labels[0].tracking_reference;
      setInitialTrackingCompany(consignmentId);
      setInitialTrackingNumber(trackingReference);
    }
  }, [checkShippingLabelLoading, checkShippingLabelData, t]);

  const checkShippingLabelStatus = (value) => {
    const { orderId = "", _id: orderLineItemId, consignmentId = "" } = value;
    setFulFillLineItemLoading({
      [orderLineItemId]: true,
    });
    getShippingLabelStatus({ variables: { input: { orderId, orderLineItemId, consignmentId } } });
  };

  const openTrackingModel = (value) => {
    setSelectedItemData(value);
    if (isThirdPartyShipping && nzPost && nzPost.clientId) {
      checkShippingLabelStatus(value);
      return;
    }
    setTrackingModal(true);
  };
  const addTrackingDetails = () => {
    if (!initialTrackingCompany) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: t("message.trackingDetail.trackingCompany"), status: constant.CRITICAL });
      return false;
    }

    if (!initialTrackingNumber) {
      setTrackingModal(false);
      setBanner({ isOpen: true, title: t("message.trackingDetail.trackingNumber"), status: constant.CRITICAL });
      return false;
    }
    const data = {
      trackingNumber: initialTrackingNumber,
      trackingCompany: initialTrackingCompany,
    };
    const { _id: id, orderId } = selectedItemData;
    data.id = orderId;
    data.lineItemId = id;
    updateOrderLine({ variables: { input: data } });
  };

  const addShipmentDetails = (isPickup = false) => {
    setModalLoading(true);
    let data = {
      vendorTrackingNumber: initialTrackingNumber,
      vendorTrackingCompany: initialTrackingCompany,
    };

    if (isPickup) {
      data = {
        consignmentPickAddress: {
          address: currentUser.address,
          city: currentUser.city,
          country: currentUser.country,
          pinCode: currentUser.pinCode,
        },
        isConsignmentPick: true,
      };
    }
    const { _id: id, orderId } = selectedItemData;
    data.id = orderId;
    data.lineItemId = id;

    if (isVendor && !isPickup) {
      const fulfillmentAddress = baseHelper.fulfillmentDetails(fulfillment, currentUser);
      data = { ...data, ...(fulfillmentAddress || {}) };
    }

    updateVendorOrder({ variables: { input: data } });
  };

  useEffect(() => {
    if (dataUpdateOrderLineTracking) {
      const updateOrderTracking = baseHelper.getResponseData(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );
      const updateOrderTrackingError = baseHelper.getResponseError(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );

      if (updateOrderTracking) {
        setPageData({ title, breadcrumbs, subtitle });
        setTrackingModal(false);
        setInitialTrackingCompany("");
        setInitialTrackingNumber("");
        setBanner({
          status: constant.SUCCESS,
          title: t("message.orderFulfilled"),
          isOpen: true,
        });
        onRefetchOrderView();
        onRefetchOrderLineView();
      }
      if (updateOrderTrackingError) {
        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
  }, [
    dataUpdateOrderLineTracking,
    breadcrumbs,
    onRefetchOrderLineView,
    onRefetchOrderView,
    setBanner,
    setPageData,
    subtitle,
    t,
    title,
  ]);

  // handling vendor tracking data
  useEffect(() => {
    if (dataVendorTracking) {
      const updateVendorOrderTracking = baseHelper.getResponseData(
        dataVendorTracking,
        constant.gql.UPDATE_VENDOR_TRACKING
      );
      const updateVendorTrackingError = baseHelper.getResponseError(
        dataVendorTracking,
        constant.gql.UPDATE_VENDOR_TRACKING
      );

      if (updateVendorOrderTracking) {
        setPageData({ title, breadcrumbs, subtitle });
        setModalLoading(false);
        setTrackingModal(false);
        setSelectedItemData(false);
        setConsignmentType(shipLabel);
        setInitialTrackingNumber("");
        setInitialTrackingCompany("");
        setIsConsignmentPick(false);
        setBanner({
          status: constant.SUCCESS,
          title: t("message.orderFulfilled"),
          isOpen: true,
        });
        onRefetchOrderView();
        onRefetchOrderLineView();
      }
      if (updateVendorTrackingError) {
        setTrackingModal(false);
        setModalLoading(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateVendorTrackingError,
          isOpen: true,
        });
      }
    }
  }, [dataVendorTracking, breadcrumbs, setBanner, setPageData, subtitle, t, title]);
  const renderTracking = () => {
    return (
      <>
        <Modal
          open={trackingModal}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber(trackingNumber);
            setInitialTrackingCompany(trackingCompany);
          }}
          title={t("label.addTracking")}
          primaryAction={{
            content: t("label.saveTrackingDetails"),
            onAction: () => addTrackingDetails(),
            loading: loadingOrderLineTracking,
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label={t("label.company")}
                value={initialTrackingCompany}
                onChange={(val) => {
                  setInitialTrackingCompany(val);
                }}
              />
              <TextField
                label={t("label.number")}
                value={initialTrackingNumber}
                onChange={(val) => {
                  setInitialTrackingNumber(val);
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      </>
    );
  };

  const handleConsignmentType = (value) => {
    setConsignmentType(value);
  };

  const renderConsignmentTracking = () => {
    const isConsignmentShip = consignmentType === shipLabel;
    const isPickup = consignmentType === pickupLabel;
    let primaryAction = {
      content: t("label.saveTrackingDetails"),
      onAction: () => addShipmentDetails(),
      loading: modalLoading,
    };
    if (isPickup) {
      primaryAction = {
        content: cms("label.readyForCollection"),
        onAction: () => addShipmentDetails(true),
        loading: modalLoading,
      };
    }
    return (
      <Modal
        open={trackingModal}
        onClose={() => {
          setTrackingModal(false);
          setSelectedItemData(false);
          setConsignmentType(shipLabel);
          setInitialTrackingNumber(trackingNumber);
          setInitialTrackingCompany(trackingCompany);
          setIsConsignmentPick(false);
        }}
        title={cms("label.fulfillmentStatus")}
        primaryAction={primaryAction}
        sectioned
      >
        <Modal.Section>
          <Layout>
            <Layout.Section>
              <Stack>
                <Stack.Item>
                  <RadioButton
                    label={cms("label.consignmentShip")}
                    helpText={cms("helpText.consignmentShip")}
                    id={shipLabel}
                    name={shipLabel}
                    checked={isConsignmentShip}
                    onChange={() => handleConsignmentType(shipLabel)}
                  />
                  <RadioButton
                    label={cms("label.consignmentPickup")}
                    helpText={cms("helpText.consignmentPickup")}
                    checked={isPickup}
                    id={pickupLabel}
                    name={pickupLabel}
                    onChange={() => handleConsignmentType(pickupLabel)}
                  />
                </Stack.Item>
              </Stack>
            </Layout.Section>
            <Layout.Section secondary>
              {isConsignmentShip && (
                <Card sectioned>
                  <FormLayout>
                    <TextField
                      label={t("label.company")}
                      value={initialTrackingCompany}
                      onChange={(val) => setInitialTrackingCompany(val)}
                    />
                    <TextField
                      label={t("label.number")}
                      value={initialTrackingNumber}
                      onChange={(val) => setInitialTrackingNumber(val)}
                    />
                  </FormLayout>
                </Card>
              )}
              {isPickup && (
                <Card
                  title={cms("label.pickupLocation")}
                  sectioned
                  actions={[{ content: cms("label.changeLocation"), disabled: true }]}
                >
                  <FormLayout>
                    <TextField label={cms("label.address")} value={currentUser.address} disabled />
                    <TextField label={cms("label.city")} value={currentUser.city} disabled />
                    <TextField label={cms("label.country")} value={currentUser.country} disabled />
                    <TextField label={cms("label.pincode")} value={currentUser.pinCode} disabled />
                  </FormLayout>
                </Card>
              )}
            </Layout.Section>
          </Layout>
        </Modal.Section>
      </Modal>
    );
  };

  const renderTotalAmount = () => {
    const amount = [];
    const vendorsSum = [];
    const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;

    itemData.filter(({ vendor, price }) => {
      vendorsSum.push(vendor);
      amount.push(price);
      return [vendorsSum, amount];
    });

    const stringtoNum = amount.map((item) => parseFloat(item));
    const totalAmount = stringtoNum.reduce((cur, acc) => cur + acc, constant.value.ZERO);

    return (
      <>
        <Card.Section>
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">{t("label.totalOrderSlip")}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation="strong">
                {`${moneyFormat || constant.symbol.DOLLAR} ${totalAmount.toFixed(2)}`}
              </TextStyle>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </>
    );
  };
  const renderUnfullfilled = () => {
    const UnfulFilled = itemData.map((value, key) => {
      if (!value || value.fulfillmentStatus !== constant.UNFULFILLED) {
        return null;
      }

      const lineImage =
        imageHelper.resize({ url: value.image, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP;

      const lineItemKey = `lineItem${key}`;
      const { isProductDeleted } = value;

      let isAccepted = false;
      let isFulfilled = false;
      switch (value.status) {
        case constant.ACCEPTED:
          isAccepted = true;
          break;
        case constant.REJECTED:
          isAccepted = false;
          break;
        case constant.STATUS_NEW:
          isAccepted = true;
          break;
        case constant.CLOSED:
          isAccepted = null;
          break;
        default:
          break;
      }

      if (value.fulfillmentStatus === constant.UNFULFILLED) {
        switch (value.status) {
          case constant.ACCEPTED:
            isFulfilled = false;
            break;
          case constant.REJECTED:
            isFulfilled = false;
            break;
          case constant.STATUS_NEW:
            isFulfilled = true;
            break;
          case constant.CLOSED:
            isFulfilled = null;
            break;
          default:
            break;
        }
      }
      const isVendorTracked = isVendor && value.isVendorFulfilled === true;
      const moneyFormat = value.moneyFormat || constant.symbol.DOLLAR;
      const lineTotalPrice = value.price * value.quantity;
      const status = statusHelper.getBadgeStatus(value.status);
      const badgeType = baseHelper.getBadgeType(value.status);
      const lineItemName = <div className="productName">{value.name}</div>;
      
      const isFulfillLineItemButtonVisible =
        (!isThirdPartyShipping && !value.consignmentId) || (isThirdPartyShipping && value.consignmentId);

      const { _id: itemId } = value;
      const lineItemLoading = (isfulfillLineItemLoading && isfulfillLineItemLoading[itemId]) || false;
      return (
        <div className="badge-gap" key={lineItemKey}>
          <Card.Section subdued>
            <Stack wrap={false}>
              <Stack.Item>
                <Thumbnail size="large" source={lineImage} alt={value.name} />
              </Stack.Item>
              <Stack.Item fill>
                {(isProductDeleted && (lineItemName || "")) || (
                  <Link onClick={() => history.push(`/products/edit/${value.productId}`)}>{value.name || ""}</Link>
                )}

                <Caption>
                  <TextStyle variation={constant.SUBDUED}>
                    {value.vendor && value.vendorId && `${t("label.provider")}: `}
                    {value.vendor}
                  </TextStyle>
                  <br />
                  <TextStyle variation={constant.SUBDUED}>
                    {t("label.skuList")}
                    {value.sku}
                  </TextStyle>
                </Caption>

                <Stack alignment="center">
                  <Stack.Item>
                    <Badge
                      status={
                        (isAccepted && value.status === t("label.accepted").toLowerCase() && constant.SUCCESS) ||
                        (isAccepted === false && constant.WARNING) ||
                        (isAccepted && value.status === t("label.new").toLowerCase() && constant.ATTENTION) ||
                        badgeType
                      }
                      className="text-capitalize"
                    >
                      {(isAccepted && value.status === t("label.accepted").toLowerCase() && t("label.accepted")) ||
                        (isAccepted === false && t("label.rejected")) ||
                        (isAccepted && value.status === t("label.new").toLowerCase() && t("label.new")) ||
                        status}
                    </Badge>
                  </Stack.Item>
                </Stack>

                {isAccepted && isFulfilled && (
                  <Stack alignment="center">
                    <Stack.Item>
                      <ButtonGroup>
                        <Tooltip content={constant.REJECT_ORDER}>
                          <div style={{ color: "#bf0711" }}>
                            <Button
                              plain
                              monochrome
                              onClick={() => processRow(value, false, key)}
                              loading={orderLineLoading}
                            >
                              {baseHelper.ucFirst(t("label.rejectThisOrder"))}
                            </Button>
                          </div>
                        </Tooltip>
                        <Tooltip content={constant.ACCEPT_ORDER}>
                          <Button
                            size="slim"
                            monochrome
                            outline
                            primary
                            onClick={() => processRow(value, true, key)}
                            loading={orderLineLoading}
                          >
                            {baseHelper.ucFirst(t("label.acceptThisOrder"))}
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Stack.Item>
                  </Stack>
                )}
                {isAccepted && !isFulfilled && !isVendorTracked && isFulfillLineItemButtonVisible && (
                  <FormLayout>
                    <Stack alignment="center">
                      <Stack.Item>
                        <ButtonGroup >
                          <Button size="slim" onClick={() => openTrackingModel(value)} loading={lineItemLoading}>
                            {baseHelper.ucFirst(t("label.FulfillLineItem"))}
                          </Button>
                          <Button plain onClick={() => history.push(`/order-slip/${match.params.id}`)}>
                            <Stack spacing="extraTight">
                              <Stack.Item>
                                <Icon source={ReceiptMajorMonotone} />
                              </Stack.Item>
                              <Stack.Item>{`${t("label.muteNotification.provider.packingSlip")}`}</Stack.Item>
                            </Stack>
                          </Button>
                        </ButtonGroup>
                      </Stack.Item>
                    </Stack>
                  </FormLayout>
                )}
              </Stack.Item>
              <Stack.Item>
                {`${t("label.qty")}:`}
                {value.quantity}
              </Stack.Item>
              <Stack.Item>
                {moneyFormat}
                {(lineTotalPrice && lineTotalPrice.toFixed(2)) || constant.value.ZERO}
              </Stack.Item>
            </Stack>
          </Card.Section>
        </div>
      );
    });
    return UnfulFilled;
  };

  const renderTrackingMethod = isSeller || !isConsignment ? renderTracking() : renderConsignmentTracking();

  return [renderUnfullfilled(), renderTotalAmount(), renderTrackingMethod];
};

export default UnfulfilledLineItems;

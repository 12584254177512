import React from "react";
import { EmptyState } from "@shopify/polaris";
import { PrivateConsumer } from "lib/context";

import { Spinner } from "lib/components";

const WithErrorBoundary = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
      window.location.reload();
      // Catch errors in any components below and re-render with error message
      this.setState({
        error,
        errorInfo,
      });
      // You can also log error messages to an error reporting service here
    }

    render() {
      const { errorInfo, error } = this.state;
      if (errorInfo || error) {
        return <Spinner />;
      }
      // Normally, just render children
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default WithErrorBoundary;

const quickReportConfig = ({ t = () => {}, count = null, path = "" }) => {
  const { inviteCount = {}, orderCount = {}, paymentCount = {}, productCount = {}, vendorCount = {} } = count;

  const {
    joined: joinedInviteCount = null,
    newInvites: newInviteCount = null,
    pending: pendingInviteCount = null,
    revoked: revokedInviteCount = null,
    total: totalInviteCount = null,
  } = inviteCount;

  const {
    archived: archivedOrderCount = null,
    pendingAcceptance: pendingAcceptanceOrderCount = null,
    pendingShipment: pendingShipmentOrderCount = null,
    rejected: rejectedOrderCount = null,
    return: returnOrderCount = null,
    shipped: shippedOrderCount = null,
    total: totalOrderCount = null,
  } = orderCount;

  const {
    disputed: disputedPaymentCount = null,
    due: duePaymentCount = null,
    forcasted: forcastedPaymentCount = null,
    paid: paidPaymentCount = null,
    total: totalPaymentCount = null,
  } = paymentCount;

  const {
    approved: approvedProductCount = null,
    delete: deletedProductCount = null,
    new: newProductCount = null,
    reject: rejectedProductCount = null,
    review: reviewProductCount = null,
    reviewed: reviewedProductCount = null,
    total: totalProductCount = null,
  } = productCount;

  const {
    approved: approvedProviderCount = null,
    new: pendingApprovalProviderCount = null,
    total: totalProviderCount = null,
  } = vendorCount;

  const config = {
    "/": [
      {
        caption: t("quickReport.caption", { item: "total orders" }),
        count: totalOrderCount,
        label: t("common.Orders"),
      },
      {
        caption: t("quickReport.caption", { item: "total products" }),
        count: totalProductCount,
        label: t("common.Products"),
      },
      {
        caption: t("quickReport.caption", { item: "total vendors" }),
        count: totalProviderCount,
        label: t("common.Providers"),
      },
      {
        caption: t("quickReport.caption", { item: "total invites" }),
        count: totalInviteCount,
        label: t("common.Invitations"),
      },
    ],
    "/invitation": [
      {
        caption: t("quickReport.caption", { item: "all invitations" }),
        count: totalInviteCount,
        label: "All count",
      },
      {
        caption: t("quickReport.caption", { item: "new invitations" }),
        count: newInviteCount,
        label: "Invited count",
      },
      {
        caption: t("quickReport.caption", { item: "joined invitations" }),
        count: joinedInviteCount,
        label: "Joined count",
      },
      {
        caption: t("quickReport.caption", { item: "revoked invitations" }),
        count: revokedInviteCount,
        label: "Revoked count",
      },
      {
        caption: t("quickReport.caption", { item: "pending invitations" }),
        count: pendingInviteCount,
        label: "Pending count",
      },
    ],
    "/orders": [
      {
        caption: t("quickReport.caption", { item: "all orders" }),
        count: totalOrderCount,
        label: "All count",
      },
      {
        caption: t("quickReport.caption", { item: "pending acceptance orders" }),
        count: pendingAcceptanceOrderCount,
        label: "Pending acceptance count",
      },
      {
        caption: t("quickReport.caption", { item: "pending shipment orders" }),
        count: pendingShipmentOrderCount,
        label: "Pending shipment count",
      },
      {
        caption: t("quickReport.caption", { item: "rejected orders" }),
        count: rejectedOrderCount,
        label: "Rejected count",
      },
      {
        caption: t("quickReport.caption", { item: "shipped orders" }),
        count: shippedOrderCount,
        label: "Shipped count",
      },
      {
        caption: t("quickReport.caption", { item: "archived orders" }),
        count: archivedOrderCount,
        label: "Archived count",
      },
      {
        caption: t("quickReport.caption", { item: "return orders" }),
        count: returnOrderCount,
        label: "Return count",
      },
    ],
    "/payments": [
      {
        caption: t("quickReport.caption", { item: "all payments" }),
        count: totalPaymentCount,
        label: "All count",
      },
      {
        caption: t("quickReport.caption", { item: "forecasted payments" }),
        count: forcastedPaymentCount,
        label: "Forecasted count",
      },
      {
        caption: t("quickReport.caption", { item: "due payments" }),
        count: duePaymentCount,
        label: "Due count",
      },
      {
        caption: t("quickReport.caption", { item: "paid payments" }),
        count: paidPaymentCount,
        label: "Paid count",
      },
      {
        caption: t("quickReport.caption", { item: "disputed payments" }),
        count: disputedPaymentCount,
        label: "Disputed count",
      },
    ],
    "/products": [
      {
        caption: t("quickReport.caption", { item: "all products" }),
        count: totalProductCount,
        label: "All count",
      },
      {
        caption: t("quickReport.caption", { item: "approved products" }),
        count: approvedProductCount,
        label: "Approved count",
      },
      {
        caption: t("quickReport.caption", { item: "new products" }),
        count: newProductCount,
        label: "New count",
      },
      {
        caption: t("quickReport.caption", { item: "review products" }),
        count: reviewProductCount,
        label: "Review count",
      },
      {
        caption: t("quickReport.caption", { item: "reviewed product" }),
        count: reviewedProductCount,
        label: "Reviewed count",
      },
      {
        caption: t("quickReport.caption", { item: "rejected products" }),
        count: rejectedProductCount,
        label: "Rejected count",
      },
      {
        caption: t("quickReport.caption", { item: "deleted products" }),
        count: deletedProductCount,
        label: "Deleted count",
      },
    ],
    "/providers": [
      {
        caption: t("quickReport.caption", { item: "all vendors" }),
        count: totalProviderCount,
        label: "All count",
      },
      {
        caption: t("quickReport.caption", { item: "pending approval vendors" }),
        count: pendingApprovalProviderCount,
        label: "Pending approval count",
      },
      {
        caption: t("quickReport.caption", { item: "approved vendors" }),
        count: approvedProviderCount,
        label: "Approved count",
      },
    ],
  };

  return config[path];
};

export default quickReportConfig;

import React, { useContext, useState } from "react";
import { Card, FormLayout, Select, Layout, SkeletonBodyText, TextContainer } from "@shopify/polaris";
import { useQuery } from "@apollo/react-hooks";

import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
import { FormContext } from "../../../../context/context";

// import queries
import { GET_PRODUCT_TAGS, GET_PRODUCT_TYPE, GET_VENDOR_BRAND_LIST } from "../../../../../../../../apollo/queries";

// import component
import Tags from "./tag";

const Organisation = () => {
  const { data, handleChange, learnMore } = useContext(FormContext);
  const { cms } = useContext(PrivateContext);

  const [isOptionLoaded, setoptionLoaded] = useState(false);

  const { loading: productTypeLoading, data: productTypeData } = useQuery(GET_PRODUCT_TYPE);
  const { loading: productTagLoading, data: productTagsData } = useQuery(GET_PRODUCT_TAGS);
  const { loading: brandListLoading, data: vendorBrandListData } = useQuery(GET_VENDOR_BRAND_LIST);

  const LoadingComponent = () => {
    return (
      <Layout.AnnotatedSection
        title={cms("section.form.section.organization.title")}
        description={cms("section.form.section.organization.description")}
      >
        <Card
          title={cms("section.form.section.organization.title")}
          id="productOrganisation"
          actions={[
            {
              content: cms("common.label.learnMore"),
              onAction: () => {
                learnMore("productOrganisation", cms("section.form.section.organization.title"));
              },
            },
          ]}
          sectioned
        >
          <SkeletonBodyText />
        </Card>
      </Layout.AnnotatedSection>
    );
  };

  if (productTypeLoading) {
    return <LoadingComponent />;
  }
  // load product type data
  const productTypeOptions = baseHelper.getResponseData(productTypeData, constant.gql.GET_PRODUCT_TYPE) || [];

  if (productTagLoading) {
    return <LoadingComponent />;
  }
  // load product tags data
  const availableProductTags = baseHelper.getResponseData(productTagsData, constant.gql.GET_PRODUCT_TAGS) || {};

  if (brandListLoading) {
    return <LoadingComponent />;
  }
  // load vendors
  const brandList = baseHelper.getResponseData(vendorBrandListData, constant.gql.GET_VENDOR_BRAND_LIST);
  const vendorOptions = brandList.map(({ _id: vendorId, brandName }) => ({
    label: brandName,
    value: vendorId,
  }));

  if (!isOptionLoaded && vendorOptions.length) {
    setoptionLoaded(true);
    handleChange(constant.VENDOR_OPTIONS, vendorOptions);
  }

  return (
    <Card
      title={cms("section.form.section.organization.title")}
      id="productOrganisation"
      actions={[
        {
          content: cms("common.label.learnMore"),
          onAction: () => {
            learnMore("productOrganisation", cms("section.form.section.organization.title"));
          },
        },
      ]}
    >
      <Card.Section>
        <TextContainer>You can select product organization details for the product.</TextContainer>
        <br />
        <FormLayout>
          <FormLayout.Group>
            <Select
              label={`${cms("common.label.vendor")}*`}
              id="chooseVendor"
              placeholder={cms("section.form.section.organization.placeholder.pleaseSelect")}
              options={vendorOptions}
              value={data.vendorValue || ""}
              onChange={(value) => handleChange(constant.VENDOR_VALUE, value)}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Select
              label={cms("section.form.section.organization.label.productType")}
              id="chooseProductType"
              placeholder={cms("section.form.section.organization.placeholder.pleaseSelect")}
              options={productTypeOptions}
              value={data.productValueType || ""}
              onChange={(value) => handleChange(constant.PRODUCT_VALUE_TYPE, value)}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Tags
              label={cms("section.form.section.organization.label.productTags")}
              id="productTag"
              placeholder={cms("section.form.section.organization.placeholder.selectTags")}
              values={availableProductTags}
              selected={data.organisationTags || []}
              onChange={handleChange}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    </Card>
  );
};

export default Organisation;

import React, { useState, useContext, useEffect } from "react";
import { Caption, Button, Stack, TextStyle, ResourceItem } from "@shopify/polaris";

// import component
import { ResourceList } from "lib/components";

import { PrivateContext } from "lib/context";

// import helper
import { baseHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";
import Option from "./option";

import { listProp } from "../../propTypes";

const List = (props) => {
  const freeShipping = constant.FREE;
  const { bands, setBanner, loading: fetchShippingBandLoading, refetch, networkStatus } = props;
  const { currentUser, cms } = useContext(PrivateContext);
  const isVendor = baseHelper.isVendor(currentUser);
  const [state, setState] = useState({
    bands: [],
    searchValue: "",
    showModal: false,
    showDeleteModal: false,
    selectedBand: false,
    bandsToShow: [],
  });

  useEffect(() => {
    if (bands) {
      setState((prevState) => ({
        ...prevState,
        bandsToShow: bands,
      }));
    }
  }, [bands]);

  const toggleModal = (id = false) => {
    setState((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
      selectedBand: prevState.showModal ? false : id,
    }));
  };

  const handleSearchChange = (searchValue) => {
    const search = searchValue.toLowerCase().trim();
    const bandsToShow = bands.filter((item) => item.name.toLowerCase().includes(search));
    setState((prevState) => ({
      ...prevState,
      bandsToShow,
      searchValue,
    }));
  };
  const handleQueryClear = () => {
    setState((prevState) => ({
      ...prevState,
      bandsToShow: bands,
      searchValue: "",
    }));
  };
  const renderModal = () => {
    const { showModal, selectedBand = false } = state;

    const bandToUpdate =
      (bands &&
        bands.length &&
        bands.find((item) => {
          const { _id: bandId } = item;
          return bandId === selectedBand;
        })) ||
      {};

    return (
      <Option
        shippingBand={{ ...bandToUpdate }}
        isDefault={!selectedBand}
        show={showModal}
        onClose={() => toggleModal()}
        setBanner={setBanner}
        refetch={refetch}
        networkStatus={networkStatus}
        getShippingBandLoading={fetchShippingBandLoading}
      />
    );
  };

  const renderItem = (item) => {
    const moneyFormat = currentUser.moneyFormat || constant.symbol.DOl;
    const { _id, price, description, isUpdateAllowed, name, priceType } = item;
    const isFreeOption = priceType === freeShipping;
    return (
      <ResourceItem id={_id} key={_id} accessibilityLabel="accessibility">
        <Stack wrap={false}>
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{name}</TextStyle>
            </h3>
            <Caption>{description}</Caption>
            <br />
            <Caption>
              {`${cms("common.label.type")}: ${isFreeOption ? cms("band.label.free") : cms("band.label.fixed")}`}
            </Caption>
            {!isFreeOption && <Caption>{`${cms("band.label.cost")}: ${moneyFormat} ${price}`}</Caption>}
            <Caption>
              {`${cms("band.label.vendorUpdate")}: ${
                isUpdateAllowed ? cms("band.label.allowed") : cms("band.label.notAllowed")
              }`}
            </Caption>
          </Stack.Item>
          {isVendor && isUpdateAllowed && (
            <Button
              size="slim"
              destructive={false}
              disabled={item.disabled || false}
              onClick={() => toggleModal(_id)}
              key={`resourceListActionButton${_id}`}
            >
              {cms("band.button.edit")}
            </Button>
          )}
        </Stack>
      </ResourceItem>
    );
  };

  const resourceName = {
    singular: cms("band.label.band.singular") || constant.BAND,
    plural: cms("band.label.band.plural") || constant.BANDS,
  };

  const { bandsToShow } = state;

  return (
    <>
      {renderModal()}
      <ResourceList
        resourceName={resourceName}
        items={bandsToShow}
        renderItem={renderItem}
        loading={fetchShippingBandLoading || networkStatus === 4}
        queryValue={state.searchValue}
        onQueryChange={(searchValue) => handleSearchChange(searchValue)}
        onQueryClear={handleQueryClear}
      />
    </>
  );
};

List.propTypes = listProp.type;

export default List;

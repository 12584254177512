import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  ResourceItem,
  TextStyle,
  Tooltip,
  Stack,
  Link,
  Caption,
  Badge,
  Tabs,
  Card,
  Thumbnail,
  FormLayout,
  TextField,
  Modal,
} from "@shopify/polaris";
import { ViewMajorMonotone, QuickSaleMajorMonotone } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-apollo";

import { QuickReport, QuickAction } from "app/reports";
import { TwoColumnLayout } from "layout/private/components";
import { PrivateContext } from "lib/context";
import { ResourceList, Banner } from "lib/components";
import constant from "lib/constant/constant";
import { withErrorBoundary } from "lib/hoc";

import { FETCH_ORDER_LINE_LIST } from "app/orders/apollo/queries";
import { baseHelper, imageHelper } from "lib/helpers";
import MoreFilters from "app/orders/modules/generic/moreFilters/moreFilter";
import Popover from "lib/components/popover/popover";
import { updateOrderLineTracking } from "app/orders/apollo/updateOrderNote";
import { ORDER_LIST } from "../../../../apollo/subscriptions";

import AdminExportOrder from "../export";
import listData from "./orderListData";

const AdminOrderList = () => {
  const { history, setPageData } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const { gql, FULFILLED, ACCEPTED } = constant;
  const [isExportModal, setIsExportModal] = useState(false);
  const [load, setLoad] = useState(true);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { mainLink, links } = listData(t);
  const [selectedItems, setSelectedItems] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [orderList, setOrderList] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const selectedSort = queryParam.sort_name && `${queryParam.sort_name}_${queryParam.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || null);
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || null);
  const [activePopover, setActivePopover] = useState({});
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");
  const [trackingModal, setTrackingModal] = useState({});

  const inputData = {
    filter: queryParam.filter,
    path: queryParam.path,
    sort_name: queryParam.sort_name || "createdAt",
    sort_order: queryParam.sort_order || "desc",
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10) || 1,
    perPage: parseInt(perPage, 10) || 10,
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };
  const { data: order, loading: orderLoading, error: orderError, subscribeToMore, refetch } = useQuery(
    FETCH_ORDER_LINE_LIST,
    {
      variables: {
        input: inputData,
      },
    }
  );

  const [updateOrderLine, { data: dataUpdateOrderLineTracking, loading: loadingOrderLineTracking }] = useMutation(
    updateOrderLineTracking
  );
  const size = constant.LARGE;
  useEffect(() => {
    if (load) {
      setPageData({
        title: "Orders",
        primaryAction: {
          content: "Export Order",
          onAction: () => setIsExportModal(!isExportModal),
          id: "exportOrder",
        },
      });
      setLoad(!load);
    }
  }, [isExportModal, load, setPageData]);
  useEffect(() => {
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (orderError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: t("error.common.somethingWentWrong"),
      });
    }
  }, [orderError, t]);
  const responseError = baseHelper.getResponseError(order, gql.GET_ORDER_LINES_LIST);
  useEffect(() => {
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
    }
  }, [responseError]);
  const responseData = baseHelper.getResponseData(order, gql.GET_ORDER_LINES_LIST);
  useEffect(() => {
    if (responseData) {
      setOrderList(responseData && responseData.orderLineList);
      setOrderCount(responseData && responseData.count);
    }
  }, [responseData]);

  useEffect(() => {
    if (dataUpdateOrderLineTracking) {
      const updateOrderTracking = baseHelper.getResponseData(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );
      const updateOrderTrackingError = baseHelper.getResponseError(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );

      if (updateOrderTracking) {
        setTrackingModal(false);
        setInitialTrackingCompany("");
        setInitialTrackingNumber("");
        setBanner({
          status: constant.SUCCESS,
          title: t("message.orderFulfilled"),
          isOpen: true,
        });
        refetch();
      }
      if (updateOrderTrackingError) {
        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
  }, [dataUpdateOrderLineTracking, t]);

  const setFilters = useCallback(
    (newFilter, select) => {
      const getFilter = baseHelper.setQueryParams(history.location, { filter: newFilter, tab: select });
      history.push(`${history.location.pathname}?${getFilter}`);
    },
    [history]
  );
  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1], page: 1 });
    }
  };
  const handleQueryClear = () => {
    setSearch("");
    baseHelper.setUrl(history, { search: "" });
  };

  const handleTaggedWithRemove = (clearFilter = false) => {
    const removeParams = { list_search: "" };
    if (clearFilter) {
      removeParams.list_filter = "";
      setSelectedFilter("");
    }
    setTaggedWith("");
    baseHelper.setUrl(history, removeParams);
  };

  const linkData = [
    {
      action: () => setIsExportModal(true),
      caption: "Export your orders",
      id: "exportOrder",
      label: "Export Order",
    },
  ];

  const getSecondaryComponent = () => {
    return (
      <>
        <QuickAction link={linkData} />
        <QuickReport />
      </>
    );
  };

  const getTabs = {
    0: "all",
    1: "pending",
    2: "accepted",
    3: "rejected",
    4: "shipped",
    5: "closed",
  };
  const handleSelect = (item) => {
    setSelectedItems(item);
  };
  const handleTabChange = (select) => {
    setSelectedTab(select);
    setFilters(getTabs[select], select);
  };
  useEffect(() => {
    subscribeToMore({
      document: ORDER_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newOrder = subscriptionData.data.orderLineView.data.orderLine;
        const prevOrder = prev.getOrderLineList.data;
        const OrderData = [newOrder, ...prevOrder.orderLineList];
        const totalCount = OrderData.length;
        return [setOrderList(OrderData), setOrderCount(totalCount)];
      },
    });
  }, [subscribeToMore]);
  const pageContent = {
    label: {
      status: t("tooltip.status"),
      fulfillmentStatus: t("tooltip.fulfillmentStatus"),
    },
  };

  const renderItem = (item) => {
    const {
      _id: id,
      image,
      title,
      vendor,
      createdAt,
      status,
      fulfillmentStatus,
      shopifyLineItemId,
      orderNumber,
      vendorId,
      orderId,
    } = item;
    const addTrackingDetails = () => {
      if (!initialTrackingCompany) {
        setTrackingModal(false);
        setBanner({ isOpen: true, title: "Tracking Company is required.", status: constant.CRITICAL });
        return false;
      }

      if (!initialTrackingNumber) {
        setTrackingModal(false);
        setBanner({ isOpen: true, title: "Tracking Number is required.", status: constant.CRITICAL });
        return false;
      }

      const data = {
        trackingNumber: initialTrackingNumber,
        trackingCompany: initialTrackingCompany,
      };
      data.id = orderId;
      data.lineItemId = id;
      updateOrderLine({ variables: { input: data } });
    };

    const fulfillment = () => {
      return (
        <Modal
          open={trackingModal[shopifyLineItemId]}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber("");
            setInitialTrackingCompany("");
          }}
          title="Add Tracking Details"
          primaryAction={{
            content: "Save Tracking Details",
            onAction: () => addTrackingDetails(),
            loading: loadingOrderLineTracking,
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label="Tracking Company"
                value={initialTrackingCompany}
                onChange={(val) => {
                  setInitialTrackingCompany(val);
                }}
              />
              <TextField
                label="Tracking Number"
                value={initialTrackingNumber}
                onChange={(val) => {
                  setInitialTrackingNumber(val);
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      );
    };

    const thumbnailImage = image
      ? imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
    const thumbnail = <Thumbnail source={thumbnailImage} size={size} />;
    const topMargin10 = {
      marginTop: "10px",
    };

    const option = [
      {
        content: constant.VIEW_LABEL,
        icon: ViewMajorMonotone,
        onAction: () => history.push(`${mainLink.orderNumber}${orderId}`),
      },
    ];
    const fullfillOption = {
      content: "Fulfill this line item ",
      icon: QuickSaleMajorMonotone,
      onAction: () => setTrackingModal({ [shopifyLineItemId]: !trackingModal[shopifyLineItemId] }),
      disabled: fulfillmentStatus === FULFILLED || status !== ACCEPTED || false,
    };

    if (fulfillmentStatus === FULFILLED || status !== ACCEPTED) {
      option.push(fullfillOption);
    }
    if (!(fulfillmentStatus === FULFILLED || status !== ACCEPTED)) {
      option.unshift(fullfillOption);
    }

    return (
      <ResourceItem id={orderId} media={thumbnail} persistActions>
        <Stack alignment="leading">
          <Stack.Item fill Style="width: 200px">
            <TextStyle>
              <Link>
                <Link onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>
                  <div className="ellipsis">{`#${orderNumber} - ${title}`}</div>
                </Link>
              </Link>
            </TextStyle>
            <Caption>
              <TextStyle variation="subdued">
                {` ${t("label.CreatedOn")} ${baseHelper.formatDate(createdAt)}`}
              </TextStyle>
            </Caption>
            <Caption>
              {`${t("label.VendorName")}: `}
              <Link onClick={() => history.push(`${links.vendor.link}${vendorId}`)}>{vendor}</Link>
            </Caption>

            <div style={topMargin10}>
              <Stack>
                <Stack.Item>
                  <Tooltip content={pageContent.label.status} preferredPosition="above">
                    <Badge status={baseHelper.getBadgeType(status)}>{baseHelper.ucFirst(status)}</Badge>
                  </Tooltip>
                </Stack.Item>
                <Stack.Item>
                  <Tooltip content={pageContent.label.fulfillmentStatus} preferredPosition="above">
                    <Badge status={baseHelper.getBadgeType(fulfillmentStatus)}>
                      {baseHelper.ucFirst(fulfillmentStatus)}
                    </Badge>
                  </Tooltip>
                </Stack.Item>
              </Stack>
            </div>
          </Stack.Item>
          {fulfillment()}
          <Stack.Item>
            <Popover
              active={activePopover[shopifyLineItemId]}
              setActive={() => setActivePopover({ [shopifyLineItemId]: !activePopover[shopifyLineItemId] })}
              options={option}
            />
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true);
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFiltersValue = [
    {
      key: "taggedWith",
      label: disambiguateLabel("taggedWith", taggedWith),
      onRemove: () => handleTaggedWithRemove(),
    },
  ];

  const appliedFilters = !isEmpty(taggedWith) ? appliedFiltersValue : [];

  const filters = [
    {
      key: t("taggedWith"),
      label: t("label.order.filterBy"),
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
  ];

  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };
  const getComponent = () => {
    return (
      <>
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => closeBanner()}
            />
            <br />
          </>
        )}
        <AdminExportOrder isOpenModal={isExportModal} setIsOpenModal={setIsExportModal} />
        <Card>
          <Tabs tabs={listData(t).tabsData} selected={selectedTab} onSelect={handleTabChange} />
          <ResourceList
            items={orderList}
            renderItem={renderItem}
            loading={loading || orderLoading}
            queryValue={search}
            onQueryClear={handleQueryClear}
            onQueryChange={handleSearchChange}
            sortOptions={listData(t).sortOptions}
            sortValue={sortValue}
            onSortChange={handleSort}
            selectedItems={selectedItems}
            onSelectionChange={handleSelect}
            bulkActions={listData(t).bulkAction}
            filters={filters}
            handleClearAll={handleClearAll}
            appliedFilters={appliedFilters}
            count={orderCount}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </Card>
      </>
    );
  };
  return <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent()} />;
};

export default withErrorBoundary(AdminOrderList);

import React, { useContext } from "react";
import { Card, Checkbox, TextField, Select, Layout, Caption } from "@shopify/polaris";

import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import { SkeletonPage, Banner } from "lib/components";
import { shippingProp } from "../propTypes";

const Shipping = (props) => {
  const { currentUser } = useContext(PrivateContext);
  const { handleChange, data, cms, isLoading, learnMore } = props;
  const {
    value: { MAX_MEASUREMENT = 999 },
  } = constant;
  const options = ["lb", "oz", "kg", "g"];

  const isSeller = baseHelper.isSeller(currentUser);

  const selectWeight = (
    <Select
      id="selectWeight"
      value={data.weightUnit}
      label={cms("section.shipping.placeholder.weightUnit")}
      onChange={(value) => handleChange(constant.SELECT_WEIGHT, value)}
      labelHidden
      placeholder={cms("section.shipping.placeholder.selectUnit")}
      options={options}
    />
  );

  const acceptOnlyValidInput = (value, measurementValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && measurementValue);
  };

  const renderMeasurement = () => {
    return (
      <>
        <TextField
          id="length"
          label="Length"
          value={(data.length && data.length.toString()) || ""}
          placeholder="Length (cm)"
          min={0}
          max={MAX_MEASUREMENT}
          disabled={isSeller}
          onChange={(value) => {
            handleChange(constant.LENGTH, acceptOnlyValidInput(value, data.length));
          }}
        />
        <br />
        <TextField
          id="width"
          label="Width"
          value={(data.width && data.width.toString()) || ""}
          placeholder="Width (cm)"
          min={0}
          max={MAX_MEASUREMENT}
          disabled={isSeller}
          onChange={(value) => {
            handleChange(constant.WIDTH, acceptOnlyValidInput(value, data.width));
          }}
        />
        <br />
        <TextField
          id="height"
          label="Height"
          value={(data.height && data.height.toString()) || ""}
          placeholder="Height (cm)"
          min={0}
          max={MAX_MEASUREMENT}
          disabled={isSeller}
          onChange={(value) => {
            handleChange(constant.HEIGHT, acceptOnlyValidInput(value, data.height));
          }}
        />
      </>
    );
  };

  if (isLoading) {
    return <SkeletonPage />;
  }

  return (
    <Layout.Section>
      <Card
        title={[
          cms("section.shipping.title"),
          data && data.updatedAt && <Caption>{`${baseHelper.lastUpdateDate(data.updatedAt)}`}</Caption>,
        ]}
        actions={[
          {
            content: cms("common.label.learnMore"),
            onAction: () => {
              learnMore(cms("section.shipping.title"), "TODO:");
            },
          },
        ]}
        id="productShipping"
      >
        <Card.Section>
          <Checkbox
            id="shippingCheckbox"
            label={cms("section.shipping.label.isPhysicalProduct")}
            checked={data.isShipping || ""}
            onChange={(value) => handleChange(constant.IS_SHIPPING, value)}
          />
          {!data.isShipping && (
            <div>
              <br />
              <Banner isOpen status="info" isScrollTop={false}>
                <p>If you uncheck this, shipping value will not be calculated at the checkout.</p>
              </Banner>
            </div>
          )}
        </Card.Section>
        {data.isShipping && (
          <Card.Section title={cms("section.shipping.label.weight")}>
            <p>{cms("section.shipping.label.description")}</p>
            <br />
            <TextField
              id="weight"
              label={cms("section.shipping.label.weight")}
              placeholder={cms("section.shipping.placeholder.weightUnit")}
              min={0}
              value={(data.weight && data.weight.toString()) || ""}
              onChange={(value) => handleChange(constant.WEIGHT, acceptOnlyValidInput(value, data.weight))}
              connectedRight={selectWeight}
            />
            <br />
            {renderMeasurement()}
          </Card.Section>
        )}
      </Card>
    </Layout.Section>
  );
};
Shipping.propTypes = shippingProp.type;
export default Shipping;

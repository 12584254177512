import { gql } from "apollo-boost";

const GET_SHIPPING_BAND = gql`
  query {
    getShippingBand {
      data {
        shippingRows {
          _id
          description
          isUpdateAllowed
          name
          parentId
          price
          priceType
          userId
        }
        shippingTerm
      }
      status
      error
    }
  }
`;

const GET_SHIPPING = gql`
  query {
    getShipping {
      data {
        shipping {
          _id
          sellerId
          isHideDescription
          term
          type
          amount
          vendorIds
          allowVendor
          isFreeForCustomer
          shippingPerVendor {
            vendorIds
            isAllowed
          }
          setting {
            isAllBand
            isAllRule
            isAllOffer
            isAllVendor
            bandIds
            ruleIds
            offerIds
            vendorIds
          }
          createdAt
          updatedAt
        }
        vendors {
          label
          value
        }
        isShippingBandAvailable
      }
      status
      error
    }
  }
`;

export { GET_SHIPPING, GET_SHIPPING_BAND };

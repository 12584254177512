import { gql } from "apollo-boost";

const GET_PRODUCT_SETTING = gql`
  query {
    getProductSetting {
      data {
        isProductSync
        productApproval
        updatedAt
        isPriceTaxInclusive
        rate
      }
      error
      status
    }
  }
`;

export default GET_PRODUCT_SETTING;

import React, { useContext } from "react";
import { Card, Layout, TextContainer, FormLayout } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import components
import { DropZone } from "lib/components";

// import context
import { PrivateContext } from "lib/context";
import { FormContext } from "../../../context/context";

const Image = () => {
  const { handleChange, data, learnMore } = useContext(FormContext);
  const { cms } = useContext(PrivateContext);

  const addImage = (value) => {
    const productImages = (data && data.image) || [];
    const uploadedImages = [...productImages, ...value];
    handleChange(constant.IMAGE, uploadedImages);
  };
  const removeImage = (index) => {
    const productImages = data.image || [];
    if (index < 0 || productImages.length <= 0) {
      return;
    }
    productImages.splice(index, 1);
    handleChange(constant.IMAGE, productImages);
  };

  return (
    <Card
      title={cms("section.form.section.image.title")}
      id="productImage"
      actions={[
        {
          content: cms("common.label.learnMore"),
          onAction: () => {
            learnMore("productImage", cms("section.form.section.image.title"));
          },
        },
      ]}
    >
      <Card.Section>
        <FormLayout>
          <TextContainer>{cms("section.form.section.price.description")}</TextContainer>
          <DropZone
            id="addProductDropZone"
            onAdd={addImage}
            onRemove={removeImage}
            size={10}
            allowMultiple
            fileList={data.image || []}
          />
        </FormLayout>
      </Card.Section>
    </Card>
  );
};

export default Image;

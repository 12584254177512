import React from "react";
import { Modal, Stack, TextField, TextContainer } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import cmsSellerList from "../../cms/sellerListCMS";

const DeleteSeller = (props) => {
  const {
    openModal,
    buttonLoading,
    handleModalClose,
    deleteSellerAction,
    isReasonField,
    handleReasonChange,
    reason,
    selectedButton,
  } = props;
  const { t } = useTranslation();
  const sellerListCMS = cmsSellerList(t);
  const { label, sectionContent, button } = sellerListCMS;
  const sellerMessage = <TextContainer>{`${sectionContent.deleteSellerSelected}`}</TextContainer>;
  return (
    <Modal
      key={label.deleteModal}
      open={openModal}
      onClose={handleModalClose}
      title={label.delete}
      primaryAction={{
        content: button.deleteFromMc,
        onAction: () => deleteSellerAction(false, label.primaryDelete),
        loading: (selectedButton === label.primaryDelete && buttonLoading) || false,
      }}
      secondaryActions={[
        {
          content: button.deleteFromShop,
          onAction: () => deleteSellerAction(true, label.secondaryDelete),
          loading: (selectedButton === label.secondaryDelete && buttonLoading) || false,
        },
      ]}
      sectioned
    >
      <Modal.Section>
        <Stack.Item>{sellerMessage}</Stack.Item>
        <br />
        {isReasonField && <TextField label={label.reason} value={reason} onChange={handleReasonChange} multiline={4} />}
      </Modal.Section>
    </Modal>
  );
};

DeleteSeller.defaultProps = {
  reason: "",
  selectedButton: "",
  openModal: false,
  isReasonField: false,
  buttonLoading: false,
  handleModalClose: () => {},
  deleteSellerAction: () => {},
  handleReasonChange: () => {},
};

DeleteSeller.propTypes = {
  reason: PropTypes.string,
  selectedButton: PropTypes.string,
  openModal: PropTypes.bool,
  buttonLoading: PropTypes.bool,
  isReasonField: PropTypes.bool,
  handleModalClose: PropTypes.func,
  deleteSellerAction: PropTypes.func,
  handleReasonChange: PropTypes.func,
};

export default DeleteSeller;

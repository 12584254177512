import React from "react";

import { storageHelper } from "lib/helpers";
import { QuickReport } from "app/reports";
import { TwoColumnLayout } from "layout/private/components";
import constant from "lib/constant/constant";

import { AdminSellerList } from "./admin";

const { userKey } = constant;
const { admin } = userKey;

const SellerList = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminSellerList />;
    }
    return null;
  };
  const getSecondaryComponent = () => (
    <>
      <QuickReport />
    </>
  );

  const currentUserRole = storageHelper.get("userRole");
  return <TwoColumnLayout primary={getComponent(currentUserRole)} secondary={getSecondaryComponent()} />;
};

export default SellerList;

import React from "react";

import AdminQuickSetting from "app/reports/admin/widgets/quickSetting/quickSetting";
import OperatorQuickSetting from "app/reports/operator/widgets/quickSetting/quickSetting";
import ProviderQuickSetting from "app/reports/provider/widgets/quickSetting/quickSetting";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";

const { userKey } = constant;
const { operator, provider, admin } = userKey;

const QuickSetting = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminQuickSetting />;
    }
    if (role === operator) {
      return <OperatorQuickSetting />;
    }
    if (role === provider) {
      return <ProviderQuickSetting />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const QuickSettingsComponent = getComponent(currentUserRole);
  return QuickSettingsComponent;
};

export default QuickSetting;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Banner as PolarisBanner,
  Caption,
  Card,
  FormLayout,
  Layout,
  List,
  Modal,
  PageActions,
  Select,
  Stack,
  TextField,
  TextStyle,
  TextContainer,
} from "@shopify/polaris";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

// import GQL
import { GET_MARKUP, UPDATE_MARKUP } from "app/setup/apollo";
import { GET_VENDOR_AND_PRODUCT_TYPES } from "app/setup/apollo";

// import helpers
import { baseHelper } from "lib/helpers";

// import components
import { Banner, SkeletonAnnotated, Sheet } from "lib/components";
import { AdvanceOption } from "app/setup/modules/generic";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

const Markup = () => {
  const { currentUser, history, cms, isLoading } = useContext(PrivateContext);
  const { PERCENTAGE, PRODUCT_TYPE, FLAT, gql } = constant;
  const { _id: currentUserId = "", moneyFormat = "" } = currentUser || {};
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [standardType, setStandardType] = useState(FLAT);
  const [isShowAdvanced, setIsShowAdvanced] = useState(true);
  const [standardAmount, setStandardAmount] = useState("");
  const [isInvalidRow, setIsInvalidRow] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [advancedValues, setAdvancedValues] = useState([
    {
      type: null,
      category: null,
      value: null,
      price: 0,
    },
  ]);
  const bannerProps = {
    status: "",
    title: "",
  };
  const [ruleBy, setRuleBy] = useState(PRODUCT_TYPE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formWidth120px = { width: "120px" };

  const { loading: markupLoading = false, error: markupError, data: markupData } = useQuery(GET_MARKUP, {
    variables: {
      input: { sellerId: currentUserId },
    },
  });

  const [updateMarkup, { loading: updateMarkupLoading }] = useMutation(UPDATE_MARKUP);
  const {
    loading: vendorAndProductTypesLoading,
    error: vendorAndProductTypesError,
    data: vendorAndProductTypesData,
  } = useQuery(GET_VENDOR_AND_PRODUCT_TYPES);

  useEffect(() => {
    if (markupError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  }, [markupError, cms]);

  useEffect(() => {
    const responseData = baseHelper.getResponseData(markupData, gql.GET_MARKUP);
    const responseError = baseHelper.getResponseError(markupData, gql.GET_MARKUP);
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
      return;
    }
    if (!responseData) {
      return;
    }
    const { advance, type = "", ruleBy: markupRuleBy = "", markup = "", updatedAt = "" } = responseData;
    const isAdvancedValues = advance && advance.length;
    if (isAdvancedValues) {
      const formattedAdvanceValues = [];
      advance.forEach((item) => {
        if (item.vendorId) {
          formattedAdvanceValues.push({
            type: constant.VENDOR,
            category: item.vendorId,
            value: item.type,
            price: item.markup,
          });
        } else if (item.productTypeId) {
          formattedAdvanceValues.push({
            type: constant.CATEGORY,
            category: item.productTypeId,
            value: item.type,
            price: item.markup,
          });
        }
      });
      setAdvancedValues(formattedAdvanceValues);
    }
    setStandardType(type);
    setStandardAmount(markup);
    setRuleBy(markupRuleBy);
    setLastUpdated(updatedAt);
  }, [gql.GET_MARKUP, markupData, setBanner]);

  const submitCallback = (data) => {
    const inputData = {
      advancedValues: data.advancedValues,
      ruleBy: data.ruleBy,
      price: Number(standardAmount),
      type: standardType,
      sellerId: currentUserId,
    };

    updateMarkup({
      variables: { input: inputData },
    })
      .then((result) => {
        setIsSubmitEnabled(false);
        const responseError = baseHelper.getResponseError(result.data, gql.UPDATE_MARKUP);
        const responseData = baseHelper.getResponseData(result.data, gql.UPDATE_MARKUP);

        if (responseData) {
          bannerProps.status = "success";
          bannerProps.title = cms("section.message.success");
          setIsInvalidRow(false);
        }

        if (responseError) {
          bannerProps.status = "critical";
          bannerProps.title = responseError;
        }

        setBanner({
          isOpen: true,
          status: bannerProps.status,
          title: bannerProps.title,
        });
      })
      .catch(() => {
        setIsSubmitEnabled(false);
        setBanner({
          isOpen: true,
          status: "critical",
          title: cms("common.message.error.somethingWentWrong"),
        });
      });
  };

  const onFormSubmit = () => {
    setIsModalOpen(false);
    if (!(standardAmount === 0 || (standardAmount && standardType))) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.standardIsRequired", { item: "Markup" }),
        status: "critical",
      });
      return;
    }

    let isInvalidValue = false;
    if (standardType === PERCENTAGE && standardAmount > constant.value.MAX_PERCENTAGE) {
      isInvalidValue = true;
    }

    let invalidRowValues = false;
    let index = 0;
    let fieldValues = false;
    if (advancedValues && advancedValues.length) {
      advancedValues.forEach((row) => {
        const keys = Object.keys(row);

        keys.forEach((key) => {
          if (row[key]) {
            fieldValues = true;
          }
        });
        if (fieldValues) {
          keys.forEach((key) => {
            if (!row[key] && row[key] !== 0) {
              setIsInvalidRow(true);
              invalidRowValues = true;
            }
          });
          if (row.value === PERCENTAGE && row.price > constant.value.MAX_PERCENTAGE) {
            isInvalidValue = true;
          }
          advancedValues[index].price = Number(row.price);
          index += 1;
        }
      });
    }

    if (isInvalidValue) {
      setBanner({
        isOpen: true,
        title: cms("common.message.error.percentageUpperLimit"),
        status: "critical",
      });
      return;
    }

    const updatedAdvancedValue = advancedValues.filter((item) => item.type !== null);

    const data = {
      advancedValues: updatedAdvancedValue,
      ruleBy,
    };

    if (!invalidRowValues) {
      submitCallback(data);
    }
  };

  const onCloseBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const renderModal = () => {
    return (
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={cms("section.standard.modal.button.title")}
        primaryAction={{
          content: cms("section.standard.modal.button.primary"),
          onAction: () => onFormSubmit(),
        }}
        sectioned
      >
        <Modal.Section>{cms("section.standard.modal.content")}</Modal.Section>
      </Modal>
    );
  };

  const isFlatType = standardType === FLAT;
  const getAmountMax = isFlatType ? constant.value.MAX_FLAT : constant.value.MAX_PERCENTAGE;
  const getAmountPrefix = isFlatType ? (currentUser && moneyFormat) || constant.symbol.DOLLAR : "";
  const getAmountSuffix = isFlatType ? "" : constant.symbol.PERCENTAGE;

  const acceptOnlyValidInput = (value, prevValue) => {
    return (baseHelper.validatePositiveNumericValues(value) && value) || (value !== "" && prevValue) || "";
  };
  const handleStandardAmountChange = (value) => {
    setStandardAmount(value);
    setIsSubmitEnabled(true);
  };
  const handleStandardTypeChange = (value) => {
    setStandardType(value);
    setIsSubmitEnabled(true);
    setStandardAmount("");
  };

  const valueOptions = [
    {
      label: cms("common.label.flat"),
      value: FLAT,
    },
    {
      label: cms("common.label.percentage"),
      value: PERCENTAGE,
    },
  ];
  const primaryAction = {
    content: cms("common.label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };

  const learnMore = (title) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(`TODO: Add description about ${title}`);
  };
  if (markupLoading || isLoading) {
    return <SkeletonAnnotated />;
  }
  if (vendorAndProductTypesLoading) {
    return <SkeletonAnnotated />;
  }

  return (
    <>
      {renderModal()}
      {banner.isOpen && (
        <Layout.Section>
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onCloseBanner} />
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={cms("section.standard.title")} description={cms("section.standard.description")}>
        <Card
          title={[
            cms("section.standard.title"),
            <TextStyle variation="subdued">
              <Caption>{`${baseHelper.lastUpdateDate(lastUpdated)}`}</Caption>
            </TextStyle>,
          ]}
          actions={[
            {
              content: cms("common.label.learnMore"),
              onAction: () => learnMore(cms("section.standard.title")),
            },
          ]}
          sectioned
        >
          <FormLayout>
            <TextContainer>{cms("section.standard.description")}</TextContainer>
            <Stack wrap={false}>
              <div style={formWidth120px}>
                <Select
                  id="standardTypeSelect"
                  options={valueOptions}
                  placeholder={cms("common.label.value")}
                  value={standardType}
                  onChange={(selectedValue) => handleStandardTypeChange(selectedValue)}
                />
              </div>
              <div style={formWidth120px}>
                <TextField
                  id="standardAmount"
                  type={constant.NUMBER}
                  min={0}
                  max={getAmountMax}
                  prefix={getAmountPrefix}
                  suffix={getAmountSuffix}
                  value={standardAmount.toString()}
                  onChange={(value) => handleStandardAmountChange(acceptOnlyValidInput(value, standardAmount))}
                />
              </div>
            </Stack>
            <PolarisBanner status="info">
              <p>{cms("common.label.option")}</p>
              <br />
              <div id="listContent">
                <List type="bullet">
                  {cms("section.standard.caption").map((item) => {
                    return (
                      <List.Item key={item}>
                        <Caption>
                          <b>{item.title}</b>
                          {` ${item.description}`}
                        </Caption>
                      </List.Item>
                    );
                  })}
                </List>
              </div>
            </PolarisBanner>
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
      {markupData && (
        <AdvanceOption
          isOpen={isShowAdvanced}
          advancedValues={advancedValues}
          ruleBy={ruleBy}
          setParentBanner={setBanner}
          setAdvancedValueOption={setAdvancedValues}
          setAdvancedRuleByOption={setRuleBy}
          isInvalidRow={isInvalidRow}
          moneyFormat={moneyFormat}
          method="markUp"
          lastUpdated={lastUpdated}
          setSubmitEnable={setIsSubmitEnabled}
          vendorAndProductTypesLoading={vendorAndProductTypesLoading}
          vendorAndProductTypesError={vendorAndProductTypesError}
          vendorAndProductTypesData={vendorAndProductTypesData}
        />
      )}
      <Layout.Section>
        <PageActions
          primaryAction={{
            content: cms("common.button.submit"),
            onAction: () => setIsModalOpen(true),
            loading: updateMarkupLoading,
            disabled: updateMarkupLoading || !isSubmitEnabled,
          }}
          secondaryActions={[
            {
              content: cms("common.button.cancel"),
              onAction: () => history.push("/"),
            },
          ]}
        />
        <Sheet
          title={sheetTitle}
          isOpen={sheetActive}
          onClose={() => setSheetActive(false)}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
        >
          {sheetContent}
        </Sheet>
      </Layout.Section>
    </>
  );
};

export default withFeature(withErrorBoundary(Markup), { feature: constant.MARKUP_SETTING });

import React, { useContext, useState } from "react";
import { Tabs as PolarisTabs } from "@shopify/polaris";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";

import { adminTabs, operatorTabs, providerTabs } from "./tabsConfig/index";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const Tabs = () => {
  const { history, match, isLoading } = useContext(PrivateContext);
  const { cms } = useContext(PrivateContext);

  const getTabs = (role) => {
    if (role === admin) {
      return adminTabs(cms, isLoading);
    }
    if (role === operator) {
      return operatorTabs(cms, isLoading);
    }
    if (role === provider) {
      return providerTabs(cms, isLoading);
    }
    return [];
  };
  const currentPath = match.path;
  const currentUserRole = storageHelper.get("userRole");
  const tabs = getTabs(currentUserRole);
  const updatedTabs = tabs.map((tab) => {
    return { ...tab, content: tab.content };
  });
  const currentTabIndex = tabs.findIndex(
    (tab) => tab.link === currentPath || tab.activeRoutes.find((route) => route === currentPath)
  );

  const initialTabState = currentTabIndex === -1 ? 0 : currentTabIndex;
  const [selectedTab, setSelectedTab] = useState(initialTabState);

  const handleTabChange = (tabIndex) => {
    history.push(tabs[tabIndex].link);
    setSelectedTab(tabIndex);
  };

  return <PolarisTabs tabs={updatedTabs} selected={selectedTab} onSelect={handleTabChange} fitted />;
};

export default Tabs;

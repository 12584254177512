import constant from "lib/constant/constant";
import baseHelper from "./base";

const { THUMBNAIL, PROFILE, HEADER_LOGO } = constant.imageTypes;
class Image {
  constructor(){
    // variable Name
    this.imagekit = "imagekit";
  }
  resize = ({ url = "", type = ""}) => {
    if (!url) {
      return constant.NOIMAGESNAP;
    }

    if (!baseHelper.isValidUrl(url)) {
      return url;
    }
      
    const getCloudinaryImage = (width, imageURL) => {
      const splitBy = "/upload/";
      const [domainDir, imagePath] = imageURL.split("/upload/");
      return `${domainDir}${splitBy}w_${width},	c_scale/${imagePath}`;
    };

    // const getImagekitImage = (width, imageURL) => {
    //   const splitBy= "/product/"
    //   const [domainDir, imagePath] = imageURL.split(splitBy);
    //   const [productId,imageName]=imagePath.split("/");
    //   return `${domainDir}${splitBy}${productId}/tr:w-${width}/${imageName}`;

    // };

    const getImagekitImage = (width, imageURL) => {
      return `${imageURL}?tr=w-${width}`;
      // const splitBy = imageURL.includes("/product/") ? "/product/" : "/user/";
      // const [domainDir, imagePath] = imageURL.split(splitBy);
      // const [productId, imageName] = imagePath.split("/");
      // return `${domainDir}${splitBy}${productId}/tr:w-${width}/${imageName}`;
    };

    const getThumbnail = (imageURL) => {
      const WIDTH = "80";
      return imageURL.includes(this.imagekit) ? getImagekitImage(WIDTH, imageURL) : getCloudinaryImage(WIDTH, imageURL);
    };

    const getProfileImage = (imageURL) => {
      const WIDTH = "200";
      return imageURL.includes(this.imagekit) ? getImagekitImage(WIDTH, imageURL) : getCloudinaryImage(WIDTH, imageURL);
    };

    const getHeaderLogo = (imageURL) => {
      const WIDTH = "200";
      return imageURL.includes(this.imagekit) ? getImagekitImage(WIDTH, imageURL) : getCloudinaryImage(WIDTH, imageURL);;
    };

    switch (type) {
      case THUMBNAIL:
        return getThumbnail(url);
      case PROFILE:
        return getProfileImage(url);
      case HEADER_LOGO:
        return getHeaderLogo(url);
      default:
        return url;
    }
  };
}

export default new Image();

import React from "react";
import { Card, TextStyle } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

import constant from "lib/constant/constant";
import baseHelper from "lib/helpers/base";

import { customerProp } from "../propsType";

const Customer = (props) => {
  const { t } = useTranslation();
  const { data, fulfillment = {}, currentUser, customerData = {} } = props;
  const { customer, shippingAddress, billingAddress } = data || {};
  const { default_address: defaultAddress } = customer || {};
  const { address1, address2, zip, city, province, country, phone: shippingPhone } = shippingAddress || {};
  const { type = "", seller = {} } = fulfillment || {};
  const fulfillmentData =
    (fulfillment && type === constant.CONSIGNMENT && baseHelper.fulfillmentDetails(fulfillment, currentUser)) || {};

  const { address = "", city: cityName = "", pincode = "", country: countryName = "" } = fulfillmentData || {};

  const { email: sellerEmail = {}, phoneNumber = "" } = seller || {};

  const { customer: customerValue } = customerData;

  const {
    address1: billingAddress1,
    address2: billingAddress2,
    zip: billingZip,
    city: billingCity,
    province: billingProvince,
    country: billingCountry,
    phone: billingPhone,
  } = billingAddress || {};

  const handleCustomer = () => {
    if (customerValue && customerValue.email) {
      return (
        <>
          <p>{(customerData && customerValue.email) || t("message.noEmailAddress")}</p>
          <p>{(customerData && customerValue.phoneNumber) || t("message.noPhoneNumber")}</p>
        </>
      );
    }
    if (!(customerValue && customerValue.email)) {
      return (
        <>
          <p>{(seller && sellerEmail && sellerEmail.address) || t("message.noEmailAddress")}</p>
          <p>{(seller && phoneNumber) || t("message.noPhoneNumber")}</p>
        </>
      );
    }
  };

  return (
    <Card title={t("label.customer")}>
      <Card.Section>
        <TextStyle variation={!(customer && defaultAddress && defaultAddress.name) && constant.SUBDUED}>
          {(customer && defaultAddress && defaultAddress.name) || t("message.noCustomer")}
        </TextStyle>
      </Card.Section>
      <Card.Section title={t("label.contactInformation")}>
        <TextStyle variation={constant.SUBDUED}>{handleCustomer()}</TextStyle>
      </Card.Section>
      {(fulfillment && type === constant.CONSIGNMENT && (
        <Card.Section title={t("label.shippingAddress").toUpperCase()}>
          <TextStyle>
            <p>{address}</p>
            <p>{cityName}</p>
            <p>{countryName}</p>
            <p>{pincode}</p>
          </TextStyle>
        </Card.Section>
      )) || (
        <>
          <Card.Section title={t("label.shippingAddress").toUpperCase()}>
            {shippingAddress ? (
              <TextStyle>
                <p>
                  {shippingAddress && address1}
                  {shippingAddress && address2}
                </p>
                <p>
                  {shippingAddress && zip}
                  {shippingAddress && city}
                  {shippingAddress && province}
                </p>
                <p>{shippingAddress && country}</p>
                <p>{shippingAddress && shippingPhone}</p>
              </TextStyle>
            ) : (
              <TextStyle variation={constant.SUBDUED}>{t("message.noShippingAddress")}</TextStyle>
            )}
          </Card.Section>
          <Card.Section title={t("label.billingAddress")}>
            <TextStyle>
              {billingAddress ? (
                <TextStyle>
                  <p>
                    {billingAddress && billingAddress1}
                    {billingAddress && billingAddress2}
                  </p>
                  <p>
                    {billingAddress && billingZip}
                    {billingAddress && billingCity}
                    {billingAddress && billingProvince}
                  </p>
                  <p>{billingAddress && billingCountry}</p>
                  <p>{billingAddress && billingPhone}</p>
                </TextStyle>
              ) : (
                <TextStyle variation={constant.SUBDUED}>{t("message.noBillingAddress")}</TextStyle>
              )}
            </TextStyle>
          </Card.Section>
        </>
      )}
    </Card>
  );
};
Customer.propTypes = customerProp.type;
export default Customer;

import React, { useContext } from "react";
import { Card, TextField } from "@shopify/polaris";

import constant from "lib/constant/constant";

// import contexts
import { PrivateContext } from "lib/context/privateContext";
// import context
import { FormContext } from "../../../context/context";

import vendorProductCms from "../../../cms/vendorProduct";

const Price = () => {
  const { cms = {} } = useContext(PrivateContext);
  const { data, handleChange } = useContext(FormContext);

  return (
    <Card title="Pricing" id="productPrice" sectioned>
      <Card.Section>
        <TextField
          id="price"
          label={cms.label.price}
          type="number"
          min="0"
          value={data.price || ""}
          placeholder={vendorProductCms.placeHolder.price}
          onChange={(value) => handleChange(constant.PRICE, Number(value))}
        />
        <br />
        <TextField
          id="comparePrice"
          label={cms.label.compare}
          type="number"
          min="0"
          placeholder={vendorProductCms.placeHolder.compare}
          value={data.comparePrice || ""}
          onChange={(value) => handleChange(constant.COMPARE_PRICE, Number(value))}
        />
        <br />
      </Card.Section>
    </Card>
  );
};

export default Price;

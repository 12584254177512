import { gql } from "apollo-boost";

const GET_VENDOR_LIST = gql`
  query getVendorList($input: GetListInput) {
    getVendorList(input: $input) {
      data {
        count
        vendors {
          email {
            address
            verified
          }
          firstName
          lastName
          brandName
          createdAt
          _id
          roles {
            name
          }
        }
      }
      status
      error
    }
  }
`;
const VERIFY_OTP = gql`
  query verifyOtp($input: VerifyOtp) {
    verifyOtp(input: $input) {
      data {
        token
      }
      status
      error
    }
  }
`;

const GET_SELLER_ASSOCIATION = gql`
  query getSellerAssociation($input: GetSellerAssociation!) {
    getSellerAssociation(input: $input) {
      status
      error
      data {
        _id
        shop
      }
    }
  }
`;

export { GET_VENDOR_LIST, VERIFY_OTP, GET_SELLER_ASSOCIATION };

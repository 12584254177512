import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { emailRegex } = yupHelper;

const emailSchema = (cms) =>
  yup.object().shape({
    email: yup
      .string()
      .required(cms("common.message.error.emailIsRequired"))
      .matches(emailRegex, cms("common.message.error.enterValidEmail")),
  });

const passwordSchema = (cms) =>
  yup.object().shape({
    password: yup.string().required(cms("common.message.error.passwordIsRequired")),
  });

export { emailSchema, passwordSchema };

import React from "react";
import { Card } from "@shopify/polaris";
import PropTypes from "prop-types";

// helper components
import { DropZone } from "lib/components";

const Image = (props) => {
  const { onChange, variantImage = [] } = props;

  return (
    <Card title="Images" sectioned>
      <DropZone
        id="dropZone"
        onAdd={(value) => onChange(value)}
        onRemove={() => onChange([])}
        fileList={variantImage}
        size={10}
      />
    </Card>
  );
};

Image.propTypes = {
  variantImage: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

Image.defaultProps = {
  variantImage: [],
};

export default Image;

import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Button,
  Card,
  Checkbox,
  Collapsible,
  DisplayText,
  Layout,
  Link,
  List,
  Modal,
  Stack,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";

import { baseHelper, errorHelper } from "lib/helpers";
import { Banner } from "lib/components";
import constant from "lib/constant/constant";

import { GET_PRODUCT_VERSION } from "../../../../apollo/queries";
import { UPDATE_PRODUCT_VERSION } from "../../../../apollo/mutations";

const ProductVersioning = (props) => {
  const { productId = "", setListBanner } = props || {};
  const { productVersion: versioningContent, gql } = constant;
  const { GET_PRODUCT_VERSION: GET_PRODUCT_VERSION_TEXT, UPDATE_PRODUCT_VERSION: UPDATE_PRODUCT_VERSION_TEXT } = gql;

  // states
  const [showModal, setShowModal] = useState(false);
  const [revertVersionId, setRevertVersionId] = useState("");
  const [productRevertKeys, setProductRevertKeys] = useState([]);
  const [showProductKeyChange, setShowProductKeyChange] = useState([]);
  const [isVariant, setIsVariant] = useState(false);
  const [productVersions, setProductVersions] = useState([]);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  // query calls
  const { error, loading, data } = useQuery(GET_PRODUCT_VERSION, {
    variables: {
      input: {
        productId,
      },
    },
  });

  // mutation calls
  const [updateProductVersion, { loading: updateVersionLoading, error: updateVersionError }] = useMutation(
    UPDATE_PRODUCT_VERSION
  );

  useEffect(() => {
    const responseError = baseHelper.getResponseError(data, GET_PRODUCT_VERSION_TEXT);
    if (responseError) {
      setListBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
      return;
    }
    const { isVariant = false, productVersions = [] } = baseHelper.getResponseData(data, GET_PRODUCT_VERSION_TEXT);

    setIsVariant(isVariant);
    setProductVersions(productVersions);
  }, [data]);

  useEffect(() => {
    if (error) {
      setListBanner({
        isOpen: true,
        status: "critical",
        title: errorHelper.parse(error),
      });
    }
  }, [error]);

  useEffect(() => {
    if (updateVersionError) {
      setListBanner({
        isOpen: true,
        status: "critical",
        title: errorHelper.parse(updateVersionError),
      });
    }
  }, [updateVersionError]);

  if (loading) {
    return null;
  }

  const toggleRevertModal = (versionId) => {
    setShowModal(!showModal);
    setRevertVersionId(versionId);
    setProductRevertKeys([]);
    setShowProductKeyChange([]);
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const revertChange = async (productChangeId) => {
    if (!(productRevertKeys && productRevertKeys.length)) {
      setBanner({
        isOpen: true,
        title: "Select at least one change to revert.",
        status: "critical",
      });
      return;
    }

    const data = {
      productChangeId: baseHelper.mongoIdAsString(productChangeId),
      productKeysToRevert: [...productRevertKeys],
    };

    const updateVersionResp = await updateProductVersion({
      variables: {
        input: data,
      },
    });

    const responseError = baseHelper.getResponseError(updateVersionResp, UPDATE_PRODUCT_VERSION_TEXT);

    if (responseError) {
      setListBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
      return;
    }

    toggleRevertModal("");
    setListBanner({
      isOpen: true,
      title: "Selected changes are reverted successfully",
      status: "success",
    });
  };

  const getData = (value) => (_.isBoolean(value) && (value ? versioningContent.yes : versioningContent.no)) || value;

  const getDataInHTMLForm = (data) => ({ __html: data });

  const handleRevertTick = (key) => {
    const keyIndex = productRevertKeys.findIndex((item) => item === key);
    if (keyIndex !== -1) {
      productRevertKeys.splice(keyIndex, 1);
    } else {
      productRevertKeys.push(key);
    }
    setProductRevertKeys([...productRevertKeys]);
  };

  const handleToggleProductDiff = (key) => {
    const keyIndex = showProductKeyChange.findIndex((item) => item === key);
    if (keyIndex !== -1) {
      showProductKeyChange.splice(keyIndex, 1);
    } else {
      showProductKeyChange.push(key);
    }

    setShowProductKeyChange([...showProductKeyChange]);
  };

  const renderProductChange = ({ key, prev, updated }) => {
    const isKeyDescription = key === "description";
    const isKeyTags = key === "tags";

    if (isVariant && !["title", "description", "tags", "productType"].includes(key)) {
      return null;
    }
    const prevData = isKeyTags ? baseHelper.sort(prev).join(", ") : getData(prev);
    const updatedData = isKeyTags ? baseHelper.sort(updated).join(", ") : getData(updated);

    if (isKeyTags && (!(prevData || updatedData) || prevData === updatedData)) {
      return null;
    }

    const prevProductData = isKeyDescription ? <p dangerouslySetInnerHTML={getDataInHTMLForm(prevData)} /> : prevData;
    const updatedProductData = isKeyDescription ? (
      <p dangerouslySetInnerHTML={getDataInHTMLForm(updatedData)} />
    ) : (
      updatedData
    );

    const buttonText = showProductKeyChange.includes(key) ? "Hide" : "Show";

    return (
      <Card sectioned>
        <Stack>
          <Stack.Item>
            <Checkbox
              label="Basic checkbox"
              labelHidden
              checked={productRevertKeys.includes(key)}
              onChange={() => handleRevertTick(key)}
            />
          </Stack.Item>
          <Stack.Item fill>
            <DisplayText size="small">{versioningContent[key]} Changed</DisplayText>
          </Stack.Item>
          <Stack.Item>
            <Button size="slim" onClick={() => handleToggleProductDiff(key)}>
              {buttonText}
            </Button>
          </Stack.Item>
        </Stack>
        <Stack vertical>
          <Collapsible open={showProductKeyChange.includes(key)} id="basic-collapsible-2">
            <Card.Section title="Previous">{prevProductData || "No Value"}</Card.Section>
            <Card.Section subdued title="Updated">
              {updatedProductData || "No Value"}
            </Card.Section>
          </Collapsible>
        </Stack>
      </Card>
    );
  };

  const renderImageChange = ({ key, prev, updated }) => {
    const buttonText = showProductKeyChange.includes(key) ? "Hide" : "Show";
    const prevImages = [];
    const updatedImages = [];

    (prev || []).forEach((image) => {
      prevImages.push([<Thumbnail source={image.imageUrl} alt="Vendor Image" />]);
    });

    (updated || []).forEach((image) => {
      updatedImages.push([<Thumbnail source={image.imageUrl} alt="Vendor Image" />]);
    });

    return (
      <Card sectioned>
        <Stack>
          <Stack.Item>
            <Checkbox
              label="Basic checkbox"
              labelHidden
              checked={productRevertKeys.includes(key)}
              onChange={() => handleRevertTick(key)}
            />
          </Stack.Item>
          <Stack.Item fill>
            <DisplayText size="small">{versioningContent[key]} Changed</DisplayText>
          </Stack.Item>
          <Stack.Item>
            <Button size="slim" onClick={() => handleToggleProductDiff(key)}>
              {buttonText}
            </Button>
          </Stack.Item>
        </Stack>
        <Stack vertical>
          <Collapsible open={showProductKeyChange.includes(key)} id="basic-collapsible-2">
            <Card.Section title="Previous">
              {prevImages && prevImages.length ? <Stack>{prevImages}</Stack> : "No Images"}
            </Card.Section>
            <Card.Section subdued title="Updated">
              {updatedImages && updatedImages.length ? <Stack>{updatedImages}</Stack> : "No Images"}
            </Card.Section>
          </Collapsible>
        </Stack>
      </Card>
    );
  };

  const renderVariantChange = ({ key, prev, updated, extraDetail = [] }) => {
    const buttonText = showProductKeyChange.includes(key) ? "Hide" : "Show";

    const getVariantInfo = (variant = {}) => {
      return (
        <Card.Section title={`Variant Position: ${variant.position}`}>
          {variant.option1Val ? `${variant.option1}: ${variant.option1Val}` : ""}
          {variant.option1Val ? <br /> : ""}
          {variant.option2Val ? `${variant.option2}: ${variant.option2Val} <br/>` : ""}
          {variant.option2Val ? <br /> : ""}
          {variant.option3Val ? `${variant.option3}: ${variant.option3Val} <br/>` : ""}
          {variant.option3Val ? <br /> : ""}
          Image: {variant.image ? <Thumbnail source={variant.image} alt="Variant Image" /> : "No Image"} <br />
          Price: {variant.price || "0"} <br />
          Compare Price: {variant.comparePrice || "0"} <br />
          Quantity: {variant.inventoryQuantity || "0"} <br />
          Inventory Management: {variant.inventoryManagement ? "Shopify" : "Don't track this inventory"} <br />
          SKU: {variant.sku || "No Value"} <br />
          Barcode: {variant.barcode || "No Value"} <br />
          Shipping Required: {variant.isShipping ? "Yes" : "No"} <br />
          Weight: {variant.weight || "No"} <br />
          Weight Unit: {variant.weightUnit || "No Value"}
        </Card.Section>
      );
    };

    const prevVariants = (prev || []).map(getVariantInfo);
    const updatedVariants = (updated || []).map(getVariantInfo);

    return (
      <Card sectioned>
        <Stack>
          <Stack.Item>
            <Checkbox
              label="Basic checkbox"
              labelHidden
              checked={productRevertKeys.includes(key)}
              onChange={() => handleRevertTick(key)}
            />
          </Stack.Item>
          <Stack.Item fill>
            <DisplayText size="small">{versioningContent[key]} Changed</DisplayText>
          </Stack.Item>
          <Stack.Item>
            <Button size="slim" onClick={() => handleToggleProductDiff(key)}>
              {buttonText}
            </Button>
          </Stack.Item>
        </Stack>
        <Stack vertical>
          <Collapsible open={showProductKeyChange.includes(key)} id="basic-collapsible-2">
            <Card.Section title="Previous">
              {prevVariants.length ? <Card>{prevVariants}</Card> : "No Variants"}
            </Card.Section>
            <Card.Section subdued title="Updated">
              {updatedVariants.length ? <Card>{updatedVariants}</Card> : "No Variants"}
            </Card.Section>
          </Collapsible>
        </Stack>
      </Card>
    );
  };

  const renderRevertModal = (productVersion) => {
    const { changes = [], operation, userName, createdAt, version, _id } = productVersion;
    if (revertVersionId !== baseHelper.mongoIdAsString(_id)) {
      return null;
    }

    return (
      <Modal
        key="productChangeRevertModal"
        open={showModal}
        onClose={toggleRevertModal}
        title={`Revert changes from Version ${version}`}
        primaryAction={{
          content: "Revert",
          onAction: () => revertChange(_id),
          loading: updateVersionLoading,
        }}
        secondaryActions={{
          content: "Cancel",
          onAction: () => toggleRevertModal(),
        }}
        sectioned
      >
        <Modal.Section>
          {banner.isOpen && (
            <>
              <Banner
                isOpen={banner.isOpen}
                status={banner.status}
                title={banner.title}
                onDismiss={() => setBanner({ isOpen: false, title: "", status: "" })}
              />
              <br />
            </>
          )}
          <Layout>
            <Layout.Section>
              {changes.map((change) => {
                const { key } = change;
                if (key === "status") {
                  return null;
                }
                return (
                  (key === "images" && renderImageChange(change)) ||
                  (key === "variants" && renderVariantChange(change)) ||
                  renderProductChange(change)
                );
              })}
            </Layout.Section>
          </Layout>
        </Modal.Section>
      </Modal>
    );
  };

  const renderVersion = (productVersion, isLatest) => {
    const { changes = [], operation, userName, createdAt, version, via, revertedVersion, _id } = productVersion;

    const isOnlyStatusChanged = changes && changes.length === 1 && changes[0].key === "status";
    const isReverted = operation === "reverted";
    const isNoChange = changes && (!changes.length || (changes.length && isOnlyStatusChanged));

    const isShowChange = ({ key }) => {
      const isProductKey = ["title", "description", "tags", "productType", "images", "variants"].includes(key);
      return key !== "status" && (isVariant ? isProductKey : true);
    };

    const viaText =
      (via === "shopify" && "Operator's Shopify Store") ||
      (via === "vendorShopify" && "Vendor Live Connect") ||
      "Marketcube Portal";

    return (
      <List.Item>
        <p className={isLatest ? "title-list" : "title-list other"}>
          {baseHelper.ucFirst(operation)}
          {" by "}
          <TextStyle variation="strong">{userName}</TextStyle> On {baseHelper.formatDate(createdAt, true)}
        </p>
        <p>
          Change was triggered by <TextStyle variation="strong">{viaText}</TextStyle>
        </p>
        <p>
          <TextStyle variation="subdued">
            Version {version}{" "}
            {!isNoChange && (
              <Link monochrome onClick={() => toggleRevertModal(baseHelper.mongoIdAsString(_id))}>
                Revert to this version{" "}
              </Link>
            )}
          </TextStyle>
        </p>
        {changes && changes.length && !isOnlyStatusChanged ? (
          <List type="bullet">
            {changes.map(
              (change) =>
                isShowChange(change) && (
                  <List.Item>
                    <TextStyle variation="strong">
                      {versioningContent[`${change.key}`] || "Basic"} Changed{" "}
                      {isReverted ? `(Reverted to version ${revertedVersion})` : ""}
                    </TextStyle>
                  </List.Item>
                )
            )}
          </List>
        ) : null}
      </List.Item>
    );
  };

  return productVersions && productVersions.length ? (
    <div className="timelineWrapper">
      {productVersions.map((version, index) => {
        const isFirstChange = !index;
        return [
          <div className="timeline">
            <List>{renderVersion(version, isFirstChange)}</List>
          </div>,
          renderRevertModal(version),
        ];
      })}
    </div>
  ) : (
    "No changes done"
  );
};

ProductVersioning.defaultProps = {
  productId: "",
  listBanner: () => {},
};

ProductVersioning.propTypes = {
  keyIndex: PropTypes.string,
  bulkAction: PropTypes.func,
};

export default ProductVersioning;

import React, { useState, useEffect, useContext, useCallback } from "react";
import { Card, TextField, FormLayout, Stack, Button, Heading, Link, Checkbox, Banner } from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import context
import { PrivateContext } from "lib/context/privateContext";

// import Constant
import constant from "lib/constant/constant";

// import helpers
import baseHelper from "lib/helpers/base";
import config from "config";
import { paypalTextFields } from "./fieldConfig";
import { PaymentContext } from "../context";

// import validate
import validate from "../yup";

// import Gql
import { CONNECT_PAYPAL_SELLER, DISCONNECT_PAYPAL } from "../../../../../apollo/mutations";

const PaypalSection = () => {
  const { t } = useTranslation();
  const { gql, PAYPAL } = constant;
  const { setBanner } = useContext(PaymentContext);
  const { currentUser = {}, oldCMS: cms = {} } = useContext(PrivateContext);
  const { message, label } = cms;
  const { clientId = "", secret = "" } = (currentUser && currentUser.paypalKey) || {};
  const [connectToPaypal, { loading: paypalLoading }] = useMutation(CONNECT_PAYPAL_SELLER);
  const [disconnectFromPaypal, { loading: disconnectPaypalLoading }] = useMutation(DISCONNECT_PAYPAL);
  const [isPaypalConnected, setIsPaypalConnected] = useState(!!(clientId && secret));
  const [values, setValues] = useState({
    paypalClientId: isPaypalConnected ? clientId : "",
    paypalSecret: isPaypalConnected ? secret : "",
  });
  const [uri, setUri] = useState({
    paypalUri: false,
    customPaypalUri: false,
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [button, setButton] = useState(true);

  const isPaypalError = useCallback(() => Object.values(errorMessage).some((error) => error), [errorMessage]);
  const isPaypalValuesFilled = useCallback(() => !!(values.paypalClientId && values.paypalSecret), [
    values.paypalClientId,
    values.paypalSecret,
  ]);

  useEffect(() => {
    setButton(isPaypalError() || !isPaypalValuesFilled());
  }, [errorMessage, isPaypalError, isPaypalValuesFilled]);

  const handleValueChange = (field, value) => {
    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleOnBlurChange = async (field, value) => {
    const error = await validate(field, value.trim());
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const handlePaypalConnect = () => {
    connectToPaypal({ variables: { input: { clientId: values.paypalClientId, secret: values.paypalSecret } } })
      .then((res) => {
        const resData = baseHelper.getResponseData(res.data, gql.CONNECT_PAYPAL_SELLER);
        let banner = { isOpen: true, title: label.connectedPaypal, status: "success" };
        setIsPaypalConnected(resData);
        if (!resData) {
          const error = baseHelper.getResponseError(res.data, gql.CONNECT_PAYPAL_SELLER);
          banner = { isOpen: true, title: error, status: "critical" };
        }
        setBanner({ isOpen: banner.isOpen, title: banner.title, status: banner.status });
      })
      .catch(() => {
        setBanner({ isOpen: true, title: message.somethingWentWrong, status: "critical" });
      });
  };
  const handlePaypalDisconnect = () => {
    disconnectFromPaypal({ variables: { input: { paymentMethod: PAYPAL } } })
      .then((res) => {
        const resData = baseHelper.getResponseData(res.data, gql.DISCONNECT_PAYPAL);
        let banner = { isOpen: true, title: message.disconnectPaypalSuccessfully, status: "success" };
        setIsPaypalConnected(!resData);
        if (!resData) {
          const error = baseHelper.getResponseError(res.data, gql.DISCONNECT_PAYPAL);
          banner = { isOpen: true, title: error, status: "critical" };
        }
        setBanner({ isOpen: banner.isOpen, title: banner.title, status: banner.status });
      })
      .catch(() => {
        setBanner({ isOpen: true, title: message.somethingWentWrong, status: "critical" });
      });
  };
  return (
    <>
      <Card
        sectioned
        title={(!isPaypalConnected && label.connectPaypal) || (isPaypalConnected && label.connectedPaypal)}
      >
        {!isPaypalConnected && (
          <FormLayout>
            <Banner
              id="paypalBanner"
              title={message.paypalDoc}
              action={{
                content: message.readDocumentation,
                url: constant.PAYPAL_BANNER_URL,
              }}
              status="critical"
            >
              <p id="paypalBannerDescription">{message.paypalBannerDescription}</p>
            </Banner>
            <Stack.Item>
              <Heading>
                <span id="paypalStep1">{label.step1}</span>
              </Heading>
              <p id="paypalDeveloperAccountLink">
                {message.clientIdAndSecretPaypal}
                <Link id="paypalUrl" external url={constant.PAYPAL_DEVELOPER_URL}>
                  {` ${message.paypalDeveloper}`}
                </Link>
              </p>
            </Stack.Item>
            {paypalTextFields.map((textField) => (
              <TextField
                id={textField.key}
                key={textField.key}
                label={textField.label}
                value={values[textField.key]}
                placeholder={textField.placeholder}
                onChange={(value) => handleValueChange(textField.key, value)}
                onBlur={() => handleOnBlurChange(textField.key, values[textField.key])}
                error={errorMessage && errorMessage[textField.key]}
              />
            ))}
            <Stack.Item>
              <Heading>
                <span id="paypalStep2">{label.step2}</span>
              </Heading>
              <p id="paypalHeading">{message.addRedirectPaypal}</p>
            </Stack.Item>
            <Checkbox
              id="customPaypalUri"
              label={`${config.rootURL}/paypal/callback`}
              checked={uri.customPaypalUri}
              onChange={() => setUri((prevState) => ({ ...prevState, customPaypalUri: !uri.customPaypalUri }))}
            />
            <Stack.Item>
              <Heading>
                <span id="paypalStep3">{label.step3}</span>
              </Heading>
              <p id="paypalLogInfo">{message.paypalLogInInformation}</p>
            </Stack.Item>
            <Checkbox
              id="paypalEmailUri"
              label={message.paypalLogInInformation}
              checked={uri.paypalUri}
              onChange={() => setUri((prevState) => ({ ...prevState, paypalUri: !uri.paypalUri }))}
            />
            <Stack.Item>
              <b>{t("message.automaticPayout.forMoreSafer")}</b>
            </Stack.Item>
            <Stack>
              <Stack.Item fill />
              <Stack.Item>
                <Button
                  id="paypalConnectButton"
                  primary
                  loading={paypalLoading}
                  disabled={button || !uri.paypalUri || !uri.customPaypalUri}
                  onClick={() => handlePaypalConnect()}
                >
                  {label.connect}
                </Button>
              </Stack.Item>
            </Stack>
          </FormLayout>
        )}
        {isPaypalConnected && (
          <FormLayout>
            <Stack.Item>
              {paypalTextFields.map((textField) => (
                <TextField
                  id={textField.key}
                  key={textField.key}
                  label={textField.label}
                  value={values[textField.key]}
                  disabled={isPaypalConnected}
                />
              ))}
            </Stack.Item>
            <Stack>
              <Stack.Item fill />
              <Stack.Item>
                <Button
                  id="paypalDisconnectButton"
                  destructive
                  loading={disconnectPaypalLoading}
                  onClick={() => handlePaypalDisconnect()}
                >
                  {label.disconnect}
                </Button>
              </Stack.Item>
            </Stack>
          </FormLayout>
        )}
      </Card>
    </>
  );
};

export default PaypalSection;

import React, { useContext } from "react";
import { Layout } from "@shopify/polaris";

// import hoc
import { withFeature } from "lib/hoc";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";
import PropTypes from "prop-types";
import Address from "../../components/address";

const Global = (props) => {
  const { cms } = useContext(PrivateContext);
  const { onChange, global, destinations, loading, setError } = props;

  return (
    <Layout.AnnotatedSection title={cms("label.defaultConsignment")} description={cms("helpText.defaultConsignment")}>
      <Address
        addressData={global}
        handleChange={onChange}
        destinations={destinations}
        loading={loading}
        setError={setError}
      />
    </Layout.AnnotatedSection>
  );
};

Global.propTypes = {
  onChange: PropTypes.func,
  global: PropTypes.objectOf(PropTypes.string),
  destinations: PropTypes.objectOf(PropTypes.string),
  loading: PropTypes.bool,
  setError: PropTypes.bool,
};
Global.defaultProps = {
  onChange: () => {},
  global: {},
  destinations: {},
  loading: null,
  setError: null,
};

export default withFeature(Global, {
  feature: constant.CONSIGNMENT,
  subFeature: constant.GLOBAL_CONSIGNMENT,
});

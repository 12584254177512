import * as yup from "yup";
import { yupHelper } from "lib/helpers";

const { passwordRegex } = yupHelper;

const passwordSchema = (cms) =>
  yup.object().shape({
    password: yup.string().required(cms("common.message.error.passwordIsRequired")),
  });

const newPasswordSchema = (cms) =>
  yup.object().shape({
    newPassword: yup
      .string()
      .required(cms("message.error.newPasswordIsRequired"))
      .matches(passwordRegex, cms("common.message.error.enterValidPassword")),
  });
const confirmationPasswordSchema = (cms) =>
  yup.object().shape({
    confirmationPassword: yup
      .string()
      .required(cms("common.message.error.confirmPasswordIsRequired"))
      .oneOf([yup.ref("newPassword"), null], cms("message.error.confirmPasswordMatch")),
  });

export { passwordSchema, newPasswordSchema, confirmationPasswordSchema };

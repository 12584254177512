// import packages
import React, { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Card, Layout, FormLayout, TextField, PageActions } from "@shopify/polaris";

// import helpers components
import { Footer } from "layout/public/components";
import { Banner } from "lib/components";

// import helpers
import { storageHelper, baseHelper } from "lib/helpers";

// import context
import { PublicContext } from "lib/context";

// import yup validation
import validate from "./yup";

// import mutation
import { LOGIN } from "../../apollo/mutations";

const Login = () => {
  const { history, location, cms } = useContext(PublicContext);
  const [loginUser, { loading }] = useMutation(LOGIN);
  const [values, setValues] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [submitButton, setSubmitState] = useState(false);
  const handleChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.from &&
      location.state.from.state &&
      location.state.from.state.isUserNotFound
    ) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("message.error.notFound"),
      });
    }
  }, [cms, location]);

  useEffect(() => {
    const isAnyValidationError = errorMessage && !!(errorMessage.email || errorMessage.password);
    const isAllValuesFilled = values.email && values.password;
    setSubmitState(isAllValuesFilled && !isAnyValidationError);
  }, [values, errorMessage]);

  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const onSubmit = async () => {
    await handleValidation("email", values.email);
    await handleValidation("password", values.password);

    if (!submitButton) {
      return;
    }

    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: "You are not connected to the internet.",
      });
      return;
    }

    loginUser({ variables: { input: { email: values.email, password: values.password } } })
      .then((response) => {
        const loginResponse = baseHelper.getResponseData(response.data, "login");
        const loginError = baseHelper.getResponseError(response.data, "login");
        if (loginError) {
          setBanner({
            isOpen: true,
            status: "critical",
            title: loginError,
          });
        } else if (loginResponse) {
          const buttonsToRemove = [
            "buttonCSS",
            "headerColor",
            "loginText",
            "logoUrl",
            "register",
            "brandName",
            "headerFont",
            "hideMcDetails",
          ];
          buttonsToRemove.forEach((element) => {
            storageHelper.remove(element);
          });
          if (loginResponse.token) {
            storageHelper.set("token", loginResponse.token);
            storageHelper.set("isInitial", true);
            storageHelper.set("email", values.email);
            history.push("/");
          }

          if (loginResponse.isOtp) {
            storageHelper.set("email", values.email);
            storageHelper.set("password", values.password);
            history.push("/otp");
          }
        }
      })
      .catch(() => {
        setBanner({
          isOpen: true,
          status: "critical",
          title: cms("message.error.unknown"),
        });
      });
  };

  const loginTitle = storageHelper.get("loginText");

  const [secondaryAction1, secondaryAction2] = cms("button.secondary");

  return (
    <>
      <Banner
        isOpen={banner.isOpen}
        status={banner.status}
        title={banner.title}
        onDismiss={() => setBanner({ isOpen: false, status: "", title: "" })}
      />
      <br />
      <Layout>
        <Layout.AnnotatedSection
          title={(loginTitle && loginTitle !== "" && loginTitle) || cms("title")}
          description={cms("description")}
        >
          <Card sectioned>
            <FormLayout>
              <TextField
                id="email"
                value={values.email || ""}
                onChange={(value) => handleChange("email", value)}
                onBlur={() => handleValidation("email", values.email)}
                label={`${cms("common.label.email")}*`}
                name="email"
                placeholder={cms("common.placeholder.emailAddress")}
                error={errorMessage && errorMessage.email}
              />
              <TextField
                id="password"
                value={values.password || ""}
                onChange={(value) => handleChange("password", value)}
                onBlur={() => handleValidation("password", values.password)}
                label={`${cms("common.label.password")}*`}
                type="password"
                name="password"
                placeholder={cms("common.placeholder.password")}
                error={errorMessage && errorMessage.password}
              />
            </FormLayout>
          </Card>
          <PageActions
            primaryAction={{
              id: "primaryActionLogin",
              content: cms("button.primary"),
              onAction: () => onSubmit(),
              disabled: !submitButton,
              loading,
            }}
            secondaryActions={[
              {
                id: "resetPassword",
                content: secondaryAction1,
                plain: true,
                onAction: () => history.push("/reset-password"),
              },
              {
                id: "resendVerifyEmail",
                content: secondaryAction2,
                plain: true,
                onAction: () => history.push("/resend-verify-email"),
              },
            ]}
          />
        </Layout.AnnotatedSection>
      </Layout>
      <Footer />
    </>
  );
};

export default Login;

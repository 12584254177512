import React from "react";
import { Card, TextField, Layout, Caption, TextStyle, FormLayout, TextContainer } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { baseHelper } from "lib/helpers";

const PaymentSection = (props) => {
  const {
    handleChange,
    paymentInformation,
    handlePaymentValidation,
    errorPaymentMessage,
    learnMore,
    lastUpdated,
  } = props;
  const { t } = useTranslation();

  const getFields = () => {
    const fields = [
      {
        label: t("label.manageVendor.bankName"),
        placeholder: t("label.manageVendor.bankName"),
        isRequired: true,
        key: "bankName",
      },
      {
        label: t("label.manageVendor.accountNumber"),
        placeholder: t("label.manageVendor.accountNumber"),
        isRequired: true,
        key: "accountNumber",
      },
      {
        label: t("label.manageVendor.sortCode"),
        placeholder: t("label.manageVendor.sortCode"),
        isRequired: true,
        key: "sortCode",
      },
      {
        label: t("label.manageVendor.country"),
        placeholder: t("label.manageVendor.country"),
        isRequired: true,
        key: "country",
      },
    ];
    return fields.map(({ label, placeholder, isRequired, key }) => {
      const required = isRequired ? "*" : "";
      return (
        <>
          <TextField
            label={`${label}${required}`}
            placeholder={placeholder}
            value={paymentInformation[key]}
            onChange={(value) => handleChange(key, value)}
            onBlur={() => (!isRequired && "") || handlePaymentValidation(key, paymentInformation[key])}
            error={errorPaymentMessage && errorPaymentMessage[key]}
          />
          <br />
        </>
      );
    });
  };
  return (
    <Layout.AnnotatedSection
      title={t("sectionContent.manageVendor.paymentSection.title")}
      description={t("sectionContent.manageVendor.paymentSection.description")}
    >
      <Card
        title={[
          t("sectionContent.manageVendor.paymentSection.title"),
          lastUpdated && (
            <TextStyle variation="subdued">
              <Caption>{`Updated : On ${baseHelper.formatDate(lastUpdated)}`}</Caption>
            </TextStyle>
          ),
        ]}
        actions={[
          {
            content: "Learn More",
            onAction: () => {
              learnMore(t("sectionContent.manageVendor.paymentSection.title"), "TODO:");
            },
          },
        ]}
      >
        <Card.Section>
          <FormLayout>
            <TextContainer>
              Fields as present in payment information section would appear in the vendor setup page
            </TextContainer>
            {getFields()}
          </FormLayout>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

PaymentSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handlePaymentValidation: PropTypes.func.isRequired,
  paymentInformation: PropTypes.shape({
    bankName: PropTypes.string,
    accountNumber: PropTypes.string,
    sortCode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  errorPaymentMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  learnMore: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string,
};

PaymentSection.defaultProps = {
  errorPaymentMessage: PropTypes.bool,
  lastUpdated: "",
};

export default PaymentSection;

import React, { useState, useEffect, useContext, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { Editor } from "@tinymce/tinymce-react";
import PropTypes from "prop-types";

import { Card, FormLayout, Layout, TextField, PageActions, Checkbox, TextContainer } from "@shopify/polaris";
import { PrivateContext, OnboardingContext } from "lib/context";
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";

import { GET_EMAIL_TEMPLATE } from "app/vendors/apollo/queries";
import { BULK_INVITATION_BY_DATA } from "app/vendors/apollo/mutations";
import validate from "../form/yup";

const { gql } = constant;

const Invite = (props) => {
  const { setBanner, learnMore } = props;
  const { history, cms = {} } = useContext(PrivateContext) || {};
  const { isOnboarding = false, onNext, onPrevious } = useContext(OnboardingContext);

  const [emails, setEmails] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [readOnly, setCheckedValue] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const handleChange = (value) => setCheckedValue(value);
  const [submitButton, setSubmitButton] = useState({ isDisabled: true });
  const [bulkInvitation] = useMutation(BULK_INVITATION_BY_DATA);

  const { data, loading } = useQuery(GET_EMAIL_TEMPLATE);

  useEffect(() => {
    if (!data) {
      return;
    }
    const resError = baseHelper.getResponseError(data, gql.GET_BULK_EMAIL_TEMPLATE);
    if (resError) {
      setBanner({ isOpen: true, title: resError, status: "critical" });
      return;
    }
    const resData = baseHelper.getResponseData(data, gql.GET_BULK_EMAIL_TEMPLATE);
    setMessage(resData.message);
    setSubject(resData.subject);
    setIsLoading(false);
  }, [data, setBanner]);

  const updateErrorState = (field, error) => {
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: error,
    }));
  };

  const updateEmailState = (stingOfEmails) => {
    const emailArray = stingOfEmails.split(",") || [];
    emailArray.pop(); /* to remove extra value (`""`) from the array 
    which is added due to split. */
    if (emailArray.length < 5) setEmails(stingOfEmails);
    else {
      updateErrorState(constant.email);
    }
  };

  const isEmailBlank = ({ field, value, onBlurEmail = false }) => {
    if (field !== "email") {
      return false;
    }

    if (!value.length || (value.length === 1 && value[0] === "")) {
      updateErrorState(field, (onBlurEmail && "Email is required") || "");
      return true;
    }

    return false;
  };

  const handleValidation = useCallback(async ({ field, valueUnderValidationChecks, onBlurEmail = false }) => {
    let value = valueUnderValidationChecks;
    if (!Array.isArray(value)) {
      value = [value];
    }

    const blankEmail = isEmailBlank({ field, value, onBlurEmail });

    if (blankEmail) {
      return;
    }

    let error = "";
    let hasError = false;
    await value.forEach(async (val, index) => {
      if (val === "" && field === "email") {
        hasError = true;
        updateErrorState(field, "please enter an email after the comma or remove comma");
        return;
      }
      error = await validate(field, val, cms);
      if (error !== "") {
        hasError = true;
        updateErrorState(field, error);
        return;
      }
      if (!hasError && index === value.length - 1) {
        updateErrorState(field, "");
      }
    });
  }, []);

  useEffect(() => {
    handleValidation({ field: constant.MAIL_SUBJECT, valueUnderValidationChecks: subject });
  }, [handleValidation, subject]);

  const handleEditorChange = (content) => {
    setMessage(content);
  };

  useEffect(() => {
    const isAnyError = () => Object.values(errorMessage).some((error) => error);

    const isAllValuesFilled = () => {
      if (!(message && emails && subject)) return false;
      return true;
    };
    setSubmitButton((prevState) => ({
      ...prevState,
      isDisabled: isAnyError() || !isAllValuesFilled(),
    }));
  }, [subject, message, emails, errorMessage]);

  const onSubmit = async () => {
    setSubmitButtonLoading(true);
    setSubmitButton({ isDisabled: true });
    const emailArr = emails.split(",");
    const emailsArrOfObj = emailArr.map((emailValue) => ({ email: emailValue.trim() }));
    const mutationInput = {
      emails: emailsArrOfObj,
      isReadOnly: readOnly,
      message: { message, subject },
    };

    try {
      const res = await bulkInvitation({
        variables: {
          input: mutationInput,
        },
        errorPolicy: "all",
      });
      const responseData = baseHelper.getResponseData(res.data, gql.BULK_INVITATION_DATA);
      let bannerValue = {
        action: {
          id: "viewInvitations",
          content: cms("section.invite.message.success.action"),
          onAction: () => history.push("/invitation"),
        },
        status: "success",
        title: cms("section.invite.message.success.invite.title"),
        children: cms("section.invite.message.success.invite.content"),
      };
      if (emailsArrOfObj.length > 1) {
        bannerValue.title = cms("section.invite.message.success.bulkInvite.title");
        bannerValue.children = cms("section.invite.message.success.bulkInvite.content");
      }
      setSubmitButtonLoading(false);
      if (!responseData) {
        const errorResponse = baseHelper.getResponseError(res.data, gql.BULK_INVITATION_DATA);
        bannerValue = { status: "critical", title: errorResponse };
      }
      props.setBanner({
        action: bannerValue.action,
        children: bannerValue.children,
        isOpen: true,
        status: bannerValue.status,
        title: bannerValue.title,
      });
      if (isOnboarding && responseData) {
        onNext();
      }
    } catch (error) {
      setSubmitButtonLoading(false);
      props.setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  const handleEmailValidation = (value = "") => {
    const emailList = value && value.split(",").map((email) => email.trim());
    if (!emailList.length) {
      updateErrorState("email", "Email is required");
      return;
    }
    handleValidation({ field: constant.EMAIL, valueUnderValidationChecks: emailList });
  };

  return (
    <>
      {loading || isLoading ? (
        <SkeletonAnnotated />
      ) : (
        <div>
          <Layout.AnnotatedSection title={cms("section.invite.title")} description={cms("section.invite.description")}>
            <Card
              title={cms("section.invite.title")}
              actions={[
                {
                  content: cms("common.label.learnMore"),
                  onAction: () => {
                    learnMore(cms("section.invite.title"), "TODO:");
                  },
                },
              ]}
            >
              <Card.Section>
                <TextContainer>
                  To invite new and perspective vendors to join your marketplace fill in the required form fields and
                  hit ‘Submit.
                </TextContainer>
                <br />
                <FormLayout>
                  <TextField
                    label={`${cms("common.label.email")}*`}
                    placeholder={cms("section.invite.placeholder.emails")}
                    key="email"
                    id="email"
                    value={emails}
                    onChange={(value) => updateEmailState(value)}
                    onBlur={() => handleEmailValidation(emails)}
                    error={errorMessage && errorMessage.email}
                  />
                  <TextField
                    label={`${cms("section.invite.label.subject")}*`}
                    placeholder={cms("section.invite.placeholder.subject")}
                    key="mailSubject"
                    id="mailSubject"
                    value={subject}
                    onChange={(value) => setSubject(value)}
                    error={errorMessage && errorMessage.mailSubject}
                  />
                  <p>
                    <strong>Message*</strong>
                  </p>
                  <Editor
                    id="idTiny"
                    textareaName="Message"
                    value={message}
                    onEditorChange={handleEditorChange}
                    init={{
                      menubar: true,
                      plugins: [""],
                      toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
                    }}
                  />
                </FormLayout>
              </Card.Section>
            </Card>
            <Card title="Vendor's access permission">
              <Card.Section>
                <TextContainer>
                  <p>
                    {`By default a vendor has full write access in their account however you can set a 
                    vendor's access to be 'read only'. With 'read only' access a vendor can log into 
                    their account but they are not able to perform any create, update or delete actions
                     ie. add or manage products and orders or change any
                    settings.`}
                  </p>
                  <p>
                    This permission can be revoked or added at any time by you after a vendor has been created. This
                    feature is particulariy useful when you are looking to manage on behalf of your vendors.
                  </p>
                </TextContainer>
                <br />
                <FormLayout>
                  <Checkbox
                    label={cms("common.label.isReadOnlyVendor")}
                    checked={readOnly}
                    onChange={handleChange}
                    id="readOnlyCheckbox"
                  />
                </FormLayout>
              </Card.Section>
            </Card>
            <PageActions
              primaryAction={{
                content: cms("common.button.submit"),
                id: "submitButton",
                onAction: () => onSubmit(),
                disabled: submitButton.isDisabled,
                loading: submitButtonLoading,
              }}
              secondaryActions={[
                {
                  content: cms("common.button.cancel"),
                  id: "cancelButton",
                  onAction: () => (isOnboarding ? onPrevious() : history.push("/")),
                },
              ]}
            />
          </Layout.AnnotatedSection>
        </div>
      )}
    </>
  );
};

Invite.propTypes = {
  setBanner: PropTypes.func.isRequired,
  learnMore: PropTypes.func.isRequired,
};
export default Invite;

import React from "react";
import { Stack, Button } from "@shopify/polaris";
import PropTypes from "prop-types";

const CardFooter = (props) => {
  const { disabled, loading } = props;
  return (
    <>
      <br />
      <Stack>
        <Stack.Item fill />
        <Stack.Item>
          <Button primary submit disabled={disabled} loading={loading || false}>
            Update
          </Button>
        </Stack.Item>
      </Stack>
    </>
  );
};

CardFooter.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
CardFooter.defaultProps = {
  loading: false,
  disabled: false,
};

export default CardFooter;

// import packages
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Card, Layout, TextField, FormLayout, PageActions } from "@shopify/polaris";

// helpers
import { baseHelper } from "lib/helpers";

// constant
import constant from "lib/constant/constant";

// helper components
import { Banner } from "lib/components";

// contexts
import { PublicContext } from "lib/context";

// import gql
import { GET_BRANDS } from "../../../../apollo/queries";
import { RESET_PASSWORD } from "../../../../apollo/mutations";

// yup validation
import validate from "./yup";

// sub-features
import { AccountType, Brand } from "./subFeatureItems";

const ResetPassword = () => {
  const { cms, history, oldCMS } = useContext(PublicContext);
  const { message = {}, label = {} } = oldCMS || {};
  const { EMAIL, ACCOUNT_TYPE, BRAND_NAME, SHOW_BRAND_NAME, SHOW_ACCOUNT_TYPE, VENDOR, gql } = constant;
  const [isEmailSent, setIsEmailSent] = useState(false);
  // states
  const [values, setValues] = useState({
    email: "",
    isEmailDisable: false,
    isAccountTypeDisable: false,
    accountType: "",
    brandName: "",
  });
  const [showFields, setShowFields] = useState({
    showAccountType: false,
    showBrandName: false,
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [userBrands, setUserBrands] = useState({
    seller: [],
    vendor: [],
  });
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitButton, setSubmitState] = useState(false);
  const [fetchNewAccount, setFetchNewAccount] = useState(true);

  // gql states
  const [getAccount, { data: accountData, loading: getBrandLoading }] = useLazyQuery(GET_BRANDS);
  const [resetPassword, { loading, data }] = useMutation(RESET_PASSWORD);

  const updateSubmitButton = useCallback(
    (field) => {
      const isAnyValidationError = errorMessage && !!errorMessage[field];
      const isAllValuesFilled = values[field];
      setSubmitState(!!isAllValuesFilled && !isAnyValidationError);
    },
    [errorMessage, values]
  );

  useEffect(() => {
    if (errorMessage) {
      if (!(showFields.showAccountType || showFields.showBrandName)) {
        updateSubmitButton(EMAIL);
      }
      if (showFields.showAccountType) {
        updateSubmitButton(ACCOUNT_TYPE);
      }
      if (showFields.showBrandName) {
        updateSubmitButton(BRAND_NAME);
      }
    }
  }, [showFields, errorMessage, updateSubmitButton, EMAIL, ACCOUNT_TYPE, BRAND_NAME]);

  const setFieldStatus = useCallback((field, value) => {
    setShowFields((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  useEffect(() => {
    setFieldStatus(SHOW_BRAND_NAME, false);
    if (values.accountType === constant.SELLER && showFields.showAccountType) {
      setFieldStatus(SHOW_BRAND_NAME, true);
    }
  }, [
    SHOW_BRAND_NAME,
    showFields.showBrandName,
    values.accountType,
    userBrands,
    setFieldStatus,
    showFields.showAccountType,
  ]);

  const handleChange = useCallback(async (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);
  const handleValidation = async (field, value) => {
    const validationError = await validate(field, value, cms);
    setErrorMessage((prevState) => ({
      ...prevState,
      [field]: validationError,
    }));
  };

  const sendEmail = useCallback(
    async (accountType = "", brandName = "") => {
      const inputData = {};
      inputData.email = values.email;
      inputData.userType = accountType || values.accountType;
      inputData.brandName = brandName || values.brandName;
      try {
        const res = await resetPassword({
          variables: { input: inputData },
        });
        const resData = baseHelper.getResponseData(res.data, gql.FORGET_PASSWORD);
        let bannerData = { title: message.mailSentSuccess, status: "success" };
        let isMailSend = true;
        if (!resData) {
          const resError = baseHelper.getResponseError(res.data, gql.FORGET_PASSWORD);
          bannerData = { title: resError, status: "critical" };
          isMailSend = false;
        }
        setBanner({
          isOpen: true,
          isScrollTop: true,
          status: bannerData.status,
          title: bannerData.title,
        });
        if (isMailSend) {
          setTimeout(() => history.push("/login"), 1000);
        }
      } catch (err) {
        setBanner({
          isOpen: true,
          isScrollTop: true,
          status: "critical",
          title: message.somethingWentWrong,
        });
      }
    },
    [
      gql.FORGET_PASSWORD,
      message.mailSentSuccess,
      message.somethingWentWrong,
      resetPassword,
      values.email,
      values.accountType,
      values.brandName,
      history,
    ]
  );

  const listBrand = useCallback((response) => {
    setUserBrands((prevState) => ({
      ...prevState,
      seller: response.getBrands.data.shops,
      vendor: response.getBrands.data.brandName,
    }));
  }, []);

  const showSellerStore = useCallback(
    async (response) => {
      listBrand(response);
      setFieldStatus(SHOW_BRAND_NAME, true);
    },
    [SHOW_BRAND_NAME, listBrand, setFieldStatus]
  );
  const showAccountAndBrand = useCallback(
    async (response) => {
      listBrand(response);
      setFieldStatus(SHOW_ACCOUNT_TYPE, true);
    },
    [SHOW_ACCOUNT_TYPE, listBrand, setFieldStatus]
  );
  const userStoreList = useCallback(
    async (response) => {
      const resData = baseHelper.getResponseData(response, gql.GET_BRANDS);
      const resError = baseHelper.getResponseError(response, gql.GET_BRANDS);
      const isSellerRole = resData && !!resData.shops.length;
      const isVendorRole = resData && !!Object.keys(resData.brandName).length;
      const isBothRole = resData && !!resData.shops.length && !!Object.keys(resData.brandName).length;
      if (resData) {
        setBanner({ isOpen: false });
      }
      if (isSellerRole && isVendorRole) {
        showAccountAndBrand(response);
      }
      if (isSellerRole && !isVendorRole) {
        showSellerStore(response);
        const sellerBrandName = resData && resData.shops && resData.shops[0].value;
        setValues((prevState) => ({
          ...prevState,
          accountType: constant.SELLER,
          brandName: sellerBrandName,
        }));
        setFieldStatus(SHOW_ACCOUNT_TYPE, false);
        if (resData.shops.length > 1) {
          setFieldStatus(SHOW_BRAND_NAME, true);
        }
        if (resData.shops.length === 1) {
          setIsEmailSent(true);
          sendEmail(constant.SELLER, sellerBrandName);
        }
      }
      if (!isSellerRole && isVendorRole) {
        showSellerStore(response);
        const vendorBrandName = resData && resData.brandName && resData.brandName.value;
        setValues((prevState) => ({
          ...prevState,
          accountType: constant.VENDOR,
          brandName: vendorBrandName,
        }));
        setFieldStatus(SHOW_ACCOUNT_TYPE, false);
        setFieldStatus(SHOW_BRAND_NAME, false);
        setIsEmailSent(true);
        sendEmail(constant.VENDOR, vendorBrandName);
      }
      if ((!isSellerRole && !isVendorRole && !isBothRole) || resError) {
        setBanner((prevState) => ({
          ...prevState,
          isOpen: true,
          status: "critical",
          title: resError || message.invalidUser,
        }));
        return;
      }
      setValues((prevState) => ({
        ...prevState,
        isEmailDisable: true,
      }));
    },
    [
      gql.GET_BRANDS,
      message.invalidUser,
      sendEmail,
      showAccountAndBrand,
      showSellerStore,
      SHOW_ACCOUNT_TYPE,
      SHOW_BRAND_NAME,
      setFieldStatus,
    ]
  );
  useEffect(() => {
    if (!loading && !getBrandLoading && isLoading) {
      if (data) {
        const resData = baseHelper.getResponseData(data, gql.FORGET_PASSWORD);
        let bannerData = { title: message.mailSent, status: "success" };
        if (!resData) {
          const resError = baseHelper.getResponseError(data, gql.FORGET_PASSWORD);
          bannerData = { title: resError, status: "critical" };
        }
        setBanner((prevState) => ({
          ...prevState,
          isOpen: true,
          status: bannerData.status,
          title: bannerData.title,
        }));
      }
      setIsLoading(false);
    }
  }, [data, accountData, loading, getBrandLoading, isLoading, gql.FORGET_PASSWORD, message.mailSent, userStoreList]);

  useEffect(() => {
    if (accountData && !isEmailSent && fetchNewAccount) {
      userStoreList(accountData);
      setFetchNewAccount(false);
    }
  }, [accountData, isEmailSent, userStoreList, fetchNewAccount]);

  const fetchAccountTypes = async () => {
    setIsLoading(true);
    const isAnyValidationError = errorMessage && !!errorMessage.email;
    const isAllValuesFilled = values.email;
    if (!isAnyValidationError && isAllValuesFilled) {
      try {
        getAccount({ variables: { input: { email: values.email } } });
      } catch (err) {
        setBanner((prevState) => ({
          ...prevState,
          isOpen: true,
          status: "critical",
          title: message.somethingWentWrong,
        }));
      }
    }
  };

  const fetchAccounts = () => {
    setIsLoading(true);
    const isVendorTypeSelected = values.accountType === VENDOR;
    setValues((prevState) => ({
      ...prevState,
      isAccountTypeDisable: true,
    }));
    if (isVendorTypeSelected) {
      setIsEmailSent(true);
      sendEmail("", userBrands.vendor.value);
      return;
    }
    setFieldStatus(SHOW_BRAND_NAME, true);
  };

  const renderBrandName = () => {
    if (!showFields.showBrandName) {
      return null;
    }
    return (
      <Brand
        handleChange={handleChange}
        handleValidation={handleValidation}
        errorMessage={errorMessage}
        brandName={values.brandName}
        brands={userBrands.seller}
      />
    );
  };

  const renderAccountType = () => {
    if (!showFields.showAccountType) {
      return null;
    }
    return (
      <AccountType
        handleChange={handleChange}
        handleValidation={handleValidation}
        errorMessage={errorMessage}
        accountType={values.accountType}
        isAccountTypeDisable={values.isAccountTypeDisable}
      />
    );
  };

  const onSubmit = async () => {
    const isEmailSubmit = !(showFields.showAccountType || showFields.showBrandName);
    const isAccountTypeSubmit = showFields.showAccountType;
    const isBrandNameSubmit = showFields.showBrandName;
    if (isEmailSubmit) {
      await handleValidation(EMAIL, values.email);
      fetchAccountTypes();
      setFetchNewAccount(true);
    }
    if (isAccountTypeSubmit) {
      await handleValidation(ACCOUNT_TYPE, values.accountType);
      fetchAccounts();
    }
    if (isBrandNameSubmit) {
      await handleValidation(BRAND_NAME, values.brandName);
      setIsEmailSent(true);
      sendEmail();
    }
  };

  const dismissBanner = () => setBanner({ isOpen: false, status: "", title: "" });

  return (
    <>
      <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={() => dismissBanner()} />
      <br />
      <Layout>
        <Layout.AnnotatedSection title={cms("title")} description={cms("description")}>
          <Card sectioned>
            <FormLayout>
              <TextField
                id="email"
                label={label.email}
                value={values.email || ""}
                placeholder={cms("common.placeholder.emailAddress")}
                onChange={(value) => handleChange(EMAIL, value)}
                onBlur={() => handleValidation(EMAIL, values.email)}
                disabled={values.isEmailDisable}
                error={errorMessage && errorMessage.email}
              />
              {renderAccountType()}
              {renderBrandName()}
            </FormLayout>
          </Card>
          <PageActions
            primaryAction={{
              id: "resetPassword",
              content: cms("button.primary"),
              onAction: () => onSubmit(),
              disabled: !submitButton,
              loading: loading || getBrandLoading,
            }}
            secondaryActions={{
              id: "cancel",
              content: cms("common.button.cancel"),
              onAction: () => history.push("/login"),
            }}
          />
        </Layout.AnnotatedSection>
      </Layout>
    </>
  );
};

export default ResetPassword;

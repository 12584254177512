import { reasonSchema, noteSchema } from "app/orders/modules/operator/features/lineReturn/yup/schema";

import { yupHelper } from "lib/helpers";

// constant
import constant from "lib/constant/constant";

const { validateValue, handlePromiseError } = yupHelper;

const { REASON, NOTE } = constant;

const validate = async (field, value) => {
  let promise = "";
  switch (field) {
    case REASON:
      promise = validateValue(reasonSchema, { reason: value });
      break;
    case NOTE:
      promise = validateValue(noteSchema, { note: value });
      break;
    default:
      return "";
  }
  return handlePromiseError(promise);
};

export default validate;

// import packages
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Layout, PageActions } from "@shopify/polaris";
import { useMutation, useQuery } from "@apollo/react-hooks";

// import hoc
import { withFeature } from "lib/hoc";

// import helper
import { baseHelper, errorHelper } from "lib/helpers";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext, OnboardingContext } from "lib/context";

// import components
import { Sheet, SkeletonAnnotated } from "lib/components";
import { Attachment, Image, Inventory, Price, Shipping, TitleDescription, Variant, Organization } from "./subFeatureItems";

// import provider
import { FormProvider } from "../../context/context";

import { ADD_PRODUCT, ATTACH_UPLOAD, UPLOAD_IMAGE, UPLOAD_FILE } from "../../../../../../apollo/mutations";
import { GET_PRODUCT_SETTING } from "../../../../../../apollo/queries";

const {
  gql: { ADD_PRODUCT: ADD_PRODUCT_GQL },
  value: { MAX_MEASUREMENT = 999 },
  SIZE,
  COLOR,
  MATERIAL,
  MANUAL,
  PRODUCT_USING_FORM,
  SHOPIFY,
  OPTIONS,
} = constant;

const ManualForm = props => {
  const { setBanner } = props;
  const { gql } = constant;
  const { history, currentUser, cms } = useContext(PrivateContext);
  const { isAwsCredsAvailable = false } = currentUser;
  const { isOnboarding, onNext, onPrevious } = useContext(OnboardingContext);

  const [value, setValue] = useState({
    shipping: true,
    policySelection: SHOPIFY,
  });
  const [sheetActive, setSheetActive] = useState(false);
  const [sheetTitle, setSheetTitle] = useState("");
  const [sheetContent, setSheetContent] = useState("");
  const [imageStartUploading, setImageStartUploading] = useState(false);
  const [fileStartUploading, setFileStartUploading] = useState(false);
  const [mcProductId, setMcProductId] = useState(" ");
  const [isHideTagAndType, setIsHideTagAndType] = useState(true);

  const [uploads, setUploads] = useState({ toBeUploadedFile: null, toBeUploadedImage: null });
  const shopify = SHOPIFY;
  const [uploadImage, { loading: uploadImageLoading }] = useMutation(UPLOAD_IMAGE);
  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);
  const [addProductItem, { loading: addProductLoading }] = useMutation(ADD_PRODUCT);
  const [attachUpload, { loading: attachLoading }] = useMutation(ATTACH_UPLOAD);
  const { data: productSettingData, error: productSettingError, loading: productSettingLoading } = useQuery(GET_PRODUCT_SETTING);

  const primaryAction = {
    content: cms("common.label.done"),
    onAction: () => setSheetActive(false),
  };

  const secondaryAction = {
    content: cms("common.button.cancel"),
    onAction: () => setSheetActive(false),
  };

  useEffect(() => {
    if(productSettingError) {
      setBanner({ isOpen: true, status: "critical", title: errorHelper.parse(productSettingError) });
    }
  }, [productSettingError, setBanner, errorHelper]);

  useEffect(() => {
    if(productSettingData) {
      const productError = baseHelper.getResponseError(productSettingData, constant.gql.GET_PRODUCT_SETTING);
      if(productError) {
        setBanner({ isOpen: true, status: "critical", title: productError });
        return;
      }
      const productSetting = baseHelper.getResponseData(productSettingData, constant.gql.GET_PRODUCT_SETTING) || {};
      const { isHideTagAndType } = productSetting || {};
      setIsHideTagAndType(!!isHideTagAndType);
    }
  }, [productSettingData, setIsHideTagAndType])

  useEffect(() => {
    if(uploads.toBeUploadedFile !== null && uploads.toBeUploadedImage !== null){
     attachUpload({ variables: { input: {
         _id: mcProductId,
        images: uploads.toBeUploadedImage || [],
        attachments: uploads.toBeUploadedFile || [],
     } } })
        .then(res => {
          const responseError = baseHelper.getResponseError(res.data, gql.ATTACH_UPLOAD);
          const responseData = baseHelper.getResponseData(res.data, gql.ATTACH_UPLOAD);
          if (responseError) {
            setBanner({ isOpen: true, status: "critical", title: responseError });
          }
          if (responseData) {
            if (isOnboarding) {
              onNext();
              return;
            }
            history.push(`edit/${encodeURIComponent(mcProductId)}`, { add: true });
          }
        })
        .catch(() => {
          setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
        });
      }
  }, [ uploads ]);

  if (productSettingLoading) {
    return <SkeletonAnnotated/>
  }

  const handleChange = (fieldName, fieldValue) => {
    setValue({
      ...value,
      [fieldName]: fieldValue,
    });
  };

  const handleTiny = content => {
    handleChange(constant.TINYMCE, content);
  };

  const learnMore = (productItem, title) => {
    setSheetActive(true);
    setSheetTitle(title);
    setSheetContent(`TODO: Add description about ${productItem}`);
  };

  const addProduct = async formValues => {
    try {
      const val = await addProductItem({
        variables: {
          input: formValues,
        },
      });

      const resData = baseHelper.getResponseData(val.data, ADD_PRODUCT_GQL);
      const resError = baseHelper.getResponseError(val.data, ADD_PRODUCT_GQL);
      if (resError) {
        const banner = {
          action: null,
          isOpen: true,
          status: "critical",
          title: resError,
        };
        setBanner(banner);
        return;
      }
      if (resData) {
        return resData;
      }
    } catch (addProductError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  const imageUpload = (images =[], productId = '') => {
    let hasImageError = false;
    const uploadedImages = [];
    if(images.length===0){
      setUploads(prevState => {
      return {...prevState,toBeUploadedImage:[]};
      });
      }
    else if (images && images.length) {
      const uploadImageCallback = (imageResponse) => {
        const { imageUrl = null, imageId = null } = imageResponse;
        if (imageUrl) {
          const uploadedImageData = {
            imageUrl,
            imageId,
          };

          uploadedImages.push(uploadedImageData);
        }
        if (hasImageError) {
          return;
        }
        if (uploadedImages && uploadedImages.length > 0 && uploadedImages.length === images.length) {
          setImageStartUploading(false);
          setUploads(prevState => {
            return {...prevState,toBeUploadedImage:uploadedImages};
          });
        }
      };

      images.map(async (img) => {
        try {
          if (hasImageError) {
            return;
          }
          const response = await uploadImage({ variables: { input: { image: img, productId } } });
          if (!imageStartUploading) {
            setImageStartUploading(true);
          }
          const imageResponse = baseHelper.getResponseData(response.data, "uploadImage");
          const imageError = baseHelper.getResponseError(response.data, "uploadImage");
          if (imageError) {
            setImageStartUploading(false);
            hasImageError = true;
            setBanner((prev) => ({
              ...prev,
              action: null,
              isOpen: true,
              status: "critical",
              title: imageError,
            }));
          }
          uploadImageCallback(imageResponse);
        } catch (uploadImageError) {
          setImageStartUploading(false);
          hasImageError = true;
          setBanner((prev) => ({
            ...prev,
            action: null,
            isOpen: true,
            status: "critical",
            title: cms("section.form.message.error.imageNotSaved"),
          }));
        }
      });
      setImageStartUploading(false);
    }
    return null;
  };

  const submitCallBack = async () => {
    const {
      file: fromAttachment,
      title,
      tinyMCE,
      image,
      price,
      comparePrice,
      sku,
      barcode,
      policySelection,
      inventoryQuantity,
      shipping,
      weight,
      weightUnit,
      organisationTags,
      productValueType,
      variant,
      length = 0,
      width = 0,
      height = 0,
    } = value;
    const option1 = (variant && variant.length && variant[0].option1) || SIZE;
    const option2 = (variant && variant.length && variant[0].option2) || COLOR;
    const option3 = (variant && variant.length && variant[0].option3) || MATERIAL;

    const { _id: id, brandName = "" } = currentUser;
    const vendor = brandName;
    const vendorId = id;

    const measurement = {
      length: {
        value: parseFloat(length),
        unit: constant.CM,
      },
      width: {
        value: parseFloat(width),
        unit: constant.CM,
      },
      height: {
        value: parseFloat(height),
        unit: constant.CM,
      },
    };

    const formValues = {
      attachments: fromAttachment || [],
      title,
      description: tinyMCE,
      vendor,
      vendorId,
      tags: organisationTags,
      productType: productValueType,
      barcode,
      inventoryManagement: policySelection === shopify ? shopify : null,
      sku,
      isShipping: !!shipping,
      weight,
      weightUnit: weightUnit || OPTIONS[0],
      fulfillmentService: MANUAL,
      option1,
      option2,
      option3,
      images: image || [],
      variants: variant,
      measurement,
    };
    formValues.quantity = parseInt(inventoryQuantity, 10);
    formValues.comparePrice = parseFloat(comparePrice);
    formValues.price = parseFloat(price);
    formValues.weight = parseFloat(weight);
    // images
    const tempImages = formValues.images && [...formValues.images];
    formValues.images = [];
    // attachment
    const tempAttachments = formValues.attachments ? [...formValues.attachments] : [];
    formValues.attachments = [];
    try {
      //add product
      const productId = await addProduct(formValues);
      setMcProductId(productId);
      if (productId && ((tempImages && tempImages.length) || (tempAttachments && tempAttachments.length))) {
        
        //save attachments
          fileUpload(tempAttachments, productId);
 
        //save images
         imageUpload(tempImages, productId);
 
      } else if (productId) {
        if (isOnboarding) {
          onNext();
          return;
        }
        history.push(`edit/${encodeURIComponent(productId)}`, { add: true });
      }
 
    } catch (uploadProductError) {
      //delete the product from the DB if it doest save
      setBanner({
        isOpen: true,
        status: "critical",
        title: cms("common.message.error.somethingWentWrong"),
      });
    }
  };

  const fileUpload = (tempAttachments = [], productId ='') => {
    let hasFileError = false;
    let uploadedFiles = [];
    if(tempAttachments.length===0){
      setUploads(prevState => {
      return {...prevState,toBeUploadedFile:[]};
      });
      }
    else if (tempAttachments && tempAttachments.length) {
      if (!fileStartUploading) {
        setFileStartUploading(true);
      }

      const uploadFileCallback = imageResponse => {
        const { fileURL = null, fileId = null } = imageResponse;
        if (fileURL) {
          const uploadedFileData = {
            fileURL,
            fileId,
          };
          uploadedFiles.push(uploadedFileData);
        }
        if (hasFileError) {
          return;
        }
        if (uploadedFiles && uploadedFiles.length > 0 && uploadedFiles.length === tempAttachments.length) {
          setFileStartUploading(false);
          setUploads(prevState => {
            return {...prevState,toBeUploadedFile:uploadedFiles};
            });
        }
      };

      tempAttachments.map(async (file, index) => {
        try {
          if (hasFileError) {
            return;
          }
          const response = await uploadFile({ variables: { input: { file: file, productId } } });
          if (!fileStartUploading) {
            setFileStartUploading(true);
          }
          const fileResponse = baseHelper.getResponseData(response.data, "uploadFile");
          const fileError = baseHelper.getResponseError(response.data, "uploadFile");
          if (fileError) {
            setFileStartUploading(false);
            hasFileError = true;
            setBanner(prev => ({
              ...prev,
              action: null,
              isOpen: true,
              status: "critical",
              title: fileError,
            }));
          }
          uploadFileCallback(fileResponse);
        } catch (uploadFileError) {
          setFileStartUploading(false);
          hasFileError = true;
          setBanner(prev => ({
            ...prev,
            action: null,
            isOpen: true,
            status: "critical",
            title: "File could not be saved.",
          }));
        }
      });
      setFileStartUploading(false);
    }
    return null;
  };

  const onFormSubmit = () => {
    let banner = {
      action: null,
      isOpen: false,
      status: "",
      title: "",
    };
    if (!value.title || value.title.trim() === "") {
      banner = {
        action: null,
        isOpen: true,
        status: "critical",
        title: cms("section.form.message.error.titleIsRequired"),
      };
      setBanner(banner);
      return;
    }

    let { length = 0, width = 0, height = 0 } = value;
    length = parseFloat(length);
    width = parseFloat(width);
    height = parseFloat(height);

    if (length > MAX_MEASUREMENT || width > MAX_MEASUREMENT || height > MAX_MEASUREMENT) {
      banner = {
        action: null,
        isOpen: true,
        status: "critical",
        title: "Length, Width, and Height should be less than 1000",
      };
      setBanner(banner);
      return;
    }

    // check volumetric weight is greater than 180 or not if yes then through error
    const isLargeVolumetricWeight = baseHelper.isLargeVolumetricWeight(length, width, height);
    if (isLargeVolumetricWeight) {
      banner = {
        action: null,
        isOpen: true,
        status: constant.CRITICAL,
        title: `Please check the dimensions Length, Width, and Height.
           The volumetric weight should be less than or equal to 180`,
      };
      setBanner(banner);
      return;
    }
    submitCallBack();
  };

  return (
    <>
      <FormProvider value={{ handleChange, handleTiny, data: value, learnMore, setBanner }}>
        <TitleDescription />
        <Image />
        {isAwsCredsAvailable && <Attachment />}
        <Price />
        <Inventory />
        <Shipping />
        <Variant />
        {
          !isHideTagAndType ? 
            <Organization />
            : null
        }
      </FormProvider>

      <PageActions
        primaryAction={{
          id: "submitButton",
          content: cms("common.button.submit"),
          onAction: () => onFormSubmit(),
          loading: attachLoading || imageStartUploading || uploadImageLoading || uploadFileLoading || addProductLoading || false,
        }}
        secondaryActions={[
          {
            id: "cancelButton",
            onAction: () => (isOnboarding && onPrevious()) || history.push("/"),
            content: cms("common.button.cancel"),
          },
        ]}
      />

      <Sheet
        title={sheetTitle}
        isOpen={sheetActive}
        onClose={() => setSheetActive(false)}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
      >
        {sheetContent}
      </Sheet>
    </>
  );
};

ManualForm.propTypes = {
  setBanner: PropTypes.func.isRequired,
};

export default withFeature(ManualForm, { feature: PRODUCT_USING_FORM });

import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Badge,
  Banner,
  Caption,
  Card,
  Layout,
  List,
  Stack,
  DisplayText,
  TextStyle,
  Heading,
} from "@shopify/polaris";

import cms from "app/plans/modules/operator/features/cms/cms";

const propTypes = {
  onSelect: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPlan: PropTypes.string,
};

const defaultProps = {
  selectedPlan: "",
};

const free = "free";

const Plan = (props) => {
  const { cms: cmsContent, isFreePlanMsg } = props;
  const getFreePlan = () => {
    const { plans = [] } = props;
    const freePlan = plans.find((plan) => plan.code === free);
    return freePlan;
  };

  const selectFreePlan = () => {
    props.onSelect(getFreePlan());
  };

  const renderFreePlan = (selectedPlan = "") => {
    const plan = getFreePlan();
    if (!plan || plan.isHidden) {
      return null;
    }
    return (
      <Layout.Section>
        <center>
          <Heading>{cmsContent.message.notSureTryFreeVersion}</Heading>
          <Caption>{cmsContent.message.getMarketcubeFree}</Caption>
          <br />
          <Button
            disabled={selectedPlan === plan.code}
            onClick={() => selectFreePlan()}
            loading={selectedPlan === plan.code}
            size="slim"
            outline
            monochrome
          >
            {cmsContent.label.startWithOurFreeVersion}
          </Button>
        </center>
      </Layout.Section>
    );
  };

  const renderTitle = (plan) => {
    const capitalizeName = plan.name.toUpperCase();
    return (
      <Stack>
        <Stack.Item fill>
          {`${capitalizeName}`}
          &nbsp;
          {plan.code === "growth" ? <Badge status="success">{cmsContent.label.popular}</Badge> : ""}
          {plan.currentPlan && <Badge status="info">{cmsContent.label.active}</Badge>}
        </Stack.Item>
      </Stack>
    );
  };

  const renderPlanButton = (plan, selectedPlan = "") => {
    return (
      <Button
        disabled={selectedPlan === plan.code}
        onClick={() => props.onSelect(plan)}
        primary
        fullWidth
        loading={selectedPlan === plan.code}
      >
        {cmsContent.label.startForFree}
      </Button>
    );
  };

  const renderListItem = (item) => (
    <List.Item key={item}>
      <TextStyle>{item}</TextStyle>
    </List.Item>
  );

  const getValuesFromObject = (obj = {}) => Object.values(obj);

  const renderCommonFeatures = () => getValuesFromObject(cms.label.commonFeatures).map(renderListItem);

  const renderGrowthPlanFeatures = () => getValuesFromObject(cms.label.growth).map(renderListItem);

  const renderProPlanFeatures = () => getValuesFromObject(cms.label.pro).map(renderListItem);

  const renderBodySection = (plan, selectedPlan) => (
    <Card.Section title={`${cmsContent.label.features}:`}>
      <List>
        {plan.maxProducts && (
          <List.Item>
            <TextStyle>{cmsContent.label.productPlural}</TextStyle>
            {`: ${plan.maxProducts}`}
          </List.Item>
        )}
        {plan.maxVendors && (
          <List.Item>
            <TextStyle>{cmsContent.label.vendorListData}</TextStyle>
            {`: ${plan.maxVendors}`}
          </List.Item>
        )}
        {renderCommonFeatures()}
        {plan.code === "growth" && renderGrowthPlanFeatures()}
        {plan.code === "pro" && renderProPlanFeatures()}
      </List>
      <br />
      <Stack>
        <Stack.Item fill>{renderPlanButton(plan, selectedPlan)}</Stack.Item>
      </Stack>
    </Card.Section>
  );

  const renderHeadingSection = (plan) => (
    <Card.Section>
      <Stack alignment="center" wrap={false}>
        <Stack.Item fill>
          <DisplayText size="small" element="h1">
            {renderTitle(plan)}
          </DisplayText>
          <Caption>{cmsContent.label.freeTrial}</Caption>
        </Stack.Item>
        <Stack.Item>
          <DisplayText size="large">{`$${plan.monthlyFee}/m`}</DisplayText>
        </Stack.Item>
      </Stack>
      {plan.message && <Badge status="warning">{plan.message}</Badge>}
    </Card.Section>
  );

  const renderPlanCard = (plan, selectedPlan) => {
    if (!plan) {
      return null;
    }
    const { _id: planId } = plan;
    return (
      <Layout.Section key={plan.code} oneThird>
        <Card key={`renderShopifyPlanCard${planId}`} className="Polaris-Page__AppInstall">
          {renderHeadingSection(plan)}
          {renderBodySection(plan, selectedPlan)}
        </Card>
      </Layout.Section>
    );
  };

  const renderPlans = (selectedPlan = "") => {
    const { plans = [] } = props;
    const filteredPlans = plans.filter((plan) => plan.code !== free);
    return filteredPlans.map((plan) => renderPlanCard(plan, selectedPlan));
  };

  const { selectedPlan = "" } = props;
  return (
    <>
      {isFreePlanMsg && <Banner title={cms.message.planNote} status="success" />}
      <br />
      <Layout>{renderPlans(selectedPlan)}</Layout>
      <br />
      <br />
      <Layout>{renderFreePlan(selectedPlan)}</Layout>
    </>
  );
};

Plan.propTypes = propTypes;
Plan.defaultProps = defaultProps;

export default Plan;

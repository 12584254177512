import React from "react";
import { Card, Stack, Caption } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";

import { fullfillmentStatusProp } from "../propsType";

const FulfillmentStatus = (props) => {
  const { t } = useTranslation();
  const { lineData, data } = props;
  const isTaxIncluded = !!data.taxesIncluded;
  const isSeller = true;
  const totals = baseHelper.getOrderPrice(lineData, data, isSeller);
  const { isUpdatedOrderLine } = totals;
  const items = `${lineData.length > constant.value.ONE ? constant.items : `${t("label.items")}:`} ${lineData.length} `;
  const markUpItems = `${totals.markUpItems} ${
    totals.markUpItems > constant.value.ONE ? constant.items : `${t("label.items")}:`
  }`;
  const moneyFormat = data.moneyFormat || constant.symbol.DOLLAR;
  const vendorsSum = [];
  lineData.filter(({ vendor }) => {
    vendorsSum.push(vendor);
    return vendorsSum;
  });
  const vendorSet = new Set(vendorsSum);
  const setSize = vendorSet.size;
  const addVendorTxt =
    setSize === constant.value.ONE ? `${setSize} ${t("label.provider")}` : `${setSize} ${t("label.vendors")}`;
  const lineDataItems = lineData.length;
  return (
    <div className="totalSection">
      <Card>
        <Card.Section subdued>
          <Card.Subsection>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>Subtotal</Stack.Item>
                <Stack.Item>{items}</Stack.Item>
                <Stack.Item>
                  {moneyFormat}
                  {isUpdatedOrderLine ? baseHelper.getPrice(totals.price) : data.totalLineItemsPrice}
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              {isUpdatedOrderLine && !!totals.markUp && (
                <Stack wrap={false}>
                  <Stack.Item>{t("label.markUpTotal")}</Stack.Item>
                  <Stack.Item fill>{markUpItems}</Stack.Item>
                  <Stack.Item>
                    {moneyFormat}
                    {baseHelper.getPrice(totals.markUp)}
                  </Stack.Item>
                </Stack>
              )}
              <Stack wrap={false}>
                <Stack.Item fill>Shipping</Stack.Item>
                <Stack.Item>
                  {moneyFormat}
                  {baseHelper.getPrice(totals.shippingAmount)}
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>Discount</Stack.Item>
                <Stack.Item>
                  {`${moneyFormat} -`}
                  {(isUpdatedOrderLine &&
                    (baseHelper.getPrice(totals.sellerDiscount) || baseHelper.getPrice(totals.discount))) ||
                    (lineData.totalDiscount && baseHelper.getPrice(lineData.totalDiscount)) ||
                    constant.value.FLOAT_ZERO}
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section>
              <Stack wrap={false}>
                <Stack.Item fill>
                  {t("label.salesTax")}
                  {isTaxIncluded && t("label.included")}
                </Stack.Item>
                <Stack.Item>
                  {moneyFormat}
                  {!isSeller && isUpdatedOrderLine ? baseHelper.getPrice(totals.tax) : data.totalTax}
                </Stack.Item>
              </Stack>
            </Card.Section>
          </Card.Subsection>
        </Card.Section>
        <Card.Section>
          <Stack vertical>
            <Stack wrap={false}>
              <Stack.Item fill>
                <b>{t("label.totalOrderSlip")}</b>
              </Stack.Item>
              <Stack.Item>
                <Caption>{`${lineDataItems} items from ${addVendorTxt}`}</Caption>
              </Stack.Item>
              <Stack.Item>
                <b>
                  {moneyFormat}
                  {isUpdatedOrderLine ? baseHelper.getPrice(totals.totalPrice) : data.totalPrice}
                </b>
              </Stack.Item>
            </Stack>
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};

FulfillmentStatus.propTypes = fullfillmentStatusProp.type;
export default FulfillmentStatus;

import React from "react";
import { Sheet as PolarisSheet, Heading, Button, Scrollable, Icon, Stack, Link } from "@shopify/polaris";
import { MobileCancelMajorMonotone } from "@shopify/polaris-icons";
import PropTypes from "prop-types";

const Sheet = (props) => {
  const { isOpen, onClose, title, secondaryAction, primaryAction, children } = props;
  return (
    <PolarisSheet open={isOpen} onClose={onClose}>
      <div className="sheetBody">
        <div className="sheetSection">
          <Heading>{title}</Heading>
          <Stack>
            <Stack.Item fill>
              <Link onClick={onClose}>
                <Icon source={MobileCancelMajorMonotone} color="skyDark" />
              </Link>
            </Stack.Item>
          </Stack>
        </div>
        <Scrollable className="sheetScrollable">{children}</Scrollable>
        <div className="sheetSection">
          <Button onClick={secondaryAction.onAction}>{secondaryAction.content}</Button>
          <Button primary onClick={primaryAction.onAction}>
            {primaryAction.content}
          </Button>
        </div>
      </div>
    </PolarisSheet>
  );
};

Sheet.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  primaryAction: PropTypes.shape({
    content: PropTypes.string,
    onAction: PropTypes.func,
  }),
  secondaryAction: PropTypes.shape({
    content: PropTypes.string,
    onAction: PropTypes.func,
  }),
};

Sheet.defaultProps = {
  isOpen: false,
  onClose: () => {},
  title: "",
  children: null,
  primaryAction: {
    content: "",
    onAction: () => {},
  },
  secondaryAction: {
    content: "",
    onAction: () => {},
  },
};

export default Sheet;

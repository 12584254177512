import React from "react";
import { Button, ButtonGroup } from "@shopify/polaris";

const Actions = (props) => {
  const { actions } = props;
  if (!(actions && actions.length)) {
    return null;
  }
  let buttons = actions.map((item, idx) => {
    return (
      <Button
        size="slim"
        destructive={item.destructive || false}
        disabled={item.disabled || false}
        loading={!!item.loading}
        onClick={() => item.onAction()}
        key={`resourceListActionButton${idx}`}
      >
        {item.content}
      </Button>
    );
  });
  buttons = buttons.filter((item) => item);
  return <ButtonGroup>{buttons}</ButtonGroup>;
};

export default Actions;

import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import PrivateContainer from "./privateContainer";

// import helpers
import { storageHelper, baseHelper } from "../../lib/helpers";

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;

  const reloadApp = () => {
    const shop = storageHelper.get("shop");
    const shopifyURL = baseHelper.getShopifyURL(shop);
    if (shopifyURL) {
      window.top.location.href = shopifyURL;
    }
  };

  const redirectToLogin = (routeProps) => {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: routeProps.location },
        }}
      />
    );
  };

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        // if inside iFrame and there is no token in browser storage
        // getShopifyURL and reload the app
        const isIframe = baseHelper.isIframe();
        if (isIframe && !storageHelper.get("token")) {
          reloadApp();
        }
        if (!storageHelper.get("token")) {
          return redirectToLogin(routeProps);
        }
        
        return <PrivateContainer component={Component} {...routeProps} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.node,
};

PrivateRoute.defaultProps = {
  component: null,
};

export default PrivateRoute;

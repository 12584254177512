import React, { useState, useContext, useEffect } from "react";
import { Card, FormLayout, Select, Modal, Layout, Stack, TextField } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import helpers components
import { ResourceList } from "lib/components";

// import constant
import constant from "lib/constant/constant";

// import gql
import { DELETE_OFFER } from "app/shipping/apollo/mutations";

// import subFeature
import AddOffer from "../add/add";
import EditOffer from "../edit/edit";
import OfferItem from "./subFeatureItems/rowItem";

// import propTypes
import { listProp } from "../../propTypes";
import cmsVendorList from "../cms/vendorListCMS";

const List = (props) => {
  const { offers = [], products, destinations, loading, setBanner, bands = [], isVendorAllowed, refetch } = props || {};
  const { currentUser, setPageData, cms } = useContext(PrivateContext);
  const [deleteOffer, { loading: deleteOfferLoading }] = useMutation(DELETE_OFFER);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const vendorListCMS = cmsVendorList(t);
  const { label, filterOptions } = vendorListCMS;
  const [selectedFilter, setSelectedFilter] = useState("");
  const [taggedWith, setTaggedWith] = useState("");

  const [state, setState] = useState({
    offers: [],
    searchValue: "",
    showModal: false,
    showDeleteModal: false,
    selectedOffer: false,
    offersToShow: [],
    appliedFilters: [],
    dismissBanner: false,
  });
  const toggleModal = (id = false) => {
    setState((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
      selectedOffer: prevState.showModal ? false : id,
    }));
  };
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      setPageData({
        title: cms("title"),
        primaryAction: {
          content: cms("modal.offer.add.title"),
          disabled: !isVendorAllowed,
          onClick: () => toggleModal(),
        },
      });
    }
  }, [isVendorAllowed, setPageData, isLoading]);

  useEffect(() => {
    if (offers.length && offers !== state.offers) {
      setState({ ...state, offers, offersToShow: offers });
    }
  }, [offers, state]);

  const toggleDeleteModal = (id = false) =>
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: !prevState.showDeleteModal,
      selectedOffer: prevState.showDeleteModal ? false : id,
    }));

  const onDelete = async () => {
    const { selectedOffer } = state;
    setState({ ...state, actionLoading: true });
    const requestData = {
      shippingOfferId: selectedOffer,
    };
    try {
      const response = await deleteOffer({
        variables: { input: requestData },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.DELETE_SHIPPING_OFFER);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: "success", title: t("message.shippingOffer.deleteOfferSuccess") });
      refetch();
    } catch (error) {
      setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
    }
    toggleDeleteModal(false);
  };

  const handleFiltersChange = (appliedFilters) => {
    if (!appliedFilters.length) {
      setState({ ...state, offersToShow: [...offers], appliedFilters, searchValue: "" });
      return;
    }
    let destinationRowsToShow = [];
    let productRowsToShow = [];
    const filterValue = appliedFilters.toLowerCase().trim();
    if (selectedFilter === constant.DESTINATION) {
      let options = [{ value: "", label: "all" }];
      destinations.forEach((item) => {
        options = [...options, ...item.options];
      });
      const filteredDestinations = options.filter((item) => item.label.toLowerCase() === filterValue.toLowerCase());
      destinationRowsToShow = offers.filter((item) =>
        filteredDestinations.find((destination) => destination.value === item.destinationCode)
      );
    }
    if (selectedFilter === constant.PRODUCT) {
      let options = [{ value: "", label: "all" }];
      products.forEach((item) => {
        options = [...options, item];
      });
      const filteredProducts = options.filter((item) => item.label.toLowerCase() === filterValue.toLowerCase());
      productRowsToShow = offers.filter((item) =>
        filteredProducts.find((product) => {
          return product.value === item.productId;
        })
      );
    }
    setState({
      ...state,
      offersToShow: [...destinationRowsToShow, ...productRowsToShow],
      appliedFilters,
      searchValue: "",
    });
  };

  const handleSearchChange = (searchValue) => {
    if (!searchValue) {
      setState({ ...state, offersToShow: [...offers], searchValue, appliedFilters: [] });
      return;
    }
    const search = searchValue.toLowerCase().trim();
    const updatedOffers = offers.filter((item) => {
      const shippingBand = bands.find((band) => band.value === item.shippingBandId);
      if (shippingBand && shippingBand.label) {
        return shippingBand.label.toLowerCase().includes(search);
      }
      return true;
    });
    setState({ ...state, offersToShow: [...updatedOffers], searchValue, appliedFilters: [] });
  };
  const handleQueryClear = () => {
    setState({ searchValue: "" });
  };
  const renderDeleteModal = () => {
    const { showDeleteModal, selectedOffer } = state;
    const offerToUpdate = offers.find((item) => item._id === selectedOffer) || {};
    const shippingBand = bands.find((band) => band.value === offerToUpdate.shippingBandId) || {};
    if (!offerToUpdate) {
      return null;
    }
    return (
      <Modal
        open={showDeleteModal}
        onClose={toggleDeleteModal}
        title={`${t("label.shippingOffer.deleteOffer")} ${t("label.shippingOffer.hyphen")} ${
          shippingBand ? shippingBand.label : ""
        }`}
        primaryAction={{
          content: constant.DELETE,
          destructive: true,
          loading: deleteOfferLoading,
          onAction: onDelete,
        }}
      >
        <Modal.Section>{t("message.shippingOffer.sureDeleteOffer")}</Modal.Section>
      </Modal>
    );
  };

  const renderModal = () => {
    const { showModal, selectedOffer = false } = state;
    const offerToUpdate = offers.find((item) => {
      const { _id: id } = item;
      return id === selectedOffer;
    });
    if (selectedOffer) {
      return (
        <EditOffer
          handleBanner={setBanner}
          destinations={destinations}
          bands={bands}
          products={products}
          shippingOffer={{ ...offerToUpdate }}
          show={showModal}
          onClose={toggleModal}
          refetch={refetch}
        />
      );
    }
    return (
      <AddOffer
        handleBanner={setBanner}
        destinations={destinations}
        bands={bands}
        products={products}
        shippingOffer={{ ...offerToUpdate }}
        show={showModal}
        onClose={toggleModal}
        refetch={refetch}
      />
    );
  };

  const renderItem = (item) => {
    if (loading) {
      return null;
    }
    const moneyFormat = currentUser.moneyFormat || constant.symbol.DOLLAR;

    return (
      <OfferItem
        moneyFormat={moneyFormat}
        item={item}
        bands={bands}
        destinations={destinations}
        products={products}
        isVendorAllowed={isVendorAllowed}
        toggleModal={toggleModal}
        toggleDeleteModal={toggleDeleteModal}
      />
    );
  };

  const resourceName = {
    singular: constant.OFFER,
    plural: constant.OFFERS,
  };
  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    setTaggedWith("");
    setState({ ...state, offersToShow: [...offers], searchValue: "" });
  };

  // function for more filter.
  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    handleFiltersChange(value);
  };

  const handleTaggedWithRemove = () => {
    setTaggedWith("");
    setSelectedFilter("");
    handleFiltersChange("");
  };

  const handleClearAll = () => {
    handleTaggedWithRemove();
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = !isEmpty(taggedWith)
    ? [{ key: "taggedWith", label: disambiguateLabel("taggedWith", taggedWith), onRemove: handleTaggedWithRemove }]
    : [];

  const customFilter = (
    <FormLayout>
      <Select
        label={label.show_all_vendor}
        value={selectedFilter}
        onChange={handleSelectChange}
        options={filterOptions}
        placeholder={label.select_filter}
      />
      {(selectedFilter && <TextField label={label.like} value={taggedWith} onChange={handleTaggedWithChange} />) ||
        null}
    </FormLayout>
  );

  const filters = [
    {
      key: t("taggedWith"),
      label: label.filter_by,
      filter: customFilter,
      shortcut: false,
    },
  ];

  const { offersToShow } = state;

  return (
    <>
      {renderModal()}
      {renderDeleteModal()}
      <Stack.Item>
        <Stack>
          <Stack.Item fill />
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Card>
          <ResourceList
            resourceName={resourceName}
            items={loading ? [] : offersToShow}
            renderItem={renderItem}
            loading={loading}
            filters={filters}
            queryValue={state.searchValue}
            onQueryChange={(searchValue) => handleSearchChange(searchValue)}
            onQueryClear={handleQueryClear}
            appliedFilters={appliedFilters}
            handleClearAll={handleClearAll}
          />
        </Card>
      </Stack.Item>
    </>
  );
};

List.propTypes = listProp.type;

export default List;

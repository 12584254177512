import React, { useContext } from "react";
import { MediaCard, List } from "@shopify/polaris";
import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";

const Finish = () => {
  const { currentUserRefetch, isLoading, cms = {} } = useContext(PrivateContext);

  return (
    <MediaCard
      title={cms("common.onboarding.vendorFinish.title")}
      primaryAction={{
        content: cms("common.onboarding.finish.button.primary"),
        onAction: () => {
          storageHelper.remove("setupProgress");
          currentUserRefetch();
        },
        loading: isLoading,
      }}
      description={[
        <>
          <p>{cms("common.onboarding.vendorFinish.description")}</p>
          <br />
          <p>{cms("common.onboarding.vendorFinish.nextStep")}</p>
          <br />
          <p>
            <List type="number">
              {cms("common.onboarding.vendorFinish.list") &&
                cms("common.onboarding.vendorFinish.list").map((list) => <List.Item>{list}</List.Item>)}
            </List>
          </p>
        </>,
      ]}
    >
      <img
        alt=""
        width="100%"
        height="100%"
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        src="https://res.cloudinary.com/marketcube/image/upload/w_800/v1604935536/marketcube/welcome.jpg"
      />
    </MediaCard>
  );
};

export default Finish;

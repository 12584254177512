import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { SkeletonAnnotated } from "lib/components";
import constant from "lib/constant/constant";
import { Fulfillment as OperatorFulfillment } from "./operator/features";

const { userKey } = constant;
const { operator } = userKey;

const Fulfillment = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <OperatorFulfillment />;
    }
    return null;
  };

  if (isLoading) {
    return <SkeletonAnnotated />;
  }

  const currentUserRole = storageHelper.get("userRole");
  const FulfillmentComponent = getComponent(currentUserRole);
  return FulfillmentComponent;
};

export default Fulfillment;

import React from "react";
import { ButtonGroup, Button, ResourceItem, Stack, Heading, Badge, TextStyle, Link } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// import from lib
import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

// import cms
import cmsVendorList from "../cms/vendorList";

const VendorListView = (props) => {
  const { item, keyIndex, onDeleteVendor, onLoginVendor } = props;
  const { _id, lastName, firstName, email, brandName, createdAt, roles } = item;
  const { address = "", verified } = email || {};
  const { name: role } = roles;

  const { t } = useTranslation();
  const vendorListCMS = cmsVendorList(t);
  const { button, label } = vendorListCMS;

  return (
    <>
      <ResourceItem id={_id} sortOrder={keyIndex} accessibilityLabel={`${label.viewDetailsFor} ${brandName}`}>
        <Stack>
          <Stack.Item fill>
            <Heading>
              <Link url={`/providers/profile/${_id}`}>{baseHelper.ucFirst(brandName) || "No BrandName"}</Link>
            </Heading>
          </Stack.Item>
          <Stack.Item>
            <Badge status={verified ? "success" : "new"}>{verified ? constant.APPROVED : constant.PENDING}</Badge>
          </Stack.Item>
          <Stack.Item>
            <ButtonGroup>
              <Button size="slim" destructive onClick={() => onDeleteVendor(_id)}>
                {button.deleteVendor}
              </Button>
              <Button size="slim" onClick={() => onLoginVendor(_id, brandName, role)}>
                {button.loginVendor}
              </Button>
              <Button size="slim" url={`/providers/edit/${_id}`}>
                Edit
              </Button>
            </ButtonGroup>
          </Stack.Item>
        </Stack>
        <Stack wrap={false} vertical spacing="extraTight">
          <Stack.Item>{`${label.name} ${firstName || "No Name"} ${lastName || ""}`}</Stack.Item>
          <Stack.Item>{`${label.email} ${address}`}</Stack.Item>
          <Stack.Item>
            <TextStyle variation="subdued">{`${label.onBoardedOn} ${baseHelper.formatDate(createdAt)}`}</TextStyle>
          </Stack.Item>
        </Stack>
      </ResourceItem>
    </>
  );
};

VendorListView.defaultProps = {
  item: {},
  keyIndex: "",
  onDeleteVendor: () => {},
  onLoginVendor: () => {},
};

VendorListView.propTypes = {
  item: PropTypes.shape(PropTypes.object),
  keyIndex: PropTypes.number,
  onDeleteVendor: PropTypes.func,
  onLoginVendor: PropTypes.func,
};

export default VendorListView;

// import react packages
import React, { useState } from "react";
import { useMutation } from "react-apollo";

// Import Polaris Components
import { Button, Modal } from "@shopify/polaris";

// import helpers
import constant from "lib/constant/constant";
import { baseHelper } from "lib/helpers";

// import gql
import { DISCONNECT_SHOP } from "app/products/apollo/mutations/connect";

import { connectedShopProp } from "../../../props";

const ConnectedShop = (props) => {
  const { useTranslation, currentUserData, setBanner, setIsConnected, setShop } = props;
  const { t } = useTranslation();
  const { DISCONNECT_SHOP: DISCONNECT } = constant.gql;

  const [isDisconnectActive, setIsDisconnectActive] = useState(false);

  const [disconnectShop, { loading: disconnectLoading }] = useMutation(DISCONNECT_SHOP);

  const disconnectShopConfirm = async () => {
    try {
      const response = await disconnectShop({
        variables: { input: { id: currentUserData.id } },
      });
      const responseError = baseHelper.getResponseError(response.data, DISCONNECT);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      setBanner({ isOpen: true, status: "success", title: t("message.ProductAdd.Disconnected") });
      setIsConnected(false);
      setShop("");
    } catch (error) {
      setBanner({ isOpen: true, status: "critical", title: t("error.common.somethingWentWrong") });
    }
  };

  const handleDisconnectModal = () => {
    setIsDisconnectActive(!isDisconnectActive);
  };

  return (
    <>
      <Modal
        open={isDisconnectActive}
        onClose={handleDisconnectModal}
        title={t("label.ProductAdd.Disconnect")}
        primaryAction={{
          content: `${t("label.ProductAdd.Disconnect")} ${currentUserData.shop}`,
          onAction: disconnectShopConfirm,
          destructive: true,
          loading: disconnectLoading,
        }}
        secondaryActions={[
          {
            content: t("label.ProductAdd.Cancel"),
            onAction: handleDisconnectModal,
          },
        ]}
      >
        <Modal.Section>{`${t("message.ProductAdd.Sure")} ${currentUserData.shop}`}</Modal.Section>
      </Modal>
      <Button plain destructive id="disconnect" onClick={handleDisconnectModal}>
        {t("label.ProductAdd.Disconnect")}
      </Button>
    </>
  );
};

ConnectedShop.propTypes = connectedShopProp;

export default ConnectedShop;

import React, { useState, useContext } from "react";

import {
  Card,
  Layout,
  Stack,
  TextContainer,
  FormLayout,
  TextField,
  ResourceItem,
  Thumbnail,
  ResourceList,
  Modal,
  Select,
  DisplayText,
  TextStyle,
} from "@shopify/polaris";
import { EditMinor, DeleteMinor } from "@shopify/polaris-icons";
import { useMutation } from "react-apollo";
import { PrivateContext } from "lib/context";

import constant from "lib/constant/constant";
import baseHelper from "lib/helpers/base";

import { imageHelper } from "lib/helpers";
import Popover from "lib/components/popover/popover";
import { BULK_UPDATE_VARIANT, DELETE_VARIANT } from "../../../../../../apollo/mutations";
import { variantsProp } from "../../../../../generic/edit/propTypes";

import { GET_PRODUCT_AND_SELLER_PRODUCT } from "../../../../../../apollo/queries";

const RenderVariants = (props) => {
  const { history, currentUser, match, cms } = useContext(PrivateContext);
  const { data, setBanner } = props || {};
  const { _id: productItemId } = data;
  const { moneyFormat } = currentUser;
  const [updateMarkUp, setMarkUp] = useState({
    type: "flat",
    price: "",
  });
  const [updateQuantity, setQuantity] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [active, setActive] = useState(false);
  const [openQuantity, setOpenQuantity] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [activePopover, setActivePopover] = useState({});

  const { gql } = constant;

  // GQL calls
  const [updateMarkupVariant, { loading: loadingBulkUpdate }] = useMutation(BULK_UPDATE_VARIANT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_AND_SELLER_PRODUCT,
        variables: { input: { _id: match.params.id } },
      },
    ],
  });
  const [deleteVariant, { loading: loadingDeleteVariant }] = useMutation(DELETE_VARIANT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_AND_SELLER_PRODUCT,
        variables: { input: { _id: match.params.id } },
      },
    ],
  });

  const handleEditMarkup = () => setActive(!active);
  const handleEditQuantity = () => setOpenQuantity(!openQuantity);

  const handleDeleteModal = (index) => {
    setSelectedId(index);
    setDeleteActive(!deleteActive);
  };
  const bulkActions = [
    { content: cms("section.modal.button.primary.markup"), onAction: () => handleEditMarkup() },
    { content: cms("section.modal.button.primary.quantity"), onAction: () => handleEditQuantity() },
  ];
  const handleChange = (fieldName, fieldValue, typeSelected) => {
    if (!typeSelected) {
      return setMarkUp({ price: "", type: fieldValue });
    }
    if (typeSelected === constant.PERCENTAGE && (fieldValue <= 100 || !fieldValue)) {
      return setMarkUp({ ...updateMarkUp, [fieldName]: fieldValue });
    }
    if (typeSelected !== constant.PERCENTAGE) {
      return setMarkUp({ ...updateMarkUp, [fieldName]: fieldValue });
    }
    return false;
  };
  const handleUpdateMarkup = (fieldName = false) => {
    const value = {
      productId: productItemId,
      positions: selectedItems,
    };
    if (fieldName === constant.MARKUP) {
      value.markUp = updateMarkUp || parseInt(0);
    }
    if (fieldName === constant.INVENTORY_QUANTITY) {
      value.inventoryQuantity = updateQuantity || parseInt(0);
    }
    updateMarkupVariant({ variables: { input: value } })
      .then((res) => {
        setSelectedItems([]);
        const responseError = baseHelper.getResponseError(res, gql.BULK_UPDATE_VARIANT);
        const successMessage =
          fieldName === constant.INVENTORY_QUANTITY
            ? cms("section.image.message.success.updateQuantity")
            : cms("section.image.message.success.updateMarkup");
        if (responseError) {
          return setBanner({ isOpen: true, status: "critical", title: responseError });
        }
        setOpenQuantity(false);
        setActive(false);
        return setBanner({
          isOpen: true,
          status: "success",
          title: successMessage,
        });
      })
      .catch(() => {
        setOpenQuantity(false);
        setActive(false);
        setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
      });
  };
  const handleDeleteVariant = () => {
    const value = {
      id: selectedId + 1,
      productId: productItemId,
    };
    deleteVariant({ variables: { input: value } })
      .then((res) => {
        const responseError = baseHelper.getResponseError(res.data, gql.DELETE_VARIANT);
        if (responseError) {
          handleDeleteModal();
          setBanner({ isOpen: true, status: "critical", title: responseError });
        }
        const responseData = baseHelper.getResponseData(res.data, gql.DELETE_VARIANT);
        if (responseData) {
          setSelectedId(null);
          handleDeleteModal();

          setBanner({ isOpen: true, status: "success", title: cms("section.image.message.success.variantDelete") });
        }
      })
      .catch(() => {
        setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
        handleDeleteModal();
      });
  };
  const renderDeleteModal = () => {
    return (
      <Modal
        open={deleteActive}
        onClose={handleDeleteModal}
        title={cms("section.modal.title.delete")}
        primaryAction={{
          content: cms("section.modal.button.primary.delete"),
          onAction: handleDeleteVariant,
          loading: loadingDeleteVariant,
          disabled: loadingDeleteVariant,
        }}
      >
        <Modal.Section>{cms("section.modal.content")}</Modal.Section>
      </Modal>
    );
  };
  const renderModal = () => {
    return (
      <Modal
        open={active}
        onClose={handleEditMarkup}
        title={cms("section.modal.title.updateMarkup")}
        primaryAction={{
          content: cms("section.modal.label.updateMarkup"),
          onAction: () => handleUpdateMarkup(constant.MARKUP),
          loading: loadingBulkUpdate,
          disabled: loadingBulkUpdate,
        }}
      >
        <Modal.Section>
          <TextField
            label={cms("section.modal.label.markup")}
            value={updateMarkUp.price.toString() || ""}
            placeholder={constant.ZERO_PRICE}
            type="number"
            min={0}
            step={0.01}
            max={(updateMarkUp.type === constant.PERCENTAGE && 100) || 9999}
            prefix={updateMarkUp.type === constant.FLAT && moneyFormat}
            suffix={updateMarkUp.type === constant.PERCENTAGE && "%"}
            onChange={(value) => handleChange(constant.PRICE, parseInt(value, 10), updateMarkUp.type)}
            connectedRight={
              <Select
                placeholder={cms("section.price.placeholder.selectType")}
                options={[
                  {
                    label: cms("common.label.flat"),
                    value: constant.FLAT,
                  },
                  {
                    label: cms("common.label.percentage"),
                    value: constant.PERCENTAGE,
                  },
                ]}
                value={updateMarkUp.type}
                onChange={(value) => handleChange(constant.TYPE, value, false)}
              />
            }
          />
        </Modal.Section>
      </Modal>
    );
  };
  const renderQuantityModal = () => {
    return (
      <Modal
        open={openQuantity}
        onClose={handleEditQuantity}
        title={cms("section.modal.title.updateQuantity")}
        primaryAction={{
          content: cms("section.modal.label.updateQuantity"),
          onAction: () => handleUpdateMarkup(constant.INVENTORY_QUANTITY),
          loading: loadingBulkUpdate,
          disabled: loadingBulkUpdate,
        }}
      >
        <Modal.Section>
          <TextField
            label={cms("section.modal.label.quantity")}
            value={updateQuantity.toString()}
            placeholder={constant.ZERO_PRICE}
            type="number"
            min={0}
            onChange={(value) => setQuantity(parseInt(value, 10))}
          />
        </Modal.Section>
      </Modal>
    );
  };
  const renderItem = (item, _, index) => {
    const {
      imageUrl,
      option1Val = false,
      option2Val = false,
      option3Val = false,
      option1 = "Size",
      option2 = "Color",
      option3 = "Material",
      inventoryQuantity,
      price,
      markUp = { type: "flat", price: 0 },
    } = item;
    const { price: priceMark = "", type = "flat" } = markUp || {};
    const media = (
      <Thumbnail
        source={imageHelper.resize({ url: imageUrl, type: constant.imageTypes.THUMBNAIL }) || constant.NOIMAGESNAP}
        alt={`image of variant ${index}`}
      />
    );

    return (
      <ResourceItem id={index + 1} media={media}>
        <TextContainer>
          <Stack wrap={false}>
            <Stack.Item fill>
              {option1Val && <div className="variantEllipsis">{`${option1}: ${option1Val}`}</div>}
              {option2Val && <div className="variantEllipsis">{`${option2}: ${option2Val}`}</div>}
              {option3Val && <div className="variantEllipsis">{`${option3}: ${option3Val}`}</div>}
            </Stack.Item>
            <Stack.Item>
              <Popover
                active={activePopover[index]}
                setActive={() => setActivePopover({ [index]: !activePopover[index] })}
                options={[
                  {
                    content: "Edit",
                    icon: EditMinor,
                    onAction: () => history.push(`/variants/edit/${productItemId}/${index + 1}`),
                  },
                  { content: "Delete", icon: DeleteMinor, destructive: true, onAction: () => handleDeleteModal(index) },
                ]}
              />
            </Stack.Item>
          </Stack>
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField
                label={cms("section.modal.label.inventory")}
                value={(inventoryQuantity && inventoryQuantity.toString()) || ""}
                disabled
              />
              <TextField
                label={cms("section.variant.label.price")}
                value={(price && price.toString()) || ""}
                prefix={(moneyFormat === "$" && moneyFormat) || "Rs."}
                disabled
              />
              <TextField
                label={cms("section.price.label.markup")}
                value={(priceMark && priceMark.toString()) || ""}
                suffix={type === "percentage" && "%"}
                prefix={(type === "flat" && moneyFormat === "$" && moneyFormat) || "Rs."}
                disabled
              />
            </FormLayout.Group>
          </FormLayout>
        </TextContainer>
      </ResourceItem>
    );
  };
  const view = (dataNew) => {
    const { _id: id, variants } = dataNew;
    return (
      <Layout.Section>
        <Card
          sectioned
          title={cms("section.variant.title")}
          actions={[
            {
              content: cms("section.variant.headerAction"),
              onAction: () => {
                history.push(`/variants/add/${id}`);
              },
            },
          ]}
        >
          {!(variants && variants.length) && (
            <>
              <TextContainer>
                <DisplayText size="extraSmall">{cms("section.variant.content")}</DisplayText>
                <br />
                <DisplayText size="extraSmall">
                  <TextStyle variation="strong">{`${cms("section.variant.note.label")}: `}</TextStyle>
                  {cms("section.variant.note.content")}
                </DisplayText>
              </TextContainer>
            </>
          )}
          {variants && variants.length > 0 && (
            <ResourceList
              items={variants}
              renderItem={renderItem}
              selectedItems={selectedItems}
              onSelectionChange={setSelectedItems}
              bulkActions={bulkActions}
              idForItem={(item, index) => index + 1}
            />
          )}
        </Card>
      </Layout.Section>
    );
  };
  return [renderModal(), renderQuantityModal(), renderDeleteModal(), view(data)];
};
RenderVariants.propTypes = variantsProp.type;
export default RenderVariants;

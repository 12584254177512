import { baseHelper } from "lib/helpers";

class PaymentHelper {
  /**
   * @desc To return payments csv data
   * @param orderLines array of orderlines
   *
   * @return csv data
   *
   */
  paymentCSVData = (orderLines = []) => {
    const paymentArray = [];
    paymentArray[0] = [
      "Payment Id",
      "Lineitem Id",
      "Product",
      "Payment Status",
      "Vendor",
      "Quantity",
      "Price",
      "Vendor Commission",
      "Seller Commission",
      "Created At",
    ];
    orderLines.forEach((item, idx) => {
      paymentArray[idx + 1] = [
        `#${item.orderNumber}`,
        `#${item.shopifyLineItemId}`,
        item.name,
        item.accountingStatus || "Unpaid",
        item.vendor,
        item.quantity,
        `${item.moneyFormat} ${baseHelper.formatPrice(item.price || 0)}`,
        `${item.moneyFormat} ${baseHelper.formatPrice(item.commissionVendor || 0)}`,
        `${item.moneyFormat} ${baseHelper.formatPrice(item.commissionSeller || 0)}`,
        baseHelper.formatDate(item.createdAt),
      ];
    });
    return paymentArray;
  };
}

export default new PaymentHelper();

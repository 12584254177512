import React, { useContext, useEffect } from "react";
import { useMutation } from "react-apollo";
import { TextContainer } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

// import helper components
import { Spinner } from "lib/components";

// import helper
import baseHelper from "lib/helpers/base";

// import context
import { PrivateContext } from "lib/context";

// import constant
import constant from "lib/constant/constant";

// import hoc
import { withErrorBoundary } from "lib/hoc";

// import gql
import { CONNECT_PAYPAL_VENDOR } from "../../../../apollo/mutations";

const PaypalCallback = () => {
  const { t } = useTranslation();
  const [connectToPaypal, { loading }] = useMutation(CONNECT_PAYPAL_VENDOR);
  const { gql } = constant;
  const { currentUser, currentUserRefetch = () => {}, history } = useContext(PrivateContext);
  const { _id: id } = currentUser;
  const { code, state } = baseHelper.getQueryParams(history.location.search);

  const connectPaypal = async () => {
    try {
      const response = await connectToPaypal({ variables: { input: { code } } });

      const responseData = baseHelper.getResponseData(response.data, gql.CONNECT_PAYPAL_VENDOR);
      const responseError = baseHelper.getResponseError(response.data, gql.CONNECT_PAYPAL_VENDOR);

      if (responseError) {
        history.push("/payment-connect", { isPaypalConnected: false, message: responseError });
      }

      if (responseData) {
        currentUserRefetch();
        history.push("/payment-connect", {
          isPaypalConnected: true,
        });
      }
    } catch {
      history.push("/payment-connect", { isPaypalConnected: false, message: t("error.common.somethingWentWrong") });
    }
  };

  useEffect(() => {
    if (code) {
      connectPaypal();
    }
    if (!code) {
      history.push("/payment-connect");
    }
  }, [code, history]);
  if (loading) {
    return <Spinner fontSize={42} />;
  }
  if (id !== state) {
    return <TextContainer>Not Found</TextContainer>;
  }
  return null;
};

export default withErrorBoundary(PaypalCallback);

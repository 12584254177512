// import packages
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Checkbox,
  DisplayText,
  List,
  Modal,
  OptionList,
  Popover,
  Select,
  Stack,
  TextField,
} from "@shopify/polaris";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

// import components
import Banner from "lib/components/banner/banner";
import AutoComplete from "lib/components/autocomplete/autocomplete";

// import helpers
import { baseHelper } from "lib/helpers";

// import context
import { PrivateContext } from "lib/context";

// import constants
import constant from "lib/constant/constant";

// import gql
import { ADD_RULE } from "app/shipping/apollo/mutations";

// import propType

import { addRuleProp } from "../../propTypes";

const AddRule = (props) => {
  const {
    shippingRule: shippingRuleProp = {},
    onClose,
    destinations,
    show,
    bands,
    products,
    handleBanner,
    refetch,
  } = props;
  const { currentUser, cms } = useContext(PrivateContext);

  const defaultRule = {
    condition: "",
    description: "",
    destinationCodes: [],
    isShippingDisabled: false,
    price: 0,
    priceType: "",
    productIds: [],
    shippingBandId: "",
  };
  const defaultBanner = {
    isOpen: false,
    status: "",
    title: "",
  };
  const { t } = useTranslation();
  const [shippingRule, setShippingRule] = useState({ ...defaultRule });
  const [banner, setBanner] = useState({ ...defaultBanner });
  const [popoverActive, setPopoverActive] = useState(false);

  const [addRule, { loading: addRuleLoading }] = useMutation(ADD_RULE);

  useEffect(() => {
    if (Object.keys(shippingRuleProp).length !== 0 && shippingRuleProp !== shippingRule) {
      setShippingRule(shippingRuleProp);
    }
  }, [shippingRule, shippingRuleProp]);

  const checkValidation = () => {
    const { shippingBandId, price, description, condition, destinationCodes, productIds, priceType } =
      shippingRule || {};
    if (!shippingBandId) {
      return t("error.shippingRule.selectShippingBand");
    }
    if (!condition) {
      return t("error.shippingRule.selectShippingCondition");
    }
    if (condition === constant.DESTINATION && !(destinationCodes && destinationCodes.length)) {
      return t("error.shippingRule.selectAtleastOneDestination");
    }
    if (condition === constant.PRODUCT && !(productIds && productIds.length)) {
      return t("error.shippingRule.selectAtleastOneProduct");
    }
    if (!description) {
      return t("error.shippingRule.addDescriptionForRule");
    }
    if (description.length > 255) {
      return t("error.shippingRule.descriptionCharacter");
    }
    if (!priceType) {
      return t("error.shippingRule.selectShippingRuleType");
    }
    if (priceType !== constant.FREE && !price) {
      return t("error.shippingRule.provideCost");
    }
    if (priceType !== constant.FREE && price < 0) {
      return t("error.shippingRule.provideValidPrice");
    }
    return false;
  };

  const closeModal = () => {
    setBanner({ ...defaultBanner });
    onClose();
  };

  const addShippingBand = async () => {
    const requestData = {
      ...shippingRule,
    };
    try {
      const response = await addRule({
        variables: { input: requestData },
      });
      const responseError = baseHelper.getResponseError(response.data, constant.gql.CREATE_SHIPPING_RULE);
      if (responseError) {
        setBanner({ isOpen: true, status: "critical", title: responseError });
        return;
      }
      setShippingRule({ ...defaultRule });
      handleBanner({ isOpen: true, status: "success", title: cms("modal.rule.add.message.success.successfullyAdded") });
      setPopoverActive(false);
      closeModal();
      refetch();
    } catch (err) {
      setBanner({ isOpen: true, status: "critical", title: cms("common.message.error.somethingWentWrong") });
    }
  };

  const onSubmit = () => {
    const hasError = checkValidation();
    if (hasError) {
      setBanner({ isOpen: true, status: "critical", title: hasError });
      return false;
    }
    addShippingBand();
    return true;
  };

  const toggleBanner = () => setBanner({ ...defaultBanner });

  const handleShippingRule = (key, value) => {
    setShippingRule({ ...shippingRule, [key]: value });
  };

  const togglePopover = () => setPopoverActive(!popoverActive);

  const renderDestinations = () => {
    const { destinationCodes = [] } = shippingRule || {};

    const list = destinations.map((item) => {
      const { title, options = [] } = item;
      const selectedOptions = options.filter((option) => destinationCodes.includes(option.value));
      if (!selectedOptions.length) {
        return null;
      }
      return (
        <List.Item key={`${item.title}${selectedOptions.length}`}>
          {title}
          <List type={constant.BULLET}>
            {selectedOptions.map((option) => (
              <List.Item key={option.value}>{option.label}</List.Item>
            ))}
          </List>
        </List.Item>
      );
    });

    const destinationList = list.filter((item) => item);

    if (!destinationList.length) {
      return null;
    }

    return (
      <Stack>
        <Stack.Item>
          <DisplayText size={constant.SMALL}>{cms("modal.rule.add.label.selected")}</DisplayText>
        </Stack.Item>
        <Stack.Item>
          <List type={constant.TYPE}>{destinationList}</List>
        </Stack.Item>
      </Stack>
    );
  };

  const {
    shippingBandId,
    price = 0,
    description,
    condition,
    destinationCodes = [],
    productIds = [],
    priceType,
    isShippingDisabled,
  } = shippingRule || {};
  const { moneyFormat = constant.symbol.DOLLAR } = currentUser || {};

  const isFieldDisabled = false;

  const isDestination = condition === constant.DESTINATION;
  const isProduct = condition === constant.PRODUCT;

  const activator = <Button onClick={togglePopover}>Select Regions</Button>;
  const priceTypeOptions = [
    {
      label: cms("modal.rule.edit.label.cost"),
      value: constant.FIXED,
    },
    {
      label: cms("modal.rule.add.label.free"),
      value: constant.FREE,
    },
  ];
  const conditionOptions = [
    {
      label: cms("modal.rule.add.label.destination"),
      value: constant.DESTINATION,
    },
    {
      label: cms("modal.rule.add.label.product"),
      value: constant.PRODUCT,
    },
  ];

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={cms("modal.rule.add.title")}
      primaryAction={{
        content: cms("modal.rule.add.button.primary"),
        loading: addRuleLoading,
        onAction: () => onSubmit(),
      }}
    >
      <Modal.Section>
        <Banner onDismiss={toggleBanner} isOpen={banner.isOpen} status={banner.status} title={banner.title} />
        <br />
        <form>
          <Stack vertical>
            <Stack.Item>
              <Select
                options={bands}
                name={constant.SHIPPING_BAND}
                label={cms("modal.rule.add.label.selectShippingBand")}
                placeholder={cms("modal.rule.add.label.selectShippingBand")}
                value={shippingBandId || ""}
                onChange={(val) => handleShippingRule(constant.SHIPPING_BAND_ID, val)}
              />
            </Stack.Item>
            <Stack.Item>
              <Select
                options={conditionOptions}
                name={constant.SHIPPING_CONDITION}
                label={cms("modal.rule.add.label.selectShippingRule")}
                placeholder={cms("modal.rule.add.label.selectShippingRule")}
                value={condition || ""}
                onChange={(val) => handleShippingRule(constant.CONDITION, val)}
              />
            </Stack.Item>
            {isDestination && (
              <Stack.Item>
                <Stack.Item>
                  <Popover active={popoverActive} activator={activator} onClose={togglePopover}>
                    <OptionList
                      onChange={(val) => handleShippingRule(constant.DESTINATION_CODE, val)}
                      sections={destinations}
                      selected={destinationCodes}
                      allowMultiple
                    />
                  </Popover>
                </Stack.Item>
                <Stack.Item>{renderDestinations()}</Stack.Item>
              </Stack.Item>
            )}
            {isProduct && (
              <Stack.Item>
                <AutoComplete
                  id={constant.PRODUCT_RULE_CONDITION}
                  label={cms("modal.rule.add.label.products")}
                  labelHidden
                  placeholder={cms("modal.rule.add.placeholder.startTyping")}
                  values={products}
                  selected={productIds}
                  onChange={(val) => handleShippingRule(constant.PRODUCT_IDS, val)}
                  minimumSearchLength={constant.value.MIN_SEARCH_LENGTH}
                />
              </Stack.Item>
            )}
            <Stack.Item>
              <TextField
                id={constant.INPUT_ID_DESCRIPTION}
                type={constant.TEXT}
                label={cms("modal.rule.add.label.description")}
                placeholder={cms("modal.rule.add.label.description")}
                value={description}
                onChange={(val) => handleShippingRule(constant.DESCRIPTION, val)}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Select
                    options={priceTypeOptions}
                    id={constant.INPUT_ID_TYPE}
                    name={constant.SHIPPING_TYPE}
                    label={cms("modal.rule.add.label.selectShippingType")}
                    placeholder={cms("modal.rule.add.placeholder.selectShipping")}
                    value={priceType || ""}
                    disabled={isFieldDisabled}
                    onChange={(val) => handleShippingRule(constant.PRICE_TYPE, val)}
                  />
                </Stack.Item>
                {priceType === constant.FIXED && (
                  <Stack.Item>
                    <TextField
                      id={constant.INPUT_ID_COST}
                      type={constant.NUMBER}
                      label={cms("modal.rule.add.label.amount")}
                      min={constant.value.MIN_PRICE}
                      step={constant.value.STEP}
                      value={price.toString() || ""}
                      disabled={isFieldDisabled}
                      onChange={(val) => handleShippingRule(constant.PRICE, Number(val))}
                      prefix={moneyFormat}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                checked={isShippingDisabled}
                onChange={() => handleShippingRule(constant.IS_SHIPPING_DISABLED, !isShippingDisabled)}
                label={cms("modal.rule.add.label.isAvailableForShipping")}
              />
            </Stack.Item>
          </Stack>
        </form>
      </Modal.Section>
    </Modal>
  );
};

AddRule.propTypes = addRuleProp.type;

export default AddRule;

import React from "react";

// import constant
import constant from "lib/constant/constant";

// import components
import { AdminPaymentList } from "./admin/features";
import { OperatorPaymentList } from "./operator/features";
import { ProviderPaymentList } from "./provider/features";

// storage helpers
import { storageHelper } from "../../../lib/helpers";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const PaymentList = () => {
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminPaymentList />;
    }
    if (role === operator) {
      return <OperatorPaymentList />;
    }
    if (role === provider) {
      return <ProviderPaymentList />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const PaymentListComponent = getComponent(currentUserRole);
  return PaymentListComponent;
};

export default PaymentList;

import { gql } from "apollo-boost";

const PAYMENT_LIST = gql`
  subscription {
    paymentListView {
      status
      error
      data {
        operationType
        paymentData {
          _id
          paidVia
          isShippingPaid
          accountingStatus
          commissionSeller
          commissionSysAdmin
          commissionVendor
          createdAt
          dueDate
          moneyFormat
          fulfillmentStatus
          orderId
          orderNumber
          shippingAmount
          status
          vendor
          vendorId
        }
      }
    }
  }
`;
export default PAYMENT_LIST;

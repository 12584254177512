import React, { useState, useContext, useEffect } from "react";
import { Badge, Card, Heading, Icon, Layout, Stack, TextContainer, Collapsible } from "@shopify/polaris";
import { CircleDisabledMajorMonotone, CircleTickMajorMonotone } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { PrivateContext } from "lib/context";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";

import { withFeature, withErrorBoundary } from "lib/hoc";

import { Banner, Spinner as Loader } from "lib/components";
import baseHelper from "lib/helpers/base";
import constant from "lib/constant/constant";
import { NotFound } from "layout/private/components";

import { getOrderView, getOrderLineView } from "app/orders/apollo/viewOrder";

import { GET_ASSOCIATED_SELLER, GET_SELLER_FULFILLMENT } from "app/setup/apollo";
import { GENERATE_SHIPPING_LABEL } from "app/orders/apollo/mutations";
import { DOWNLOAD_LABEL } from "app/orders/apollo/queries";
import { GET_CUSTOMER } from "../../../../apollo/queries";

import {
  VendorFulfillments,
  Pickup,
  UnfulfilledLineItems,
  FulfilledLinesItems,
  Fulfillments,
  FulfillmentStatus,
  Notes,
  Customer,
} from "./features";

const { gql, HIDE, SHOW } = constant;

const downloadLabelId = "download-shipping-Label";
const generateLabelId = "shipping-Label";

const ProviderOrderView = () => {
  const { t } = useTranslation();
  const { currentUser, cms } = useContext(PrivateContext);
  const { introductorySeller: currentUserId, _id } = currentUser;

  const [viewOrderData, setViewOrderData] = useState(false);
  const [viewLineOrderData, setViewLineOrderData] = useState(false);
  const [isError, setIsError] = useState(false);
  const [banner, setBanner] = useState({
    title: "",
    status: "",
    isOpen: false,
    onDismiss: null,
  });

  const [isConsignmentValue, setConsignmentValue] = useState(false);

  const { setPageData, match, history } = useContext(PrivateContext);
  const [toggleStatus, setToggleStatus] = useState({
    unfulfilledLineItems: {
      active: true,
      toggleText: HIDE,
    },
    fullFillmentStatus: {
      active: true,
      toggleText: HIDE,
    },
    fulFilledLineItems: {
      active: true,
      toggleText: HIDE,
    },
    fulFillments: {
      active: true,
      toggleText: HIDE,
    },
    vendorFulfillments: {
      active: false,
      toggleText: SHOW,
    },
    pickup: {
      active: false,
      toggleText: SHOW,
    },
  });
  const {
    loading: loadingOrderLineView,
    error: errorOrderLineView,
    data: dataOrderLineView,
    refetch: refetchOrderLineView,
  } = useQuery(getOrderLineView, {
    variables: { input: { orderId: match.params.id } },
  });

  const { loading: loadingOrderView, error: errorOrderView, data: dataOrderView, refetch: refetchOrderView } = useQuery(
    getOrderView,
    {
      variables: { input: { id: match.params.id } },
    }
  );

  const { data: fulfillmentData } = useQuery(GET_SELLER_FULFILLMENT, {
    variables: {
      input: { ownerId: currentUserId },
    },
  });

  const { data: sellerData } = useQuery(GET_ASSOCIATED_SELLER, {
    variables: {
      input: {
        id: baseHelper.mongoIdAsString(_id),
      },
    },
  });

  const { data: customerData } = useQuery(GET_CUSTOMER, {
    variables: {
      input: { sellerId: currentUserId },
    },
  });

  const fulfillmentView = baseHelper.getResponseData(fulfillmentData, gql.GET_SELLER_FULFILLMENT) || {};

  const sellerView = baseHelper.getResponseData(sellerData, gql.GET_ASSOCIATED_SELLER) || {};
  fulfillmentView.seller = sellerView;
  const customerView = baseHelper.getResponseData(customerData, gql.GET_CUSTOMER);

  useEffect(() => {
    if (dataOrderLineView && dataOrderView) {
      const orderLineView = baseHelper.getResponseData(dataOrderLineView, gql.GET_ORDER_LINE_VIEW);
      const orderView = baseHelper.getResponseData(dataOrderView, gql.GET_ORDER_VIEW);
      const orderLineViewError = baseHelper.getResponseError(dataOrderLineView, gql.GET_ORDER_LINE_VIEW);
      const orderViewError = baseHelper.getResponseError(dataOrderView, gql.GET_ORDER_VIEW);

      if (orderLineView && orderView) {
        setViewOrderData(orderView);
        setViewLineOrderData(orderLineView);
      }
      if (orderLineViewError && orderViewError) {
        setIsError(true);
      }
    }
  }, [dataOrderLineView, dataOrderView]);

  useEffect(() => {
    if (viewOrderData && viewLineOrderData) {
      let isDisabled = true;
      let isConsignmentValueExist = true;
      const { orderNumber = "", createdAt = "" } = viewLineOrderData.length && viewLineOrderData[0];
      viewLineOrderData.map((lineItem) => {
        if (lineItem.status === constant.ACCEPTED) {
          isDisabled = false;
        }
        if (!lineItem.consignmentId) {
          isConsignmentValueExist = false;
        }
        return null;
      });
      setConsignmentValue(isConsignmentValueExist);
      setPageData({
        title: `#${orderNumber}`,
        breadcrumbs: [{ content: t("pageTitle.dashboard"), url: "/" }],
        subtitle: baseHelper.formatDate(createdAt),
      });
    }
  }, [viewOrderData, viewLineOrderData, t, history, match.params.id, setPageData]);

  useEffect(() => {
    if (errorOrderView || errorOrderLineView) {
      setBanner({
        status: constant.CRITICAL,
        title: `${t("error.common.somethingWentWrong")}`,
        isOpen: true,
      });
    }
  }, [errorOrderView, errorOrderLineView, t]);

  const handleToggle = (evt) => {
    const { id } = evt.currentTarget;
    setToggleStatus((newToggleStatus) => ({
      ...newToggleStatus,
      [id]: {
        active: !newToggleStatus[id].active,
        toggleText: newToggleStatus[id].active ? t("label.show") : t("label.hide"),
      },
    }));
  };

  const [generateShippingLabel, { loading: shippingLabelLoading }] = useMutation(GENERATE_SHIPPING_LABEL);
  useEffect(() => {
    if (shippingLabelLoading) {
      setBanner({
        status: constant.SUCCESS,
        title: cms("message.requestProcess"),
        isOpen: true,
      });
    }
  }, [shippingLabelLoading]);

  const [downloadLabel, { loading: downloadLabelLoading, data: downloadLabelData }] = useLazyQuery(DOWNLOAD_LABEL);

  const { dropship = {} } = fulfillmentView || {};
  const { isThirdPartyShipping = false } = dropship || {};

  useEffect(() => {
    if (downloadLabelLoading) {
      setBanner({
        isOpen: true,
        title: cms("message.requestProcess"),
        status: constant.SUCCESS,
      });
    }
    if (!downloadLabelLoading && downloadLabelData) {
      const responseData = baseHelper.getResponseData(downloadLabelData, gql.DOWNLOAD_SHIPPING_LABEL);
      let bannerData = {
        title: cms("message.success.downloadSuccess"),
        status: constant.SUCCESS,
        isOpen: true,
      };
      if (!responseData) {
        bannerData = {
          title:
            baseHelper.getResponseError(downloadLabelData, gql.DOWNLOAD_SHIPPING_LABEL) ||
            t("error.common.somethingWentWrong"),
          status: constant.CRITICAL,
          isOpen: true,
        };
        setBanner(bannerData);
        return;
      }
      
      const { 
        clientId          = "",
        consignmentId     = "",
        consignmentStatus = "",
        pdf               = {},
        shippingOption    = "",
        token             = "",
      } = responseData || {};

      if (shippingOption === constant.NZPOST) {
        if (!consignmentStatus) {
          bannerData = {
            title: cms("message.error.labelNotGenerated"),
            status: constant.WARNING,
            isOpen: true,
          };
          setBanner(bannerData);
          return;
        }
  
        if (consignmentStatus === constant.PROCESSING) {
          bannerData = {
            title: cms("message.error.labelUnderProcess"),
            status: constant.WARNING,
            isOpen: true,
          };
          setBanner(bannerData);
          return;
        }
        // TODO: needs to improvised latter as no other solution found;
        const shippingUrl = baseHelper.getNewZeaLandPostAPIUrl(consignmentId);
        fetch(shippingUrl, {
          headers: {
            Authorization: token,
            client_id: clientId,
          },
        })
          .then((res) => {
            if (res && !res.ok) {
              return false;
            }
            return res.blob();
          })
          .then((result) => {
            if (!result) {
              bannerData = {
                title: cms("message.error.unableToDownloadPdf"),
                status: constant.CRITICAL,
                isOpen: true,
              };
              setBanner(bannerData);
              return;
            }
            const url = window.URL.createObjectURL(result);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = `vendor-${consignmentId}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
            setBanner(bannerData);
          });
      } else {
        const buffer = new Buffer(pdf);
        const newBlob = new Blob([buffer], { type: 'application/pdf' });  
        const url = window.URL.createObjectURL(newBlob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = `vendor-${consignmentId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        setBanner(bannerData);
      }
    }
  }, [downloadLabelLoading, downloadLabelData, t, cms]);

  const renderHeading = (title, count, fulfilled = false) => {
    return (
      <Stack alignment="center" wrap>
        <Stack.Item>
          <Icon
            source={fulfilled ? CircleTickMajorMonotone : CircleDisabledMajorMonotone}
            backdrop
            color={fulfilled ? constant.GREENDARK : constant.YELLOWDARK}
          />
        </Stack.Item>
        <Stack.Item>
          <TextContainer spacing="tight">
            <Heading>{title}</Heading>
          </TextContainer>
        </Stack.Item>
        {count && (
          <Stack.Item>
            <Badge status={fulfilled ? constant.SUCCESS : constant.ATTENTION}>{count}</Badge>
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const renderItemData = (dataOrderViews, dataOrderLineViews) => {
    const orderLineItemsData = dataOrderLineViews;
    const { financialStatus } = dataOrderViews || {};
    if (!orderLineItemsData)
      return (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      );

    let unfulfillCount = constant.value.ZERO;
    let fulfillCount = constant.value.ZERO;
    let consignmentShipCount = constant.value.ZERO;
    let pickUpCount = constant.value.ZERO;
    if (orderLineItemsData) {
      orderLineItemsData.map((lineItem) => {
        if (lineItem.fulfillmentStatus === constant.UNFULFILLED) {
          unfulfillCount += constant.value.ONE;
        } else {
          fulfillCount += constant.value.ONE;
        }
        return null;
      });

      pickUpCount = orderLineItemsData.filter(
        (lineItem) =>
          lineItem.isVendorFulfilled && lineItem.fulfillmentStatus !== constant.FULFILLED && lineItem.isConsignmentPick
      ).length;

      consignmentShipCount = orderLineItemsData.filter(
        (lineItem) =>
          lineItem.isVendorFulfilled && lineItem.fulfillmentStatus !== constant.FULFILLED && !lineItem.isConsignmentPick
      ).length;
    }

    const createShippingLabel = async () => {
      const { params = {} } = match;
      const { id: orderId } = params;
      const response = await generateShippingLabel({ variables: { input: { orderId } } });
      const shippingLabelResponse = baseHelper.getResponseData(response.data, gql.GENERATE_SHIPPING_LABEL);
      const shippingLabelError = baseHelper.getResponseError(response.data, gql.GENERATE_SHIPPING_LABEL);
      if (shippingLabelResponse) {
        refetchOrderLineView();
      }

      if (shippingLabelError) {
        const bannerData = {
          isOpen: true,
          title:
            baseHelper.getResponseError(response.data, gql.GENERATE_SHIPPING_LABEL) ||
            t("error.common.somethingWentWrong"),
          status: constant.CRITICAL,
        };
        setBanner(bannerData);
      }
    };

    const downloadShippingLabel = async () => {
      const { params = {} } = match;
      const { id: orderId } = params;
      downloadLabel({ variables: { input: { orderId } } });
    };

    const actionButtons = [
      {
        content: toggleStatus.unfulfilledLineItems.toggleText,
        onClick: handleToggle,
        disclosure: true,
        id: constant.UNFULFILLEDLINEITEMS,
      },
    ];

    const fulfilledActionButton = [
      {
        content: toggleStatus.fulFilledLineItems.toggleText,
        onClick: handleToggle,
        disclosure: true,
        id: constant.FULFILLEDLINEITEMS,
      },
    ];

    if (isThirdPartyShipping && !isConsignmentValue) {
      const shippingLabel = {
        content: cms("label.shippingLabel"),
        onClick: () => createShippingLabel(),
        id: generateLabelId,
      };

      if (unfulfillCount) {
        actionButtons.unshift(shippingLabel);
      }

      if (fulfillCount) {
        fulfilledActionButton.unshift(shippingLabel);
      }
    }

    if (isThirdPartyShipping && isConsignmentValue) {
      const shippingLabel = {
        content: "Download Shipping Label",
        onClick: () => downloadShippingLabel(),
        id: downloadLabelId,
      };
      if (unfulfillCount) {
        actionButtons.unshift(shippingLabel);
      }

      if (fulfillCount) {
        fulfilledActionButton.unshift(shippingLabel);
      }
    }

    return (
      <>
        {(unfulfillCount && (
          <Card title={renderHeading(baseHelper.ucFirst(constant.UNFULFILLED), unfulfillCount)} actions={actionButtons}>
            <br />
            <Collapsible id={constant.UNFULFILLEDLINEITEMS} open={toggleStatus.unfulfilledLineItems.active}>
              <UnfulfilledLineItems
                itemData={orderLineItemsData}
                onRefetchOrderLineView={refetchOrderLineView}
                onRefetchOrderView={refetchOrderView}
                setBanner={setBanner}
                fulfillment={fulfillmentView}
                data={dataOrderViews}
                setConsignmentValue={setConsignmentValue}
              />
            </Collapsible>
          </Card>
        )) ||
          null}
        {(fulfillCount && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.FULFILLED), fulfillCount, true)}
            actions={fulfilledActionButton}
          >
            <br />
            <Collapsible id={constant.FULFILLEDLINEITEMS} open={toggleStatus.fulFilledLineItems.active}>
              <FulfilledLinesItems lineData={orderLineItemsData} data={dataOrderViews} />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(fulfillCount && (
          <Card
            title={renderHeading(baseHelper.ucFirst(constant.FULFILLMENTS), constant.value.ZERO, true)}
            actions={[
              {
                content: toggleStatus.fulFillments.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: "fulFillments",
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULFILLMENTS} open={toggleStatus.fulFillments.active}>
              <Fulfillments lineData={orderLineItemsData} />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(pickUpCount && (
          <Card
            title={renderHeading(cms("label.readyToCollect"), pickUpCount, true)}
            actions={[
              {
                content: toggleStatus.pickup.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: cms("label.pickup"),
              },
            ]}
          >
            <br />
            <Collapsible id={cms("label.pickup")} open={toggleStatus.pickup.active}>
              <Pickup lineData={orderLineItemsData} />
            </Collapsible>
          </Card>
        )) ||
          null}

        {(consignmentShipCount && (
          <Card
            title={renderHeading(cms("label.consignmentShip"), consignmentShipCount, true)}
            actions={[
              {
                content: toggleStatus.vendorFulfillments.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: cms("label.vendorFulfillments"),
              },
            ]}
          >
            <br />
            <Collapsible id={cms("label.vendorFulfillments")} open={toggleStatus.vendorFulfillments.active}>
              <VendorFulfillments lineData={orderLineItemsData} />
            </Collapsible>
          </Card>
        )) ||
          null}

        {dataOrderViews && (
          <Card
            title={renderHeading(
              (financialStatus && `${t("label.paid")}`) || t("label.unpaid"),
              constant.value.ZERO,
              true
            )}
            actions={[
              {
                content: toggleStatus.fullFillmentStatus.toggleText,
                onClick: handleToggle,
                disclosure: true,
                id: constant.FULLFILLMENTSTATUS,
              },
            ]}
          >
            <br />
            <Collapsible id={constant.FULLFILLMENTSTATUS} open={toggleStatus.fullFillmentStatus.active}>
              <FulfillmentStatus lineData={orderLineItemsData} data={dataOrderViews} />
            </Collapsible>
          </Card>
        )}
      </>
    );
  };
  let isNote = true;
  const getSecondaryComponent = (dataOrderViews) => {
    const { note } = dataOrderViews || {};
    if (!note) {
      isNote = false;
    }
    return (
      <>
        {dataOrderViews && (
          <>
            <Notes
              note={(isNote && note) || ""}
              dataItem={dataOrderViews}
              setBanner={setBanner}
              onRefetchOrderView={refetchOrderView}
            />
            <Customer
              data={dataOrderViews}
              fulfillment={fulfillmentView}
              currentUser={currentUser}
              customerData={customerView}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner
            isOpen={banner.isOpen}
            status={banner.status}
            title={banner.title}
            onDismiss={() => {
              setBanner({
                isOpen: false,
                title: "",
                status: "",
              });
            }}
          />
        </Layout.Section>
      )}
      {(loadingOrderView || loadingOrderLineView) && (
        <Layout.Section>
          <Loader />
        </Layout.Section>
      )}
      <Layout.Section>
        {isError && (
          <Layout.Section>
            <div style={{ display: "flex" }}>
              <NotFound />
            </div>
          </Layout.Section>
        )}

        {viewOrderData && viewLineOrderData && renderItemData(viewOrderData, viewLineOrderData)}
      </Layout.Section>
      <Layout.Section secondary>{getSecondaryComponent(viewOrderData)}</Layout.Section>
    </>
  );
};
export default withFeature(withErrorBoundary(ProviderOrderView), { feature: constant.VIEW_ORDER });

import React, { useContext, useState, useEffect, useCallback } from "react";
import { Layout, Card, Link } from "@shopify/polaris";
import { useQuery, useMutation } from "react-apollo";
import { PublicContext } from "lib/context";
import { baseHelper, errorHelper, storageHelper } from "lib/helpers";
import { Banner, Spinner } from "lib/components";

import { FETCH_PLANS } from "app/plans/apollo/queries";
import { SET_SHOPIFY_DETAILS, SELECT_PLAN } from "app/plans/apollo/mutations";
import Plan from "../generic/plan/plan";

const OperatorSelectPlan = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [isFreePlanBanner, setIsFreePlanBanner] = useState(true);

  const updateErrorMessage = (res, key) => {
    const err = baseHelper.getResponseError(res, key);
    if (!err) {
      return;
    }
    setErrorMessage(err);
  };

  const { loading: planLoading, data: planData, error: planError } = useQuery(FETCH_PLANS);
  const [setShopifyDetail, { loading: shopifyDetailLoading }] = useMutation(SET_SHOPIFY_DETAILS);
  const [selectPlan] = useMutation(SELECT_PLAN);

  const { history, oldCMS: cms, isLoading } = useContext(PublicContext);
  const { shop, code: shopifyCode } = baseHelper.getQueryParams(history.location.search);

  const setURL = (url) => {
    window.top.location = url;
  };

  const onShopifyDetailCompleted = useCallback(
    (res) => {
      const responseData = baseHelper.getResponseData(res.data, "shopifyDetail");

      if (!(responseData && responseData.token)) {
        return;
      }
      storageHelper.set("token", responseData.token);
      const route = "/";
      if (typeof window !== "undefined") {
        setURL(baseHelper.shopifyUrl(shop, route));
      }
      history.push(route);
      updateErrorMessage(res.data, "shopifyDetail");
    },
    [history, shop]
  );

  // will work as componentDidMount
  useEffect(() => {
    storageHelper.set("shop", shop);
    const shopifyDetailInput = {
      code: shopifyCode,
      shop,
    };
    setShopifyDetail({
      variables: { input: shopifyDetailInput },
    })
      .then(onShopifyDetailCompleted)
      .catch((exception) => setErrorMessage(errorHelper.parse(exception)));
      // .catch(() => setErrorMessage("Something went wrong, Please try again."));
  }, [onShopifyDetailCompleted, setShopifyDetail, shop, shopifyCode]);

  const onSubmit = (plan) => {
    setSelectedPlan(plan.code);
    const { monthlyFee, name, code, _id: planId } = plan;
    const selectPlanInput = {
      shop,
      name,
      planId,
      code,
      price: monthlyFee,
    };
    selectPlan({ variables: { input: selectPlanInput } })
      .then((res) => {
        setSelectedPlan("");
        const responseData = baseHelper.getResponseData(res.data, "selectPlan");
        if (responseData && responseData.token && (responseData.price || responseData.price === 0)) {
          storageHelper.set("token", responseData.token);
          storageHelper.set("shop", responseData.shop);
          storageHelper.set("isInitial", true);
          const route = "/";
          setURL(baseHelper.shopifyUrl(shop, route));
        }
        if (responseData && responseData.confirmation_url) {
          const isIframe = baseHelper.isIframe();
          if (isIframe) {
            setURL(baseHelper.shopifyUrl(shop, responseData.confirmation_url));
          }
          window.location.href = responseData.confirmation_url;
        }
        updateErrorMessage(res.data, "selectPlan");
      })
      .catch((exception) => {
        setErrorMessage(errorHelper.parse(exception));
        // setErrorMessage("Something went wrong, Please try again.");
      });
  };

  if (shopifyDetailLoading || planLoading || isLoading) {
    return <Spinner isFullPage />;
  }

  if (planError) {
    setErrorMessage(errorHelper.parse(planError));
    // setErrorMessage("Something went wrong, Please try again.");
  }
  updateErrorMessage(planData, "getPlans");

  const getSortedPlans = (plans, key) => {
    return (plans || []).sort((first, second) => first[key] - second[key]);
  };

  const getPlans = () => {
    const plans = baseHelper.getResponseData(planData, "getPlans");
    return getSortedPlans(plans, "sortOrder");
  };

  const renderBanner = () => {
    return (
      <Layout>
        <Layout.Section>
          <Banner title={errorMessage} status="critical" />
        </Layout.Section>
        <Layout.Section>
          <Card>
            <Card.Section>
              <h3>
                Click
                <Link onClick={() => setURL(baseHelper.shopifyUrl(shop))}>here</Link>
                to go to plan page
              </h3>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    );
  };

  if (errorMessage) {
    renderBanner();
  }

  return (
    <Plan
      isFreePlanMsg={isFreePlanBanner}
      cms={cms}
      plans={getPlans()}
      selectedPlan={selectedPlan}
      onSelect={onSubmit}
    />
  );
};

export default OperatorSelectPlan;

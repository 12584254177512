import React, { useContext } from "react";
import { Layout, Stack, Button, DisplayText } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

import html2canvas from "html2canvas";
import * as JsPDF from "jspdf";

import { PrivateContext } from "lib/context";

import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";

require("jspdf-autotable");

const OrderSlipView = (props) => {
  const { sellerInfo, orderData, logo, orderLineItems, fulfillment = {} } = props;
  const { t } = useTranslation();
  const { currentUser } = useContext(PrivateContext);
  const marketCube = t("label.operatorPackingSlip.marketCube");
  const currentUserAddress = baseHelper.getFullAddress(currentUser);
  const { type = "" } = fulfillment;

  const fulfillmentData =
    fulfillment && type === constant.CONSIGNMENT && baseHelper.fulfillmentDetails(fulfillment, currentUser);

  const pdfToHTML = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new JsPDF("pt");
      pdf.addImage(imgData, "PNG", 5, 5, 200, 287);
      pdf.save(`${orderData.shopifyOrderId}.pdf`);
    });
  };

  const getShippingAddress = (dataShipping) => {
    const {
      name = "",
      phone = "",
      address1 = "",
      address2 = "",
      city = "",
      province = "",
      country = "",
      zip = "",
    } = dataShipping;
    return (
      <>
        {name}
        {name ? <br /> : ""}
        {phone}
        {phone ? <br /> : ""}
        {address1}
        {address2}
        {address1 || address2 ? <br /> : ""}
        {city}
        {city ? <br /> : ""}
        {province}
        {country}
        {zip}
      </>
    );
  };

  const getVendorShippingAddress = (data, users) => {
    const { address = "", city = "", pincode = "", country = "" } = data || {};
    const { firstName = "", lastName = "", phoneNumber = "" } = users || {};
    return (
      <>
        {firstName} {lastName}
        {firstName || lastName ? <br /> : ""}
        {phoneNumber}
        {phoneNumber ? <br /> : ""}
        {address}
        {address ? <br /> : ""}
        {city}
        {city ? <br /> : ""}
        {country}
        {country ? <br /> : ""}
        {pincode}
      </>
    );
  };

  const productsList = (isSku = false) => {
    const productList = orderLineItems.map((item) => {
      const unId = Date.now() + Math.random();
      const totalPrice = parseFloat(item.quantity) * parseFloat(item.price);
      return (
        <tr className="item" key={`${item.shopifyLineItemId}_${unId}`}>
          <td>{item.name}</td>
          {isSku && <td>{item.sku}</td>}
          <td>{item.quantity}</td>
          <td>{item.price}</td>
          <td>{`${orderData.moneyFormat} ${baseHelper.getPrice(totalPrice)}`}</td>
        </tr>
      );
    });
    return productList;
  };

  const getSenderName = () => {
    const isSeller = baseHelper.isSeller(currentUser);
    const isVendor = baseHelper.isVendor(currentUser);
    if (isSeller) {
      return currentUser.brandName || currentUser.shop;
    }
    if (isVendor) {
      return currentUser.brandName;
    }
    return marketCube;
  };

  const checkSku = (lineData = []) => {
    let isSku = false;
    lineData.forEach((data) => {
      if (data.sku && data.sku !== "NaN") {
        isSku = true;
      }
    });
    return isSku;
  };

  const isSku = checkSku(orderLineItems || []);
  const isSeller = baseHelper.isSeller(currentUser);
  const isVendor = baseHelper.isVendor(currentUser);
  const isTaxIncluded = !!orderData.taxesIncluded;
  const totals = baseHelper.getOrderPrice(orderLineItems || [], orderData, isSeller);
  const { isUpdatedOrderLine } = totals;

  return (
    <>
      <Layout.Section>
        <Stack>
          <Stack.Item>
            <Button primary onClick={pdfToHTML}>
              {t("label.operatorPackingSlip.downloadPDF")}
            </Button>
          </Stack.Item>
        </Stack>
      </Layout.Section>
      <Layout.Section>
        <Stack>
          <Stack.Item>
            <div id="divToPrint" className="order-slip-box">
              <Table responsive striped={false}>
                <tbody>
                  <tr className="top">
                    <td colSpan="5">
                      <table>
                        <tbody>
                          <tr>
                            <td className="title text-capitalize">
                              {(logo && (
                                <img
                                  src={logo}
                                  id="tableBanner"
                                  style={{ width: "200px", maxHeight: "100px" }}
                                  alt="Brand Logo"
                                />
                              )) || <DisplayText size="medium">{sellerInfo.brandName}</DisplayText>}
                            </td>
                            <td>
                              {`${t("label.operatorPackingSlip.packingId")} ${orderData.orderNumber}-${
                                orderData.shopifyOrderId
                              }`}
                              <br />
                              {`${t("label.operatorPackingSlip.created")} ${baseHelper.formatDate(
                                orderData.createdAt
                              )}`}
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr className="information">
                    <td colSpan="5">
                      <table>
                        <tbody>
                          <tr>
                            <td className="text-capitalize">
                              <b>{t("label.operatorPackingSlip.shippingAddress")}</b>
                              <br />
                              {(isVendor &&
                                type === constant.CONSIGNMENT &&
                                getVendorShippingAddress(fulfillmentData, sellerInfo)) ||
                                getShippingAddress((orderData && orderData.shippingAddress) || {})}
                              <br />
                            </td>
                            <td className="text-capitalize">
                              {`${t("label.operatorPackingSlip.orderId")}${orderData.orderNumber}`}
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr className="heading">
                    <td>{t("label.operatorPackingSlip.product")}</td>
                    {isSku && <td>{t("label.operatorPackingSlip.sku")}</td>}
                    <td>{t("label.operatorPackingSlip.qty")}</td>
                    <td>{t("label.operatorPackingSlip.price")}</td>
                    <td>{t("label.operatorPackingSlip.total")}</td>
                  </tr>
                  {productsList(isSku)}
                  <tr className="item">
                    <td />
                    {isSku && <td />}
                    <td />
                    <td>
                      <b>{t("label.operatorPackingSlip.subTotal")}</b>
                    </td>
                    <td>{`${orderData.moneyFormat} ${baseHelper.getPrice(totals.price)}`}</td>
                  </tr>
                  {isSeller && isUpdatedOrderLine && totals.markUp ? (
                    <tr className="item">
                      <td />
                      {isSku && <td />}
                      <td />
                      <td>
                        <b>{t("label.operatorPackingSlip.markUpTotal")}</b>
                      </td>
                      <td>{`${orderData.moneyFormat} ${baseHelper.getPrice(totals.markUp)}`}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr className="item">
                    <td />
                    {isSku && <td />}
                    <td />
                    <td>
                      <b>{t("label.operatorPackingSlip.shipping")}</b>
                    </td>
                    <td>{`${orderData.moneyFormat} ${baseHelper.getPrice(totals.shippingAmount)}`}</td>
                  </tr>
                  <tr className="item">
                    <td />
                    {isSku && <td />}
                    <td />
                    <td>
                      <b>{t("label.operatorPackingSlip.discount")}</b>
                    </td>
                    <td>
                      {`${orderData.moneyFormat} - ${
                        (isUpdatedOrderLine && isSeller && baseHelper.getPrice(totals.sellerDiscount)) ||
                        baseHelper.getPrice(totals.discount)
                      }`}
                    </td>
                  </tr>
                  <tr className="item">
                    <td />
                    {isSku && <td />}
                    <td />
                    <td>
                      <b>
                        {t("label.operatorPackingSlip.totalTax")}
                        {isTaxIncluded ? t("label.operatorPackingSlip.included") : ""}
                      </b>
                    </td>
                    <td>
                      {`${orderData.moneyFormat} ${
                        !isSeller && isUpdatedOrderLine
                          ? baseHelper.getPrice(totals.tax)
                          : baseHelper.getPrice(totals.tax)
                      }`}
                    </td>
                  </tr>
                  <tr className="total">
                    <td />
                    {isSku && <td />}
                    <td />
                    <td>
                      <b>{t("label.operatorPackingSlip.total")}</b>
                    </td>
                    <td>{`${orderData.moneyFormat} ${baseHelper.getPrice(totals.totalPrice)}`}</td>
                  </tr>

                  <tr className="information">
                    <td colSpan="4">
                      <table>
                        <tbody>
                          <tr>
                            <td className="text-capitalize">
                              <b>{t("label.operatorPackingSlip.shippedFrom")}</b>
                              <br />
                              {getSenderName()}
                              <br />
                              {currentUserAddress.addressFormatted}
                              <br />
                              {currentUserAddress.city}
                              <br />
                              {currentUserAddress.country}
                              {currentUserAddress.pinCode ? `, ${currentUserAddress.pinCode}` : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Stack.Item>
        </Stack>
      </Layout.Section>
    </>
  );
};

OrderSlipView.defaultProps = {
  sellerInfo: {},
  orderData: {},
  logo: "",
  orderLineItems: [],
};
OrderSlipView.propTypes = {
  sellerInfo: PropTypes.shape(PropTypes.object),
  orderData: PropTypes.shape(PropTypes.object),

  logo: PropTypes.string,
  orderLineItems: PropTypes.arrayOf(PropTypes.object),
};

export default OrderSlipView;

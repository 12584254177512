import React, { useState, useEffect, useContext, useCallback } from "react";
import { Card, Stack, TextStyle, Caption, Badge, Layout, SkeletonBodyText, Collapsible } from "@shopify/polaris";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

// context
import { PrivateContext } from "lib/context";

import { baseHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { Banner } from "lib/components";

import { GET_ALL_COUNTS } from "../../../apollo/queries";
import { ORDER_DATA, PRODUCT_DATA, PROVIDER_DATA, INVITE_DATA } from "../../../apollo/subscriptions";

import config from "./quickReportConfig";

const {
  gql: { GET_COUNT, ORDER_COUNT, VENDOR_COUNT, PRODUCT_COUNT, INVITE_COUNT },
} = constant;

const OperatorQuickReport = () => {
  const { match } = useContext(PrivateContext);
  const { path } = match || {};
  const { t } = useTranslation();
  const { data: dashboardData, loading: dashboardLoading, error: dashboardError, subscribeToMore } = useQuery(
    GET_ALL_COUNTS
  );

  const [openQuickReport, setQuickReport] = useState(false);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });
  const [count, setCount] = useState({
    inviteCount: {},
    orderCount: {},
    paymentCount: {},
    productCount: {},
    vendorCount: {},
  });
  useEffect(() => {
    if (dashboardError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: t("error.common.somethingWentWrong"),
      });
    }
  }, [dashboardError, t]);
  useEffect(() => {
    const responseData = baseHelper.getResponseData(dashboardData, GET_COUNT);
    const responseError = baseHelper.getResponseError(dashboardData, GET_COUNT);
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
      return;
    }
    if (!responseData) {
      return;
    }
    const {
      inviteCount = null,
      orderCount = null,
      paymentCount = null,
      productCount = null,
      vendorCount = null,
    } = responseData;
    setCount({
      inviteCount: inviteCount || {},
      orderCount: orderCount || {},
      paymentCount: paymentCount || {},
      productCount: productCount || {},
      vendorCount: vendorCount || {},
    });
  }, [dashboardData]);

  const createSubscribeToMore = useCallback(
    ({ document, responseKey }) => {
      subscribeToMore({
        document,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }
          const responseData = baseHelper.getResponseData(subscriptionData.data, responseKey);
          if (!responseData) {
            const responseError = baseHelper.getResponseError(subscriptionData.data, responseKey);
            setBanner({
              isOpen: true,
              status: "critical",
              title: responseError,
            });
            return null;
          }
          setCount((prevState) => ({
            ...prevState,
            [responseKey]: responseData[responseKey] || {},
          }));
          return true;
        },
      });
    },
    [subscribeToMore]
  );

  useEffect(() => {
    switch (path) {
      case "/":
        createSubscribeToMore({ document: ORDER_DATA, responseKey: ORDER_COUNT });
        createSubscribeToMore({ document: PROVIDER_DATA, responseKey: VENDOR_COUNT });
        createSubscribeToMore({ document: PRODUCT_DATA, responseKey: PRODUCT_COUNT });
        createSubscribeToMore({ document: INVITE_DATA, responseKey: INVITE_COUNT });
        break;
      case "/invitation":
        createSubscribeToMore({ document: INVITE_DATA, responseKey: INVITE_COUNT });
        break;
      case "/orders":
        createSubscribeToMore({ document: ORDER_DATA, responseKey: ORDER_COUNT });
        break;
      case "/products":
        createSubscribeToMore({ document: PRODUCT_DATA, responseKey: PRODUCT_COUNT });
        break;
      case "/providers":
        createSubscribeToMore({ document: PROVIDER_DATA, responseKey: VENDOR_COUNT });
        break;
      default:
        break;
    }
  }, [createSubscribeToMore, path]);

  // data taken from report config
  const quickReportData = config({ t, count, path });

  const onCloseBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };

  const noDataAvailable = (
    <Card.Section subdued key="noData">
      <Stack>
        <Stack.Item>No data available</Stack.Item>
      </Stack>
    </Card.Section>
  );

  const getQuickReportList = () => {
    if (!quickReportData) {
      return noDataAvailable;
    }
    const loading = <SkeletonBodyText lines={3} />;
    const dataStack = (quickReport) => (
      <Stack>
        <Stack.Item fill>
          <TextStyle>{quickReport.label}</TextStyle>
          <Caption>{quickReport.caption}</Caption>
        </Stack.Item>
        <Stack.Item>
          <Badge status="success">{quickReport.count}</Badge>
        </Stack.Item>
      </Stack>
    );
    const reportData = quickReportData.map((quickReport, index) => {
      const { count: reportCount = null } = quickReport;
      if (!reportCount && reportCount !== 0) {
        return null;
      }
      return (
        <div>
          {index > 0 && index !== quickReportData.length && <br />}
          {dashboardLoading ? loading : dataStack(quickReport)}
        </div>
      );
    });

    const filteredReportData = reportData.filter((data) => data);

    if (filteredReportData.length) {
      return filteredReportData;
    }

    return noDataAvailable;
  };

  return (
    <>
      {banner.isOpen && (
        <Layout.Section>
          <Banner isOpen={banner.isOpen} status={banner.status} title={banner.title} onDismiss={onCloseBanner} />
        </Layout.Section>
      )}
      <Card>
        <Card.Header
          title={t("quickReport.title")}
          actions={[
            {
              content: openQuickReport ? "hide" : "show",
              onAction: () => setQuickReport(!openQuickReport),
              disclosure: openQuickReport ? "up" : "down",
            },
          ]}
        />
        <br />
        <Collapsible open={openQuickReport} id="collapsibleQuickReport">
          <Card>
            <Card.Section subdued>{getQuickReportList()}</Card.Section>
          </Card>
        </Collapsible>
      </Card>
    </>
  );
};

export default OperatorQuickReport;

import React, { useContext } from "react";
import { FormLayout, Select, TextField } from "@shopify/polaris";
import PropsType from "prop-types";
import { useTranslation } from "react-i18next";

// import lib
import { baseHelper } from "lib/helpers";
import { PrivateContext } from "lib/context";

const AdvanceFilters = (props) => {
  const { dataToFetch, setDataToFetch, setTaggedWith, taggedWith, selectedFilter } = props;
  const { t } = useTranslation();
  const { history } = useContext(PrivateContext);

  const filterStatus = [
    { label: t("label.productList.Select"), value: "" },
    { label: t("label.productList.New"), value: t("label.productList.New").toLowerCase() },
    { label: t("label.productList.Review"), value: t("label.productList.Review").toLowerCase() },
    { label: t("label.productList.Approved"), value: t("label.productList.Approved").toLowerCase() },
    { label: t("label.productList.Rejected"), value: t("label.productList.Rejected").toLowerCase() },
    { label: "Deleted", value: "deleted" },
  ];

  const handleTaggedWithChange = (value) => {
    setTaggedWith(value);
    const listSearch = baseHelper.setQueryParams(history.location, { list_search: value });
    history.push(`${history.location.pathname}?${listSearch}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };

  return (
    <>
      <FormLayout>
        {(selectedFilter && selectedFilter !== t("label.productList.Status") && (
          <TextField label={t("label.productList.like")} value={taggedWith} onChange={handleTaggedWithChange} />
        )) ||
          (selectedFilter === t("label.productList.Status") && (
            <Select
              label={baseHelper.ucFirst(t("label.productList.Status"))}
              value={taggedWith}
              onChange={handleTaggedWithChange}
              options={filterStatus}
            />
          )) ||
          null}
      </FormLayout>
    </>
  );
};

const Filter = (props) => {
  const { dataToFetch, setDataToFetch, setTaggedWith, selectedFilter, setSelectedFilter } = props;
  const { t } = useTranslation();
  const { history, currentUser } = useContext(PrivateContext);

  const filterOptions = [
    { label: t("label.productList.Select"), value: "" },
    { label: t("label.productList.Title"), value: t("label.productList.Title").toLowerCase() },
    { label: t("label.productList.Vendor"), value: t("label.productList.Vendor").toLowerCase() },
    { label: t("label.productList.ProductStatus"), value: t("label.productList.Status") },
  ];

  const isVendor = baseHelper.isVendor(currentUser);

  if (isVendor) {
    delete filterOptions[2];
  }

  const handleSelectChange = (value) => {
    setSelectedFilter(value);
    let listFilter = baseHelper.setQueryParams(history.location, { list_filter: value });
    if (value !== t("label.productList.Status")) {
      setTaggedWith("");
      listFilter = baseHelper.setQueryParams(history.location, { list_filter: value, list_search: "" });
    }
    history.push(`${history.location.pathname}?${listFilter}`);
    const filterApplied = baseHelper.queryParamsFromLocation(history);
    setDataToFetch({ ...dataToFetch, ...filterApplied });
  };
  return (
    <Select
      label={t("label.productList.ShowAllProducts")}
      value={selectedFilter}
      onChange={handleSelectChange}
      options={filterOptions}
    />
  );
};

AdvanceFilters.defaultProps = {
  dataToFetch: {},
  setDataToFetch: () => {},
  setTaggedWith: () => {},
  taggedWith: "",
  selectedFilter: "",
};

AdvanceFilters.propTypes = {
  dataToFetch: PropsType.shape(PropsType.object),
  setDataToFetch: PropsType.func,
  setTaggedWith: PropsType.func,
  taggedWith: PropsType.string,
  selectedFilter: PropsType.string,
};

Filter.defaultProps = {
  dataToFetch: {},
  setDataToFetch: () => {},
  setTaggedWith: () => {},
  setSelectedFilter: () => {},
  selectedFilter: "",
};

Filter.propTypes = {
  dataToFetch: PropsType.shape(PropsType.object),
  setDataToFetch: PropsType.func,
  setTaggedWith: PropsType.func,
  setSelectedFilter: PropsType.func,
  selectedFilter: PropsType.string,
};

export { AdvanceFilters as default, Filter };

import React, { useContext } from "react";

import AdminQuickReport from "app/reports/admin/widgets/quickReport/quickReportCard";
import OperatorQuickReport from "app/reports/operator/widgets/quickReport/quickReport";
import ProviderQuickReport from "app/reports/provider/widgets/quickReport/quickReport";

import { storageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import { PrivateContext } from "lib/context";
import { SkeletonBodyText } from "@shopify/polaris";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const QuickReport = () => {
  const { isLoading } = useContext(PrivateContext);
  if (isLoading) return <SkeletonBodyText lines={3} />;
  const getComponent = (role) => {
    if (role === admin) {
      return <AdminQuickReport />;
    }
    if (role === operator) {
      return <OperatorQuickReport />;
    }
    if (role === provider) {
      return <ProviderQuickReport />;
    }
    return null;
  };

  const currentUserRole = storageHelper.get("userRole");
  const QuickReportComponent = getComponent(currentUserRole);
  return QuickReportComponent;
};

export default QuickReport;

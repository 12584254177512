import { GET_VENDOR_AUTH_CODE, GET_SELLER_PAYMENT } from "./connect";
import GET_COMMISSION from "./commission";
import GET_VENDOR_PRODUCT_TYPES from "./vendorProductType";
import GET_MARKUP from "./markup";
import GET_VENDOR_AND_PRODUCT_TYPES from "./advanceOption";
import GET_DISCOUNT from "./discount";
import { GET_MODULE, GET_ALL_USER_FOR_FEATURE, GET_FEATURE_BY_PARENT_ID } from "./feature";
import STANDARD_DISCOUNT from "./discountSetting";
import GET_USER from "./getUser";
import GET_ASSOCIATED_SELLER from "./getAssociatedSeller";
import GET_FULFILLMENT from "./fulfilment";
import GET_TERM from "./term";
import GET_SELLER_SETTING from "./sellerSetting";
import GET_SELLER_FULFILLMENT from "./sellerFulfillment"; 

export {
  GET_ALL_USER_FOR_FEATURE,
  GET_ASSOCIATED_SELLER,
  GET_COMMISSION,
  GET_DISCOUNT,
  GET_FEATURE_BY_PARENT_ID,
  GET_FULFILLMENT,
  GET_MARKUP,
  GET_MODULE,
  GET_SELLER_FULFILLMENT,
  GET_SELLER_PAYMENT,
  GET_SELLER_SETTING,
  GET_USER,
  GET_VENDOR_AND_PRODUCT_TYPES,
  GET_VENDOR_AUTH_CODE,
  GET_VENDOR_PRODUCT_TYPES,
  STANDARD_DISCOUNT,
  GET_TERM,
};

import { gql } from "apollo-boost";

const GET_VENDOR_AUTH_CODE = gql`
  query getVendorAuthCode($input: AuthCode) {
    getVendorAuthCode(input: $input) {
      status
      data {
        uri
      }
      error
    }
  }
`;

const GET_SELLER_PAYMENT = gql`
  query getSellerPayment {
    getSellerPayment {
      status
      data {
        _id
        paypal {
          emailId
        }
        paypalKey {
          clientId
        }
        stripe {
          accountId
        }
        stripeKey {
          clientId
        }
      }
      error
    }
  }
`;
export { GET_VENDOR_AUTH_CODE, GET_SELLER_PAYMENT };

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { PageActions } from "@shopify/polaris";

import { ProviderProductAdd } from "app/products/modules/provider/features";
import { OnboardingProvider, PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

const { onboardingSteps } = constant;

const AddProduct = (props) => {
  const { setSelectedTab } = props;
  const { currentUserRefetch = () => {} } = useContext(PrivateContext);

  const onNext = () => {
    setSelectedTab(onboardingSteps.FINISH);
  };

  const onPrevious = () => {
    setSelectedTab(onboardingSteps.UPDATE_PROFILE);
    currentUserRefetch();
  };

  return (
    <OnboardingProvider
      value={{
        isOnboarding: true,
        onNext,
        onPrevious,
      }}
    >
      <ProviderProductAdd />
    </OnboardingProvider>
  );
};

AddProduct.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
};

export default AddProduct;

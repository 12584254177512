import React from "react";
import { Card, Stack, TextStyle, DisplayText, Caption } from "@shopify/polaris";

const AdminPlanCard = () => (
  <Card title="Subscription Plan" actions={[{ content: "Upgrade" }]}>
    <Card.Section subdued>
      <Stack>
        <Stack.Item fill>
          <TextStyle>Growth</TextStyle>
          <Caption>Count of new products</Caption>
        </Stack.Item>
        <Stack.Item>
          <DisplayText>$79/m</DisplayText>
        </Stack.Item>
      </Stack>
    </Card.Section>
  </Card>
);

export default AdminPlanCard;

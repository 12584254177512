import {
  CONNECT_PAYPAL_VENDOR,
  CONNECT_STRIPE_VENDOR,
  DISCONNECT_PAYPAL_VENDOR,
  DISCONNECT_STRIPE_VENDOR,
} from "./connect";
import {
  CONNECT_STRIPE,
  DISCONNECT_STRIPE,
  CONNECT_PAYPAL_SELLER,
  DISCONNECT_PAYPAL,
  TOGGLE_AUTO_PAYOUT,
} from "./paymentService";
import { UPDATE_SELLER_SETTING } from "./sellerSetting";
import { UPDATE_TERM } from "./terms";
import UPLOAD_IMAGE from "./image";
import UPDATE_COMMISSION from "./commission";
import UPDATE_BRANDING from "./branding";
import STANDARD_DISCOUNT_SETTING from "./discountSetting";
import UPDATE_MARKUP from "./markup";
import UPDATE_DISCOUNT from "./discount";
import UPDATE_FEATURE from "./feature";
import UPDATE_FULFILLMENT from "./fulfillment";
import { GET_TERM } from "../queries";

export {
  CONNECT_PAYPAL_SELLER,
  CONNECT_PAYPAL_VENDOR,
  CONNECT_STRIPE_VENDOR,
  CONNECT_STRIPE,
  DISCONNECT_PAYPAL_VENDOR,
  DISCONNECT_PAYPAL,
  DISCONNECT_STRIPE_VENDOR,
  DISCONNECT_STRIPE,
  STANDARD_DISCOUNT_SETTING,
  TOGGLE_AUTO_PAYOUT,
  UPDATE_BRANDING,
  UPDATE_COMMISSION,
  UPDATE_DISCOUNT,
  UPDATE_FEATURE,
  UPDATE_FULFILLMENT,
  UPDATE_MARKUP,
  UPDATE_SELLER_SETTING,
  UPDATE_TERM,
  UPLOAD_IMAGE,
  GET_TERM,
};

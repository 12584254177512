import React, { useContext } from "react";

// import constant
import constant from "lib/constant/constant";

// components
import { PrivateContext } from "lib/context";
import { Spinner } from "lib/components";
import { AdminInvoice } from "./admin/features";
import { OperatorInvoice } from "./operator/features";
import { ProviderInvoice } from "./provider/features";

import { storageHelper } from "../../../lib/helpers";

const { userKey } = constant;
const { admin, operator, provider } = userKey;

const Invoice = () => {
  const currentUserRole = storageHelper.get("userRole");
  const { isLoading } = useContext(PrivateContext);

  const getComponent = (role) => {
    if (isLoading) {
      return <Spinner isFullPage />;
    }
    if (role === admin) {
      return <AdminInvoice />;
    }
    if (role === operator) {
      return <OperatorInvoice />;
    }
    if (role === provider) {
      return <ProviderInvoice />;
    }
    return null;
  };
  const InvoiceComponent = getComponent(currentUserRole);
  return InvoiceComponent;
};

export default Invoice;

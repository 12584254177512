const content = {
  variantLabels: {
    attribute: "Attribute",
    size: "Size",
    active: "Status",
    price: "Price",
    stock: "Stock",
    measurement: "Measurement",
    barcode: "Bar Code",
    inventoryManagement: "InventoryManagement",
    isShipping: "Is Shipping",
    isTaxable: "Is Taxable",
    option1: "Option1",
    option1Val: "Option1 Value",
    option2: "Option2",
    option2Val: "Option2 Value",
    option3: "Option3",
    option3Val: "Option3 Value",
    position: "Position",
    sku: "SKU",
    image: "Variant Image",
    weightUnit: "Weight Unit",
    comparePrice: "Compare Price",
    weight: "Weight",
    inventoryQuantity: "Inventory Quantity",
    length: "Length",
    width: "Width",
    height: "Height"
  },

  labels: {
    approveAll: "Accept and Publish",
    approveSelected: "Accept and Don't Publish",
    barcode: "Bar Code",
    category: "Category",
    collections: "Collections",
    comparePrice: "Compare Price",
    description: "Description",
    handle: "Handle",
    images: "Images",
    inventoryManagement: "Inventory Management",
    isShipping: "Is Shipping",
    isTaxable: "Is Taxable",
    markAs: "Don't Accept Changes",
    no: "No",
    price: "Price",
    productType: "Product Type",
    quantity: "Quantity",
    reviewChanges: "Review Product Changes",
    sellers: "Seller's Value",
    sku: "SKU",
    tags: "Tags",
    title: "Title",
    variants: "Variants",
    vendor: "Vendor",
    vendors: "Vendor's Value",
    weight: "Weight",
    weightUnit: "WeightUnit",
    yes: "Yes",
    length: "Length",
    width: "Width",
    height: "Height"
  },
};

export default content;

import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  ResourceItem,
  TextStyle,
  Tooltip,
  Stack,
  Heading,
  Link,
  Caption,
  Badge,
  Tabs,
  Card,
  Button,
  Thumbnail,
  TextField,
  FormLayout,
  Modal,
} from "@shopify/polaris";
import { ViewMajorMonotone, QuickSaleMajorMonotone } from "@shopify/polaris-icons";
import { useQuery, useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";

import { QuickAction, QuickReport } from "app/reports";
import { TwoColumnLayout } from "layout/private/components";
import { ResourceList, Banner } from "lib/components";

import { PrivateContext } from "lib/context";
import constant from "lib/constant/constant";

// import hoc
import { withFeature, withErrorBoundary } from "lib/hoc";

import { FETCH_ORDER_LINE_LIST } from "app/orders/apollo/queries";
import { updateOrderLineTracking } from "app/orders/apollo/updateOrderNote";
import { baseHelper, imageHelper } from "lib/helpers";
import MoreFilters from "app/orders/modules/generic/moreFilters/moreFilter";
import Popover from "lib/components/popover/popover";
import { ORDER_LIST } from "../../../../apollo/subscriptions";
import ProviderExportOrder from "../export";
import ProviderLineReturn from "../lineReturn";
import listData from "./orderListData";

const ProviderOrderList = () => {
  const { history } = useContext(PrivateContext);
  const [banner, setBanner] = useState({
    isOpen: false,
    status: "",
    title: "",
  });

  const { gql, FULFILLED, REJECT, ACCEPT } = constant;
  const [isExportModal, setIsExportModal] = useState(false);
  const queryParam = baseHelper.getQueryParams(history.location.search);
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { mainLink } = listData(t);
  const [selectedItems, setSelectedItems] = useState([]);
  const [perPage, setPerPage] = useState(parseInt(queryParam.perPage, 10) || 10);
  const [page, setPage] = useState(parseInt(queryParam.page, 10) || 1);
  const [search, setSearch] = useState(queryParam.search || "");
  const [orderList, setOrderList] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const selectedSort = queryParam.sort_name && `${queryParam.sort_name}_${queryParam.sort_order}`;
  const [sortValue, setSortValue] = useState(selectedSort || constant.CREATED_DESC);
  const [taggedWith, setTaggedWith] = useState(queryParam.list_search || null);
  const [selectedFilter, setSelectedFilter] = useState(queryParam.list_filter || null);
  const [activePopover, setActivePopover] = useState({});

  const [lineReturn, setLineReturn] = useState({
    showModal: false,
    selectedOrder: false,
  });
  const [initialTrackingCompany, setInitialTrackingCompany] = useState("");
  const [initialTrackingNumber, setInitialTrackingNumber] = useState("");
  const [trackingModal, setTrackingModal] = useState({});

  const inputData = {
    filter: queryParam.filter,
    path: queryParam.path,
    sort_name: queryParam.sort_name || "createdAt",
    sort_order: queryParam.sort_order || "desc",
    sort_date: queryParam.sort_date,
    page: parseInt(queryParam.page, 10),
    perPage: parseInt(perPage, 10),
    list_search: queryParam.list_search,
    list_filter: queryParam.list_filter,
    search: queryParam.search,
  };

  const { data: order, loading: orderLoading, error: orderError, subscribeToMore, refetch } = useQuery(
    FETCH_ORDER_LINE_LIST,
    {
      variables: {
        input: inputData,
      },
    }
  );
  const [updateOrderLine, { data: dataUpdateOrderLineTracking, loading: loadingOrderLineTracking }] = useMutation(
    updateOrderLineTracking
  );

  const linkData = [
    {
      action: () => setIsExportModal(!isExportModal),
      caption: "Export your orders",
      id: "exportOrder",
      label: "Export Order",
    },
  ];

  useEffect(() => {
    if (!orderLoading) {
      setLoading(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (orderError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: t("error.common.somethingWentWrong"),
      });
    }
  }, [orderError, t]);
  const responseError = baseHelper.getResponseError(order, gql.GET_ORDER_LINES_LIST);
  useEffect(() => {
    if (responseError) {
      setBanner({
        isOpen: true,
        status: "critical",
        title: responseError,
      });
    }
  }, [responseError]);
  const responseData = baseHelper.getResponseData(order, gql.GET_ORDER_LINES_LIST);
  useEffect(() => {
    if (responseData) {
      setOrderList(responseData && responseData.orderLineList);
      setOrderCount(responseData && responseData.count);
    }
  }, [responseData]);

  const setFilters = useCallback(
    (newFilter, select) => {
      const getFilter = baseHelper.setQueryParams(history.location, { filter: newFilter, tab: select });
      history.push(`${history.location.pathname}?${getFilter}`);
    },
    [history]
  );
  const handleSearchChange = (searchValue) => {
    setSearch(searchValue);
    baseHelper.setUrl(history, { search: searchValue, page: 1 });
  };
  const handleSort = (selected) => {
    setSortValue(selected);
    const sort = selected.split("_");
    if (sort && sort.length === 2) {
      baseHelper.setUrl(history, { sort_name: sort[0], sort_order: sort[1], page: 1 });
    }
  };
  const handleQueryClear = () => {
    setSearch("");
    baseHelper.setUrl(history, { search: "" });
  };

  const handleTaggedWithRemove = (clearFilter = false) => {
    const removeParams = { list_search: "" };
    if (clearFilter) {
      removeParams.list_filter = "";
      setSelectedFilter("");
    }
    setTaggedWith("");
    baseHelper.setUrl(history, removeParams);
  };
  const getSecondaryComponent = () => (
    <>
      <QuickAction link={linkData} />
      <QuickReport />
    </>
  );

  const getTabs = {
    0: "all",
    1: "pending",
    2: "accepted",
    3: "rejected",
    4: "shipped",
    5: "closed",
    6: "return",
  };
  const handleSelect = (item) => {
    setSelectedItems(item);
  };
  const handleTabChange = (select) => {
    setSelectedTab(select);
    setFilters(getTabs[select], select);
  };
  useEffect(() => {
    subscribeToMore({
      document: ORDER_LIST,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newOrder = subscriptionData.data.orderLineView.data.orderLine;
        const prevOrder = prev.getOrderLineList.data.orderLineList;
        const filteredOrder = prevOrder.filter((item) => item.shopifyLineItemId !== newOrder.shopifyLineItemId);
        const OrderData = [newOrder, ...filteredOrder];
        const totalCount = OrderData.length;
        return [setOrderList(OrderData), setOrderCount(totalCount)];
      },
    });
  }, [subscribeToMore]);
  const pageContent = {
    label: {
      status: t("tooltip.status"),
      fulfillmentStatus: t("tooltip.fulfillmentStatus"),
    },
  };
  useEffect(() => {
    if (dataUpdateOrderLineTracking) {
      const updateOrderTracking = baseHelper.getResponseData(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );
      const updateOrderTrackingError = baseHelper.getResponseError(
        dataUpdateOrderLineTracking,
        constant.gql.UPDATE_ORDER_LINE_TRACKING
      );
      if (updateOrderTracking) {
        setTrackingModal(false);
        setInitialTrackingCompany("");
        setInitialTrackingNumber("");
        setBanner({
          status: constant.SUCCESS,
          title: t("message.orderFulfilled"),
          isOpen: true,
        });
        refetch();
      }
      if (updateOrderTrackingError) {
        setTrackingModal(false);
        setBanner({
          status: constant.CRITICAL,
          title: updateOrderTrackingError,
          isOpen: true,
        });
      }
    }
  }, [dataUpdateOrderLineTracking]);

  const toggleModal = (shopifyLineItemId = false) => {
    setLineReturn((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
      selectedOrder: prevState.showModal ? false : shopifyLineItemId,
    }));
  };

  const renderModal = () => {
    const { showModal, selectedOrder = false } = lineReturn;

    const orderToInitiate = orderList.find((item) => {
      const { shopifyLineItemId } = item;
      return shopifyLineItemId === selectedOrder;
    });

    if (!orderToInitiate) {
      return null;
    }
    return <ProviderLineReturn orderToInitiate={orderToInitiate} showModal={showModal} onClose={() => toggleModal()} />;
  };
  const renderPopUp = (shopifyLineItemId, item) => {
    const { status } = item;
    if (status === REJECT || status === ACCEPT) {
      return;
    }
    toggleModal(shopifyLineItemId);
  };

  const showReturnStatus = (lineReturnStatus, item) => {
    const { shopifyLineItemId, return: orderReturn } = item;
    const buttonShown = (
      <Stack.Item>
        <Button
          outline
          size="slim"
          destructive={false}
          onClick={() => renderPopUp(shopifyLineItemId, orderReturn)}
          key={`resourceListOrder${shopifyLineItemId}`}
        >
          {lineReturnStatus}
        </Button>
      </Stack.Item>
    );
    const badgeShown = (
      <Badge status={baseHelper.getBadgeType(orderReturn.status)}>{baseHelper.ucFirst(lineReturnStatus)}</Badge>
    );
    const { status } = orderReturn;
    if (status === REJECT || status === ACCEPT) {
      return badgeShown;
    }
    return buttonShown;
  };

  const renderItem = (item) => {
    const {
      _id: id,
      orderId,
      vendor,
      orderNumber,
      fulfillmentStatus,
      status,
      createdAt,
      shopifyLineItemId,
      return: orderReturn,
      image,
      title,
    } = item;

    let isReturnInitatied = false;
    let lineReturnStatus = "";
    const isReturn = orderReturn && Object.keys(orderReturn).length;
    if (isReturn && orderReturn.status) {
      const { status: orderReturnStatus } = orderReturn;
      isReturnInitatied = true;
      lineReturnStatus = listData(t).lineReturnStatus[orderReturnStatus];
    }
    const thumbnailImage = image
      ? imageHelper.resize({ url: image, type: constant.imageTypes.THUMBNAIL })
      : constant.NOIMAGESNAP;
    const thumbnail = <Thumbnail source={thumbnailImage} size={constant.LARGE} />;
    const option = [
      {
        content: constant.VIEW_LABEL,
        icon: ViewMajorMonotone,
        onAction: () => history.push(`${mainLink.orderNumber}${orderId}`),
      },
    ];
    const fullfillOption = {
      content: "Fulfill this line item ",
      icon: QuickSaleMajorMonotone,
      onAction: () => setTrackingModal({ [shopifyLineItemId]: !trackingModal[shopifyLineItemId] }),
      disabled: fulfillmentStatus === FULFILLED || status !== constant.ACCEPTED || false,
    };
    if (fulfillmentStatus === FULFILLED || status !== constant.ACCEPTED) {
      option.push(fullfillOption);
    }
    if (!(fulfillmentStatus === FULFILLED || status !== constant.ACCEPTED)) {
      option.unshift(fullfillOption);
    }

    const addTrackingDetails = () => {
      if (!initialTrackingCompany) {
        setTrackingModal(false);
        setBanner({ isOpen: true, title: t("message.trackingDetail.trackingCompany"), status: constant.CRITICAL });
        return false;
      }
      if (!initialTrackingNumber) {
        setTrackingModal(false);
        setBanner({ isOpen: true, title: t("message.trackingDetail.trackingNumber"), status: constant.CRITICAL });
        return false;
      }
      const data = {
        trackingNumber: initialTrackingNumber,
        trackingCompany: initialTrackingCompany,
      };
      data.id = orderId;
      data.lineItemId = id;
      updateOrderLine({ variables: { input: data } });
    };
    const fulfillment = () => {
      return (
        <Modal
          open={trackingModal[shopifyLineItemId]}
          onClose={() => {
            setTrackingModal(false);
            setInitialTrackingNumber("");
            setInitialTrackingCompany("");
          }}
          title={t("label.addTracking")}
          primaryAction={{
            content: t("label.saveTrackingDetails"),
            onAction: () => addTrackingDetails(),
            loading: loadingOrderLineTracking,
          }}
          sectioned
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label={t("label.company")}
                value={initialTrackingCompany}
                onChange={(val) => {
                  setInitialTrackingCompany(val);
                }}
              />
              <TextField
                label={t("label.number")}
                value={initialTrackingNumber}
                onChange={(val) => {
                  setInitialTrackingNumber(val);
                }}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      );
    };
    return (
      <ResourceItem id={orderId} media={thumbnail} persistActions>
        <Stack>
          <Stack.Item fill Style="width: 200px">
            <div className="ellipsis">
              <Heading>
                <Link onClick={() => history.push(`${mainLink.orderNumber}${orderId}`)}>
                  <div className="ellipsis">{`#${orderNumber} - ${title}`}</div>
                </Link>
              </Heading>
              <Caption>
                <TextStyle variation="subdued">
                  {` ${t("label.CreatedOn")} ${baseHelper.formatDate(createdAt)}`}
                </TextStyle>
              </Caption>
              <Caption>
                {`${t("label.VendorName")} :`}
                <b>{vendor}</b>
              </Caption>
              <Caption>
                {`${t("label.LineItemId")} : `}
                <span>{`#${shopifyLineItemId}`}</span>
              </Caption>
              <Caption>
                <Stack>
                  <Stack.Item>
                    {!isReturn && (
                      <Tooltip content={pageContent.label.status} preferredPosition="above">
                        <Badge status={baseHelper.getBadgeType(status)}>{baseHelper.ucFirst(status)}</Badge>
                      </Tooltip>
                    )}
                  </Stack.Item>
                  <Stack.Item>
                    {!isReturn && (
                      <Tooltip content={pageContent.label.fulfillmentStatus} preferredPosition="above">
                        <Badge status={baseHelper.getBadgeType(fulfillmentStatus)}>
                          {baseHelper.ucFirst(fulfillmentStatus)}
                        </Badge>
                      </Tooltip>
                    )}
                  </Stack.Item>
                </Stack>
              </Caption>
            </div>
          </Stack.Item>
          {fulfillment()}
          <Stack.Item>
            <Popover
              active={activePopover[shopifyLineItemId]}
              setActive={() => setActivePopover({ [shopifyLineItemId]: !activePopover[shopifyLineItemId] })}
              options={option}
            />
          </Stack.Item>
          {fulfillmentStatus === FULFILLED && isReturnInitatied && showReturnStatus(lineReturnStatus, item)}
        </Stack>
      </ResourceItem>
    );
  };

  const handleClearAll = () => {
    handleTaggedWithRemove(true);
  };

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === "" || value == null;
  }

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: "taggedWith",
          label: disambiguateLabel("taggedWith", taggedWith),
          onRemove: () => handleTaggedWithRemove(false),
        },
      ]
    : [];

  const filters = [
    {
      key: t("taggedWith"),
      label: t("label.order.filterBy"),
      filter: (
        <MoreFilters
          taggedWith={taggedWith}
          setTaggedWith={setTaggedWith}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ),
      shortcut: false,
    },
  ];

  const closeBanner = () => {
    setBanner({
      isOpen: false,
      status: "",
      title: "",
    });
  };
  const getComponent = () => {
    return (
      <>
        {banner.isOpen && (
          <>
            <Banner
              isOpen={banner.isOpen}
              status={banner.status}
              title={banner.title}
              onDismiss={() => closeBanner()}
            />
            <br />
          </>
        )}
        <ProviderExportOrder isOpenModal={isExportModal} setIsOpenModal={setIsExportModal} />
        {renderModal()}
        <Card>
          <Tabs tabs={listData(t).tabsData} selected={selectedTab} onSelect={handleTabChange} />
          <ResourceList
            items={orderList}
            renderItem={renderItem}
            loading={loading || orderLoading}
            queryValue={search}
            onQueryClear={handleQueryClear}
            onQueryChange={handleSearchChange}
            sortOptions={listData(t).sortOptions}
            sortValue={sortValue}
            onSortChange={handleSort}
            selectedItems={selectedItems}
            onSelectionChange={handleSelect}
            bulkActions={listData(t).bulkAction}
            filters={filters}
            appliedFilters={appliedFilters}
            handleClearAll={handleClearAll}
            count={orderCount}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </Card>
      </>
    );
  };
  return <TwoColumnLayout primary={getComponent()} secondary={getSecondaryComponent()} />;
};

export default withFeature(withErrorBoundary(ProviderOrderList), { feature: constant.ORDERS });

import React from "react";
import PropTypes from "prop-types";
import { Card, Checkbox, TextField, Select } from "@shopify/polaris";

import { Banner } from "lib/components";

const Shipping = (props) => {
  const { data, onChange } = props;
  const { isPhysicalProduct = false, weight = "", weightUnit } = data;

  const handleShipping = (key, value) => {
    onChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const selectWeightUnit = () => {
    const options = ["lb", "oz", "kg", "g"];
    return (
      <Select
        id="selectWeight"
        value={weightUnit || "lb"}
        label="Weight unit"
        labelHidden
        onChange={(value) => handleShipping("weightUnit", value)}
        options={options}
      />
    );
  };

  return (
    <Card title="Shipping">
      <Card.Section>
        <Checkbox
          label="This is a physical product"
          checked={isPhysicalProduct}
          onChange={(value) => handleShipping("isPhysicalProduct", value)}
        />
        {!isPhysicalProduct && (
          <div>
            <br />
            <Banner isOpen status="info" isScrollTop={false}>
              <p>If you uncheck this, shipping value will not be calculated at the checkout.</p>
            </Banner>
          </div>
        )}
      </Card.Section>
      {isPhysicalProduct && (
        <Card.Section title="WEIGHT">
          <p>Used to calculate shipping rates at checkout.</p>
          <br />
          <TextField
            id="weight"
            label="Weight"
            type="number"
            value={weight}
            placeholder="0"
            onChange={(value) => handleShipping("weight", value)}
            connectedRight={selectWeightUnit()}
          />
        </Card.Section>
      )}
    </Card>
  );
};

Shipping.propTypes = {
  data: PropTypes.shape({
    isPhysicalProduct: PropTypes.bool.isRequired,
    weight: PropTypes.string.isRequired,
    weightUnit: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Shipping;

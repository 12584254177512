import React, { useContext } from "react";

import { PrivateContext } from "lib/context";
import { storageHelper } from "lib/helpers";
import { Spinner } from "lib/components";
import constant from "lib/constant/constant";

import { AdvanceOperatorProviderView } from "app/advanceVendor/modules/operator";

const { userKey } = constant;
const { operator } = userKey;

const AdvanceProviderView = () => {
  const { isLoading } = useContext(PrivateContext);
  const getComponent = (role) => {
    if (role === operator) {
      return <AdvanceOperatorProviderView />;
    }
    return null;
  };
  if (isLoading) {
    return <Spinner />;
  }
  const currentUserRole = storageHelper.get("userRole");
  const VendorAddComponent = getComponent(currentUserRole);
  return VendorAddComponent;
};

export default AdvanceProviderView;

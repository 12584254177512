import React, { useContext } from "react";
import { Card, TextField, Stack, ColorPicker, Layout, TextStyle, TextContainer } from "@shopify/polaris";

// import constant
import constant from "lib/constant/constant";

// import context
import { PrivateContext } from "lib/context";
import { BrandingContext } from "../../../context/context";

const HeaderText = () => {
  const { color, hex, errorMessage, handleChange, resetDefault, learnMore } = useContext(BrandingContext);
  const { cms = () => {} } = useContext(PrivateContext);
  const { HEX, HEADER_TEXT, COLOR, DEFAULT_TEXT_COLOR } = constant;

  return (
    <Layout.AnnotatedSection
      title={cms("section.headerTextColor.title")}
      description={cms("section.headerTextColor.description")}
    >
      <Card
        title={cms("section.headerTextColor.title")}
        actions={[{ content: "Learn more", onAction: () => learnMore(cms("section.headerTextColor.title"), "TODO: ") }]}
      >
        <Card.Section>
          <TextContainer>{cms("section.headerTextColor.subDescription")}</TextContainer>
          <br />
          <Stack>
            <Stack.Item fill>
              <TextField
                label={cms("section.headerTextColor.label.hexCode")}
                value={hex.headerTextHex}
                onChange={(value) => handleChange(HEADER_TEXT, value, HEX)}
                error={errorMessage && errorMessage.headerText}
                labelAction={
                  hex.headerTextHex !== DEFAULT_TEXT_COLOR && {
                    content: cms("label.reset"),
                    onClick: () => resetDefault(HEADER_TEXT, DEFAULT_TEXT_COLOR, HEX),
                  }
                }
              />
              <br />
              <TextStyle>
                <div
                  style={{
                    width: "120px",
                    height: "50px",
                    background: hex.headerHex,
                    color: hex.headerTextHex,
                    textAlign: "center",
                    paddingTop: "13px",
                  }}
                >
                  {cms("section.headerTextColor.label.sampleText")}
                </div>
              </TextStyle>
            </Stack.Item>
            <Stack.Item>
              <ColorPicker onChange={(value) => handleChange(HEADER_TEXT, value, COLOR)} color={color.headerText} />
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default HeaderText;

import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Banner,
  DropZone as PolarisDropZone,
  Button,
  List,
  Stack,
  Thumbnail,
  SkeletonThumbnail,
} from "@shopify/polaris";
import { imageHelper } from "lib/helpers";
import constant from "lib/constant/constant";
import LazyLoad from "react-lazyload";

const DropZone = (props) => {
  const {
    allowMultiple = true,
    onAdd,
    fileList = [],
    onRemove,
    existingImageList = [],
    loadingPosition = "",
    removeExistingImage,
    id,
    disabled,
    label,
  } = props;

  const [files, setFiles] = useState(fileList);
  const [existingImages, setExistingImages] = useState(existingImageList);

  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  useEffect(() => {
    setExistingImages(existingImageList);
  }, [existingImageList]);

  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [hasError, setHasError] = useState(rejectedFiles.length > 0);
  const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"];
  const skeletalThumbnail = <SkeletonThumbnail size={constant.LARGE} />;

  const handleDrop = useCallback(
    async (_droppedFiles, acceptedFiles, rejectedFile) => {
      const promiseList = acceptedFiles.map((acceptedFile) => {
        return new Promise((resolve) => {
          const reader = new window.FileReader();
          reader.readAsDataURL(acceptedFile);
          reader.onloadend = () => {
            const result = reader.result || "";
            resolve(result);
          };
        });
      });
      const response = await Promise.all(promiseList);
      onAdd(response);
      setFiles([...files, ...response]);
      setRejectedFiles(rejectedFile);
    },
    [files, onAdd]
  );

  const fileUpload = <PolarisDropZone.FileUpload />;

  const removeFile = (file, buttonIndex) => {
    const updatedFiles = [...files];
    const index = updatedFiles.indexOf(file);
    if (index < 0) {
      return;
    }
    onRemove(index, buttonIndex);
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setHasError(false);
    }
  };

  const isValidSize = (file) => {
    if (file.size >= props.size * 1024 * 1024) {
      return false;
    }
    return true;
  };

  const isTypeAllowed = (file) => {
    return allowedTypes.includes(file.type);
  };

  const customValidator = (file) => {
    const isValidFile = isValidSize(file) && isTypeAllowed(file);
    if (!isValidFile) {
      setHasError(!isValidFile);
    }
    return isValidFile;
  };

  const uploadedFiles = () => {
    if (files.length === 0 && existingImages.length === 0) {
      return null;
    }

    return (
      <Stack>
        {existingImages.map((image) => (
          <Stack key={`dropZoneUploadedImage${image.position}`} alignment="center" vertical>
            <Stack.Item fill>
              <LazyLoad once height={80} placeholder={skeletalThumbnail}>
                <Thumbnail
                  alt={`image${image.position}`}
                  size={constant.LARGE}
                  source={imageHelper.resize({ url: image.url, type: constant.imageTypes.THUMBNAIL })}
                />
              </LazyLoad>
            </Stack.Item>
            <Stack.Item>
              <Button
                loading={image.position === loadingPosition}
                disabled={loadingPosition && !image.position === loadingPosition}
                onClick={() => removeExistingImage(image)}
              >
                <i className="far fa-trash fa-lg" style={{ color: "red" }} />
              </Button>
            </Stack.Item>
          </Stack>
        ))}
        {files.map((file) => {
          const imageURL = file.url || file.imageUrl || file;
          return (
            <Stack alignment="center" vertical key="uploadedImages">
              <Stack.Item fill>
                <Thumbnail
                  size="large"
                  alt={file.name}
                  source={imageHelper.resize({ url: imageURL, type: constant.imageTypes.THUMBNAIL })}
                />
              </Stack.Item>
              <Stack.Item>
                <Button onClick={() => removeFile(file)}>
                  <i className="far fa-trash fa-lg" style={{ color: "red" }} />
                </Button>
              </Stack.Item>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const errorMessage = rejectedFiles.length && hasError && (
    <Banner title="The following images couldn’t be uploaded:" status="critical" onDismiss={() => setHasError(false)}>
      <List type="bullet">
        {rejectedFiles.map((file) => (
          <List.Item>
            {`"${file.name}" is not supported.
          File type must be .gif, .jpg, .png or .svg and should be less than ${props.size}MB.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  const isDropZoneShown = (!allowMultiple && files.length === 0) || allowMultiple;

  return (
    <Stack vertical>
      {errorMessage}
      {uploadedFiles()}
      {isDropZoneShown && (
        <PolarisDropZone
          id={id}
          accept="image/*"
          type="image"
          onDrop={handleDrop}
          allowMultiple={allowedTypes}
          customValidator={customValidator}
          disabled={disabled}
          label={label}
        >
          {fileUpload}
        </PolarisDropZone>
      )}
    </Stack>
  );
};

DropZone.propTypes = {
  allowMultiple: PropTypes.bool,
  existingImageList: PropTypes.arrayOf(PropTypes.any),
  fileList: PropTypes.arrayOf(PropTypes.any),
  loadingPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  removeExistingImage: PropTypes.func,
  size: PropTypes.number,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};
DropZone.defaultProps = {
  disabled: false,
  allowMultiple: false,
  existingImageList: [],
  fileList: [],
  loadingPosition: "",
  onAdd: () => {},
  onRemove: () => {},
  removeExistingImage: () => {},
  size: 1,
  id: "dropZone",
  label: "",
};

export default DropZone;
